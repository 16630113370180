import '../../../util/string'
import React, { Component, useEffect } from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { withRouter, Link } from 'react-router-dom'
import { Select, MenuItem, TextField, Button, CircularProgress } from '@material-ui/core/';
import Grid from '@material-ui/core/Grid';
import SendIcon from '@material-ui/icons/Send'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import classnames from "classnames";
import axios from '../../../axios-default'
import { connect } from 'react-redux';
import { updateUpdatingAccountUser, reloadUpdatingAccount } from '../../../store/UpdatingAccount/UpdatingAccount.action'
import { easySnackbar } from '../../../store/Noti/noti.actions';
import QueryString from 'querystring'
import { Typography } from '@material-ui/core';

const styles = theme => ({
  "@global": {
    body: {
      background: "#fdfdfd",
    },
  },

  root: {
    flexGrow: 1,
  },
  main: {
    width: 'auto',
    display: 'block',
    marginLeft: "0px 24px",
    [theme.breakpoints.up(500 + 48)]: {
      width: 500,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    [theme.breakpoints.up(600)]: {
      marginTop: "10%",
      marginBottom: "30px"
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  form: {
    width: '100%',
    marginTop: 8,
    textAlign: "left",
  },
  submit: {
    marginTop: "24px",
  },
  textField: {
    // marginLeft: "8px",
    // marginRight: "8px",
    '& label.Mui-focused': {
      color: 'black',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'black',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey',
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
  },
  dense: {
    marginTop: "16px",
    marginLeft: "0px",
  },
  welcomeTitle: {
    margin: "16px 0px"
  },
  progress: {
    marginTop: "48px"
  },
  required: {
    '&:after': {
      content: `'*'`,
      color: "red",
      marginLeft: "3px"
    }
  },
  errors: {
    listStyleType: "none",
    color: "red",
    padding: "2px",
  },
  errorlist: {
    margin: "8px"
  },
  login: {
    backgroundColor: `#473a69 !important`,
    // color: Colors.backgroundSecond,
    marginTop: "32px"
  },
  loginLeft: {
    height: "100vh",
    backgroundPosition: "top center",
    backgroundSize: "cover"
  }
});

class AddressList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      errors: {},
      isUpdating: false,
      disabled: false,
      loading: false,
      role: JSON.parse(localStorage.getItem('role'))
    }
    this.isCreating = false
  }

  returnState() {
    return (
      this.props.stateProvinces.filter(s => s.country_id === this.props.user.country_id).map(v => (
        <MenuItem value={v.state_province_id} key={v.state_province_id}>{v.name}</MenuItem>
      ))
    )
  }

  async submitAccountUpdate() {
    let errors = {}

    if ((!String.isBlank(this.props.user.password) || !String.isBlank(this.props.user.confirm_password)) &&
        this.props.user.password !== this.props.user.confirm_password
    ) {
      errors['password'] = "Passwords do not match"
    }
    if (String.isBlank(this.props.user.first_name)) {
      errors['first_name'] = "First Name is required"
    }
    if (String.isBlank(this.props.user.last_name)) {
      errors['last_name'] = "Last Name is required"
    }
    // if (String.isBlank(this.props.user.client_code)) {
    //   errors['client_code'] = "Client Code is required"
    // }
    if (String.isBlank(this.props.user.company_name)) {
      errors['company_name'] = "Company name is required"
    }
    if (String.isBlank(this.props.user.license_no)) {
      errors['license_no'] = "License number is required"
    }
    if (String.isBlank(this.props.user.phone_no)) {
      errors['phone_no'] = "Phone number is required"
    }
    if (Object.keys(errors).length > 0 && errors.constructor === Object) {
      this.setState({ errors: errors })
    }
    else {
      this.setState({ errors: "", isUpdating: true, disabled: true })
      try {
        let pwdChangeRes = String.isBlank(this.props.user.password)? true : (await axios.post(`user/change_password/admin`, {
          user_id: this.props.user.user_id,
          new_password: this.props.user.password
        })).data.success

        let updateUserRes = (await axios.post(`user/${this.props.user.user_id}`, this.props.user)).data.success
        if (pwdChangeRes && updateUserRes) {
          window.scroll({top: 0, left: 0, behavior: 'smooth' })
          this.props.enqueuSnackbar('Updated successfully', 'success')
          if (this.props.user.user_id == JSON.parse(localStorage.getItem('auth-token'))) {
            this.props.enqueuSnackbar('Some changes will be applied after re-login', 'success')
          }
          let a = this.props.onSave()
          // let a = this.props.onSave()
          this.props.updateUser({
            password: "",
            confirm_password: ""
          })
        }
      }
      catch (e) {
        console.error(e.response)
        this.props.enqueuSnackbar(e.response?.data?.message ?? e.message ?? e, 'error')
      }

      this.setState({ isUpdating: false, disabled: false })
    }
  }

  async submitNewAccount() {
    if (!this.isCreating) {
      this.isCreating = true
      // console.log('submit new account')
      let errors = {}
      if (!this.props.user.title?.length) {
        errors['title'] = "Must fill in title."
      }
      if (isNaN(this.props.user.phone_no)) {
        errors['phone_no'] = 'Invalid phone number.'
      }
      if (!this.props.user.first_name?.length) {
        errors['first_name'] = "Must fill in name."
      }
      if (!this.props.user.last_name?.length) {
        errors['last_name'] = "Must fill in last name."
      }
      if (String.isBlank(this.props.user.phone_no)) {
        errors['phone_no'] = "Must fill in phone number."
      }
      if (!this.props.user.password?.length) {
        errors['password'] = "Must fill in password."
      } else if (!this.props.user.confirm_password?.length) {
        errors['confirmPassword'] = 'Confirm filled in password.'
      } else if (this.props.user.password !== this.props.user.confirm_password) {
        errors['confirmPassword'] = 'Password does not match.'
      }
      if (!this.props.user.company_name?.length) {
        errors['company_name'] = "Must fill in company name."
      }
      if (!this.props.user.license_no?.length) {
        errors['license_no'] = "Must fill in license number."
      }
      if (!this.props.user.clinic_phone_no?.length) {
        errors['clinic_phone_no'] = "Must fill in clinic phone number."
      }
      if (!this.props.user.country_id) {
        errors['country_id'] = "Must fill in country."
      }
      if (Object.keys(errors).length > 0 && errors.constructor === Object) {
        this.setState({ errors: errors })
      }
      else {
        this.setState({ errors: "", isUpdating: true, disabled: true })
        try {
          const { data: { success, user_id } } = await axios.post('register', this.props.user)
          // let { data } = await axios.post(`register`, this.props.user).data.success
          // let refcode = String.isBlank(this.props.user.caseId)? true : (await axios.post(`case/info/this.props.user.case_id`, {
          //   ref_code: this.props.user.ref_code
          // })).data.success
          // if(data && refcode){}
          if (success) {
            window.scroll({top: 0, left: 0, behavior: 'smooth' })
            this.props.enqueuSnackbar(`Successfully create a new account #${user_id}`, 'success')
            try { this.props.onSave() } catch {}
            // this.props.updateUser({
            //   user_id: data.user_id,
            //   password: "",
            //   confirm_password: ""
            // })
            this.props.history.push(`/admin/accounts/${user_id}/1`)
          } else {
            this.props.enqueuSnackbar('Cannot create a new account', 'error')
          }
        }
        catch (err) {
          console.error(err)
          if (err.response && err.response.statusText === 'Unauthorized') {
            localStorage.removeItem("auth-token");
            localStorage.removeItem("expirationDate");
            localStorage.removeItem("role");
            this.props.history.push("/login")
          } else {
            this.props.enqueuSnackbar(err.response?.data?.message ?? err.message ?? err, 'error')
          }
        }

        this.setState({ isUpdating: false, disabled: false })
      }
      this.isCreating = false
    }
  }

  handleChangeData(stateName, value) {
    if (this.props.onChange && this.props.user[stateName] != value) {
      this.props.onChange()
    }
    switch (stateName) {
      case 'email':
        this.props.updateUser({
          email: value
        })
        break;
      case 'password':
        this.props.updateUser({
          password: value
        })
        break;
      case 'confirm_password':
        this.props.updateUser({
          confirm_password: value
        })
        break;
      case 'gender':
        // this.setState(({ user }) => ({ user: { ...user, "gender": value } }));
        this.props.updateUser({
          gender: value
        })
        break;
      case 'title':
        // this.setState(({ user }) => ({ user: { ...user, "gender": value } }));
        this.props.updateUser({
          title: value
        })
        break;
      case 'client_code':
        this.props.updateUser({
          client_code: value
        })
        break;
      case 'first_name':
        this.props.updateUser({
          first_name: value
        })
        break;
      case 'last_name':
        this.props.updateUser({
          last_name: value
        })
        break;
      case 'phone_no':
        this.props.updateUser({
          phone_no: value
        })
        break;
      case 'fax_no':
        this.props.updateUser({
          fax_no: value
        })
        break;
      case 'company_name':
        this.props.updateUser({
          company_name: value
        })
        break;
      case 'license_no':
        this.props.updateUser({
          license_no: value
        })
        break;
      case 'clinic_phone_no':
        this.props.updateUser({
          clinic_phone_no: value
        })
        break;
      case 'clinic_website':
        this.props.updateUser({
          clinic_website: value
        })
        break;
      case 'country':
        this.props.updateUser({
          country_id: value,
          state_province_id: null
        })
        break;
      // case 'currentUserRole':
      //   this.setState(({ user }) => ({ user: { ...user, "role": value } }));
      //   break;
      case 'changeUserStatus':
        this.props.updateUser({
          active_flag: value
        })
        break;
      case 'state':
        this.props.updateUser({
          state_province_id: value
        })
        break;
    }
  }

  render() {
    const { classes } = this.props,
          stateList = this.returnState()
    return (
      <>
        {
          this.state.loading ?
            <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress style={{ color: "rgb(6,26,46)" }} />
            </Grid> :
          <>{['ADMIN', 'SADMIN', 'DISTR'].includes(this.state.role) ?

            <form className={classes.form}>
              {/* {<ul className={classes.errors}>
                {
                  Object.keys(this.state.errors).map((key, index) => (
                    <li className={classes.errorlist}>{this.state.errors[key]}</li>
                  ))
                }
              </ul>} */}

                <p className={classes.required} style={{fontSize: 18, marginBlockEnd: 0}}>Email</p>
                <TextField
                  disabled={JSON.parse(localStorage.getItem("role")) === "DISTR" && this.props.location.pathname != '/admin/accounts/new' || this.state.disabled}
                  className={classnames(classes.textField, classes.dense)}
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  autoFocus
                  value={this.props.user.email}
                  onChange = {(event) => this.handleChangeData("email",event.target.value)}
                />
                <Grid item xs={12} style={{ paddingRight: 4 }}>
                  <p className={classes.required} style={{ fontSize: 18, marginBlockEnd: 0 }}>Phone No.</p>
                  <TextField
                    disabled={this.state.disabled}
                    className={classnames(classes.textField, classes.dense)}
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    value={this.props.user.phone_no}
                    onChange={(event) => this.handleChangeData("phone_no", event.target.value)}
                  />
                  <Typography style={{color: 'red'}}>{this.state.errors['phone_no']}</Typography>
                </Grid>


              <Grid container>
                <Grid item xs={String.isBlank(this.props.user.password)? 12 : 6} style={{ paddingRight: 4 }}>
                  <p className={classes.required} style={{fontSize: 18, marginBlockEnd: 0}}>
                    {this.props.user.user_id? 'New Password' : 'Password'}
                  </p>
                  <TextField
                    disabled={this.state.disabled}
                    className={classnames(classes.textField, classes.dense)}
                    margin="dense"
                    variant="outlined"
                    type="password"
                    autoComplete='new-password'
                    fullWidth
                    autoFocus
                    value={this.props.user.password}
                    onChange = {(event) => this.handleChangeData("password",event.target.value)}
                  />
                  <Typography style={{color: 'red'}}>{this.state.errors['password']}</Typography>
                </Grid>
                <Grid hidden={String.isBlank(this.props.user.password)} item xs={6} style={{ paddingRight: 4 }}>
                  <p className={classes.required} style={{fontSize: 18, marginBlockEnd: 0}}>Confirm Password</p>
                  <TextField
                    disabled={this.state.disabled}
                    className={classnames(classes.textField, classes.dense)}
                    margin="dense"
                    variant="outlined"
                    type="password"
                    autoComplete='new-password'
                    fullWidth
                    autoFocus
                    value={this.props.user.confirm_password}
                    onChange = {(event) => this.handleChangeData("confirm_password",event.target.value)}
                  />
                  <Typography style={{color: 'red'}}>{this.state.errors['confirmPassword']}</Typography>
                </Grid>
              </Grid>

              {/* <p className={classes.required} style={{ fontSize: 18, marginBlockEnd: 0 }}>Gender</p>
              <Select
                disabled={this.state.disabled}
                value={this.props.user.gender}
                onChange={(event) => this.handleChangeData("gender", event.target.value)}
                style={{
                  width: "100%"
                }}
              >
                <MenuItem value="M">Male</MenuItem>
                <MenuItem value="F">Female</MenuItem>
              </Select> */}

              <p className={classes.required} style={{ fontSize: 18, marginBlockEnd: 0 }}>Title</p>
              <Select
                disabled={JSON.parse(localStorage.getItem("role")) === "DISTR" && this.props.location.pathname != '/admin/accounts/new' || this.state.disabled}
                value={this.props.user.title}
                onChange={(event) => this.handleChangeData("title", event.target.value)}
                style={{
                  width: "100%"
                }}
              >
                <MenuItem value="Prof.">Prof.</MenuItem>
                <MenuItem value="Dr.">Dr.</MenuItem>
                <MenuItem value="Mr.">Mr.</MenuItem>
                <MenuItem value="Mrs.">Mrs.</MenuItem>
                <MenuItem value="Ms.">Ms.</MenuItem>
                <MenuItem value="Miss.">Miss.</MenuItem>
              </Select>
              <Typography style={{color: 'red'}}>{this.state.errors['title']}</Typography>

              <Grid container>
                <Grid item xs={6} style={{ paddingRight: 4 }}>
                  <p className={classes.required} style={{ fontSize: 18, marginBlockEnd: 0 }}>First Name</p>
                  <TextField
                    disabled={JSON.parse(localStorage.getItem("role")) === "DISTR" && this.props.location.pathname != '/admin/accounts/new' || this.state.disabled}
                    className={classnames(classes.textField, classes.dense)}
                    margin="dense"
                    variant="outlined"
                    required
                    fullWidth
                    autoFocus
                    value={this.props.user.first_name}
                    onChange={(event) => this.handleChangeData("first_name", event.target.value)}
                  />
                  <Typography style={{color: 'red'}}>{this.state.errors['first_name']}</Typography>
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: 4 }}>
                  <p className={classes.required} style={{ fontSize: 18, marginBlockEnd: 0 }}>Last Name</p>
                  <TextField
                    disabled={JSON.parse(localStorage.getItem("role")) === "DISTR" && this.props.location.pathname != '/admin/accounts/new' || this.state.disabled}
                    className={classnames(classes.textField, classes.dense)}
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    autoFocus
                    value={this.props.user.last_name}
                    onChange={(event) => this.handleChangeData("last_name", event.target.value)}
                  />
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={6} style={{ paddingRight: 4}}>
                  <p style={{ fontSize: 18, marginBlockEnd: 0 }}>Client Code.</p>
                  <TextField
                    disabled={this.state.disabled}
                    className={classnames(classes.textField, classes.dense)}
                    margin="dense"
                    variant="outlined"
                    required
                    fullWidth
                    value={this.props.user.client_code}
                    onChange={(event) => this.handleChangeData("client_code", event.target.value)}
                  />
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: 4 }}>
                  <p style={{ fontSize: 18, marginBlockEnd: 0 }}>Fax No.</p>
                  <TextField
                    disabled={JSON.parse(localStorage.getItem("role")) === "DISTR" && this.props.location.pathname != '/admin/accounts/new' || this.state.disabled}
                    className={classnames(classes.textField, classes.dense)}
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    value={this.props.user.fax_no}
                    onChange={(event) => this.handleChangeData("fax_no", event.target.value)}
                  />
                </Grid>
              </Grid>

              <Grid container>

                <Grid item xs={6} style={{ paddingRight: 4 }}>
                  <p className={classes.required} style={{ fontSize: 18, marginBlockEnd: 0 }}>Company Name</p>
                  <TextField
                    disabled={JSON.parse(localStorage.getItem("role")) === "DISTR" && this.props.location.pathname != '/admin/accounts/new' || this.state.disabled}
                    className={classnames(classes.textField, classes.dense)}
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    value={this.props.user.company_name}
                    onChange={(event) => this.handleChangeData("company_name", event.target.value)}
                  />
                  <Typography style={{color: 'red'}}>{this.state.errors['company_name']}</Typography>
                </Grid>
                <Grid item xs={6} style={{ paddingRight: 4}}>
                  <p className={classes.required} style={{ fontSize: 18, marginBlockEnd: 0 }}>License No.</p>
                  <TextField
                    disabled={JSON.parse(localStorage.getItem("role")) === "DISTR" && this.props.location.pathname != '/admin/accounts/new' || this.state.disabled}
                    className={classnames(classes.textField, classes.dense)}
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    value={this.props.user.license_no}
                    onChange={(event) => this.handleChangeData("license_no", event.target.value)}
                  />
                  <Typography style={{color: 'red'}}>{this.state.errors['license_no']}</Typography>
                </Grid>
              </Grid>

              <p className={classes.required} style={{ fontSize: 18, marginBlockEnd: 0 }}>Clinic Phone No.</p>
              <TextField
                disabled={JSON.parse(localStorage.getItem("role")) === "DISTR" && this.props.location.pathname != '/admin/accounts/new' || this.state.disabled}
                className={classnames(classes.textField, classes.dense)}
                margin="dense"
                variant="outlined"
                fullWidth
                value={this.props.user.clinic_phone_no}
                onChange={(event) => this.handleChangeData("clinic_phone_no", event.target.value)}
              />
              <Typography style={{color: 'red'}}>{this.state.errors['clinic_phone_no']}</Typography>

              <p style={{ fontSize: 18, marginBlockEnd: 0 }}>Clinic Website</p>
              <TextField
                disabled={JSON.parse(localStorage.getItem("role")) === "DISTR" && this.props.location.pathname != '/admin/accounts/new' || this.state.disabled}
                className={classnames(classes.textField, classes.dense)}
                margin="dense"
                variant="outlined"
                fullWidth
                value={this.props.user.clinic_website}
                onChange={(event) => this.handleChangeData("clinic_website", event.target.value)}
              />

              <p className={classes.required} style={{ fontSize: 18, marginBlockEnd: 0 }}>Country</p>
              <Select
                disabled={JSON.parse(localStorage.getItem("role")) === "DISTR" && this.props.location.pathname != '/admin/accounts/new' || this.state.disabled}
                value={this.props.user.country_id}
                onChange={(event) => this.handleChangeData("country", event.target.value)}
                style={{
                  width: "100%"
                }}
              >
                {
                  this.props.countries.map(v => (
                    <MenuItem value={v.country_id} key={v.country_id}>{v.country_name}</MenuItem>
                  ))
                }
              </Select>
              <Typography style={{color: 'red'}}>{this.state.errors['country_id']}</Typography>

              {
                stateList.length ?
                  <>
                    <p className={classes.required} style={{ fontSize: 18, marginBlockEnd: 0 }}>State</p>
                    <Select
                      disabled={this.state.disabled}
                      onChange={(event) => this.handleChangeData("state", event.target.value)}
                      style={{ width: "100%" }}
                      value={this.props.user.state_province_id}
                    >
                      {stateList}
                    </Select>
                    <Typography style={{color: 'red'}}>{this.state.errors['state_province_id']}</Typography>
                  </> :
                  null
              }

              {/* <p className={classes.required} style={{ fontSize: 18, marginBlockEnd: 0 }}>User Role</p> */}
              {/* <Select
                disabled={this.state.disabled}
                value={this.props.user.role}
                onChange={(event) => this.handleChangeData("currentUserRole", event.target.value)}
                style={{
                  width: "100%"
                }}
              >
                {
                  UserRoles.map(v => (
                    <MenuItem value={v.value} key={v.value}>{v.label}</MenuItem>
                  )).concat(JSON.parse(localStorage.getItem("role")) === 'ADMIN'? (<MenuItem value='ADMIN' key='ADMIN'>ADMIN</MenuItem>) : null)
                }
              </Select> */}

              {/* <p className={classes.required} style={{ fontSize: 18, marginBlockEnd: 0 }}>Status</p>
              <Select
                disabled={this.state.disabled}
                value={this.props.user.approved_flag}
                onChange={(event) => this.handleChangeData("changeUserStatus", event.target.value)}
                style={{
                  width: "100%"
                }}
              >
                <MenuItem value={true} key={true}>Approve</MenuItem>
                <MenuItem value={false} key={false}>Disapprove </MenuItem>
              </Select> */}

              <p className={classes.required} style={{ fontSize: 18, marginBlockEnd: 0 }}>Status</p>
              <Select
                disabled={this.state.disabled}
                value={this.props.user.active_flag}
                onChange={(event) => this.handleChangeData("changeUserStatus", event.target.value)}
                style={{
                  width: "100%"
                }}
              >
                <MenuItem value={true} key={true}>Enabled</MenuItem>
                <MenuItem value={false} key={false}>Disabled </MenuItem>
              </Select>

              {this.props.user.user_id?
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.login}
                  onClick={this.submitAccountUpdate.bind(this)}
                  style={{ marginBottom: 16 }}
                >
                  {
                    this.state.isUpdating ? <CircularProgress style={{ color: "white" }} /> :
                      <div style={{ color: "white" }}>Update<SendIcon style={{ margin: "0px 8px", verticalAlign: "middle", color: "white" }} /></div>
                  }
                </Button> :
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.login}
                  onClick={this.submitNewAccount.bind(this)}
                  style={{ marginBottom: 16 }}
                >
                  {
                    this.state.isUpdating ? <CircularProgress style={{ color: "white" }} /> :
                      <div style={{ color: "white" }}>Create<SendIcon style={{ margin: "0px 8px", verticalAlign: "middle", color: "white" }} /></div>
                  }
                </Button>}

            </form> : null
          }</>
        }
      </>
    );
  }
}

AddressList.propTypes = {
}


const mapStateToProps = state => ({
  ...state.updatingAccount
});

const mapDispatchToProps = dispatch => {
  return {
    updateUser: (userObj) => dispatch(updateUpdatingAccountUser(userObj)),
    reload: (userId) => dispatch(reloadUpdatingAccount(userId)),
    enqueuSnackbar: (msg, variant) => dispatch(easySnackbar(msg, variant)),
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)((withStyles(styles))(withRouter(AddressList)))
