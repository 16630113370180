import { Button, CircularProgress, Grid, Paper, TextField, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import SendIcon from '@material-ui/icons/Send'
import classNames from 'classnames'
import React, { Component } from 'react'
import { Animated } from "react-animated-css"
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import axios from '../axios-default'
import * as action from '../store/Auth/Auth.action'
import { easySnackbar } from '../store/Noti/noti.actions'
import { withLang } from '../util/lang'
import '../util/string'
import join from 'url-join'
import Url from 'url'
import { ApiServerUrl } from '../config.json'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import { getWebAbsPath } from '../util/path'





// material ui and css stuff
// import { Grid } from '@material-ui/core'

const styles = theme => ({
  "@global": {
    body: {
      background: "#fdfdfd",
    },
  },
  root: {
    flexGrow: 1,
  },
  main: {
    width: 'auto',
    display: 'block',
    marginLeft: "0px 24px",
    boxShadow: "-2px 6px 24px -9px rgba(166,130,166,1)",
    [theme.breakpoints.up(500 + 48)]: {
      width: 500,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    [theme.breakpoints.up(600)]: {
      marginTop: 12,
      marginBottom: "30px"
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  form: {
    width: '100%',
    marginTop: 8,
    textAlign: "left",
  },
  submit: {
    marginTop: "24px",
  },
  textField: {
    // marginLeft: "8px",
    // marginRight: "8px",
    '& label.Mui-focused': {
      color: 'black',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'black',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey',
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
  },
  dense: {
    marginTop: "16px",
    marginLeft: "0px",
  },
  welcomeTitle: {
    margin: "16px 0px"
  },
  progress: {
    marginTop: "48px"
  },
  login: {
    backgroundColor: `#473a69 !important`,
    // color: Colors.backgroundSecond,
    marginTop: "32px"
  },
  loginLeft: {
    height: "100vh",
    backgroundPosition: "top center",
    backgroundSize: "cover"
  },
  devicesContainer: {
    padding: 16,
    background: "#f2f2f2",
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  }
})

class changePassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userName: "",
      hasUserName: false,
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      passwordError: null,
      currentUserId: parseInt(JSON.parse(localStorage.getItem('auth-token'))),
      loading: false
    }
  }

  async componentDidMount() {
    let userName = '',
        hasUserName = false
    try {
      userName = (await axios.get(`user/${this.state.currentUserId}`)).data.user.user_name
      hasUserName = true
    }
    catch (err) {
      hasUserName = false
    }
    finally {
      this.setState({
        userName: userName? userName : this.props.match.params.email ?? '',
        hasUserName,
        oldPassword: this.props.match.params.oldPassword ?? ''
      })
    }
  }

  handleChangeData(stateName, value) {
    switch (stateName) {
      case 'email':
        this.setState({ userName: value })
        break
      case 'OldPassword':
        this.setState({ "oldPassword": value });
        break;
      case 'Newpassword':
        this.setState({ "newPassword": value });
        break;
      case 'ConfirmNewPassword':
        this.setState({ "confirmNewPassword": value });
        break;
      default:
        break
    }
  }
  async submitHandler() {
    if (this.state.oldPassword.length === 0 || this.state.newPassword.length === 0 || this.state.confirmNewPassword.length === 0) {
      // this.setState({ passwordError: "All the fields are required" })
      this.props.enqueSnackbar("All the fields are required", 'error')
    }
    else if (this.state.newPassword !== this.state.confirmNewPassword) {
      // this.setState({ passwordError: "New Password does not match" })
      this.props.enqueSnackbar("New Password does not match", 'error')
    }
    else {
      this.setState({ loading: true })
      this.setState({ passwordError: "" })
      // this.props.onAuthChangePassword(this.state.userName, this.state.oldPassword, this.state.newPassword);
      try {
        await axios.post(`user/change_password`, {
          user_name: this.state.userName,
          old_password: this.state.oldPassword,
          new_password: this.state.newPassword
        })
        this.setState({
          loading: false,
          oldPassword: "",
          newPassword: "",
          confirmNewPassword: ""
        })
        this.props.enqueSnackbar('Success', 'success', true)
        if (this.state.currentUserId) {
          await this.props.logout()
        }
        this.props.history.replace('/login')
      } catch (err) {
        console.error(err.response)
        if (err.response && err.response.data && err.response.data.message) {
          this.props.enqueSnackbar(err.response.data.message, 'error')
        } else {
          this.props.enqueSnackbar(err.message, 'error')
        }
      } finally {
        this.setState({ loading: false })
      }
    }
  }

  getBanner = () => {
    if (this.props.config.banner && this.props.config.banner.length) {
      return this.props.config.banner.map(b => {
        const banner = {}
        if (typeof b === 'string') {
          banner.img = b
          banner.url = ''
        } else {
          banner.img = b.img
          banner.url = b.url
        }
        if (banner.img.startsWith('/file')) {
          banner.img = join(ApiServerUrl, banner.img)
        }
        return banner
      })
    } else {
      return [{ img: getWebAbsPath('/img/bannerSmall.jpg'), url: '' }]
    }
  }

  render() {
    const { classes } = this.props
    return (
      <Animated animationIn="fadeIn" isVisible={true}>
        <Grid container>
          <Grid item xs={12} md={8} style={{ padding: "24px 16px 24px 8px", margin: "0 auto", textAlign: "center" }}>
            <Paper>
              <Grid container style={{ padding: "16px 24px 24px" }}>
                <Grid item xs={12}
                  style={{
                    padding: 8,
                    textAlign: "center"
                  }}>
                  <img style={{ width: "100%", maxWidth: 400 }} src={getWebAbsPath("/img/changePw.png")} />
                </Grid>

                <Grid item xs={12}>
                  <Typography component="h1" variant="h4" className={classes.welcomeTitle}>
                    {this.get('title')}
                    </Typography>

                  <form className={classes.form}>
                    <div style={{display: !this.state.hasUserName? undefined : 'none'}}>
                      <p style={{ fontSize: 18, marginBlockEnd: 0 }}>{this.get(':fields.email')}</p>
                      <TextField
                        className={classNames(classes.textField, classes.dense)}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        autoFocus
                        value={this.state.userName}
                        onChange={(event) => this.handleChangeData("email", event.target.value)}
                      />
                    </div>
                    <p style={{ fontSize: 18, marginBlockEnd: 0 }}>{this.get('oldPwd')}</p>
                    <TextField
                      className={classNames(classes.textField, classes.dense)}
                      margin="dense"
                      type="password"
                      variant="outlined"
                      autoComplete='new-password'
                      fullWidth
                      autoFocus
                      value={this.state.oldPassword}
                      onChange={(event) => this.handleChangeData("OldPassword", event.target.value)}
                    />
                    <p style={{ fontSize: 18, marginBlockEnd: 0 }}>{this.get('newPwd')}</p>
                    <TextField
                      id="password"
                      type="password"
                      className={classNames(classes.textField, classes.dense)}
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      value={this.state.newPassword}
                      onChange={(event) => this.handleChangeData("Newpassword", event.target.value)}
                    />

                    <p style={{ fontSize: 18, marginBlockEnd: 0 }}>{this.get('confirmNewPwd')}</p>
                    <TextField
                      id="password"
                      type="password"
                      className={classNames(classes.textField, classes.dense)}
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      value={this.state.confirmNewPassword}
                      onChange={(event) => this.handleChangeData("ConfirmNewPassword", event.target.value)}
                    />

                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      disabled={this.state.loading}
                      className={classes.login}
                      onClick={this.submitHandler.bind(this)}
                      style={{ marginBottom: 16 }}
                    >
                      {
                        this.state.loading ? <CircularProgress style={{ color: "white" }} /> :
                          <div style={{ color: "white" }}>{this.get(':const.save')}<SendIcon style={{ margin: "0px 8px", verticalAlign: "middle", color: "white" }} /></div>
                      }
                    </Button>

                  </form>
                </Grid>
              </Grid>
            </Paper>

            {/* </main> */}
          </Grid>
          <Grid item xs={12} md={6}>

          </Grid>
        </Grid>
      </Animated>
    )
  }
}

const mapStateToProps = store => {
  return {
    passwordUpdateSuccessText: store.auth.passwordUpdateSuccessText,
    passwordUpdateFailureText: store.auth.passwordUpdateFailureText,
    isLoading: store.loading.isLoading,
    lang: store.lang,
    config: store.config
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onAuthChangePassword: (username, old_password, new_password) => dispatch(action.updatePassword(username, old_password, new_password)),
    enqueSnackbar: (msg, variant, queue=false) => dispatch(easySnackbar(msg, variant, queue)),
    logout: () => dispatch(action.logOutUser())
  }
};



export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(withLang(changePassword, 'ChangePassword'))))