import React, { Component, PropTypes } from 'react';
import { Grid, TextField, Button, Select, MenuItem, Checkbox, FormGroup, FormControlLabel, Radio } from '@material-ui/core'
import NewPatientPhoto from './NewPatientPhoto'
import style from './NewPatientPhotos.module.css'
import DragAndDrop from '../Photos/DragAndDrop'
import { Animated } from "react-animated-css";
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { getWebAbsPath } from '../../../util/path';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { connect } from 'react-redux';
import { withLang } from '../../../util/lang';
import axios from '../../../axios-default'
import ModalLoader from '../../ModalLoader'
import RadioGroup from '@material-ui/core/RadioGroup';

import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
class ReplaceMyp extends Component {

  constructor(props) {
    super(props);

    //const treatmentPlan = this.props.TreatmentPlanData;
    this.state= ({
       loadingMyo: false,
       smile_3d: '',
       myo_oid: '',
       myo_pid: '',
       myo_id: '',
       myo_token: '',
       treatmentPlanId: 0
     })
     this.handleCurrentTreatmentPlan = this.handleCurrentTreatmentPlan.bind(this);
     this.onMyoFileChange = this.onMyoFileChange.bind(this);

     //this.handleMyoFileUploaded = props.handleMyoFileUploaded.bind(this);
  }

  onMyoFileChange = (event) => {
    this.handleMyo(event.target.files[0]);
  }
  fileupload(file, stateType) {
    let ClassThis = this
    let fileName = file.name
    //ClassThis.props.handleDefectData(file, stateType, fileName)
    // let baseFile = this.fileToBase64(file).then((data) => {
    //   ClassThis.props.handleTreatmentData(data, stateType, fileName)
    // })
  }
   handleCurrentTreatmentPlan = ({ target: { value } }) => {
      this.props.TreatmentPlanData.flat(3).some(plan => {
      if (plan.plan_id == value) {
        //setCurrentTreatmentPlan(plan)
        this.props.handleChangeTreatmentPlanId(value)
        return true
      }
      return false
    })
  }
  onFileChange = (event, stateType) => {
    let compo = this
    let fileName = event.target.files[0].name
  }

  handleChangeTP = (uploadedFile, stateType) => {
    this.fileupload(uploadedFile, stateType)
  }
  handleDrop = (uploadedFile, stateType) => {
    this.handleMyo(uploadedFile, stateType)
  }
  handleRemoveMyo = () =>{
    this.setState({smile_3d:''});
    this.props.handleChangeSmile3d();
  }
  handleMyo = async (uploadedFile) => {
    try {

      this.setState({ loadingMyo: true });
      if (!uploadedFile.name.includes(".myp")){
        alert("Please upload a .myp file!");
        this.setState({ loadingMyo: false });
        return;
      }
      // Upload the .myo file and get the respond.
      const form = new FormData();

      let treatmentData = {
        case_id: this.props.patientInfo.id,
        patient_name: `${this.props.patientInfo.firstName} ${this.props.patientInfo.lastName}`,
        created_by: this.props.patientInfo.created_by
      }

      let mypLink = `case/treatment_plan_myp/${this.props.patientInfo.id}`
      var res

      form.append('$$FILE:myp', uploadedFile);
      form.append('body', JSON.stringify(treatmentData))
      res = await axios.post(mypLink, form);

      this.props.handleMyoFileUploaded(res)
      var smile3dUrl = res.data.url;
      this.setState({smile_3d:smile3dUrl});

      this.setState({ loadingMyo: false });

    } catch (err){
      this.setState({ loadingMyo: false });
      console.log(err);
      alert("Upload failed, please check your file and try again.");
      // if (window.confirm("Upload failed, please check your file and try again")){
      //   this.handleMyo(uploadedFile);
      // }
    }
  }

  removefile(stateType) {

    //this.props.handleDefectData(null, stateType, "")
    //this.props.handleDataPhoto("")
  }

  render() {
    return (
      <Grid item xs={12} sm={12} id = "myo_grid" hidden={false} >
      <Animated animationIn="fadeIn" isVisible={true}>
      <FormControl component="fieldset" style={{display:'block'}}>
        <RadioGroup row aria-label="position" name="position" >
        {
          this.props.TreatmentPlanData.length > 0 ?
            this.props.TreatmentPlanData.map((plans, _refNo) =>
              plans.map(plan => {
                const refNo = plan.refinement_no >= _refNo ? plan.refinement_no : _refNo
                return (
                  <>
                  <FormControlLabel
                    value={plan.plan_id?.toString()}
                    key={refNo} control={<Radio value={plan?.plan_id?.toString() ?? ""} onChange={this.handleCurrentTreatmentPlan} />}
                    label={`${this.get(':fields.treatmentPlan')} ${plan.plan_no} ${refNo > 0 ? `(R${refNo})` : ''}`}
                  />
                  <p></p></>
                )
              })
            )
            : <p>{this.get('clinicalSimulation.msg.noTreatmentPlan')}</p>
        }
        {
          this.state.smile_3d ==='' ?

          <Grid item xs={12} sm={12} id = "myo_grid" hidden={false} >

           <DragAndDrop handleDrop={(uploadedFile) => this.handleMyo(uploadedFile)}>
             <div className={style.imageUploadArea}>
               {this.state.loadingMyo ? <ModalLoader height={200} hideLogo={true} loadingMessage={"Processing, please wait..."}/> : <span>{this.get('treatmentPlan.dragAndDrop')}</span>}
               <div className={style.uploadBtnWrapper}>
                 <button className={style.uploadBtn}>+</button>
                 <input
                   type="file"
                   onChange={(e) => this.onMyoFileChange(e)}
                   name="patientImage"
                   id="patientImage"
                 />
               </div>
             </div>
           </DragAndDrop>
       </Grid>
       :
          <DragAndDrop handleDrop={(uploadedFile) => this.handleMyo(uploadedFile)}>
            <div className={style.imageUploadArea}>
              <button className={style.remove} onClick={() => this.handleRemoveMyo()}><DeleteOutlinedIcon /></button>
            </div>
          </DragAndDrop>}
          </RadioGroup>
          <p style={{ textAlign: "center", color: "rgba(0,0,0,0.3)" }}>(.myp file)</p>

        <TextField
          disabled={true}
          style={{ width: "100%" }}
          InputProps={
            !this.state.myo_token ? {style: {color: "black"}} : {style: {color: "black", fontWeight: "bold"}}
          }
          id="3durl"
          label="Smile 3D View(Url)"
          value={this.state.smile_3d}
          //onChange={(event) => this.handleInput(event.target.value, "smile_3d")}
          onClick={() => {

              window.open(this.state.smile_3d);
          }}
          margin="normal"
        />
      </FormControl>
      </Animated>
      </Grid>
    );
  }
}


const mapStateToProps = store => {
  return {
    lang: store.lang
  }
};

//export default connect(mapStateToProps)(withLang(TreatmentPlan, 'PatientInfo'));
export default connect(mapStateToProps)(withLang(ReplaceMyp, 'ReplaceMyp'));
