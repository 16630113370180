import React, { memo, useCallback, useRef, useState } from 'react'
import { useLang } from '../../util/lang'
import { useDispatch } from 'react-redux'
import { Grid, AppBar, Tabs, TextField, FormControlLabel, Checkbox, makeStyles, Box, Typography, Tab, Button } from '@material-ui/core'
import style from './../NewPatient/Photos/NewPatientPhotos.module.css'
import { NewPatientPhoto } from '../NewPatient/Photos/NewPatientPhoto'
import Zip from 'jszip'
import { safeGetWithProgress } from '../../axios-default'
import { easySnackbar } from '../../store/Noti/noti.actions'
import FileSaver from 'file-saver'
import Path from 'path'
import { ApiServerUrl } from '../../config.json'
import LoadingProgressBar from '../LoadingProgressBar'

const TabPanel = ({ children, value, index, ...other }) => (
  <Typography
    component="div"
    role="tabpanel"
    hidden={value !== index}
    id={`full-width-tabpanel-${index}`}
    aria-labelledby={`full-width-tab-${index}`}
    {...other}
  >
    {value === index && <Box p={3}>{children}</Box>}
  </Typography>
)
const LinkTab = props => (
  <Tab
    component="a"
    onClick={event => {
      event.preventDefault();
    }}
    {...props}
  />
)

const ImpressionViewerStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  input: {
    color: 'black'
  }
}));

const ImpressionViewer = ({
  caseImpression: {
    type, scanner_brand, doctor_id, modern_code,
    waybill_number, courier_code,
    stl_file0, stl_file1, stl_file2, stl_file3,
    stl_files_later, case_id
  }
}) => {
  const classes = ImpressionViewerStyles(),
        get = useLang('NewPatient')
  const mode = 'view'

  return (
    <div className={[classes.root, "tabContainer"].join(' ')}>
      {/* <div className={style.headerWrapper}>
        <AppBar position="static">

          <Tabs
            variant="fullWidth"
            value={type}
            aria-label="nav tabs"
            className="inkBar"
            style={{height: 0}}
            TabIndicatorProps={{
              style: {
                backgroundColor: "#593687"
              }
            }}
          >
            <LinkTab style={{ display: type === 'FILE'? null : 'none' }} label='' {...a11yProps(0)} />
            <LinkTab style={{ display: type === 'COURIER'? null : 'none' }} label='' {...a11yProps(1)} />
            <LinkTab style={{ display: type === 'SCANNER'? null : 'none' }} label='' {...a11yProps(2)} />
          </Tabs>

        </AppBar>
      </div> */}
      <TabPanel value={type} index="FILE">
        <ul className={style.patientImages}>
          <StlImpression
            {
              ...{ case_id, stl_file0, stl_file1, stl_file2, stl_file3, stl_files_later }
            }
          />
        </ul>
      </TabPanel>
      <TabPanel value={type} index="COURIER">
        <TextField
          id="outlined-basic"
          // disabled={true}
          label={get('impressions.byCourierLabel')}
          variant="outlined"
          inputProps={{
            className: classes.input
          }}
          style={{ width: "100%", color: 'black' }}
          value={modern_code}
        />
      </TabPanel>
      <TabPanel value={type} index="SCANNER">
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} ld={4}>
            <TextField
            id="outlined-basic"
            // disabled={true}
              label={get('impressions.byScannerBrandLabel')}
              variant="outlined"
              value={scanner_brand}
              inputProps={{
                className: classes.input
              }}
              style={{ width: "100%", color: 'black' }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} style={{ marginTop: 16 }}>
          <Grid item xs={12} md={6} ld={4}>
            <TextField
              id="outlined-basic"
              // disabled={true}
              label={get('impressions.byScannerUserNameLabel')}
              variant="outlined"
              value={doctor_id}
              inputProps={{
                className: classes.input
              }}
              style={{ width: "100%" }}
            />
          </Grid>
        </Grid>
      </TabPanel>
    </div>
  )
}
export default ImpressionViewer

const StlFileMapper = {
  stl_file0: "Upper STL",
  stl_file1: "Lower STL",
  stl_file2: "Bite 1 STL",
  stl_file3: "Bite 2 STL"
}
const StlImpression = memo(({
  stl_file0, stl_file1, stl_file2, stl_file3,
  stl_files_later, case_id
}) => {
  const get = useLang('NewPatient'),
        dispatch = useDispatch()

  const [downloading, setDownloading] = useState(false),
        progressBarRef = useRef(null)

  const mode = 'view'

  const download = useCallback(async () => {
    let i = 0
    try {
      setDownloading(true)
      const zipFile = new Zip()
      // console.log([ stl_file0, stl_file1, stl_file2, stl_file3 ].map(v => Path.join(ApiServerUrl, v)))
      let loaded = [],
          total = []
      const promises = await Promise.allSettled([ stl_file0, stl_file1, stl_file2, stl_file3 ].map((file, i) =>
        // Axios({ baseURL: ApiServerUrl, url: file, responseType: 'blob' }).then(v => v.data)
        safeGetWithProgress({ baseURL: ApiServerUrl, url: file, responseType: 'blob' }, ({ loaded: _loaded, total: _total }) => {
          loaded[i] = _loaded
          total[i] = _total
          progressBarRef.current.onProgressChange({
            loaded: loaded.reduce((a, v) => a + v, 0),
            total: total.reduce((a, v) => a + v, 0)
          })
        })
      ))
      if (promises.some(({ status }) => status !== 'fulfilled')) {
        const failedRequest = promises.map((v, i) => [i, v]).filter(([_, v]) => v.status !== 'fulfilled')
        if (failedRequest.length === 4) {
          await dispatch(easySnackbar(`Connot get all stl files`, 'error'))
        } else {
          await dispatch(easySnackbar(`Connot get ${failedRequest.map(([i]) => StlFileMapper['stl_file'+i]).join(', ')}`, 'error'))
        }
        return
      } else {
        promises.forEach(({ value }, i) => zipFile.file(`${StlFileMapper['stl_file'+i]}.stl`, value))
      }
      FileSaver.saveAs(await zipFile.generateAsync({
        type: 'blob'
      }), `Impression_${case_id}.zip`)
    } catch (err) {
      await dispatch(easySnackbar(`Cannot get ${StlFileMapper['stl_file'+i]}`, 'error'))
    } finally {
      setDownloading(false)
    }
  }, [ stl_file0, stl_file1, stl_file2, stl_file3 ])

  return (
    <Grid container spacing={1}>
      <LoadingProgressBar ref={progressBarRef} open={downloading} />
      <Grid item xs={12} sm={12}>
        <Button onClick={download} disabled={downloading} variant="outlined">
          Download
        </Button>
      </Grid>
      <Grid item xs={12} sm={3}>
        <li>
          <NewPatientPhoto
            mode={mode}
            imageId="impression_upper"
            imageUploaded={!!stl_file0}
            imageData={stl_file0}
            imageName="uploaded.png"
            type="impression"
            //label="STL"
            StlLabel="Upper STL"
            StlWillNotUploadLabel={get('impressions.notUploadWarning')}
            upload_without_STLfile={stl_files_later}
            // handleRoatate={(degree) => { props.handleRoatate("impression_upper", degree) }}
            // handleChange={(file) => props.handleChange("impression_upper", file)}
          />
        </li>
      </Grid>
      <Grid item xs={12} sm={3}>
        <li>
          <NewPatientPhoto
            mode={mode}
            imageId="impression_lower"
            imageUploaded={!!stl_file1}
            imageData={stl_file1}
            imageName="uploaded.png"
            type="impression"
            //label="STL"
            StlLabel="Lower STL"
            StlWillNotUploadLabel={get('impressions.notUploadWarning')}
            upload_without_STLfile={stl_files_later}
            // handleRoatate={(degree) => { props.handleRoatate("impression_lower", degree) }}
            // handleChange={(file) => { props.handleChange("impression_lower", file) }}
            />
        </li>
      </Grid>
      <Grid item xs={12} sm={3}>
        <li>
          <NewPatientPhoto
            mode={mode}
            imageId="impression_scan_one"
            imageUploaded={!!stl_file2}
            imageData={stl_file2}
            imageName="uploaded.png"
            type="impression"
            //label="STL"
            StlLabel="Bite 1 STL"
            StlWillNotUploadLabel={get('impressions.notUploadWarning')}
            upload_without_STLfile={stl_files_later}
            // handleRoatate={(degree) => { props.handleRoatate("impression_scan_one", degree) }}
            // handleChange={(file) => { props.handleChange("impression_scan_one", file) }}
            />
        </li>
      </Grid>
      <Grid item xs={12} sm={3}>
        <li>
          <NewPatientPhoto
            mode={mode}
            imageId="impression_scan_two"
            imageUploaded={!!stl_file3}
            imageData={stl_file3}
            imageName="uploaded.png"
            type="impression"
            //label="STL"
            StlLabel="Bite 2 STL"
            StlWillNotUploadLabel={get('impressions.notUploadWarning')}
            upload_without_STLfile={stl_files_later}
            // handleRoatate={(degree) => { props.handleRoatate("impression_scan_two", degree) }}
            // handleChange={(file) => { props.handleChange("impression_scan_two", file) }}
            />
        </li>
      </Grid>

      {
        stl_files_later &&
        <>
          <Grid item xs={12} sm={12} md={12} style={{ textAlign: "center", marginTop: "4%" }}>
            <FormControlLabel
              value="end"
              control={
                <Checkbox checked={stl_files_later} />
              }
              label={get('impressions.uploadStlFilesLater')}
              labelPlacement="end"
            />
          </Grid>
        </>
      }

    </Grid>
  )
})