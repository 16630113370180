export const NEW_PATIENT_DELIVERY_PROMO = 'NEW_PATIENT_DELIVERY_PROMO';
export const NEW_PATIENT_DELIVERY_ADDRESS = 'NEW_PATIENT_DELIVERY_ADDRESS';

export const NEW_PATIENT_INFO_GENDER = 'NEW_PATIENT_INFO_GENDER';
export const NEW_PATIENT_INFO_LASTNAME = 'NEW_PATIENT_INFO_LASTNAME';
export const NEW_PATIENT_INFO_FIRSTNAME = 'NEW_PATIENT_INFO';
export const NEW_PATIENT_INFO_DAY = 'NEW_PATIENT_INFO_DAY';
export const NEW_PATIENT_INFO_MONTH = 'NEW_PATIENT_INFO_MONTH';
export const NEW_PATIENT_INFO_YEAR = 'NEW_PATIENT_INFO_YEAR';
export const NEW_PATIENT_INFO_COUNTRY_CODE = 'NEW_PATIENT_INFO_COUNTRY_CODE';
export const NEW_PATIENT_INFO_EMAIL = 'NEW_PATIENT_INFO_EMAIL';
export const NEW_PATIENT_INFO_REFERENCE_CODE = 'NEW_PATIENT_INFO_REFERENCE_CODE';
export const NEW_PATIENT_INFO_REMARK = 'NEW_PATIENT_INFO_REMARK';
export const NEW_PATIENT_INTERNAL_REMARK = 'NEW_PATIENT_INTERNAL_REMARK';
export const NEW_PATIENT_INFO_PHOTO = 'NEW_PATIENT_INFO_PHOTO';


export const NEW_PATIENT_QUES_EXTRACTION = 'NEW_PATIENT_QUES_EXTRACTION';
export const NEW_PATIENT_QUES_EXPANSION = 'NEW_PATIENT_QUES_EXPANSION';
export const NEW_PATIENT_QUES_EXTRACTION_CONCAT = 'NEW_PATIENT_QUES_EXTRACTION_CONCAT';
export const NEW_PATIENT_QUES_EXPANSION_CONCAT = 'NEW_PATIENT_QUES_EXPANSION_CONCAT';
export const NEW_PATIENT_QUES_INTER = 'NEW_PATIENT_QUES_INTER';
export const NEW_PATIENT_QUES_DIST = 'NEW_PATIENT_QUES_DIST';
export const NEW_PATIENT_QUES_PROCLINATION = 'NEW_PATIENT_QUES_PROCLINATION';
export const NEW_PATIENT_QUES_SIMULTAE = 'NEW_PATIENT_QUES_SIMULTAE';
export const NEW_PATIENT_QUES_JET = 'NEW_PATIENT_QUES_JET';
export const NEW_PATIENT_QUES_BITE = 'NEW_PATIENT_QUES_BITE';
export const NEW_PATIENT_QUES_MID = 'NEW_PATIENT_QUES_MID';
export const NEW_PATIENT_QUES_ACROSS = 'NEW_PATIENT_QUES_ACROSS';
export const NEW_PATIENT_QUES_TEETH = 'NEW_PATIENT_QUES_TEETH';
export const NEW_PATIENT_QUES_COMMENT = 'NEW_PATIENT_QUES_COMMENT';
export const NEW_PATIENT_CLINICAL_CONDITION_COMMENT = 'NEW_PATIENT_CLINICAL_CONDITION_COMMENT'

export const NEW_PATIENT_FILES = 'NEW_PATIENT_FILES'
export const NEW_PATIENT_FILE_SHORTCUT = 'NEW_PATIENT_FILE_SHORTCUT'
export const NEW_PATIENT_FILE_INVERT = 'NEW_PATIENT_FILE_INVERT'

export const NEW_PATIENT_IMPRESSION_TYPE = 'NEW_PATIENT_IMPRESSION_TYPE'
export const NEW_PATIENT_MODERN_CODE = 'NEW_PATIENT_MODERN_CODE'
export const NEW_PATIENT_SCANNER = 'NEW_PATIENT_SCANNER'
export const NEW_PATIENT_DOCTOR = 'NEW_PATIENT_DOCTOR'
export const NEW_PATIENT_WAYBILL_NUMBER = 'NEW_PATIENT_WAYBILL_NUMBER'
export const NEW_PATIENT_COURIER_COMPANY = 'NEW_PATIENT_COURIER_COMPANY'

export const NEW_PATIENT_DATA_SAVE_SUCCESS = 'NEW_PATIENT_DATA_SAVE_SUCCESS'
export const NEW_PATIENT_DATA_SAVE_FAILURE = 'NEW_PATIENT_DATA_SAVE_FAILURE'
export const NEW_PATIENT_DATA_UPDATE_SUCCESS = 'NEW_PATIENT_DATA_UPDATE_SUCCESS'
export const NEW_PATIENT_DATA_UPDATE_FAILURE = 'NEW_PATIENT_DATA_UPDATE_FAILURE'

export const NEW_PATIENT_TEMP_SAVE_SUCCESS = 'NEW_PATIENT_TEMP_SAVE_SUCCESS'
export const NEW_PATIENT_INIT_TEMP = 'NEW_PATIENT_INIT_TEMP'

export const NEW_PATIENT_FILE_ROTATE = 'NEW_PATIENT_FILE_ROTATE'
export const NEW_PATIENT_INFO_PHOTO_ROTATE = 'NEW_PATIENT_INFO_PHOTO_ROTATE'

export const CHANGE_CLINICAL_CONDITION = 'CHANGE_CLINICAL_CONDITION'
export const NEW_PATIENT_DATA_ERROR = 'NEW_PATIENT_DATA_ERROR'

export const NEW_PATIENT_CBCT = 'NEW_PATIENT_CBCT'
export const NEW_PATIENT_REMOVE_CBCT = 'NEW_PATIENT_REMOVE_CBCT'

export const NEW_PATIENT_QUES_SIMPLE_UPDATE = 'NEW_PATIENT_QUES_SIMPLE_UPDATE'
export const NEW_PATIENT_QUES_PHONE_UPDATE = 'NEW_PATIENT_QUES_PHONE_UPDATE'

export const NEW_PATIENT_COLOR_PATTERN = 'NEW_PATIENT_COLOR_PATTERN'