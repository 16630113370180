import React, { Component } from 'react'
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Dropzone from 'react-dropzone';
import DragAndDrop from './DragAndDrop';
import { Button } from '@material-ui/core'
import style from './NewPatientPhoto.module.css'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import FlipIcon from '@material-ui/icons/Flip';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import ExifOrientationImg from 'react-exif-orientation-img'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from 'react-redux';
import { withLang } from '../../../util/lang';
import '../../../util/string'
import Url from 'url'
import { ApiServerUrl } from '../../../config.json'
import { getWebAbsPath } from '../../../util/path';

export class NewPatientPhoto extends Component {

  constructor(props) {
    super(props)
    this.state = {
      // upload_later: false,
      // invert: false,
      // rotateDegree: 0,
      open: false,
      will_not_upload_checked: false,
      upload_later_checked: false,
      curImageData: null
    }

    this.removefile = this.removefile.bind(this)
    this.handleNoImage = this.handleNoImage.bind(this)
    this.handleDrop = this.handleDrop.bind(this)
    this.handleClickOpen = this.handleClickOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.invert = this.invert.bind(this)
    this.rotate = this.rotate.bind(this)
  }

  // fileToBase64 = file => new Promise((resolve, reject) => {
  //   const reader = new FileReader();
  //   if (Array.isArray(file) && file.length > 0) {
  //     file = file[0]
  //   }
  //   if (file !== undefined) {
  //     reader.readAsDataURL(file);
  //   }
  //   reader.onload = () => resolve(reader.result);
  //   reader.onerror = error => reject(error);
  // });

  fileupload(file) {
    let ClassThis = this
    if (this.props.mode !== 'view') {
      // console.log(file)
      ClassThis.props.handleChange(file)
      // if (this.props.type === 'impression') {
      // } else {
      //   this.fileToBase64(file).then((data) => {
      //     if (file.type.startsWith('image')) {
      //       var i = new Image();
      //       i.src = data;
      //       i.onload = function () {
      //         ClassThis.props.handleChange(data)
      //       }
      //     } else {
      //       ClassThis.props.handleChange(data)
      //     }

      //   }).catch(err => console.error(err))
      // }
    }
  }

  handleDrop(uploadfile) {
    this.fileupload(uploadfile)
  }

  handleClickOpen() {
    this.setState({ open: true });
  };

  handleClose() {
    this.setState({ open: false });
  };

  handleNoImage(type) {
    // if (type === 'will_not_upload') {
    //   this.setState({ will_not_upload_checked: !this.props.imageData.will_not_upload })
    // }
    // if (type === 'upload_later') {
    //   this.setState({ upload_later_checked: !this.props.imageData.upload_later })
    // }
    if (type === 'will_not_upload' && !this.props.imageData.will_not_upload && !this.props.no_warning) {
      this.setState({ open: true });
    }
    this.props.handleChange(type)
  }

  removefile() {
    this.props.handleChange("remove")
  }

  invert() {
    this.props.handleInvert(!this.props.imageData.flipped)
  }

  rotate(e, direction) {
    if (direction == 'rotateRight') {
      this.props.handleRoatate(this.props.imageData.rotate % 360 + 90)
    } else {
      this.props.handleRoatate(this.props.imageData.rotate % 360 - 90)
    }
  }

  render() {
    let imageSrc = getWebAbsPath(`/img/${this.props.imageName}`)
    if (this.props.type && this.props.type === 'impression') {
      if (
        (this.props.imageData?.data && typeof this.props.imageData?.data !== 'string') ||
        (String.isNotBlank(this.props.imageData?.data) && typeof this.props.imageData?.data === 'string')
      ) {
        imageSrc = getWebAbsPath('/img/uploaded.png')
      }
    } else if (String.isNotBlank(this.props.imageData?.dataURL)) {
      imageSrc = this.props.imageData.dataURL
      // if (this.props.imageData.dataURL.startsWith('data:image/')) {
      //   imageSrc = this.props.imageData.dataURL
      // } else {
      //   imageSrc = join(ApiServerUrl, this.props.imageData.dataURL)
      // }
    } else if (String.isNotBlank(this.props.imageData?.data)) {
      imageSrc = this.props.imageData.data
    }
    return (
      <div>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{this.get(':const.warning')}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.get('radiographs.notUploadWarning')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>
              {this.get(':const.close')}
            </Button>
          </DialogActions>
        </Dialog>

        <DragAndDrop style={{ width: "100%" }} handleDrop={this.handleDrop} disabled={this.props.mode === 'view'}>
          <div className={style.card}>
            {
              this.props.label &&
              this.props.imageData?.upload_later && < div className={style.cardOverlay}><span>{this.props.type === 'radio' ? this.get(':uploadType.uploadXrayLater') : this.get(':uploadType.uploadPhotoLater')}</span></div>
            }
            {
              this.props.label &&
              this.props.imageData?.will_not_upload && < div className={style.cardOverlay}><span>{this.props.type === 'radio' ? this.get(':uploadType.willNotUploadXray') : this.get(':uploadType.willNotUploadPhoto')}</span></div>
            }
            {this.props.StlLabel &&
              this.props.upload_without_STLfile &&
              < div className={style.cardOverlay}><span>{this.props.StlWillNotUploadLabel}</span></div>
            }
            <div className={style.uploadBtnWrapper}>
              {this.props.mode !== 'view' && <button className={style.btn}>+</button>}
              {this.props.imageLabel && <span className={style.ImageLabelText}>{this.props.imageLabel}</span>}
              {(String.isNotBlank(this.props.imageData?.dataURL) || this.props.imageData?.data) && this.props.mode !== 'view' && <button className={style.remove} onClick={this.removefile}><DeleteOutlinedIcon /></button>}
              {this.props.mode !== 'view' && <input type="file" name="photo_section" onChange={(event) => this.fileupload(event.target.files[0])} />}
            </div>

            {this.props.flip && <div className={style.mirrorImage}></div>}
            <div className={style.cardImage}>
              <img
                style={{
                  transform: this.props.imageData?.flipped ?
                    `scaleX(-1) rotateZ(${this.props.imageData?.rotate}deg)` :
                    `rotateZ(${this.props.imageData?.rotate}deg)`
                }}
                src={imageSrc}
              />
              {
                this.props.flip &&
                <>
                  <span className={style.mirrorText1}>{this.props.fliplabel1}</span>
                  <span className={style.mirrorText2}>{this.props.fliplabel2}</span>
                </>
              }
            </div>
            
            {this.props.labelUnderImage &&
              <div className={style.uploadBtnWrapper} style={{textAlign: 'center'}}>
                <span className={style.ImageLabelText}>
                  {this.props.labelUnderImage}
                </span>
              </div>
            }
          </div>
          <div className={style.uploadFooter}>
            {
              !this.props.mandatoryImage && this.props.label && this.props.label !== 'STL' && this.props.uploadLater &&
              this.props.mode !== 'view' &&
              <div className={style.ImageCheckbox}>
                <FormControlLabel value="end" control={<Checkbox
                  checked={this.props.imageData?.upload_later} />}
                  label={this.props.label}
                  labelPlacement="end"
                  onChange={() => this.handleNoImage('upload_later')}
                  disabled={this.props.imageData?.will_not_upload}
                />
              </div>
            }

            {
              this.props.will_not_upload &&
              this.props.mode !== 'view' &&
              <div className={style.ImageCheckbox}>
                <FormControlLabel value="end" control={<Checkbox checked={this.props.imageData?.will_not_upload} />}
                  label={this.props.will_not_upload_label}
                  labelPlacement="end"
                  disabled={this.props.imageData?.upload_later}
                  onChange={() => this.handleNoImage('will_not_upload')} />
              </div>
            }
            {
              this.props.type === 'impression' &&
              <div className={style.ImageCheckbox}>
                {this.props.StlLabel}
              </div>
            }
            {(String.isNotBlank(this.props.imageData?.dataURL) || this.props.imageData?.data) && this.props.handleRoatate && this.props.mode !== 'view' && <>
              <Button style={{ marginLeft: 15 }} variant="outlined" className={style.customButton} value="rotateLeft" onClick={(e) => this.rotate(e, "rotateLeft")}><RotateLeftIcon /></Button>
              <Button style={{ marginLeft: 15 }} variant="outlined" className={style.customButton} value="rotateRight" onClick={(e) => this.rotate(e, "rotateRight")}><RotateRightIcon /></Button>
            </>}
          </div>
          {<p style={{ fontSize: 18, marginBlockEnd: 0, marginLeft: 15, textAlign: 'center' }}>{this.props.descLabel}</p>}
          {this.props.flip && this.props.mode !== 'view' && <Button style={{ marginLeft: 15 }} variant="outlined" className={style.customButton} onClick={this.invert}>{this.get('photographs.flip')}<FlipIcon /></Button>}
        </DragAndDrop>
      </div>
    )
  }
}

const mapStateToProps = store => {
  return {
    lang: store.lang,
  }
};

export default connect(mapStateToProps)(withLang(NewPatientPhoto, 'NewPatient'))
