import '../../util/string'
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { Grid, IconButton } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ImageIcon from '@material-ui/icons/Image';
import FlareIcon from '@material-ui/icons/Flare';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import Brightness5Icon from '@material-ui/icons/Brightness5';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import style from './NewPatientsTabs.module.css'
import AlertDialog from '../Dialog/Dialog'
import { withLang } from '../../util/lang';

// material ui and css stuff
// import { Grid } from '@material-ui/core'

const styles = theme => ({
  root: {
    margin: '48px auto',
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
    },
    [theme.breakpoints.up('lg')]: {
      width: "70%",
      minHeight: "400px",
    },
  },
  tabText: {
    marginBlockStart: "8px",
    marginBlockEnd: "8px",
    fontSize: "14px",
    color: "#797979",
    cursor: "pointer"
  },
  tabTextActive: {
    marginBlockStart: "8px",
    marginBlockEnd: "8px",
    fontSize: "14px",
    color: "#2eafb6",
    cursor: "pointer"
  }
})

class NewPatientsTabs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      firstNameDialogOpen: false,
      lastNameDialogOpen: false,
      // cityDialogOpen: false,
      // zipCodeDialogOpen: false,
      phoneDialogOpen: false,
      countryCodeDialogOpen: false,
      contactNumberDialogOpen: false,
      alignerPackageTypeDialogOpen: false,
      emailDialogOpen: false,
      dialogOpen: false,
      dialogMsg: ''
    }
    this.handleChangeTabLocal = this.handleChangeTabLocal.bind(this)
    this.handleDialogClose = this.handleDialogClose.bind(this)
  }

  componentDidMount() {

  }

  handleDialogClose(type) {
    switch (type) {
      case 'first_name':
        this.setState({ firstNameDialogOpen: false })
        break
      case 'last_name':
        this.setState({ lastNameDialogOpen: false })
        break
      // case 'city':
      //   this.setState({ cityDialogOpen: false })
      //   break
      // case 'zipCode':
      //   this.setState({ zipCodeDialogOpen: false })
      //   break
      case 'address':
        this.setState({ addressDialogOpen: false })
        break
      case 'phone':
        this.setState({ phoneDialogOpen: false })
        break
      case 'country_code':
        this.setState({ countryCodeDialogOpen: false })
        break
      case 'contact_number':
        this.setState({ contactNumberDialogOpen: false })
        break
      case 'aligner_package_type':
        this.setState({ alignerPackageTypeDialogOpen: false })
        break
      case 'email':
        this.setState({ emailDialogOpen: false })
        break
      case undefined:
        this.setState({ dialogOpen: false, dialogMsg: '' })
        break
    }
  }

  handleChangeTabLocal(value) {
    if (value == "summary" && this.props.NewCaseType != "retainer") {
      this.props.ErrorData({ id: "colorpattern", msg: "", valid: true })
    }
    if (this.props.tabMode === 'info') {
      if (this.props.firstName.length == 0) {
        this.setState({ firstNameDialogOpen: true })
        //this.props.handleValidation({"firstName":"First Name is required"})
      }
      else if (this.props.lastName.length == 0) {
        //this.props.handleValidation({"lastName":"Last Name is required"})
        this.setState({ lastNameDialogOpen: true })
      }
      else if (String.isBlank(this.props.NewPaitientData.country_code)) {
        this.setState({ countryCodeDialogOpen: true })
      }
      else if (String.isBlank(this.props.NewPaitientData.contact_number)) {
        this.setState({ contactNumberDialogOpen: true })
      }
      else if (String.isBlank(this.props.NewPaitientData.email)) {
        this.setState({ emailDialogOpen: true })
      }
      else if (this.props.NewCaseType != 'retainer' && String.isBlank(this.props.NewPaitientData.attr0)) {
        this.setState({ alignerPackageTypeDialogOpen: true })
      }
      else {
        this.props.ErrorData({ id: "patient_info", msg: '', valid: true })
        this.changetab(value)
      }
    } else if (this.props.tabMode == 'delivery') {
      // if (this.props.NewPatientAddressData.delivery_id == 'New Address' && this.props.NewPatientAddressData.city.length == 0) {
      //   this.setState({ cityDialogOpen: true })
      // }
      if (this.props.NewPatientAddressData.delivery_id == 'New Address' && this.props.NewPatientAddressData.address.length == 0) {
        this.setState({ addressDialogOpen: true })
      }
      // else if (this.props.NewPatientAddressData.delivery_id == 'New Address' && this.props.NewPatientAddressData.zipCode.length == 0) {
      //   this.setState({ zipCodeDialogOpen: true })
      // }
      else if (this.props.NewPatientAddressData.delivery_id == 'New Address' && this.props.NewPatientAddressData.phoneNumber.length == 0) {
        this.setState({ phoneDialogOpen: true })
      } else {
        this.changetab(value)
      }
    } else if (this.props.tabMode === 'photos') {
      let counter = 0
      if (this.props.NewCaseType !== 'retainer') {
        this.props.NewPaitientData.files.forEach((v, index) => {
          if (index < 6 && !v.data && !v.upload_later && !v.will_not_upload) {
            counter += 1;
          }
        })
      }
      if (counter && this.props.NewCaseType !== 'retainer') {
        this.props.ErrorData({ id: "photo", msg: this.get('warning.mandatoryPhoto'), valid: false })
      }
      else {
        this.props.ErrorData({ id: "photo", msg: '', valid: true })
      }
      this.changetab(value)
    }
    else if (this.props.tabMode === 'radiography') {
      let counter = 0
      if (this.props.NewCaseType !== 'retainer') {
        this.props.NewPaitientData.files.forEach((v, index) => {
          if (v.category === 'radio' && v.id === 'front_with_smile' && !v.data && !v.upload_later && !v.will_not_upload) {
            counter += 1;
          }
        })
      }
      if (counter && this.props.NewCaseType !== 'retainer') {
        this.props.ErrorData({ id: "radiograph", msg: this.get('warning.mandatoryRadiograph'), valid: false })
      }
      else {
        this.props.ErrorData({ id: "radiograph", msg: '', valid: true })
      }
      this.changetab(value)
    }
    else if (this.props.tabMode === 'impression') {
      switch (this.props.NewPaitientData.impressionType) {
        case 'FILE': {
          if (this.props.NewPaitientData.files.some(v =>
            v.category === 'stl_file' && !v.data && !v.upload_later && !v.will_not_upload
          )) {
            this.props.ErrorData({ id: "impression", msg: this.get('warning.allStl'), valid: false })
          } else {
            this.props.ErrorData({ id: "impression", msg: '', valid: true })
          }
          break
        }
        case 'COURIER': {
          if (String.isBlank(this.props.NewPaitientData.modernCode)) {
            this.props.ErrorData({ id: "impression", msg: this.get('warning.courier'), valid: false })
          } else {
            this.props.ErrorData({ id: "impression", msg: '', valid: true })
          }
          break
        }
        case 'SCANNER': {
          if (String.isBlank(this.props.NewPaitientData.scannerBrand) || String.isBlank(this.props.NewPaitientData.doctorId)) {
            this.props.ErrorData({ id: "impression", msg: this.get('warning.scanner'), valid: false })
          } else {
            this.props.ErrorData({ id: "impression", msg: '', valid: true })
          }
          break
        }
      }
      this.changetab(value)
    } else if (this.props.tabMode == "colorpattern") {
      for (let i = 0; i < this.props.NewPaitientData.retainer_pairs; i++) {
        if (this.props.NewPaitientData[`retainer_type${i}`] == null) {
          this.setState({ dialogMsg: `Missing Retainer ${i + 1} Type`, dialogOpen: true })
          return
        } else if (this.props.NewPaitientData[`retainer_type${i}`] == "fashion") {
          if (this.props.NewPaitientData[`retainer_color${i}_upper`] == null) {
            this.setState({ dialogMsg: `Missing Retainer ${i + 1} Upper Jaw Color`, dialogOpen: true })
            return
          } else if (this.props.NewPaitientData[`retainer_color${i}_lower`] == null) {
            this.setState({ dialogMsg: `Missing Retainer ${i + 1} Lower Jaw Color`, dialogOpen: true })
            return
          } else if (this.props.NewPaitientData[`retainer_position${i}`] == null) {
            this.setState({ dialogMsg: `Missing Retainer ${i + 1} Position`, dialogOpen: true })
            return
          }
        }
      }
      this.props.ErrorData({ id: "colorpattern", msg: "", valid: true })
      this.changetab(value)
    } else {
      this.changetab(value)
    }
  }

  changetab(value) {

    this.props.handleChangeTab(value)
    this.props.handleValidation({})
  }

  render() {
    const { classes, NewCaseType } = this.props

    let errors = this.props.NewPaitientData.errors;

    let patient_info_errors = errors.filter(v => {
      return v.id === 'patient_info'
    })

    let patient_colorpattern_errors = errors.filter(v => {
      return v.id === 'colorpattern';
    });

    let photos_errors = errors.filter(v => {
      return v.id === 'photo'
    })

    let radiography_errors = errors.filter(v => {
      return v.id === 'radiograph'
    })

    let impression_errors = errors.filter(v => {
      return v.id === 'impression'
    })

    return (
      // <Grid container className={style.gridContainer}>
      <Grid container
        style={{
          padding: 16,
          overflowX: "scroll",
          marginBottom: 48
        }}
        className={style.customScrollBar}
      >

        {
          this.state.firstNameDialogOpen && <>
            <AlertDialog
              title={this.get(':const.warning')}
              dialogOpen={true}
              handleDialogClose={() => this.handleDialogClose('first_name')}
              content={this.get('warning.firstName')}
            />
          </>
        }

        {
          this.state.lastNameDialogOpen && <>
            <AlertDialog
              title={this.get(':const.warning')}
              dialogOpen={true}
              handleDialogClose={() => this.handleDialogClose('last_name')}
              content={this.get('warning.lastName')}
            />
          </>
        }

        {/* {
          this.state.cityDialogOpen && <>
            <AlertDialog
              title={this.get(':const.warning')}
              dialogOpen={true}
              handleDialogClose={() => this.handleDialogClose('city')}
              content={this.get('warning.city')}
            />
          </>
        } */}

        {
          this.state.addressDialogOpen && <>
            <AlertDialog
              title={this.get(':const.warning')}
              dialogOpen={true}
              handleDialogClose={() => this.handleDialogClose('address')}
              content='Please provide a valid address'
            />
          </>
        }

        {/* {
          this.state.zipCodeDialogOpen && <>
            <AlertDialog
              title={this.get(':const.warning')}
              dialogOpen={true}
              handleDialogClose={() => this.handleDialogClose('zipCode')}
              content={this.get('warning.postCode')}
            />
          </>
        } */}

        {
          this.state.phoneDialogOpen && <>
            <AlertDialog
              title={this.get(':const.warning')}
              dialogOpen={true}
              handleDialogClose={() => this.handleDialogClose('phone')}
              content={this.get('warning.phoneNo')}
            />
          </>
        }

        {
          this.state.countryCodeDialogOpen && <>
            <AlertDialog
              title={this.get(':const.warning')}
              dialogOpen={true}
              handleDialogClose={() => this.handleDialogClose('country_code')}
              content='Missing Country Code'
            />
          </>
        }

        {
          this.state.contactNumberDialogOpen && <>
            <AlertDialog
              title={this.get(':const.warning')}
              dialogOpen={true}
              handleDialogClose={() => this.handleDialogClose('contact_number')}
              content='Missing Contact Number'
            />
          </>
        }
        
        <AlertDialog
          title={this.get(':const.warning')}
          dialogOpen={this.state.alignerPackageTypeDialogOpen}
          handleDialogClose={() => this.handleDialogClose('aligner_package_type')}
          content="Missing Aligner Package Type"
        />

        {
          this.state.emailDialogOpen && <>
            <AlertDialog
              title="Warning"
              dialogOpen={true}
              handleDialogClose={() => this.handleDialogClose('email')}
              content="Missing Email"
            />
          </>
        }

        <AlertDialog
          title="Warning"
          dialogOpen={this.state.dialogOpen}
          handleDialogClose={() => this.handleDialogClose()}
          content={this.state.dialogMsg}
        />

        <div style={{ display: "flex", margin: "auto" }}>
          <Grid item style={{ textAlign: "center", display: "flex" }}>
            <div className={style.iconButtonContainer} onClick={() => this.handleChangeTabLocal("info")}>
              <div className={style.CustomBadgeContainer}>
                {
                  (patient_info_errors[0] && !patient_info_errors[0].valid) ? <span className={style.CustomBadge}>*</span> : <span className={style.CustomBadge} style={{ display: "none" }}>*</span>
                }
                <IconButton
                  aria-label="delete"
                  style={{
                    background: this.props.tabMode === "info" ? "#2eafb6" : "#bababa",
                  }}
                  className={style.iconButton}
                >
                  <InfoIcon />
                </IconButton>
              </div>
              <p className={this.props.tabMode === "info" ? classes.tabTextActive : classes.tabText}>{this.get('tabs.info')}</p>
            </div>

            <div className={style.iconButtonArrowContainer}>
              <IconButton aria-label="delete" disabled className="iconButtonArrow">
                <KeyboardArrowRightIcon />
              </IconButton>
            </div>
          </Grid>
          {NewCaseType === 'retainer' && 
            <Grid item style={{ textAlign: "center", display: "flex" }}>
              <div className={style.iconButtonContainer} onClick={() => this.handleChangeTabLocal("colorpattern")}>
                <div className={style.CustomBadgeContainer}>
                  {
                    (patient_colorpattern_errors[0] && !patient_colorpattern_errors[0].valid) ? <span className={style.CustomBadge}>*</span> : <span className={style.CustomBadge} style={{ display: "none" }}>*</span>
                  }
                  <IconButton
                    aria-label="color pattern"
                    style={{
                      background: this.props.tabMode === "colorpattern" ? "#2eafb6" : "#bababa",
                    }}
                    className={style.iconButton}
                  >
                    <ColorLensIcon />
                  </IconButton>
                </div>
                <p className={this.props.tabMode === "colorpattern" ? classes.tabTextActive : classes.tabText}>Retainer Style</p>
              </div>

              <div className={style.iconButtonArrowContainer}>
                <IconButton aria-label="delete" disabled className="iconButtonArrow">
                  <KeyboardArrowRightIcon />
                </IconButton>
              </div>
            </Grid>
          }
          {NewCaseType !== 'retainer' && <>

            <Grid item style={{ textAlign: "center", display: "flex" }}>
              <div className={style.iconButtonContainer} onClick={() => this.handleChangeTabLocal("questionnaire")}>
              <div className={style.CustomBadgeContainer}>
                <IconButton
                  aria-label="delete"
                  style={{
                    background: this.props.tabMode === "questionnaire" ? "#2eafb6" : "#bababa",
                  }}
                  className={style.iconButton}
                >
                  <QuestionAnswerIcon />
                </IconButton>
                </div>
                <p className={this.props.tabMode === "questionnaire" ? classes.tabTextActive : classes.tabText}>Clinical Info</p>
              </div>

              <div className={style.iconButtonArrowContainer}>
                <IconButton aria-label="delete" disabled className="iconButtonArrow">
                  <KeyboardArrowRightIcon />
                </IconButton>
              </div>
            </Grid>

            {NewCaseType !== 'retainer' && <Grid item style={{ textAlign: "center", display: "flex" }}>
              <div className={style.iconButtonContainer} onClick={() => this.handleChangeTabLocal("photos")}>

                <div className={style.CustomBadgeContainer}>
                  {(photos_errors[0] && !photos_errors[0].valid) ? <span className={style.CustomBadge}>*</span> : <span className={style.CustomBadge} style={{ display: "none" }}>*</span>}
                  <IconButton
                    aria-label="delete"
                    style={{
                      background: this.props.tabMode === "photos" ? "#2eafb6" : "#bababa",
                    }}
                    className={style.iconButton}
                  >
                    <ImageIcon />
                  </IconButton>
                </div>

                <p className={this.props.tabMode === "photos" ? classes.tabTextActive : classes.tabText}>{this.get(':fields.photos')}</p>
              </div>

              <div className={style.iconButtonArrowContainer}>
                <IconButton aria-label="delete" disabled className="iconButtonArrow">
                  <KeyboardArrowRightIcon />
                </IconButton>
              </div>
            </Grid>}

            {NewCaseType !== 'retainer' && <Grid item style={{ textAlign: "center", display: "flex" }}>
              <div className={style.iconButtonContainer} onClick={() => this.handleChangeTabLocal("radiography")}>
                <div className={style.CustomBadgeContainer}>
                  {(radiography_errors[0] && !radiography_errors[0].valid) ? <span className={style.CustomBadge}>*</span> : <span className={style.CustomBadge} style={{ display: "none" }}>*</span>}
                  <IconButton
                    aria-label="delete"
                    style={{
                      background: this.props.tabMode === "radiography" ? "#2eafb6" : "#bababa",
                    }}
                    className={style.iconButton}
                  >
                    <FlareIcon />
                  </IconButton>
                </div>
                <p className={this.props.tabMode === "radiography" ? classes.tabTextActive : classes.tabText}>{this.get(':fields.radiographs')}</p>
              </div>

              <div className={style.iconButtonArrowContainer}>
                <IconButton aria-label="delete" disabled className="iconButtonArrow">
                  <KeyboardArrowRightIcon />
                </IconButton>
              </div>
            </Grid>}
          </>
          }
          <Grid item style={{ textAlign: "center", display: "flex" }}>
            <div className={style.iconButtonContainer} onClick={() => this.handleChangeTabLocal("impression")}>
              <div className={style.CustomBadgeContainer}>
                {(impression_errors[0] && !impression_errors[0].valid) ? <span className={style.CustomBadge}>*</span> : <span className={style.CustomBadge} style={{ display: "none" }}>*</span>}
                <IconButton
                  aria-label="delete"
                  style={{
                    background: this.props.tabMode === "impression" ? "#2eafb6" : "#bababa",
                  }}
                  className={style.iconButton}
                >
                  <Brightness5Icon />
                </IconButton>
              </div>
              <p className={this.props.tabMode === "impression" ? classes.tabTextActive : classes.tabText}>{this.get(':fields.impression')}</p>
            </div>

            <div className={style.iconButtonArrowContainer}>
              <IconButton aria-label="delete" disabled className="iconButtonArrow">
                <KeyboardArrowRightIcon />
              </IconButton>
            </div>
          </Grid>

          <Grid item style={{ textAlign: "center", display: "flex" }}>
            <div className={style.iconButtonContainer} onClick={() => this.handleChangeTabLocal("delivery")}>
              <IconButton
                aria-label="delete"
                style={{
                  background: this.props.tabMode === "delivery" ? "#2eafb6" : "#bababa",
                }}
                className={style.iconButton}
              >
                <LocalShippingIcon />
              </IconButton>
              <p className={this.props.tabMode === "delivery" ? classes.tabTextActive : classes.tabText}>{this.get('tabs.delivery')}</p>
            </div>

            <div className={style.iconButtonArrowContainer}>
              <IconButton aria-label="delete" disabled className="iconButtonArrow">
                <KeyboardArrowRightIcon />
              </IconButton>
            </div>
          </Grid>

          <Grid item style={{ textAlign: "center", display: "flex" }} className={style.gridContainerLast}>
            <div className={style.iconButtonContainer} onClick={() => this.handleChangeTabLocal("summary")}>
              <IconButton
                aria-label="delete"
                style={{
                  background: this.props.tabMode === "summary" ? "#2eafb6" : "#bababa",
                }}
                className={style.iconButton}
              >
                <InsertDriveFileIcon />
              </IconButton>
              <p className={this.props.tabMode === "summary" ? classes.tabTextActive : classes.tabText}>{this.get('tabs.summary')}</p>
            </div>
          </Grid>
        </div>
      </Grid>
    )
  }
}

const mapStateToProps = store => {
  return {
    NewPaitientData: store.newPatientReducer,
    lang: store.lang
  }
};

const mapDispatchToProps = dispatch => {
  return {
    ErrorData: (errorMsg) => dispatch({ type: 'NEW_PATIENT_DATA_ERROR', errorMsg: errorMsg })
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(withLang(NewPatientsTabs, 'NewPatient'))))