import { Button, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { Animated } from "react-animated-css";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withLang } from '../util/lang';
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import frontend_config from '../frontend_config';
import { getWebAbsPath } from '../util/path';

const hostname = window.location.hostname

// material ui and css stuff
// import { Grid } from '@material-ui/core'

const styles = theme => ({
  root: {
    margin: '48px auto',
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
    },
    [theme.breakpoints.up('lg')]: {
      width: "70%",
    },
  },
  supportContainer: {
    padding: 36,
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
      padding: 16,
    },
    [theme.breakpoints.up('lg')]: {
      width: "70%",
      marginLeft: "15%",
    },
  },
  devicesContainer: {
    padding: 16,
    background: "#f2f2f2",
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  }
})

class Support extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  componentDidMount() {

  }

  render() {
    const { classes } = this.props
    return (
      <Animated animationIn="fadeIn" isVisible={true}>
        <Grid container className={classes.supportContainer} align="center">
          <Grid item xs={12}
            style={{
              textAlign: "left",
              boxShadow: "-2px 6px 24px -9px rgba(166,130,166,1)",
            }}
          >
            <Grid container>
              <Grid item xs={12} sm={6} style={{ display: "flex" }}>
                <img src={getWebAbsPath("/img/ContactInfo.jpg")} style={{ width: "100%" }} />
              </Grid>
              <Grid item xs={12} sm={6} style={{ padding: 24 }}>
                <h3
                  style={{
                    textAlign: "left",
                    fontWeight: 600,
                    color: "#2eafb6",
                    fontSize: 36,
                    marginBlockStart: 0,
                    marginBlockEnd: 0
                  }}
                >
                  {this.get('contactInfo')}
                </h3>
                <hr style={{ border: "solid 3px #2eafb6", width: 88, margin: "16px 0px" }} align="left" />
                <p style={{ margin: "24px 0px" }}>{this.get(':fields.tel')}: {frontend_config?.[hostname]?.contact_phone}</p>
                <p style={{ margin: "24px 0px" }}>{this.get(':fields.email')}: {frontend_config?.[hostname]?.contact_email}</p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container className={classes.supportContainer} align="center">
          <Grid item xs={12}
            style={{
              textAlign: "left",
              boxShadow: "-2px 6px 24px -9px rgba(166,130,166,1)",
            }}
          >
            <Grid container>
              <Grid item xs={12} sm={6} style={{ padding: 24 }}>
                <h3
                  style={{
                    textAlign: "left",
                    fontWeight: 600,
                    color: "#2eafb6",
                    fontSize: 36,
                    marginBlockStart: 0,
                    marginBlockEnd: 0
                  }}
                >
                  {this.get('formDownload')}
                </h3>
                <hr style={{ border: "solid 3px #2eafb6", width: 88, margin: "16px 0px" }} align="left" />

                <div style={{ margin: "8px 0px" }}>
                  <a href="/files/BeMeTermsAndConditions.docx" target="_blank" style={{ textDecoration: "none", display: "block" }}>
                    <Button variant="outlined">
                      Terms &amp; Conditions
                    </Button>
                  </a>
                </div>

                <div style={{ margin: "8px 0px" }}>
                  <a href="/files/BeMePrivacyPolicy.docx" target="_blank" style={{ textDecoration: "none", display: "block" }}>
                    <Button variant="outlined">
                      Privacy Policy
                    </Button>
                  </a>
                </div>

                <div style={{ margin: "8px 0px" }}>
                  <a href="/files/BeMePatientConsentForm.docx" target="_blank" style={{ textDecoration: "none", display: "block" }}>
                    <Button variant="outlined">
                      Patient Consent Form
                    </Button>
                  </a>
                </div>
              </Grid>

              <Grid item xs={12} sm={6} style={{ display: "flex" }}>
                <img src={getWebAbsPath("/img/FormDownload.jpg")} style={{ width: "100%" }} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </Animated>
    )
  }
}

const mapStateToProps = store => {
  return {
    lang: store.lang,
    config: store.config.config
  }
};

export default connect(mapStateToProps)(withStyles(styles)(withRouter(withLang(Support, 'Support'))))
