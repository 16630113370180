import * as actionTypes from './actionType';
import { MaxRetainerPairs } from '../../config.json'

export const initialState = {
  promotionCode: "",
  gender: "M",
  lastName: "",
  firstName: "",
  day: (new Date()).getDate(),
  month: (new Date()).getMonth() + 1,
  year: (new Date()).getFullYear(),
  remarks: "",
  internalRemarks: "",
  refCode: null,
  patientPhoto: { id: "profile_pic", uploaded: false, dataURL: null, data: null, rotate: 0 },

  //added by vincent
  country_code: "",
  contact_number: "",
  email: "",

  attr0: null,
  attr1: null,
  attr2: null,
  attr3: null,
  attr4: null,
  attr5: null,
  attr6: null,
  attr7: null,
  attr8: null,
  attr9: null,
  attr10: null,
  attr11: null,
  attr12: null,
  attr13: null,
  attr14: null,
  attr15: null,
  attr16: null,
  attr17: null,
  attr18: null,
  attr19: null,
  attr20: null,
  attr21: null,
  attr22: null,
  attr23: null,
  attr24: null,
  attr25: null,
  attr26: null,
  attr27: null,
  attr28: null,
  attr29: null,
  attr30: null,
  attr31: null,
  attr32: null,
  attr33: null,
  attr34: null,
  attr35: null,
  attr36: null,
  attr37: null,
  attr38: null,
  attr39: null,
  attr40: null,
  attr41: null,
  attr42: null,
  attr43: null,
  attr44: null,
  attr45: null,
  attr46: null,
  attr47: null,
  attr48: null,
  attr49: null,

  logistics_order_id: null,
  logistics_company: null,

  files: [
    { id: "jaw_side_with_smile", uploaded: false, dataURL: null, data: null, flipped: false, rotate: 0, category: "photo", upload_later: false, will_not_upload: null },
    { id: "face_front_with_smile", uploaded: false, dataURL: null, data: null, flipped: false, rotate: 0, category: "photo", upload_later: false, will_not_upload: null },
    { id: "teeth_side_without_smile", uploaded: false, dataURL: null, data: null, flipped: false, rotate: 0, category: "photo", upload_later: false, will_not_upload: null },
    { id: "jaw_front_with_smile", uploaded: false, dataURL: null, data: null, flipped: false, rotate: 0, category: "photo", upload_later: false, will_not_upload: null },
    { id: "teeth_side_with_smile", uploaded: false, dataURL: null, data: null, flipped: false, rotate: 0, category: "photo", upload_later: false, will_not_upload: null },
    { id: "jaw_side_without_smile", uploaded: false, dataURL: null, data: null, flipped: false, rotate: 0, category: "photo", upload_later: false, will_not_upload: null },
    { id: "face_side_with_smile", uploaded: false, dataURL: null, data: null, flipped: false, rotate: 0, category: "photo", upload_later: false, will_not_upload: null },
    { id: "teeth_front_with_smile", uploaded: false, dataURL: null, data: null, flipped: false, rotate: 0, category: "photo", upload_later: false, will_not_upload: null },
    { id: "face_side_without_smile", uploaded: false, dataURL: null, data: null, flipped: false, rotate: 0, category: "photo", upload_later: false, will_not_upload: null },
    { id: "front_with_smile", uploaded: false, dataURL: null, data: null, flipped: false, rotate: 0, category: "radio", upload_later: false, will_not_upload: null },
    { id: "side_with_smile", uploaded: false, dataURL: null, data: null, flipped: false, rotate: 0, category: "radio", upload_later: false, will_not_upload: null },
    { id: "cbct", uploaded: false, dataURL: null, data: null, flipped: false, rotate: 0, category: "radio", upload_later: false, will_not_upload: null },
    { id: "impression_upper", uploaded: false, dataURL: null, data: null, flipped: false, rotate: 0, category: "stl_file", upload_later: false, will_not_upload: null },
    { id: "impression_lower", uploaded: false, dataURL: null, data: null, flipped: false, rotate: 0, category: "stl_file", upload_later: false, will_not_upload: null },
    { id: "impression_scan_one", uploaded: false, dataURL: null, data: null, flipped: false, rotate: 0, category: "stl_file", upload_later: false, will_not_upload: null },
    { id: "impression_scan_two", uploaded: false, dataURL: null, data: null, flipped: false, rotate: 0, category: "stl_file", upload_later: false, will_not_upload: null },
  ],
  cbctFiles: [],
  impressionType: "FILE",
  scannerBrand: "",
  doctorId: "",
  modernCode: "",
  waybillNumber: "",
  courierCompany: "",
  saveSuccess: false,
  errors: [
    { id: "patient_info", msg: 'First Name And Last Name Is Required', valid: false },
    { id: "photo", msg: 'Please Upload All The mandatory Photos', valid: false },
    { id: "radiograph", msg: "Please Upload The Mandatory Radiograph Image", valid: false },
    { id: "impression", msg: "Please Upload All The STL Files or Check 'Upload this photo later' Boxes", valid: false },
    { id: "colorpattern", msg: "Please Choose Retainer Style", valid: false },
  ],
  case_retainer_id: null,
  retainer_pairs: 2,
  max_retainer_pairs: MaxRetainerPairs
};
const initialStateBackup = JSON.parse(JSON.stringify(initialState))

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.NEW_PATIENT_DELIVERY_PROMO:
      return {
        ...state,
        promotionCode: action.value
      }
    case actionTypes.NEW_PATIENT_DELIVERY_ADDRESS:
      return {
        ...state,
        address: action.value
      }
    case actionTypes.NEW_PATIENT_INFO_GENDER:
      return {
        ...state,
        gender: action.value
      }
    case actionTypes.NEW_PATIENT_INFO_LASTNAME:
      return {
        ...state,
        lastName: action.value
      }
    case actionTypes.NEW_PATIENT_INFO_FIRSTNAME:
      return {
        ...state,
        firstName: action.value
      }

    case actionTypes.NEW_PATIENT_INFO_DAY:
      return {
        ...state,
        day: action.value
      }

    case actionTypes.NEW_PATIENT_INFO_MONTH:
      return {
        ...state,
        month: action.value
      }

    case actionTypes.NEW_PATIENT_INFO_YEAR:
      return {
        ...state,
        year: action.value
      }
    case actionTypes.NEW_PATIENT_INFO_COUNTRY_CODE:
      return {
        ...state,
        country_code: action.value
      }
    case actionTypes.NEW_PATIENT_INFO_EMAIL:
      return {
        ...state,
        email: action.value
      }
    case actionTypes.NEW_PATIENT_INFO_REFERENCE_CODE:
      return {
        ...state,
        refCode: action.value
      }
    case actionTypes.NEW_PATIENT_INFO_REMARK:
      return {
        ...state,
        remarks: action.value.slice(0, 1000)
      }

      case actionTypes.NEW_PATIENT_INTERNAL_REMARK:
        return {
          ...state,
          internalRemarks: action.value.slice(0, 1000)
        }

    case actionTypes.NEW_PATIENT_INFO_PHOTO:
      if (state.patientPhoto.dataURL) {
        URL.revokeObjectURL(state.patientPhoto.dataURL)
        state.patientPhoto.dataURL = null
      }
      return {
        ...state,
        patientPhoto: {
          ...state.patientPhoto,
          data: action.value,
          dataURL: action.value && URL.createObjectURL(action.value),
          uploaded: true,
          rotate: 0
        }
      }
    case actionTypes.NEW_PATIENT_INFO_PHOTO_ROTATE:
      let newRotatePhoto = { ...state.patientPhoto }
      newRotatePhoto['rotate'] = action.value
      return {
        ...state,
        patientPhoto: newRotatePhoto
      }
    case actionTypes.NEW_PATIENT_CLINICAL_CONDITION_COMMENT:
      return {
        ...state,
        clinicalConditionComment: action.value.slice(0, 1000)
      }
    case actionTypes.CHANGE_CLINICAL_CONDITION:
      return {
        ...state,
        clinical_cond: action.value
      }
    case actionTypes.NEW_PATIENT_QUES_EXTRACTION:
      return {
        ...state,
        extraction: action.value
      }

    case actionTypes.NEW_PATIENT_QUES_EXPANSION:
      return {
        ...state,
        expansion: action.value
      }
    case actionTypes.NEW_PATIENT_QUES_EXTRACTION_CONCAT:
      return {
        ...state,
        extractionConcat: action.value
      }

    case actionTypes.NEW_PATIENT_QUES_EXPANSION_CONCAT:
      return {
        ...state,
        expansionConcat: action.value
      }
    case actionTypes.NEW_PATIENT_QUES_INTER:
      return {
        ...state,
        interproximalReduction: action.value
      }

    case actionTypes.NEW_PATIENT_QUES_DIST:
      return {
        ...state,
        distalization: action.value
      }

    case actionTypes.NEW_PATIENT_QUES_PROCLINATION:
      return {
        ...state,
        proclination: action.value
      }

    case actionTypes.NEW_PATIENT_QUES_SIMULTAE:
      return {
        ...state,
        treatmentSimulation: action.value
      }
    case actionTypes.NEW_PATIENT_QUES_JET:
      return {
        ...state,
        overJet: action.value
      }

    case actionTypes.NEW_PATIENT_QUES_BITE:
      return {
        ...state,
        overBite: action.value
      }
    case actionTypes.NEW_PATIENT_QUES_MID:
      return {
        ...state,
        midLine: state.midLine.includes(action.value) ?
          state.midLine.filter(v => v !== action.value) :
          [...state.midLine, action.value]
      }
    case actionTypes.NEW_PATIENT_QUES_ACROSS:
      return {
        ...state,
        crossBite: action.value
      }
    case actionTypes.NEW_PATIENT_QUES_TEETH:
      let modify_teeth_images = [...state.teeth];
      modify_teeth_images[action.value[0]].checked = action.value[1] ? true : !modify_teeth_images[action.value[0]].checked
      return {
        ...state,
        teeth: modify_teeth_images
      }
    case actionTypes.NEW_PATIENT_QUES_COMMENT:
      return {
        ...state,
        comment: action.value.slice(0, 1000)
      }
    case actionTypes.NEW_PATIENT_IMPRESSION_TYPE:
      return {
        ...state,
        impressionType: action.value
      }
    case actionTypes.NEW_PATIENT_MODERN_CODE:
      return {
        ...state,
        modernCode: action.value
      }
    case actionTypes.NEW_PATIENT_SCANNER:
      return {
        ...state,
        scannerBrand: action.value
      }
    case actionTypes.NEW_PATIENT_DOCTOR:
      return {
        ...state,
        doctorId: action.value
      }
    case actionTypes.NEW_PATIENT_COURIER_COMPANY:
      return {
        ...state,
        courierCompany: action.value
      }
    case actionTypes.NEW_PATIENT_WAYBILL_NUMBER:
      return {
        ...state,
        waybillNumber: action.value
      }
    case actionTypes.NEW_PATIENT_CBCT:
      let cbct = [...state.cbctFiles]
      cbct.push({ path: action.value, name: action.name })
      return {
        ...state,
        cbctFiles: cbct
      }
    case actionTypes.NEW_PATIENT_REMOVE_CBCT:
      let newcbctFile = [...state.cbctFiles]
      let modifiedFile = newcbctFile.splice(action.value, 1)
      return {
        ...state,
        cbctFiles: newcbctFile
      }
    case actionTypes.NEW_PATIENT_FILES: {
      let files = state.files.map(imageObj => {
        if (imageObj.id == action.id) {
          if (imageObj?.dataURL) {
            URL.revokeObjectURL(imageObj.dataURL)
            imageObj.dataURL = null
          }
          if (action.value == "upload_later") {
            imageObj['data'] = null;
            imageObj['rotate'] = 0
            imageObj['flipped'] = false
            imageObj['uploaded'] = false;
            imageObj['upload_later'] = !imageObj['upload_later'];
            imageObj['will_not_upload'] = false;
          }
          else if (action.value == "will_not_upload") {
            imageObj['data'] = null;
            imageObj['rotate'] = 0
            imageObj['flipped'] = false
            imageObj['upload_later'] = false
            imageObj['will_not_upload'] = !imageObj['will_not_upload']
            imageObj['upload_later'] = false;
          }
          else if (action.value == "remove") {
            imageObj['data'] = null;
            imageObj['rotate'] = 0
            imageObj['flipped'] = false
            imageObj['uploaded'] = false;
            imageObj['will_not_upload'] = false;
            imageObj['upload_later'] = false;
          }
          else {
            imageObj['rotate'] = 0
            imageObj['flipped'] = false
            imageObj['data'] = action.value;
            imageObj['uploaded'] = true;
            imageObj['upload_later'] = false;
            imageObj['will_not_upload'] = false;
          }
          if (typeof imageObj?.data === 'object' && imageObj?.data !== null && imageObj.category !== 'stl_file') {
            try {
              imageObj.dataURL = URL.createObjectURL(imageObj.data)
            } catch {
              imageObj.data = null
              imageObj.dataURL = null
            }
          }
        }
        return imageObj
      })
      return {
        ...state,
        files
      }
    }
    // p: shortcut skipping "Terrible" callback function linked list
    case actionTypes.NEW_PATIENT_FILE_SHORTCUT:
      return {
        ...state,
        files: state.files.map(file => {
          if (file.id == action.id) {
            if (file.dataURL) {
              URL.revokeObjectURL(file.dataURL)
              file.dataURL = null
            }
            switch (action.subtype) {
              case 'upload_later': {
                file = {
                  ...file,
                  data: null,
                  uploaded: false,
                  upload_later: action.value
                }
                break
              }
              case 'will_not_upload': {
                file = {
                  ...file,
                  data: null,
                  upload_later: false,
                  will_not_upload: !action.value
                }
                break
              }
              case 'remove': {
                file = {
                  ...file,
                  data: null,
                  uploaded: false,
                  upload_later: false,
                  will_not_upload: false
                }
                break
              }
              case 'upload': {
                file = {
                  ...file,
                  data: action.value,
                  uploaded: true,
                  upload_later: false,
                  will_not_upload: false
                }
                break
              }
            }
            if (typeof file?.data === 'object' && file.category !== 'stl_file') {
              file.dataURL = URL.createObjectURL(file.data)
            }
          }
          return file
        })
      }
    case actionTypes.NEW_PATIENT_FILE_INVERT:
      let invertFiles = [...state.files]
      let invertModifiedFiles = invertFiles.filter(imageObj => {
        if (imageObj.id == action.id) {
          imageObj['flipped'] = action.value;
        }
      })
      return {
        ...state,
        files: invertFiles
      }
    case actionTypes.NEW_PATIENT_DATA_SAVE_SUCCESS:
      return {
        ...state,
        saveSuccess: true
      }
    case actionTypes.NEW_PATIENT_DATA_ERROR:
      let errorList = [...state.errors]
      if (!errorList.some(e => e.msg === action.errorMsg.msg) && action.errorMsg.msg !== '') {
        errorList.push(action.errorMsg)
      }
      errorList.forEach(v => {
        if (v.id == action.errorMsg.id) {
          v['valid'] = action.errorMsg.valid
        }
      })
      return {
        ...state,
        errors: errorList
      }
    case actionTypes.NEW_PATIENT_INIT_TEMP:
      if (state.patientPhoto.dataURL) {
        URL.revokeObjectURL(state.patientPhoto.dataURL)
      }
      state.files.forEach(file => {
        if (file.dataURL) {
          URL.revokeObjectURL(state.patientPhoto.dataURL)
        }
      })
      // let initProfile = { ...state.patientPhoto }
      // initProfile['data'] = null;
      // initProfile['uploaded'] = false;
      // initProfile['rotate'] = 0;
      // let initFiles = [...state.files]
      // let initModifiedFiles = initFiles.map(imageObj => {
      //   imageObj['data'] = null;
      //   imageObj['uploaded'] = false;
      //   imageObj['rotate'] = 0;
      //   imageObj['upload_later'] = null;
      //   imageObj['will_not_upload'] = null;
      //   return imageObj
      // })
      // let initTeeth = [...state.teeth]
      // let initModifiedTeeth = initTeeth.map(imageObj => {
      //   imageObj['checked'] = false;
      //   return imageObj
      // })
      return {
        ...JSON.parse(JSON.stringify(initialStateBackup)),
        // impressionType: 'FILE',
        // patientPhoto: initProfile,
        // files: initModifiedFiles,
        // teeth: initModifiedTeeth
      }
    case actionTypes.NEW_PATIENT_TEMP_SAVE_SUCCESS:
      if (state.patientPhoto.dataURL) {
        URL.revokeObjectURL(state.patientPhoto.dataURL)
      }
      state.files.forEach(file => {
        if (file.dataURL) {
          URL.revokeObjectURL(state.patientPhoto.dataURL)
        }
      })
      return {
        ...JSON.parse(JSON.stringify(initialStateBackup)),
        ...action.payload
      }
    case actionTypes.NEW_PATIENT_FILE_ROTATE: {
      let files = state.files.map(imageObj => {
        if (imageObj.id == action.id) {
          imageObj['rotate'] = action.value;
        }
        return imageObj
      })
      return {
        ...state,
        files
      }
    }
    case actionTypes.NEW_PATIENT_QUES_SIMPLE_UPDATE: {
      return {
        ...state,
        [action.payload.attrName]: action.payload.value
      }
    }
    case actionTypes.NEW_PATIENT_QUES_PHONE_UPDATE: {
      return {
        ...state,
        [action.payload.attrName]: action.payload.value.replace(/\D/g, '')
      }
    }
    case actionTypes.NEW_PATIENT_COLOR_PATTERN: {
      return {
        ...state,
        [action.payload.attrName]: action.payload.value
      }
    }
  }
  return state;
};

export default reducer;