import '../../util/array'

import {
  CONFIG_RELOAD,
  CONFIG_UPDATE
} from './actionType'

import { loadLang } from '../lang/lang.action'
import { loadRes } from '../res/res.action'

import axios from '../../axios-default'

let isLoading = false

const configReloaded = payload => ({
  type: CONFIG_RELOAD,
  payload
})

const configUpdate = payload => ({
  type: CONFIG_UPDATE,
  payload
})

export const check = configResLangObj => async dispatch => {
  try {
    if (!configResLangObj.config.isInit) {
      await loadConfig()(dispatch)
    }
    if (!configResLangObj.res.isInit) {
      await loadRes()(dispatch)
    }
    if (!configResLangObj.lang.isInit) {
      await loadLang(localStorage.getItem('lang'))(dispatch)
    }
  } catch (err) {
    console.error(err.message || err)
  }
}

export const updateConfig = (configChanges, userId, countryId, stateProvinceId) => async dispatch => {
  try {
    // if userId, countryId & stateProvinceId are undefined,
    //  it will update default config [only admin, distr]
    await axios.post('config', {
      user_id: userId, // user level config
      coutnry_id: countryId, // country level config
      state_province_id: stateProvinceId,
      config: JSON.stringify(configChanges)
    })
    await dispatch(configUpdate(configChanges))
  } catch (e) {
    console.error(e)
  }
}

export const updateAndReloadConfig = (configChanges, userId, countryId, stateProvinceId) => async dispatch => {
  try {
    // if userId, countryId & stateProvinceId are undefined,
    //  it will update default config [only admin, distr]
    let { data } = await axios.post('config', {
      user_id: userId, // user level config
      coutnry_id: countryId, // country level config
      state_province_id: stateProvinceId,
      config: JSON.stringify(configChanges)
    })
    if (data && data.success) {
      await (loadConfig()(dispatch))
    }
  } catch (e) {
    console.error(e)
  }
}

export const loadConfig = () => async dispatch => {
  if (!isLoading) {
    isLoading = true
    try {
      let { data } = await axios.get('config')
      if (data && data.success) {
        await dispatch(configReloaded(data.config))
        // if (data.config.lang) {
        //   let langObj
        //   if (Array.isArray(data.config.lang) && data.config.lang.length) {
        //     langObj = {
        //       avail: data.config.lang,
        //       default: data.config.lang[0]
        //     }
        //     await dispatch(langConfig(langObj))
        //   } else if (data.config.lang.default && data.config.lang.avail) {
        //     langObj = {
        //       avail: Array.ensureArray(data.config.lang.avail),
        //       default: data.config.lang.default
        //     }
        //     await dispatch(langConfig(langObj))
        //   }
        //   if (langObj) {
        //     loadLang(langObj.default)(dispatch)
        //   }
        // loadLang(localStorage.getItem('lang'))(dispatch)
      }
    } catch (e) {
      console.error(e)
    } finally {
      isLoading = false
    }
  }
}
