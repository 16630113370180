import {
  RES_LOAD, RES_UPDATE
} from './actionType'

const initialState = {
  isInit: false,
  countries: {},
  stateProvinces: {},
  clinics: {},
  groups: {},
  distr_groups: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RES_LOAD:
      return {
        ...initialState,
        isInit: true,
        ...action.payload
      }
    case RES_UPDATE:
      return {
        ...state,
        isInit: true,
        ...action.payload
      }
    default:
      return state
  }
}