import { Button, CircularProgress, Hidden, Typography } from '@material-ui/core/';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import classNames from 'classnames';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import axios from '../axios-default';
import Loader from "../components/Loader";
import { easySnackbar } from '../store/Noti/noti.actions';
import { withLang } from '../util/lang';
import TopBar from '../components/TopBar';
import { getWebAbsPath } from '../util/path';


const styles = theme => ({
  "@global": {
    body: {
      background: "#fdfdfd",
    },
  },
  root: {
    flexGrow: 1,
  },
  main: {
    width: 'auto',
    display: 'block',
    marginLeft: "0px 24px",
    [theme.breakpoints.up(500 + 48)]: {
      width: 500,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    [theme.breakpoints.up(600)]: {
      marginTop: "10%",
      marginBottom: "30px"
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  form: {
    width: '100%',
    marginTop: 8,
    textAlign: "left",
  },
  submit: {
    marginTop: "24px",
  },
  textField: {
    // marginLeft: "8px",
    // marginRight: "8px",
    '& label.Mui-focused': {
      color: 'black',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'black',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey',
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
  },
  dense: {
    marginTop: "16px",
    marginLeft: "0px",
  },
  welcomeTitle: {
    margin: "16px 0px"
  },
  progress: {
    marginTop: "48px"
  },
  login: {
    backgroundColor: `#473a69 !important`,
    // color: Colors.backgroundSecond,
    marginTop: "32px",
    marginBottom: "12px",
    color: "white"
  },
  loginLeft: {
    height: "100vh",
    backgroundPosition: "top center",
    backgroundSize: "cover",
  }
});

const backgroundImage = `url(${getWebAbsPath("/img/Beme4002.jpg")})`

class Forgot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ''
    }
  }

  async submitHandler() {
    //this.props.onAuth( this.state.account, this.state.password);
    try {
      await axios.post('user/forget_password', { email: this.state.email })
      await this.props.enqueSnackbar('Reset password email sent', 'success', true)
      this.props.history.replace('/')
    } catch (err) {
      this.props.enqueSnackbar((err.response && err.response.data && err.response.data.message) || err.message || err, 'error')
    }
  }

  render() {
    const { classes, isLoading } = this.props
    return (
      <Fragment>
        {isLoading ? (
          <Loader />
        ) : (
            <Grid container>
              <TopBar />
              <Hidden smDown>
                <Grid item xs={12} md={6} style={{ backgroundImage: backgroundImage }} className={classes.loginLeft}></Grid>
              </Hidden>
              <Grid item xs={12} md={6} style={{ height: "100vh", marginTop: '15vh' }}>
                <main className={classes.main}>
                  {this.state.isLoggedin ?
                    <div>
                      <Paper className={classes.paper}>
                        <Grid container>
                          <Grid item xs={12}>
                            <h2 style={{ textAlign: "center" }}>You have logged in, welcome back!<br />We will now redirect you to main page!<br />
                              <CircularProgress className={classes.progress} />
                            </h2>
                          </Grid>
                        </Grid>
                      </Paper>
                      {/* <div style={{display:"none"}}>{setTimeout(()=>{ this.props.history.replace('/') }, 1500)}</div> */}
                    </div>
                    :
                    <Paper className={classes.paper} elevation={isWidthUp('sm', this.props.width) ? 2 : 0}>
                      <div>
                        {/* <Grid container style={{ padding: "16px" }}>
                          <Grid item xs={2}>
                          </Grid>
                          <Grid item xs={8} style={{ textAlign: "center", paddingTop: 20, paddingBottom: 20 }}>
                            <img className="loginLogo" alt="CT Logo" src="/img/logo_white.png" style={{ width: "60%" }} />
                            <Typography variant="h5" style={{color: 'white'}}>Reset your password</Typography>
                          </Grid>
                        </Grid> */}


                        <Grid container style={{ padding: "16px 24px 24px" }}>
                          <Grid item xs={12}>
                            {/* <Typography variant="body1" className={classes.welcomeTitle}>
                              {this.get('provideEmail')}
                            </Typography> */}
                            <Typography component="h1" variant="h4" style={{margin: "16px 0px"}}>
                              {this.get('title')}
                            </Typography>
                            <Typography component="h3" variant="subtitle1" align="left" style={{margin: "16px 0px"}}>
                              {this.get('provideEmail')}
                            </Typography>
                            <Divider className="loginHr" />
                            <form className={classes.form}>
                              <p style={{ fontSize: 18, marginBlockEnd: 0 }}>{this.get(':fields.email')}</p>
                              <TextField
                                id="account"
                                className={classNames(classes.textField, classes.dense)}
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                autoFocus
                                value={this.state.email}
                                onChange={({ target }) => this.state.email !== target.value && this.setState({ email: target.value })}
                              />

                              <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.login}
                                onClick={this.submitHandler.bind(this)}
                                style={{ marginBottom: 8 }}
                              >
                                {this.get('recover')}
                              </Button>

                              <Link
                                to="/login"
                                style={{
                                  textDecoration: "none",
                                  color: "black"
                                }}
                              >
                                <Button variant="outlined" style={{ textAlign: "left", border: "black solid 1px", width: "100%" }}>
                                  {this.get(':const.loginInstead')}
                      </Button>
                              </Link>

                              <p style={{ color: "red" }}>{this.props.error}</p>
                            </form>
                          </Grid>
                        </Grid>
                      </div>
                    </Paper>
                  }
                </main>
              </Grid>
            </Grid>
          )}
      </Fragment>
    )
  }
}

const mapStateToProps = store => ({
  lang: store.lang
})

const mapDispatchToProps = dispatch => {
  return {
    enqueSnackbar: (msg, variant, queue=false) => dispatch(easySnackbar(msg, variant, queue))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(withWidth()(withLang(Forgot, 'Forgot')))))