import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { withRouter } from 'react-router-dom'
import axios from '../../axios-default'
import { CircularProgress, Button, Select, MenuItem } from '@material-ui/core/';
import Grid from '@material-ui/core/Grid';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import MUIDataTable from "mui-datatables";
import DateFnsUtils from '@date-io/date-fns';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core'
import QueryString from 'querystring'
import group_by from '../../util/group.js'
import Moment from 'moment';
let columns = null;

const customTheme = createMuiTheme({
  overrides: {
    MuiPickersBasePicker: {
      pickerView: {
        backgroundColor: "white"
      }
    },
    MuiPickersDay: {
      day: {
        color: "light-gray",
        fontFamily: "\"Do Hyeon\", sans-serif",
        backgroundColor: "white",
        borderRadius: "0px",
      },
      container: {
        backgroundColor: "black"
      },
      daySelected: {
        backgroundColor: "",
        color: "light-gray"
      },
      dayDisabled: {
        color: "black",
      },
      current: {
        color: "",
      },
    },
  },
});

class ReportsList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      customerData: [],
      groupBy: ""
    }
    this.columnName = this.columnName.bind(this)
  }
  componentDidMount() {
    this.loadStat()
  }

  columnName() {

    if (this.state.groupBy === 'COUNTRY') {
      columns = [
        {
          name: "country",
          label: "Country",
          options: {
            filter: true,
            sort: true,
          }
        },
        {
          name: "orderTotal",
          label: "Order Total",
          options: {
            filter: true,
            sort: true,
          }
        }
      ]
    }
    else if (this.state.groupBy === 'USER') {
      columns = [
        {
          name: "email",
          label: "User",
          options: {
            filter: true,
            sort: true,
          }
        },
        {
          name: "orderTotal",
          label: "Order Total",
          options: {
            filter: true,
            sort: true,
          }
        }
      ]
    }
    else {

      columns = [
        {
          name: "country",
          label: "Country",
          options: {
            filter: true,
            sort: true,
          }
        },
        {
          name: "group",
          label: "Group",
          options: {
            filter: true,
            sort: true,
          }
        },
        {
          name: "clinic",
          label: "Clinic",
          options: {
            filter: true,
            sort: true,
          }
        },
        {
          name: "orderTotal",
          label: "Order Total",
          options: {
            filter: true,
            sort: true,
          }
        },
        {
          name: "email",
          label: "Email",
          options: {
            filter: true,
            sort: true,
          }
        }
      ]

    }

  }

  componentDidUpdate(prevProps) {

    if (prevProps.startDate.getTime() !== this.props.startDate.getTime() ||
      prevProps.endDate.getTime() !== this.props.endDate.getTime() ||
      prevProps.currentStatus !== this.props.currentStatus
    ) {
      this.loadStat()
    }
  }

  async loadStat() {
    let { startDate, endDate } = this.props

    try {
      let { startDate, endDate, country_id } = this.props
      const query = QueryString.stringify({
        startMonth: startDate.getMonth() + 1,
        startYear: startDate.getFullYear(),
        endMonth: endDate.getMonth() + 1,
        endYear: endDate.getFullYear(),
        case_status: this.props.currentStatus
      })
      let { data } = await axios.get('stats/report?' + query)
      let report = []
      data.report.forEach((v) => {
        var obj = {};

        obj['country'] = v.country_name
        obj['group'] = v.group_name ? v.group_name : 'No Group'
        obj['clinic'] = v.clinic_name ? v.clinic_name : 'No Clinic'
        obj['email'] = v.email ? v.email : 'No Email'
        obj['orderTotal'] = v.count

        report.push(obj)
      });
      this.setState({ customerData: report })
    }
    catch (e) {
      if (e && e.response && e.response.statusText === 'Unauthorized') {
        localStorage.removeItem("auth-token");
        localStorage.removeItem("expirationDate");
        localStorage.removeItem("role");
        this.props.history.replace('/login')
      }
    }
  }

  handleChangeGroupBy(val) {
    this.setState({ groupBy: val })

    let country_name = (val === 'COUNTRY') ? true : false
    let user = (val === 'USER') ? true : false
    let groupByObj = {
      country: country_name,
      group_name: false,
      clinic_name: false,
      email: user
    }
    let group_records = group_by(this.state.customerData, groupByObj)
  }

  handleStart = date => {
    this.props.handleChangeStart(date)
  };

  handleEnd = date => {
    this.props.handleChangeEnd(date)
  };

  handleChangeStatus(value) {
    this.props.handleChangeStatus(value)
  }

  handleDateChange = (date) => {
    this.setState({ selectedDate: date })
  };

  render() {
    this.columnName()
    const options = {
      download: false,
      filterType: "dropdown",
      responsive: "scroll",
      selectableRows: false,
      rowsPerPage: 30,
      rowsPerPageOptions: [15, 30, 50],
      // onRowClick: (rowData) => this.props.history.push("/admin/accounts/00228")
    };

    return (
      <MuiThemeProvider theme={customTheme}>
        <Grid container>
          <Grid item xs={12} md={6}>
            {/* TODO: Date picker */}
            <p>Start Date</p>
            <div style={{ padding: 8, width: "90%", border: "1px solid" }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  // minDate={Moment().subtract(5, 'years').toDate()}
                  maxDate={Moment().add(1, 'month').toDate()}
                  views={["year", "month"]}
                  variant="inline"
                  className="customDatepicker"
                  value={this.props.startDate}
                  onChange={this.handleStart}
                />
              </MuiPickersUtilsProvider>
            </div>
          </Grid>

          <Grid item xs={12} md={6}>
            {/* TODO: Date picker */}
            <p>End Date:</p>
            <div style={{ padding: 8, width: "90%", border: "1px solid", }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  // minDate={Moment().subtract(5, 'years').toDate()}
                  maxDate={Moment().add(1, 'month').toDate()}
                  views={["year", "month"]}
                  variant="inline"
                  className="customDatepicker"
                  value={this.props.endDate}
                  onChange={this.handleEnd}
                />
              </MuiPickersUtilsProvider>
            </div>
          </Grid>

          <Grid item xs={12} md={6}>
            {/* TODO: Case Status */}
            <p>Case Status</p>
            <Select
              value={this.props.currentStatus}
              displayEmpty
              onChange={(event) => this.handleChangeStatus(event.target.value)}
              style={{
                width: "93%",
                border: "1px solid",
                padding: "5px",
                marginBottom: "15px"
              }}
            >{
                this.props.caseStatusOptions.map(v =>
                  <MenuItem value={v.value} key={v.value}>{v.label}</MenuItem>
                )
              }
            </Select>
          </Grid>

          <Grid item xs={12} md={6}>
            <p>Group By</p>
            <Select
              value={this.state.groupBy}
              displayEmpty
              onChange={(event) => this.handleChangeGroupBy(event.target.value)}
              style={{
                width: "93%",
                border: "1px solid",
                padding: "5px",
                marginBottom: "15px"
              }}
            >
              <MenuItem value="" disabled>Please Select Option</MenuItem>
              <MenuItem value="USER">User</MenuItem>
              <MenuItem value="COUNTRY">Country</MenuItem>

            </Select>
          </Grid>



          <Grid item xs={12}>
            <MUIDataTable
              title={"Reports List"}
              data={this.state.customerData}
              columns={columns}
              options={options}
            />
          </Grid>
        </Grid>
      </MuiThemeProvider>
    );
  }
}

ReportsList.propTypes = {
}

export default withRouter(ReportsList)