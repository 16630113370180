import '../../util/string'
import { CircularProgress, Fab, Tooltip, Typography, InputLabel, Select, MenuItem } from '@material-ui/core/';
import Grid from '@material-ui/core/Grid';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import Moment from 'moment';
import MUIDataTable from "mui-datatables";
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useState, useCallback, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import QueryString from 'querystring'
import { withRouter, useHistory } from 'react-router-dom';
import Url from 'url';
import join from 'url-join'
import axios from '../../axios-default';
//Components
import SideNav from '../../components/Admin/SideNav';
import { ApiServerUrl } from '../../config.json';
import { easySnackbar } from '../../store/Noti/noti.actions';
import { updateRes } from '../../store/res/res.action';
import { getFromObj } from '../../util/lang';
import { get } from 'http';
import { Edit as EditIcon, Undo as UndoIcon ,Add as AddIcon, EmojiPeople as EmojiPeopleIcon} from "@material-ui/icons";
import Modal from '../../components/Modal/modal'
import Add from '@material-ui/icons/Add'
import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import DraftsIcon from '@material-ui/icons/Drafts'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import GridOnIcon from '@material-ui/icons/GridOn'
import LastPage from '@material-ui/icons/LastPage'
import MailOutlinedIcon from '@material-ui/icons/MailOutlined'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import ViewListIcon from '@material-ui/icons/ViewList'
import MaterialTable from 'material-table';
import { getWebAbsPath } from '../../util/path';
import TextField from '@material-ui/core/TextField';

const StatusList = {
  DRAFT: 'Draft',
  SUBMITTED: 'Prescription Submitted',
  AWAITING: 'Awaiting More Records{Remark: ?}',
  RECEIVED: 'Confirmed All Materials Received',
  CHANGE_REQUEST: 'Treatment Plan Change Request Received',
  PLAN_UPLOADED: 'Treatment Plan {No.?} Uploaded',
  PLAN_APPROVED: 'Treatment Plan {No.?} Approved',
  PRODUCTION: 'Production In Progress',
  DELIVERED: 'Aligners Set {No.1 ?}-{No.2 ?} Delivered',
  NEXT_SET_REQUEST: 'Request for Next Aligner Set',
  ALL_DELIVERED: 'All Aligners Sets Are Delivered',
  ALIGNER_REQUEST: 'Aligner Request',
  RETAINER_REQUEST: 'Retainer Request',
  REFINEMENT: 'Refinements Prescription Received',
  ENDED: 'Case Ended'
}

const TableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
}

const CaseStyles = makeStyles(theme => ({
  "@global": {
    body: {
      background: "#fdfdfd",
    },
  },
  root: {
    flexGrow: 1,
  },
  rootContainer: {
    padding: 16,
    margin: "8px 0px 0px 238px",
    [theme.breakpoints.between("xs", "sm")]: {
      margin: "12px 0px 0px 0px",
      width: "100%",
    },
    width: "calc(100% - 238px)",
  },
  scanBox: {
    padding: 16,
    margin: "8px 0px"
  },
  textField: {
    // marginLeft: "8px",
    // marginRight: "8px",
    '& label.Mui-focused': {
      color: 'black',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'black',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey',
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
  },
  dateField: {
    width: "100%"
  },
  formControl: {
    width: "100%"
  },
  dense: {
    marginTop: 16,
    marginLeft: 0
  },
  submitBtn: {
    backgroundColor: "#061a2e !important",
    color: "white",
    padding: "8px 24px",
    margin: "12px 0px"
  },
  linkBtn: {
    background: "rgba(89,54,135,1)",
    color: "white",
    padding: "4px 24px",
    fontWeight: 300,
    margin: "16px 0px",
    width: "100%",
    [theme.breakpoints.up('sm')]: {
      padding: "4px 72px",
      width: "unset",
    },
    "&:hover": {
      background: "rgb(77, 74, 142)",
      color: "white"
    }
  },
}))

const Columns = [
  {
    field: 'case_id',
    title: 'Order #',
    searchable: true
  },
  {
    field: 'profile_pic',
    title: 'Profile',
    searchable: false,
    render: ({ profile_pic, gender }) => (
      <img
        alt={gender}
        style={{ width: 50 }}
        src={profile_pic}
        onError={e => {
          let u = Url.parse(e.target.src)
          e.target.src = (
            (u.pathname === '/' || u.pathname.startsWith('/img')
              ? ''
              : (
                gender === 'M'
                  ? getWebAbsPath('/img/maleIcon.png')
                  : (gender === 'F'? getWebAbsPath('/img/femaleIcon.png') : getWebAbsPath('/img/otherIcon.png'))
              )
            )
          )
        }}
      />
    )
  },
  {
    field: 'case_status',
    title: 'Status',
    searchable: true
  },
  {
    field: 'created_by',
    title: 'Doctor ID',
    searchable: true
  },
  {
    field: 'first_name',
    title: 'First Name',
    searchable: true
  },
  {
    field: 'last_name',
    title: 'Last Name',
    searchable: true
  },
  {
    field: 'approval_date',
    title: 'Approved',
    sorting: false,
    render: ({ approval_date }) => (
      <div style={{ display: "flex", justifyContent: 'center', margin: '0px 4px', width: 40, height: 40 }}>
        {approval_date ? <CheckIcon /> : <CloseIcon />}
      </div>
    )
  },
  {
    field: "case_type",
    title: "Treatment Option",
    searchable: true
  },
  {
    field: "product_name",
    title: "Product Name",
    searchable: true
  },
  {
    field: "created_date",
    title: "Creation Date",
  }
]

export default function Cases(props) {
  const classes = CaseStyles(),
        dispatch = useDispatch(),
        history = useHistory()

  const [loading, setLoading] = useState(false),
        lastPageSize = useRef(),
        lastPageNo = useRef(0),
        [total, setTotal] = useState(0),
        [editCaseType, setEditCaseType] = useState(""),
        [editCaseTypeTo, setEditCaseTypeTo] = useState(""),
        [editCaseTypeCaseId, setEditCaseTypeCaseId] = useState(0),
        [changeCaseTypeModalOpen, setChangeCaseTypeModalOpen] = useState(false),
        [createdByError, setCreatedByError] = useState(""),
        [changeChangeCreatedBy, setChangeCreatedBy] = useState(0),
        [changeCreatedByDoctorId, setChangeCreatedByDoctorId] = useState(0),
        [changeCreatedByCaseId, setChangeCreatedByCaseId] = useState(0),
        [changeCreatedByModalOpen, setChangeCreatedByModalOpen] = useState(false)

  const createdByFieldRef= useRef(null)
  const changeCaseTypeRef= useRef(null)
  useEffect(() => {
    window.scroll({top: 0, left: 0, behavior: 'smooth' })
  }, [])

  const handleCaseTypeChange = (e) =>{
    const caseTypevalue = e.target.value
    setEditCaseTypeTo(caseTypevalue)
    changeCaseTypeRef.current=caseTypevalue
    console.log(changeCaseTypeRef.current)
  }

  const handleCaseTypeSave = useCallback(async () => {
    const role = localStorage.getItem('role') && JSON.parse(localStorage.getItem('role'))

    if (role === 'SADMIN') {
      if (window.confirm('Are you going to change this case type?') ) {
        try {const { value: changedCaseType } =changeCaseTypeRef.current
          const { data: result }=await axios.post(`case/${editCaseTypeCaseId}/${editCaseType}/${changedCaseType}/changetype`)
          if (result.success) {
            await dispatch(easySnackbar('Success', 'success', true))
            reloadPage()
          } else {
            console.error(result.message)
            dispatch(easySnackbar(result.message, 'error'))
          }
        } catch (err) {
          console.error(err)
          dispatch(easySnackbar(
            getFromObj(err, 'response.data.message') || err.message || err,
            'error'
          ))
        }
      }
    }
  }, [editCaseType,editCaseTypeCaseId])

  const reloadPage = () => {
    var historyLoc = history.location.pathname
    history.push('/blank')
    history.replace(historyLoc)
  }
  const handleChangeCreatedBySave = async()  => {
    const { value: createdBy } = createdByFieldRef.current
    if (createdBy.isBlank()) {
      //setAuthError("Input the fields are required")
      setCreatedByError("Doctor is required")
    } else {
      setCreatedByError("")

      const role = localStorage.getItem('role') && JSON.parse(localStorage.getItem('role'))

      if (role === 'SADMIN') {
        if (window.confirm('Are you going to change this doctor?')) {
          try {
            const { data: result } = await axios.post(`case/${changeCreatedByCaseId}/${changeCreatedByDoctorId}/${createdByFieldRef.current.value}/changeCreatedBy`)
            if (result.success) {
              await dispatch(easySnackbar('Success', 'success', true))
              reloadPage()
            } else {
              console.error(result.message)
              dispatch(easySnackbar(result.message, 'error'))
            }
          } catch (err) {
            console.error(err)
            dispatch(easySnackbar(
              getFromObj(err, 'response.data.message') || err.message || err,
              'error'
            ))
          }
        }
      }
    }
  }

  const handleEnter = ({ key }) => key == "Enter" && handleChangeCreatedBySave()

  const Actions = [
    row=>({
      icon: () => (['SADMIN'].includes(localStorage.getItem('role')&& JSON.parse(localStorage.getItem('role'))  && JSON.parse(localStorage.getItem('role')))?<EmojiPeopleIcon />:<></>),
      tooltip: 'Change Doctor ID',
      onClick: (_, data) => {
        setChangeCreatedByCaseId(data.case_id)
        setChangeCreatedByDoctorId(data.created_by)
        setChangeCreatedByModalOpen(true)
      }
    }),
    row => (row.org_case_status!=="NEXT_SET_REQUEST" && row.org_case_status!=="PRODUCTION" ) ||
      row.case_type=="RETAINER" ||
      !['SADMIN'].includes(localStorage.getItem('role') && JSON.parse(localStorage.getItem('role')))
      ? null :
    ({
      icon: () => <UndoIcon/>,
      tooltip: 'Rollback Flow',
      onClick: async (_, data) => {
        if (['SADMIN'].includes(localStorage.getItem('role') && JSON.parse(localStorage.getItem('role')))) {
          if (window.confirm('Are you going to rollback this case?')) {
            try {
              const { data: result } = await axios.post(`case/${row.case_id}/${row.org_case_status}/rollback`)
              if (result.success) {
                await dispatch(easySnackbar('Success', 'success', true))
                reloadPage()
              } else {
                console.error(result.message)
                dispatch(easySnackbar(result.message, 'error'))
              }
            } catch (err) {
              console.error(err)
              dispatch(easySnackbar(
                getFromObj(err, 'response.data.message') || err.message || err,
                'error'
              ))
            }
          }
        }
      }
    }),
    row => ({
      icon: () => (
        row.cancel_flag?
        <Typography variant="body1">Cancelled</Typography> :
        <Fab
          size="small"
          aria-label="add"
          style={{
            backgroundColor: "#ff0000",
            color: "white",
            margin: "0px 4px"
          }}
        >
          <CancelIcon />
        </Fab>
      ),
      tooltip: row.cancel_flag? 'Cancelled' : 'Cancel',
      hidden: false,
      onClick: async () => {
        const role = localStorage.getItem('role') && JSON.parse(localStorage.getItem('role'))
        if (row.cancel_flag) {
          if (role === 'SADMIN') {
            if (window.confirm('Are you going to uncancel this case?')) {
              try {
                const { data: result } = await axios.post(`case/${row.case_id}/uncancel`)
                if (result.success) {
                  await dispatch(easySnackbar('Success', 'success', true))
                  reloadPage()
                } else {
                  console.error(result.message)
                  dispatch(easySnackbar(result.message, 'error'))
                }
              } catch (err) {
                console.error(err)
                dispatch(easySnackbar(
                  getFromObj(err, 'response.data.message') || err.message || err,
                  'error'
                ))
              }
            }
          }
        } else {
          if (window.confirm('Are you going to cancel this case?')) {
            try {
            const { data: result } = await axios.post(`case/${row.case_id}/cancelled`)
              if (result.success) {
                await dispatch(easySnackbar('Success', 'success', true))
                // setLoading(true)
                reloadPage()
              } else {
                console.error(result.message)
                dispatch(easySnackbar(result.message, 'error'))
              }
            } catch (err) {
              console.error(err)
              dispatch(easySnackbar(
                getFromObj(err, 'response.data.message') || err.message || err,
                'error'
              ))
            }
          }
        }
      }
    })
  ]

  const dataGetter = async query => {
    const { pageSize, page: pageNo, search, orderBy, orderDirection } = query
    try {
      if (orderBy && orderBy.field.endsWith('_label')) {
        orderBy.field = orderBy.field.slice(0, -6)
      }
      const { data } = (await axios.get(`case?${QueryString.encode({
        searchStr: typeof search !== 'string' || String.isBlank(search)? undefined : search,
        sortBy: orderBy && orderBy.field,
        sortDir: orderDirection,
        statusFilter: 'ALL',
        pageSize,
        pageNo,
        all: 1
      })}`))
      lastPageSize.current= pageSize
      lastPageNo.current = pageNo
      setLoading(false)
      return {
        data: data.case.filter(c => c.case_id >= 1).map(c => {

          return {
            case_id: c.case_id,
            profile_pic: c.profile_pic? join(ApiServerUrl, c.profile_pic) : '',
            gender: c.gender,
            first_name: c.first_name,
            last_name: c.last_name,
            approval_date: c.approval_date,
            case_status: StatusList[c.case_status],
            org_case_status: c.case_status,
            created_by: c.created_by,
            product_name: c.product_name,
            case_type: c.case_type,
            created_date: Moment(c.created_date).format('DD MMM YYYY'),
            cancel_flag: c.cancel_flag
          }
        }),
        page: pageNo ?? 0,
        totalCount: data.total ?? 0
      }
    } catch (err) {
      console.error(err)
      if (err.response && err.response.statusText === 'Unauthorized') {
        localStorage.removeItem("auth-token");
        localStorage.removeItem("expirationDate");
        localStorage.removeItem("role");
        history.replace('/login')
      }
    }
  }

  return (
    <div>
      <SideNav />
      <Grid container className={classes.rootContainer}>

      </Grid>
      <Grid container className={classes.rootContainer} style={{ marginTop: 0 }}>
        {!loading ?
          <div style={{ display: "contents" }}>
            <Grid container>

              <Grid item xs={12}
                style={{
                  padding: 8
                }}>
                <h1
                  style={{
                    marginBlockEnd: 0
                  }}
                >
                  Cases
                </h1>

                <hr />
              </Grid>

              <Grid item xs={12}
                style={{
                  padding: 8,
                  textAlign: "center"
                }}>
                <img style={{ width: "100%", maxWidth: 800 }} src={getWebAbsPath("/img/account1.png")} />
              </Grid>

              <Grid item xs={12} style={{ padding: 8 }}>
                <MaterialTable
                  title=''
                  columns={Columns}
                  actions={['SADMIN', 'ADMIN'].includes(localStorage.getItem('role') && JSON.parse(localStorage.getItem('role'))) && Actions}
                  data={dataGetter}
                  icons={TableIcons}
                  options={{
                    initialPage: 0,
                    emptyRowsWhenPaging: false,
                    exportButton: true,
                    exportAllData: true,
                    debounceInterval: 700,
                    columnsButton: true,
                    actionsColumnIndex: -1,
                    pageSize: 30,
                    pageSizeOptions: [15, 30, 50],
                    minBodyHeight: 700,
                    maxBodyHeight: 1000,
                  }}
                />

                <Modal
                  className="modal"
                  modal='CaseType'
                  title="Change Case Type"
                  open={changeCaseTypeModalOpen}
                  handleClose={() => setChangeCaseTypeModalOpen(false)}
                  handleDataSave={() => handleCaseTypeSave()}
                  //modalError={this.state.modalError}
                  submitButton={true}
                >
                  <Grid item xs={12} style={{ padding: 4 }}>

                   <InputLabel >Case ID: {editCaseTypeCaseId}</InputLabel>
                   <InputLabel id="case_type">Case Type</InputLabel>
                   <Select
                     labelId="demo-simple-select-label"
                     id="demo-simple-select"
                     value={editCaseTypeTo}
                     label="Age"
                     onChange={ handleCaseTypeChange }
                     inputRef={changeCaseTypeRef}
                   >

                     <MenuItem value="SIMPLE">Simple</MenuItem>
                     <MenuItem value="COMPLETE">Complete</MenuItem>
                     <MenuItem value="TOUCH_UP">Touch Up</MenuItem>
                     <MenuItem value="TOUCHUP_PLUS">Touch Up Plus</MenuItem>
                   </Select>
                  </Grid>
                </Modal>
                <Modal
                  className="modal"
                  modal='CreatedBy'
                  title="Change Created By"
                  open={changeCreatedByModalOpen}
                  handleClose={() => setChangeCreatedByModalOpen(false)}
                  handleDataSave={() => handleChangeCreatedBySave()}
                  //modalError={this.state.modalError}
                  submitButton={true}
                >
                  <Grid item xs={12} style={{ padding: 4 }}>

                   <InputLabel >  Case ID: {changeCreatedByCaseId}</InputLabel>
                   <InputLabel id="case_type">Doctor ID</InputLabel>
                   <TextField
                     id="created_by"
                     //className={classNames(classes.textField)}
                     margin="dense"
                     variant="outlined"
                     inputRef={createdByFieldRef}
                     fullWidth
                     autoFocus
                     onKeyDown={handleEnter}
                     errorText={createdByError}
                   />
                  </Grid>
                </Modal>
              </Grid>

            </Grid>

          </div> :
          <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress style={{ color: "rgb(6,26,46)" }} />
          </Grid>}
      </Grid>
    </div>
  )
}
