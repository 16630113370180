import * as React from "react"
import ReactToPrint from "react-to-print"
import { Button, Grid } from "@material-ui/core"
import Moment from "moment/moment"
import style from "./PrintOrder.module.css"
import { retainer_colors } from '../../color_pattern'

// import { ComponentToPrint } from "../ComponentToPrint";

export class PrintOrder extends React.PureComponent {
    componentRef = null

    constructor(props) {
        super(props)

        this.state = {
            display: "none"
        }
    }

    handleAfterPrint = () => {
        // console.log("`onAfterPrint` called"); // tslint:disable-line no-console
    }

    handleBeforePrint = () => {
        // console.log("`onBeforePrint` called"); // tslint:disable-line no-console
    }

    handleOnBeforeGetContent = () => {
        // console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console
    }

    setComponentRef = (ref) => {
        this.componentRef = ref
    }

    reactToPrintContent = () => {
        return this.componentRef
    }

    reactToPrintTrigger = () => {
        return <Button style={{ float: "right", marginTop: "5px" }} variant="contained">Print</Button>
    }

    render() {
        Moment.locale("en")

        let retainer_order = [], retainer_orders = [], first_row_style = ""
        if (this.props.caseRetainer) {
            for (let i = 0; i < this.props.caseRetainer.retainer_pairs; i++) {
                // 1st page can show 2 retainers, other page can show 3 retainers
                if ((i - 2) % 3 == 0) {
                    retainer_orders.push(retainer_order)
                    retainer_order = []
                    first_row_style = " " + style.formFirst
                }
                let img_upper = '', img_lower = ''
                if (this.props.caseRetainer[`retainer_color${i}_upper`]) {
                    img_upper = `/img/color-pattern/${this.props.caseRetainer[`retainer_color${i}_upper`]}.${retainer_colors.find(c => c.value == this.props.caseRetainer[`retainer_color${i}_upper`]).extension}`
                }
                if (this.props.caseRetainer[`retainer_color${i}_lower`]) {
                    img_lower = `/img/color-pattern/${this.props.caseRetainer[`retainer_color${i}_lower`]}.${retainer_colors.find(c => c.value == this.props.caseRetainer[`retainer_color${i}_lower`]).extension}`
                }
                retainer_order.push(
                    <Grid container className={style.form + first_row_style}>
                        <Grid item xs={12} className={style.question}>
                            Retainer {i + 1}
                        </Grid>

                        <Grid item xs={3} className={style.question}>
                            Type:
                        </Grid>
                        <Grid item xs={9} className={style.answer}>
                            {String.toCapitalLetter(this.props.caseRetainer[`retainer_type${i}`])}
                        </Grid>

                        {this.props.caseRetainer[`retainer_type${i}`] == "fashion" && <>
                            <Grid item xs={4}>
                                <div className={style.img} style={{ backgroundImage: `url('${img_upper}')` }}></div>
                                Upper Jaw Color
                            </Grid>

                            <Grid item xs={4}>
                                <div className={style.img} style={{ backgroundImage: `url('${img_lower}')` }}></div>
                                Lower Jaw Color
                            </Grid>

                            <Grid item xs={4}>
                                <div className={style.imgPos} style={{ backgroundImage: `url('/img/color-pattern/${this.props.caseRetainer[`retainer_position${i}`]}.png')` }}></div>
                                Position
                            </Grid>
                        </>}
                    </Grid>
                )
                first_row_style = ""
            }
            if (retainer_order) {
                retainer_orders.push(retainer_order)
            }
        }

        return this.props.retainerOrder && this.props.caseRetainer && <>
            <ReactToPrint
                content={this.reactToPrintContent}
                documentTitle="PrintOrder"
                onAfterPrint={this.handleAfterPrint}
                onBeforeGetContent={this.handleOnBeforeGetContent}
                onBeforePrint={this.handleBeforePrint}
                removeAfterPrint
                trigger={this.reactToPrintTrigger}
            />
            <div className={style.hidden}>
                <div ref={this.setComponentRef}>
                    <div className={style.page}>
                        <img className={style.imgLogo} src="/img/BeMe_Logo_Final.png" />
                        <h2 className={style.heading}>RETAINERS ORDER</h2>
                        <Grid container className={style.form + " " + style.formFirst}>
                            <Grid item xs={3} className={style.question}>
                                Order Date:
                            </Grid>
                            <Grid item xs={9} className={style.answer}>
                                {Moment(this.props.caseRetainer.created_date).format('DD MMMM YYYY')}
                            </Grid>

                            <Grid item xs={3} className={style.question}>
                                Case ID:
                            </Grid>
                            <Grid item xs={9} className={style.answer}>
                                {this.props.caseRetainer.case_id}
                            </Grid>

                            <Grid item xs={3} className={style.question}>
                                Patient Name:
                            </Grid>
                            <Grid item xs={9} className={style.answer}>
                                {this.props.retainerOrder.patientName}
                            </Grid>

                            <Grid item xs={3} className={style.question}>
                                Dentist Name:
                            </Grid>
                            <Grid item xs={9} className={style.answer}>
                                {this.props.retainerOrder.dentistName}
                            </Grid>

                            <Grid item xs={3} className={style.question}>
                                Recipient Name:
                            </Grid>
                            <Grid item xs={9} className={style.answer}>
                                {this.props.retainerOrder.recipientName}
                            </Grid>

                            <Grid item xs={3} className={style.question}>
                                Phone Number:
                            </Grid>
                            <Grid item xs={9} className={style.answer}>
                                {this.props.retainerOrder.phoneNumber}
                            </Grid>

                            <Grid item xs={3} className={style.question}>
                                Delivery Address:
                            </Grid>
                            <Grid item xs={9} className={style.answer}>
                                {this.props.retainerOrder.deliveryAddress}
                            </Grid>
                        </Grid>
                        <Grid container className={style.form}>
                            <Grid item xs={12} className={style.question}>
                                Ordered Items:
                            </Grid>
                            
                            <Grid item xs={3} className={style.question}>
                                Product code
                            </Grid>
                            <Grid item xs={9} className={style.answer}>
                                BM3021
                            </Grid>
                            
                            <Grid item xs={3} className={style.question}>
                                Product name
                            </Grid>
                            <Grid item xs={9} className={style.answer}>
                                BeMe Retainers
                            </Grid>
                            
                            <Grid item xs={3} className={style.question}>
                                Quantity and details
                            </Grid>
                            <Grid item xs={9} className={style.answer}>
                                See picture below
                            </Grid>
                        </Grid>
                    {retainer_orders.shift()}
                    </div>
                    {retainer_orders.length ? retainer_orders.map(retainer_order => {
                        return (<>
                            <div style={{pageBreakAfter: "always"}}></div>
                            <div className={style.page}>
                                {retainer_order}
                            </div>
                        </>)
                    }) : ''}
                </div>
            </div>
        </>
    }
}
