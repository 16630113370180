import { Button, Grid, IconButton, Input, InputAdornment, Paper, Typography, withStyles } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import FormatSizeIcon from '@material-ui/icons/FormatSize';
import SendIcon from '@material-ui/icons/Send';
// import VisibilityIcon from '@material-ui/icons/Visibility';
// import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import DraftsIcon from '@material-ui/icons/Drafts';
import Moment from 'moment/moment';
import join from 'url-join'
import React, { Component } from 'react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { WsUrl } from '../../config.json'
import Url from 'url';
import axios from '../../axios-default';
import { sendMessage2ServiceWorker } from '../../util/service_worker'
import { ApiServerUrl } from '../../config.json';
import { withLang } from '../../util/lang';
import '../../util/string';
import DragAndDrop from '../NewPatient/Photos/DragAndDrop';
import style from './chat.module.css';
import { easySnackbar } from '../../store/Noti/noti.actions';
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import ChatIcon from "@material-ui/icons/Chat";
import AssessmentIcon from "@material-ui/icons/Assessment";
import LockIcon from "@material-ui/icons/Lock";
import CollectionsBookmarkIcon from "@material-ui/icons/CollectionsBookmark";
import Tooltip from '@material-ui/core/Tooltip';
import Badge from '@material-ui/core/Badge';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import CircularProgress from '@material-ui/core/CircularProgress';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';
import PanToolIcon from '@material-ui/icons/PanTool';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import BurstModeIcon from '@material-ui/icons/BurstMode';
import { DoubleArrow } from '@material-ui/icons';
import { getWebAbsPath } from '../../util/path';



const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
  chatBase: {
    padding: "8px 12px",
    borderRadius: "5px",
    color: "white",
    width: "fit-content",
    maxWidth: "70%",
    margin: "8px 0px"
  },
  image: {
    backgroundColor: "#e6e6e6"
  },
  system: {
    backgroundColor: "black"
  },
  chatBgOther: {
    background: "linear-gradient(34deg, rgb(120, 92, 191) 39%, rgb(137, 64, 167) 100%) !important"
  },
  chatBgSelf: {
    background: "#9c9c9c !important"
  },
  progress: {
    margin: theme.spacing.unit * 2,
  },
  hideTabs: {
    display: 'none'
  },
  exitFullScreen: {
    display: 'none'
  },
  positioned: {
    position: 'absolute',
    top: '0%',
    right: '30px',
    minWidth: '433px',
    backgroundColor: '#fff',
    border: '2px dashed lightgray'
  },
  appbarStyle: {
    backgroundColor: '#323639'
  },
})

function TabContainer(props) {
  return <Typography component="div">{props.children}</Typography>;
}

function TabContainerC(props) {
  return <Tab {...props} style={{ minWidth: "40px", fontSize: "xx-small", color: '#eee' }} />;
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class Chat extends Component {

  constructor(props) {
    super(props);
    this.state = {
      chatRoom: 0,
      message: "",
      messages: [],
      chatRoomMessags: [],
      privateChatMessages: [],
      response: "",
      textFontSize: "small",
      case: null,
      allMessages: [],
      loadingMessages: true,
      privateMessage: false,
      unreadMessageCount: 0,
      chatRoomUnreadMessagsCount: 0,
      privateChatUnreadMessagesCount: 0,
      opendMarkReadConfirm: false,
      currentUserId: parseInt(JSON.parse(localStorage.getItem('auth-token'))),
      currentDistrGroupId: parseInt(JSON.parse(localStorage.getItem('distr_group_id'))),
      currentAdminGroupId: parseInt(JSON.parse(localStorage.getItem('admin_group_id'))),
      currentUserRole: JSON.parse(localStorage.getItem('role')),
      lastSeen: undefined,
      privateLastSeen: undefined
    };
    this.isStopped = false
    this.retryCount = 0
    this.msgContainer = React.createRef()
    this.setMessage = this.setMessage.bind(this)
    this.handleDrop = this.handleDrop.bind(this)
    this.markAsRead = this.markAsRead.bind(this)
    this.handleChange = this.handleChange.bind(this);
  }

  changeFontSize() {
    let fontSizes = ["small", "medium", "large"]
    let currentSize = fontSizes.indexOf(this.state.textFontSize)
    currentSize == 2 ? this.setState({ textFontSize: fontSizes[0] }) : this.setState({ textFontSize: fontSizes[currentSize + 1] })
  }

  async componentDidMount() {
    this.url = join(WsUrl, this.props.caseId.toString())
    this.socket = new WebSocket(this.url);
    this.setupWebsocket(this.socket)

    window.addEventListener('focus', this.onFocus)
    window.addEventListener('blur', this.onBlur)

    if (navigator.serviceWorker && navigator.serviceWorker.controller) {
      navigator.serviceWorker.addEventListener('message', this.onServiceWorkerMessage)
      navigator.serviceWorker.controller.postMessage({
        type: 'load',
        case_id: parseInt(this.props.caseId)
      })
    }

    try {
      const { data } = await axios.get(`case/${this.props.caseId}`)
      this.setState({ case: data.case[0] })
    } catch (err) {
      if (err.response && err.response.statusText === 'Unauthorized') {
        localStorage.removeItem("auth-token");
        localStorage.removeItem("expirationDate");
        localStorage.removeItem("role");
        this.props.history.replace('/login')
      }
    }
  }

  componentWillUnmount() {
    this.isStopped = true
  }

  onFocus = _ => {
    sendMessage2ServiceWorker({
      type: 'focus',
      case_id: parseInt(this.props.caseId)
    })
  }

  onBlur = _ => {
    sendMessage2ServiceWorker({
      type: 'blur',
      case_id: parseInt(this.props.caseId)
    })
  }

  async componentWillMount() {
    if (navigator.serviceWorker) {
      navigator.serviceWorker.removeEventListener('message', this.onServiceWorkerMessage)
      sendMessage2ServiceWorker({
        type: 'unload',
        case_id: parseInt(this.props.caseId)
      })
      window.removeEventListener('focus', this.onFocus)
      window.removeEventListener('blur', this.onBlur)
    }
  }

  onServiceWorkerMessage = event => {
    console.log('From service worker: ', event.data)
  }

  setupWebsocket(socket) {
    if (++this.retryCount > 50) {
      this.props.enqueueSnackbar('Chatroom disconnected. Please try again later', 'error')
      return
    }
    socket.onerror = (err) => {
      console.error(err)
      if (![socket.CONNECTING, socket.OPEN].includes(socket.readyState)) {
        this.setState({ loadingMessages: true });
        if (!this.retryTimer) {
          this.retryTimer = setTimeout(() => {
            try {
              this.socket.onclose = this.socket.onerror = this.socket.onmessage = null
              this.socket.close()
            } finally { }
            if (!this.isStopped) {
              let socket = new WebSocket(this.url)
              this.setupWebsocket(socket)
              this.socket = socket
              this.retryTimer = null
            }
          }, 5000)
        }
      }
    }

    socket.onopen = () => {
      console.info('connected')
      this.setState({
        allMessages: []
      }, () => this.reloadChatroom())
    }

    socket.onmessage = function ({ data: dataStr }) {
      this.setState({ loadingMessages: false });
      let data = JSON.parse(dataStr)
      if (data.success) {
        this.updateMsg(data.chat)
      } else {
        console.error(data.message)
      }
    }.bind(this);

    socket.onclose = () => {
      console.info('disconnected')
      if (![socket.CONNECTING, socket.OPEN].includes(socket.readyState)) {
        this.setState({ loadingMessages: true });
        if (!this.retryTimer) {
          this.retryTimer = setTimeout(() => {
            try {
              this.socket.onclose = this.socket.onerror = this.socket.onmessage = null
              this.socket.close()
            } finally { }
            if (!this.isStopped) {
              let socket = new WebSocket(this.url)
              this.setupWebsocket(socket)
              this.socket = socket
              this.retryTimer = null
            }
          }, 5000)
        }
      }
    }
  }

  reloadChatroom() {
    ;[0, 3].forEach(room => {
      const { visibleMsgs, lastSeen } = this.state.allMessages.reduce(({ visibleMsgs, lastSeen }, msg) => {
        if (parseInt(msg.room) === parseInt(room) && !['SEEN', 'DELETE'].includes(msg.type)) {
          visibleMsgs.push(msg)
        } else if (
          msg.type === 'SEEN'
          && parseInt(msg.room) === room
          && (parseInt(msg.room) !== 0 || (
            (parseInt(this.state.currentAdminGroupId) && !msg.user_id && !msg.distr_group_id && parseInt(msg.admin_group_id) === parseInt(this.state.currentAdminGroupId))
            || (parseInt(this.state.currentDistrGroupId) && !msg.user_id && !msg.admin_group_id && parseInt(msg.distr_group_id) === parseInt(this.state.currentDistrGroupId))
            || (!this.state.currentDistrGroupId && !this.state.currentAdminGroupId && !msg.distr_group_id && !msg.admin_group_id && parseInt(msg.user_id) === parseInt(this.state.currentUserId))
          ))
          && (parseInt(msg.room) !== 3 || (
            parseInt(msg.user_id) === parseInt(this.state.currentUserId)
          ))
          && (!lastSeen || (msg.last_seen_date && new Date(msg.last_seen_date) > lastSeen))
        ) {
          lastSeen = new Date(msg.last_seen_date)
        }
        return { visibleMsgs, lastSeen }
      }, { visibleMsgs: [], lastSeen: undefined })
      switch (room) {
        case 0: {
          const unseenCount = visibleMsgs.filter(({ type, sender_id, sender_distr_group_id, sender_admin_group_id, sent_date }) =>
            type !== 'SYSTEM'
            && parseInt(sender_id) !== parseInt(this.state.currentUserId)
            && (!sender_distr_group_id || parseInt(sender_distr_group_id) !== parseInt(this.state.currentDistrGroupId))
            && (!sender_admin_group_id || parseInt(sender_admin_group_id) !== parseInt(this.state.currentAdminGroupId))
            && (!lastSeen || new Date(sent_date) > lastSeen)
          ).length
          this.setState({
            chatRoomMessags: visibleMsgs,
            chatRoomUnreadMessagsCount: unseenCount,
            lastSeen
          })
          break
        }
        case 3: {
          const unseenCount = visibleMsgs.filter(({ type, sender_id, sent_date }) =>
            type !== 'SYSTEM'
            && parseInt(sender_id) !== parseInt(this.state.currentUserId)
            && (!lastSeen || new Date(sent_date) > lastSeen)
          ).length
          this.setState({
            privateChatMessages: visibleMsgs,
            privateChatUnreadMessagesCount: unseenCount,
            privateLastSeen: lastSeen
          })
          break
        }
      }
    })
  }

  updateMsg(incomingMsgs) {
    incomingMsgs = Array.ensureArray(incomingMsgs)
    const { msgs, deletedChatIds } = incomingMsgs.reduce(({ msgs, deletedChatIds }, msg) => {
      if (msg.type === 'DELETE') {
        deletedChatIds.push(parseInt(msg.data))
      } else {
        msgs.push(msg)
      }
      return { msgs, deletedChatIds }
    }, { msgs: [], deletedChatIds: [] })
    // const curMsgs = msgs.filter(msg => parseInt(msg.room) === parseInt(this.state.chatRoom) && msg.type !== 'SEEN')
    // const lastSeen = msgs.reduce((lastSeen, msg) => {
    //   if (
    //     msg.type === 'SEEN'
    //     // && parseInt(msg.room) === parseInt(this.state.chatRoom)
    //     && (parseInt(msg.room) !== 0 || (
    //       parseInt(msg.user_id) === parseInt(this.state.currentUserId)
    //       || parseInt(msg.distr_group_id) === parseInt(this.state.currentDistrGroupId)
    //       || parseInt(msg.admin_group_id) === parseInt(this.state.currentAdminGroupId)
    //     ))
    //     && (parseInt(msg.room) !== 3 || (
    //       parseInt(msg.user_id) === parseInt(this.state.currentUserId)
    //     ))
    //     && (!lastSeen || new Date(msg.last_seen_date) > lastSeen)
    //   ) {
    //     return new Date(msg.last_seen_date)
    //   }
    //   return lastSeen
    // }, undefined) ?? (msg.room !== 3? this.state.lastSeen : this.state.privateLastSeen)
    let { allMessages, chatRoomMessags, privateChatMessages, chatRoomUnreadMessagsCount, privateChatUnreadMessagesCount } = this.state
    if (deletedChatIds.length) {
      allMessages = allMessages.filter(msg => !deletedChatIds.includes(parseInt(msg.chat_id)))
    }
    allMessages = allMessages.concat(msgs)
      ;[0, 3].forEach(room => {
        const curMsgs = msgs.filter(msg => parseInt(msg.room) === parseInt(room) && msg.type !== 'SEEN')
        const lastSeen = msgs.reduce((lastSeen, msg) => {
          if (
            msg.type === 'SEEN'
            && parseInt(msg.room) === room
            && (parseInt(msg.room) !== 0 || (
              (parseInt(this.state.currentAdminGroupId) && !msg.user_id && !msg.distr_group_id && parseInt(msg.admin_group_id) === parseInt(this.state.currentAdminGroupId))
              || (parseInt(this.state.currentDistrGroupId) && !msg.user_id && !msg.admin_group_id && parseInt(msg.distr_group_id) === parseInt(this.state.currentDistrGroupId))
              || (!this.state.currentDistrGroupId && !this.state.currentAdminGroupId && !msg.distr_group_id && !msg.admin_group_id && parseInt(msg.user_id) === parseInt(this.state.currentUserId))
            ))
            && (parseInt(msg.room) !== 3 || (
              parseInt(msg.user_id) === parseInt(this.state.currentUserId)
            ))
            && (!lastSeen || (msg.last_seen_date && new Date(msg.last_seen_date) > lastSeen))
          ) {
            return new Date(msg.last_seen_date)
          }
          return lastSeen
        }, undefined) ?? (room !== 3 ? this.state.lastSeen : this.state.privateLastSeen)
        switch (room) {
          case 0: {
            if (deletedChatIds.length) {
              chatRoomMessags = chatRoomMessags.filter(msg => !deletedChatIds.includes(parseInt(msg.chat_id)))
            }
            chatRoomMessags = chatRoomMessags.concat(curMsgs)
            if (lastSeen || deletedChatIds.length) {
              chatRoomUnreadMessagsCount = chatRoomMessags.filter(({
                type, sender_id, sender_distr_group_id, sender_admin_group_id, sent_date
              }) =>
                type !== 'SYSTEM'
                && parseInt(sender_id) !== parseInt(this.state.currentUserId)
                && (!sender_distr_group_id || parseInt(sender_distr_group_id) !== parseInt(this.state.currentDistrGroupId))
                && (!sender_admin_group_id || parseInt(sender_admin_group_id) !== parseInt(this.state.currentAdminGroupId))
                && (!lastSeen || new Date(sent_date) > lastSeen)
              ).length
            } else {
              chatRoomUnreadMessagsCount += curMsgs.filter(({ type, sender_id, sender_distr_group_id, sender_admin_group_id }) =>
                type !== 'SYSTEM'
                && parseInt(sender_id) !== parseInt(this.state.currentUserId)
                && (!sender_distr_group_id || parseInt(sender_distr_group_id) !== parseInt(this.state.currentDistrGroupId))
                && (!sender_admin_group_id || parseInt(sender_admin_group_id) !== parseInt(this.state.currentAdminGroupId))
              ).length
            }
            this.setState({
              allMessages,
              chatRoomMessags,
              chatRoomUnreadMessagsCount,
              lastSeen
            })
            break
          }
          case 3: {
            if (deletedChatIds.length) {
              privateChatMessages = privateChatMessages.filter(msg => !deletedChatIds.includes(parseInt(msg.chat_id)))
            }
            privateChatMessages = privateChatMessages.concat(curMsgs)
            if (lastSeen || deletedChatIds.length) {
              privateChatUnreadMessagesCount = privateChatMessages.filter(({
                type, sender_id, sent_date
              }) =>
                type !== 'SYSTEM'
                && parseInt(sender_id) !== parseInt(this.state.currentUserId)
                && (!lastSeen || new Date(sent_date) > lastSeen)
              ).length
            } else {
              privateChatUnreadMessagesCount += curMsgs.filter(({ type, sender_id }) =>
                type !== 'SYSTEM'
                && parseInt(sender_id) !== parseInt(this.state.currentUserId)
              ).length
            }
            this.setState({
              allMessages,
              privateChatMessages,
              privateChatUnreadMessagesCount,
              privateLastSeen: lastSeen
            })
            break
          }
        }
      })
  }

  markAsRead() {
    this.setState({ opendMarkReadConfirm: false });
    this.socket.send(JSON.stringify({
      type: 'SEEN',
      room: this.state.chatRoom,
      data: new Date()
    }));
  }

  componentDidUpdate(_, prevState) {
    let prevMessages, stateMessages;

    // NOTE: test without this code always scroll to bottom

    // // NOTE: we are only handling chatroom 1 and 3
    // if(this.state.chatRoom === 0) {
    //   prevMessages = prevState.chatRoomMessags;
    //   stateMessages = this.state.chatRoomMessags
    // } else {
    //   prevMessages = prevState.privateChatMessages;
    //   stateMessages = this.state.privateChatMessages
    // }

    // if (Array.isArray(prevMessages) &&
    //   Array.isArray(stateMessages) &&
    //   prevMessages.length < stateMessages.length
    // ) {
    //   this.scrollToBottem()
    // }
    this.scrollToBottem()
  }

  handleMsgInputKeypress(e) {
    let msg = this.state.message

    if (e.key === 'Enter' && !e.shiftKey && msg.replace(/\s/g, '').length > 0) {
      e.preventDefault()
      this.setState({ message: "" })
      e.target.value = "";

      this.socket.send(JSON.stringify({
        type: 'TEXT',
        data: msg,
        room: this.state.chatRoom,
        isPrivate: this.state.privateMessage
      }))
    }
  }

  handleSentButtonClick(e) {
    if (String.isNotBlank(this.state.message)) {
      let msg = this.state.message
      this.setState({ message: "" })

      this.socket.send(JSON.stringify({
        type: 'TEXT',
        data: msg,
        room: this.state.chatRoom,
        isPrivate: this.state.privateMessage
      }))
    }
  }

  fileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    if (file !== undefined) {
      reader.readAsDataURL(file);
    }
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });


  async fileupload(file) {
    let ClassThis = this
    const data = await this.fileToBase64(file)
    if (file.type.startsWith('image')) {
      ClassThis.socket.send(JSON.stringify({
        type: 'IMAGE',
        room: this.state.chatRoom,
        data
      }))
    } else {
      ClassThis.socket.send(JSON.stringify({
        type: 'FILE',
        room: this.state.chatRoom,
        fileName: file.name,
        data
      }))
    }
  }

  handleDrop(uploadfile) {
    this.fileupload(uploadfile)
  }
  // changeVisbility() {
  //   this.setState({ privateMessage: !this.state.privateMessage })
  // }
  setMessage(messagesList) {
    const { classes } = this.props
    // let msgbackgroundColor = { ADMIN: "#504575", DISTR: "#445994", DR: "#7c939e" }
    // const roleBgClasses = { ADMIN: classes.chatBgAdmin, DISTR: classes.chatBgDistr, DR: classes.chatBgDr, GADMIN: classes.chatBgDr, NR: classes.chatBgDr, CADMIN: classes.chatBgDr }
    return messagesList.filter(v =>
      v && v.type !== 'SEEN' && (v.type !== 'SYSTEM' || (v.data.type === 'STATUS' && v.data.status !== ""))
    ).map((msg, i) => {
      // if NOT myself and (message is text or image) or (message is status and not empty)
      // if (msg && msg.type !== 'SEEN') {
      if (this.state.currentUserId !== msg.sender_id) {
        return (
          <Grid item xs={12} key={i}>
            <Paper elevation={1}
              className={["msgBody", classes.chatBase, classes.chatBgOther].join(' ')}
              style={{
                textAlign: "left",
                width: msg.type === 'IMAGE' ? "70%" : "fit-content",
                fontSize: this.state.textFontSize
              }}
            >
              {msg.type === 'IMAGE' && <Zoom zoomMargin={48}><img src={join(ApiServerUrl, msg.data)} style={{ width: "100%" }} /></Zoom>}
              {msg.type === 'TEXT' && msg.data.split("\n").map(v => <p key={v}>{v}</p>)}
              {msg.type === 'FILE' && <a href={join(ApiServerUrl, `${msg.data}?fn=${msg.fileName}`)} style={{ color: 'white' }}>{msg.fileName}</a>}
              {msg.type === 'SYSTEM' && <p>{`${this.get('clinicalSimulation.chat.caseStatusChanged')}. ${this.get('clinicalSimulation.chat.newStatus')}: ${msg.data.status} (system)`}</p>}

            </Paper>

            <div style={{ display: "inline-flex" }}>
              <Typography variant="body1" component="p" style={{ textAlign: "left", margin: "4px", fontSize: 12, color: "grey" }}>
                {msg.sender} {Moment(msg.sent_date).format('YYYY-MM-DD HH:mm')}
              </Typography>
            </div>

          </Grid>
        )
      }
      //if myself and (message is text or image) or (message is status and not empty)
      else if (this.state.currentUserId === msg.sender_id) {
        return (
          <Grid item xs={12} align="right" key={i}>
            <Paper
              elevation={1}
              className={["msgBody", classes.chatBase, classes.chatBgSelf].join(' ')}
              style={{
                textAlign: "left",
                fontSize: this.state.textFontSize
              }}
            >

              {msg.type === 'IMAGE' && <Zoom zoomMargin={48}><img src={join(ApiServerUrl, msg.data)} style={{ width: "100%" }} /></Zoom>}
              {msg.type === 'TEXT' && msg.data.split("\n").map(v => <p key={v}>{v}</p>)}
              {msg.type === 'FILE' && <a href={join(ApiServerUrl, `${msg.data}?fn=${msg.fileName}`)} style={{ color: 'white' }}>{msg.fileName}</a>}
              {msg.type === 'SYSTEM' && <p>{`${this.get('clinicalSimulation.chat.caseStatusChanged')}. ${this.get('clinicalSimulation.chat.newStatus')}: ${msg.data.status} (system)`}</p>}

            </Paper>
            <div style={{ display: "inline-flex" }}>
              <Typography variant="body1" component="p" style={{ textAlign: "right", margin: "4px", fontSize: 12, color: "grey" }}>
                {msg.sender} {Moment(msg.sent_date).format('YYYY-MM-DD HH:mm')}
              </Typography>
              {msg.type !== 'SYSTEM' && Date.now() - (new Date(msg.sent_date)).getTime() <= 24 * 3600 * 1000 &&
                <DeleteOutlineIcon
                  style={{ color: "red", cursor: "pointer" }}
                  fontSize="small"
                  onClick={() => {
                    this.socket.send(JSON.stringify({
                      type: 'DELETE',
                      room: this.state.chatRoom,
                      data: msg.chat_id
                    }))
                  }}
                />}
            </div>
          </Grid>
        )
      }
    })
  }

  createMarkAsRead(unReadCount) {
    return (
      <>
        <IconButton style={{ height: "fit-content", color: "white" }} onClick={() => this.handleClickOpen()}>
          <Tooltip title="Mark as Read" placement="top">
            <Badge className={style.customBadge} badgeContent={unReadCount}><DraftsIcon></DraftsIcon></Badge>
          </Tooltip>
        </IconButton>
        <Dialog
          open={this.state.opendMarkReadConfirm}
          onClose={this.handleClose}
        // PaperComponent={PaperComponent}
        >
          <DialogContent>
            <DialogContentText>Confirm marking read for chatroom's messages?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>No</Button>
            <Button onClick={this.markAsRead}>Yes</Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  scrollToBottem() {
    if (this.msgContainer.current) {
      this.msgContainer.current.scrollTop = this.msgContainer.current.scrollHeight
    } else {
      setTimeout(this.scrollToBottem.bind(this), 1000)
    }
    //      let readMessage = this.socket.send(JSON.stringify({
    // 	 type: 'SEEN',
    // 	 data: new Date()
    // }))
  }

  handleClickOpen = () => {
    this.setState({ opendMarkReadConfirm: true });
  };
  handleClose = () => {
    this.setState({ opendMarkReadConfirm: false });
  };
  handleChange = (event, chatRoom) => {
    this.setState({ chatRoom })//, () => this.reloadChatroom(chatRoom));
  };
  LoadDistributorChat = () => {
    // First create ChatRoom
    this.props.handleNewChatRoom(true)
    // Reload
    this.handleChange(undefined, 3)
  }
  render() {
    const { classes, hideTabs, dragPosition,
      hideSimulation, chatRomWidth,
      handleVerticalSplit, exitFullScreen,
      dragableChatRoom, handleFullScreen, secondary, } = this.props;
    const { chatRoom } = this.state;
    return (

      <Grid item xs={12} md={chatRomWidth}
        className={dragPosition ? classes.positioned : ""}
        style={{
          padding: 4
        }}
      >
        <div className="chatboxWrap">

          {/* <Link
            to={`/patients/${this.props.caseId}/treatment`}
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <Button
              className="treatmentplan"
              style={{
                backgroundColor: "#445994",
                color: "white",
                borderRadius: 0
              }}
            >
              {this.get('case_info.documentsList.viewTreatmentPlan')}
            </Button>
          </Link> */}
          <ul className="plan-option">
            <li><a href="">abc</a></li>
            <li><a href="">abc</a></li>
            <li><a href="">abc</a></li>
          </ul>
          <div
            className="caseIDWrap"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: (secondary && !hideSimulation) || hideSimulation? "#853d3d" : "inherit"
            }}
          >
            <img src={getWebAbsPath("/img/front_smile.png")} width="48" />
            <p>{this.get(':fields.caseId')}: {this.props.caseId} <span>{this.get('breadcrumbs.patient')}: {!!this.state.case && `${this.state.case.first_name} ${this.state.case.last_name}`}</span></p>

            {["ADMIN", 'SADMIN', "DISTR"].includes(this.state.currentUserRole) ?
              <div>
                {!hideTabs ?
                  <Tooltip title="Open in Parallel"><IconButton style={{color: "white"}} onClick={() => this.LoadDistributorChat()}><OpenInNewIcon /></IconButton></Tooltip>
                  : ""
                }
                {
                  secondary && !hideSimulation
                  ? <Tooltip title="Single window"><IconButton style={{color: "white"}} onClick={() => this.props.handleNewChatRoom(false)}><DoubleArrow /></IconButton></Tooltip>
                  : ''
                }
                {/* Vertical Split: set 50% widths to chatRooms, show when md=4*3 */}
                {hideTabs && chatRoom === 0 && !hideSimulation ?
                  <Tooltip title="Hide simulation"><IconButton style={{color: "white"}} onClick={() => handleVerticalSplit()}><VerticalSplitIcon /></IconButton></Tooltip>
                  : ""
                }
                {hideSimulation ?
                  <Tooltip title="Show simulation"><IconButton style={{color: "white"}} onClick={() => handleVerticalSplit()}><BurstModeIcon /></IconButton></Tooltip>
                  : ""
                }
              </div>
              : ""
            }
            {["DR", "NR"].includes(this.state.currentUserRole) ?
              <div>
                <IconButton style={{color: "white"}} onClick={() => dragableChatRoom()}><PanToolIcon /></IconButton>
                <IconButton style={{color: "white"}} onClick={() => handleFullScreen()}>
                  {!exitFullScreen ?
                    <FullscreenExitIcon />
                    : <FullscreenIcon />
                  }
                </IconButton>
              </div>
              : ""
            }
          </div>
          {/* Tabs */}
          <div className={classes.root && `${exitFullScreen ? classes.exitFullScreen : ""}`}>
            <AppBar position="static" classes={{root: classes.appbarStyle }} style={{ boxShadow: "none" }}>
              <Tabs
                value={hideTabs ? 3 : chatRoom}
                onChange={this.handleChange}
                // variant="standard"
                variant={!["DR", "NR"].includes(this.state.currentUserRole) ? "standard" : "fullWidth"}
                centered
                indicatorColor="primary"
                textColor="primary"
                className={`${hideTabs ? classes.hideTabs : ""}`}
              >
                <TabContainerC
                  label="CHATROOM"
                  icon={
                    <Badge
                      className={style.customBadge}
                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                      // overlap="circle"
                      badgeContent={this.state.chatRoomUnreadMessagsCount}
                      invisible={this.state.chatRoom === 0 || this.state.chatRoomUnreadMessagsCount === 0}
                    >
                      <ChatIcon style={{fontSize: "large"}}/>
                    </Badge>
                  }
                />
                <Tab
                  label="IPR/ATT PLAN"
                  icon={<CollectionsBookmarkIcon />}
                  disabled={true}
                  style={{ minWidth: "40px", fontSize: "xx-small", color: '#eee', display: "none" }}
                />
                {/* <TabContainerC
                  label="IPR/ATT PLAN"
                  icon={<CollectionsBookmarkIcon />}
                  disabled={true}
                /> */}
                <Tab
                  label="ANALYSIS"
                  icon={<AssessmentIcon />}
                  disabled={true}
                  style={{ minWidth: "40px", fontSize: "xx-small", color: '#eee', display: "none" }}
                />
                {/* <TabContainerC
                  label="ANALYSIS"
                  icon={<AssessmentIcon />}
                  disabled={true}
                /> */}
                {!["DR", "NR"].includes(this.state.currentUserRole) ?
                  <TabContainerC
                    label="DISTR CHAT"
                    icon={
                      <Badge
                        className={style.customBadge}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        // overlap="circle"
                        badgeContent={this.state.privateChatUnreadMessagesCount}
                        invisible={this.state.chatRoom === 3 || this.state.privateChatUnreadMessagesCount === 0}
                      >
                        <LockIcon style={{fontSize: "large"}}/>
                      </Badge>} />
                  : ""}
              </Tabs>
            </AppBar>
            {/* {
              if (data.success){
                let roomMsg = Array.ensureArray(data.chat)
                const room = msg.filter((msgObject) => msgObject && msgObject.room === 1)
                console.log('[ROOM]', room)
              }
            } */}
            {chatRoom === 0 && (
              <TabContainer>
                <DragAndDrop
                  style={{ width: "100%" }}
                  handleDrop={this.handleDrop}
                >
                  <div
                    className="msgroom"
                    ref={this.msgContainer}
                    style={{
                      height: hideTabs ? "calc(100vh - 36px - 4px - 78px - 64px - 65px - 64px - 64px + 20px)" : "calc(100vh - 36px - 4px - 78px - 64px - 72px - 65px - 64px - 64px + 20px)",
                      backgroundColor: "#323639",
                      borderTop: "solid 1px #4d4d4d"
                    }}
                  >
                    <Grid container>
                      {
                        this.state.loadingMessages ?
                          <div style={{
                            position: 'absolute',
                            left: '50%',
                            top: '50%',
                            transform: 'translate(-50%, -50%)'
                          }}>
                            <CircularProgress className={classes.progress} color="secondary" />
                          </div> :
                          this.setMessage(this.state.chatRoomMessags)
                      }

                    </Grid>
                    {/*<Button onClick={()=>this.markAsRead()} style={{width: "100%",background: "cadetblue",color:"white",marginTop:"20px"}}>Mark As Read</Button>*/}
                  </div>
                </DragAndDrop>
              </TabContainer>
            )}
            {chatRoom === 1 && <TabContainer>Item Two</TabContainer>}
            {chatRoom === 2 && <TabContainer>Item Three</TabContainer>}
            {chatRoom === 3 && (
              <TabContainer>
                <DragAndDrop
                  style={{ width: "100%" }}
                  handleDrop={this.handleDrop}
                >
                  <div
                    className="msgroom"
                    ref={this.msgContainer}
                    style={{
                      height: hideTabs ? "calc(100vh - 36px - 4px - 78px - 64px - 95px - 64px - 64px + 20px)" : "calc(100vh - 36px - 4px - 78px - 64px - 72px - 95px - 64px - 64px + 20px)",
                      backgroundColor: "#323639",
                      borderTop: "solid 1px #4d4d4d"
                    }}
                  >
                    <Grid container>
                      {
                        this.state.loadingMessages ?
                          <div style={{
                            position: 'absolute',
                            left: '50%',
                            top: '50%',
                            transform: 'translate(-50%, -50%)'
                          }}>
                            <CircularProgress className={classes.progress} color="secondary" />
                          </div> :
                          this.setMessage(this.state.privateChatMessages)
                      }
                    </Grid>
                    {/*<Button onClick={()=>this.markAsRead()} style={{width: "100%",background: "cadetblue",color:"white",marginTop:"20px"}}>Mark As Read</Button>*/}
                  </div>
                </DragAndDrop>
              </TabContainer>
            )}
          </div>
          {/* Tabs End */}
          {['ADMIN', 'SADMIN'].includes(this.state.currentUserRole) && this.state.chatRoom === 0 ?
            (
              this.state.chatRoom === 0 && this.state.chatRoomUnreadMessagsCount != 0 ?
                <Grid
                  container
                  style={{
                    padding: 8,
                    backgroundColor: (secondary && !hideSimulation) || hideSimulation? "#853d3d" : "#323639",
                    marginTop: '32px !important',
                    borderTop: "solid 1px #4d4d4d"
                  }}
                  className={classes.alignToBottom && `${exitFullScreen ? classes.exitFullScreen : ""}`}
                >
                  <Grid item xs={12} style={{ display: "inline-flex" }}>
                    {this.createMarkAsRead(this.state.chatRoomUnreadMessagsCount)}
                  </Grid>
                </Grid>
                : ""
            ) :
            <Grid container style={{ padding: 8, backgroundColor: "#323639", borderTop: "solid 1px #4d4d4d" }}>
              <Grid item xs={12} style={{ display: "inline-flex" }}>
                <div className={style.uploadBtnWrapper}>
                  <IconButton style={{ height: "fit-content", color: "white" }}><AttachFileIcon /></IconButton>
                  <input type="file" name="photo_section" onChange={(event) => this.fileupload(event.target.files[0])} />
                </div>
                <IconButton style={{ height: "fit-content", color: "white" }} onClick={() => this.changeFontSize()}>
                  <FormatSizeIcon />
                </IconButton>
                {/* {this.state.currentUserRole === "ADMIN" || this.state.currentUserRole === "DISTR" ?
                <div style={{ display: "contents" }}>
                  <IconButton style={{ height: "fit-content" }} onClick={() => this.changeVisbility()}>
                    {this.state.privateMessage ? <VisibilityOffIcon style={{ color: "red" }} /> : <VisibilityIcon />}
                  </IconButton>
                  <p style={{ fontSize: 12, marginTop: 16 }}>Private Message to Internal: {this.state.privateMessage ? 'ON' : 'OFF'}</p>
                </div> : ""
              } */}
                {this.state.chatRoom === 0 && this.state.chatRoomUnreadMessagsCount != 0 ?
                  this.createMarkAsRead(this.state.chatRoomUnreadMessagsCount)
                  : ""}
                {this.state.chatRoom === 3 && this.state.privateChatUnreadMessagesCount != 0 ?
                  this.createMarkAsRead(this.state.privateChatUnreadMessagesCount)
                  : ""}
              </Grid>
              <Grid item xs={12} style={{ display: "inline-flex" }}>
                {/* <IconButton style={{height: "fit-content"}}>
					<AttachFileIcon/>
				</IconButton> */}
                <Input
                  // ref="msgbox"
                  id="text"
                  type="text"
                  multiline
                  onChange={e => { this.setState({ message: e.target.value }) }}
                  onKeyPress={(e) => this.handleMsgInputKeypress(e)}
                  placeholder={this.get('clinicalSimulation.chat.sendPlaceHolder')}
                  value={this.state.message}
                  style={{ width: "100%", color: "white" }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={(e) => this.handleSentButtonClick(e)}
                        style={{
                          color: "white"
                        }}
                      >
                        <SendIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
            </Grid>
          }

        </div>
      </Grid>
    );
  }
}

const mapStateToProps = store => ({
  lang: store.lang
})

const mapDispatchToProps = dispatch => ({
  enqueueSnackbar: (msg, variant) => dispatch(easySnackbar(msg, variant, true)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(withLang(Chat, 'PatientInfo'))));
