import React, { Component } from 'react';
import { Grid, Radio } from '@material-ui/core';
import { connect } from 'react-redux';
import * as actionTypes from '../../../store/NewPatient/actionType';
import { withRouter } from 'react-router-dom';
import styles from './ColorPattern.module.css';
import { RadioGroup, FormControlLabel } from '@material-ui/core';
import { withLang } from '../../../util/lang';
import '../../../util/string'
import { retainer_colors } from '../../../color_pattern'


class ColorPattern extends Component {
  retainer_type = [
    { value: "clear", label: "Clear Retainer" },
    { value: "fashion", label: "Fashion Retainer" }
  ]

  retainer_position = [
    { value: "FrontSides", label1: "Upper Jaw: Pattern in front", label2:"Lower Jaw: Pattern on sides" },
    { value: "SidesFront", label1: "Upper Jaw: Pattern on sides", label2:"Lower Jaw: Pattern in front" },
    { value: "SidesSides", label1: "Upper Jaw: Pattern on sides", label2:"Lower Jaw: Pattern on sides" }
  ]

  constructor(props) {
    super(props)
  }

  handleRetainerSets(value) {
    this.props.handleChange('retainer_pairs', parseInt(value))
  }

  handleRetainerType(retainer_no, value) {
    this.props.handleChange(`retainer_type${retainer_no}`, value)
    if (value != "fashion") {
      this.props.handleChange(`retainer_color${retainer_no}_upper`, null)
      this.props.handleChange(`retainer_color${retainer_no}_lower`, null)
      this.props.handleChange(`retainer_position${retainer_no}`, null)
    }
  }

  componentDidMount() {
    for (let i = 0; i < this.props.max_retainer_pairs; i++) {
      this.handleRetainerType(i, this.props[`retainer_type${i}`])
    }
  }

  render() {
    const retainer_pairs_options = []
    const pattern = []

    for (let i = 0; i < this.props.max_retainer_pairs; i++) {
      let value = i + 1
      retainer_pairs_options.push(
        <Grid item xs={12} sm={6} md={3} key={value}>
          <FormControlLabel value={value} control={<Radio />} label={`${value} pair${value > 1 ? 's' : ''}`} />
        </Grid>
      )
    }

    for (let n = 0; n < this.props.retainer_pairs; n++) {
      pattern.push(<>
        <Grid item xs={12} className={styles.tableItem + " " + styles.heading}>
          <p>Retainer {n + 1}</p>
        </Grid>
        <Grid item xs={12} className={styles.tableItem}>
          <Grid container>
            <Grid item xs={12} sm={4} md={3}>
              <span style={{color: 'red'}}>*</span>Retainer Type
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              <RadioGroup
                onChange={(e) => {
                  this.handleRetainerType(n, e.target.value)
                }}
                name={`retainer_type${n}`} value={this.props[`retainer_type${n}`]}
              >
                <Grid container>
                  {this.retainer_type.map((i) => 
                    <Grid item xs={12}>
                      <FormControlLabel key={i.value} value={i.value} control={<Radio />} label={String.toCapitalLetter(i.value)} />
                    </Grid>
                  )}
                </Grid>
              </RadioGroup>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={styles.tableItem + " " + (this.props[`retainer_type${n}`] == "fashion" ? "" : styles.hide)}>
          <Grid container>
            <Grid item xs={12} sm={4} md={3}>
              <span style={{color: 'red'}}>*</span>Upper Jaw Color
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              <RadioGroup
                onChange={(e) => {
                  this.props.handleChange(`retainer_color${n}_upper`, e.target.value)
                }}
                name={`retainer_color${n}_upper`} value={this.props[`retainer_color${n}_upper`]}
              >
                <Grid container>
                  {retainer_colors.filter(i => i.enabled).map((i) =>
                    <Grid item xs={12} sm={6} md={4} key={i.value}>
                      <FormControlLabel value={i.value} control={<Radio />} label={ <div><img className={styles.img} src={`/img/color-pattern/${i.value}.${i.extension}`} /><span>{i.label}</span></div> }/>
                    </Grid>
                  )}
                </Grid>
              </RadioGroup>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={styles.tableItem + " " + (this.props[`retainer_type${n}`] == "fashion" ? "" : styles.hide)}>
          <Grid container>
            <Grid item xs={12} sm={4} md={3}>
              <span style={{color: 'red'}}>*</span>Lower Jaw Color
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              <RadioGroup
                onChange={(e) => {
                  this.props.handleChange(`retainer_color${n}_lower`, e.target.value)
                }}
                name={`retainer_color${n}_lower`} value={this.props[`retainer_color${n}_lower`]}
              >
                <Grid container>
                  {retainer_colors.filter(i => i.enabled).map((i) =>
                    <Grid item xs={12} sm={6} md={4} key={i.value}>
                      <FormControlLabel value={i.value} control={<Radio />} label={ <div><img className={styles.img} src={`/img/color-pattern/${i.value}.${i.extension}`} /><span>{i.label}</span></div> }/>
                    </Grid>
                  )}
                </Grid>
              </RadioGroup>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={styles.tableItem + " " + (this.props[`retainer_type${n}`] == "fashion" ? "" : styles.hide)}>
          <Grid container>
            <Grid item xs={12} sm={4} md={3}>
              <span style={{color: 'red'}}>*</span>Position
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              <RadioGroup
                onChange={(e) => {
                  this.props.handleChange(`retainer_position${n}`, e.target.value)
                }}
                name={`retainer_position${n}`} value={this.props[`retainer_position${n}`]}
              >
                <Grid container>
                  {this.retainer_position.map((i) => 
                    <Grid item xs={12} sm={6} md={4} key={i.value}>
                      <FormControlLabel value={i.value} control={<Radio />} label={ <div><img className={styles.imgPos} src={`/img/color-pattern/${i.value}.png`} /><div className={styles.imgtext}>{i.label1}<br />{i.label2}</div></div> }/>
                    </Grid>
                  )}
                </Grid>
              </RadioGroup>
            </Grid>
          </Grid>
        </Grid>
      </>)
    }
    return (
      <Grid container>
        <Grid item xs={12} className={this.props.popup ? styles.popupTableItem : styles.tableItem}>
          <Grid container>
            <Grid item xs={12} sm={4} md={3} className={styles.heading}>
              Retainers
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              <RadioGroup
                onChange={(e) => {
                  this.handleRetainerSets(e.target.value)
                }}
                name="retainer_pairs" value={this.props.retainer_pairs}
              >
                <Grid container>
                  {retainer_pairs_options}
                </Grid>
              </RadioGroup>
            </Grid>
          </Grid>
        </Grid>
        {pattern}
      </Grid>
    )
  }
}

const mapStateToProps = store => {
  return {
    ...store.newPatientReducer,
    lang: store.lang
  }
};

const mapDispatchToProps = dispatch => {
  return {
    handleChange: (attrName, value) => {
      dispatch({ type: actionTypes.NEW_PATIENT_COLOR_PATTERN, payload: { attrName, value } })
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withLang(ColorPattern, 'NewPatient')))
