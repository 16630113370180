
function NewPatientHandleUpload(image_id, file, handleDataFileUpload) {
  switch (image_id) {
    case 'face_side_with_smile':
      handleDataFileUpload("face_side_with_smile", file);
      break;
    case 'face_front_with_smile':
      handleDataFileUpload("face_front_with_smile", file);
      break;
    case 'face_side_without_smile':
      handleDataFileUpload("face_side_without_smile", file);
      break;
    case 'teeth_side_with_smile':
      handleDataFileUpload("teeth_side_with_smile", file);
      break;
    case 'teeth_front_with_smile':
      handleDataFileUpload("teeth_front_with_smile", file);
      break;
    case 'teeth_side_without_smile':
      handleDataFileUpload("teeth_side_without_smile", file);
      break;
    case 'jaw_side_with_smile':
      handleDataFileUpload("jaw_side_with_smile", file);
      break;
    case 'jaw_front_with_smile':
      handleDataFileUpload("jaw_front_with_smile", file);
      break;
    case 'jaw_side_without_smile':
      handleDataFileUpload("jaw_side_without_smile", file);
      break;
    case 'front_with_smile':
      handleDataFileUpload("front_with_smile", file);
      break;
    case 'side_with_smile':
      handleDataFileUpload("side_with_smile", file);
      break;
    case 'cbct':
      handleDataFileUpload("cbct", file);
      break;
    case 'impression_upper':
      handleDataFileUpload('impression_upper', file)
      break;
    case 'impression_lower':
      handleDataFileUpload("impression_lower", file)
      break;
    case 'impression_scan_one':
      handleDataFileUpload("impression_scan_one", file)
      break;
    case 'impression_scan_two':
      handleDataFileUpload("impression_scan_two", file)
      break;
  }
}

export default NewPatientHandleUpload;