import '../util/string'
import { Button, Grid } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import React, { Component, Fragment } from 'react';
import { Animated } from "react-animated-css";
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import join from 'url-join'
import axios from '../axios-default';
import ConfirmDialog from '../components/Dialog/ConfirmDialog';
import NewPatientPhoto from '../components/NewPatient/Photos/NewPatientPhoto';
import Photos from '../components/NewPatient/Photos/NewPatientPhotos';
import Questionnaire from '../components/NewPatient/Questionnaire/NewPatientQuestionnaire_beme';
import { ApiServerUrl } from '../config.json';
import * as actionTypes from '../store/NewPatient/actionType';
import * as saveAction from '../store/NewPatient/NewPatient.action';
import NewPatientHandleUpload from '../util/NewPatientHandleUpload';
import { easySnackbar } from '../store/Noti/noti.actions';
import NewConfirmDialog from '../components/Dialog/NewConfirmDialog';
import { withLang } from '../util/lang';
import { getFormToken } from '../util/file';
import LoadingProgressBar from '../components/LoadingProgressBar';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Refinement extends Component {

  constructor(props) {
    super(props);
    this.state = {
      LoadingStatus: false,
      loading: false,
      uploading: false,
      comments: "",
      caseId: this.props.match.params.id,
      role: JSON.parse(localStorage.getItem('role')),
      mandatoryPhotoWithoutData: false,
      mandatoryPhotoDataValidation: false,
      mandatoryPhotoValidationDarft: false,
      refinementData: null,
      initialRefinementInfo: null,
      error: "",
      draftConfirmation: false,
      submitConfirmation: false,
      refinement_id: null,
      case_detail_id: null,
      case_file_id: null,
      case_impression_id: null
    }
    this.progressBarRef = React.createRef(null)
  }

  async componentDidMount() {
    let { data } = await axios.get(`case/refinement/${this.state.caseId}`)
    // console.log(data)
    if (data.case_refinement.length > 0) {
      let refinementDraft = data.case_refinement.filter((v, index) => {
        return v.refinement_status === "DRAFT"
      })

      if (refinementDraft.length > 0) {
        let refinement_id = refinementDraft[0]['refinement_id']
        let case_detail_id = refinementDraft[0]['case_detail']['case_detail_id']
        let case_file_id = refinementDraft[0]['case_file']['case_file_id']
        let case_impression_id = refinementDraft[0]['case_impression']['case_impression_id']

        if (data && this.state.patientInfo == null) {
          let intialState = {}
          
          Object.entries(refinementDraft[0].case_detail)
            .filter(([k]) => /^attr[0-9]{1,2}$/.test(k))
            .forEach(([k, v]) => {
              try {
                const [_, i] = k.match(/^attr([0-9]{1,2})$/)
                intialState[`attr${i}`] = v
              } catch {}
          })

          //set Photo Data
          let modifiedFilesData = [...this.props.NewPatientData.files]
          let radioKey = 0, stlKey = 0, key = "";
          modifiedFilesData.forEach((v, index) => {

            if (v.category === 'photo' || v.category === 'radio') {
              key = v.category === "radio" ? v.category + radioKey : v.category + index
              radioKey = v.category === "radio" ? radioKey + 1 : 0
              v['data'] = (refinementDraft[0].case_file && refinementDraft[0].case_file[key]) ? (/^https?:\/\//.test(refinementDraft[0].case_file[key]) ? refinementDraft[0].case_file[key] : join(ApiServerUrl, refinementDraft[0].case_file[key])) : ""
              v['uploaded'] = v['data'] ? true : false
              v['upload_later'] = v['uploaded'] ? false : true
              v['will_not_upload'] = refinementDraft[0].case_file && refinementDraft[0].case_file[key+'_later'] === null
            }
            else {
              key = v.category + stlKey
              stlKey = stlKey + 1
              v['data'] = (refinementDraft[0].case_impression && refinementDraft[0].case_impression[key]) ? (/^https?:\/\//.test(refinementDraft[0].case_impression[key]) ? refinementDraft[0].case_impression[key] : join(ApiServerUrl, refinementDraft[0].case_impression[key])) : ""
              v['uploaded'] = v['data'] ? true : false
              v['upload_later'] = v['uploaded'] ? false : true
              v['will_not_upload'] = refinementDraft[0].case_impression && refinementDraft[0].case_impression[key+'_later'] === null
            }

          })
          intialState['files'] = modifiedFilesData;

          intialState['scannerBrand'] = (refinementDraft[0].case_impression && refinementDraft[0].case_impression['scanner_brand']) ? refinementDraft[0].case_impression['scanner_brand'] : "";
          intialState['doctorId'] = (refinementDraft[0].case_impression && refinementDraft[0].case_impression['doctor_id']) ? refinementDraft[0].case_impression['doctor_id'] : "";
          intialState['modernCode'] = (refinementDraft[0].case_impression && refinementDraft[0].case_impression['modern_code']) ? refinementDraft[0].case_impression['modern_code'] : "";
          intialState['waybillNumber'] = (refinementDraft[0].case_impression && refinementDraft[0].case_impression['waybill_number']) ? refinementDraft[0].case_impression['waybill_number'] : "";
          intialState['courierCompany'] = (refinementDraft[0].case_impression && refinementDraft[0].case_impression['courier_company']) ? refinementDraft[0].case_impression['courier_company'] : "";
          intialState['impressionType'] = (refinementDraft[0].case_impression && refinementDraft[0].case_impression['type']) ? refinementDraft[0].case_impression['type'] : "FILE";

          this.props.setTempData(intialState)
        }
        this.setState({ refinement_id, case_detail_id, case_impression_id, case_file_id })
      }
    }
  }

  async handleDataSave(save_type) {
    const form = new FormData()

    let newState = { ...this.props.NewPatientData }

    let modifiedKey, modifiedState = {}, case_file = {}, case_impression = {}, case_detail = {}, radioCounter = 0, stlCounter = 0;
    let files = newState['files']

    files.forEach((value, index) => {
      if (value.category == "stl_file") {
        const fileName = `$$FILE:stl_file${stlCounter}`
        case_impression['stl_file' + stlCounter] = getFormToken(form, value.data, fileName)
        case_impression['stl_files_later'] = value.will_not_upload? null : value.upload_later
        stlCounter++
      }
      else if (value.category == "radio") {
        const fileName = `$$FILE:radio${radioCounter}`
        case_file['radio' + radioCounter] = { data: getFormToken(form, value.data, fileName), rotate: value.rotate }
        case_file['radio' + radioCounter + '_later'] = value.will_not_upload? null : value.upload_later
        radioCounter++
      }
      else {
        const fileName = `$$FILE:photo${index}`
        case_file['photo' + index] = { data: getFormToken(form, value.data, fileName), flipped: value.flipped, rotate: value.rotate }
        case_file['photo' + index + '_later'] = value.will_not_upload? null : value.upload_later
      }
    })

    Object.keys(newState).forEach(function (key, index) {
      modifiedKey = key.replace(/(?:^|\.?)([A-Z])/g, function (x, y) { return "_" + y.toLowerCase() }).replace(/^_/, "");
      const attrExtractor = /^attr([0-9]{1,2})$/
      if (modifiedKey == "scanner_brand" || modifiedKey == "doctor_id" || modifiedKey == "modern_code") {
        if (newState[key] && newState[key].length > 0) {
          case_impression[modifiedKey] = newState[key]
        }
      } else if (attrExtractor.test(key)) {
        try {
          const [_, i] = key.match(attrExtractor)
          case_detail[`attr${i}`] = newState[key]
        } catch {}
      }
    });
    if (case_impression.stl_files_later) {
      for (let i = 0; i < 4; i++) case_impression[`stl_file${i}`] = null
    }

    case_detail['case_detail_id'] = this.state.case_detail_id
    case_file['case_file_id'] = this.state.case_file_id
    case_impression['case_impression_id'] = this.state.case_impression_id
    case_impression['type'] = newState.impressionType

    modifiedState['case_detail'] = case_detail
    modifiedState['case_file'] = case_file
    modifiedState['case_impression'] = case_impression

    modifiedState['clinical_condition_comment'] = this.state.clinicalConditionComment
    modifiedState['case_id'] = this.state.caseId

    if (this.state.refinement_id) {
      modifiedState['refinement_id'] = this.state.refinement_id
    }

    let mandatoryCounter = 0, mandatoryPhotoWithoutValueCounter = 0, stronglyRecommended = 0, mandatoryRadioWithoutValueCounter

    files.map((value, index) => {

      // there is no upload later button in cbct, so it need to hard code value.id !="cbct"
      // mandatory photo are first six photo and "front_with_smile"      
      if (['photo', 'radio'].includes(value.category) && !value.data && !value.upload_later && !value.will_not_upload 
      && value.id =="front_with_smile"  && value.id !="cbct") {

        mandatoryCounter += 1;
      }
      if (value.category === "photo" && !value.data && !value.upload_later && !value.will_not_upload && index < 6) {
        mandatoryPhotoWithoutValueCounter += 1;
      }
    })

    files.map((value, index) => {
      if (value.category === "photo" && !value.data && !value.upload_later && !value.will_not_upload && index >= 6) {
        stronglyRecommended += 1;
      }
    })

    modifiedState['refinement_status'] = save_type

    form.append('body', JSON.stringify(modifiedState))

    if (mandatoryCounter > 0 && save_type === 'SUBMITTED') {
      this.setState({ mandatoryPhotoDataValidation: true })
    }
    else if (mandatoryPhotoWithoutValueCounter > 0 && save_type === 'SUBMITTED') {
      this.setState({ mandatoryPhotoWithoutData: true });
    }
    else {
      this.handleSaveValidatedData(form)
    }
  }

  async handleSaveValidatedData(form) {
    try {
      this.setState({ loading: true })
      await axios.post(`case/refinement/${this.state.caseId}`, form, {
        onUploadProgress: this.progressBarRef.current?.onProgressChange
      })
      await this.props.showSnackbar('Success', 'success', true)
      this.props.history.push(`/patients/${this.state.caseId}`)
    }
    catch (e) {
      console.error(e.response)
      if (e.response?.data?.invalid) {
        const translation = {
          'modern_code': this.get('impressions.byCourierLabel'),
          'scanner_brand': this.get('impressions.byScannerBrandLabel'),
          'doctor_id': this.get('impressions.byScannerUserNameLabel')
        }
        const invalidFields = e.response.data.invalid.map(v => translation[v] ?? v).join(',')
        this.props.showSnackbar(`Please enter ${invalidFields}`, 'error')
      } else {
        this.props.showSnackbar(e.response?.data?.message ?? e.message ?? e, 'error')
      }
    } finally {
      this.setState({ loading: false })
    }
  }

  handleRoatate(image_id, degree) {
    this.props.handleRoatateImage(image_id, degree)
  }

  checkIfImageExists(image_id) {
    let image_data = this.props.files.filter(imageObj => { return imageObj.id == image_id })
    return image_data[0].uploaded;
  }

  getImage(image_id) {
    let image_data = this.props.files.filter(imageObj => { return imageObj.id == image_id })
    return image_data[0];
  }

  handleUpload(image_id, file) {
    NewPatientHandleUpload(image_id, file, this.props.handleDataFileUpload)
    this.forceUpdate();
  }
  setMandatoryPhotoWithoutData(val) {
    this.setState({ mandatoryPhotoWithoutData: val })
  }

  setMandatoryPhotoDataValidation(val) {
    this.setState({ mandatoryPhotoDataValidation: val })
  }

  setMandatoryPhotoValidationDarft(val) {
    this.setState({ mandatoryPhotoValidationDarft: val })
  }

  render() {
    return (
      <Fragment>
        <Animated animationIn="fadeIn" isVisible={true} style={{ backgroundColor: 'rgb(242, 242, 242)' }}>
          <LoadingProgressBar ref={this.progressBarRef} open={this.state.loading} />
          <Grid container style={{ padding: 16, background: "#fff" }}>
            <Grid item xs={12}>
              <div
                style={{
                  margin: "8px 0px"
                }}
              >
                <Link
                  to="/"
                  style={{
                    color: "black"
                  }}
                >
                  Case Management
                  </Link>
                {` > Patient #${this.props.match.params.id} > Refinement`}
              </div>
            </Grid>

            <Grid item xs={12}>
              <Questionnaire />
              <ConfirmDialog
                title="Save Draft"
                open={this.state.mandatoryPhotoWithoutData}
                dialogConfirmation="OK"
                setOpen={(val) => this.setMandatoryPhotoWithoutData(val)}
                onConfirm={() => this.handleSaveValidatedData()}
              >
                For mandatory photos, you have indicated that you will upload it later.Please note that our technicians will await all mandatory data before beginning to treatment plan of the case
              </ConfirmDialog>

              <ConfirmDialog
                title="Warning"
                open={this.state.mandatoryPhotoDataValidation}
                dialogConfirmation="OK"
                setOpen={(val) => this.setMandatoryPhotoDataValidation(val)}
              >
                Mandatory photos are not uploaded, please save draft and proceed refinement submission afterwards
              </ConfirmDialog>

              <ConfirmDialog
                title="Warning"
                open={this.state.mandatoryPhotoValidationDarft}
                dialogConfirmation="OK"
                setOpen={(val) => this.setMandatoryPhotoValidationDarft(val)}
              >
                Please upload all the images or check upload later option for Photo,Radiograph and Impression
              </ConfirmDialog>

              <NewConfirmDialog
                title="Confirmation"
                open={this.state.submitConfirmation}
                dialogConfirmation="Confirm"
                onClose={() => this.setState({ submitConfirmation: false })}
                onConfirm={() => this.handleDataSave('SUBMITTED')}
              >
                Are you sure to submit this refinement?
              </NewConfirmDialog>

              <NewConfirmDialog
                title="Confirmation"
                open={this.state.draftConfirmation}
                dialogConfirmation="Confirm"
                onClose={() => this.setState({ draftConfirmation: false })}
                onConfirm={() => this.handleDataSave('DRAFT')}
              >
                Are you sure to save as draft?
              </NewConfirmDialog>

              <Photos tabMode="photos" parent='refinement' />
              <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                  <NewPatientPhoto
                    imageId="front_with_smile"
                    imageUploaded={this.checkIfImageExists("front_with_smile")}
                    imageData={this.getImage("front_with_smile")}
                    label="Upload this photo later"
                    labelSecondary="Will not submit"
                    imageName="bg_xray1.png"
                    uploadLater={true}
                    no_warning={true}
                    will_not_upload={true}
                    will_not_upload_label={this.get(":uploadType.willNotUploadXray")}
                    imageLabel={`${this.get('radiographs.orthopantomogram')} - ${this.get(':uploadType.mandatory')}`}
                    handleRoatate={(degree) => { this.handleRoatate("front_with_smile", degree) }}
                    handleChange={(file) => { this.handleUpload("front_with_smile", file) }} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <NewPatientPhoto
                    imageId="side_with_smile"
                    imageUploaded={this.checkIfImageExists("side_with_smile")}
                    imageData={this.getImage("side_with_smile")}
                    label="I will upload this X-ray later"
                    imageName="bg_xray2.png"
                    will_not_upload={true}
                    uploadLater={true}
                    no_warning={true}
                    will_not_upload_label={this.get(":uploadType.willNotUploadXray")}
                    imageLabel={`${this.get('radiographs.lateralCephalogram')} - ${this.get(':uploadType.stronglyRecommended')}`}
                    handleRoatate={(degree) => { this.handleRoatate("side_with_smile", degree) }}
                    handleChange={(file) => { this.handleUpload("side_with_smile", file) }} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <NewPatientPhoto
                    imageId="cbct"
                    imageUploaded={this.checkIfImageExists("cbct")}
                    imageData={this.getImage("cbct")}
                    label={this.get(':uploadType.uploadXrayLater')}
                    imageName="bg_xray1.png"
                    no_warning={true}
                    imageLabel={`${this.get('radiographs.cbct_dicom')} - ${this.get(':uploadType.optional')}`}
                    labelUnderImage="FOV 16(W)X10(H)"
                    handleRoatate={(degree) => { this.handleRoatate("cbct", degree) }}
                    handleChange={(file) => { this.handleUpload("cbct", file) }} />
                </Grid>
              </Grid>
              <Photos tabMode="impression" />
            </Grid>

            <Grid item xs={12}>
              <Button onClick={() => this.setState({ submitConfirmation: true })}
                style={{
                  background: "rgba(89,54,135,1)",
                  color: "white",
                  padding: "4px 24px",
                  marginLeft: "12px",
                  float: "right"
                }}
                disabled={this.state.LoadingStatus}>
                {this.state.LoadingStatus ? "Saving..." : "Submit"}
              </Button>
              <Button
                onClick={() => this.setState({ draftConfirmation: true })}
                style={{
                  background: "rgba(89,54,135,1)",
                  color: "white",
                  padding: "4px 24px",
                  float: "right"
                }}
                disabled={this.state.LoadingStatus}>
                {this.state.LoadingStatus ? "Saving..." : "Save as draft"}
              </Button>
            </Grid>
          </Grid>
        </Animated>
      </Fragment>
    );
  }
}

const mapStateToProps = store => {
  return {
    NewPatientData: store.newPatientReducer,
    files: store.newPatientReducer.files,
    config: store.config.config,
    lang: store.lang
  }
};

const mapDispatchToProps = dispatch => {
  return {
    handleDataFileUpload: (id, value) => dispatch({ type: actionTypes.NEW_PATIENT_FILES, id: id, value: value }),
    setTempData: (newPatientData) => dispatch(saveAction.tempSave(newPatientData)),
    handleRoatateImage: (id, value) => dispatch({ type: actionTypes.NEW_PATIENT_FILE_ROTATE, id: id, value: value }),
    showSnackbar: (msg, variant, queue=false) => dispatch(easySnackbar(msg, variant, queue))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withLang(Refinement, 'NewPatient')));
