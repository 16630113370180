import '../../util/string'
import { CircularProgress, Fab, Tooltip, Typography } from '@material-ui/core/';
import Grid from '@material-ui/core/Grid';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import Moment from 'moment';
import MUIDataTable from "mui-datatables";
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import QueryString from 'querystring'
import { withRouter, useHistory } from 'react-router-dom';
import Url from 'url';
import join from 'url-join'
import axios from '../../axios-default';
//Components
import SideNav from '../../components/Admin/SideNav';
import { ApiServerUrl } from '../../config.json';
import { easySnackbar } from '../../store/Noti/noti.actions';
import { updateRes } from '../../store/res/res.action';
import { getFromObj } from '../../util/lang';
import { get } from 'http';


import Add from '@material-ui/icons/Add'
import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import DraftsIcon from '@material-ui/icons/Drafts'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import GridOnIcon from '@material-ui/icons/GridOn'
import LastPage from '@material-ui/icons/LastPage'
import MailOutlinedIcon from '@material-ui/icons/MailOutlined'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import ViewListIcon from '@material-ui/icons/ViewList'
import MaterialTable from 'material-table';
import { getWebAbsPath } from '../../util/path';

const StatusList = {
  DRAFT: 'Draft',
  SUBMITTED: 'Prescription Submitted',
  AWAITING: 'Awaiting More Records{Remark: ?}',
  RECEIVED: 'Confirmed All Materials Received',
  CHANGE_REQUEST: 'Treatment Plan Change Request Received',
  PLAN_UPLOADED: 'Treatment Plan {No.?} Uploaded',
  PLAN_APPROVED: 'Treatment Plan {No.?} Approved',
  PRODUCTION: 'Production In Progress',
  DELIVERED: 'Aligners Set {No.1 ?}-{No.2 ?} Delivered',
  NEXT_SET_REQUEST: 'Request for Next Aligner Set',
  ALL_DELIVERED: 'All Aligners Sets Are Delivered',
  ALIGNER_REQUEST: 'Aligner Request',
  RETAINER_REQUEST: 'Retainer Request',
  REFINEMENT: 'Refinements Prescription Received',
  ENDED: 'Case Ended'
}

const TableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
}

const CaseStyles = makeStyles(theme => ({
  "@global": {
    body: {
      background: "#fdfdfd",
    },
  },
  root: {
    flexGrow: 1,
  },
  rootContainer: {
    padding: 16,
    margin: "8px 0px 0px 238px",
    [theme.breakpoints.between("xs", "sm")]: {
      margin: "12px 0px 0px 0px",
      width: "100%",
    },
    width: "calc(100% - 238px)",
  },
  scanBox: {
    padding: 16,
    margin: "8px 0px"
  },
  textField: {
    // marginLeft: "8px",
    // marginRight: "8px",
    '& label.Mui-focused': {
      color: 'black',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'black',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey',
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
  },
  dateField: {
    width: "100%"
  },
  formControl: {
    width: "100%"
  },
  dense: {
    marginTop: 16,
    marginLeft: 0
  },
  submitBtn: {
    backgroundColor: "#061a2e !important",
    color: "white",
    padding: "8px 24px",
    margin: "12px 0px"
  },
  linkBtn: {
    background: "rgba(89,54,135,1)",
    color: "white",
    padding: "4px 24px",
    fontWeight: 300,
    margin: "16px 0px",
    width: "100%",
    [theme.breakpoints.up('sm')]: {
      padding: "4px 72px",
      width: "unset",
    },
    "&:hover": {
      background: "rgb(77, 74, 142)",
      color: "white"
    }
  },
}))

const Columns = [
  {
    field: "case_id",
    title: "Case ID",
    searchable: true
  },
  {
    field: "new_status",
    title: "Treatment Option",
    searchable: true
  },

  {
    field: "created_by",
    title: "Created By",
  },
  {
    field: "created_date",
    title: "Creation Date",
  },
  {
    field: "deleted_by",
    title: "Deleted By",
  },
  {
    field: "deleted_date",
    title: "Deleted Date",
  }
]

export default function CasesRollbackLog(props) {
  const classes = CaseStyles(),
        dispatch = useDispatch(),
        history = useHistory()

  const [loading, setLoading] = useState(false),
        lastPageSize = useRef(),
        lastPageNo = useRef(0),
        [total, setTotal] = useState(0)

  useEffect(() => {
    window.scroll({top: 0, left: 0, behavior: 'smooth' })
  }, [])

  const reloadPage = () => {
    history.push('/blank')
    history.replace(history.location.pathname)
  }


  const dataGetter = async query => {
    const { pageSize, page: pageNo, search, orderBy, orderDirection } = query
    try {
      if (orderBy && orderBy.field.endsWith('_label')) {
        orderBy.field = orderBy.field.slice(0, -6)
      }
      const { data } = (await axios.get(`/case/cases_rollback_log?${QueryString.encode({
        searchStr: typeof search !== 'string' || String.isBlank(search)? undefined : search,
        sortBy: orderBy && orderBy.field,
        sortDir: orderDirection,
        statusFilter: 'ALL',
        pageSize,
        pageNo,
        all: 1
      })}`))
      lastPageSize.current= pageSize
      lastPageNo.current = pageNo

      setLoading(false)
      let data_temp = {
        data: data.case.filter(c => c.case_id >= 1).map(c => {
          return {
            case_id: c.case_id,
            case_type: c.case_type,
            changed_case_type: c.changed_case_type,
            created_by: c.created_by,
            created_date: Moment(c.created_date).calendar({
              sameElse: 'DD MMM YYYY [at] hh:mm A'
            }),
            case_id: c.case_id,
            new_status : c.new_status,
            status_param1 : c.status_param1,
            status_param2 : c.status_param2,
            status_remark : c.status_remark,
            created_by: c.created_by,
            created_date: Moment(c.created_date).calendar({
              sameElse: 'DD MMM YYYY [at] hh:mm A'
            }),
            deleted_by: c.deleted_by,
            deleted_date: Moment(c.deleted_date).calendar({
              sameElse: 'DD MMM YYYY [at] hh:mm A'
            }),

          }
        }),
        page: pageNo ?? 0,
        totalCount: data.total ?? 0
      }

      return data_temp
    } catch (err) {
      console.error(err)
      if (err.response && err.response.statusText === 'Unauthorized') {
        localStorage.removeItem("auth-token");
        localStorage.removeItem("expirationDate");
        localStorage.removeItem("role");
        history.replace('/login')
      }
    }
  }

  return (
    <div>
      <SideNav />
      <Grid container className={classes.rootContainer}>

      </Grid>
      <Grid container className={classes.rootContainer} style={{ marginTop: 0 }}>
        {!loading ?
          <div style={{ display: "contents" }}>
            <Grid container>

              <Grid item xs={12}
                style={{
                  padding: 8
                }}>
                <h1
                  style={{
                    marginBlockEnd: 0
                  }}
                >
                  Cases Cancel Log
                </h1>

                <hr />
              </Grid>

              <Grid item xs={12}
                style={{
                  padding: 8,
                  textAlign: "center"
                }}>
                <img style={{ width: "100%", maxWidth: 800 }} src={getWebAbsPath("/img/account1.png")} />
              </Grid>

              <Grid item xs={12} style={{ padding: 8 }}>
                <MaterialTable
                  title=''
                  columns={Columns}
                  //actions={['SADMIN', 'ADMIN'].includes(localStorage.getItem('role') && JSON.parse(localStorage.getItem('role'))) && Actions}
                  data={dataGetter}
                  icons={TableIcons}
                  options={{
                    initialPage: 0,
                    emptyRowsWhenPaging: false,
                    exportButton: true,
                    exportAllData: true,
                    debounceInterval: 700,
                    columnsButton: true,
                    actionsColumnIndex: -1,
                    pageSize: 30,
                    pageSizeOptions: [15, 30, 50],
                    minBodyHeight: 700,
                    maxBodyHeight: 1000,
                  }}
                />
              </Grid>

            </Grid>

          </div> :
          <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress style={{ color: "rgb(6,26,46)" }} />
          </Grid>}
      </Grid>
    </div>
  )
}
