import '../util/string'
import React, { forwardRef, memo, useEffect, useImperativeHandle, useState } from "react"
import { usePrevious } from '../util/react';
import { Backdrop, Box, CircularProgress, createStyles, Grid, LinearProgress, makeStyles, Typography } from '@material-ui/core'

const DownloadingProgressBarStyles = makeStyles(theme =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.modal + 1,
      color: '#fff'
    }
  })
)
const DownloadingProgressBar = ({ open, label }, ref) => {
  const { backdrop } = DownloadingProgressBarStyles()

  const [ progress, setProgress ] = useState(100),
        prevOpen = usePrevious(open)

  useEffect(() => {
    if (!prevOpen && open) {
      setProgress(0)
    }
  }, [prevOpen, open])

  useImperativeHandle(ref, () => ({
    onProgressChange: ({ loaded, total, progress }) => {
      if (progress) {
        setProgress(progress)
      } else {
        setProgress(Math.ceil(loaded/total*100))
      }
    }
  }))
  return (
    <Backdrop className={backdrop} open={open}>
      <Box position="relative" display="inline-flex">
        <CircularProgress variant="static" size={60} color="inherit" value={progress} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="body1">{progress}%</Typography>
          <Typography variant="body1">{label}</Typography>
        </Box>
      </Box>
    </Backdrop>
  )
}
export default forwardRef(DownloadingProgressBar)