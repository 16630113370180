/**
 *
 * @param {Array} data
 * @param {Object} groupBy example: {
  country_name: true,
  group_name: false,
  clinic_name: true,
  email: false
})
 */
export default function grouping(data, groupBy) {
  if (!groupBy.country && !groupBy.group_name && !groupBy.clinic_name && !groupBy.email) {
    return data
  }
  return Object.values(data.reduce((acc, d) => {
    let keys = [],
      cats = {}
    Object.entries(groupBy).forEach(([g, f]) => {
      if (f) {
        keys.push(d[g])
        cats[g] = d[g]
      }
    })
    let keyStr = keys.join(',')
    //console.log("key",keyStr)
    if (acc[keyStr]) {
      //console.log("acc inside",acc)
      acc[keyStr].count += d.orderTotal
    } else {
      acc[keyStr] = {
        ...cats,
        count: d.orderTotal
      }
    }
    //console.log(acc)
    return acc
  }, {}))
}