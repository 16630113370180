import Axios from '../axios-default'
import { ApiServerUrl } from '../config.json'
import Path from 'path'

export const fileToBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader()
  if (file) {
    reader.readAsDataURL(file)
  }
  reader.onload = () => {
    resolve(reader.result)
  }
  reader.onerror = error => reject(error)
})

export const getObjectURL = async file => {
  try {
    if (file.startsWith('/file') || /^https?:\/\//.test(file)) {
      const { data } = await Axios.get(
        /^https?:\/\//.test(file)? file : Path.join(ApiServerUrl, file),
        {
        responseType: 'blob'
        }
      )
      return URL.createObjectURL(data)
    } else if (typeof file === 'object') {
      return URL.createObjectURL(file)
    }
  } catch {}
  return null
}

export const getFormToken = (form, data, token) => {
  if (typeof data === 'string') {
    if (/https?:\/\//.test(data) || data.startsWith('/file')) {
      return data
    }
  } else if (typeof data === 'object' && data !== null) {
    form.append(token, data)
    return token
  }
  return null
}