import Box from '@material-ui/core/Box';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import externalCss from '../css/settings.module.css';
import LoginPagePhoto from './settings/LoginPagePhoto';
import PageBanner from './settings/PageBanner.js';
import Translation from './settings/Translation';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SettingTabs() {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div className={classes.root}>
      {/* <AppBar position="static" color="default"> */}
        <Tabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{
            style: {
              backgroundColor: "#593687"
            }
          }}
          variant="fullWidth"
          className={externalCss.CustomTabPanel}
          aria-label="settings tabs"
        >
          {/* <Tab className={externalCss.CustomTab} label="Translation" {...a11yProps(0)} /> */}
          <Tab className={externalCss.CustomTab} label="Login Photo" {...a11yProps(0)} />
          {/* <Tab className={externalCss.CustomTab} label="Banner" {...a11yProps(2)} /> */}
        </Tabs>
      {/* </AppBar> */}
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {/* <TabPanel value={value} index={0} dir={theme.direction}>
          <Translation/>
        </TabPanel> */}
        <TabPanel value={value} index={0} dir={theme.direction}>
          <LoginPagePhoto />
        </TabPanel>
        {/* <TabPanel value={value} index={2} dir={theme.direction}>
          <PageBanner />
        </TabPanel> */}
      </SwipeableViews>
    </div>
  );
}