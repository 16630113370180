import { Button, CircularProgress, IconButton, Fade, Grid, makeStyles, Tooltip, Icon, MenuItem, Select } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';
import ArchiveIcon from '@material-ui/icons/Archive';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import Badge from '@material-ui/core/Badge'
import { blue, grey } from '@material-ui/core/colors'
import Add from '@material-ui/icons/Add'
import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import { Message } from '@material-ui/icons'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import DraftsIcon from '@material-ui/icons/Drafts'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import GridOnIcon from '@material-ui/icons/GridOn'
import LastPage from '@material-ui/icons/LastPage'
import MailOutlinedIcon from '@material-ui/icons/MailOutlined'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import ViewListIcon from '@material-ui/icons/ViewList'
import ReplayIcon from '@material-ui/icons/Replay';
import MaterialTable from 'material-table'
import Moment from 'moment/moment'
import QueryString from 'querystring'
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState, useMemo } from 'react'
import { Animated } from "react-animated-css"
import { useBottomScrollListener } from 'react-bottom-scroll-listener'
import { useDispatch, useSelector } from 'react-redux'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import { Link, useHistory } from 'react-router-dom'
import Url from 'url'
import join from 'url-join'
import axios, { safeGet } from '../axios-default'
import Modal from '../components/Modal/modal'
import { ApiServerUrl } from '../config.json'
import { updateConfig } from '../store/config/config.action'
import { easySnackbar } from '../store/Noti/noti.actions'
import { getFromObj, useLang } from '../util/lang'
import '../util/object'
import { usePrevious } from '../util/react'
import '../util/string'
import '../util/array'
import style from './css/patients.module.css'
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import frontend_config from '../frontend_config';
import { getWebAbsPath } from '../util/path';
import { updateRes } from '../store/res/res.action';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { PublicUrl, WebPushPublicKey } from '../config.json'
var CaseType =[];

const hostname = window.location.hostname

const TableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
}
const caseStatusList =['DRAFT','SUBMITTED','AWAITING','RECEIVED','PLAN_UPLOADED','CHANGE_REQUEST','PLAN_APPROVED','PRODUCTION','DELIVERED','NEXT_SET_REQUEST','ALL_DELIVERED','ENDED','REFINEMENT']
const usePatientsStyles = makeStyles(theme => ({
  datepicker: {
  margin: "2px 0px 2px 0px",
  height: "60px",
  // width: 'fit-content',
  padding: "20px 15px 10px",
  borderBottom: "2px solid blue",
  backgroundColor: "#fff",
  color: "#2c2c2c",
  width: 300,
  fontWeight: 600
  },
  root: {
    margin: '48px auto',
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
    },
    [theme.breakpoints.up('lg')]: {
      width: "70%",
      minHeight: "400px",
    },
  },
  linkBtn: {
    background: frontend_config?.[hostname]?.main_color_1,
    color: "white",
    padding: "4px 24px",
    fontWeight: 300,
    margin: "16px 0px",
    width: "100%",
    [theme.breakpoints.up('sm')]: {
      padding: "4px 72px",
      width: "unset",
    },
    "&:hover": {
      background: frontend_config?.[hostname]?.main_color_2,
      color: "white"
    }
  },
  roundBtn: {
    background: "#00000000",
    border: `solid 1px ${frontend_config?.[hostname]?.main_color_2}`,
    color: frontend_config?.[hostname]?.main_color_2,
    padding: "4px 24px",
    fontWeight: 300,
    minWidth: "auto",
    borderRadius: "20px",
    marginTop: "8px",
    marginRight: "8px",
    "&:hover": {
      background: frontend_config?.[hostname]?.main_color_2,
      color: "white"
    }
  },
  roundBtnActive: {
    background: frontend_config?.[hostname]?.main_color_2,
    border: `solid 1px ${frontend_config?.[hostname]?.main_color_2}`,
    color: "white",
    padding: "4px 24px",
    fontWeight: 300,
    minWidth: "auto",
    borderRadius: "20px",
    marginTop: "8px",
    marginRight: "8px",
    "&:hover": {
      background: frontend_config?.[hostname]?.main_color_2,
      color: "white"
    }
  },
  activeView: {
    background: frontend_config?.[hostname]?.main_color_2,
    border: `solid 1px ${frontend_config?.[hostname]?.main_color_2}`,
    color: "white",
    fontWeight: 300,
    marginTop: "8px",
    marginRight: "8px",
    "&:hover": {
      background: "rgba(89,54,135,1)",
      color: "white"
    }
  },
  listCard: {
    borderRadius: 4,
    border: "solid #dddddd 1px",
    boxShadow: "0 1px 2px rgba(0,0,0,0.15);",
    padding: 16,
    "&:hover": {
      boxShadow: "-2px 6px 24px -9px rgba(166,130,166,1)",
      // TODO: transition top
      // transition: "box-shadow 0.2s ease-in-out, top 1s linear",
      // top: -8,
      transition: "box-shadow 0.2s ease-in-out",
      position: "relative",
      // cursor: "pointer"
    }
  },
  gridIcon: {
    width: "auto",
    height: "100px",
    [theme.breakpoints.down('sm')]: {
      height: "78px",
    },
  },
  firstLastName: {
    [theme.breakpoints.only('xs')]: {
      marginTop: "5px",
      marginBottom: "5px",
      width: "100%",
    },
  },
}))

const notificationMsg = false
var localExcluedDistr ='';
export default function Patients(props) {
localExcluedDistr = localStorage.getItem('distr_group_id')

  const classes = usePatientsStyles(),
        dispatch = useDispatch(),
        history = useHistory(),
        get = useLang('Patients'),

        [ config, countries, lang] = useSelector(store => [
          store.config.config,
          store.res.countries,
          store.lang
        ])
  const StatusList = {
          DRAFT: 'Draft',
          SUBMITTED: 'Prescription Submitted',
          AWAITING: 'Awaiting More Records{Remark: ?}',
          RECEIVED: 'Confirmed All Materials Received',
          CHANGE_REQUEST: 'Treatment Plan Change Request Received',
          PLAN_UPLOADED: 'Treatment Plan {No.?} Uploaded',
          PLAN_APPROVED: 'Treatment Plan {No.?} Approved',
          PRODUCTION: 'Production In Progress',
          DELIVERED: 'Aligners Set {No.1 ?}-{No.2 ?} Delivered',
          NEXT_SET_REQUEST: 'Request for Next Aligner Set',
          ALL_DELIVERED: 'All Aligners Sets Are Delivered',
          ALIGNER_REQUEST: 'Aligner Request',
          RETAINER_REQUEST: 'Retainer Request',
          REFINEMENT: 'Refinements Prescription Received',
          ENDED: 'Case Ended'
        }
  const [loading, setLoading] = useState(true),
        [mode, setMode] = useState(null),
        [cat, setCat] = useState(localStorage.getItem('Patients.cat') || 'ALL'),
        [searchObj, setSearchObj] = useState(JSON.parse(localStorage.getItem('Patients.searchObj')) || {}),
        [tableArchiveConfirm, settableArchiveConfirm]=useState(false),
        [tableUnarchiveConfirm, settableUnarchiveConfirm]=useState(false),
        [archiveTargetCaseId, setArchiveTargetCaseId] = useState(null),
        [unarchiveTargetCaseId, setUnarchiveTargetCaseId] = useState(null),
        [startDate, setStartDate] = useState(null),
        [endDate, setEndDate] = useState(null),
        [updatedCountry, setUpdatedCountry] = useState({})
  const res = useSelector(({ res }) => res)
  const countrySelect = useMemo(() =>
     Object.entries(res.countries).map(([id, c]) => (
       <MenuItem value={c.country_name} key={id}>{c.country_name}</MenuItem>
     ))
   , [res?.countries])
  const role = useRef(JSON.parse(localStorage.getItem("role"))),
        distr_group_id = useRef(JSON.parse(localStorage.getItem("distr_group_id"))),
        columnOrder = useRef([]),
        columnHidden = useRef([]),
        columns = useRef([]),
        isExternal = useRef(["DR", "NR", "CADMIN", "GADMIN"].includes(role.current)),
        isAdminDistributor = useRef(["ADMIN", 'SADMIN', "DISTR"].includes(role.current)),
        lastPageSize = useRef(),
        lastPageNo = useRef(0),
        tableRef = useRef(null),
        tableTopRef = useRef(null),
        gridRef = useRef(null)
  const prevCat = usePrevious(cat),
        prevMode = usePrevious(mode)
  // modal
  const [modalOpen, setModalOpen] = useState(false)

  let countryId = -1
  try {
    countryId = parseInt(JSON.parse(localStorage.getItem('country_id')))
  } catch {
    console.error('Connot get the doctor\'s country')
  }

  useEffect(() => {
    (async () => {
      try {
        const { data: { pm} } = await axios.get(`/productmatrix/productmatrixForDr`)
        CaseType=pm
        //setContent(content)
      } catch (err) {
        dispatch(easySnackbar(err.message || err, 'error'))
      }
    })()
  }, [CaseType])
  useEffect(() => {
    window.scroll({top: 0, left: 0, behavior: 'smooth' })
    onbeforeunload = () => {
      localStorage.removeItem('Patients.cat')
      localStorage.removeItem('Patients.searchObj')
      localStorage.removeItem('Patients.pageNo')
    }
    return () => {
      onbeforeunload = null
    }
  }, [])

  useEffect(() => {
    columnOrder.current = getFromObj(config, 'Patients.columnOrder')
    columnHidden.current = getFromObj(config, 'Patients.columnHidden')
    columns.current = getColumns()
    if ((getFromObj(config, 'Patients.mode')||'table') !== mode) {
      setMode(getFromObj(config, 'Patients.mode')||'table')
    }
  }, [config])

  useEffect(() => {
    if (prevCat !== undefined && prevCat !== cat) {
      if (mode === 'table' && tableRef.current) {
        tableRef.current.onQueryChange()
      } else if (mode === 'grid' && gridRef.current) {
        gridRef.current.onQueryChange(cat)
      }
    }
  }, [cat])

  const handlePageChange = useCallback(() => {
    tableTopRef.current.scrollIntoView({ behavior: 'smooth' })
  }, [tableTopRef.current])

  const reloadtable = () => {
    history.push('/blank');
    history.replace(`/`);
  }
  const handleTableArchive = useCallback(async (e) => {
    try{
        e.stopPropagation();
        if (archiveTargetCaseId === null) {
          throw new Error('Target case id is null')
        }
        let { archivedData } = await axios.post(`case/${archiveTargetCaseId}/archived`)
        await dispatch(easySnackbar('Case Archived', 'success'));
        setArchiveTargetCaseId(null)
        reloadtable();
      }catch (err){
        console.error(err);
        dispatch(easySnackbar(err.message ?? err, 'error'));
      }
  }, [archiveTargetCaseId, setArchiveTargetCaseId, dispatch])
  const handleTableUnarchive = useCallback(async (e) => {
    try{
        e.stopPropagation();
        if (unarchiveTargetCaseId === null) {
          throw new Error('Target case id is null')
        }
        let { unarchivedData } = await axios.post(`case/${unarchiveTargetCaseId}/archived/undo`)
        await dispatch(easySnackbar('Case Unarchived', 'success'));
        setUnarchiveTargetCaseId(null)
        reloadtable();
      }catch (err){
        console.error(err);
        dispatch(easySnackbar(err.message ?? err, 'error'));
      }
    }, [unarchiveTargetCaseId, setUnarchiveTargetCaseId, dispatch])
  const handleClickOpen = (e, caseId) => {
    e.stopPropagation();
    settableArchiveConfirm(true)
    setArchiveTargetCaseId(caseId)
  };
  const handleClose = (e) => {
    e.stopPropagation();
    setArchiveTargetCaseId(null)
    settableArchiveConfirm(false)
  };
  const handleUnarchiveClickOpen = (e, caseId) => {
    e.stopPropagation();
    setUnarchiveTargetCaseId(caseId)
    settableUnarchiveConfirm(true)
  };
  const handleUnarchiveClose = (e) => {
    e.stopPropagation();
    setUnarchiveTargetCaseId(null)
    settableUnarchiveConfirm(false)
  };
  const getColumns = () => {
    let columns = []
    if (role.current === 'DR' || role.current === 'NURSE') {
      columns = [
        {
          field: "unread_changes_count",
          title: get('case_list.columns.actions'),
          defaultSort: 'desc',
          render: data => (
            <>
              {cat !== 'ARCHIVED' ?
                <>
                  <IconButton onClick={(e) => handleClickOpen(e, data.case_id)}>
                    <Tooltip title={getFromObj(lang, 'dict.pages.paitents.markarchive')} placement="top">
                      <ArchiveIcon style={{color: "#212121"}}></ArchiveIcon>
                    </Tooltip>
                  </IconButton>
                </>
                : null}
              {cat === 'ARCHIVED' ?
                <>
                  <IconButton onClick={(e) => handleUnarchiveClickOpen(e, data.case_id)}>
                    <Tooltip title="Unarchive" placement="top">
                      <UnarchiveIcon style={{color: "#212121"}}></UnarchiveIcon>
                    </Tooltip>
                  </IconButton>
                </>
                : null}
              <Badge
                style={{marginRight: 16}}
                className={style.customBadge}
                badgeContent={data.unread_status_changes_count + data.unread_chat_changes_count === 0 ? 0 : `${data.unread_status_changes_count}+${data.unread_chat_changes_count}`}
              >
                {data.unread_status_changes_count + data.unread_chat_changes_count === 0 ? <IconButton disabled><DraftsIcon /></IconButton> : <MailOutlinedIcon />}
              </Badge>
            </>
          ),
        },
        {
          field: "case_status_label",
          title: get('case_list.columns.caseStatus'),
        },
        {
          field: "profile_pic",
          title: get('case_list.columns.profile'),
          export: false,
          render: data => (
            <img
              alt="profile_pic"
              style={{ width: 50, maxHeight: 75 }}
              src={data.profile_pic+'?thumb=1'}
              onError={e => {
                let u = Url.parse(e.target.src)
                e.target.src = (
                  (u.pathname === '/' || u.pathname.startsWith('/img')
                    ? ''
                    : (
                      data.gender === 'M'
                        ? getWebAbsPath('/img/maleIcon.png')
                        : (data.gender === 'F'? getWebAbsPath('/img/femaleIcon.png') : getWebAbsPath('/img/otherIcon.png'))
                    )
                  )
                )
              }}
            />
          ),
          sorting: false,
        },
        {
          field: "first_name",
          title: get(':fields.firstName'),
        },
        {
          field: "last_name",
          title: get(':fields.lastName'),
        },
        {
          field: "gender",
          title: get('case_list.columns.gender'),
          lookup: {
            "M": get(':fields.male'),
            "F": get(':fields.female'),
            "O": get(':fields.other')
          },
        },
        {
          field: "case_id",
          title: get('case_list.columns.patientID'),
        },
        {
          field: "case_type_label",
          title: get('case_list.columns.treatmentOption'),
        },
        {
          field: "approval_date",
          title: get('case_list.columns.approvalDate'),
        },
        {
          field: "expiration_date",
          title: get('case_list.columns.treatmentEndDate'),
        },
        {
          field: "age",
          title: get('case_list.columns.age'),
        },
        {
          field: "submission_date",
          title: get('case_list.columns.submissionDate'),
        },
        {
          field: "remarks",
          title: get('case_list.columns.remarks'),
          cellStyle: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: 60
          }
        },
      ]
    } else {
      columns = [
        {
          field: "unread_changes_count",
          title: get('case_list.columns.notification'),
          defaultSort: 'desc',
          render: data => (
            <>
              {cat !== 'ARCHIVED' ?
                <>
                  <IconButton onClick={(e) => handleClickOpen(e, data.case_id)}>
                    <Tooltip title={getFromObj(lang, 'dict.pages.paitents.markarchive')} placement="top">
                      <ArchiveIcon style={{color: "#212121"}}></ArchiveIcon>
                    </Tooltip>
                  </IconButton>
                </>
                : null}
              {cat === 'ARCHIVED' ?
                <>
                  <IconButton onClick={(e) => handleUnarchiveClickOpen(e, data.case_id)}>
                    <Tooltip title="Unarchive" placement="top">
                      <UnarchiveIcon style={{color: "#212121"}}></UnarchiveIcon>
                    </Tooltip>
                  </IconButton>
                </>
                : null}
              <Badge
                style={{marginRight: 16}}
                className={style.customBadge}
                badgeContent={data.unread_status_changes_count + data.unread_chat_changes_count + data.unread_private_chat_changes_count === 0 ? 0 : `${data.unread_status_changes_count}+${data.unread_chat_changes_count}+${typeof data.unread_private_chat_changes_count === 'number' && data.unread_private_chat_changes_count}`}
              >
                {data.unread_status_changes_count + data.unread_chat_changes_count + data.unread_private_chat_changes_count === 0 ? <IconButton disabled><DraftsIcon/></IconButton> : <MailOutlinedIcon />}
              </Badge>
              {data.unanswered?
                <>
                  <IconButton onClick={(e) => {
                    e.stopPropagation()
                    history.push(`/clinicalSimulation/${data.case_id}`)
                  }}>
                    <Tooltip title="Unanswered" placement="top">
                      <Message style={{color: "#212121"}}></Message>
                    </Tooltip>
                  </IconButton>
                </>
                : null}
            </>
          ),
        },
        {
          field: "case_status_label",
          title: get('case_list.columns.caseStatus'),
        },
        {
          field: "profile_pic",
          title: get('case_list.columns.profile'),
          export: false,
          render: data => (
            <img
              alt="gender"
              style={{ width: 50 }}
              src={data.profile_pic+'?thumb=1'}
              onError={e => {
                let u = Url.parse(e.target.src)
                e.target.src = (
                  (u.pathname === '/' || u.pathname.startsWith('/img')
                    ? ''
                    : (
                      data.gender === 'M'
                        ? getWebAbsPath('/img/maleIcon.png')
                        : (data.gender === 'F'? getWebAbsPath('/img/femaleIcon.png') : getWebAbsPath('/img/otherIcon.png'))
                    )
                  )
                )
              }}
            />
          ),
          sorting: false,
        },
        {
          field: "first_name",
          title: get(':fields.firstName'),
        },
        {
          field: "last_name",
          title: get(':fields.lastName'),
        },
        {
          field: "case_id",
          title: get('case_list.columns.patientID'),
        },
        {
          field: "case_type_label",
          title: get('case_list.columns.treatmentOption'),
        },
        {
          field: "ref_code",
          title: get('case_list.columns.refCode'),
        },
        {
          field: "client_code",
          title: get('case_list.columns.clientCode'),
        },
        {
          field: "approval_date",
          title: get('case_list.columns.approvalDate'),
        },
        {
          field: "expiration_date",
          title: get('case_list.columns.treatmentEndDate'),
        },
        {
          field: "country_name",
          title: get(':fields.country'),
        },
        ...(!['GADMIN', 'CADMIN'].includes(role.current)? [{
          field: "distr_name",
          title: get('case_list.columns.distributor'),
        }] : []),
        ...(role.current !== 'CADMIN'? [{
          field: "clinic_name",
          title: get('case_list.columns.clinic'),
        }] : []),
        {
          field: "doctor_name",
          title: get('case_list.columns.drName'),
        },
        {
          field: "submission_date",
          title: get('case_list.columns.submissionDate'),
        },
        {
          field: "remarks",
          title: get('case_list.columns.remarks'),
          cellStyle: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: 60
          }
        },
      ]
    }
    if (config) {
      if (Array.isArray(columnOrder.current)) {
        const orderDict = columnOrder.current.reduce((a, field, i) => {
          if (String.isNotBlank(field)) {
            a[field] = i
          }
          return a
        }, {})
        columns.sort((a, b) => orderDict[a.field] - orderDict[b.field])
      }
      if (Array.isArray(columnHidden.current)) {
        const hiddenDict = columnHidden.current.reduce((a, field) => Object.assign(a, { [field]: true }), {})
        columns = columns.map(c => Object.assign(c, { hidden: hiddenDict[c.field] }))
      }
    }
    return columns
  }
  const saveMode = async mode => {
    const userId = JSON.parse(localStorage.getItem('auth-token'))
    try {
      if (userId && getFromObj(config, 'Patients.mode') !== mode) {
        await dispatch(updateConfig({
          Patients: {
            mode
          }
        }, userId))
      }
    } catch (err) {
      console.trace(err)
      dispatch(easySnackbar(err.message || err, 'error'))
    } finally {
      setMode(mode)
    }
  }
  const saveColumnOrder = async (srcIndex, destIndex) => {
    const columnsCopy = columns.current
    const [removed] = columnsCopy.splice(srcIndex, 1)
    columnsCopy.splice(destIndex, 0, removed)
    columnOrder.current = columnsCopy.map(c => c.field)
    const userId = JSON.parse(localStorage.getItem('auth-token'))
    if (userId) {
      try {
        await updateConfig({
          Patients: {
            columnOrder: columnOrder.current
          }
        }, userId)
      } catch (err) {
        dispatch(easySnackbar(err.message || err, 'error'))
      } finally {
        columns.current = getColumns()
      }
    } else {
      dispatch(easySnackbar('User id invalid', 'error'))
    }
  }
  const saveColumnHidden = async (column, hidden) => {
     const userId = JSON.parse(localStorage.getItem('auth-token'))
     if (userId) {
       try {
         if (config) {
           if (Array.isArray(columnHidden.current)) {
             columnHidden.current = hidden? columnHidden.current.concat(column.field) : columnHidden.current.filter(v => v !== column.field)
             if (!columnHidden.current.length) {
               columnHidden.current = null
             }
             await updateConfig({
               Patients: {
                 columnHidden: columnHidden.current
               }
             }, userId)
             return
           }
         }
         if (hidden) {
           await updateConfig({
             Patients: {
               columnHidden: [column.field]
             }
           }, userId)
         }
       } catch (err) {
         console.trace(err)
         dispatch(easySnackbar(err.message || err, 'error'))
       } finally {
         columns.current = getColumns()
       }
     }
   }
  const onSearchChange = async sObj => {
    if (mode === 'table') {
      if (tableRef.current) tableRef.current.onSearchChangeDebounce(sObj)
    } else if (mode === 'grid') {
      if (gridRef.current) gridRef.current.onSearchChangeDebounce(sObj)
    }
  }
  const handleStart = async date=>{
    setStartDate( Moment(date).format('YYYY-MM-DD') )
    const newSearchObj = { ...searchObj, startDate: Moment(date).format('YYYY-MM-DD') }

    setSearchObj(newSearchObj)

    if (Moment(date).isValid()) {

      localStorage.setItem('Patients.searchObj', JSON.stringify(newSearchObj))
    } else {
      localStorage.removeItem('Patients.searchObj')
    }
    onSearchChange(newSearchObj)
  }
  const handleEnd = async date=>{
    setEndDate( Moment(date).format('YYYY-MM-DD') )
    const newSearchObj = { ...searchObj, endDate: Moment(date).format('YYYY-MM-DD') }

    setSearchObj(newSearchObj)

    if (Moment(date).isValid()) {

      localStorage.setItem('Patients.searchObj', JSON.stringify(newSearchObj))
    } else {
      localStorage.removeItem('Patients.searchObj')
    }
    onSearchChange(newSearchObj)
  }
  const handleSearch = async update => {
    const newSearchObj = { ...searchObj, ...update }

    setSearchObj(newSearchObj)
    if (Object.values(update).some(v => String.isNotBlank(v))) {
      localStorage.setItem('Patients.searchObj', JSON.stringify(newSearchObj))
    } else {
      localStorage.removeItem('Patients.searchObj')
    }
    onSearchChange(newSearchObj)
  }

  const clearFilters = async => {
    if (!Object.keys(searchObj).length == 0) {
      setSearchObj({})
      localStorage.removeItem('Patients.searchObj')
      onSearchChange({})

    }
  }

  const dataGetter = async query => {
    let { pageSize, page: pageNo, search, orderBy, orderDirection} = query
    let { searchStr, firstName, lastName, drName, paitentId, caseStatus, country, startDate, endDate, caseRefCode, clientCode  } = search ?? {}
    const CaseTypeRename = {
      type_1: "Aligner Evolve",
      type_2: "Aligner Progress",
      complete: "Complete",
      touch_up: "Touch Up +",
      simple: "Aligner",
      retainer: "Retainer",
      cbct: "CBCT Analysis"
    }
    try {
      if (orderBy && orderBy.field.endsWith('_label')) {
        orderBy.field = orderBy.field.slice(0, -6)
      }
      if (
        lastPageNo.current === 0 && localStorage.getItem('Patients.pageNo') &&
        parseInt(localStorage.getItem('Patients.pageNo')) !== lastPageNo.current
      ) {
        pageNo = parseInt(localStorage.getItem('Patients.pageNo'))
      } else if (prevCat !== undefined && prevCat !== cat && cat !== localStorage.getItem('Patients.cat')) {
        pageNo = 0
      }
      const { data } = (await axios.get(`case?${QueryString.encode({
        searchStr: typeof searchStr !== 'string' || String.isBlank(searchStr)? undefined : searchStr,
        firstName: typeof firstName !== 'string' || String.isBlank(firstName)? undefined : firstName,
        lastName: typeof lastName !== 'string' || String.isBlank(lastName)? undefined : lastName,
        drName: typeof drName !== 'string' || String.isBlank(drName)? undefined : drName,
        paitentId: typeof paitentId !== 'string' || String.isBlank(paitentId)? undefined : paitentId,
        caseStatus: typeof caseStatus !== 'string' || String.isBlank(caseStatus)? undefined : caseStatus,
        country: typeof country !== 'string' || String.isBlank(country)? undefined : country,
        caseRefCode: typeof caseRefCode !== 'string' || String.isBlank(caseRefCode)? undefined : caseRefCode,
        clientCode: typeof clientCode !== 'string' || String.isBlank(clientCode)? undefined : clientCode,
        startDate:  startDate,
        endDate:  endDate,
        sortBy: orderBy && orderBy.field,
        sortDir: orderDirection,
        statusFilter: cat,
        pageSize,
        pageNo
      })}`))
      lastPageSize.current= pageSize
      lastPageNo.current = pageNo
      localStorage.setItem('Patients.pageNo', pageNo)
      localStorage.setItem('Patients.cat', cat)
      if (loading) setLoading(false)
      return {
        data: data.case.filter(c => c.case_id >= 1).map(c => {
          const birthYear = c.date_of_birth && c.date_of_birth.match(/[0-9]{1,2}-[0-9]{1,2}-([0-9]{4})/)
          c.case_type = CaseTypeRename[c.aligner_type.toLowerCase()]

          return {
            ...c,
            unread_changes_count: `${c.unread_status_changes_count}+${c.unread_chat_changes_count}+${typeof c.unread_private_chat_changes_count === 'number'? c.unread_private_chat_changes_count : 0}`,
            profile_pic: c.profile_pic? (/^https?:\/\//.test(c.profile_pic) ? c.profile_pic : join(ApiServerUrl, c.profile_pic)) : (c.gender === 'M'? getWebAbsPath('/img/maleIcon.png') : (c.gender === 'F'? getWebAbsPath('/img/femaleIcon.png') : getWebAbsPath('/img/otherIcon.png'))),
            case_status_label: get(`:caseStatus.${c.case_status}`, c.case_status),
            case_type_label: c.product_name==='' ? c.case_type : c.product_name,
            client_code: c.client_code? c.client_code : 'N/A',
            ref_code: c.ref_code? c.ref_code : 'N/A',
            expiration_date: c.expiration_date? Moment(c.expiration_date).format('DD MMM YYYY') : 'N/A',
            approval_date: c.approval_date? Moment(c.approval_date).format('DD MMM YYYY') : 'N/A',
            submission_date: c.submission_date? Moment(c.submission_date).format('DD MMM YYYY') : 'N/A',
            age: (birthYear && birthYear.length > 1 && !isNaN(parseInt(birthYear[1])))? Moment().get('year') - parseInt(birthYear[1]) : 'N/A'
          }
        }),
        page: pageNo || 0,
        totalCount: data.total || 0
      }
    } catch (err) {
      console.error(err)
      if (err.response && err.response.statusText === 'Unauthorized') {
        localStorage.removeItem("auth-token");
        localStorage.removeItem("expirationDate");
        localStorage.removeItem("role");
        history.replace('/login')
      }
    }
  }

  return (
    <Animated animationIn="fadeIn" isVisible={!loading} animateOnMount={false}>
      <Dialog
        open={tableArchiveConfirm}
        onClose={(e) => handleClose(e)}
      >
        <DialogContent>
          <DialogContentText>Do you want to Archive this case?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={(e) => handleTableArchive(e)}>Yes</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={tableUnarchiveConfirm}
        onClose={(e) => handleUnarchiveClose(e)}
      >
        <DialogContent>
          <DialogContentText>Do you want to Unarchive this case?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUnarchiveClose}>No</Button>
          <Button onClick={(e) => handleTableUnarchive(e)}>Yes</Button>
        </DialogActions>
      </Dialog>
      <Grid container style={{
        padding: 16,
        background: "#f2f2f2"
      }}>

        {notificationMsg && <Grid item xs={12}>
          <Alert severity="info" style={{backgroundColor: "#b2c6d6", marginTop: 12}}>Scheduled maintenance will take place on 11 September 2021 12:00 UTC (20:00 HKT)</Alert>
        </Grid>}

        <Modal
          className="modal"
          modal='NewCaseModal'
          title='New Case Option'
          open={modalOpen}
          handleClose={() => setModalOpen(false)}
          modalSize='sm'
          submitButton={false}
        >
          <Grid container>
            {CaseType.filter(i => ![i.excludedDistr].flat().includes(JSON.parse(localExcluedDistr))).filter(v => ![v.excludedCountries].flat().includes(countries?.[countryId]?.iso_code_2)).map((v, _, arr) => (
              <Grid
                item xs={6}
                sm={arr.length === 4 ? 6 : 4}
                md={arr.length === 4 ? 3 : 4}
                style={{ textAlign: "center", padding: "8px 4px" }}
                key={v["code"]}
              >
                <Link to={`/patients/newPatient/${v["code"]}`} style={{ color: "unset", textDecoration: "none" }}>
                  {/* <Tooltip title={<div style={{fontSize: 15, textAlign: 'center'}}>{v["remarks"]}</div>} placement="bottom-start"> */}
                    <Button className={style.NewCaseModalButton}>
                      <img src={join(ApiServerUrl, `/file/config/${v.iconPath}`)} style={{ width: "100%", maxWidth: 100 }} />
                      <p style={{ marginBlockStart: 0, marginBlockEnd: 0 }}>{v["name"]}</p>
                    </Button>
                  {/* </Tooltip> */}
                </Link>
              </Grid>
            ))}
          </Grid>
        </Modal>

        {isExternal.current ?
          <Grid item xs={12}>
            <Button className={classes.linkBtn} onClick={() => setModalOpen(true)}>
              {get('new_case')}
            </Button>
            <hr style={{ borderTop: "solid #dbdbdb 1px" }} />
          </Grid> : ""}

        <Grid item xs={12} style={{ marginTop: 24, marginBottom: 20 }}>
          <div className="SearchConatiner">
            <input
              type="text"
              value={searchObj.searchStr ?? ''}
              onChange={({ target: { value } }) => handleSearch({ searchStr: value })}
              placeholder={get('case_list.searchPlaceHolder')}
              style={{
                width: "calc(100% - 4px)",
                fontSize: 17,
                border: "solid 1px rgba(89,54,135,1)",
                height: 32,
                paddingLeft: '0px 0px 0px 4px'
              }}
            />
          </div>
        </Grid>

        {isAdminDistributor.current?
        <Grid container spacing={24}>
        <Grid item xs={12} sm={2} style={{ marginBottom: 5 }}>
          <input
              type="text"
              value={searchObj.paitentId ?? ''}
              onChange={({ target: { value } }) => handleSearch({ paitentId: value })}
              placeholder='Patient ID' //{get(':fields.firstName')}
              style={{
                width: "95%",
                height: 32,
                fontSize: "17px",
                border: "solid 1px rgba(89,54,135,1)",
                padding: "0px 4px",
              }}
            />
        </Grid>




          <Grid item xs={12} sm={2} style={{ marginBottom: 5 }}>
            <input
                type="text"
                value={searchObj.firstName ?? ''}
                onChange={({ target: { value } }) => handleSearch({ firstName: value })}
                placeholder={get(':fields.firstName')}
                style={{
                  width: "95%",
                  height: 32,
                  fontSize: "17px",
                  border: "solid 1px rgba(89,54,135,1)",
                  padding: "0px 4px",
                }}
              />
          </Grid>
          <Grid item xs={12} sm={2}>
            <div className={classes.firstLastName}>
            <input
                type="text"
                value={searchObj.lastName ?? ''}
                onChange={({ target: { value } }) => handleSearch({ lastName: value })}
                placeholder={get(':fields.lastName')}
                style={{
                  width: "95%",
                  height: 32,
                  fontSize: "17px",
                  border: "solid 1px rgba(89,54,135,1)",
                  padding: "0px 4px",
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={2}>
            <div className={classes.firstLastName}>
            <input
                type="text"
                value={searchObj.drName ?? ''}
                onChange={({ target: { value } }) => handleSearch({ drName: value })}
                placeholder={get(':fields.doctorName')}
                style={{
                  width: "95%",
                  height: 32,
                  fontSize: "17px",
                  border: "solid 1px rgba(89,54,135,1)",
                  padding: "0px 4px"
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={2} style={{ marginBottom: 5 }}>
           <input
               type="text"
               value={searchObj.clientCode ?? ''}
               onChange={({ target: { value } }) => handleSearch({ clientCode: value })}
               placeholder={get('case_list.columns.clientCode')}//{get(':fields.firstName')}
               style={{
                 width: "95%",
                 height: 32,
                 fontSize: "17px",
                 border: "solid 1px rgba(89,54,135,1)",
                 padding: "0px 4px",
               }}
             />
         </Grid>
          <Grid item xs={12} sm={2} style={{ marginBottom: 5 }}>

           <input
               type="text"
               value={searchObj.caseRefCode ?? ''}
               onChange={({ target: { value } }) => handleSearch({ caseRefCode: value })}
               placeholder={get('case_list.columns.refCode')}
               style={{
                 width: "95%",
                 height: 32,
                 fontSize: "17px",
                 border: "solid 1px rgba(89,54,135,1)",
                 padding: "0px 4px",
               }}
             />
         </Grid>
          <Grid item xs={12} sm={3} style={{ marginBottom: 5 }}>
          <p>Submission Date From</p>


          <div style={{ padding: 8 }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
                    fullWidth
                    maxDate={Moment().add(1, 'month').toDate()}
                    views={["year", "month", "date"]}
                    value={startDate}
                    onChange={handleStart}
                    format='yyyy-MM-dd'
                    placeholder={"Start Date"}
                    okLabel={<span style={{ color: "#304257" }}>Ok</span>}
                     cancelLabel={<span style={{ color: "#304257" }}>Cancel</span>}

                  />
            </MuiPickersUtilsProvider>
          </div>
          </Grid>
          <Grid item xs={12} sm={3} style={{ marginBottom: 5 }}>
          <p>Submission Date To</p>
          <div style={{ padding: 8, }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
                    fullWidth
                    maxDate={Moment().add(1, 'month').toDate()}
                    views={["year", "month", "date"]}
                    value={endDate}
                    onChange={handleEnd}
                    format='yyyy-MM-dd'
                    placeholder={"End Date"}
                    okLabel={<span style={{ color: "#304257" }}>Ok</span>}
                    cancelLabel={<span style={{ color: "#304257" }}>Cancel</span>}
                  />
            </MuiPickersUtilsProvider>
          </div>
          </Grid>

	  <Grid item xs={12} sm={2} style={{ marginBottom: 5 }}>
        <p>{get('case_list.columns.caseStatus')}&nbsp;</p>
          <div style={{ padding: 8 }}>
        <Select
             displayEmpty
             value={searchObj.caseStatus ?? ''}
             label="CaseStatus"
             onChange={({ target: { value } }) => handleSearch({ caseStatus: value })}
           >
        {caseStatusList.map((c)=>(

             <MenuItem value={c} >{get(`:caseStatus.${c}`, c)}</MenuItem>
        ))}
        </Select>
        </div>
        </Grid>

		    <Grid item xs={12} sm={2} style={{ marginBottom: 5 }}>
        <p>{get(':fields.country')}&nbsp;</p>
          <div style={{ padding: 8 }}>
        <Select
             displayEmpty

             value={searchObj.country ?? ''}
             label="Country"
             onChange={({ target: { value } }) => handleSearch({ country: value })}
           >
             {countrySelect}
           </Select>
          </div>
        </Grid>

        <Grid item xs={12} sm={1}>
        <p>&nbsp;</p>
          {/* <IconButton style={{ height: "fit-content" }} onClick={() => this.handleClickOpen()}> */}
          <IconButton style={{margin: "-7px 12px"}} onClick={() => clearFilters()}>
            <Tooltip title="Clear all filters" placement="top">
            <ReplayIcon style={{color: frontend_config?.[hostname]?.main_color_2 }}></ReplayIcon>
            </Tooltip>
          </IconButton>
          </Grid>
        </Grid>
        : ""}

        <Grid container>
          <Grid item xs={12} sm={8} style={{ marginTop: 8 }} className={style.actionNavBar}>

            <Button
              className={cat === "ALL" ? classes.roundBtnActive : classes.roundBtn}
              onClick={() => setCat("ALL")}
            >
              {get(':const.all')}
            </Button>

            <Button
              className={cat === "UNREAD_CASELOG" ? classes.roundBtnActive : classes.roundBtn}
              onClick={() => setCat("UNREAD_CASELOG")}
            >

                Case Log Unread Message
            </Button>
            <Button
              className={cat === "UNREAD_CHATROOM" ? classes.roundBtnActive : classes.roundBtn}
              onClick={() => setCat("UNREAD_CHATROOM")}
            >

                Chatroom Unread Message
            </Button>
            <Button
              className={cat === "PENDING" ? classes.roundBtnActive : classes.roundBtn}
              onClick={() => setCat("PENDING")}
            >
              {get('categories.pending')}
            </Button>

            <Button
              className={cat === "SUBMITTED" ? classes.roundBtnActive : classes.roundBtn}
              onClick={() => setCat("SUBMITTED")}
            >
              {get('categories.inProgress')}
            </Button>

            <Button
              className={cat === "FINISHED" ? classes.roundBtnActive : classes.roundBtn}
              onClick={() => setCat("FINISHED")}
            >
              {get('categories.completed')}
            </Button>
            <Button
              className={cat === "ARCHIVED" ? classes.roundBtnActive : classes.roundBtn}
              onClick={() => setCat("ARCHIVED")}
            >
              {get('categories.archived')}
            </Button>

          </Grid>

          <Grid item xs={12} sm={4} style={{ marginTop: 8, textAlign: "right" }}>
            <Button
              className={mode === 'grid' ? classes.activeView : ""}
              style={{
                marginTop: 8
              }}
              onClick={() => saveMode('grid')}
            >
              <GridOnIcon />
            </Button>
            <Button
              className={mode === 'table' ? classes.activeView : ""}
              style={{
                marginTop: 8
              }}
              onClick={() => saveMode('table')}
            >
              <ViewListIcon />
            </Button>
          </Grid>

        </Grid>
      </Grid>

      <Grid
        container
        style={{
          padding: 16,
          background: "#f2f2f252",
          minHeight: 680
        }}
      >
        <Grid item xs={12}>
          {
            mode === 'table' && (
              <>
                <div ref={tableTopRef} />
                <MaterialTable
                  // components={{
                  //   Toolbar: props => (
                  //     <div style={{ backgroundColor: '#e8eaf5' }}>
                  //         haha
                  //     </div>
                  //   )
                  // }}
                  title={role.current === "DR" ?
                  <>
                    <p style={{fontSize: 12, fontWeight: 700, margin: "2px 0px"}}>
                      <span
                        style={{
                          borderLeft: "6px solid #ff6161",
                          paddingLeft: 6
                        }}
                      >
                        Case Row
                      </span>: Waiting for your action
                    </p>
                    <p style={{fontSize: 12, fontWeight: 700, margin: "2px 0px"}}>
                      <span
                        style={{
                          borderLeft: "6px solid #ffb961",
                          paddingLeft: 6
                        }}
                      >
                        Case Row
                      </span>: Treatment in progress
                    </p>
                    <p style={{fontSize: 12, fontWeight: 700, margin: "2px 0px"}}>
                      Notification:
                      <span
                        style={{
                          padding: "2px 8px",
                          backgroundColor: "red",
                          color: "white",
                          borderRadius: 20
                        }}
                      >
                        Status Update + Unread Message
                      </span>
                    </p>
                  </> :
                  <>
                    <p style={{fontSize: 12, fontWeight: 700, margin: "2px 0px"}}>
                      <span
                        style={{
                          borderLeft: "6px solid #ff6161",
                          paddingLeft: 6
                        }}
                      >
                        Case Row
                      </span>: Waiting for your action
                    </p>
                    <p style={{fontSize: 12, fontWeight: 700, margin: "2px 0px"}}>
                      <span
                        style={{
                          borderLeft: "6px solid #ffb961",
                          paddingLeft: 6
                        }}
                      >
                        Case Row
                      </span>: Treatment in progress
                    </p>
                    <p style={{fontSize: 12, fontWeight: 700, margin: "2px 0px"}}>
                      Notification:
                      <span
                        style={{
                          padding: "2px 8px",
                          backgroundColor: "red",
                          color: "white",
                          borderRadius: 20
                        }}
                      >
                        Status Update + Unread Msg + Unread Private Msg
                      </span>
                    </p>

                  </>
                  }
                  tableRef={tableRef}
                  columns={getColumns()}
                  data={dataGetter}
                  icons={TableIcons}
                  onColumnDragged={saveColumnOrder}
                  onChangeColumnHidden={saveColumnHidden}
                  onChangePage={handlePageChange}
                  onRowClick={(_, data) => {
                    history.push(data.case_status == 'DRAFT' ? `/patients/${data.case_id}/edit` : `/patients/${data.case_id}`)
                  }}
                  options={{
                    rowStyle: data => ({
                      borderLeft:
                        ["DRAFT", "AWAITING", "PLAN_UPLOADED"].includes(data.case_status.toUpperCase())
                          ? "solid 12px #ff6161" : data.case_status.toUpperCase() === "ENDED" ? "solid 12px grey" : "solid 12px #ffb961"
                    }),
                    initialPage: 0,
                    searchText: searchObj,
                    emptyRowsWhenPaging: false,
                    exportButton: isExternal.current? false : true,
                    exportAllData: true,
                    debounceInterval: 700,
                    columnsButton: true,
                    pageSize: 10,
                    searchFieldStyle: {
                      display: 'none'
                    },
                    pageSizeOptions: [10, 15, 30, 50, 100],
                    minBodyHeight: 700,
                    // maxBodyHeight: 1000,
                  }}
                />
              </>
            )
          }
          {
            mode === 'grid' && (
              <Grid container>
                <GridView searchStr={searchObj} data={dataGetter} ref={gridRef} />
              </Grid>
            )
          }
        </Grid>
      </Grid>
    </Animated>
  )
}

const GridView = forwardRef((props, ref) => {
  const get = useLang('Patients')
  const getPages = useLang('pages')
  const classes = usePatientsStyles(),
         dispatch = useDispatch(),
          history = useHistory();

  const [pageNo, setPageNo] = useState(0),
        [pageSize] = useState(15),
        [total, setTotal] = useState(0),
        [data, setData] = useState([]),
        [loading, setLoading] = useState(true),
        [gridArchiveconfirm, setgridArchiveconfirm] = useState(false)
  const lastSearchStr = useRef(props.searchStr),
        debounceTimer = useRef(),
        cat = useRef(localStorage.getItem('Patients.cat') || 'ALL'),
        role = useRef(JSON.parse(localStorage.getItem("role")))

  useEffect(() => {
    dataSetter(pageNo, lastSearchStr.current)()
  }, [])

  const reloadGrid = () => {
    history.push('/blank');
    history.replace(`/`);
  }
  const handleGridArchive = async (e, v) => {
      try{
        e.stopPropagation();
        // e.preventDefault();
        // debugger;
        let { archivedData } = await axios.post(`case/${v.case_id}/archived`)
        await dispatch(easySnackbar('Case Archived', 'success'));
        reloadGrid();
      }catch (err){
        console.error(err);
      }
    }
  const handleClickOpen = (e) => {
    e.preventDefault();
    setgridArchiveconfirm(true)
  };

  const handleClose = (e) => {
    e.preventDefault();
    setgridArchiveconfirm(false)
  };
  const getData = useCallback(() => {
    return loading? data.concat((new Array(4-(data.length%4))).fill(null)) : data
  }, [loading, data])

  const dataSetter = (_pageNo, search={}) => async () => {
    if (data.length < total || !_pageNo) {
      try {
        const { data: newData, page, totalCount } = await Promise.resolve(props.data({
          page: _pageNo,
          pageSize: pageSize,
          search,
          statusFilter: cat.current,
          orderBy: { field: 'unread_changes_count' },
          orderDirection: 'desc'
        }))
        if (page) {
          setData([...data, ...newData])
        } else {
          setData(newData)
        }
        setPageNo(page)
        setTotal(totalCount)
      } catch (err) {
        console.error(err)
      }
    }
    setLoading(false)
  }

  const loadMore = () => {
    setLoading(true)
    dataSetter(pageNo+1, lastSearchStr.current)()
  }
  useBottomScrollListener(loadMore)

  useImperativeHandle(ref, () => ({
    onSearchChangeDebounce(searchStr) {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current)
      }
      debounceTimer.current = setTimeout(dataSetter(0, searchStr), 700)
    },
    onQueryChange(_cat) {
      cat.current = _cat
      dataSetter(0, '')()
    }
  }))

  return (
    <>
      {getData().map((v, i) =>
        <Fade
          in={true}
          timeout={1000}
          key={`case${i}`}
        >
          <Grid
            item xs={12} sm={6} md={4} lg={3}
            style={{
              padding: 8,
              display: "block",
              transition: "display 2s ease-in-out",
              transitionDelay: "1s"
            }}
          >
            {v !== null?
              <Link
                to={v.case_status == 'DRAFT' ? `/patients/${v.case_id}/edit` : `/patients/${v.case_id}`}
                style={{
                  color: "unset",
                  textDecoration: "none"
                }}
              >
                <Grid container>
                  <Grid
                    item xs={12}
                    className={classes.listCard}
                    style={{
                      borderLeft: ["DRAFT", "AWAITING", "PLAN_UPLOADED"].includes(v.case_status.toUpperCase()) ? "solid 6px #ffb961" : v.case_status.toUpperCase() === "ENDED" ? "solid 6px grey" : "solid 6px #ff6161",
                      borderRadius: "4px",
                      background: "white",
                    }}
                  >
                    <Grid container>
                      <Grid item xs={6}>
                        <img
                          crossOrigin='use-credentials'
                          src={v.profile_pic}
                          className={classes.gridIcon}
                          onError={e => {
                            let u = Url.parse(e.target.src)
                            e.target.src = (
                              (u.pathname === '/' || u.pathname.startsWith('/img')
                                ? ''
                                : (
                                  v.gender === 'M'
                                    ? getWebAbsPath('/img/maleIcon.png')
                                    : (v.gender === 'F'? getWebAbsPath('/img/femaleIcon.png') : getWebAbsPath('/img/otherIcon.png'))
                                )
                              )
                            )
                          }}
                        />
                      </Grid>
                      <Grid item xs={6} style={{ padding: 4 }}>
                        <p style={{ fontWeight: 600 }}>{v.first_name + ", " + v.last_name}</p>
                        <p style={{ fontSize: 14 }}>{`(#${v.case_id})`}</p>
                      </Grid>

                      <hr style={{ border: "solid 1px #ededed", width: "100%" }} />


                      <Grid item xs={6}>
                        <p style={{ fontWeight: 600 }}>{get('case_list.columns.treatmentOption')}</p>
                        <p style={{ fontSize: 14 }}>{`${v.case_type_label}`}</p>
                      </Grid>
                      <Grid item xs={3} style={{ textAlign: 'center', alignSelf: 'center' }}>
                        <Badge
                          className={style.customBadge}
                          badgeContent={
                            ['DR', 'NR'].includes(role.current)
                              ? (v.unread_status_changes_count + v.unread_chat_changes_count === 0? 0 : `${v.unread_status_changes_count}+${v.unread_chat_changes_count}`)
                              : (v.unread_status_changes_count + v.unread_chat_changes_count + v.unread_private_chat_changes_count === 0 ? 0 : `${v.unread_status_changes_count}+${v.unread_chat_changes_count}+${typeof v.unread_private_chat_changes_count === 'number' && v.unread_private_chat_changes_count}`)
                          }
                        >
                          {v.unread_status_changes_count + v.unread_chat_changes_count + (['DR', 'NR'].includes(role.current) ? 0 : v.unread_private_chat_changes_count) === 0 ? <DraftsIcon /> : <MailOutlinedIcon />}
                        </Badge>
                      </Grid>
                      {cat !== 'ARCHIVED' && ['DR', 'NR'].includes(role.current) ?
                      <Grid item xs={3} style={{ textAlign: 'center', alignSelf: 'center' }}>
                        <><IconButton onClick={(e) => handleClickOpen(e)}>
                          <Tooltip title={getPages('paitents.markarchive')} placement="top">
                            <ArchiveIcon style={{color: "#212121"}}></ArchiveIcon>
                          </Tooltip>
                        </IconButton>
                        <Dialog
                          open={gridArchiveconfirm}
                          onClose={(e) => handleClose(e)}
                        >
                          <DialogContent>
                            <DialogContentText>Do you want to Archive this case?</DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={handleClose}>No</Button>
                            <Button onClick={(e) => handleGridArchive(e, v)}>Yes</Button>
                          </DialogActions>
                        </Dialog></>
                      </Grid> : ''
                      }
                      <Grid item xs={12}>
                        <p style={{ fontWeight: 600 }}>{get('case_list.columns.caseStatus')}</p>
                        <p style={{ fontSize: 14 }}>{v.case_status_label}</p>
                      </Grid>
                
                      <Grid item xs={12}>
                        <p style={{ fontWeight: 600 }}>{get('case_list.columns.drName')}</p>
                        <p style={{ fontSize: 14 }}>{`${v.doctor_name}`}</p>
                      </Grid>
                      { ['ADMIN', 'SADMIN','DISTR'].includes(role.current) &&
                      <Grid item xs={12}>
                        <p style={{ fontWeight: 600 }}>{get('case_list.columns.refCode')}</p>
                        <p style={{ fontSize: 14 }}>{`${v.refCode}`}</p>
                      </Grid>}
                      { ['ADMIN', 'SADMIN','DISTR'].includes(role.current) &&
                      <Grid item xs={12}>
                        <p style={{ fontWeight: 600 }}>{get('case_list.columns.clientCode')}</p>
                        <p style={{ fontSize: 14 }}>{`${v.clientCode}`}</p>
                      </Grid>
                      }
                      <Grid item xs={12}>
                        <p style={{ fontWeight: 600 }}>{get('case_list.columns.submissionDate')}</p>
                        <p style={{ fontSize: 14 }}>{`${v.submission_date}`}</p>
                      </Grid>
                      <Grid item xs={12}>
                        <p style={{ fontWeight: 600 }}>{get('case_list.columns.approvalDate')}</p>
                        <p style={{ fontSize: 14 }}>{`${v.approval_date}`}</p>
                      </Grid>

                    </Grid>
                  </Grid>
                </Grid>
              </Link> :
              <Grid
                container
                item xs={12}
                className={classes.listCard}
                style={{
                  minHeight: 400,
                  borderLeft: "solid 6px #888888",
                  borderRadius: "4px",
                  backgroundColor: grey[200],
                  display: 'flex',
                  justifyContent: 'center'
                }}
                justify='center' alignItems='center'
              >
                <CircularProgress style={{ color: blue[400] }} />
              </Grid>
            }
          </Grid>
        </Fade>
      )}
      <Button
        fullWidth
        style={{
          display: data.length < total? undefined : 'none',
          heigth: 5,
          marginTop: 5,
          backgroundColor: grey[300]
        }}
        onClick={() => loadMore()}
      >
        <Add color='white'/>
      </Button>
    </>
  )
})
