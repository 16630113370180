import { AppBar, Breadcrumbs, Button, Grid, Tab, Tabs, Typography, Checkbox } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useHistory, useParams } from 'react-router'
import clsx from 'clsx';
import join from 'url-join'
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState , useCallback, useMemo} from 'react';
import { Link, withRouter } from 'react-router-dom';
import Url from 'url';
import axios, { safeGet } from '../axios-default';
import Chat from '../components/chat/chat';
import StlViewer from '../components/ClinicalSimulation/StlViewer';
import UploadStl from '../components/ClinicalSimulation/UploadStl';
import ConfirmDialog from '../components/Dialog/ConfirmDialog';
import { ApiServerUrl } from '../config.json';
import { useLang } from '../util/lang';
import './css/ClinicalSimulation.css';
import { useSelector } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import Draggable from 'react-draggable';
import { getWebAbsPath } from '../util/path';
import Moment from 'moment/moment'
import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '../components/Modal/modal'
import RecommendedDays from "../components/TreatmentApproval/RecommendedDays"
import { easySnackbar } from "../store/Noti/noti.actions"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  progress: {

    padding: 5,
    left: 16,
    top: 5,
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
  hideSimulation: {
    display:'none'
  },
}));


function StyledRadio(props) {
  const classes = useStyles();
  return (
    <Radio
      className={classes.root}
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

function TabPanel(props) {

  const { children, value, index, ...other } = props;
  const classes = useStyles();


  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{
        padding: 12
      }}
    >
      {value === index && <div>{children}</div>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



function ClinicalSimulation() {


  const downloadFile = (event,file) => {
    setDownloading(true)
    let date = Moment().format('YYYY-MM-DD')
    axios.get(`case/download_myo/${file}`, {responseType: 'blob'}).then( ({data}) => {
      let url = window.URL.createObjectURL(new Blob([data]))
      Object.assign(document.createElement("a"), { download: `${caseId}_${date}.myo`, href: url }).click()
      window.URL.revokeObjectURL(url)
      setDownloading(false)
    })
  }
  const classes = useStyles()
  const [value, setValue] = useState(0),
        [currentStlPairNo, setCurrentStlNo] = useState(1),
        [stlPairs, setStlPairs] = useState([]),
        [downloading, setDownloading] = useState(false),
        [treatmentPlan, setTreatmentPlan] = useState([]),
        [confirmOpenNextAligner, setConfirmOpenNextAligner] = useState(false),
        [currentTreatmentPlan, setCurrentTreatmentPlan] = useState({}),
        [currentTreatmentPlanList, setCurrentTreatmentPlanList] = useState([]),
        [stls, setStls] = useState([]),
        [chatRomWidth, setChatRomWidth] = useState(3),
        [hideTabs, setHideTabs] = useState(false),
        [simulationWidth, setSimulationWidth] = useState(9),
        [hideSimulation, setHideSimulation] = useState(false),
        [draggable, setDraggable] = useState(false),
        [exitFullScreen, setExitFullScreen] = useState(false),
        [dragPosition, setDragPosition] = useState(false),
        // [enableDistrAlert, setEnableDistrAlert] = useState(false),
        // [distrAlertMsg1, setDistrAlertMsg1] = useState(false),
        // [distrAlertMsg2, setDistrAlertMsg2] = useState(false),
        // [distrData, setDistrData] = useState([]),
        // [distrMissingMsg, setDistrMissingMsg] = useState(true),
        [patientInfo, setPatientInfo]= useState({}),
        [ModalState, setModalState] = useState(""),
        [selectedPlan, setSelectedPlan] = useState(null),
        [RecommendedDaysModal, setRecommendedDaysModal] = useState(false),
        [RecommendedDaysModalTitle, setRecommendedDaysModalTitle] = useState(""),
        [RecommendedDaysModalSubmitButton, setRecommendedDaysModalSubmitButton] = useState(false),
        [RecommendedDaysModalSubmitLabel, setRecommendedDaysModalSubmitLabel] = useState(""),
        [RecommendedDaysModalCloseLabel, setRecommendedDaysModalCloseLabel] = useState(""),
        [approve_question, setApprove_question] = useState(""),
        [aligners, setAligners] = useState([])

  const viewer = useRef(null)
  const { id: caseId, plan_id } = useParams()
  const { config } = useSelector(store => ({
    config: store.config.config
  }))
  // const distrAlertModelData ={}
  const get = useLang('PatientInfo')
  const showSnackbar = (message, variant, queue = false) => easySnackbar(message, variant, queue)
  // useEffect(() => {
  //   let result = JSON.parse('{"data":[{"id": 1, "msg1": "test1","msg2": "test2", "distr_id": 23, "enable_flag":"true"}] }')
  //   axios.get(`distrmsg/getdistrmsg`).then((res) => {
  //     console.log(res)
  //   setDistrData(res.data.dm)
  // }).catch(error => {
  //   console.error(error)
  // })
  // },[])
  useEffect(() => {
     document.title = "Clinical Simulation"
  }, [])

  // useEffect(()=>{
  //   if (enableDistrAlert){
  //     if (distrAlertMsg1 && distrAlertMsg2){
  //        setDistrMissingMsg(false)
  //     }else{
  //        setDistrMissingMsg(true)
  //     }
  //   }
  // },[distrAlertMsg1, distrAlertMsg2])

  useEffect(() => {
    axios.get(`case/stl_pair/${caseId}`).then((res) => {
      if (res.data.stl_pair.stl_pair.length > 0) {
        setStlPairs(res.data.stl_pair.stl_pair);
        setStls([
          join(ApiServerUrl, res.data.stl_pair.stl_pair[0]['stl0']),
          join(ApiServerUrl, res.data.stl_pair.stl_pair[0]['stl1']),
        ])
      }
    }).catch(error => {
      console.error(error)
    })
    axios.get(`case/treatment_plan/${caseId}`).then((res) => {
      if (res.data.treatment_plan.length && res.data.treatment_plan?.[0]?.[0]) {
        const curTp = res.data.treatment_plan.slice(-1)[0]
        setCurrentTreatmentPlanList(curTp)
        if (isNaN(plan_id)) {
          setCurrentTreatmentPlan(curTp.find(v => v.approved_flag) ?? curTp[0])
        } else {
          res.data.treatment_plan.flat(3).some(plan => {
            if (plan.plan_id == parseInt(plan_id)) {
              setCurrentTreatmentPlan(plan);
            }
          })
        }
        setTreatmentPlan(res.data.treatment_plan);
      }
    }).catch(error => {
      console.error(error)
    })
    // safeGet(`case/simulation/${caseId}`).then(({ data }) => {
    //   console.log(data)
    // }).catch(err => {
    //   console.error(err)
    // })
    axios.get(`case/${caseId}`).then((res) => {

      const [_case] = res.data.case
      const patientInfoData = {
        id: _case.case_id,
        firstName: _case.first_name,
        lastName: _case.last_name,
        case_status:_case.case_status,
      }

      setPatientInfo(patientInfoData)

    }).catch(err => {
      console.error(err)
    })
  }, [])

  const handleChangeStlPair = (event, newValue) => {
    setCurrentStlNo(newValue);
    let currentStls = stlPairs.filter(v => {
      return v.pair_no === newValue
    })
    setStls([
      join(ApiServerUrl, currentStls[0]['stl0']),
      join(ApiServerUrl, currentStls[0]['stl1']),
    ])
  };

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  const handleProcessNextSet = (val) => {
    setConfirmOpenNextAligner(val)
  }

  const handleChangeStatusUpdate = async (value) => {

    try {
      var { data } = await axios.post(`case/status/${caseId}/${value}`, { caseId: caseId, newStatus: value })
      window.location.reload();
    }
    catch (err) {
      console.error("error", err.response)
    }
  }

  const handleCurrentTreatmentPlan = ({ target: { value } }) => {
    treatmentPlan.flat(3).some(plan => {
      if (plan.plan_id == value) {
        setCurrentTreatmentPlan(plan)
        return true
      }
      return false
    })
    // console.log(currentTreatmentPlan,parseInt(event.target.value),currentTreatmentPlanList)
  };


  const handleOpen = (modalType, ModalTypeState = "view", args) => {
    switch (modalType) {
      case "recommended_days":
        let title = "Aligners", submitButton = false, submitLabel = "Update", closeLabel = get(':const.close'), approve_question = ""
        if (ModalTypeState == "edit") {
          title = get('treatmentPlan.approvePlan')
          submitLabel = get(':const.yes')
          closeLabel = get(':const.no')
          approve_question = "Are you sure you want to approve this treatment plan?"
        }
        if (JSON.parse(localStorage.getItem("role")) == "DR") {
          submitButton = true
        }
        
        setRecommendedDaysModal(true)
        setModalState(ModalTypeState)
        setSelectedPlan(args)
        setRecommendedDaysModalTitle(title)
        setRecommendedDaysModalSubmitButton(submitButton)
        setRecommendedDaysModalSubmitLabel(submitLabel)
        setRecommendedDaysModalCloseLabel(closeLabel)
        setApprove_question(approve_question)
        break
    }
  }

  const handleClose = (modalType) => {
    switch (modalType) {
      case "recommended_days":
        setRecommendedDaysModal(false)
        break
    }
  }

  const updateRecommendedDays = (val, index) => {
    if (JSON.parse(localStorage.getItem("role")) == "DR") {
      let tmp_aligners = [...aligners]
      aligners.map((v, i) => {
        let aligner = {...tmp_aligners[i]}
        if (i >= index) {
          aligner.days_for_aligner = val ? parseInt(val) : 0
        }
        tmp_aligners[i] = aligner
      })
      setAligners(tmp_aligners)
    }
  }

  const saveRecommendedDays = async () => {
    if (JSON.parse(localStorage.getItem("role")) == "DR") {
      let planId = selectedPlan.plan_id
      if (aligners) {
        let invalidDays = false
        aligners.map(v => {
          if (!v.days_for_aligner || v.days_for_aligner == 0) {
            invalidDays = true
          }
        })

        if (invalidDays) {
          return alert("Recommended days must be at least 1 day")
        }
      }
      try {
        if (ModalState == "view") {
          await axios.post(
            `case/${caseId}/update_recommended_days/${planId}`,
            {case_aligner: aligners}
          )
          await showSnackbar(
            `Recommended days updated`,
            "success",
            true
          )
        } else {
          await axios.post(
            `case/${caseId}/treatment_plan/${planId}/approve`,
            {case_aligner: aligners}
          )
          await showSnackbar(
            `Treatment plan #${planId} approved`,
            "success",
            true
          )
        }
        window.location.reload()
      } catch (e) {
        // this.setState({
        //   error: "Cannot change the status please connect with admin",
        // });
        console.error(e.response);
      }
    }
  }

  const handleApprovePlan = async (plan_id) => {

    try {
      let { data } = await axios.post(`case/${caseId}/treatment_plan/${plan_id}/approve`, { caseId: caseId, planId: plan_id })
      if (data.success) {
        window.location.reload()
      }
    }
    catch (e) {
      console.error(e.response)
    }

  }

  const widths = [3, 6, 9, 12]

  const handleNewChatRoom = () => {

    // hide tab and add second chatroom
    setHideTabs(true)

    // set simulation to width md=4
    setSimulationWidth(widths[0])

    // set ChatRoom width to md=4
    setChatRomWidth(widths[0])

  }
  const handleVerticalSplit = () => {
    setHideSimulation(hideSimulation => !hideSimulation);
    if(chatRomWidth === 3){
      setChatRomWidth(7)
    }else{
      setChatRomWidth(3)
    }
  }
  const dragableChatRoom = () => {
    setDraggable(draggable => !draggable);
    setSimulationWidth(12)

    if(chatRomWidth == 0){
      setSimulationWidth(9)
      setChatRomWidth(3)
    }

    if(chatRomWidth === 3){
      setChatRomWidth(0)
    }
    // dragPosition, setDragPosition
    setDragPosition(dragPosition => !dragPosition);
  }
  // Fullscreen Simulation
  const handleFullScreen = () => {
    setExitFullScreen(exitFullScreen => !exitFullScreen);
  }

  return (
    <div className="container">
      <ConfirmDialog
        title="Process Next?"
        open={confirmOpenNextAligner}
        setOpen={(val) => handleProcessNextSet(val)}
        onConfirm={() => handleChangeStatusUpdate('NEXT_SET_REQUEST')}
      >
        {get('clinicalSimulation.msg.nextSet')}
      </ConfirmDialog>

      <Grid container spacing={2} style={{ position: "relative"}}>
        <Grid item xs={12}
          md={simulationWidth}
          className={ `${hideSimulation? classes.hideSimulation : ""}`}
        >
          <div className={classes.root}>

            <TabPanel value={value} index={0}>
              <Grid container>
                {JSON.parse(localStorage.getItem('role')) === "DR" || JSON.parse(localStorage.getItem('role')) === "NR" ?
                  <Grid
                    item xs={12}
                    style={{
                      background: "linear-gradient(42deg, #646873 0%, #646873 100%)",
                      padding: 6
                    }}
                  >
                    {
                      treatmentPlan.length > 0 &&
                      <>

                        {
                          patientInfo?.case_status ==='PLAN_UPLOADED' &&
                          currentTreatmentPlan && !currentTreatmentPlan.approved_flag &&
                          currentTreatmentPlanList.find(v => v.plan_id === currentTreatmentPlan.plan_id) &&
                          currentTreatmentPlanList.every(v => !v.approved_flag) &&
                          <>
                            <Button
                              // onClick={() => handleApprovePlan(currentTreatmentPlan.plan_id)}
                              onClick={() => handleOpen("recommended_days", "edit", currentTreatmentPlan)}
                              style={{
                                border: "solid 1px white",
                                color: "white",
                                padding: "4px 24px",
                                fontWeight: 300,
                                margin: "4px 12px 4px 4px",
                                borderRadius: 0,
                                width: "fit-content"
                              }}
                            >
                              {get('clinicalSimulation.buttons.approveTreatmentPlan')}
                            </Button>
                          </>
                        } <p style={{color:"white"}}>{currentTreatmentPlan.approved_flag? "Treatment plan current " +currentTreatmentPlan.plan_no + " Status: Approved":"" }</p>

                      </>
                    }
                  </Grid>
                  : ""}
              </Grid>
              <FormControl component="fieldset">
                <RadioGroup row aria-label="position" name="position" value={currentTreatmentPlan?.plan_id?.toString() ?? ""} onChange={handleCurrentTreatmentPlan}>
                  {
                    treatmentPlan.length > 0 ?
                      treatmentPlan.map((plans, _refNo) =>
                        plans.map(plan => {
                          const refNo = plan.refinement_no >= _refNo ? plan.refinement_no : _refNo
                          return (
                            <FormControlLabel
                              value={plan.plan_id?.toString()}
                              key={refNo} control={<StyledRadio />}
                              label={`${get(':fields.treatmentPlan')} ${plan.plan_no} ${refNo > 0 ? `(R${refNo})` : ''}`}
                            />
                          )
                        })
                      )
                      // treatmentPlan.flat(3).map((plan, index) => (
                      //   <FormControlLabel value={plan.plan_id?.toString()} key={index} control={<StyledRadio />} label={`${get(':fields.treatmentPlan')} ${plan.plan_no} ${index > 0? `(R${index})` : ''}`} />
                      // ))
                      : <p>{get('clinicalSimulation.msg.noTreatmentPlan')}</p>
                  }
                </RadioGroup>
              </FormControl>
              <span>
              {currentTreatmentPlan.myo_url && ["ADMIN", "SADMIN"].includes(JSON.parse(localStorage.getItem('role'))) &&
                <Button onClick={(e) => downloadFile(e,currentTreatmentPlan.myo_pid)} variant="outlined">Download</Button>
              }
              {downloading?<CircularProgress className={classes.progress} color="secondary"  size={20} />: <></> }
              </span>
              {currentTreatmentPlan && currentTreatmentPlan.url && /^https?:\/\/.*uuid/.test(currentTreatmentPlan.url) &&
                <iframe id="ifrmbox"
                  src={
                    /^https?:\/\//.test(currentTreatmentPlan.url)
                      ? currentTreatmentPlan.url
                      : 'https://' + currentTreatmentPlan.url
                  }
                  style={{ border: "none", width: "100%", height: "800px" }}></iframe>
              }
              {currentTreatmentPlan && currentTreatmentPlan.url && !/^https?:\/\/.*uuid/.test(currentTreatmentPlan.url) && /^https?:\/\/idesignview/.test(currentTreatmentPlan.url) &&
                <iframe id="ifrmbox"
                  src={
                    /^https?:\/\//.test(currentTreatmentPlan.url)
                      ? currentTreatmentPlan.url +'&userid='+ JSON.parse(localStorage.getItem('auth-token'))
                      : 'https://' + currentTreatmentPlan.url+'&userid='+JSON.parse(localStorage.getItem('auth-token'))
                  }
                  style={{ border: "none", width: "100%", height: "800px" }}></iframe>
              }
            </TabPanel>
            <TabPanel
              value={value}
              index={1}
              style={{
                border: "none",
                padding: "0",
                width: "100%",
                height: "513px"
              }}
            >
              <Grid container style={{ display: stls && stls.length > 0 ? undefined : 'none' }}>

                <Grid
                  item xs={12}
                  style={{
                    background: "linear-gradient(42deg, #445994 0%, #445994 100%)",
                    padding: 6
                  }}
                >
                  <Button
                    // className={classnames(externalCss.buttonPulse)}
                    onClick={() => viewer.current.anter()}
                    style={{
                      color: "white",
                      padding: "4px 18px 4px 6px",
                      fontWeight: 300,
                      borderRadius: 0,
                      margin: "4px 8px 4px 0px",
                      width: "fit-content",
                      borderRight: "dashed white 2px"
                    }}
                  >
                    <img
                      src={getWebAbsPath("/img/stl-viewer/teeth_all.png")}
                      style={{
                        width: 24,
                        height: 24,
                        marginRight: 8
                      }}
                    />
                    {get('clinicalSimulation.buttons.all')}
                  </Button>

                  <Button
                    // className={classnames(externalCss.buttonPulse)}
                    onClick={() => viewer.current.upperOnly()}
                    style={{
                      color: "white",
                      padding: "4px 6px",
                      fontWeight: 300,
                      margin: "4px 8px 4px 0px",
                      width: "fit-content"
                    }}
                  >
                    <img
                      src={getWebAbsPath("/img/stl-viewer/teeth_up.png")}
                      style={{
                        width: 24,
                        height: 24,
                        marginRight: 8
                      }}
                    />
                    {get('clinicalSimulation.buttons.upper')}
                  </Button>

                  <Button
                    // className={classnames(externalCss.buttonPulse)}
                    onClick={() => viewer.current.lowerOnly()}
                    style={{
                      color: "white",
                      padding: "4px 18px 4px 6px",
                      fontWeight: 300,
                      borderRadius: 0,
                      margin: "4px 8px 4px 0px",
                      width: "fit-content",
                      borderRight: "dashed white 2px"
                    }}
                  >
                    <img
                      src={getWebAbsPath("/img/stl-viewer/teeth_down.png")}
                      style={{
                        width: 24,
                        height: 24,
                        marginRight: 8
                      }}
                    />
                    {get('clinicalSimulation.buttons.lower')}
                  </Button>

                  <Button
                    // className={classnames(externalCss.buttonPulse)}
                    onClick={() => viewer.current.anter()}
                    style={{
                      color: "white",
                      padding: "4px 6px",
                      fontWeight: 300,
                      margin: "4px 8px 4px 0px",
                      width: "fit-content"
                    }}
                  >
                    <img
                      src={getWebAbsPath("/img/stl-viewer/tmove_reset.png")}
                      style={{
                        width: 24,
                        height: 24,
                        marginRight: 8
                      }}
                    />
                ANTER
              </Button>

                  <Button
                    // className={classnames(externalCss.buttonPulse)}
                    onClick={() => viewer.current.maxil()}
                    style={{
                      color: "white",
                      padding: "4px 6px",
                      fontWeight: 300,
                      margin: "4px 8px 4px 0px",
                      width: "fit-content"
                    }}
                  >
                    <img
                      src={getWebAbsPath("/img/stl-viewer/tmove_up.png")}
                      style={{
                        width: 24,
                        height: 24,
                        marginRight: 8
                      }}
                    />
                    {get('clinicalSimulation.buttons.maxil')}
                  </Button>

                  <Button
                    // className={classnames(externalCss.buttonPulse)}
                    onClick={() => viewer.current.mand()}
                    style={{
                      color: "white",
                      padding: "4px 6px",
                      fontWeight: 300,
                      margin: "4px 8px 4px 0px",
                      width: "fit-content"
                    }}
                  >
                    <img
                      src={getWebAbsPath("/img/stl-viewer/tmove_down.png")}
                      style={{
                        width: 24,
                        height: 24,
                        marginRight: 8
                      }}
                    />
                    {get('clinicalSimulation.buttons.mand')}
                  </Button>

                  <Button
                    // className={classnames(externalCss.buttonPulse)}
                    onClick={() => viewer.current.right()}
                    style={{
                      color: "white",
                      padding: "4px 6px",
                      fontWeight: 300,
                      margin: "4px 8px 4px 0px",
                      width: "fit-content"
                    }}
                  >
                    <img
                      src={getWebAbsPath("/img/stl-viewer/tmove_right.png")}
                      style={{
                        width: 24,
                        height: 24,
                        marginRight: 8
                      }}
                    />
                    {get('clinicalSimulation.buttons.right')}
                  </Button>

                  <Button
                    // className={classnames(externalCss.buttonPulse)}
                    onClick={() => viewer.current.left()}
                    style={{
                      color: "white",
                      padding: "4px 6px",
                      fontWeight: 300,
                      margin: "4px 8px 4px 0px",
                      width: "fit-content"
                    }}
                  >
                    <img
                      src={getWebAbsPath("/img/stl-viewer/tmove_left.png")}
                      style={{
                        width: 24,
                        height: 24,
                        marginRight: 8
                      }}
                    />
                    {get('clinicalSimulation.buttons.left')}
                  </Button>
                </Grid>
              </Grid>

              {JSON.parse(localStorage.getItem('role')) === "DR" || JSON.parse(localStorage.getItem('role')) === "NR" ?
                <Grid
                  item xs={12}
                  style={{
                    background: "linear-gradient(42deg, #646873 0%, #646873 100%)",
                    padding: 6
                  }}
                >
                  {
                    treatmentPlan.length > 0 &&
                    <>
                      {
                        Object.keys(currentTreatmentPlan).length > 0 && !currentTreatmentPlan.approved_flag &&
                        <>
                          <Button
                            onClick={() => handleApprovePlan(currentTreatmentPlan.plan_id)}
                            style={{
                              border: "solid 1px white",
                              color: "white",
                              padding: "4px 24px",
                              fontWeight: 300,
                              margin: "4px 12px 4px 4px",
                              borderRadius: 0,
                              width: "fit-content"
                            }}
                          >
                            {get('clinicalSimulation.buttons.approveTreatmentPlan')}
                          </Button>
                        </>
                      }
                      <Button
                        onClick={() => handleProcessNextSet(true)}
                        style={{
                          border: "solid 1px white",
                          color: "white",
                          padding: "4px 24px",
                          fontWeight: 300,
                          margin: "4px 0px",
                          borderRadius: 0,
                          width: "fit-content"
                        }}
                      >
                        {get('clinicalSimulation.buttons.processToNextSet')}
                      </Button>
                    </>
                  }
                </Grid> : ""}


              {stls && stls.length > 0 ?
                <StlViewer
                  style={{
                    border: "none",
                    width: "100%",
                    height: "100%"
                  }}
                  ref={viewer}
                  stls={stls}
                  modelColor='#555555'
                  backgroundColor='#d9d9d9'
                  rotate={false}
                  orbitControls={true}
                /> : <p style={{ textAlign: "center", padding: "20px", fontSize: "20px" }}>{get('caseStatusLabel.msg.noStlFiles')}</p>}

              <Grid
                item xs={12}
                style={{
                  display: stls && stls.length > 0 ? undefined : 'none',
                  background: "linear-gradient(42deg, #646873 0%, #646873 100%)",
                  padding: 6
                }}
              >
                {
                  //TODO: get stl pairs
                  stlPairs.map((v, i) =>
                    <Button
                      // className={classnames(externalCss.buttonPulse)}
                      disabled={currentStlPairNo === v.pair_no ? true : false}
                      onClick={(e) => handleChangeStlPair(e, v.pair_no)}
                      key={i}
                      style={{
                        border: "solid 1px white",
                        color: "white",
                        padding: "4px",
                        fontWeight: 300,
                        margin: "4px 10px 4px 0px",
                        borderRadius: 0,
                        minWidth: "unset",
                        width: 38,
                        background: (currentStlPairNo === v.pair_no) ? "black" : ""
                      }}
                    >
                      {i + 1}
                    </Button>
                  )
                }
              </Grid>
            </TabPanel>

            <TabPanel value={value} index={2}>
              {/* Add Upload Stl */}
              <UploadStl caseId={caseId} />
            </TabPanel>
          </div>
        </Grid>
        {draggable?
          <Draggable handle=".handle">
            <div className="handle">
              <Chat
                caseId={caseId}
                handleNewChatRoom={handleNewChatRoom}
                hideTabs={hideTabs}
                chatRomWidth={chatRomWidth}
                dragableChatRoom={dragableChatRoom}
                exitFullScreen={exitFullScreen}
                handleFullScreen={handleFullScreen}
                dragPosition={dragPosition}
              />
            </div>
          </Draggable>
        :
          <Chat
            caseId={caseId}
            handleNewChatRoom={handleNewChatRoom}
            chatRomWidth={chatRomWidth}
            hideTabs={hideTabs}
            dragableChatRoom={dragableChatRoom}
            exitFullScreen={exitFullScreen}
            handleFullScreen={handleFullScreen}
          />
        }
        {/* Second ChatRoom */}
        { hideTabs?
          <Chat
            caseId={caseId}
            handleNewChatRoom={handleNewChatRoom}
            chatRomWidth={chatRomWidth}
            hideTabs={hideTabs}
            handleVerticalSplit={handleVerticalSplit}
            hideSimulation={hideSimulation}
          />
          : <div></div>
        }
      </Grid>

      <Modal
        className="modal"
        modal='recommended_days'
        title={RecommendedDaysModalTitle}
        open={RecommendedDaysModal}
        modalSize="sm"
        submitButton={RecommendedDaysModalSubmitButton}
        submitButtonLabel={RecommendedDaysModalSubmitLabel}
        closeButtonLabel={RecommendedDaysModalCloseLabel}
        handleDataSave={saveRecommendedDays}
        handleClose={() => handleClose('recommended_days')}
      >
        <RecommendedDays
          selectedPlan={selectedPlan}
          setAligners={setAligners}
          updateRecommendedDays={updateRecommendedDays}
          aligners={aligners}
        />
        {approve_question}
      </Modal>
    </div>
  );
}

export default (withRouter((ClinicalSimulation)))
