import '../../../util/array'
import React, { PropTypes, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import NewPatientPhoto from './NewPatientPhoto'
import style from './NewPatientPhotos.module.css'
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { useLang } from '../../../util/lang';
import { useSelector, useDispatch } from 'react-redux';
import { NEW_PATIENT_FILE_SHORTCUT } from '../../../store/NewPatient/actionType';

const StlImpression = (props) => {
  const [upload_without_STLfile, setStlFileStatus] = useState(false);
  const get = useLang('NewPatient')

  const dispatch = useDispatch()
  const { files } = useSelector(store => ({
    files: store.newPatientReducer.files
  }))

  // function handleNoImage() {
  //   props.handleChange("impression_upper", "upload_later")
  //   props.handleChange("impression_lower", "upload_later")
  //   props.handleChange("impression_scan_one", "upload_later")
  //   props.handleChange("impression_scan_two", "upload_later")

  //   setStlFileStatus(!upload_without_STLfile)
  // }

  const isUploadLater = () => files.filter(v => v.id.startsWith('impression')).every(v => v.upload_later)

  const setUploadLater = bFlag => {
    [ "impression_upper", "impression_lower", "impression_scan_one", "impression_scan_two" ].asyncForEach(id =>
      dispatch({
        type: NEW_PATIENT_FILE_SHORTCUT,
        subtype: 'upload_later',
        id,
        value: bFlag
      })
    )
  }

  // function checkUploadLater() {
  //   let image_data = props.imageData("impression_upper")
  //   return image_data.upload_later
  // }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={3}>
        <li>
          <NewPatientPhoto
            mode={props.mode}
            imageId="impression_upper"
            imageUploaded={props.imageUploaded("impression_upper")}
            imageData={props.imageData("impression_upper")}
            imageName="imageUpload.png"
            type="impression"
            //label="STL"
            StlLabel={get('impressions.upper_stl') != "N/A" ? get('impressions.upper_stl') : "Upper STL"}
            StlWillNotUploadLabel={get('impressions.notUploadWarning')}
            upload_without_STLfile={isUploadLater()}
            // handleRoatate={(degree) => { props.handleRoatate("impression_upper", degree) }}
            handleChange={(file) => props.handleChange("impression_upper", file)} />
        </li>
      </Grid>
      <Grid item xs={12} sm={3}>
        <li>
          <NewPatientPhoto
            mode={props.mode}
            imageId="impression_lower"
            imageUploaded={props.imageUploaded("impression_lower")}
            imageData={props.imageData("impression_lower")}
            imageName="imageUpload.png"
            type="impression"
            //label="STL"
            StlLabel={get('impressions.lower_stl') != "N/A" ? get('impressions.lower_stl') : "Lower STL"}
            StlWillNotUploadLabel={get('impressions.notUploadWarning')}
            upload_without_STLfile={isUploadLater()}
            // handleRoatate={(degree) => { props.handleRoatate("impression_lower", degree) }}
            handleChange={(file) => { props.handleChange("impression_lower", file) }} />
        </li>
      </Grid>
      <Grid item xs={12} sm={3}>
        <li>
          <NewPatientPhoto
            mode={props.mode}
            imageId="impression_scan_one"
            imageUploaded={props.imageUploaded("impression_scan_one")}
            imageData={props.imageData("impression_scan_one")}
            imageName="imageUpload.png"
            type="impression"
            //label="STL"
            StlLabel={get('impressions.bite_1_stl') != "N/A" ? get('impressions.bite_1_stl') : "Bite 1 STL"}
            StlWillNotUploadLabel={get('impressions.notUploadWarning')}
            upload_without_STLfile={isUploadLater()}
            // handleRoatate={(degree) => { props.handleRoatate("impression_scan_one", degree) }}
            handleChange={(file) => { props.handleChange("impression_scan_one", file) }} />
        </li>
      </Grid>
      <Grid item xs={12} sm={3}>
        <li>
          <NewPatientPhoto
            mode={props.mode}
            imageId="impression_scan_two"
            imageUploaded={props.imageUploaded("impression_scan_two")}
            imageData={props.imageData("impression_scan_two")}
            imageName="imageUpload.png"
            type="impression"
            //label="STL"
            StlLabel={get('impressions.bite_2_stl') != "N/A" ? get('impressions.bite_2_stl') : "Bite 2 STL"}
            StlWillNotUploadLabel={get('impressions.notUploadWarning')}
            upload_without_STLfile={isUploadLater()}
            // handleRoatate={(degree) => { props.handleRoatate("impression_scan_two", degree) }}
            handleChange={(file) => { props.handleChange("impression_scan_two", file) }} />
        </li>
      </Grid>

      {
        props.uploadLater && props.mode !== 'view' &&
        ![ "impression_upper", "impression_lower", "impression_scan_one", "impression_scan_two" ].every(id => {
          const targetFile = files.find(file => file.id === id)?.data
          return typeof targetFile === 'object'
        }) &&
        <>
          <Grid item xs={12} sm={12} md={12} style={{ textAlign: "center", marginTop: "4%" }}>
            <FormControlLabel
              value="end"
              control={
                <Checkbox checked={isUploadLater()} />
              }
              label={get('impressions.uploadStlFilesLater')}
              labelPlacement="end"
              // onChange={() => handleNoImage()}
              onChange={() => setUploadLater(!isUploadLater())}
            />
          </Grid>
        </>
      }

    </Grid>
  );
};


export default StlImpression;
