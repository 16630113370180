import React, { useEffect, forwardRef, useState, useCallback, useMemo } from 'react'
import { CircularProgress, Grid, TextField, Select, InputLabel, MenuItem } from '@material-ui/core'
import MaterialTable from 'material-table'
import { useHistory } from 'react-router'
import { safeGet } from '../../axios-default'
import QueryString from 'querystring'
import Modal from '../../components/Modal/modal';
import DragAndDrop from '../../components/NewPatient/Photos/DragAndDrop';
import style from './PageBanner.module.css'
import axios from '../../axios-default';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { ApiServerUrl } from '../../config.json';
import { connect, useDispatch, useSelector } from 'react-redux';
import { easySnackbar } from '../../store/Noti/noti.actions';
import { updateRes } from '../../store/res/res.action';
import {
  Close as CloseIcon, Check as CheckIcon, AddBox, ArrowDownward,
  Check, ChevronLeft, ChevronRight, Clear, DeleteOutline,
  Edit, FilterList, FirstPage, LastPage, Remove, SaveAlt,
  Search, ViewColumn, StarRate
} from '@material-ui/icons'
import { Edit as EditIcon, Add as AddIcon } from "@material-ui/icons";
const TableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
}


 const SupportResourceTable = ({addOpen,handleAddModleOpen,handleAddButtonDisable}) => {
  const res = useSelector(({ res }) => res)
  const history = useHistory()
  const dispatch = useDispatch()

  const [data, setData] = useState([]),
        [loading, setLoading] = useState(true),
        [search, setSearch] = useState(''),
        [modalOpen, setModalOpen] = useState(false),
        [modalError, setModalError] = useState(''),
        [editModalOpen, setEditModalOpen] = useState(false),
        [selectedFile, setSelectedFile] = useState(false),
        //[updatedProductMatrix, setUpdatedProductMatrix] = useState({pmData: Object.assign({})})
        [updatedSupportResource, setUpdatedSupportResource] = useState({srData: Object.assign({})})



  const fileToBase64 = (file) => new Promise((resolve, reject) => {
   const reader = new FileReader()
   if (file) {
     reader.readAsDataURL(file)
   }
   reader.onload = () => {
     resolve(reader.result)
   }
   reader.onerror = error => reject(error)
 })



 const handleDrop = (uploadedFile) => {

   handleFileChange(uploadedFile)

 }
 /*
 const handleFileChange =(event)=>{
   //let newTermPDF ={};
   //newTermPDF.pdf = data;
   //newTermPDF.pdf1 = data.name;
   //const form = new FormData();
   setSelectedFile(event.target.files[0]);

 }*/
 const handleFileChange = useCallback( async file => {
     let data = undefined
     if (file) {
       data = await fileToBase64(file)
     }

     setUpdatedSupportResource({
       ...updatedSupportResource,
       srData:  {
         ...updatedSupportResource.srData,
         terms_conditions: data,
       }
     })

     setSelectedFile(true)
 }, [updatedSupportResource, selectedFile])
 //useCallback( async file => {
 const handleSubmit =  useCallback(async () => {
    try {
      //this.setState({ PhotoUploadLoadingStatus: true, loading: true });
      let {data} = await axios.post(
        `supportresource/create`,
        updatedSupportResource.srData
      )
      if (!data.success){
            dispatch(easySnackbar('Error. Please check your input.', 'error'))
            return
      }

      dispatch(easySnackbar('Success', 'success'))
      setUpdatedSupportResource({srData: Object.assign({})})
      reloadPage()
      setLoading(true)
      setModalOpen(false)
      reloadPage()
    } catch (err) {
          setSelectedFile(false)
          dispatch(easySnackbar('Error. Please check your input.', 'error'))

      console.error(err.response);
    } finally {
      //this.setState({ PhotoUploadLoadingStatus: false, loading: false });
      setLoading(false)
      setSelectedFile(false)
    }


  }, [updatedSupportResource])

  const handleEditSubmit =  useCallback(async () => {
     try {
       //this.setState({ PhotoUploadLoadingStatus: true, loading: true });
       let {data} = await axios.post(
         `supportresource/edit`,
         updatedSupportResource.srData
       )
       if (!data.success){
             dispatch(easySnackbar('Error. Please check your input.', 'error'))
             return
       }
     dispatch(easySnackbar('Success', 'success'))
     setUpdatedSupportResource({srData: Object.assign({})})
     setLoading(true)
     setModalOpen(false)
     reloadPage()
     } catch (err) {

     //  this.setState({
       dispatch(easySnackbar('Error. Please check your input.', 'error'))
       //  modalError: "Something Went Wrong Please Connect With Admin",
     //  });
       console.error(err.response);
     } finally {
       //this.setState({ PhotoUploadLoadingStatus: false, loading: false });
       setLoading(false)
       setSelectedFile(false)
     }


   }, [updatedSupportResource])
  const handleUpdate = useCallback(field => ({ target: { value } }) => {

      setUpdatedSupportResource({
        ...updatedSupportResource,
        srData:  {
          ...updatedSupportResource.srData,
          [field]: value ?? null
        }
      })

  }, [updatedSupportResource, setUpdatedSupportResource])
  const reloadPage = () => {
    const curPage = window.location.pathname
    history.push('/blank')
    history.replace(curPage)
  }
  const countrySelect = useMemo(() =>
    Object.entries(res.countries).map(([id, c]) => (
      <MenuItem value={id} key={id}>{c.country_name}</MenuItem>
    ))
  , [res?.countries])
  /*
  const handleSave = useCallback(async () => {
    try {
      if (loading) return
      setLoading(true)

      await axios.post(`productmatrix/update`, updatedProductMatrix.pmData)

      window.scroll({top: 0, left: 0, behavior: 'smooth' })
      dispatch(easySnackbar('Success', 'success'))

      //dispatch(updateRes('admin_groups'))
      reloadPage()
      setLoading(true)
      setModalOpen(false)

    } catch (err) {

      console.error(err)
      if (err.response && err.response.statusText === 'Unauthorized') {
        localStorage.removeItem("auth-token");
      localStorage.removeItem("expirationDate");
        localStorage.removeItem("role");
        history.push("/login")
      }
    } finally {
      setLoading(false)
    }
  }, [updatedProductMatrix])
  */
  const dataGetter = useCallback(async query => {
    await dispatch(updateRes('country'))
    const { pageSize, page: pageNo, search, orderBy, orderDirection } = query
    try {
      const { sr: data, total } = await safeGet(`supportresource?${QueryString.encode({
        // all: 1,
        pageNo,
        pageSize,
        search,
        sortBy: orderBy?.field,
        sortDir: orderDirection
      })}`)
      if (loading) setLoading(false)

      if (JSON.parse(localStorage.getItem("role"))==="DISTR" && total==2 ){
          handleAddButtonDisable()
      }
      console.log(JSON.parse(localStorage.getItem('role')))

      if (JSON.parse(localStorage.getItem('role')) === 'SADMIN' || JSON.parse(localStorage.getItem('role'))=== 'ADMIN'){
          handleAddButtonDisable()
      }
      return {
        data: data.filter(({ deleted_flag }) => !deleted_flag),
        page: pageNo ?? 0,
        totalCount: total ?? 0
      }

    } catch (err) {
      console.error(err)
    }
  }, [search])

  return (<>

    <MaterialTable
      title="Support Resource List"
      data={dataGetter}
      actions={[
        {
          icon: () => <EditIcon />,
          tooltip: 'Edit Row',

          onClick: (_, data) => {

            if (data.id==1){
              dispatch(easySnackbar('Cannot Change defualt. Please contact IT', 'error'))
              return
            }
            setUpdatedSupportResource({srData: Object.assign({'oldpdf': data.terms_conditions}, data)})

            setSelectedFile(false)
            setEditModalOpen(true)
          }
        }
      ]}
      columns={[
        {
          field: 'id',
          title: 'ID',
          sorting: true,
          filtering: true,
        },
        {
          field: 'distr_group_id',
          title: 'Distr ID',
          sorting: true,
          filtering: false,
          render:  rowData => ( rowData.distr_group_id && res.distr_groups[rowData.distr_group_id]?res.distr_groups[rowData.distr_group_id].distr_group_name:'') ,
        }
        ,
        {
          field: 'tel',
          title: 'Telephone',
          sorting: true,
          filtering: true,

        },
        {
          field: 'email',
          title: 'Email',
          sorting: true,
          filtering: true,

        },

        {
          field: 'facebook',
          title: 'facebook',
          sorting: true,
          filtering: true,

        },

        {
          field: 'instagram',
          title: 'Instagram',
          sorting: true,
          filtering: true,

        }
        ,

        {
          field: 'contact_us',
          title: 'Customer Service',
          sorting: true,
          filtering: true,

        },

        {
          field: 'terms_conditions',
          title: 'Terms Conditions',
          render : rowData => <a href={'../file/config/'+rowData.terms_conditions} target="_blank">Download</a>,
          sorting: true,
          filtering: true,

        },
        /*  `instagram` VARCHAR(200) NULL,
  `terms_conditions` VARCHAR(255) NULL,

        {
          field: 'image',
          title: 'Product Image',
          render:  rowData => (  <img style={{ width: "400px"}} src={ ApiServerUrl+ "file/config/" +rowData.product_image}/>) ,
          sorting: false,
          filtering: false,
        },
        {
          field: 'enable',
          title: 'Enable',
          sorting: true,
          filtering: true,
          render: rowData => (
            <div style={{ display: "flex", justifyContent: 'center', margin: '0px 4px', width: 40, height: 40 }}>
              {rowData.enable_flag ? <CheckIcon /> : <CloseIcon />}
            </div>
          )
        },
        */
      ]}

      options={{
        initialPage: 0,
        emptyRowsWhenPaging: false,
        exportAllData: true,
        debounceInterval: 700,
        columnsButton: true,
        pageSize: 10,
        search: false,
        pageSizeOptions: [10, 15, 30, 50, 100],
        minBodyHeight: 700,
        exportButton: true
      }}
      icons={TableIcons}
      isLoading={loading}
    />
    <Modal
      className="modal"
      modal=''
      title='Add Support Resource'
      open={addOpen}
      handleClose={() =>{

          handleAddModleOpen(false)

          setSelectedFile(false)
          setModalOpen(false)
          setUpdatedSupportResource({srData: Object.assign({})})
        }
      }
      handleDataSave={() => handleSubmit()}
      //modalError={this.modalError}
      submitButton={true}
    >
    <p>Select Country</p>
    <Select
      displayEmpty
      onChange={handleUpdate('country_id')}
      style={{ width: "100%" }}
      value={updatedSupportResource.country_id}
    >
      {countrySelect}
    </Select>
    <TextField
      fullWidth
      label="Tel"
      variant="filled"
      value={updatedSupportResource.srData.tel}
      onChange={handleUpdate('tel')
      }
    />
    <TextField
      fullWidth
      label="Email"
      variant="filled"
      value={updatedSupportResource.srData.email}
      onChange={handleUpdate('email')
      }
    />
    <TextField
      fullWidth
      label="Facebook"
      variant="filled"
      value={updatedSupportResource.srData.facebook}
      onChange={handleUpdate('facebook')
      }
    />
    <TextField
      fullWidth
      label="Instagram"
      variant="filled"
      value={updatedSupportResource.srData.instagram}
      onChange={handleUpdate('instagram')
      }
    />
    <TextField
      fullWidth
      label="Customer Serivce"
      variant="filled"
      value={updatedSupportResource.srData.contact_us}
      onChange={handleUpdate('contact_us')
      }
    />


    <Grid item xs={12}>
       {selectedFile ?<p>Uploaded Pdf</p>:<p>Current Pdf</p>}

      </Grid>
      <Grid item xs={12}>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>

      <DragAndDrop handleDrop={(uploadedFile) => handleFileChange(uploadedFile)}>

            <span>Pdf</span>
            <div className={style.uploadBtnWrapper}>
              <button className={style.uploadBtn}>+</button>
              <input
                type="file"
                onChange={({ target }) => {
                        handleFileChange(target.files[0])
                      }}
                name="patientImage"
                id="patientImage"
              />
            </div>

        </DragAndDrop>
      </Grid>
    </Modal>
    <Modal
      className="modal"
      modal=''
      title='Edit Support Resource'
      open={editModalOpen}
      handleClose={() =>{
          setEditModalOpen(false)
          setSelectedFile(false)
          setUpdatedSupportResource({srData: Object.assign({})})
        }
      }
      handleDataSave={() => handleEditSubmit()}
      //modalError={this.modalError}
      submitButton={true}
    >
    <p>Select Country</p>
    <Select
      displayEmpty
      onChange={handleUpdate('country_id')}
      style={{ width: "100%" }}
      value={updatedSupportResource.srData.country_id}
    >
      {countrySelect}
    </Select>
    <TextField
      fullWidth
      label="Tel"
      variant="filled"
      value={updatedSupportResource.srData.tel}
      onChange={handleUpdate('tel')
      }
    />
    <TextField
      fullWidth
      label="Email"
      variant="filled"
      value={updatedSupportResource.srData.email}
      onChange={handleUpdate('email')
      }
    />
    <TextField
      fullWidth
      label="Facebook"
      variant="filled"
      value={updatedSupportResource.srData.facebook}
      onChange={handleUpdate('facebook')
      }
    />
    <TextField
      fullWidth
      label="Instagram"
      variant="filled"
      value={updatedSupportResource.srData.instagram}
      onChange={handleUpdate('instagram')
      }
    />
    <TextField
      fullWidth
      label="Customer Service"
      variant="filled"
      value={updatedSupportResource.srData.contact_us}
      onChange={handleUpdate('contact_us')
      }
    />
    {selectedFile==false&&
      <a href={`..\/file\/config\/${updatedSupportResource.srData.terms_conditions}`} target="_blank">Term Conditions</a>
    }
      <Grid item xs={12}>
        <p>Current Pdf</p>
      </Grid>
      <Grid item xs={12}>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>

      <DragAndDrop handleDrop={(uploadedFile) => handleFileChange(uploadedFile)}>
            <span>Pdf</span>
            <div className={style.uploadBtnWrapper}>
              <button className={style.uploadBtn}>+</button>
              <input
                type="file"
                onChange={({ target }) => {
                        handleFileChange(target.files[0])
                      }}
                name="patientImage"
                id="patientImage"
              />
            </div>

        </DragAndDrop>
      </Grid>
    </Modal>
    </>
  )

}

export default SupportResourceTable
