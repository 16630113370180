import React, { Component } from 'react'
import { Grid, TextField, Radio } from '@material-ui/core'
import { connect } from 'react-redux';
import * as actionTypes from '../../../store/NewPatient/actionType';
import { withRouter } from 'react-router-dom'
import styles from './NewPatientQuestionnaire.module.css'
import { RadioGroup, FormControlLabel } from '@material-ui/core';
import { withLang } from '../../../util/lang';
import { beme_questionnaire } from '../../../beme_questionnaire'

class NewPatientQuestionnaire extends Component {
  componentDidMount() {
    beme_questionnaire.map((q) => {
      this.props.handleChange(q.name, this.props[q.name] ?? q.default)
    })
  }

  render() {
    return (
      <Grid container>
        {beme_questionnaire.map((q) => <>
          <Grid item xs={12} sm={6} md={3} className={styles.tableItem + " " + styles.heading}>
            {q.required ? <span style={{color: 'red'}}>*</span> : ""}{q.question}
          </Grid>
          <Grid item xs={12} sm={6} md={9} className={styles.tableItem}>
            <RadioGroup
              onChange={(e) => {
                this.props.mode !== 'view' && this.props.handleChange(q.name, e.target.value)
              }}
              name={q.name} value={this.props[q.name]}
            >
              <Grid container>
                {q.options.map((i) => 
                  <Grid item xs={12} md={6} lg={4}>
                    <FormControlLabel key={i.value} value={i.value} control={<Radio />} label={i.label} />
                  </Grid>
                )}
              </Grid>
            </RadioGroup>
          </Grid>
        </>)}
        <Grid item xs={12} sm={6} md={3} className={styles.tableItem + " " + styles.heading}>
          Notes and Comments (Optional)
        </Grid>
        <Grid item xs={12} sm={6} md={9}>
          <TextField
            id="attr12"
            className={styles.textField}
            value={this.props.attr12 ?? ""}
            onChange={({ target: { value } }) => this.props.mode !== 'view' && this.props.handleChange('attr12', value)}
            margin="normal"
            variant="outlined"
            multiline
            rows={4}
            placeholder=""
          />
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = store => {
  return {
    ...store.newPatientReducer,
    lang: store.lang
  }
};

const mapDispatchToProps = dispatch => {
  return {
    handleChange: (attrName, value) => {
      dispatch({ type: actionTypes.NEW_PATIENT_QUES_SIMPLE_UPDATE, payload: { attrName, value } })
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withLang(NewPatientQuestionnaire, 'NewPatient')))

