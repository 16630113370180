import axios from 'axios';
import join from 'url-join'
import Url from 'url';
import { ApiServerUrl } from './config.json'
import { getWebAbsPath } from './util/path'

const instance = axios.create({
  baseURL: join(ApiServerUrl, '/api'),
  withCredentials: true,
  maxContentLength: Infinity,
  maxBodyLength: Infinity
});

export async function safeGetWithProgress(options, onProgressChange) {
  try {
    const { data } = await instance({
      ...options,
      method: 'GET',
      onDownloadProgress: event => { console.log(event); onProgressChange(event) }
    })
    return data
  } catch (err) {
    console.error(err)
    if (err && err.response && err.response && err.response.statusText === 'Unauthorized') {
      localStorage.removeItem("auth-token");
      localStorage.removeItem("expirationDate");
      localStorage.removeItem("role");
      window.location = getWebAbsPath('/login');
    }
    return { ...(err?.response?.data ?? null), success: false }
  }
}

export async function safeGet(apiPath, config) {
  try {
    const { data } = await instance.get(apiPath, config)
    return data
  } catch (err) {
    console.error(err)
    if (err && err.response && err.response && err.response.statusText === 'Unauthorized') {
      localStorage.removeItem("auth-token");
      localStorage.removeItem("expirationDate");
      localStorage.removeItem("role");
      window.location = getWebAbsPath('/login');
    }
    return { ...(err?.response?.data ?? null), success: false }
  }
}

export async function safePost(apiPath, data, config) {
  try {
    const { data: _data } = await instance.post(apiPath, data, config)
    return _data
  } catch (err) {
    console.error(err)
    if (err && err.response && err.response && err.response.statusText === 'Unauthorized') {
      localStorage.removeItem("auth-token");
      localStorage.removeItem("expirationDate");
      localStorage.removeItem("role");
      window.location = getWebAbsPath('/login');
    }
    return err?.response?.data ?? null
  }
}

export async function safePostWithProgress(apiPath, data, onProgressChange) {
  try {
    const { data: _data } = await instance.post(apiPath, data, {
      onUploadProgress: onProgressChange
    })
    return _data
  } catch (err) {
    console.error(err)
    if (err && err.response && err.response && err.response.statusText === 'Unauthorized') {
      localStorage.removeItem("auth-token");
      localStorage.removeItem("expirationDate");
      localStorage.removeItem("role");
      window.location = getWebAbsPath('/login');
    }
    return { ...(err?.response?.data ?? null), success: false }
  }
}

export async function safeDelete(apiPath) {
  try {
    const { data } = await instance.delete(apiPath)
    return data
  } catch (err) {
    console.error(err)
    if (err && err.response && err.response && err.response.statusText === 'Unauthorized') {
      localStorage.removeItem("auth-token");
      localStorage.removeItem("expirationDate");
      localStorage.removeItem("role");
      window.location = getWebAbsPath('/login');
    }
    return { ...(err?.response?.data ?? null), success: false }
  }
}

export default instance;