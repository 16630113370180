import { CircularProgress } from '@material-ui/core/';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import ReportsList from '../../components/Admin/ReportsList';
//Components
import SideNav from '../../components/Admin/SideNav';
import CaseLogs from '../../components/Admin/CaseLogs'
import { getWebAbsPath } from '../../util/path';



const styles = theme => ({
  "@global": {
    body: {
      background: "#fdfdfd",
    },
  },
  root: {
    flexGrow: 1,
  },
  rootContainer: {
    padding: 16,
    margin: "8px 0px 0px 238px",
    [theme.breakpoints.between("xs", "sm")]: {
      margin: "12px 0px 0px 0px",
      width: "100%",
    },
    width: "calc(100% - 238px)",
  },
  scanBox: {
    padding: 16,
    margin: "8px 0px"
  },
  textField: {
    // marginLeft: "8px",
    // marginRight: "8px",
    '& label.Mui-focused': {
      color: 'black',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'black',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey',
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
  },
  dateField: {
    width: "100%"
  },
  formControl: {
    width: "100%"
  }
});

class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedin: true,
      name: "",
      channel: "",
      codeUrl: "",
      sources: {},
      addMsg: "",
      userInfo: {},
      redeemSurprisingGift: false,
      scanResult: "",
      reviewCustomer: true,
      data: [],
      caseStatusOptions: [
        { label: "Prescription Submitted", value: "SUBMITTED" },
        { label: "Awaiting More Records{Remark: ?}", value: "AWAITING" },
        { label: "Confirmed All Materials Received", value: "RECEIVED" },
        { label: "Treatment Plan Change Request Received", value: "CHANGE_REQUEST" },
        { label: "Treatment Plan {No.?} Uploaded", value: "PLAN_UPLOADED" },
        { label: "Treatment Plan {No.?} Approved", value: "PLAN_APPROVED" },
        { label: "Production In Progress", value: "PRODUCTION" },
        { label: "Aligners Set {No.1 ?}-{No.2 ?} Delivered", value: "DELIVERED" },
        { label: "Request for Next Aligner Set", value: "NEXT_SET_REQUEST" },
        { label: "All Aligners Sets Are Delivered", value: "ALL_DELIVERED" },
        { label: "Aligner Request", value: "ALIGNER_REQUEST" },
        { label: "Retainer Request", value: "RETAINER_REQUEST" },
        { label: "Refinements Prescription Received", value: "REFINEMENTS" },
        { label: "Case Ended", value: "ENDED" }
      ],
      currentStatus: "SUBMITTED",
      startDateCustomer: new Date(),
      endDateCustomer: new Date(),
    }
  }

  handleChangeStatus = value => {
    this.setState({ currentStatus: value })
  }

  ChangeStartCustomerDate = date => {
    this.setState({
      startDateCustomer: date
    });
  };

  ChangeEndCustomerDate = date => {
    this.setState({
      endDateCustomer: date
    });
  };

  handleError = data => {
    console.error(data)
  }

  componentDidMount() {
    // console.log("mounted")
  }


  render() {
    const { classes } = this.props
    return (
      <div>
        <SideNav />
        <Grid container className={classes.rootContainer}>

        </Grid>
        <Grid container className={classes.rootContainer} style={{ marginTop: 0 }}>
          {this.state.isLoggedin ?
            <div style={{ display: "contents" }}>
              <Grid container>

                <Grid item xs={12}
                  style={{
                    padding: 8
                  }}>
                  <h1
                    style={{
                      marginBlockEnd: 0
                    }}
                  >
                    Reports
                  </h1>

                  <hr />
                </Grid>

                <Grid item xs={12}
                  style={{
                    padding: 8,
                    textAlign: "center"
                  }}>
                  <img style={{ width: "100%", maxWidth: 800 }} src={getWebAbsPath("/img/order1.png")} />
                </Grid>

                <Grid item xs={12} style={{ padding: 8 }}>
                  <ReportsList
                    caseStatusOptions={this.state.caseStatusOptions}
                    currentStatus={this.state.currentStatus}
                    customerData={this.state.data}
                    startDate={this.state.startDateCustomer}
                    endDate={this.state.endDateCustomer}
                    handleChangeStart={(date) => this.ChangeStartCustomerDate(date)}
                    handleChangeEnd={(date) => this.ChangeEndCustomerDate(date)}
                    handleChangeStatus={(date) => this.handleChangeStatus(date)}
                    caseStatusOptions={this.state.caseStatusOptions}

                  />
                </Grid>
                <Grid item xs={12} style={{padding: 8}}>
                  <CaseLogs />
                </Grid>

              </Grid>

            </div> :
            <Grid item xs={12}>
              <CircularProgress style={{ color: "rgb(6,26,46)" }} />
            </Grid>}
        </Grid>
      </div>
    )
  }
}

Reports.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(withWidth()(Reports)))