import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  TableRow, TableCell, IconButton,
  Typography, Box, Collapse, TableBody,
  makeStyles, Button, Table, TableHead,
  Fade,
  Paper,
  TableContainer,
  Toolbar
} from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import AddIcon from '@material-ui/icons/Add';
import CollapsibleTableHead from './CollapsibleTableHead';

const useRowStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  collapseCell: {
    padding: 0
  },
  subTablePaper: {
    boxShadow: '1px 3px 10px -1px rgba(0,0,0,0.3)'
  },
  subTableContainer: {
    width: 'auto',
    marginBottom: 10
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  title: {
    flex: '1 1 100%',
  },
}))

const CountryRow = (props) => {
  const [open, setOpen] = React.useState(false),
        [isEntered, setIsEntered] = React.useState(false),
        classes = useRowStyles(),
        sortingFunc = React.useRef(data => data),
        [subData, setSubData] = React.useState([])

  const getSubTableRows = () => {
    return sortingFunc.current(subData).slice().map((d, i) => (
      <TableRow key={i} hover>
        {(props.subColumns||[]).map(({ name, render, index, numeric }, i) => (
          <TableCell key={i} align={numeric? 'right' : (index? 'left' : 'center')}>{(render||(v => v))((d||{})[name])}</TableCell>
        ))}
      </TableRow>
    ))
  }

  useEffect(() => {
    if (props.data && props.subDataName && props.data[props.subDataName]) {
      setSubData(sortingFunc.current(props.data[props.subDataName]||[]).slice())
    }
  }, [props.data])

  return (
    <Fragment key={props.key}>
      {/* {getRow()} */}
      <TableRow
        className={classes.root}
        hover
        onClick={() => setOpen(open? false : !!props.data && !!props.data[props.subDataName])}
        onMouseEnter={() => setIsEntered(true)}
        onMouseLeave={() => setIsEntered(false)}
      >
        {props.data && !!props.data[props.subDataName]?
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowRightIcon />}
            </IconButton>
          </TableCell> :
          <TableCell>
            <Fade in={props.onInsert && isEntered}>
              <IconButton aria-label="insert" size="small" onClick={() => props.onInsert(props.data)}>
                <AddIcon/>
              </IconButton>
            </Fade>
          </TableCell>}
          {(props.columns||[]).map(({ name, render, index, numeric }, i) => (
            <TableCell key={i} align={numeric? 'right' : (index? 'left' : 'center')}>{(render||(v => v))((props.data||{})[name])}</TableCell>
          ))}
      </TableRow>
      <TableRow>
        <TableCell className={classes.collapseCell} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Paper className={classes.subTablePaper}>
              {/* <Box margin={1}> */}
              <Toolbar className={classes.toolbar}>
                <Typography variant="h6" gutterBottom component="div" className={classes.title}>
                  {props.title} <Button style={{marginLeft: 10}}>Add New</Button>
                </Typography>
              </Toolbar>
              <TableContainer component={Paper} className={classes.subTableContainer}>
                <Table size="small" aria-label="collapsible sub table">
                  <CollapsibleTableHead
                    columns={props.subColumns}
                    onRequestSort={(name, dir) => {
                      sortingFunc.current = data => data.sort((a, b) => {
                        let ret = 0
                        if (!a[name]) {
                          ret = -1
                        } else if (!b[name]) {
                          ret =  1
                        } else {
                          ret = (a[name] === b[name])? 0 : ((a[name] < b[name])? -1 : 1)
                        }
                        if (dir === 'desc') ret *= -1
                        return ret
                      })
                      setSubData(sortingFunc.current(subData).slice())
                    }}
                  />
                  {/* <TableRow>
                    {(props.subColumns||[]).map(({ label }, i) => (
                      <TableCell key={i}>{label}</TableCell>
                    ))}
                  </TableRow> */}
                  <TableBody>
                    {getSubTableRows()}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* </Box> */}
            </Paper>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}
CountryRow.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    render: PropTypes.func,
    index: PropTypes.bool,
    sortable: PropTypes.bool
  })),
  subColumns: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    render: PropTypes.func,
    index: PropTypes.bool,
    sortable: PropTypes.bool
  })),
  subDataName: PropTypes.string,
  onInsert: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.object)
}

export default CountryRow