import { CircularProgress } from '@material-ui/core/';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import axios from '../../axios-default';
import CaseStatChart from '../../components/Admin/CaseStatChart';
import NewAccountChart from '../../components/Admin/NewAccountChart';
//Components
import SideNav from '../../components/Admin/SideNav';


const styles = theme => ({
  "@global": {
    body: {
      background: "#fdfdfd",
    },
  },
  root: {
    flexGrow: 1,
  },
  rootContainer: {
    padding: 16,
    margin: "8px 0px 0px 238px",
    [theme.breakpoints.between("xs", "sm")]: {
      margin: "12px 0px 0px 0px",
      width: "100%",
    },
    width: "calc(100% - 238px)",
  },
  scanBox: {
    padding: 16,
    margin: "8px 0px"
  },
  textField: {
    // marginLeft: "8px",
    // marginRight: "8px",
    '& label.Mui-focused': {
      color: 'black',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'black',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey',
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
  },
  dateField: {
    width: "100%"
  },
  formControl: {
    width: "100%"
  },
  dense: {
    marginTop: 16,
    marginLeft: 0
  },
  submitBtn: {
    backgroundColor: "#061a2e !important",
    color: "white",
    padding: "8px 24px",
    margin: "12px 0px"
  },
});

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDateCase: null,
      endDateCase: new Date(),
      startDateAccount: null,
      endDateAccount: new Date(),
      distributors: [],
      groups: [],
      clinics: [],
      current_distributor: "",
      current_group: "",
      current_clinic: "",
      isLoggedin: true,
      name: "",
      channel: "",
      codeUrl: "",
      sources: {},
      addMsg: "",
      userInfo: {},
      redeemSurprisingGift: false,
      scanResult: "",
      reviewCustomer: true,
    }
    let halfYearAgo = new Date()
    halfYearAgo.setMonth(halfYearAgo.getMonth() - 6 + 1)
    let aWeekAgo = new Date()
    aWeekAgo.setDate(aWeekAgo.getDate() - 7)
    this.state.startDateCase = aWeekAgo
    this.state.startDateAccount = halfYearAgo
  }


  handleError = data => {
    console.error(data)
  }

  async componentDidMount() {

    try {
      let groups = (await axios.get(`res/group/`)).data.group;
      let clinics = (await axios.get(`res/clinic/`)).data.clinic;
      const role = JSON.parse(localStorage.getItem('role'))
      let distributors = {}
      if (['ADMIN', 'SADMIN'].includes(role)) {
        distributors = (await axios.get(`user?role=DISTR`)).data.user;
      }
      this.setState({ groups, clinics, distributors })
    }
    catch (e) {
      console.error(e)
    }
  }

  handleChangeStartCase = date => {
    this.setState({
      startDateCase: date
    });
  };

  handleChangeEndCase = date => {
    this.setState({
      endDateCase: date
    });
  };

  handleChangeRangeCase = (sDate, eDate) => {
    this.setState({
      startDateCase: sDate,
      endDateCase: eDate
    });
  };

  handleChangeStartAccount = date => {
    this.setState({
      startDateAccount: date
    });
  };

  handleChangeEndAccount = date => {
    this.setState({
      endDateAccount: date
    });
  };

  handleChangeSelectData(type, value) {
    switch (type) {
      case "distributor_id":
        this.setState({ current_distributor: value })
        break;
      case "group_id":
        this.setState({ current_group: value })
        break;
      case "clinic_id":
        this.setState({ current_clinic: value })
        break;
    }
  }


  render() {
    const { classes } = this.props
    return (
      <div>
        <SideNav />
        <Grid container className={classes.rootContainer}>

        </Grid>
        <Grid container className={classes.rootContainer} style={{ marginTop: 0 }}>
          {this.state.isLoggedin ?
            <div style={{ display: "contents" }}>
              <Grid container>

                <Grid item xs={12}
                  style={{
                    padding: 8
                  }}>
                  <h1
                    style={{
                      marginBlockEnd: 0
                    }}
                  >
                    Dashboard
                  </h1>

                  <hr />
                </Grid>

                {/* <Grid item xs={12}
                style={{
                  padding: 8,
                  textAlign: "center"
                }}>
                  <img style={{width: "100%", maxWidth: 800}} src={getWebAbsPath("/img/stat1.PNG")}/>
              </Grid> */}

                <Grid item xs={12}
                  style={{
                    padding: 8
                  }}>
                  <NewAccountChart
                    groups={this.state.groups}
                    distributors={this.state.distributors}
                    clinics={this.state.clinics}
                    distributor_id={this.state.current_distributor}
                    group_id={this.state.current_group}
                    clinic_id={this.state.current_clinic}
                    startDate={this.state.startDateAccount}
                    endDate={this.state.endDateAccount}
                    handleChangeSelect={(type, value) => this.handleChangeSelectData(type, value)}
                    handleChangeStart={(date) => this.handleChangeStartAccount(date)}
                    handleChangeEnd={(date) => this.handleChangeEndAccount(date)} />

                </Grid>

                <Grid item xs={12} md={12}
                  style={{
                    padding: 8
                  }}>
                  <CaseStatChart
                    country_id={this.state.country_id}
                    startDate={this.state.startDateCase}
                    endDate={this.state.endDateCase}
                    handleChangeStart={(date) => this.handleChangeStartCase(date)}
                    handleChangeEnd={(date) => this.handleChangeEndCase(date)}
                    handleChangeRange={(sDate, eDate) => this.handleChangeRangeCase(sDate, eDate)}
                  />
                </Grid>

              </Grid>

            </div> :
            <Grid item xs={12}>
              <CircularProgress style={{ color: "rgb(6,26,46)" }} />
            </Grid>}
        </Grid>
      </div>
    )
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(withWidth()(Dashboard)))