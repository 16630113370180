import { Grid ,Button} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { Component, Fragment } from 'react';
import { Animated } from "react-animated-css";
import { connect } from 'react-redux';
import { Link, Prompt, withRouter } from 'react-router-dom';
import Url from 'url';
import join from 'url-join'
import axios, { safePostWithProgress } from '../axios-default';
import Loader from "../components/Loader";
import LoadingProgressBar from '../components/LoadingProgressBar';
import NewPatientDelivery from '../components/NewPatient/Address/NewPatientDelivery';
// import components
import NewPatientInfo from '../components/NewPatient/Info/NewPatientInfo';
import NewPatientsButtonTabs from '../components/NewPatient/NewPatientButtonTabs';
import NewPatientsTabs from '../components/NewPatient/NewPatientsTabs';
import NewPatientSummary from '../components/NewPatient/NewPatientSummary/NewPatientSummary_beme';
import NewPatientPhotos from '../components/NewPatient/Photos/NewPatientPhotos';
import NewPatientQuestionnaire from '../components/NewPatient/Questionnaire/NewPatientQuestionnaire_beme';
import ColorPattern from '../components/NewPatient/ColorPattern/ColorPattern';
import { ApiServerUrl, MaxRetainerPairs } from '../config.json';
import * as saveAction from '../store/NewPatient/NewPatient.action';
import { easySnackbar } from '../store/Noti/noti.actions';
import { withLang, getFromObj} from '../util/lang';
import '../util/array'
import { getFormToken } from '../util/file';
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import { getWebAbsPath } from '../util/path';
import { beme_questionnaire } from '../beme_questionnaire'
import * as actionNewAddress from '../store/NewAddress/actionType'


const styles = theme => ({
  back: {
    margin: "8px",

    background: "#404156",
    color: "white",
    fontSize: "16px",
    textTransform: "unset",
    "&:hover": {
      background: "#404156",
    }
  },
  root: {
    margin: '48px auto',
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
    },
    [theme.breakpoints.up('lg')]: {
      width: "70%",
      minHeight: "400px",
    },
  },
  mainContainer: {
    textAlign: "left",
    boxShadow: "0 1px 2px rgba(0,0,0,0.15)",
    backgroundColor: "white",
    padding: 16,
    [theme.breakpoints.down('xs')]: {
      maxHeight: "unset"
    },
  },
  devicesContainer: {
    padding: 16,
    background: "#f2f2f2",
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  }
})
const photoStartRef = React.createRef()
const CaseType = {
  'undefined': 'UNDEFINED',
  touchUp: 'TOUCH_UP',
  simple: 'SIMPLE',
  complete: 'COMPLETE',
  retainer: 'RETAINER',
  cbct: 'CBCT',
  touchUp_Plus: 'TOUCHUP_PLUS',
  //recommend: 'RECOMMEND'
}

class NewPatient extends Component {

  constructor(props) {
    super(props)
    this.state = {
      tabMode: "info",
      error: {},
      patientInfo: null,
      countryList: null,
      stateList: null,
      addressList: null,
      scrollToPhoto: true,
      saved: false,
      case_impression_id: null,
      uploading: false,
      savingData: false,
      NewCaseType: this.props.match.params.type,
      delivery_address_id: null
    }
    this.progressBarRef = React.createRef(null)
    this.handleSave = this.handleSave.bind(this)
    this.scrollToView = this.scrollToView.bind(this)
    this.handleValidation = this.handleValidation.bind(this)
  }

  scrollToView() {
    photoStartRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  componentDidUpdate(prevProps, prevState) {
    let { history } = this.props
    if (prevProps.NewPatientData.saveSuccess && this.props.NewPatientData.saveSuccess) {
      this.props.initData()
      this.props.showSnackbar('Saved', 'success')
      history.push('/')
    }

    if (this.state.tabMode === "photos" && photoStartRef.current && this.state.scrollToPhoto) {
      if (prevState.tabMode !== 'photos') {
        this.scrollToView()
      }
      //this.setState({scrollToPhoto:false})
    }
  }

  async handleSave(saveType) {
    try {
      this.setState({
        uploading: true
      })
      this.setState({
        savingData: true
      })
      const form = new FormData()

      let newState = { ...this.props.NewPatientData }
      let newAddress = { ...this.props.NewPatientAddressData }
      let modifiedKey, modifiedState = {}, case_file = {}, case_impression = {}, case_detail = {}, case_retainer = {},
        // questionnaire_teeth_array, 
        radioCounter = 0, stlCounter = 0;

      let date_of_birth = newState['day'] + '-' + newState['month'] + '-' + newState['year']

      // let questionnaire_teeth = newState['teeth']
      let files = newState['files']

      // questionnaire_teeth_array = questionnaire_teeth.map((value, index) => {
      //   if (value.checked) {
      //     return index
      //   }
      //   return null
      // })

      files.forEach((value, index) => {
        if (value.category === "stl_file") {
          const fileName = `$$FILE:stl_file${stlCounter}`
          case_impression['stl_file' + stlCounter] = {data: getFormToken(form, value.data, fileName),rotate:value.rotate,upload_later:value.upload_later}
          // case_impression['stl_file' + stlCounter] = await fileToBase64(value.data)
          case_impression['stl_files_later'] = value.upload_later? true : (value.will_not_upload? null : false)
          // case_impression['type'] = "FILE"
          stlCounter++
        }
        else if (value.category === "radio") {
          const fileName = `$$FILE:radio${radioCounter}`
          case_file['radio' + radioCounter] = { data: getFormToken(form, value.data, fileName), rotate: value.rotate }
          case_file['radio' + radioCounter + '_later'] = value.upload_later? true : (value.will_not_upload? null : false)
          //case_file[value.category+radioCounter] = {data:value.data,rotate:value.rotate,upload_later:value.upload_later}
          radioCounter++
        }
        else {
          const fileName = `$$FILE:photo${index}`
          //case_file[value.category+index] = {data:value.data,flipped:value.flipped,rotate:value.rotate,upload_later:value.upload_later}
          case_file['photo' + index] = { data: getFormToken(form, value.data, fileName), flipped: value.flipped, rotate: value.rotate }
          case_file['photo' + index + '_later'] = value.upload_later? true : (value.will_not_upload? null : false)
        }
      })

      case_impression.type = newState.impressionType

      // var questionnaire_teeth_array_filtered = questionnaire_teeth_array.filter(function (el) {
      //   return el != null;
      // })

      delete newState.day
      delete newState.month
      delete newState.year
      delete newState.teeth
      delete newState.files


      // detail
      const attrExtractor = /^attr([0-9]{1,2})$/
      const retainerExtractor = /^(case_retainer_|retainer_)/
      Object.entries(newState).forEach(([key, value]) => {
        modifiedKey = key.replace(/(?:^|\.?)([A-Z])/g, function (x, y) { return "_" + y.toLowerCase() }).replace(/^_/, "");

        // if (Object.keys(ModifiedName).includes(modifiedKey)) {
        //   // if need to hide clnical_condition_comment if clinical condition does not include "others", do it here
          
        //   var attrName = ModifiedName[modifiedKey]
        //   case_detail[attrName] = typeof value === 'object'? JSON.stringify(value) : value
        // } else 
        if (modifiedKey === "scanner_brand" || modifiedKey === "doctor_id" || modifiedKey === "modern_code" || modifiedKey === "waybill_number" || modifiedKey === "courier_company") {
          if (value.length > 0) {
            case_impression[modifiedKey] = value
          }
        }
        else if (modifiedKey === "patient_photo") {
          const fileName = '$$FILE:profile_pic'
          modifiedState['profile_pic'] = {
            data: getFormToken(form, value.data, fileName),
            rotate: value.rotate
          }
        } else if (attrExtractor.test(key)) {
          // for cn1
          try {
            const [_, i] = key.match(attrExtractor)
            case_detail[`attr${i}`] = value
          } catch {}
        } else if (retainerExtractor.test(key)) {
          case_retainer[key] = value
        } else {
          modifiedState[modifiedKey] = value
        }
      })

      case_detail = Object.fromEntries(Object.entries(case_detail).map(([key, value]) => {
        if (String.isBlank(value)) {
          return [key, null]
        }
        return [key, value]
      }))

      case_retainer = Object.fromEntries(Object.entries(case_retainer).map(([key, value]) => {
        if (String.isBlank(value) && isNaN(value)) {
          return [key, null]
        }
        return [key, value]
      }))

      delete modifiedState.cbct_files
      modifiedState['case_cbct'] = this.props.NewPatientData.cbctFiles.map((cbct, index) => {
        const fileName = `$$FILE:cbct${index}`
        cbct.path = getFormToken(form, cbct.path, fileName)
        return cbct
      })

      modifiedState['date_of_birth'] = date_of_birth

      // case_detail['excluded_dentition'] = questionnaire_teeth_array_filtered + ""
      // case_detail['clinical_cond'] = newState['clinical_cond']

      modifiedState['case_detail'] = case_detail
      modifiedState['case_retainer'] = case_retainer
      modifiedState['case_file'] = case_file

      //set impression id on edit mode
      if (this.state.case_impression_id) {
        case_impression['case_impression_id'] = this.state.case_impression_id
      }

      modifiedState['case_impression'] = case_impression

      if (saveType === 'tempSave') {
        modifiedState['case_status'] = 'DRAFT'
      }
      else {
        modifiedState['case_status'] = 'SUBMITTED'
      }

      if (this.props.match?.params?.id) {
        modifiedState['case_id'] = parseInt(this.props.match.params.id)
      }
      modifiedState['case_type'] = CaseType[this.state.NewCaseType] ? CaseType[this.state.NewCaseType] : this.state.NewCaseType

      if (newAddress.delivery_id === 'New Address') {
        let newAddressData = {
          first_name: newAddress.firstName,
          last_name: newAddress.lastName,
          country_id: newAddress.country_id,
          state_province_id: newAddress.state_id,
          city: newAddress.city,
          address_1: newAddress.address,
          postcode: newAddress.zipCode,
          phone_no: newAddress.phoneNumber,
          fax_no: newAddress.faxNumber,
          case_id: modifiedState['case_id']
        }
        try {
          let deliveryNewAddress = (await axios.post(`case/address`, newAddressData)).data.delivery_address_id
          modifiedState['delivery_address_id'] = deliveryNewAddress
        } catch (e) {
          this.setState({
            savingData: false
          })
          if (modifiedState.case_status !== 'DRAFT') {
            if (Array.isArray(e.response?.data?.invalid)) {
              // this.props.showSnackbar(e.response.data.invalid.map(field => `Invalid field "${field}".`).join('\n'), 'error')
              this.props.showSnackbar('Please enter all required fields in Delivery tab', 'error')
            } else {
              this.props.showSnackbar(e.response?.data?.message ?? e.message ?? e, 'error')
            }
            console.error(e.response)
            throw e
          } else {
            modifiedState['delivery_address_id'] = null
          }
        }
      } else if (newAddress.delivery_id === null || typeof newAddress.delivery_id !== 'number') {
        this.setState({
          savingData: false
        })
        this.props.showSnackbar('Please select delivery address', 'error')
        throw new Error('Please select delivery address')
      } else {
        modifiedState['delivery_address_id'] = newAddress.delivery_id
      }
      // this.props.saveData(modifiedState)

      try {
        // await axios.post('case', modifiedState)
        // await safePostWithProgress('case', modifiedState, this.progressBarRef.current?.onProgressChange)
        // const stringifiedData = JSON.stringify(modifiedState)
        // console.log('Data len: ', stringifiedData.length/1024/1024, 'MB')
        // const buffer = (new TextEncoder()).encode(stringifiedData)
        form.append('body', JSON.stringify(modifiedState))
        await axios.post('case', form, {
          onUploadProgress: this.progressBarRef.current?.onProgressChange
        })
        this.setState({
          saved: true
        })
        if (modifiedState.case_status === 'DRAFT') {
          this.props.showSnackbar('Draft saved', 'success', true)
        } else {
          this.props.showSnackbar('Case submitted', 'success', true)
        }
        this.props.history.replace('/')
      } catch (err) {
        this.setState({
          savingData: false
        })
        console.error(err)
        this.props.showSnackbar(err.response?.data?.message ?? err.message ?? err, 'error')
      } finally {
        if (newAddress.delivery_id === 'New Address' && modifiedState['delivery_address_id']) {
          this.props.saveAddressId(modifiedState['delivery_address_id'])
        }
      }
    } finally {
      this.setState({
        uploading: false
      })
    }
  }

  handleChangeTab(tab) {
    this.setState({ tabMode: tab })
  }

  handleValidation(err) {
    this.setState({ error: err })
  }

  async componentDidMount() {
    if (this.state.tabMode === "photos") {
      this.scrollIntoView()
    }
    let country, state, addressList;
    try {
      country = (await axios.get(`res/country`)).data.country
      state = (await axios.get(`res/state_province`)).data.state_province
      addressList = (await axios.get(`case/address${isNaN(this.props.match.params.id)? '' : `?case_id=${this.props.match.params.id}`}`)).data.address
      this.setState({ countryList: country, stateList: state, addressList: addressList })
    }
    catch (err) {
      if (err.response && err.response.statusText === 'Unauthorized') {
        localStorage.removeItem("auth-token");
        localStorage.removeItem("expirationDate");
        localStorage.removeItem("role");
        this.props.history.push("/login")
      }
    }

    if (String.isNotBlank(this.props.match.params.id)) {
      let id = parseInt(this.props.match.params.id)
      try {
        var { data } = await axios.get(`case/${id}`)
      }
      catch (err) {
        if (err.response && err.response.statusText === 'Unauthorized') {
          localStorage.removeItem("auth-token");
          localStorage.removeItem("expirationDate");
          localStorage.removeItem("role");
          this.props.history.push("/login")
        }
      }
      if (data && data.case.length > 0) {
        if (data.case?.[0]?.case_impression?.[0]?.[0]?.case_impression_id) {
          this.setState({ case_impression_id: data.case?.[0]?.case_impression?.[0]?.[0]?.case_impression_id })
        }

        // if (data.case?.[0]?.case_detail?.[0]?.[0]?.excluded_dentition) {
        //   data.case[0].excluded_dentition = data.case?.[0]?.case_detail?.[0]?.[0]?.excluded_dentition.split(',').map(x => +x)
        // } else {
        //   data.case[0].excluded_dentition = []
        // }
      }

      if (data && this.state.patientInfo == null) {
        let intialState = {}
        // set patient info record
        intialState['promotionCode'] = "";
        intialState['gender'] = data.case[0].gender;
        intialState['lastName'] = data.case[0].last_name;
        intialState['firstName'] = data.case[0].first_name;
        intialState['day'] = parseInt(data.case[0].date_of_birth.split('-')[0]);
        intialState['month'] = parseInt(data.case[0].date_of_birth.split('-')[1]);
        intialState['year'] = parseInt(data.case[0].date_of_birth.split('-')[2]);
        intialState['country_code'] = data.case[0].country_code;
        intialState['contact_number'] = data.case[0].contact_number;
        intialState['email'] = data.case[0].email;
        intialState['remarks'] = data.case[0].remarks;
        intialState['patientPhoto'] = {
          data: data.case[0].profile_pic ? (/^https?:\/\//.test(data.case[0].profile_pic) ? data.case[0].profile_pic : join(ApiServerUrl, data.case[0].profile_pic)) : null,
          uploaded: true,
          rotate: 0
        };

        // for cn1
        if (data.case?.[0]?.case_detail?.[0]?.[0]) {
          Object.entries(data.case?.[0]?.case_detail?.[0]?.[0])
            .filter(([k]) => /^attr[0-9]{1,2}$/.test(k))
            .forEach(([k, v]) => {
              try {
                const [_, i] = k.match(/^attr([0-9]{1,2})$/)
                intialState[`attr${i}`] = v
              } catch {}
            })
        }

        //set Photo Data
        let modifiedFilesData = [...this.props.NewPatientData.files]
        let radioKey = 0, stlKey = 0, key = "";
        modifiedFilesData.forEach((v, index) => {

          if (v.category === 'photo' || v.category === 'radio') {
            key = v.category === "radio" ? v.category + radioKey : v.category + index
            radioKey = v.category === "radio" ? radioKey + 1 : 0
            v['data'] = (data.case[0].case_file.length > 0 && data.case?.[0]?.case_file?.[0]?.[0]?.[key]) ? (/^https?:\/\//.test(data.case?.[0]?.case_file?.[0]?.[0]?.[key]) ? data.case?.[0]?.case_file?.[0]?.[0]?.[key] : Url.resolve(ApiServerUrl, data.case?.[0]?.case_file?.[0]?.[0]?.[key])) : ""
            v['uploaded'] = (data.case[0].case_file.length > 0 && data.case?.[0]?.case_file?.[0]?.[0]?.[key])
            v['upload_later'] = data.case[0].case_file?.[0]?.[0]?.[`${key}_later`] === true
            v['will_not_upload'] = data.case[0].case_file?.[0]?.[0]?.[`${key}_later`] === null
          }
          else {
            key = v.category + stlKey
            stlKey = stlKey + 1
            v['data'] = (data.case[0].case_impression.length > 0 && data.case?.[0]?.case_impression?.[0]?.[0]?.[key]) ? (/^https?:\/\//.test(data.case?.[0]?.case_impression?.[0]?.[0]?.[key]) ? data.case?.[0]?.case_impression?.[0]?.[0]?.[key] : Url.resolve(ApiServerUrl, data.case?.[0]?.case_impression?.[0]?.[0]?.[key])) : ""
            v['uploaded'] = (data.case[0].case_impression.length > 0 && data.case?.[0]?.case_impression?.[0]?.[0]?.[key])
            v['upload_later'] = data.case[0].case_impression?.[0]?.[0]?.stl_files_later === true
            v['will_not_upload'] = data.case[0].case_impression?.[0]?.[0]?.stl_files_later === null
          }

        })
        intialState['files'] = modifiedFilesData;
        intialState['impressionType'] = (data.case[0].case_impression.length > 0 && data.case?.[0]?.case_impression?.[0]?.[0]?.type) ?? 'FILE';
        intialState['scannerBrand'] = (data.case[0].case_impression.length > 0 && data.case?.[0]?.case_impression?.[0]?.[0]?.['scanner_brand']) ? data.case?.[0]?.case_impression?.[0]?.[0]?.['scanner_brand'] : "";
        intialState['doctorId'] = (data.case[0].case_impression.length > 0 && data.case?.[0]?.case_impression?.[0]?.[0]?.['doctor_id']) ? data.case?.[0]?.case_impression?.[0]?.[0]?.['doctor_id'] : "";
        intialState['modernCode'] = (data.case[0].case_impression.length > 0 && data.case?.[0]?.case_impression?.[0]?.[0]?.['modern_code']) ? data.case?.[0]?.case_impression?.[0]?.[0]?.['modern_code'] : "";
        intialState['waybillNumber'] = (data.case[0].case_impression.length > 0 && data.case?.[0]?.case_impression?.[0]?.[0]?.['waybill_number']) ? data.case?.[0]?.case_impression?.[0]?.[0]?.['waybill_number'] : "";
        intialState['courierCompany'] = (data.case[0].case_impression.length > 0 && data.case?.[0]?.case_impression?.[0]?.[0]?.['courier_company']) ? data.case?.[0]?.case_impression?.[0]?.[0]?.['courier_company'] : "";
        let errors = [], photo_counter = 0, radio_counter = 0, impression_counter = 0

        const caseType = Object.entries(CaseType).find(([_, val]) => val === data.case[0].case_type)?.[0]

        this.props.NewPatientData.files.forEach((v, index) => {
          if (index < 6 && !v.data && !v.upload_later && !v.will_not_upload) {
            photo_counter += 1;
          }

          if (v.category === 'radio' && v.id === 'front_with_smile' && !v.data && !v.upload_later && !v.will_not_upload) {
            radio_counter += 1;
          }

          if (v.category === 'stl_file' && !v.data && !v.upload_later && !v.will_not_upload) {
            impression_counter += 1;
          }
        })

        if (photo_counter && caseType !== 'retainer') {
          let obj = { id: "photo", msg: this.get('warning.mandatoryPhoto'), valid: false }
          errors.push(obj)
        }

        if (radio_counter && caseType !== 'retainer') {
          let obj = { id: "radiograph", msg: this.get('warning.mandatoryRadiograph'), valid: false }
          errors.push(obj)
        }

        const impression = data.case[0].case_impression.length > 0 && data.case?.[0]?.case_impression?.[0]?.[0]
        switch (impression?.type ?? 'FILE') {
          case 'FILE': {
            if (impression_counter) {
              errors.push({ id: "impression", msg: this.get('warning.allStl'), valid: false })
            }
            break
          }
          case 'COURIER': {
            if (String.isBlank(impression.modern_code)) {
              errors.push({ id: "impression", msg: this.get('warning.courier'), valid: false })
            }
            break
          }
          case 'SCANNER': {
            if (String.isBlank(impression.scanner_brand) || String.isBlank(impression.doctor_id)) {
              errors.push({ id: "impression", msg: this.get('warning.scanner'), valid: false })
            }
            break
          }
          default:
            this.props.showSnackbar('Unknown impression type', 'error')
            throw new Error('unknown impression type')
        }
        // if (impression_counter) {
        //   let obj = { id: "impression", msg: this.get('warning.allStl'), valid: false }
        //   errors.push(obj)
        // }
        
        // set case retainer
        if (data.case?.[0]?.case_retainer?.length) {
          intialState['case_retainer_id'] = data.case?.[0]?.case_retainer?.[0]?.case_retainer_id
          intialState['retainer_pairs'] = data.case?.[0]?.case_retainer?.[0]?.retainer_pairs
          for (let i = 0; i < MaxRetainerPairs; i++) {
            intialState[`retainer_type${i}`] = data.case?.[0]?.case_retainer?.[0]?.[`retainer_type${i}`]
            intialState[`retainer_color${i}_upper`] = data.case?.[0]?.case_retainer?.[0]?.[`retainer_color${i}_upper`]
            intialState[`retainer_color${i}_lower`] = data.case?.[0]?.case_retainer?.[0]?.[`retainer_color${i}_lower`]
            intialState[`retainer_position${i}`] = data.case?.[0]?.case_retainer?.[0]?.[`retainer_position${i}`]
          }
        }
        
        intialState['errors'] = errors
        this.props.setTempData(intialState)
        this.setState({ patientInfo: "Edit Patient", NewCaseType: caseType, delivery_address_id: data.case[0].delivery_address_id })
      }
    }
    else {
      this.props.initData()
      this.setState({ patientInfo: "newPatient" })
    }
    this.props.handleInitData(this.state.countryList, this.state.stateList, this.state.addressList, this.state.delivery_address_id)

    if (this.state.delivery_address_id) {
      let address
      if (this.state.delivery_address_id == 'New Address') {
        address = 'New Address'
      } else {
        address = this.state.addressList.filter(v => {
          return v.delivery_address_id === this.state.delivery_address_id
        })
      }
      this.props.handleChangeAddress(address)
    }

    if (String.isNotBlank(this.props.match.params.type) && this.props.match.params.type === 'retainer') {
      this.props.ErrorData({ id: "photo", msg: '', valid: true })
      this.props.ErrorData({ id: "radiograph", msg: '', valid: true })
    }

    let defaultValue = {}
    beme_questionnaire.map((q) => {
      defaultValue[q.name] = q.default
    })
    this.setState(defaultValue)
  }

  render() {
    const { classes, isLoading } = this.props
    const { firstName, lastName, country_code, contact_number, email, files } = this.props.NewPatientData
    return (
      <Animated animationIn="fadeIn" isVisible={true} style={{ backgroundColor: 'rgb(242, 242, 242)' }}>
        <LoadingProgressBar ref={this.progressBarRef} open={this.state.uploading} />
        <Prompt
          when={!this.state.saved}
          message={(location, action) => {
            return `${this.get('warning.leaveWithoutSave')} \n${this.get('warning.saveDraft')}`
          }}
        />
        <Fragment>
          {(this.state.patientInfo == null || this.props.isLoading) ? (
            <Loader />
          ) : (
              <Grid
                container
                className={classes.devicesContainer}
              >
                <Grid item xs={12} style={{ padding: "0px 8px" }}>
                  <p>{this.get('breadcrumbs.caseManagement')} &gt; {this.get('breadcrumbs.newCase')}</p>
                </Grid>

                <Grid
                  item xs={12}
                  className={classes.leftCards}
                >
                  <Grid container className={classes.mainContainer}>
                  <Button variant="contained" className={this.props.classes.back} onClick={() => {this.props.history.push('/')}}>{getFromObj(this.props.lang, 'dict.pages.newpatient.back')}</Button>
                    <NewPatientsTabs
                      tabMode={this.state.tabMode}
                      handleChangeTab={(tab) => this.handleChangeTab(tab)}
                      handleValidation={(err) => { this.handleValidation(err) }}
                      firstName={firstName}
                      lastName={lastName}
                      country_code={country_code}
                      contact_number={contact_number}
                      email={email}
                      files={files}
                      NewPatientAddressData={this.props.NewPatientAddressData}
                      NewCaseType={this.state.NewCaseType}
                    />

                    <Grid item xs={12} style={{backgroundColor: "#7030a0", padding: "5px"}}>
                      <div ref={photoStartRef} />
                      <h3
                        style={{
                          textAlign: "left",
                          fontWeight: 600,
                          color: "#FFFFFF",
                          fontSize: 24,
                          marginBlockStart: 0,
                          marginBlockEnd: 0
                        }}
                      >
                        {this.get('breadcrumbs.newCase')} - {this.get(`tabs.${this.state.tabMode}`)}
                      </h3>
                      {/* <hr style={{ border: "solid 3px black", width: 88, margin: "16px 0px" }} align="left" /> */}
                    </Grid>

                    <div style={{
                      display: this.state.tabMode === "info" ? "contents" : "none"
                    }}>
                      <NewPatientInfo error={this.state.error} case_type={this.state.NewCaseType} />
                    </div>

                    {this.state.NewCaseType !== "retainer" ? (
                      <div style={{
                        display: this.state.tabMode === "questionnaire" ? "block" : "none", width: '100%'
                      }}>
                        <NewPatientQuestionnaire />
                      </div>
                    ) : ""}

                    {this.state.NewCaseType === "retainer" ? (
                      <div style={{
                        display: this.state.tabMode === "colorpattern" ? "block" : "none", width: '100%'
                      }}>
                        <ColorPattern />
                      </div>
                    ) : ""}

                    <div style={{
                      display: this.state.tabMode === "delivery" ? "contents" : "none"
                    }}>
                      <NewPatientDelivery tabMode={this.state.tabMode} />
                    </div>
                    <div style={{
                      display: this.state.tabMode === "photos" ? "contents" : "none"
                    }}>
                      <NewPatientPhotos tabMode={this.state.tabMode} />
                    </div>

                    <div style={{
                      display: this.state.tabMode === "impression" ? "contents" : "none"
                    }}>
                      <NewPatientPhotos tabMode={this.state.tabMode} />
                    </div>

                    <div style={{
                      display: this.state.tabMode === "radiography" ? "contents" : "none"
                    }}>
                      <NewPatientPhotos tabMode={this.state.tabMode} case_type={this.state.NewCaseType} />
                    </div>

                    <div style={{
                      display: this.state.tabMode === "summary" ? "contents" : "none"
                    }}>
                      <NewPatientSummary NewCaseType={this.state.NewCaseType} />
                    </div>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <NewPatientsButtonTabs
                    savingData={this.state.savingData}
                    handleSave={saveType => this.handleSave(saveType)}
                    tabMode={this.state.tabMode}
                    handleChangeTab={(tab) => this.handleChangeTab(tab)}
                    handleValidation={(err) => { this.handleValidation(err) }}
                    firstName={firstName}
                    lastName={lastName}
                    country_code={country_code}
                    contact_number={contact_number}
                    email={email}
                    files={files}
                    NewPatientAddressData={this.props.NewPatientAddressData}
                    NewCaseType={this.state.NewCaseType}
                  />
                </Grid>

              </Grid>
            )}
        </Fragment>
      </Animated>
    )
  }
}

const mapStateToProps = store => {
  return {
    NewPatientData: store.newPatientReducer,
    NewPatientAddressData: store.newAddressReducer,
    isLoading: store.loading.isLoading,
    lang: store.lang,
    config: store.config.config
  }
};

const mapDispatchToProps = dispatch => {
  return {
    saveData: (newPatientData) => dispatch(saveAction.save(newPatientData)),
    setTempData: (newPatientData) => dispatch(saveAction.tempSave(newPatientData)),
    initData: () => dispatch({ type: 'NEW_PATIENT_INIT_TEMP' }),
    handleInitData: (countryList, stateList, addressList, delivery_address_id) => dispatch({ type: 'NEW_PATIENT_DELIVERY_ADDRESS_INIT', country: countryList, state: stateList, address: addressList, delivery_address_id: delivery_address_id }),
    saveAddressId: addressId => dispatch({ type: 'NEW_PATIENT_DELIVERY_ADDRESS_ID', value: addressId}),
    showSnackbar: (msg, variant, queue=false) => dispatch(easySnackbar(msg.toString(), variant, queue)),
    ErrorData: (errorMsg) => dispatch({ type: 'NEW_PATIENT_DATA_ERROR', errorMsg: errorMsg }),
    handleChangeAddress: (value) => dispatch({ type: actionNewAddress.NEW_PATIENT_DELIVERY_ADDRESS, address: value })
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(withLang(NewPatient, 'NewPatient'))))
