export const beme_questionnaire = [
    {required: true, name: "attr1", field_name: "attr1", question: "Chief correction goal", default: "type_1", options:[
        {value: "type_1", label: "Align the teeth"},
        {value: "type_2", label: "Close the interdental space"},
        {value: "type_3", label: "Improve protruded facial profile"},
        {value: "type_4", label: "Correct anterior crossbite"}
    ]},
    {required: true, name: "attr2", field_name: "attr2", question: "Dental arch(es) for treatment", default: "type_1", options:[
        {value: "type_1", label: "Both arches"},
        {value: "type_2", label: "Upper arch"},
        {value: "type_3", label: "Lower arch"}
    ]},
    {required: false, name: "attr3", field_name: "attr3", question: "Any teeth that should not be moved? (E.g. Implants, ankylosed teeth)", default: "type_1", options:[
        {value: "type_1", label: "No"},
        {value: "type_2", label: "Yes. Specify the teeth not to be moved in Notes and Comments"}
    ]},
    {required: false, name: "attr4", field_name: "attr4", question: "Any teeth that attachments cannot be bonded on? (e.g. Crowns, bridges, porcelain work)", default: "type_1", options:[
        {value: "type_1", label: "No"},
        {value: "type_2", label: "Yes. Specify the teeth which attachments cannot be bonded in Notes and Comments"}
    ]},
    {required: true, name: "attr5", field_name: "attr5", question: "Mid-line", default: "type_3", options:[
        {value: "type_1", label: "Maintain"},
        {value: "type_2", label: "Improve"},
        {value: "type_3", label: "Confirm after 3D design"}
    ]},
    {required: false, name: "attr6", field_name: "attr6", question: "Is this a remote monitoring case?", default: "type_1", options:[
        {value: "type_0", label: "Yes. The patient will not be able to see me in person before the treatment completes"},
        {value: "type_1", label: "No. The patient will come for physical consultation for mid-treatment and completion review"}
    ]}
]

export default { beme_questionnaire }