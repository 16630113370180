import { PublicUrl, WebPushPublicKey } from './config.json'
import Axios from './axios-default'
import join from 'url-join'
import Url from 'url'

function getDomain(host) {
  return origin.match(/^(www|[a-zA-Z\-_]+)?\.([^\.]+)\.([^\.]+)$/)?.slice(2).join('.')
}

export async function init(config) {
  if ('serviceWorker' in navigator && 'permissions' in navigator && 'Notification' in window) {
    const publicUrl = new URL(PublicUrl, window.location.href)

    if (getDomain(publicUrl.host) !== getDomain(window.location.host)) {
      console.error('[ServiceWorker] Different domain.')
      return
    }

    // if (await requestPermission()) {
      window.addEventListener('load', async () => {
        const swUrl = join(PublicUrl, `/sw.js`)
        try {
          await register(swUrl, config)
        } catch {}
      })
    // } else {
    //   console.error('User rejected push permission request')
    // }
  }
}

async function register(url, config) {
  try {
    // if (navigator.serviceWorker.)
    const registration = await navigator.serviceWorker.register(url, { scope: '/' }),
          pushSubscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(WebPushPublicKey)
          })
    await Axios.post('/push/register', pushSubscription.toJSON())
  } catch (err) {
    console.error('Error during service worker registration:', err);
  }
}

async function requestPermission() {
  if (Notification.permission !== 'granted') {
    if (await Notification.requestPermission() === 'granted') {
      return true
    }
    return await new Promise(resolve =>
      navigator.permissions.query({ name: 'notifications' }).then(status => {
        if (status.state === 'prompt') {
          status.onchange = () => {
            if (status.state === 'granted') {
              resolve(true)
            }
          }
        } else if (status.state === 'granted') {
          resolve(true)
        } else if (status.state === 'denied') {
          resolve(false)
        }
      })
    )
  } else {
    return true
  }
}

function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}