import React, { Component } from 'react'
import { Grid, Paper, CircularProgress, Button } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import Axios from '../../axios-default'
import '../../util/date_beme'
import QueryString from 'querystring'
import DateFnsUtils from '@date-io/date-fns';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core'
import './account.css'
// components
import ReactApexChart from 'react-apexcharts'
import { connect } from 'react-redux'
import { withLang } from '../../util/lang'
import Moment from 'moment'

const customTheme = createMuiTheme({
  overrides: {
    MuiPickersBasePicker: {
      pickerView: {
        backgroundColor: "white"
      }
    },
    MuiPickersDay: {
      day: {
        color: "light-gray",
        fontFamily: "\"Do Hyeon\", sans-serif",
        backgroundColor: "white",
        borderRadius: "0px",
      },
      container: {
        backgroundColor: "black"
      },
      daySelected: {
        backgroundColor: "",
        color: "light-gray"
      },
      dayDisabled: {
        color: "black",
      },
      current: {
        color: "",
      },
    },
  },
});

class CaseStatChart extends Component {

  CaseStatusList = [
    [ 'draft', 'Draft' ],
    [ 'submitted', 'Submitted' ],
    [ 'awaiting', 'Awaiting' ],
    [ 'received', 'Received' ],
    [ 'plan_uploaded', 'PlanUploaded' ],
    [ 'change_request', 'ChangeRequest' ],
    [ 'plan_approved', 'PlanApproved' ],
    [ 'production', 'Production' ],
    [ 'delivered', 'Delivered' ],
    [ 'next_set_request', 'NextSetRequest' ],
    [ 'all_delivered', 'AllDelivered' ],
    [ 'ended', 'Ended' ],
    [ 'refinement', 'Refinement' ]
  ]

  constructor(props) {
    super(props)
    this.state = {
      series: [],
      categories: [],
      currentStatus: "",
      status: [{ label: "ALL", value: "All" }, { label: "Submitted", value: "Submitted" }, { label: "Draft", value: "Darft" }],
      startDate: props.startDate ?? null,
      endDate: props.endDate ?? null,
      startDateChanged: false,
      endDateChanged: false,
      loading: true
    }
  }

  handleStart = date => {
    // this.props.handleChangeStart(date)
    // this.startDate = date
    if (date != this.state.startDate) {
      this.setState({ startDate: date, startDateChanged: true })
    }
  };

  handleEnd = date => {
    // this.props.handleChangeEnd(date)
    // this.endDate = date
    if (date != this.state.endDate) {
      this.setState({ endDate: date, endDateChanged: true })
    }
  };

  handleSubmit = () => {
    if (this.state.loading || (!this.state.endDateChanged && !this.state.startDateChanged)) return
    this.props.handleChangeRange(this.state.startDate, this.state.endDate)
    this.setState({ loading: true })
  }

  handleChangeStatus = val => {
    this.setState({
      currentStatus: val
    });
  }

  componentDidMount() {
    this.loadStat()
  }

  componentDidUpdate(prevProps) {
    try {
      if (
        prevProps.user_id !== this.props.user_id ||
        prevProps.country_id !== this.props.country_id ||
        prevProps.startDate.getTime() !== this.props.startDate.getTime() ||
        prevProps.endDate.getTime() !== this.props.endDate.getTime()
      ) {
        this.startDate = this.props.startDate
        this.endDate = this.props.endDate
        this.loadStat()
      }
    } catch (e) { }
  }

  async loadStat() {
    try {
      let { startDate, endDate, country_id, user_id } = this.props
      startDate = new Date(startDate)
      endDate = new Date(endDate)
      // console.log(endDate)
      if (!startDate) {
        startDate = new Date()
        // startDate.setMonth(startDate.getMonth() - 6 + 1)
        startDate.setDate(startDate.getDate() - 7)
      }
      if (!endDate) {
        endDate = new Date()
      }
      // endDate.setMonth(endDate.getMonth() + 1)
      endDate.setDate(endDate.getDate() + 1)
      const query = QueryString.stringify({
        country_id,
        user_id,
        startDay: startDate.getDate(),
        startMonth: startDate.getMonth() + 1,
        startYear: startDate.getFullYear(),
        endDay: endDate.getDate(),
        endMonth: endDate.getMonth() + 1,
        endYear: endDate.getFullYear()
      })
      // console.log(query)
      let { data } = await Axios.get('stats/case/daily/change?' + query)
      // console.log(data)
      data = data.stat.reduce((acc, date) => {
        if (date) {
          acc[`${date.year}-${date.month}-${date.day}`] = date
        }
        return acc
      }, {})
      
      let curDate = new Date(startDate),
        stopDate = new Date(endDate),
        dayIndex = 0,
        series = [],
        categories = []
      // curDate.setDate(1)
      while (stopDate - curDate > 0) {
        const year = curDate.getFullYear(),
              month = curDate.getMonth(),
              day = curDate.getDate(),
              curKey = `${year}-${month + 1}-${day}`
        // var submitted = 0,
        //     draft = 0
        this.CaseStatusList.forEach(([caseStatus, statusLabel]) => {
          if (!series[caseStatus]) {
            series[caseStatus] = {
              name: statusLabel,
              data: []
            }
          }
          series[caseStatus].data[dayIndex] = (series[caseStatus].data[dayIndex] ?? 0) + (data[curKey]?.[caseStatus] ?? 0)
        })
        // if (curKey in data) {
        //   var { submitted, draft } = data[curKey]
        // }
        // if (!series['submitted']) {
        //   series['submitted'] = {
        //     name: 'Submitted',
        //     data: []
        //   }
        // }
        // if (!series['draft']) {
        //   series['draft'] = {
        //     name: 'Draft',
        //     data: []
        //   }
        // }
        // series['submitted'].data[dayIndex] = (series['submitted'].data[dayIndex] || 0) + submitted
        // series['draft'].data[dayIndex] = (series['draft'].data[dayIndex] || 0) + draft
        const catName = `${day} ${Date.getMonthName(month)} ${year}`
        if (!categories.includes(catName)) {
          categories.push(catName)
        }
        curDate.setDate(curDate.getDate() + 1)
        dayIndex++
      }
      // while (stopDate - curDate > 0) {
      //   let firstDayOfMonth = new Date(curDate)
      //   firstDayOfMonth.setDate(1)
      //   let lastDayOfMonth = new Date(firstDayOfMonth)
      //   lastDayOfMonth.setMonth(lastDayOfMonth.getMonth() + 1)
      //   lastDayOfMonth.setDate(0)
      //   const year = curDate.getFullYear(),
      //     month = curDate.getMonth(),
      //     weekNo = curDate.getWeek() - firstDayOfMonth.getWeek() + 1
      //   var submitted = 0, draft = 0
      //   if (`${weekNo}-${year}-${month + 1}` in data) {
      //     var { submitted, draft } = data[`${weekNo}-${year}-${month + 1}`]
      //   }
      //   if (!series['submitted']) {
      //     series['submitted'] = {
      //       name: 'Submitted',
      //       data: []
      //     }
      //   }
      //   if (!series['draft']) {
      //     series['draft'] = {
      //       name: 'Draft',
      //       data: []
      //     }
      //   }
      //   series['submitted'].data[monthIndex] = (series['submitted'].data[monthIndex] || 0) + submitted
      //   series['draft'].data[monthIndex] = (series['draft'].data[monthIndex] || 0) + draft
      //   const catName = `${Date.getMonthName(month)} ${year}`
      //   if (!categories.includes(catName)) {
      //     categories.push(catName)
      //   }
      //   if (curDate.getDate() + 7 > lastDayOfMonth.getDate()) {
      //     curDate = lastDayOfMonth
      //     curDate.setDate(curDate.getDate() + 1)
      //     monthIndex++
      //   } else {
      //     curDate.setDate(curDate.getDate() + 7 - curDate.getDay())
      //   }
      // }
      series = Object.values(series)
      this.setState({
        series,
        categories,
        startDateChanged: false,
        endDateChanged: false,
        loading: false
      })
    } catch (e) {
      console.error(e)
      if (e && e.response && e.response.statusText === 'Unauthorized') {
        localStorage.removeItem("auth-token");
        localStorage.removeItem("expirationDate");
        localStorage.removeItem("role");
        this.props.history.replace('/login')
      }
    }
  }

  render() {
    const config_color = ["#1b262c", "#3282b8", "#8ec6c5", "#0f4c75"],
      { categories, series } = this.state
    return (
      <MuiThemeProvider theme={customTheme}>
        <Grid item xs={12} style={{ padding: "16px 8px 16px 0px" }}>
          <Paper>
            <Grid container>
              <Grid item xs={12} style={{ textAlign: "center", padding: "16px 0px" }}>
                <h3>{this.get('caseChartTitle')}</h3>
                <p>{this.get('caseChartSubtitle')}</p>
              </Grid>

              <Grid item xs={6} style={{ padding: 8 }}>
                <p>{this.get(':fields.startDate')}:</p>
                <div style={{ padding: 8, border: `solid ${this.state.startDateChanged? 'blue 2px' : 'black 1px'}` }}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      minDate={Moment().subtract(5, 'years').toDate()}
                      maxDate={Moment().add(1, 'month').toDate()}
                      views={["year", "month", "date"]}
                      variant="inline"
                      className="customDatepicker"
                      value={this.state.startDate}
                      onChange={this.handleStart}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </Grid>

              <Grid item xs={5} style={{ padding: 8 }}>
                <p>{this.get(':fields.endDate')}:</p>
                <div style={{ padding: 8, border: `solid ${this.state.endDateChanged? 'blue 2px' : 'black 1px'}` }}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      minDate={this.state.startDate? Moment(this.state.startDate).add(1, 'd') : Moment().subtract(5, 'years').toDate()}
                      maxDate={Moment().toDate()}
                      views={["year", "month", "date"]}
                      variant="inline"
                      className="customDatepicker"
                      value={this.state.endDate}
                      onChange={this.handleEnd}
                    />
                  </MuiPickersUtilsProvider>
                </div>

              </Grid>

              <Grid container item xs={1} justify="center" alignItems="flex-end">
                <Grid container item xs={12} justify="center" alignItems="flex-end">
                  {this.state.loading
                    ? <CircularProgress style={{ padding: 8, marginBottom: 15 }}/>
                    : <Button
                        variant="outlined"
                        style={{ padding: 8, marginBottom: 15 }}
                        onClick={this.handleSubmit}
                      >
                        {this.get(':const.submit')}
                      </Button>
                  }
                </Grid>
              </Grid>

              {/*<Grid item xs={12} style={{padding: 8}}>
                  <Select
                  displayEmpty
                  variant="outlined"
                  value={this.state.currentStatus}
                  onChange = {(event) => this.handleChangeStatus(event.target.value)}
                  style={{
                    width:"100%"
                  }}
                >
                  <MenuItem value="" disabled>
                    Please select status
                  </MenuItem>

                  {
                    this.state.status.map(v =>
                      <MenuItem value={v.value} key={v.value}>{v.label}</MenuItem>
                    )

                  }
                </Select>

            </Grid>*/}

              <Grid item xs={12}>
                <ReactApexChart
                  options={{
                    chart: {
                      shadow: {
                        enabled: true,
                        color: '#000',
                        top: 18,
                        left: 7,
                        blur: 10,
                        opacity: 1
                      },
                      toolbar: {
                        show: false
                      },
                      stacked: true
                    },
                    colors: config_color,
                    dataLabels: {
                      enabled: true,
                    },
                    stroke: {
                      curve: 'straight'
                    },
                    title: {
                      text: this.props.title,
                      align: 'left',
                      style: {
                        fontSize: '28px'
                      },
                    },
                    grid: {
                      borderColor: '#e7e7e7',
                      row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                      },
                    },
                    markers: {
                      size: 6
                    },
                    xaxis: {
                      // categories: this.props.data.map(v => v.date),
                      categories,
                      title: {
                        text: this.get(':fields.date')
                      }
                    },
                    yaxis: {
                      title: {
                        text: ''
                      },
                      min: 0,
                      // max: Math.max(...this.props.data.map(v=>v.revenue)) + 100
                      // max: 20
                    },
                    legend: {
                      show: true,
                      position: 'top',
                      horizontalAlign: 'left',
                      floating: false,
                      // offsetY: -25,
                      // offsetX: -5
                      onItemClick: {
                        toggleDataSeries: false
                      }
                    }
                  }}
                  series={series}
                  type="bar"
                  height="350"
                  width="100%"
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </MuiThemeProvider>
    );
  }
}

CaseStatChart.propTypes = {
  user_id: PropTypes.number,
  country_id: PropTypes.number,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date)
}

const mapStateToProps = store => {
  return {
    lang: store.lang
  }
};

export default connect(mapStateToProps)(withRouter(withLang(CaseStatChart, 'Account')))