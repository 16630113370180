Object.getFromObj = (obj, key, defaultVal=null) => {
  let finalRet = null
  if (typeof obj === 'object') {
    let ret = obj
    typeof key === 'string' && key.split('.').every(k => {
      if (k.trim().length) {
        ret = ret[k.trim()] || ret[Object.keys(ret).find(_k => _k.toLowerCase().trim() === k.toLowerCase().trim())]
      }
      return ret
    })
    finalRet = ret || null
  } else {
    finalRet = null
  }
  return finalRet === null? (typeof defaultVal === 'function'? defaultVal(obj) : defaultVal) : finalRet
}

Object.objToDict = obj => {
  if (typeof obj === 'object') {
    let ret = {},
        queue = [[undefined, obj]]
    while (queue.length) {
      const [path, obj] = queue.pop()
      Object.entries(obj).map(([key, value]) => {
        const realPath = [path, key].filter(v => !!v).join('.')
        if (typeof value === 'object') {
          queue.push([realPath, value])
        } else if (typeof value === 'string') {
          ret[realPath] = value
        } else {
          ret[realPath] = value.toString()
        }
      })
    }
    return ret
  } else {
    return null
  }
}

Object.ifNull = (obj, defaultVal, checkFunc=undefined) => {
  if (checkFunc? checkFunc(obj) : obj === null) {
    return typeof defaultVal === 'function'? defaultVal(obj) : defaultVal
  } else {
    return obj
  }
}