import '../../util/string'
import React, { Component } from 'react'
import { Grid, TextField, Button, Select, MenuItem } from '@material-ui/core'
import style from './NewPatientButtonTabs.module.css';
import { connect } from 'react-redux';
import AlertDialog from '../Dialog/Dialog'
import { withLang } from '../../util/lang';

class NewPatientButtonTabs extends Component {

  constructor(props) {
    super(props)

    let initialTabMode = ["info", "questionnaire", "photos", "radiography", "impression", "delivery", "summary"]

    if (this.props.NewCaseType == 'retainer') {
      initialTabMode = ["info", "colorpattern", "impression", "delivery", "summary"]
    }

    this.state = {
      tabMode: initialTabMode,
      prev: "",
      next: "",
      summaryDialogOpen: false,
      firstNameDialogOpen: false,
      lastNameDialogOpen: false,
      // cityDialogOpen: false,
      // zipCodeDialogOpen: false,
      addressDialogOpen: false,
      phoneDialogOpen: false,
      countryCodeDialogOpen: false,
      contactNumberDialogOpen: false,
      alignerPackageTypeDialogOpen: false,
      emailDialogOpen: false,
      dialogOpen: false,
      dialogMsg: ''
    }
    this.submitData = this.submitData.bind(this)
    this.handleDialogClose = this.handleDialogClose.bind(this)
    this.handleChangeTab = this.handleChangeTab.bind(this)
  }
  handleDialogClose(type) {
    switch (type) {

      case 'summary':
        this.setState({ summaryDialogOpen: false })
        break
      case 'first_name':
        this.setState({ firstNameDialogOpen: false })
        break
      case 'last_name':
        this.setState({ lastNameDialogOpen: false })
        break
      // case 'city':
      //   this.setState({ cityDialogOpen: false })
      //   break
      // case 'zipCode':
      //   this.setState({ zipCodeDialogOpen: false })
      //   break
      case 'address':
        this.setState({ addressDialogOpen: false })
        break
      case 'phone':
        this.setState({ phoneDialogOpen: false })
        break
      case 'country_code':
        this.setState({ countryCodeDialogOpen: false })
        break
      case 'contact_number':
        this.setState({ contactNumberDialogOpen: false })
        break
      case 'aligner_package_type':
        this.setState({ alignerPackageTypeDialogOpen: false })
        break
      case 'email':
        this.setState({ emailDialogOpen: false })
        break
      case undefined:
        this.setState({ dialogOpen: false, dialogMsg: '' })
        break
    }
  }

  handleChangeTab(inc) {
    // let nextTab = ''
    if (typeof inc === 'number') {
      inc = Math.min(1, Math.max(-1, inc))
      // nextTab = this.state.tabMode[this.state.tabMode.indexOf(this.props.tabMode) - 1]
      // if (!nextTab) throw new Error('unknown next tab')
    } else {
      throw new Error('unknown tab change')
    }
    if (this.props.NewCaseType != "retainer") {
      this.props.ErrorData({ id: "colorpattern", msg: "", valid: true })
    }
    if (this.props.tabMode === 'info') {
      if (this.props.NewPaitientData.firstName.length == 0) {
        this.setState({ firstNameDialogOpen: true })
        //this.props.handleValidation({"firstName":"First Name is required"})
      }
      else if (this.props.NewPaitientData.lastName.length == 0) {
        //this.props.handleValidation({"lastName":"Last Name is required"})
        this.setState({ lastNameDialogOpen: true })
      }
      else if (String.isBlank(this.props.NewPaitientData.country_code)) {
        this.setState({ countryCodeDialogOpen: true })
      }
      else if (String.isBlank(this.props.NewPaitientData.contact_number)) {
        this.setState({ contactNumberDialogOpen: true })
      }
      else if (String.isBlank(this.props.NewPaitientData.email)) {
        this.setState({ emailDialogOpen: true })
      }
      else if (this.props.NewCaseType != "retainer" && String.isBlank(this.props.NewPaitientData.attr0)) {
        this.setState({ alignerPackageTypeDialogOpen: true })
      }
      else {
        this.props.ErrorData({ id: "patient_info", msg: '', valid: true })
        this.changetab(inc)
      }
    } else if (this.props.tabMode === 'delivery') {
      if (this.props.NewPatientAddressData.delivery_id == 'New Address' && this.props.NewPatientAddressData.city.length == 0) {
        this.setState({ cityDialogOpen: true })
      }
      else if (this.props.NewPatientAddressData.delivery_id == 'New Address' && this.props.NewPatientAddressData.zipCode.length == 0) {
        this.setState({ zipCodeDialogOpen: true })
      }
      else if (this.props.NewPatientAddressData.delivery_id == 'New Address' && this.props.NewPatientAddressData.phoneNumber.length == 0) {
        this.setState({ phoneDialogOpen: true })
      } else {
        this.changetab(inc)
      }
    } else if (this.props.tabMode === 'photos') {
      let counter = 0
      if (this.props.NewCaseType !== 'retainer') {
        this.props.NewPaitientData.files.map((v, index) => {
          if (index < 6 && !v.data && !v.upload_later && !v.will_not_upload) {
            counter += 1;
          }
        })
      }
      if (counter && this.props.NewCaseType !== 'retainer') {
        this.props.ErrorData({ id: "photo", msg: this.get('warning.mandatoryPhoto'), valid: false })
      }
      else {
        this.props.ErrorData({ id: "photo", msg: '', valid: true })
      }
      this.changetab(inc)
    } else if (this.props.tabMode === 'radiography') {
      let counter = 0
      if (this.props.NewCaseType !== 'retainer') {
        this.props.NewPaitientData.files.map((v, index) => {
          if (v.category === 'radio' && v.id === 'front_with_smile' && !v.data && !v.upload_later && !v.will_not_upload) {
            counter += 1;
          }
        })
      }
      if (counter && this.props.NewCaseType !== 'retainer') {
        this.props.ErrorData({ id: "radiograph", msg: this.get('warning.mandatoryRadiograph'), valid: false })
      }
      else {
        this.props.ErrorData({ id: "radiograph", msg: '', valid: true })
      }
      this.changetab(inc)
    }
    else if (this.props.tabMode === 'impression') {
      switch (this.props.NewPaitientData.impressionType) {
        case 'FILE': {
          if (this.props.NewPaitientData.files.some(v =>
            v.category === 'stl_file' && !v.data && !v.upload_later && !v.will_not_upload
          )) {
            this.props.ErrorData({ id: "impression", msg: this.get('warning.allStl'), valid: false })
          } else {
            this.props.ErrorData({ id: "impression", msg: '', valid: true })
          }
          break
        }
        case 'COURIER': {
          if (String.isBlank(this.props.NewPaitientData.modernCode)) {
            this.props.ErrorData({ id: "impression", msg: this.get('warning.courier'), valid: false })
          } else {
            this.props.ErrorData({ id: "impression", msg: '', valid: true })
          }
          break
        }
        case 'SCANNER': {
          if (String.isBlank(this.props.NewPaitientData.scannerBrand) || String.isBlank(this.props.NewPaitientData.doctorId)) {
            this.props.ErrorData({ id: "impression", msg: this.get('warning.scanner'), valid: false })
          } else {
            this.props.ErrorData({ id: "impression", msg: '', valid: true })
          }
          break
        }
      }
      this.changetab(inc)
    } else if (this.props.tabMode == "colorpattern") {
      for (let i = 0; i < this.props.NewPaitientData.retainer_pairs; i++) {
        if (this.props.NewPaitientData[`retainer_type${i}`] == null) {
          this.setState({ dialogMsg: `Missing Retainer ${i + 1} Type`, dialogOpen: true })
          return
        } else if (this.props.NewPaitientData[`retainer_type${i}`] == "fashion") {
          if (this.props.NewPaitientData[`retainer_color${i}_upper`] == null) {
            this.setState({ dialogMsg: `Missing Retainer ${i + 1} Upper Jaw Color`, dialogOpen: true })
            return
          } else if (this.props.NewPaitientData[`retainer_color${i}_lower`] == null) {
            this.setState({ dialogMsg: `Missing Retainer ${i + 1} Lower Jaw Color`, dialogOpen: true })
            return
          } else if (this.props.NewPaitientData[`retainer_position${i}`] == null) {
            this.setState({ dialogMsg: `Missing Retainer ${i + 1} Position`, dialogOpen: true })
            return
          }
        }
      }
      this.props.ErrorData({ id: "colorpattern", msg: "", valid: true })
      this.changetab(inc)
    } else {
      this.changetab(inc)
    }

  }

  changetab(inc) {
    if (typeof inc === 'number') {
      inc = Math.min(1, Math.max(-1, inc))
    } else {
      throw new Error('unknown tab change')
    }
    let currentIndexVal = this.state.tabMode.indexOf(this.props.tabMode)
    if (currentIndexVal < 0) throw new Error('unknown next tab')
    let nextState = this.state.tabMode[currentIndexVal + inc]
    this.setState({ next: nextState, current: nextState })
    this.props.handleChangeTab(nextState)
    this.props.handleValidation({})
  }
  submitData(e) {
    let errors = this.props.NewPaitientData.errors;
    let counter = 0
    errors.map(v => {
      if (!v.valid) {
        counter++
      }
    })

    if (counter) {
      this.setState({ summaryDialogOpen: true })
    }
    else {
      this.props.handleSave('submitted')
    }

  }
  render() {
    return (

      <Grid item xs={12}>
        {
          this.state.summaryDialogOpen && <>
            <AlertDialog
              title="Warning"
              dialogOpen={true}
              handleDialogClose={() => this.handleDialogClose('summary')}
              // content={this.get('warning.allMandatory')}
              content="Submission is incomplete. Refer to the red texts for instructions."
            />
          </>
        }

        {
          this.state.firstNameDialogOpen && <>
            <AlertDialog
              title="Warning"
              dialogOpen={true}
              handleDialogClose={() => this.handleDialogClose('first_name')}
              content={this.get('warning.firstName')}
            />
          </>
        }

        {
          this.state.lastNameDialogOpen && <>
            <AlertDialog
              title="Warning"
              dialogOpen={true}
              handleDialogClose={() => this.handleDialogClose('last_name')}
              content={this.get('warning.lastName')}
            />
          </>
        }

        {
          this.state.addressDialogOpen && <>
            <AlertDialog
              title="Warning"
              dialogOpen={true}
              handleDialogClose={() => this.handleDialogClose('address')}
              content='Please provide valid address'
            />
          </>
        }

        {/* {
          this.state.zipCodeDialogOpen && <>
            <AlertDialog
              title="Warning"
              dialogOpen={true}
              handleDialogClose={() => this.handleDialogClose('zipCode')}
              content={this.get('warning.postCode')}
            />
          </>
        } */}

        {
          this.state.phoneDialogOpen && <>
            <AlertDialog
              title="Warning"
              dialogOpen={true}
              handleDialogClose={() => this.handleDialogClose('phone')}
              content={this.get('warning.phoneNo')}
            />
          </>
        }

        {
          this.state.countryCodeDialogOpen && <>
            <AlertDialog
              title={this.get(':const.warning')}
              dialogOpen={true}
              handleDialogClose={() => this.handleDialogClose('country_code')}
              content='Missing Country Code'
            />
          </>
        }

        {
          this.state.contactNumberDialogOpen && <>
            <AlertDialog
              title={this.get(':const.warning')}
              dialogOpen={true}
              handleDialogClose={() => this.handleDialogClose('contact_number')}
              content='Missing Contact Number'
            />
          </>
        }

        <AlertDialog
          title={this.get(':const.warning')}
          dialogOpen={this.state.alignerPackageTypeDialogOpen}
          handleDialogClose={() => this.handleDialogClose('aligner_package_type')}
          content="Missing Aligner Package Type"
        />

        {
          this.state.emailDialogOpen && <>
            <AlertDialog
              title="Warning"
              dialogOpen={true}
              handleDialogClose={() => this.handleDialogClose('email')}
              content="Missing Email"
            />
          </>
        }

        <AlertDialog
          title="Warning"
          dialogOpen={this.state.dialogOpen}
          handleDialogClose={() => this.handleDialogClose()}
          content={this.state.dialogMsg}
        />

        <div className={style.customButton}>
          {this.props.tabMode !== "info" && <button style={{ border: "solid #593687 2px", backgroundColor: "transparent", color: "#593687" }} type="button" onClick={() => this.handleChangeTab(-1)}>{this.get('navBtns.previous')}</button>}

          {this.props.tabMode !== "info" && this.props.tabMode !== "summary" && <button type="button" className={style.next} value="tempSave" onClick={(e) => this.props.handleSave('tempSave')} >{this.get('navBtns.saveDraft')}</button>}

          {this.props.tabMode !== "summary" ?
            <button type="button" onClick={() => this.handleChangeTab(1)} className={style.next}>{this.get('navBtns.next')}</button> :
            <button type="button" className={style.next} onClick={this.submitData} disabled={this.props.savingData} value="save">{this.get('navBtns.submit')}</button>
          }
        </div>
      </Grid>
    );
  }
}

const mapStateToProps = store => {
  return {
    NewPaitientData: store.newPatientReducer,
    lang: store.lang
  }
};

const mapDispatchToProps = dispatch => {
  return {
    ErrorData: (errorMsg) => dispatch({ type: 'NEW_PATIENT_DATA_ERROR', errorMsg: errorMsg })
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withLang(NewPatientButtonTabs, 'NewPatient'));
