import frontend_config from '../../frontend_config';
const hostname = window.location.hostname
export const styles = theme => ({
  root: {
    margin: '48px auto',
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
    },
    [theme.breakpoints.up('lg')]: {
      width: "70%",
      minHeight: "400px",
    },
  },
  banner: {
    [theme.breakpoints.down('xs')]: {
      display: "none"
    },
  },
  leftCards: {
    padding: "8px 8px 8px 0px",
    [theme.breakpoints.down('sm')]: {
      padding: "0px"
    },
  },
  rightCard: {
    padding: "8px 0px 8px 8px",
    [theme.breakpoints.down('sm')]: {
      padding: "0px"
    },
  },
  rightTitles: {
    fontSize: 18,
    marginBlockStart: 0,
    marginBlockEnd: "8px"
  },
  mainTitle: {
    fontSize: 18,
    marginBlockStart: "16px",
    marginBlockEnd: "8px"
  },
  linkBtn: {
    border: "solid 1px black",
    color: "black",
    padding: "4px 24px",
    fontWeight: 300,
    margin: "4px 0px",
    width: "100%",
    borderRadius: 0
  },
  uploadBtn: {
    // border: "solid 1px black",
    background: "black",
    color: "white",
    padding: "4px 24px",
    fontWeight: 300,
    margin: "4px 0px",
    width: "100%",
    borderRadius: 0,
    "&:hover": {
      background: "#585858",
    }
  },
  setDescription: {
    marginBlockStart: 0,
    marginBlockEnd: "8px",
    fontSize: 12
  },
  accountInfo: {
    marginBlockStart: "4px",
    marginBlockEnd: "4px",
    width: "100%"
  },
  mainInfo: {
    marginBlockStart: "4px",
    marginBlockEnd: "16px",
  },
  caseBtn: {
    border: "solid 1px white",
    color: "white",
    padding: "4px 24px",
    fontWeight: 300,
    fontSize: 12,
    marginRight: 8,
    marginTop: 8
  },
  roundBtn: {
    background: "#00000000",
    border: "solid 1px rgba(89,54,135,1)",
    color: "rgba(89,54,135,1)",
    padding: "4px 24px",
    fontWeight: 300,
    minWidth: "auto",
    "&:hover": {
      background: "rgb(77, 74, 142)",
      color: "white"
    }
  },
  caseBtn2: {
    border: "solid 1px #444444",
    color: "#444444",
    padding: "4px 24px",
    fontWeight: 300,
    fontSize: 12,
    marginRight: 8,
    marginTop: 8
  },
  logMsg: {
    display: "flex",
    alignItems: "center",
    background: `linear-gradient(90deg, ${frontend_config?.[hostname]?.main_color_1} 0%, ${frontend_config?.[hostname]?.main_color_2} 100%)`,
    [theme.breakpoints.down('xs')]: {
      background: frontend_config?.[hostname]?.main_color_2,
      padding: 8,
      justifyContent: "center"
    },
  },
  logMsg2: {
    display: "flex",
    alignItems: "center",
    background: "#cfcfcf",
    [theme.breakpoints.down('xs')]: {
      background: "#cfcfcf",
      padding: 8,
      justifyContent: "center"
    },
  },
  logDate: {
    textAlign: "center",
    // margin: "16px auto 8px auto",
    marginBottom: 8,
    [theme.breakpoints.down('xs')]: {
      margin: "6px auto",
    },
  },
  logDateContainer: {
    backgroundColor: frontend_config?.[hostname]?.main_color_1,
    borderRight: "solid 6px #353535",
    color: "white",
    padding: "12px 0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down('xs')]: {
      borderRight: "none",
      backgroundColor: frontend_config?.[hostname]?.main_color_2
    },
  },
  logDateContainer2: {
    backgroundColor: "#cfcfcf",
    borderRight: "solid 6px #353535",
    color: "#444444",
    padding: "12px 0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down('xs')]: {
      borderRight: "none",
    },
  },
  logBtns: {
    padding: 16,
    background: frontend_config?.[hostname]?.main_color_2,
    [theme.breakpoints.down('xs')]: {
      background: frontend_config?.[hostname]?.main_color_2,
      padding: 8,
    },
  },
  logBtns2: {
    padding: 16,
    background: "#cfcfcf",
    [theme.breakpoints.down('xs')]: {
      background: "#cfcfcf",
      padding: 8,
    },
  },
  mainInfoContainer: {
    textAlign: "left",
    boxShadow: "-2px 6px 24px -9px rgba(166,130,166,1)",
    // maxHeight: 500,
    [theme.breakpoints.down('xs')]: {
      maxHeight: "unset"
    },
  },
  markReadClearNotification: {
    color: "#e54b4b",
    border: "1px solid #e54b4b",
  },
  ClearNotificationBadge: {
    marginLeft: "10px",
    marginBottom: "10px",
    "& > span": {
    background: '#e54b4b !important',
    color: 'white',
    }
  },
  clinicalSimulationBadge: {
    color: "#e54b4b",
    // border: "1px solid #e54b4b",
    "& > span": {
    background: '#e54b4b !important',
    color: 'white',
    }
  }
})
