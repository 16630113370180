import { Button, CircularProgress, Fab, TextField,
  Select, MenuItem, InputAdornment, FormHelperText, InputLabel, FormLabel, FormControl } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import axios from '../../axios-default';
import CountryTable from '../../components/Admin/CountryPage/CollapsibleTable';
//Components
import SideNav from '../../components/Admin/SideNav';
import Modal from '../../components/Modal/modal';
import { easySnackbar } from '../../store/Noti/noti.actions';
import { updateRes } from '../../store/res/res.action';
import { getWebAbsPath } from '../../util/path';




const styles = theme => ({
  "@global": {
    body: {
      background: "#fdfdfd",
    },
  },
  root: {
    flexGrow: 1,
  },
  rootContainer: {
    padding: 16,
    margin: "8px 0px 0px 238px",
    [theme.breakpoints.between("xs", "sm")]: {
      margin: "12px 0px 0px 0px",
      width: "100%",
    },
    width: "calc(100% - 238px)",
  },
  scanBox: {
    padding: 16,
    margin: "8px 0px"
  },
  textField: {
    // marginLeft: "8px",
    // marginRight: "8px",
    '& label.Mui-focused': {
      color: 'black',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'black',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey',
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
  },
  dateField: {
    width: "100%"
  },
  formControl: {
    width: "100%"
  },
  dense: {
    marginTop: 16,
    marginLeft: 0
  },
  submitBtn: {
    backgroundColor: "#061a2e !important",
    color: "white",
    padding: "8px 24px",
    margin: "12px 0px"
  },
  linkBtn: {
    background: "rgba(89,54,135,1)",
    color: "white",
    padding: "4px 24px",
    fontWeight: 300,
    margin: "16px 0px",
    width: "100%",
    [theme.breakpoints.up('sm')]: {
      padding: "4px 72px",
      width: "unset",
    },
    "&:hover": {
      background: "rgb(77, 74, 142)",
      color: "white"
    }
  },
});

class SubDomains extends React.Component {

  columns = [
    {
      name: "isoCode3",
      label: "ISO3",
      sortable: true
    },
    {
      name: "name",
      label: "Country",
      sortable: true
    },
    {
      name: "subdomainname",
      label: 'Sub Domain Name',
      sortable: true
    },
    {
      name: "enabled",
      label: "Enabled",
      sortable: true
    },
    {
      name: "distr-group",
      label: "Distributor Group",
      sortable: true
    },
    {
      name: "obj",
      label: "Action",
      numeric: true,
      render: value => (
        <Grid container justify='flex-end'>
          <Grid item>
            <Fab size="small" aria-label="add"
              style={{
                backgroundColor: "#75a0de",
                color: "white",
                margin: "0px 4px"
              }}
              onClick={() => {
                this.setState({
                  updatedCountry: Object.assign({}, value),
                  countryModalOpen: true
                })
              }}>
              <EditIcon />
            </Fab>
          </Grid>
          <Grid item>
            <Fab size="small" aria-label="add"
              style={{
                backgroundColor: "#da5851",
                color: "white",
                margin: "0px 4px"
              }}
              onClick={async () => {
                if (window.confirm('Are you going to delete this country?')) {
                  try {
                    await axios.delete(`res/country/${value.country_id}`)
                    this.setState({
                      loading: true
                    }, async () => {
                      await this.props.updateRes('country')
                      this.componentDidMount()
                    })
                  } catch (err) {
                    console.error(err)
                    if (err.response && err.response.statusText === 'Unauthorized') {
                      localStorage.removeItem("auth-token");
                      localStorage.removeItem("expirationDate");
                      localStorage.removeItem("role");
                      this.props.history.push("/login")
                    }
                    if (err.response && err.response.data) {
                      window.scroll({top: 0, left: 0, behavior: 'smooth' })
                      this.props.showSnackbar(err.response.data.message, 'error')
                    }
                  }
                }
              }}>
              <DeleteOutlineIcon />
            </Fab>
          </Grid>
        </Grid>
      )
    },
  ]

  constructor(props) {
    super(props);
    this.state = {
      isLoggedin: true,
      subDomainModalOpen: false,
      updatedCountry: {},
      subDomainModalError: "",
      stateModalError: "",
      countries: [],
      iso3_code: '',
      loading: true,
      enableDisableDomain: true,
      distributors: [],
      selectedDistributor: ''
    }
  }

  handleCountryUpdate = select => ({ target: { value } }) => {
    const updateObj = {
      ...this.state.updatedCountry,
      [select]: value
    }
    if (select === 'country_id') {
      Object.values(this.props.res.countries).some(country => {
        if(value === country.country_id){
          updateObj.iso_code_3 = country.iso_code_3
          return true
        }
        return false
      })
    }
    this.setState({
      updatedCountry: updateObj
    }, () => console.log(this.state.updatedCountry))
  }


  handleSubdomainSave = async () => {
    try {
      console.log('this.state.updatedCountry', this.state.updatedCountry);
    } catch (err) {
      console.error(err)
      if (err.response && err.response.statusText === 'Unauthorized') {
        localStorage.removeItem("auth-token");
        localStorage.removeItem("expirationDate");
        localStorage.removeItem("role");
        this.props.history.push("/login")
      }
    }
  }

  // handleStateSave = async () => {
  //   try {
  //     await axios.post('/res/state_province', this.state.updatedState)
  //     this.setState({
  //       loading: true,
  //       subDomainModalOpen: false,
  //       updatedState: {}
  //     }, async () => {
  //       await this.props.updateRes('state_province')
  //       this.componentDidMount()
  //     })
  //   } catch (err) {
  //     console.error(err)
  //     if (err.response && err.response.statusText === 'Unauthorized') {
  //       localStorage.removeItem("auth-token");
  //       localStorage.removeItem("expirationDate");
  //       localStorage.removeItem("role");
  //       this.props.history.push("/login")
  //     }
  //     if (err.response && err.response.data) {
  //       if (err.response.data.invalid) {
  //         this.setState({
  //           stateModalError: `Following field(s) invalid: ${err.response.data.invalid.join(',')}`
  //         })
  //       } else {
  //         this.setState({
  //           stateModalError: err.response.data.message
  //         })
  //       }
  //     }
  //   }
  // }

  async componentDidMount() {
    try {
      const countries = Object.values(this.props.res.countries).reduce((a, c) => Object.assign(a, {
        [c.country_id]: {
          name: c.country_name,
          isoCode2: c.iso_code_2,
          isoCode3: c.iso_code_3,
          isoCodeNo: c.iso_code_numeric,
          obj: c
        }
      }), {})
      Object.values(this.props.res.stateProvinces).forEach(s => {
        if (countries[s.country_id]) {
          countries[s.country_id].state_province = (countries[s.country_id].state_province||[]).concat({
            name: s.name,
            abbrv: s.abbreviation,
            obj: s
          })
        } else {
          console.log('Country not found for ', s)
        }
      })
      const role = JSON.parse(localStorage.getItem('role'))
      let distributors = {}
      if (['ADMIN', 'SADMIN'].includes(role)) {
        distributors = (await axios.get(`user?role=DISTR`)).data.user;
      }
      this.setState({ distributors })

      this.setState({
        countries: Object.values(countries).sort((a, b) => a.name.localeCompare(b.name)).concat(),
        loading: false
      })
    } catch (err) {
      console.error(err)
      if (err.response && err.response.statusText === 'Unauthorized') {
        localStorage.removeItem("auth-token");
        localStorage.removeItem("expirationDate");
        localStorage.removeItem("role");
        this.props.history.push("/login")
      }
    }
  }

  render() {
    const { classes } = this.props
    // const { distributors } = this.state
    // console.log('Rendering.... ', this.props.res.stateProvinces, "Distributor", this.state.distributors)
    return (
      <div>
        <SideNav />
        <Grid container className={classes.rootContainer}>

        </Grid>
        <Grid container className={classes.rootContainer} style={{ marginTop: 0 }}>
          {this.state.isLoggedin && !this.state.loading ?
            <div style={{ display: "contents" }}>
              <Grid container>

                <Grid item xs={12}
                  style={{
                    padding: 8
                  }}>
                  <h1
                    style={{
                      marginBlockEnd: 0
                    }}
                  >
                    Sub Domain Management
                  </h1>

                  <hr />
                </Grid>

                <Grid item xs={12}
                  style={{
                    padding: 8,
                    textAlign: "center"
                  }}>
                  <img style={{ width: "100%", maxWidth: 800 }} src={getWebAbsPath("/img/account1.png")} />
                </Grid>

                <Grid item xs={12} style={{ padding: 8 }}>
                  <Button className={classes.linkBtn} onClick={() => this.setState({ subDomainModalOpen: true, updatedCountry: { active_flag: true } })}>
                    Add Sub-Domain
                  </Button>

                  <CountryTable
                    title='Countries'
                    onInsert={v => {
                      // console.log(v, this.props.res.countries)
                      const country = this.props.res.countries[v.obj.country_id]
                      this.setState({
                        subDomainModalOpen: true,
                        updatedCountry: {
                          country_name: country.country_name,
                          country_id: country.country_id,
                          iso_code_3: country.iso_code_3,
                          active_flag: true
                        }
                      })
                    }}
                    filterColumns={['name']}
                    columns={this.columns}
                    data={this.state.countries}
                  />

                  <Modal
                    className="modal"
                    modal='subDomain'
                    title='Modify Sub-Domain'
                    maxWidth="md"
                    open={this.state.subDomainModalOpen}
                    handleClose={() => this.setState({ subDomainModalOpen: false, updatedCountry: {} })}
                    handleDataSave={() => this.handleSubdomainSave()}
                    modalError={this.state.subDomainModalError}
                    submitButton={true}
                  >
                    <Grid item xs={12} style={{ padding: 4 }}>
                      {/* <FormLabel style={{fontSize:'0.8rem'}}>Country Name</FormLabel> */}
                      <FormControl fullWidth>
                        <FormLabel style={{fontSize:'0.8rem'}}>Country Name</FormLabel>
                        <Select
                          id="country_name"
                          label="Country Name"
                          style={{ width: "100%" }}
                          value={this.state.updatedCountry.country_id}
                          onChange={this.handleCountryUpdate('country_id')}
                        >
                          <MenuItem value="" disabled>Select Country</MenuItem>
                          {
                            Object.values(this.props.res.countries).map(value =>(
                              <MenuItem value={value.country_id} key={value.country_id}>{value.country_name}</MenuItem>
                          ))
                          }
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} style={{ padding: 4 }}>
                    <TextField
                      InputProps={{
                        readOnly: true,
                      }}
                      placeholder="ISO Code 3"
                      style={{ width: "100%" }}
                      id="iso_code_3"
                      value={this.state.updatedCountry.iso_code_3}
                      onChange={this.handleCountryUpdate('iso_code_3')}
                    />
                    </Grid>
                    <Grid item xs={12} style={{ padding: 4 }}>
                      <TextField
                        id="suffix"
                        // label="Sub Domain Name"
                        placeholder="Sub Domain Name"
                        style={{ width: "100%" }}
                        margin="normal"
                        value={this.state.updatedCountry.suffix}
                        onChange={this.handleCountryUpdate('suffix')}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">
                          {this.state.updatedCountry.iso_code_3}
                          </InputAdornment>,
                        }}
                      />
                      <FormHelperText>Preview: {this.state.updatedCountry.iso_code_3?.toLowerCase() ?? ''}{this.state.updatedCountry.suffix}.triclear.com</FormHelperText>
                    </Grid>
                    <Grid item xs={12} style={{ padding: 4 }}>
                      {/* {Array.isArray(distributors) && distributors.length?  */}
                      <Select
                        id="distr_group_id"
                        placeholder="Select Distributor Group"
                        value={this.state.updatedCountry.distr_group_id}
                        onChange={this.handleCountryUpdate('distr_group_id')}
                        style={{width: "100%"}}
                      >
                        {
                          Object.values(this.props.res.distr_groups).map(v => (
                            <MenuItem value={v.distr_group_id} key={v.distr_group_id}>{v.distr_group_name}</MenuItem>
                          ))
                        }
                      </Select>
                      {/* // : null} */}
                    </Grid>
                    <Grid item xs={12} style={{ padding: 4 }}>
                      <Select
                        id="active_flag"
                        placeholder="Enable this Sub-domain"
                        // label="Enable this Sub-domain"
                        style={{ width: "100%" }}
                        value={this.state.updatedCountry.active_flag}
                        onChange={this.handleCountryUpdate('active_flag')}
                      >
                        <MenuItem value={true}>Enable</MenuItem>
                        <MenuItem value={false}>Disable</MenuItem>
                      </Select>
                    </Grid>
                  </Modal>

                </Grid>
              </Grid>

            </div> :
            <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress style={{ color: "rgb(6,26,46)" }} />
            </Grid>}
        </Grid>
      </div>
    )
  }
}

SubDomains.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  res: state.res
});

const mapDispatchToProps = dispatch => ({
  updateRes: (resName) => dispatch(updateRes(resName)),
  showSnackbar: (message, variant) => dispatch(easySnackbar(message, variant))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(withWidth()(SubDomains))))