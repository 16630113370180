import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import { BrowserRouter as Router, Link, withRouter } from 'react-router-dom'

import { Button, SwipeableDrawer, List, ListItem, ListItemText, Hidden, Grid, IconButton, Typography, Fade, MenuItem, Menu, Divider } from '@material-ui/core'

import AccountCircle from '@material-ui/icons/AccountCircle'
import MenuIcon from '@material-ui/icons/Menu'
import { connect } from "react-redux";
import * as authAction from '../../store/Auth/Auth.action';

const styles = theme => ({
  sideNav: {
    width: "238px",
    height: "100vh",
    position: "fixed",
    color: "white",
    textAlign: "left",
    top: "0px",
    zIndex: 1,
    overflowY: "scroll"
  },
  buttonActive: {
    margin: "8px",
    width: "calc( 100% - 16px)",
    background: "#404156",
    color: "white",
    fontSize: "16px",
    textTransform: "unset",
    "&:hover": {
      background: "#404156",
    }
  },
  logoutBtn: {
    margin: "8px",
    width: "calc( 100% - 16px)",
    background: "#df4d4d",
    color: "white",
    fontSize: "16px",
    textTransform: "unset",
    "&:hover": {
      background: "#e47474",
    }
  },
  backToPlatform: {
    margin: "8px",
    width: "calc( 100% - 16px)",
    background: "#547da8",
    color: "white",
    fontSize: "16px",
    textTransform: "unset",
    "&:hover": {
      background: "#8ac2fc",
    }
  },
  button: {
    margin: "8px",
    width: "calc( 100% - 16px)",
    background: "#ffffff00",
    color: "white",
    boxShadow: "unset",
    fontSize: "16px",
    textTransform: "unset",
    "&:hover": {
      background: "#404156",
      color: "white"
    }
  },
  appBar: {
    [theme.breakpoints.between("md", "xl")]: {
      width: "calc(100% - 238px)",
      // backgroundColor: Colors.mainButton,
      marginLeft: "238px",
      height: 48
    },
    [theme.breakpoints.between("xs", "sm")]: {
      // height: 128,
      // background: "url(../img/mobileBg.png)",
      backgroundSize: "cover",
      width: "100%",
      filter: "drop-shadow(5px 5px 5px #d1bebe)"
    },
    position: "fixed",
    top: 0,
    zIndex: 1199,
    color: "black",
    boxShadow: "unset",
    color: "white",
  },
  title: {
    margin: "8px 0px",
    textAlign: 'center'
  },
  navInner: {
    // [theme.breakpoints.between("xs", "sm")]:{
    //   paddingTop: "24px"
    // },
    padding: 4,
  },
  sideLogo: {
    fontSize: 28,
    color: "white",
    textAlign: "center",
    marginBlockStart: "10px",
    marginBlockEnd: 0
  }
})

class SideNav extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pages: [
        {
          name: "Dashboard",
          icon: "",
          linkTo: "/admin/dashboard"
        },
        {
          name: "Accounts",
          icon: "",
          linkTo: "/admin/accounts"
        },
        {
          name: "Clinics",
          icon: "",
          linkTo: "/admin/clinics"
        },
        // {
        //   name: "Groups",
        //   icon: "",
        //   linkTo: "/admin/groups"
        // },
        {
          name: "DistrGroups",
          icon: "",
          linkTo: "/admin/distrgroups",
          roles: ['SADMIN', 'ADMIN']
        },
        {
          name: "AdminGroups",
          icon: "",
          linkTo: "/admin/admingroups",
          roles: ['SADMIN']
        },
        {
          name: "Cases",
          icon: "",
          linkTo: "/admin/cases"
        },
        {
          name: "Cases Change Log",
          icon: "",
          linkTo: "/admin/caseslog",
          roles: ['SADMIN', 'ADMIN']
        },
        {
          name: "Cases Cancel Log",
          icon: "",
          linkTo: "/admin/casesRollbackLog",
          roles: ['SADMIN', 'ADMIN']
        },
        {
          name: "Cases Transfer Log",
          icon: "",
          linkTo: "/admin/casesTransferLog",
          roles: ['SADMIN', 'ADMIN']
        },
        {
          name: "Countries",
          icon: "",
          linkTo: "/admin/countries",
          roles: ['SADMIN', 'ADMIN']
        },
        // {
        //   name: "Support and Resource",
        //   icon: "",
        //   linkTo: "/admin/supportresource"
        // },
        // {
        //   name: "Distributor Message",
        //   icon: "",
        //   linkTo: "/admin/distrmsg"
        // },
        {
          name: "Reports",
          icon: "",
          linkTo: "/admin/reports"
        },
        // {
        //   name: "SubDomains",
        //   icon: "",
        //   linkTo: "/admin/subdomains",
        //   roles: ['SADMIN', 'ADMIN']
        // },
        // {
        //   name: "Product List",
        //   icon: "",
        //   linkTo: "/admin/productmatrix",
        //   roles: ['SADMIN', 'ADMIN','DISTR']
        // },
        {
          name: "Email Management",
          icon: "",
          linkTo: "/admin/email-management",
          roles: ['SADMIN', 'ADMIN', 'DISTR']
        },
        {
          name: "Settings",
          icon: "",
          linkTo: "/admin/settings"
        },
      ],
      left: false,
      anchorEl: null,
      settings: {},
      role: JSON.parse(localStorage.getItem('role'))
    }
  }

  componentDidMount() {
    if (!['ADMIN', 'DISTR', 'SADMIN'].includes(this.state.role)) {
      this.props.history.replace('/')
    }
  }

  componentWillUnmount() {

  }

  handleLogout = () => {
    this.props.logOut();
    // this.props.history.replace('/login')
  }

  toggleDrawer(side, open) {
    return (
      () => {
        this.setState({
          [side]: open
        })
      }
    )
    // console.log(open)
  }

  returnPages() {
    return this.state.pages.filter(({ roles }) => !roles || roles.includes(this.state.role)).map(v => {
      return (
        <Link to={v.linkTo} key={v.name} style={{ textDecoration: "none" }}>
          <Button variant="contained" className={this.props.location.pathname.includes(v.linkTo) ? this.props.classes.buttonActive : this.props.classes.button}>
            {v.name}
          </Button>
        </Link>
      )
    })
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    return (
      <div>
        <div className={this.props.classes.appBar}
          style={{
            backgroundColor: "rgba(6,26,46,1)",
          }}>
          <Grid container className={this.props.classes.navInner}>
            <Grid item xs={3}>
              <Hidden mdUp>
                <IconButton
                  aria-label="Account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={this.toggleDrawer('left', true)}
                  color="inherit"
                  style={{
                    marginTop: "6px"
                  }}
                >
                  <MenuIcon />
                </IconButton>
              </Hidden>
            </Grid>
            <Grid item xs={6} style={{ paddingTop: 16 }}>
              <Hidden mdUp>
                <h3 style={{ textAlign: "center" }}>BeMe</h3>
              </Hidden>
            </Grid>
            <Grid item xs={3} style={{ textAlign: "right" }}>
              {(
                <div style={{ paddingRight: 24 }}>
                  {/* <IconButton
                  aria-owns={anchorEl ? 'simple-menu' : undefined}
                  aria-haspopup="true"
                  onClick={this.handleClick}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton> */}
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}
                    PaperProps={{
                      style: {
                        maxHeight: 48 * 4.5,
                        width: 168,
                        left: "unset !important",
                        right: "24px"
                      },
                    }}
                  >
                    <MenuItem onClick={this.handleClose}>Profile</MenuItem>
                    <MenuItem onClick={() => {
                      this.props.history.replace('/settings')
                      this.handleClose()
                    }}>
                      Settings
                  </MenuItem>
                    <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
                  </Menu>
                </div>
              )}
            </Grid>
          </Grid>
        </div>
        <Hidden smDown>
          <nav className={this.props.classes.sideNav} style={{ backgroundColor: "rgba(6,26,46,1)" }}>
            <div className="navDiv">
              <h1 className={this.props.classes.sideLogo}>BeMe</h1>
              <hr style={{ borderTop: "white solid 2px", width: "50%", margin: "32px auto" }} />
              {this.returnPages()}
              <Link to={"/"} style={{ textDecoration: "none" }}>
                <Button variant="contained" className={this.props.classes.backToPlatform}>
                  Back To Platform
                </Button>
              </Link>
              <Button
                variant="contained"
                className={this.props.classes.logoutBtn}
                onClick={this.handleLogout}
              >
                Logout
              </Button>
            </div>
          </nav>
        </Hidden>
        <SwipeableDrawer
          open={this.state.left}
          onClose={this.toggleDrawer('left', false)}
          onOpen={this.toggleDrawer('left', true)}
          PaperProps={{
            style: {
              width: 250,
              background: "#061a2e"
            }
          }}
        >
          <h1 style={{ fontSize: 28, color: "white", fontFamily: "'Poppins', sans-serif !important", textAlign: "center" }}>BeMe</h1>
          <Divider style={{ backgroundColor: "rgba(255, 255, 255, 0.14)" }} />
          <List style={{ width: "250px" }}>
            {this.state.pages.map(v => (
              <Link to={v.linkTo} style={{ color: "white", textDecoration: "none" }} key={v.name}>
                <ListItem button style={{ textAlign: "center" }} key={v.name} onClick={() => this.setState({ left: false })}>
                  <div
                    style={{
                      flex: "1 1 auto",
                      display: "block",
                      textAlign: "center",
                      margin: "4px 0px"
                    }}
                  >
                    <span
                      style={{
                        fontSize: "1rem",
                        lineHeight: 1.5,
                        textAlign: "center"
                      }}
                    >
                      {v.name}
                    </span>
                  </div>
                </ListItem>
              </Link>
            ))}
            <Link to={"/"} style={{ textDecoration: "none" }}>
              <Button variant="contained" className={this.props.classes.backToPlatform}>
                Back To Platform
              </Button>
            </Link>
            <Button
              variant="contained"
              className={this.props.classes.logoutBtn}
              onClick={this.handleLogout}
            >
              Logout
            </Button>
          </List>
        </SwipeableDrawer>
      </div>

    )
  }
}

const mapDispatchToProps = dispatch => ({
  logOut: () => dispatch(authAction.logOut())
});


export default connect(null, mapDispatchToProps)(withStyles(styles)(withRouter(SideNav)))
