module.exports = {
  "localhost": {
    logo_path: "/img/BeMe_Logo_Final.png",
    white_logo_path: "/img/BeMe_Logo_Final.png",
    main_background_color: "linear-gradient(42deg, #363793 0%, #ba4397 100%)",
    main_color_1: "#363793",
    main_color_2: "#ba4397",
    main_color_3: "#1a1a1a",
    main_color_4: "#4d4d4d",
    contact_phone: "02-8215866 / 082-2128994",
    contact_email: "dr@bemealigners.com",
    social_media:{
      facebook: "",
      instagram: "",
      wechat: "",
      weibo: ""
    }
  },
  "cloud.soarstack.com":{
    logo_path: "/img/logo_color.png",
    main_background_color: "linear-gradient(42deg, rgba(46,126,162,1) 0%, rgba(89,54,135,1) 100%)",
    main_color_1: "#485291",
    main_color_2: "#543f8a",
    main_color_3: "#1a1a1a",
    main_color_4: "#4d4d4d",
    contact_phone: "+852 22505101",
    contact_email: "cs@trioclear.com.hk",
    social_media:{
      facebook: "https://www.facebook.com/TrioClear.Official",
      instagram: "https://www.instagram.com/trioclear.official",
      wechat: "",
      weibo: ""
    }
  },
  "ecom.trioclear.com": {
    logo_path: "/img/logo_color.png",
    white_logo_path: "/img/logo_white.png",
    main_background_color: "linear-gradient(42deg, rgba(46,126,162,1) 0%, rgba(89,54,135,1) 100%)",
    main_color_1: "#485291",
    main_color_2: "#543f8a",
    main_color_3: "#1a1a1a",
    main_color_4: "#4d4d4d",
    contact_phone: "+852 22505101",
    contact_email: "cs@trioclear.com.hk",
    social_media:{
      facebook: "https://www.facebook.com/TrioClear.Official",
      instagram: "https://www.instagram.com/trioclear.official",
      wechat: "",
      weibo: ""
    }
  },
  "pro.trioclear.com": {
    logo_path: "/img/logo_color.png",
    white_logo_path: "/img/logo_white.png",
    main_background_color: "linear-gradient(42deg, rgba(46,126,162,1) 0%, rgba(89,54,135,1) 100%)",
    main_color_1: "#485291",
    main_color_2: "#543f8a",
    main_color_3: "#1a1a1a",
    main_color_4: "#4d4d4d",
    contact_phone: "+852 22505101",
    contact_email: "cs@trioclear.com.hk",
    social_media:{
      facebook: "https://www.facebook.com/TrioClear.Official",
      instagram: "https://www.instagram.com/trioclear.official",
      wechat: "",
      weibo: ""
    }
  },
  "pro.trioclear.com.cn": {
    logo_path: "/img/logo_color.png",
    white_logo_path: "/img/logo_white.png",
    main_background_color: "linear-gradient(42deg, rgba(46,126,162,1) 0%, rgba(89,54,135,1) 100%)",
    main_color_1: "#485291",
    main_color_2: "#543f8a",
    main_color_3: "#1a1a1a",
    main_color_4: "#4d4d4d",
    contact_phone: "+852 22505101",
    contact_email: "cs@trioclear.com.hk",
    social_media:{
      facebook: "https://www.facebook.com/TrioClear.Official",
      instagram: "https://www.instagram.com/trioclear.official",
      wechat: "",
      weibo: ""
    }
  },
  "trioclear-cn1.soarstack.com": {
    logo_path: "/img/logo_color.png",
    white_logo_path: "/img/logo_white.png",
    main_background_color: "linear-gradient(42deg, rgba(46,126,162,1) 0%, rgba(89,54,135,1) 100%)",
    main_color_1: "#485291",
    main_color_2: "#543f8a",
    main_color_3: "#1a1a1a",
    main_color_4: "#4d4d4d",
    contact_phone: "+852 22505101",
    contact_email: "cs@trioclear.com.hk",
    social_media:{
      facebook: "https://www.facebook.com/TrioClear.Official",
      instagram: "https://www.instagram.com/trioclear.official",
      wechat: "",
      weibo: ""
    }
  },
  "anz.trioclear.com": {
    logo_path: "/img/logo_color.png",
    main_background_color: "linear-gradient(42deg, rgba(46,126,162,1) 0%, rgba(89,54,135,1) 100%)",
    contact_phone: "+852 22505101",
    contact_email: "cs@trioclear.com.hk",
    social_media:{
      facebook: "https://www.facebook.com/TrioClear.Official",
      instagram: "https://www.instagram.com/trioclear.official",
      wechat: "",
      weibo: ""
    }
  },
  "eu.trioclear.com": {
    logo_path: "/img/logo_color.png",
    main_background_color: "linear-gradient(42deg, rgba(46,126,162,1) 0%, rgba(89,54,135,1) 100%)",
    contact_phone: "+852 22505101",
    contact_email: "cs@trioclear.com.hk",
    social_media:{
      facebook: "https://www.facebook.com/TrioClear.Official",
      instagram: "https://www.instagram.com/trioclear.official",
      wechat: "",
      weibo: ""
    }
  },
  "us.trioclear.com": {
    logo_path: "/img/logo_color.png",
    main_background_color: "linear-gradient(42deg, rgba(46,126,162,1) 0%, rgba(89,54,135,1) 100%)",
    contact_phone: "+852 22505101",
    contact_email: "cs@trioclear.com.hk",
    social_media:{
      facebook: "https://www.facebook.com/TrioClear.Official",
      instagram: "https://www.instagram.com/trioclear.official",
      wechat: "",
      weibo: ""
    }
  },
  "pro.qjsmile.com": {
    logo_path: "/img/qj_logo_horizontal.png",
    white_logo_path: "/img/qj_logo_horizontal_white.png",
    main_background_color: "linear-gradient(42deg, #38A758 0%, #1A6B3E 100%)",
    main_color_1: "#38a758",
    main_color_2: "#1a6b3e",
    main_color_3: "#1a1a1a",
    main_color_4: "#4d4d4d",
    contact_phone: "400-680-7979",
    contact_email: "18998907768",
    social_media:{
      facebook: "",
      instagram: "",
      wechat: "",
      weibo: ""
    }
  },
  "trioclear-cn2.soarstack.com": {
    logo_path: "/img/qj_logo_horizontal.png",
    white_logo_path: "/img/qj_logo_horizontal_white.png",
    main_background_color: "linear-gradient(42deg, #38A758 0%, #1A6B3E 100%)",
    main_color_1: "#38a758",
    main_color_2: "#1a6b3e",
    main_color_3: "#1a1a1a",
    main_color_4: "#4d4d4d",
    contact_phone: "400-680-7979",
    contact_email: "18998907768",
    social_media:{
      facebook: "",
      instagram: "",
      wechat: "",
      weibo: ""
    }
  },
  "52.221.207.199": {
    logo_path: "/img/BeMe_Logo_Final.png",
    white_logo_path: "/img/BeMe_Logo_Final.png",
    main_background_color: "linear-gradient(42deg, #363793 0%, #ba4397 100%)",
    main_color_1: "#363793",
    main_color_2: "#ba4397",
    main_color_3: "#1a1a1a",
    main_color_4: "#4d4d4d",
    contact_phone: "02-8215866 / 082-2128994",
    contact_email: "dr@bemealigners.com",
    social_media:{
      facebook: "",
      instagram: "",
      wechat: "",
      weibo: ""
    }
  },
  "ec2-52-221-207-199.ap-southeast-1.compute.amazonaws.com": {
    logo_path: "/img/BeMe_Logo_Final.png",
    white_logo_path: "/img/BeMe_Logo_Final.png",
    main_background_color: "linear-gradient(42deg, #363793 0%, #ba4397 100%)",
    main_color_1: "#363793",
    main_color_2: "#ba4397",
    main_color_3: "#1a1a1a",
    main_color_4: "#4d4d4d",
    contact_phone: "02-8215866 / 082-2128994",
    contact_email: "dr@bemealigners.com",
    social_media:{
      facebook: "",
      instagram: "",
      wechat: "",
      weibo: ""
    }
  },
  "3.0.96.122": {
    logo_path: "/img/BeMe_Logo_Final.png",
    white_logo_path: "/img/BeMe_Logo_Final.png",
    main_background_color: "linear-gradient(42deg, #363793 0%, #ba4397 100%)",
    main_color_1: "#363793",
    main_color_2: "#ba4397",
    main_color_3: "#1a1a1a",
    main_color_4: "#4d4d4d",
    contact_phone: "028215866",
    contact_email: "dr@bemealigners.com",
    social_media:{
      facebook: "",
      instagram: "",
      wechat: "",
      weibo: ""
    }
  },
  "pro.bemealigners.com": {
    logo_path: "/img/BeMe_Logo_Final.png",
    white_logo_path: "/img/BeMe_Logo_Final.png",
    main_background_color: "linear-gradient(42deg, #363793 0%, #ba4397 100%)",
    main_color_1: "#363793",
    main_color_2: "#ba4397",
    main_color_3: "#1a1a1a",
    main_color_4: "#4d4d4d",
    contact_phone: "02-8215866 / 082-2128994",
    contact_email: "dr@bemealigners.com",
    social_media:{
      facebook: "",
      instagram: "",
      wechat: "",
      weibo: ""
    }
  },
  "beme-lb-49111724.ap-southeast-1.elb.amazonaws.com": {
    logo_path: "/img/BeMe_Logo_Final.png",
    white_logo_path: "/img/BeMe_Logo_Final.png",
    main_background_color: "linear-gradient(42deg, #363793 0%, #ba4397 100%)",
    main_color_1: "#363793",
    main_color_2: "#ba4397",
    main_color_3: "#1a1a1a",
    main_color_4: "#4d4d4d",
    contact_phone: "02-8215866 / 082-2128994",
    contact_email: "dr@bemealigners.com",
    social_media:{
      facebook: "",
      instagram: "",
      wechat: "",
      weibo: ""
    }
  }
}