import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import './modal.css'

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { useLang } from '../../util/lang';

export default function ResponsiveDialog(props) {
  const theme = useTheme();
  const get = useLang('')
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('lg');

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  return (
    <div>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={props.modalSize ? props.modalSize : maxWidth}
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText> */}
          {props.modalError && <Alert severity="error" style={{ marginBottom: "30px" }}>{props.modalError}</Alert>}
          {props.children}
          {/* </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={props.handleClose}>
            {get(':const.close')}
          </Button>
          {props.submitButton &&
            <Button onClick={() => props.handleDataSave()} className="customButton" autoFocus disabled={props.photoUploadLoadingStatus}>
              {props.photoUploadLoadingStatus ? `${get(':const.saving')}...` : (props.submitButtonLabel ? props.submitButtonLabel : get(':const.save'))}
            </Button>
          }
        </DialogActions>
      </Dialog>
    </div>
  );
}
