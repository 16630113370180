import { AppBar, Button, CircularProgress, Tab, Tabs, Typography } from '@material-ui/core/';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ActivityLog from '../../components/Admin/AccountsInfoPage/ActivityLog';
import AddressList from '../../components/Admin/AccountsInfoPage/AddressList';
import Information from '../../components/Admin/AccountsInfoPage/Information';
import Orders from '../../components/Admin/AccountsInfoPage/Orders';
import Roles from '../../components/Admin/AccountsInfoPage/Roles';
//Components
import SideNav from '../../components/Admin/SideNav';
import { initUpdatingAccount } from '../../store/UpdatingAccount/UpdatingAccount.action';
import { getWebAbsPath } from '../../util/path';



const styles = theme => ({
  "@global": {
    body: {
      background: "#fdfdfd",
    },
  },
  root: {
    flexGrow: 1,
  },
  rootContainer: {
    padding: 16,
    margin: "8px 0px 0px 238px",
    [theme.breakpoints.between("xs", "sm")]: {
      margin: "12px 0px 0px 0px",
      width: "100%",
    },
    width: "calc(100% - 238px)",
  },
  scanBox: {
    padding: 16,
    margin: "8px 0px"
  },
  textField: {
    // marginLeft: "8px",
    // marginRight: "8px",
    '& label.Mui-focused': {
      color: 'black',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'black',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey',
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
  },
  dateField: {
    width: "100%"
  },
  formControl: {
    width: "100%"
  },
  dense: {
    marginTop: 16,
    marginLeft: 0
  },
  submitBtn: {
    backgroundColor: "#061a2e !important",
    color: "white",
    padding: "8px 24px",
    margin: "12px 0px"
  },
  linkBtn: {
    background: "gray",
    color: "white",
    padding: "4px 20px",
    fontWeight: 300,
    margin: "16px 0px",
    width: "100%",
    [theme.breakpoints.up('sm')]: {
      padding: "4px 20px",
      width: "unset",
    },
    "&:hover": {
      background: "rgb(77, 74, 142)",
      color: "white"
    }
  }
});

class AccountsInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedin: true,
      value: this.props.match.params.tabIndex || 0,
      loading: true
    }
    this.props.history.replace(`/admin/accounts/${this.props.match.params.id}`)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id ||
        prevProps.match.params.tabIndex !== this.props.match.params.tabIndex
    ) {
      this.props.history.replace(`/admin/accounts/${this.props.match.params.id}`)
      this.componentDidMount()
    }
  }

  handleError = data => {
    console.error(data)
  }

  handleInfoChange = (isChanged) => {
    this.setState({
      changesMade: isChanged
    })
  }

  handleChange = (event, value) => {
    if (this.state.value === 0 &&
      this.state.changesMade &&
      !window.confirm('Changes you made will be discarded. Continue?')
    ) {
      return
    }
    this.setState({ value, changesMade: false });
  };

  async componentDidMount() {
    try {
      await this.props.init(this.props.match.params.id)
      this.setState({
        loading: false
      })
    } catch (err) {
      console.error(err)
      if (err.response && err.response.statusText === 'Unauthorized') {
        localStorage.removeItem("auth-token");
        localStorage.removeItem("expirationDate");
        localStorage.removeItem("role");
        this.props.history.push("/login")
      }
    }
  }

  render() {
    const { classes } = this.props
    return (
      <div>
        <SideNav />
        <Grid container className={classes.rootContainer}>

        </Grid>
        <Grid container className={classes.rootContainer} style={{ marginTop: 0 }}>
          {this.state.isLoggedin && !this.state.loading ?
            <div style={{ display: "contents" }}>
              <Grid container>

                <Grid item xs={12}
                  style={{
                    padding: 8
                  }}
                >
                  <Button className={classes.linkBtn} onClick={() => this.props.history.replace('/admin/accounts')}>Back</Button>
                  <hr />
                  <Typography variant='h4'
                    style={{
                      marginBlockEnd: 0
                    }}
                  >
                    {this.props.match.params.id === 'new'? 'New Account' : `${this.props.user.approved_flag? '' : 'Pending '}Account #${this.props.match.params.id}`}
                  </Typography>
                </Grid>

                <Grid item xs={12}
                  style={{
                    padding: 8,
                    textAlign: "center"
                  }}>
                  <img style={{ width: "100%", maxWidth: 800 }} src={getWebAbsPath("/img/account1.png")} />
                </Grid>

                <Grid item xs={12}
                  style={{
                    padding: 8,
                  }}>
                  <AppBar position="static" style={{ boxShadow: "none", marginBottom: 24, bottom: 0 }}>
                    <Tabs
                      variant="fullWidth"
                      value={this.state.value}
                      // onChange={handleChange}
                      onChange={this.handleChange}
                      aria-label="nav tabs"
                      style={{
                        backgroundColor: "#fdfdfd"
                      }}
                      TabIndicatorProps={{
                        style: {
                          backgroundColor: "#593687"
                        }
                      }}
                    >
                      <Tab label="Account Information" />
                      {this.props.user.user_id && <Tab label="Approve User / Change Role" />}
                      {this.props.user.user_id && <Tab label="Address List" />}
                      {this.props.user.user_id && <Tab label="Cases" />}
                      {this.props.user.user_id && <Tab label="Activity Log" />}
                    </Tabs>
                  </AppBar>
                  {this.state.value == 0 && <Information onChange={() => this.handleInfoChange(true)} onSave={() => this.handleInfoChange(false)}/>}
                  {this.state.value == 1 && <Roles/>}
                  {this.state.value == 2 && this.props.user.user_id && <AddressList/>}
                  {this.state.value == 3 && this.props.user.user_id && <Orders/>}
                  {this.state.value == 4 && this.props.user.user_id && <ActivityLog/>}
                </Grid>

              </Grid>

            </div> :
            <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress style={{ color: "rgb(6,26,46)" }} />
            </Grid>}
        </Grid>
      </div>
    )
  }
}

AccountsInfo.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  user: state.updatingAccount.user,
});

const mapDispatchToProps = dispatch => {
  return {
    init: (userId) => dispatch(initUpdatingAccount(userId))
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(withWidth()(AccountsInfo))))