import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILURE,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  FETCH_USER_FAILURE,
  FETCH_USER_SUCCESS,
  FORGOT_SUCCESS,
  FORGOT_FAILURE,
  LOGOUT
} from "./actionType";

import axios from '../../axios-default'
import Url from 'url'
import { uiStartLoading, uiStopLoading } from "../loading/actions";
import { getWebAbsPath } from "../../util/path";

const loginSuccess = payload => ({
  type: LOGIN_SUCCESS,
  payload
});

const logoutSuccess = payload => ({
  type: LOGOUT,
  payload
});

const loginFailure = payload => ({
  type: LOGIN_FAILURE,
  payload
});

const passwordUpdateSuccess = payload => ({
  type: UPDATE_PASSWORD_SUCCESS,
  payload
});

const passwordUpdateFailure = payload => ({
  type: UPDATE_PASSWORD_FAILURE,
  payload
});

const registerSuccess = payload => ({
  type: REGISTER_SUCCESS,
  payload
});

const registerFailure = payload => ({
  type: REGISTER_FAILURE,
  payload
});

const fetchUserSuccess = payload => ({
  type: FETCH_USER_SUCCESS,
  payload
});

const fetchUserFailure = payload => ({
  type: FETCH_USER_FAILURE,
  payload
});

const forgotSuccess = payload => ({
  type: FORGOT_SUCCESS,
  payload
});

const forgotFailure = payload => ({
  type: FORGOT_FAILURE,
  payload
});



export const logOut = () => async dispatch => {
  dispatch(uiStartLoading());
  try {
    localStorage.removeItem("auth-token");
    localStorage.removeItem("expirationDate");
    localStorage.removeItem("role");
    await axios.post('auth/logout');
  }
  catch (e) {
    console.error(e)
  } finally {
    window.location = getWebAbsPath('/login');
  }
  //dispatch(uiStopLoading());
  // console.log("logout",logout)
  //dispatch(logoutSuccess({value:true}))
};

export const login = (email, password) => async dispatch => {
  dispatch(uiStartLoading());
  try {
    let TokenData = await axios.get('auth/token')
    let { data } = await axios.post('auth/login', { user: email, pass: password, token: TokenData.data.token })
    const date = new Date().getTime() + 604800000;
    localStorage.setItem("auth-token", JSON.stringify(`${data.user.user_id}`));
    localStorage.setItem("role", JSON.stringify(`${data.user.role}`));
    localStorage.setItem("country_id", JSON.stringify(`${data.user.country_id}`))
    localStorage.setItem("distr_group_id", JSON.stringify(`${data.user.distr_group_id}`))
    localStorage.setItem("admin_group_id", JSON.stringify(`${data.user.admin_group_id}`))
    localStorage.setItem("doctor_dist_id",JSON.stringify(`${data.user.doctor_distr_group_id}`))
    localStorage.setItem("expirationDate", date);
    await dispatch(loginSuccess(data));
    await dispatch(uiStopLoading());
  } catch (err) {
    try {
      await dispatch(loginFailure((err.response && err.response.data && err.response.data.message) || err.message || err));
      await dispatch(uiStopLoading());
    } catch (err) { console.error(err) }
  }
};

export const updatePassword = (username, old_password, new_password) => async dispatch => {
  try {
    let { data } = await axios.post('user/change_password', { user_name: username, old_password: old_password, new_password: new_password })
    dispatch(passwordUpdateSuccess(data));
  } catch (err) {
    dispatch(passwordUpdateFailure(err.response.data.message));
  }
};

export const checkLoggedIn = () => async dispatch => {
  dispatch(uiStartLoading());
  try {
    let { data } = await axios.get('auth/check')
    if (data && data.success) {
      localStorage.setItem("auth-token", JSON.stringify(`${data.user.user_id}`));
      localStorage.setItem("role", JSON.stringify(`${data.user.role}`));
      localStorage.setItem("country_id", JSON.stringify(`${data.user.country_id}`))
      const date = new Date().getTime() + 604800000;
      localStorage.setItem("expirationDate", date);
      dispatch(loginSuccess(data))
    } else {
      dispatch(loginFailure())
    }
    dispatch(uiStopLoading());
  } catch (err) { }
}

export const register = (UserData) => async dispatch => {
  dispatch(uiStartLoading());
  try {
    let { data } = await axios.post('register', UserData)
    dispatch(registerSuccess(data));
    dispatch(uiStopLoading());
    return data
  } catch (err) {
    // dispatch(registerFailure(err.response));
    dispatch(registerFailure(err.response?.data?.message ?? err.message ?? err))
    dispatch(uiStopLoading());
  }
};

export const resetPassword = (email, password) => async dispatch => {
  // try {
  //   const { data } = await api.post("/admin/resetPassword", {
  //     email,
  //     password
  //   });
  //   dispatch(forgotSuccess(data));
  // } catch (err) {
  //   dispatch(forgotFailure(err));
  // }
};


export const fetchUser = () => async dispatch => {
  // try {
  //   const token = localStorage.getItem("auth-token");
  //   const expirationDate = localStorage.getItem("expirationDate");
  //   const date = new Date().getTime();
  //   const headers = {
  //     Authorization: token
  //   };
  //   if (!token) {
  //     dispatch(logOut());
  //   } else {
  //     if (date >= expirationDate) {
  //       dispatch(logOut());
  //     } else {
  //       // const { data } = await api.get("/admin/me", { headers });
  //       const { data } = await api.get("/admin/me");
  //       dispatch(fetchUserSuccess(data));

  //     }
  //   }
  // } catch (err) {
  //   dispatch(fetchUserFailure(err));
  // }
};

export const logOutUser = () => async dispatch => {
  try {
    dispatch(logOut());
  } catch (err) {
  }
};
