export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';

export const easySnackbar = (message, variant, queue=false, key=undefined) => {
  return (queue? enqueueSnackbar : showSnackbar)({
    message,
    options: { variant, key }
  })
}

export const showSnackbar = notification => {
	const key = (notification.options && notification.options.key) || parseInt(new Date().getTime() + Math.random())
  delete notification.options.key

	return {
		type: SHOW_SNACKBAR,
		notification: {
			...notification,
			key
		},
	};
};

export const enqueueSnackbar = notification => {
  const key = (notification.options && notification.options.key) || parseInt(new Date().getTime() + Math.random())
  delete notification.options.key

	return {
		type: ENQUEUE_SNACKBAR,
		notification: {
			...notification,
			key
		},
	};
}

export const closeSnackbar = key => ({
	type: CLOSE_SNACKBAR,
	dismissAll: !key, // dismiss all if no key has been defined
	key,
});

export const removeSnackbar = key => ({
	type: REMOVE_SNACKBAR,
	key,
});
