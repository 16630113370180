import React, { Component } from 'react'
import { Grid, Paper, Typography, Divider, Select, MenuItem } from '@material-ui/core'
import { BrowserRouter as Router, Link, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import Axios from '../../axios-default'
import '../../util/date_beme'
import QueryString from 'querystring'
import Moment from 'moment'

import DateFnsUtils from '@date-io/date-fns';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core'

// components
import ReactApexChart from 'react-apexcharts'
import { connect } from 'react-redux'
import { withLang } from '../../util/lang'


const customTheme = createMuiTheme({
  overrides: {
    MuiPickersBasePicker: {
      pickerView: {
        backgroundColor: "white"
      }
    },
    MuiPickersDay: {
      day: {
        color: "light-gray",
        fontFamily: "\"Do Hyeon\", sans-serif",
        backgroundColor: "white",
        borderRadius: "0px",
      },
      container: {
        backgroundColor: "black"
      },
      daySelected: {
        backgroundColor: "",
        color: "light-gray"
      },
      dayDisabled: {
        color: "black",
      },
      current: {
        color: "",
      },
    },
  },
});

class NewAccountChart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      series: [],
      categories: []
    }
  }
  handleStart = date => {
    this.props.handleChangeStart(date)
  };

  handleEnd = date => {
    this.props.handleChangeEnd(date)
  };


  componentDidMount() {
    this.loadStat()
  }

  componentDidUpdate(prevProps) {
    try {
      if (prevProps.distributor_id !== this.props.distributor_id ||
        prevProps.group_id !== this.props.group_id ||
        prevProps.clinic_id !== this.props.clinic_id ||
        prevProps.startDate.getTime() !== this.props.startDate.getTime() ||
        prevProps.endDate.getTime() !== this.props.endDate.getTime()
      ) {
        this.loadStat()
      }
    } catch (e) { }
  }

  async loadStat() {
    try {
      let { startDate, endDate, distributor_id, group_id, clinic_id } = Object.assign({}, this.props)
      startDate = new Date(startDate)
      endDate = new Date(endDate)
      if (!startDate) {
        startDate = new Date()
        startDate.setMonth(startDate.getMonth() - 6 + 1)
      }
      if (!endDate) {
        endDate = new Date()
      } else {
        endDate.setDate(1)
      }
      endDate.setMonth(endDate.getMonth() + 1)
      const query = QueryString.stringify({
        distributor_id,
        group_id,
        clinic_id,
        startMonth: startDate.getMonth() + 1,
        startYear: startDate.getFullYear(),
        endMonth: endDate.getMonth() + 1,
        endYear: endDate.getFullYear()
      })
      let { data } = await Axios.get('stats/new_user?' + query)
      data = data.stat.reduce((acc, date) => {
        if (date) {
          acc[`${date.week_of_month}-${date.year}-${date.month}`] = date.count
        }
        return acc
      }, {})
      let curDate = new Date(startDate),
        stopDate = new Date(endDate),
        monthIndex = 0,
        series = [],
        categories = []
      curDate.setDate(1)
      while (stopDate - curDate > 0) {
        let firstDayOfMonth = new Date(curDate)
        firstDayOfMonth.setDate(1)
        let lastDayOfMonth = new Date(firstDayOfMonth)
        lastDayOfMonth.setMonth(lastDayOfMonth.getMonth() + 1)
        lastDayOfMonth.setDate(0)
        const year = curDate.getFullYear(),
          month = curDate.getMonth(),
          weekNo = curDate.getWeek() - firstDayOfMonth.getWeek() + 1
        let count = 0
        if (`${weekNo}-${year}-${month + 1}` in data) {
          count = data[`${weekNo}-${year}-${month + 1}`]
        }
        if (!series[weekNo - 1]) {
          series[weekNo - 1] = {
            name: `week-${weekNo}`,
            data: []
          }
        }
        series[weekNo - 1].data[monthIndex] = count
        const catName = `${Date.getMonthName(month)} ${year}`
        if (!categories.includes(catName)) {
          categories.push(catName)
        }
        if (curDate.getDate() + 7 > lastDayOfMonth.getDate()) {
          curDate = lastDayOfMonth
          curDate.setDate(curDate.getDate() + 1)
          monthIndex++
        } else {
          curDate.setDate(curDate.getDate() + 7 - curDate.getDay())
        }
      }
      this.setState({ series, categories })
    } catch (e) {
      if (e && e.response && e.response.statusText === 'Unauthorized') {
        localStorage.removeItem("auth-token");
        localStorage.removeItem("expirationDate");
        localStorage.removeItem("role");
        this.props.history.replace('/login')
      }
    }
  }

  render() {
    const config_color = ["#1b262c", "#3282b8", "#8ec6c5", "#0f4c75"],
      { categories, series } = this.state,
      { groups, distributors, clinics, distributor_id, group_id, clinic_id } = this.props

    return (
      <MuiThemeProvider theme={customTheme}>
        <Grid item xs={12} style={{ padding: "16px 8px 16px 0px" }}>
          <Paper>
            <Grid container>
              <Grid item xs={12} style={{ textAlign: "center", padding: "16px 0px" }}>
                <h3>{this.get('accountChartTitle')}</h3>
                {/* <p>{this.get('accountChartSubtitle')}</p> */}
              </Grid>

              <Grid item xs={6} style={{ padding: 8 }}>
                {/* TODO: Dropdown */}
                <p>{this.get(':fields.startDate')}:</p>
                <div style={{ padding: 8, border: "solid black 1px" }}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      minDate={Moment().subtract(5, 'years').toDate()}
                      maxDate={Moment().add(1, 'month').toDate()}
                      views={["year", "month"]}
                      variant="inline"
                      className="customDatepicker"
                      value={this.props.startDate}
                      onChange={this.handleStart}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </Grid>

              <Grid item xs={6} style={{ padding: 8 }}>
                {/* TODO: Dropdown */}
                <p>{this.get(':fields.endDate')}:</p>
                <div style={{ padding: 8, border: "solid black 1px" }}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      minDate={Moment().subtract(5, 'years').toDate()}
                      maxDate={Moment().add(1, 'month').toDate()}
                      views={["year", "month"]}
                      variant="inline"
                      className="customDatepicker"
                      value={this.props.endDate}
                      onChange={this.handleEnd}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </Grid>

              {Array.isArray(distributors) && distributors.length? <Grid item xs={12} sm={6} md={4} style={{ padding: 8 }}>
                {/* TODO: Dropdown */}
                <p>{this.get(':fields.distributor')}</p>
                <Select
                  displayEmpty
                  variant="outlined"
                  value={distributor_id}
                  onChange={(event) => this.props.handleChangeSelect("distributor_id", event.target.value)}
                  style={{
                    width: "100%"
                  }}
                >
                  <MenuItem value="">{this.get(':const.all')}</MenuItem>
                  {
                    distributors.map(v => (
                      <MenuItem value={v.user_id} key={v.user_id}>{v.first_name} {v.last_name}</MenuItem>
                    ))
                  }
                </Select>
              </Grid> : null}

              <Grid item xs={12} sm={6} md={4} style={{ padding: 8 }}>
                {/* TODO: Dropdown */}
                <p>{this.get(':fields.group')}</p>
                <Select
                  value={group_id}
                  displayEmpty
                  variant="outlined"
                  onChange={(event) => this.props.handleChangeSelect("group_id", event.target.value)}
                  style={{
                    width: "100%"
                  }}
                >
                  <MenuItem value="">{this.get(':const.all')}</MenuItem>
                  {
                    groups.map(v => (
                      <MenuItem value={v.group_id} key={v.group_id}>{v.group_name}</MenuItem>
                    ))
                  }
                </Select>
              </Grid>

              <Grid item xs={12} md={4} style={{ padding: 8 }}>
                {/* TODO: Dropdown */}
                <p>{this.get(':fields.clinic')}</p>
                <Select
                  displayEmpty
                  variant="outlined"
                  value={clinic_id}
                  onChange={(event) => this.props.handleChangeSelect("clinic_id", event.target.value)}
                  style={{
                    width: "100%"
                  }}
                >
                  <MenuItem value="">{this.get(':const.all')}</MenuItem>
                  {
                    clinics.map(v => (
                      <MenuItem value={v.clinic_id} key={v.clinic_id}>{v.clinic_name}</MenuItem>
                    ))
                  }
                </Select>
              </Grid>

              <Grid item xs={12}>
                <ReactApexChart
                  options={{
                    chart: {
                      shadow: {
                        enabled: true,
                        color: '#000',
                        top: 18,
                        left: 7,
                        blur: 10,
                        opacity: 1
                      },
                      toolbar: {
                        show: false
                      }
                    },
                    colors: config_color,
                    dataLabels: {
                      enabled: true,
                    },
                    stroke: {
                      curve: 'straight'
                    },
                    title: {
                      text: this.props.title,
                      align: 'left',
                      style: {
                        fontSize: '28px'
                      },
                    },
                    grid: {
                      borderColor: '#e7e7e7',
                      row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                      },
                    },
                    markers: {
                      size: 6
                    },
                    xaxis: {
                      // categories: this.props.data.map(v => v.date),
                      categories,
                      title: {
                        text: this.get(':fields.date')
                      }
                    },
                    yaxis: {
                      title: {
                        text: ''
                      },
                      min: 0,
                      // max: Math.max(...this.props.data.map(v=>v.revenue)) + 100
                      // max: 80
                    },
                    legend: {
                      show: true,
                      position: 'top',
                      horizontalAlign: 'left',
                      floating: false,
                      // offsetY: -25,
                      // offsetX: -5
                      onItemClick: {
                        toggleDataSeries: false
                      }
                    }
                  }}
                  series={series}
                  type="bar"
                  height="350"
                  width="100%"
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </MuiThemeProvider>
    );
  }
}

NewAccountChart.propTypes = {
  distributor_id: PropTypes.number,
  group_id: PropTypes.number,
  clinic_id: PropTypes.number,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date)
}

const mapStateToProps = store => {
  return {
    lang: store.lang
  }
};

export default connect(mapStateToProps)(withRouter(withLang(NewAccountChart, 'Account')))
