import '../../util/string'
import Grid from '@material-ui/core/Grid';
import Moment from 'moment';
import { CircularProgress } from '@material-ui/core/';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import QueryString from 'querystring'
import { withRouter, useHistory } from 'react-router-dom';
import Url from 'url';
import axios, { safeGet } from '../../axios-default';
import Log from '../../util/log_beme'
import { useLang } from '../../util/lang';
//Components
import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import DraftsIcon from '@material-ui/icons/Drafts'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import GridOnIcon from '@material-ui/icons/GridOn'
import LastPage from '@material-ui/icons/LastPage'
import MailOutlinedIcon from '@material-ui/icons/MailOutlined'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import ViewListIcon from '@material-ui/icons/ViewList'
import MaterialTable from 'material-table';

const TableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
}


const Columns = [
  {
    field: 'created_date',
    title: 'Date'
  },
  {
    field: 'case_id',
    title: 'Case ID',
    searchable: true
  },
  {
    field: 'patient_name',
    title: 'Patient'
  },
  // {
  //   field: "distr_name",
  //   title: "Distributor Name"
  // },
  {
    field: "doctor_name",
    title: "Doctor Name"
  },
  {
    field: "log",
    title: "Case Log Description"
  }
]

export default function CaseLogs(props) {
  const dispatch = useDispatch(),
        history = useHistory(),
        get = useLang('PatientInfo')

  const lastPageSize = useRef(),
        lastPageNo = useRef(0),
        [loading, setLoading] = useState(false)

  useEffect(() => {
    window.scroll({top: 0, left: 0, behavior: 'smooth' })
  }, [])

  const reloadPage = () => {
    const curPage = history.location.pathname
    history.push('/blank')
    history.replace(curPage)
  }
  const dataGetter = async query => {
    const { pageSize, page: pageNo, search, orderBy, orderDirection} = query
    try {
      if (orderBy && orderBy.field.endsWith('_label')) {
        orderBy.field = orderBy.field.slice(0, -6)
      }
      const { data } = (await axios.get(`case/log${typeof search !== 'string' || !String.isBlank(search)? `/${search}` : ''}?${QueryString.encode({
        sortBy: orderBy && orderBy.field,
        sortDir: orderDirection,
        statusFilter: 'ALL',
        pageSize,
        pageNo,
        all: 1
      })}`))
      lastPageSize.current= pageSize
      lastPageNo.current = pageNo
      setLoading(false)
      const logs = data.log?.length? Log.translateLogs.bind({ get })(data.log.filter(c => c.case_id >= 1), data.log[0].case_type, true, false, true).map(({
        log: { case_id, patient_name, doctor_name }, msg, date: { formal }
      }) => ({
        case_id,
        patient_name,
        doctor_name,
        log: typeof msg === 'object'? msg?.msg : msg,
        created_date: formal
      })) : []
      return {
        data: logs,
        page: pageNo ?? 0,
        totalCount: data.total ?? 0
      }
    } catch (err) {
      console.error(err)
      if (err.response && err.response.statusText === 'Unauthorized') {
        localStorage.removeItem("auth-token");
        localStorage.removeItem("expirationDate");
        localStorage.removeItem("role");
        history.replace('/login')
      }
    }
  }
    return (
      <div>

        <Grid container style={{ marginTop: 0 }}>
          {!loading ?
          <div style={{ display: "contents" }}>
            <Grid item xs={12} style={{padding: 8}}>
              <h1 style={{ marginBlockEnd: 0 }}> CaseLogs</h1>
              <hr />
            </Grid>

            <Grid item xs={12} style={{ padding: 8 }}>
              <MaterialTable
                title=''
                columns={Columns}
                data={dataGetter}
                icons={TableIcons}
                localization={{
                  toolbar: {
                    searchPlaceholder: 'Case Id'
                  }
                }}
                options={{
                  initialPage: 0,
                  emptyRowsWhenPaging: false,
                  exportButton: true,
                  exportAllData: true,
                  debounceInterval: 700,
                  pageSize: 15,
                  pageSizeOptions: [15, 30, 50],
                  minBodyHeight: 700,
                  maxBodyHeight: 1000,
                  sorting: true
                }}
              />
            </Grid>
          </div>:
            <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress style={{ color: "rgb(6,26,46)" }} />
            </Grid>
          }
        </Grid>
      </div>
    )
}