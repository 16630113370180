import { Button, CircularProgress, Fab, Grid, makeStyles, TextField } from '@material-ui/core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import SideNav from '../../components/Admin/SideNav'
import Modal from '../../components/Modal/modal';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { updateRes } from '../../store/res/res.action'
import MUIDataTable from 'mui-datatables'
import axios from '../../axios-default';
import { easySnackbar } from '../../store/Noti/noti.actions';
import { getWebAbsPath } from '../../util/path'


const AdminGroupsStyles = makeStyles(theme => ({
  "@global": {
    body: {
      background: "#fdfdfd",
    },
  },
  root: {
    flexGrow: 1,
  },
  rootContainer: {
    padding: 16,
    margin: "8px 0px 0px 238px",
    [theme.breakpoints.between("xs", "sm")]: {
      margin: "12px 0px 0px 0px",
      width: "100%",
    },
    width: "calc(100% - 238px)",
  },
  scanBox: {
    padding: 16,
    margin: "8px 0px"
  },
  textField: {
    // marginLeft: "8px",
    // marginRight: "8px",
    '& label.Mui-focused': {
      color: 'black',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'black',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey',
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
  },
  dateField: {
    width: "100%"
  },
  formControl: {
    width: "100%"
  },
  dense: {
    marginTop: 16,
    marginLeft: 0
  },
  submitBtn: {
    backgroundColor: "#061a2e !important",
    color: "white",
    padding: "8px 24px",
    margin: "12px 0px"
  },
  linkBtn: {
    background: "rgba(89,54,135,1)",
    color: "white",
    padding: "4px 24px",
    fontWeight: 300,
    margin: "16px 0px",
    width: "100%",
    [theme.breakpoints.up('sm')]: {
      padding: "4px 72px",
      width: "unset",
    },
    "&:hover": {
      background: "rgb(77, 74, 142)",
      color: "white"
    }
  },
}))

const AdminGroups = () => {
  const classes = AdminGroupsStyles()
  const res = useSelector(({ res }) => res)
  const dispatch = useDispatch()
  const history = useHistory()

  const [modalOpen, setModalOpen] = useState(false),
        [modalError, setModalError] = useState(''),
        [updatedAdminGroup, setUpdatedAdminGroup] = useState({}),
        [AdminGroups, setAdminGroups] = useState([]),
        [loading, setLoading] = useState(true)

  useEffect(() => {
    (async () => {
      try {
        setAdminGroups(
          Object.values(res.admin_groups).sort((a, b) => a.admin_group_id - b.admin_group_id).map(g => ({
            admin_group_name: g.admin_group_name,
            desc: g.description,
            id: g
          }))
        )
        setLoading(false)
        setUpdatedAdminGroup({})
      } catch (err) {
        console.error(err)
        if (err.response && err.response.statusText === 'Unauthorized') {
          localStorage.removeItem("auth-token");
          localStorage.removeItem("expirationDate");
          localStorage.removeItem("role");
          history.push("/login")
        }
      }
    })()
  }, [res.admin_groups])



  const Columns = useMemo(() => [
    {
      name: "admin_group_name",
      label: "Admin Group Name",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "desc",
      label: 'description',
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "id",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ display: "flex" }}>
              <Fab size="small" aria-label="add"
                style={{
                  backgroundColor: "#75a0de",
                  color: "white",
                  margin: "0px 4px"
                }}
                onClick={() => {
                  setUpdatedAdminGroup(Object.assign({}, value))
                  setModalOpen(true)
                }}
              >
                <EditIcon />
              </Fab>
              <Fab size="small" aria-label="add"
                style={{
                  backgroundColor: "#da5851",
                  color: "white",
                  margin: "0px 4px"
                }}
                onClick={async () => {
                  if (window.confirm('Are you going to delete this admin group?')) {
                    try {
                      const { data } = await axios.delete(`res/admingroup/${value.admin_group_id}`)
                      if (data.success) {
                        setLoading(true)
                        await dispatch(updateRes('admin_groups'))
                        reloadPage()
                      } else {
                        console.error(data.message)
                      }
                    } catch (err) {
                      console.error(err)
                      if (err.response && err.response.statusText === 'Unauthorized') {
                        localStorage.removeItem("auth-token");
                        localStorage.removeItem("expirationDate");
                        localStorage.removeItem("role");
                        history.push("/login")
                      }
                    }
                  }
                }}>
                <DeleteOutlineIcon />
              </Fab>
            </div>
          );
        }
      }
    },
  ], [])

  const reloadPage = useCallback(() => {
    const curPage = history.location.pathname
    history.push('/blank')
    history.replace(curPage)
  }, [history])

  const handleUpdate = useCallback(field => ({ target: { value } }) => {
    setUpdatedAdminGroup({
      ...updatedAdminGroup,
      [field]: value
    })
  }, [updatedAdminGroup])

  const handleSave = useCallback(async () => {
    try {
      if (loading) return
      setLoading(true)
      if (String.isBlank(updatedAdminGroup.admin_group_name)) {
        return setModalError("Admin Group Name is required")
      }
      await axios.post(`res/admingroup`, updatedAdminGroup)
      window.scroll({top: 0, left: 0, behavior: 'smooth' })
      dispatch(easySnackbar('Success', 'success'))

      dispatch(updateRes('admin_groups'))
      reloadPage()
      setLoading(true)
      setModalOpen(false)
    } catch (err) {
      console.error(err)
      if (err.response && err.response.statusText === 'Unauthorized') {
        localStorage.removeItem("auth-token");
        localStorage.removeItem("expirationDate");
        localStorage.removeItem("role");
        history.push("/login")
      } else {
        dispatch(easySnackbar(err.response?.data?.message ?? err.message ?? err, 'error'))
      }
    } finally {
      setLoading(false)
    }
  }, [updatedAdminGroup])

  return (
    <div>
      <SideNav />
      <Grid container className={classes.rootContainer}>

      </Grid>
      <Grid container className={classes.rootContainer} style={{ marginTop: 0 }}>
        {!loading ?
          <div style={{ display: "contents" }}>
            <Grid container>

              <Grid item xs={12}
                style={{
                  padding: 8
                }}>
                <h1
                  style={{
                    marginBlockEnd: 0
                  }}
                >
                  Admin Groups
                </h1>

                <hr />
              </Grid>

              <Grid item xs={12}
                style={{
                  padding: 8,
                  textAlign: "center"
                }}>
                <img style={{ width: "100%", maxWidth: 800 }} src={getWebAbsPath("/img/account1.png")} />
              </Grid>

              <Grid item xs={12} style={{ padding: 8 }}>
                <Button className={classes.linkBtn} onClick={() => setModalOpen(true)}>
                  Add Admin Group
                </Button>
                <MUIDataTable
                  title={"Admin Group List"}
                  data={AdminGroups}
                  columns={Columns}
                  options={{
                    download: false,
                    filterType: "dropdown",
                    responsive: "scroll",
                    selectableRows: false,
                    rowsPerPage: 30,
                    rowsPerPageOptions: [15, 30, 50]
                  }}
                />
                <Modal
                  className="modal"
                  modal='Admin Group'
                  title='Modify Admin Group'
                  open={modalOpen}
                  handleClose={() => setModalOpen(false)}
                  handleDataSave={() => handleSave()}
                  modalError={modalError}
                  submitButton={true}
                >
                  <>
                    <Grid item xs={12} style={{ padding: 4 }}>
                      <TextField
                        id="admin_group_name"
                        label="Admin Group Name"
                        margin="normal"
                        style={{ width: "100%" }}
                        value={updatedAdminGroup.admin_group_name}
                        onChange={handleUpdate('admin_group_name')}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ padding: 4 }}>
                      <TextField
                        id="description"
                        label="Description"
                        style={{ width: "100%" }}
                        margin="normal"
                        value={updatedAdminGroup.description}
                        onChange={handleUpdate('description')}
                      />
                    </Grid>
                  </>
                </Modal>
              </Grid>

            </Grid>

          </div> :
          <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress style={{ color: "rgb(6,26,46)" }} />
          </Grid>}
      </Grid>
    </div>
  )
}
export default AdminGroups