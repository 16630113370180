import React, { Component } from 'react'
import { connect } from 'react-redux';
import * as actionTypes from '../../../store/NewPatient/actionType';
import * as actionNewAddress from '../../../store/NewAddress/actionType';
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { Grid, TextField, Button, Select, MenuItem } from '@material-ui/core'
import style from './NewPatientDelivery.module.css';
import NewAddress from './NewAddress'
import axios from '../../../axios-default'
import { withLang } from '../../../util/lang';
import { getWebAbsPath } from '../../../util/path';
// material ui and css stuff
// import { Grid } from '@material-ui/core'

const styles = theme => ({
  root: {
    margin: '48px auto',
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
    },
    [theme.breakpoints.up('lg')]: {
      width: "70%",
      minHeight: "400px",
    },
  },
  mainCard: {
    backgroundColor: "white",
    borderRight: "solid 6px #353535",
    color: "white",
    [theme.breakpoints.down('xs')]: {
      borderRight: "none",
    },
  },
  mainContainer: {
    textAlign: "left",
    boxShadow: "0 1px 2px rgba(0,0,0,0.15)",
    backgroundColor: "white",
    padding: 16,
    [theme.breakpoints.down('xs')]: {
      maxHeight: "unset"
    },
  },
  textField: {
    width: "100%",
    fontWeight: "300 !important"
  },
  textfieldContainer: {
    padding: 4
  },
  genderSelectActive: {
    border: "solid 1px #4a4f90",
    borderRadius: 0,
    backgroundColor: "#4a4f90",
    color: "white",
    padding: "4px 24px",
    fontWeight: 300,
    "&:hover": {
      background: "#4a4f90",
      color: "white"
    }
  },
  dateLabel: {
    marginBlockStart: "8px",
    marginBlockEnd: "8px",
    fontSize: "14px"
  }
})

class NewPatientDelivery extends Component {

  constructor(props) {
    super(props);

    this.state = {}
  }

  returnAddresses() {
    return (
      this.props.addresses.map(v =>
        <MenuItem value={v.delivery_address_id} key={"address " + v.delivery_address_id}>{v.first_name},{v.address_1},{v.phone_no}</MenuItem>
      )

    )
  }

  async handleChange(value) {
    let address;
    if (value == 'New Address') {
      address = 'New Address'
    }
    else {
      const caseId = parseInt(window.location?.pathname?.split('/')?.[2])
      let addressList = (await axios.get(`case/address${isNaN(caseId)? '' : `?case_id=${caseId}`}`)).data.address
      address = addressList.filter(v => {
        return v.delivery_address_id === value
      })
    }
    this.props.handleChangeAddress(address)
  }


  render() {
    const { classes } = this.props
    return (
      <div style={{ display: "contents" }}>
        <Grid item xs={12} md={6} style={{ textAlign: "center" }}>
          <Grid container>
            <Grid item xs={12} style={{ marginTop: 24 }}>
              <img style={{ width: "100%" }} src={getWebAbsPath("/img/Delivery.jpg")} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6} style={{ padding: 8 }}>
          <Grid container>
            <Grid item xs={12} className={classes.textfieldContainer}>
              <TextField
                id="promotionCode"
                label={this.get('delivery.promotionCodeLabel')}
                className={classes.textField}
                value={this.props.promotionCode}
                onChange={(event) => this.props.handleChangePromotion(event.target.value)}
                margin="normal"
              />
            </Grid>

            <Grid item xs={12} style={{ padding: 4 }}>
              <p>{this.get('delivery.selectAddressLabel')}</p>
              <Select
                value={this.props.delivery_id == null ? "New Address" : this.props.delivery_id}
                onChange={(event) => this.handleChange(event.target.value)}
                inputProps={{
                  name: 'address',
                  id: 'address',
                }}
                style={{
                  width: "100%"
                }}
              >
                {this.returnAddresses()}
                <MenuItem value="New Address" key={"address New Address"}>New Address</MenuItem>
              </Select>
            </Grid>
            <Grid container>
              {
                this.props.delivery_id === 'New Address' && <NewAddress
                  countryList={this.state.countryList}
                  stateList={this.state.stateList}
                  addressList={this.state.addressList} />
              }
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = store => {
  return {
    promotionCode: store.newPatientReducer.promotionCode,
    addresses: store.newAddressReducer.addresses,
    delivery_id: store.newAddressReducer.delivery_id,
    delivery_address_id: store.newAddressReducer.delivery_address_id,
    lang: store.lang
  }
};

const mapDispatchToProps = dispatch => {
  return {
    handleChangePromotion: (value) => dispatch({ type: actionTypes.NEW_PATIENT_DELIVERY_PROMO, value: value }),
    handleChangeAddress: (value) => dispatch({ type: actionNewAddress.NEW_PATIENT_DELIVERY_ADDRESS, address: value }),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(withLang(NewPatientDelivery, 'NewPatient'))))