import { ClickAwayListener, Grid, Grow, IconButton, MenuItem, MenuList, Paper, Popper, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import HomeIcon from '@material-ui/icons/Home';
import LanguageIcon from '@material-ui/icons/Language';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { loadLang } from '../store/lang/lang.action';
import { withLang } from '../util/lang';
import frontend_config from '../frontend_config';
import { getWebAbsPath } from '../util/path';

const hostname = window.location.hostname


const styles = theme => ({
  loginTop: {
    position: "fixed",
    top: "0px",
    left: "0px",
    right: "0px",
    display: "flex",
    paddingTop: 6,
    justifyContent: "space-between",
    alignItems: "center",
    height: "60px",
    paddingLeft: "2rem",
    paddingRight: "2rem",
    zIndex: 2,
    boxShadow: "rgba(0, 0, 0, 0.25) 0px 2px 3px",
    background: "rgb(255, 255, 255)",
    borderBottom: `solid 2px${frontend_config?.[hostname]?.main_color_2}`,
    [theme.breakpoints.down(600)]: {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  }
})

class TopBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      anchorEl: null
    }
  }

  handleLangChange = langName => () => {
    this.props.loadLang(langName)
    localStorage.setItem('lang', langName)
    this.setState({ anchorEl: null })
  }

  render() {
    const { classes, lang } = this.props
    return (
      <div className={classes.loginTop}>
        <Grid container>
          <Grid item xs={6}>
            <Link to="/">
            <img
                alt="BeMe Logo"
              src={ getWebAbsPath(frontend_config?.[hostname]?.logo_path)}
              style={{
                height: 60
              }}
            />
            </Link>
          </Grid>
          <Grid item xs={6} style={{ paddingTop: 6, textAlign: "right" }}>
            {/* <Tooltip title={this.get('trioclearWebsite')}>
              <IconButton onClick={() => window.open("https://www.trioclear.com/")}>
                <HomeIcon style={{ color: `${frontend_config?.[hostname]?.main_color_1}` }} />
              </IconButton>
            </Tooltip> */}

            <Tooltip title={this.get('lang')}>
              <IconButton
                onClick={({ currentTarget }) => this.setState({ anchorEl: currentTarget })}
              >
                <LanguageIcon style={{ color: `${frontend_config?.[hostname]?.main_color_1}` }} />
              </IconButton>
            </Tooltip>

            <Popper
              open={Boolean(this.state.anchorEl)}
              anchorEl={this.state.anchorEl}
              role={undefined}
              style={{zIndex: 999}}
              placement="bottom"
              transition
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{ transformOrigin: placement === 'bottom' ? 'center bottem' : 'center top' }}
                >
                  <Paper style={{overflow: 'auto', maxHeight: '30vh'}}>
                    <ClickAwayListener onClickAway={() => this.setState({ anchorEl: null })}>
                      <MenuList autoFocusItem={Boolean(this.state.anchorEl)} id="lang-menu">
                        {lang.avail.map(langName => (
                          <MenuItem onClick={this.handleLangChange(langName)}>{langName}</MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = store => ({
  lang: store.lang,
})

const mapDispatchToProps = dispatch => ({
  loadLang: langName => dispatch(loadLang(langName))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(withLang(TopBar, 'TopBar'))))
