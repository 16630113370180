import {
  UPDATING_ACCOUNT_INIT, UPDATING_ACCOUNT_USER, UPDATING_ACCOUNT_ROLE, UPDATING_ACCOUNT_RELOAD
} from './actionType'

import axios from '../../axios-default'

const _initUpdatingAccount = payload => ({
  type: UPDATING_ACCOUNT_INIT,
  payload
})

const reloadUpdatingAccountRole = payload => ({
  type: UPDATING_ACCOUNT_RELOAD,
  payload
})

const modifyUpdatingAccountUser = payload => ({
  type: UPDATING_ACCOUNT_USER,
  payload
})

const modifyUpdatingAccountRole = payload => ({
  type: UPDATING_ACCOUNT_ROLE,
  payload
})

export const initUpdatingAccount = (user_id) => async dispatch => {
  let countries, stateProvinces, user, hierarchy
  try {
    countries = (await axios.get(`res/country`)).data.country
    stateProvinces = (await axios.get(`res/state_province`)).data.state_province
    user = user_id === 'new'? { user_id: null } : (await axios.get(`user/${user_id}`)).data.user
    hierarchy = user_id === 'new'? null : (await axios.get(`user/hierarchy/${user_id}`)).data.hierarchy
  } catch (e) {
    console.error(e)
    user = { user_id: null }
    hierarchy = null
  }
  let clinics, groups, admin_groups, distr_groups
  if (hierarchy) {
    clinics = [...new Set((hierarchy.clinic_sep||[]).concat(hierarchy.clinic_sub||[]))]
    groups = [...new Set((hierarchy.group_sep||[]).concat(hierarchy.group_sub||[]))]
    admin_groups = [...new Set(hierarchy.admin_group_sep||[])]
    distr_groups = [...new Set((hierarchy.distr_group_sep||[]).concat(hierarchy.distr_group_sub||[]))]
  }
  return dispatch(_initUpdatingAccount({
    countries,
    stateProvinces,
    user,
    role: {
      ...hierarchy,
      ...(hierarchy? {
          user_id: [...new Set((hierarchy.user_sep||[]).concat(hierarchy.user_sub||[]))],
          clinic_id: clinics,
          clinics,
          group_id: groups,
          groups,
          admin_groups,
          distr_groups,
        } : null
      ),
      role: user.role && user.role.role_name
    }
  }))
}

export const reloadUpdatingAccount = (user_id) => async dispatch => {
  let user, hierarchy
  try {
    user = user_id === 'new'? { user_id: null } : (await axios.get(`user/${user_id}`)).data.user
    hierarchy = user_id === 'new'? null : (await axios.get(`user/hierarchy/${user_id}`)).data.hierarchy
  } catch (e) {
    console.error(e)
    user = { user_id: null }
    hierarchy = null
  }
  let clinics, groups, admin_groups, distr_groups
  if (hierarchy) {
    clinics = [...new Set((hierarchy.clinic_sep||[]).concat(hierarchy.clinic_sub||[]))]
    groups = [...new Set((hierarchy.group_sep||[]).concat(hierarchy.group_sub||[]))]
    admin_groups = [...new Set(hierarchy.admin_group_sep||[])]
    distr_groups = [...new Set((hierarchy.distr_group_sep||[]).concat(hierarchy.distr_group_sub||[]))]
  }
  return dispatch(reloadUpdatingAccountRole({
    user,
    role: {
      ...hierarchy,
      ...(hierarchy? {
          user_id: [...new Set((hierarchy.user_sep||[]).concat(hierarchy.user_sub||[]))],
          clinic_id: clinics,
          clinics,
          group_id: groups,
          groups,
          admin_groups,
          distr_groups,
        } : null
      ),
      role: user.role && user.role.role_name
    }
  }))
}

export const updateUpdatingAccountUser = (updatedUser) => async dispatch => {
  try {
    return dispatch(modifyUpdatingAccountUser(updatedUser))
  } catch (e) {
    console.error(e)
  }
}

export const updateUpdatingAccountRole = (updatedRole) => async dispatch => {
  try {
    return dispatch(modifyUpdatingAccountRole(updatedRole))
  } catch (e) {
    console.error(e)
  }
}