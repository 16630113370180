import {
  Button,
  Grid,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Checkbox
} from "@material-ui/core";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from "@material-ui/core/FormControl";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import DoneIcon from "@material-ui/icons/Done";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import MuiAlert from "@material-ui/lab/Alert";
import Moment from "moment/moment";
import React, { Component } from "react";
import { Animated } from "react-animated-css";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Url from "url";
import join from 'url-join'
import axios, { safeGet } from "../axios-default";
import { ApiServerUrl } from "../config.json";
import { withLang, getFromObj} from "../util/lang";
import externalCss from "./css/patientInfo.module.css";
import { easySnackbar } from "../store/Noti/noti.actions";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withStyles } from "@material-ui/core/styles";
import Treatment from "../components/NewPatient/Photos/TreatmentPlan";
import Modal from "../components/Modal/modal";
import LoadingProgressBar from "../components/LoadingProgressBar";
import EditIcon from "@material-ui/icons/Edit";
import RecommendedDays from "../components/TreatmentApproval/RecommendedDays"
import { getWebAbsPath } from "../util/path"
import '../util/string'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ExpansionPanel = withStyles({
  root: {
    border: "1px solid rgba(0,0,0,.125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    marginBottom: "10px",
  },
  expanded: {
    margin: "auto",
    paddingBottom: "10px",
  },
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: "rgba(0,0,0,.03)",
    borderBottom: "1px solid rgba(0,0,0,.125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    margin: "0px 0",
    "&$expanded": {
      margin: "0px 0",
    },
  },
  expanded: {},
})((props) => <MuiExpansionPanelSummary {...props} />);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: "10px 10px 0",
  },
}))(MuiExpansionPanelDetails);

ExpansionPanelSummary.muiName = "ExpansionPanelSummary";

class TreatmentApproval extends Component {
  constructor(props) {
    super(props)
    this.state = {
      treatment_plan: [],
      caseId: this.props.match.params.id,
      role: JSON.parse(localStorage.getItem("role")),
      error: "",
      selectedPlan: null,
      latestApprovedPlan: {},
      distrData:[],
      TreatmentModal: false,
      TreatmentApprovalModal: false,
      distrAlertMsg1: false,
      distrAlertMsg2: false,
      distrMissingMsg: true,
      RecommendedDaysModal: false,
      RecommendedDaysModalTitle: "",
      RecommendedDaysModalSubmitButton: false,
      RecommendedDaysModalSubmitLabel: "",
      RecommendedDaysModalCloseLabel: "",
      approve_question: "",
      ProgressPhotoModal: false,
      currentTreatmentPlanIDforModal: "",
      loading: false,
      treatmentPlan: {
        pdf1: null,
        pdf1_label: "",
        pdf2: null,
        pdf2_label: "",
        video1: null,
        video1_label: "",
        video2: null,
        video2_label: "",
        smile_3d: "",
        treatment_approval: true,
        max_aligner_number: 0
      },
      aligners: []
    }
    this.progressBarRef = React.createRef(null)
    this.handleOpen = this.handleOpen.bind(this)
    this.setAligners = this.setAligners.bind(this)
    this.updateRecommendedDays = this.updateRecommendedDays.bind(this)
    this.saveRecommendedDays = this.saveRecommendedDays.bind(this)
  }

  handleOpen(modalType, ModalTypeState = "view", args) {
    switch (modalType) {
      case "treatment":
        this.setState({ TreatmentModal: true, ModalState: ModalTypeState });
        break;
      case "treatment_approval":
        this.setState({ TreatmentApprovalModal: true, ModalState: ModalTypeState });
        break;
      case "recommended_days":
        let title = "Aligners", submitButton = false, submitLabel = "Update", closeLabel = this.get(':const.close'), approve_question = ""
        if (ModalTypeState == "edit") {
          title = this.get('treatmentPlan.approvePlan')
          submitLabel = this.get(':const.yes')
          closeLabel = this.get(':const.no')
          approve_question = "Are you sure you want to approve this treatment plan?"
        }
        if (["DR", "DISTR"].includes(JSON.parse(localStorage.getItem("role")))) {
          submitButton = true
        }
        this.setState({
          RecommendedDaysModal: true,
          ModalState: ModalTypeState,
          selectedPlan: args,
          RecommendedDaysModalTitle: title,
          RecommendedDaysModalSubmitButton: submitButton,
          RecommendedDaysModalSubmitLabel: submitLabel,
          RecommendedDaysModalCloseLabel: closeLabel,
          approve_question: approve_question
        })
        break
    }
  }
  handleClose(modalType) {
    let defaultState = { modalError: "", targetId: null };
    switch (modalType) {
      case "treatment":
        defaultState = { ...defaultState, TreatmentModal: false };
        break;
      case "treatment_approval":
        defaultState = { ...defaultState, TreatmentApprovalModal: false };
        break;
      case "recommended_days":
        defaultState = { ...defaultState, RecommendedDaysModal: false }
        break
    }
    this.setState(defaultState);
  }

  async handleTreatmentData(data = null, stateType, fileName) {
    let newtreatmentPlan = { ...this.state.treatmentPlan };
    switch (stateType) {
      case "pdf1":
        newtreatmentPlan.pdf1 = data;
        newtreatmentPlan.pdf1_label = fileName;
        this.setState({ treatmentPlan: newtreatmentPlan });
        break;
      case "pdf2":
        newtreatmentPlan.pdf2 = data;
        newtreatmentPlan.pdf2_label = fileName;
        this.setState({ treatmentPlan: newtreatmentPlan });
        break;
      case "video1":
        newtreatmentPlan.video1 = data;
        newtreatmentPlan.video1_label = fileName;
        this.setState({ treatmentPlan: newtreatmentPlan });
        break;
      case "video2":
        newtreatmentPlan.video2 = data;
        newtreatmentPlan.video2_label = fileName;
        this.setState({ treatmentPlan: newtreatmentPlan });
        break;
      case "smile_3d":
        newtreatmentPlan.smile_3d = data;
        this.setState({ treatmentPlan: newtreatmentPlan });
        break;
      case "treatment_approval":
        newtreatmentPlan.treatment_approval = data;
        this.setState({ treatmentPlan: newtreatmentPlan });
        break;
      case 'max_aligner_number':
        newtreatmentPlan.max_aligner_number = parseInt(data) || 0
        this.setState({ treatmentPlan: newtreatmentPlan })
        break
    }
  }

  async handleTreatmentSave() {
    const form = new FormData();
    let treatmentData = {
      pdf0: this.state.treatmentPlan.pdf1 ? "$$FILE:pdf0" : null,
      pdf0_name: this.state.treatmentPlan.pdf1_label,
      pdf1: this.state.treatmentPlan.pdf2 ? "$$FILE:pdf1" : null,
      pdf1_name: this.state.treatmentPlan.pdf2_label,
      video0: this.state.treatmentPlan.video1 ? "$$FILE:video0" : null,
      video0_name: this.state.treatmentPlan.video1_label,
      video1: this.state.treatmentPlan.video2 ? "$$FILE:video1" : null,
      video1_name: this.state.treatmentPlan.video2_label,
      url: this.state.treatmentPlan.smile_3d,
      myo_oid:this.state.treatmentPlan.myo_oid,
      myo_pid:this.state.treatmentPlan.myo_pid,
      myo_id:this.state.treatmentPlan.myo_id,
      myo_token:this.state.treatmentPlan.myo_token,
      max_aligner_number: this.state.treatmentPlan.max_aligner_number
    };
    let isFileUploaded = false;
    if (this.state.treatmentPlan.pdf1) {
      form.append("$$FILE:pdf0", this.state.treatmentPlan.pdf1);
      isFileUploaded = true;
    }
    if (this.state.treatmentPlan.pdf2) {
      form.append("$$FILE:pdf1", this.state.treatmentPlan.pdf2);
      isFileUploaded = true;
    }

    if (this.state.treatmentPlan.video1) {
      form.append("$$FILE:video0", this.state.treatmentPlan.video1);
      isFileUploaded = true;
    }
    if (this.state.treatmentPlan.video2) {
      form.append("$$FILE:video1", this.state.treatmentPlan.video2);
      isFileUploaded = true;
    }
    if (this.state.treatmentPlan.smile_3d && this.state.treatmentPlan.smile_3d != "~pending upload~"){
      //if smile 3d have value
      isFileUploaded = true;
    }

    if (!isFileUploaded){
      alert("No any file uploaded, please check and save again.");
      return;
    }

    form.append("body", JSON.stringify(treatmentData));
    //console.log(treatmentData);
    try {
      this.setState({ PhotoUploadLoadingStatus: true, loading: true });
      await axios.put(
        `case/treatment_plan/${this.state.caseId}/${this.state.currentTreatmentPlanIDforModal}`,
        form,
        {
          onUploadProgress: this.progressBarRef.current?.onProgressChange,
        }
      );
      await this.props.showSnackbar(getFromObj(this.props.lang, 'dict.pages.treatmentapproval.treatment_plan_uploaded'), "success", true);
      this.reloadPage();
    } catch (err) {
      this.setState({
        modalError: "Something Went Wrong Please Connect With Admin",
      });
      console.error(err.response);
    } finally {
      this.setState({ PhotoUploadLoadingStatus: false, loading: false });
    }
  }

  reloadPage() {
    this.props.history.push("/blank");
    this.props.history.replace(`/patients/${this.state.caseId}/treatment`);
  }

  async componentDidMount() {
    let id = parseInt(this.state.caseId);
    try {
      let TreatmentPlanData = (
        await axios.get(`case/treatment_plan/${this.state.caseId}`)
      ).data.treatment_plan;
      this.setState({ treatment_plan: TreatmentPlanData });
      this.setState({
        latestApprovedPlan:
          TreatmentPlanData[TreatmentPlanData.length - 1]?.find(
            (plan) => plan.approved_flag
          ) ?? {},
      });

      let caseStatus = (await axios.get(`case/${this.state.caseId}`)).data.case[0].case_status

      this.setState({ caseStatus })

      //get the case data in case need to upload new 3d simulation file.
      const caseData = await safeGet(`case/${id}`)
      const [_case] = caseData.case
      const patientInfoData = {
        id: _case.case_id,
        firstName: _case.first_name,
        lastName: _case.last_name,
        case_status:_case.case_status,
      }

      this.setState({
        patientInfo: patientInfoData
      })
    } catch (e) {
      console.error(e);
    }
  }

  async handleUndoApproval(plan_id) {
    let result = window.confirm(
      "Are you sure you want to undo this treatment plan approval?"
    );
    if (result) {
      try {
        let data = await axios.post(
          `case/${this.state.caseId}/treatment_plan/${plan_id}/undo`,
          {}
        );
        await this.props.showSnackbar(
          `Treatment plan #${plan_id} approval is undone`,
          "success",
          true
        );
        this.props.history.push("/blank");
        this.props.history.replace(`/patients/${this.state.caseId}`);
      } catch (e) {
        this.setState({
          error: "Cannot change the status please connect with admin",
        });
        console.error(e.response);
      }
    }
  }

  setAligners(aligners) {
    this.setState({aligners})
  }

  updateRecommendedDays(val, index) {
    if (["DR", "DISTR"].includes(JSON.parse(localStorage.getItem("role")))) {
      let aligners = [...this.state.aligners]
      this.state.aligners.map((v, i) => {
        let aligner = {...aligners[i]}
        if (i >= index) {
          aligner.days_for_aligner = val ? parseInt(val) : 0
        }
        aligners[i] = aligner
      })
      this.setState({aligners: aligners})
    }
  }

  async saveRecommendedDays() {
    if (["DR", "DISTR"].includes(this.state.role)) {
      let planId = this.state.selectedPlan.plan_id
      if (this.state.aligners) {
        let invalidDays = false
        this.state.aligners.map(v => {
          if (!v.days_for_aligner || v.days_for_aligner == 0) {
            invalidDays = true
          }
        })

        if (invalidDays) {
          return alert("Recommended days must be at least 1 day")
        }
      }
      try {
        if (this.state.ModalState == "view") {
          await axios.post(
            `case/${this.state.caseId}/update_recommended_days/${planId}`,
            {case_aligner: this.state.aligners}
          )
          await this.props.showSnackbar(
            `Recommended days updated`,
            "success",
            true
          )
        } else {
          await axios.post(
            `case/${this.state.caseId}/treatment_plan/${planId}/approve`,
            {case_aligner: this.state.aligners}
          )
          await this.props.showSnackbar(
            `Treatment plan #${planId} approved`,
            "success",
            true
          )
        }
        this.props.history.push("/blank");
        this.props.history.replace(`/patients/${this.state.caseId}`)
      } catch (e) {
        this.setState({
          error: "Cannot change the status please connect with admin",
        });
        console.error(e.response);
      }
    }
  }

  render() {
    const approvedRefinement = []; //new Set([])
    this.state.treatment_plan.forEach((refinement, i) => {
      approvedRefinement.push(new Set([]));
      if (refinement) {
        refinement.forEach((plan) => {
          if (plan?.approved_flag) {
            approvedRefinement[i].add(plan.refinement_no);
          }
        });
      }
    });
    return (
      <Animated animationIn="fadeIn" isVisible={true}>
        <LoadingProgressBar
          ref={this.progressBarRef}
          open={this.state.loading}
        />
        <Grid container style={{ padding: 16, background: "#f2f2f2" }}>
          <Grid item xs={12}>
            <div
              style={{
                margin: "8px 0px",
              }}
            >
              <Link
                to="/"
                style={{
                  color: "black",
                }}
              >
                {this.get("breadcrumbs.caseManagement")}
              </Link>
              {` > ${this.get("breadcrumbs.patient")} #${
                this.props.match.params.id
              }`}
            </div>
          </Grid>

          {this.props.history.length > 1 && (
            <Button
              onClick={() => this.props.history.goBack()}
              variant="outlined"
              style={{
                margin: "8px 0px",
                minWidth: 128,
              }}
            >
              <ArrowBackIosIcon />
              {this.get(":const.back")}
            </Button>
          )}

          <Grid item xs={12}>
            {this.state.error && (
              <Alert severity="error" style={{ marginBottom: "30px" }}>
                {this.state.error}
              </Alert>
            )}
            {!Array.isArray(this.state.treatment_plan[0]) ||
            this.state.treatment_plan[0].length === 0 ? (
              <Grid container>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <p>{this.get("treatmentPlan.noTreatmentPlan")}</p>
                  <img
                    src={getWebAbsPath("/img/noData.png")}
                    style={{ width: "100%", maxWidth: 600 }}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <h3>{getFromObj(this.props.lang, 'dict.pages.treatmentapproval.latest_approved_plan')}</h3>
                  <Modal
                    className="modal"
                    modal="treatmentPlan"
                    title="Treatment Plan"
                    open={this.state.TreatmentModal && !this.state.loading}
                    handleClose={() => this.handleClose("treatment")}
                    handleDataSave={() => this.handleTreatmentSave()}
                    submitButton={true}
                    PhotoUploadLoadingStatus={
                      this.state.PhotoUploadLoadingStatus
                    }
                    modalError={this.state.modalError}
                  >
                    {
                      <Treatment
                        patientInfo={this.state.patientInfo}
                        treatmentPlan={this.state.treatmentPlan}
                        handleTreatmentData={(data, stateType, fileName) =>
                          this.handleTreatmentData(data, stateType, fileName)
                        }
                      />
                    }
                  </Modal>
                  {Object.keys(this.state.latestApprovedPlan).length != 0 ? (
                    <>
                      <TableContainer component={Paper}>
                        <FormControl className={externalCss.radioFieldset}>
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              style={{
                                backgroundColor: this.state
                                  .latestApprovedPlan?.["approved_flag"]
                                  ? "#a7d2a5"
                                  : "#c7c7c7",
                              }}
                            >
                              <div className={externalCss.plan}>
                                <p>

                                  {getFromObj(this.props.lang, 'dict.pages.treatmentapproval.treatment_plan_status').replace(/\{No[^\}]*\?\}/, this.state.latestApprovedPlan?.plan_no)}
                                  {this.state.latestApprovedPlan?.[
                                    "approved_flag"
                                  ]
                                    ? getFromObj(this.props.lang, 'dict.pages.treatmentapproval.approved')
                                    : getFromObj(this.props.lang, 'dict.pages.treatmentapproval.notapproved')}
                                </p>
                                
                                {(['ADMIN', 'SADMIN', 'DISTR'].includes(this.state.role)) &&
                                  this.state.latestApprovedPlan?.[
                                    "approved_flag"
                                  ]  && this.state.caseStatus === 'PLAN_APPROVED' && (
                                    <Button
                                      variant="outlined"
                                      onClick={() =>
                                        this.handleUndoApproval(
                                          this.state.latestApprovedPlan?.[
                                            "plan_id"
                                          ]
                                        )
                                      }
                                      style={{
                                        backgroundColor:
                                          !['ADMIN', 'SADMIN', 'DISTR'].includes(this.state.role)
                                            ? "#455793"
                                            : "#b14f4f",
                                        color: "white",
                                      }}
                                    >
                                      {this.get(
                                        "treatmentPlan.undoPlanApproval"
                                      )}
                                    </Button>
                                  )}
                              </div>
                            </Grid>
                          </Grid>
                        </FormControl>

                        <Table aria-label="simple table">
                          <TableHead>
                            {this.state.latestApprovedPlan?.["max_aligner_number"] > 0 ? (
                              <TableRow>
                                <TableCell
                                  colSpan={4}
                                  style={{ color: "#4d4dbf" }} 
                                  onClick={() =>
                                    this.handleOpen("recommended_days", "view", this.state.latestApprovedPlan)
                                  }
                                >
                                  {this.state.latestApprovedPlan?.["max_aligner_number"]} set{this.state.latestApprovedPlan?.["max_aligner_number"] == 1 ? "" : "s"} of aligner (Click to view recommended days)
                                </TableCell>
                              </TableRow>
                            ) : ""}
                            <TableRow>
                              <TableCell align="center">
                                {this.get("treatmentPlan.fileName")}
                              </TableCell>
                              <TableCell align="center">
                                {this.get("treatmentPlan.submissionDate")}
                              </TableCell>
                              <TableCell align="center">
                                {this.get("treatmentPlan.approved")}
                              </TableCell>
                              <TableCell align="center">
                                {this.get("treatmentPlan.approvedDate")}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>

                            {this.state.latestApprovedPlan?.["url"] && (
                              <TableRow>
                                <TableCell align="center">
                                  <a
                                    href={
                                     /^https?:\/\/modern/.test(this.state.latestApprovedPlan?.[
                                         "url"
                                       ])
                                        ? this.state.latestApprovedPlan?.["url"] +'&userid='+JSON.parse(localStorage.getItem('auth-token'))
                                        :
                                          this.state.latestApprovedPlan?.["url"]
                                    }
                                    target="_blank"
                                    style={{
                                      textDecoration: "none",
                                      color: "#4d4dbf",
                                    }}
                                  >
                                    {this.get(
                                      "treatmentPlan.view_3DSimulation"
                                    )}
                                  </a>
                                </TableCell>
                                <TableCell align="center">
                                  {Moment(
                                    this.state.latestApprovedPlan?.[
                                      "created_date"
                                    ]
                                  ).format("DD MMM YYYY")}
                                </TableCell>
                                <TableCell align="center">
                                  {this.state.latestApprovedPlan?.[
                                    "approved_flag"
                                  ] ? (
                                    <DoneIcon />
                                  ) : (
                                    <ErrorOutlineIcon />
                                  )}
                                </TableCell>
                                <TableCell align="center">
                                  {this.state.latestApprovedPlan?.[
                                    "approval_date"
                                  ]
                                    ? Moment(
                                        this.state.latestApprovedPlan?.[
                                          "approval_date"
                                        ]
                                      ).format("YYYY-MM-DD")
                                    : getFromObj(this.props.lang, 'dict.pages.treatmentapproval.notapproved')}
                                </TableCell>
                              </TableRow>
                            )}
                            {Object.keys(this.state.latestApprovedPlan).map(
                              (key1, index1) =>
                                (key1 === "pdf0_name" ||
                                  key1 === "pdf1_name" ||
                                  key1 === "video0_name" ||
                                  key1 === "video1_name") && (
                                  <TableRow>
                                    {key1 !== "url" ? (
                                      <TableCell align="center">
                                        <a
                                          href={join(
                                            ApiServerUrl,
                                            this.state.latestApprovedPlan?.[
                                              key1.replace("_name", "")
                                            ]
                                          )}
                                          target="_blank"
                                          style={{
                                            textDecoration: "none",
                                            color: "#4d4dbf",
                                          }}
                                        >
                                          {key1 == "pdf0_name" && getFromObj(this.props.lang, 'dict.pages.treatmentapproval.analysis')}
                                          {key1 == "pdf1_name" &&
                                            getFromObj(this.props.lang, 'dict.pages.treatmentapproval.treatment_plan')}
                                          {key1 == "video0_name" &&
                                            getFromObj(this.props.lang, 'dict.pages.treatmentapproval.buccal_view_video')}
                                          {key1 == "video1_name" &&
                                            getFromObj(this.props.lang, 'dict.pages.treatmentapproval.occlusal_view_video')}
                                        </a>
                                      </TableCell>
                                    ) : (
                                      ""
                                    )}

                                    <TableCell align="center">
                                      {Moment(
                                        this.state.latestApprovedPlan?.[
                                          "created_date"
                                        ]
                                      ).format("DD MMM YYYY")}
                                    </TableCell>
                                    <TableCell align="center">
                                      {this.state.latestApprovedPlan?.[
                                        "approved_flag"
                                      ] ? (
                                        <DoneIcon />
                                      ) : (
                                        <ErrorOutlineIcon />
                                      )}
                                    </TableCell>
                                    <TableCell align="center">
                                      {this.state.latestApprovedPlan?.[
                                        "approval_date"
                                      ]
                                        ? Moment(
                                            this.state.latestApprovedPlan?.[
                                              "approval_date"
                                            ]
                                          ).format("YYYY-MM-DD")
                                        : getFromObj(this.props.lang, 'dict.pages.treatmentapproval.notapproved')}
                                    </TableCell>
                                  </TableRow>
                                )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  ) : (
                    <Box
                      marginTop={8}
                      p={1}
                      border={1}
                      style={{ borderStyle: "dotted" }}
                    >
                      <p align="center">No approved plan yet</p>
                    </Box>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h3>{getFromObj(this.props.lang, 'dict.pages.treatmentapproval.all_treatment_plans_by_date')}</h3>
                  {this.state.treatment_plan
                    .slice(0)
                    .reverse()
                    .map((refinement, rindex, arr) => (
                      <ExpansionPanel
                        defaultExpanded={rindex == 0 ? true : false}
                      >
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography>
                            {" "}
                            {arr.length - rindex - 1 == 0
                              ? getFromObj(this.props.lang, 'dict.pages.treatmentapproval.first_production')
                              : getFromObj(this.props.lang, 'dict.pages.treatmentapproval.refinement')+ " " + (arr.length - rindex - 1)}
                          </Typography>
                        </ExpansionPanelSummary>
                        {refinement &&
                          refinement
                            .slice(0)
                            .reverse()
                            .map((row, index, plans) => (
                              <ExpansionPanelDetails>
                                <Box width="100%">
                                  <ExpansionPanel
                                    defaultExpanded={
                                      rindex == 0 && index == 0 ? true : false
                                    }
                                  >
                                    {/* <Typography>Refinement {index +1}</Typography> */}

                                    <ExpansionPanelSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      style={{
                                        backgroundColor: row["approved_flag"]
                                          ? "#a7d2a5"
                                          : "#c7c7c7",
                                      }}
                                    >
                                      {/* <Grid item xs={12}
                                        style={arr.length === 1 || rindex === 0 || index > 0 ? {display: 'none'} : {
                                          display: undefined,
                                          paddingTop: 30
                                        }}>
                                    {arr.length !== 1 && rindex !== 0 && index === 0 &&
                                    <hr style={{background: 'black', height: 2}}/>}
                                    <p style={{
                                      padding: '10px 20px',
                                      paddingBottom: '0px',
                                      fontWeight: 700,
                                      fontSize: 'larger'
                                    }}>Refinement #{rindex}</p>
                                  </Grid> */}
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        justify="space-between"
                                        container
                                      >
                                        <div
                                          className={externalCss.planUpdated}
                                        >
                                          {['DISTR', 'ADMIN', 'SADMIN'].includes(this.state.role)?
                                            <Box mr={1} boxShadow={0}>
                                              <Button
                                                style={{backgroundColor: "#000000", color: "#ffffff"}}
                                                size="small"
                                                variant="contained"
                                                disableElevation
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  this.setState({
                                                    currentTreatmentPlanIDforModal:
                                                      row["plan_id"],
                                                  });
                                                  this.handleOpen("treatment");
                                                }}
                                              >
                                                <EditIcon
                                                  style={{
                                                    fontSize: 20,
                                                    marginRight: "5px",
                                                  }}
                                                ></EditIcon>{" "}
                                                {getFromObj(this.props.lang, 'dict.pages.treatmentapproval.edit_plan')}
                                              </Button>
                                            </Box> : null}
                                          <p>

                                            {getFromObj(this.props.lang, 'dict.pages.treatmentapproval.treatment_plan_status').replace(/\{No[^\}]*\?\}/, row.plan_no ?? index + 1)}

                                            {row["approved_flag"]
                                              ? getFromObj(this.props.lang, 'dict.pages.treatmentapproval.approved')
                                              : getFromObj(this.props.lang, 'dict.pages.treatmentapproval.notapproved')}
                                          </p>
                                        </div>
                                        <div>


                                          {!['ADMIN', 'SADMIN', 'DISTR'].includes(this.state.role) &&
                                            !row["approved_flag"] && (this.state.patientInfo?.case_status ==='PLAN_UPLOADED') &&
                                            !approvedRefinement?.has?.(
                                              row.refinement_no
                                            ) &&
                                            !plans.some(
                                              (v) => v.approved_flag
                                            ) && (
                                              <Button
                                                variant="outlined"
                                                onClick={() =>
                                                  this.handleOpen("recommended_days", "edit", row)
                                                }
                                                style={{
                                                  backgroundColor:
                                                    !['ADMIN', 'SADMIN', 'DISTR'].includes(this.state.role)
                                                      ? "#455793"
                                                      : "#b14f4f",
                                                  color: "white",
                                                }}
                                              >
                                                {this.get(
                                                  "treatmentPlan.approvePlan"
                                                )}
                                              </Button>
                                            )}
                                        </div>
                                      </Grid>
                                    </ExpansionPanelSummary>

                                    <ExpansionPanelDetails
                                      style={{ padding: "0px" }}
                                    >
                                      <TableContainer component={Paper}>
                                        <Box
                                          display="flex"
                                          flexDirection="row-reverse"
                                        ></Box>
                                        <Table aria-label="simple table">
                                          <TableHead>
                                            {row["max_aligner_number"] > 0 ? (
                                              <TableRow>
                                                <TableCell
                                                  colSpan={4}
                                                  style={{ color: "#4d4dbf" }} 
                                                  onClick={() =>
                                                    this.handleOpen("recommended_days", "view", row)
                                                  }
                                                >
                                                  {row["max_aligner_number"]} set{row["max_aligner_number"] == 1 ? "" : "s"} of aligner (Click to view recommended days)
                                                </TableCell>
                                              </TableRow>
                                            ) : ""}
                                            <TableRow>
                                              <TableCell align="center">
                                                {this.get(
                                                  "treatmentPlan.fileName"
                                                )}
                                              </TableCell>
                                              <TableCell align="center">
                                                {this.get(
                                                  "treatmentPlan.submissionDate"
                                                )}
                                              </TableCell>
                                              <TableCell align="center">
                                                {this.get(
                                                  "treatmentPlan.approved"
                                                )}
                                              </TableCell>
                                              <TableCell align="center">
                                                {this.get(
                                                  "treatmentPlan.approvedDate"
                                                )}
                                              </TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {/* {Object.keys(row)[0]} */}
                                            {row["url"] && (
                                              <TableRow>
                                                <TableCell align="center">
                                                  <a
                                                    href={
                                                      !/^https?:\/\/.*uuid/.test(row["url"])
                                                        ? row["url"] + '&userid=' + JSON.parse(localStorage.getItem('auth-token'))
                                                        : 
                                                          row["url"]
                                                    }
                                                    target="_blank"
                                                    style={{
                                                      textDecoration: "none",
                                                      color: "#4d4dbf",
                                                    }}
                                                  >
                                                    {this.get(
                                                      "treatmentPlan.view_3DSimulation"
                                                    )}
                                                  </a>
                                                </TableCell>
                                                <TableCell align="center">
                                                  {Moment(
                                                    row["created_date"]
                                                  ).format("DD MMM YYYY")}
                                                </TableCell>
                                                <TableCell align="center">
                                                  {row["approved_flag"] ? (
                                                    <DoneIcon />
                                                  ) : (
                                                    <ErrorOutlineIcon />
                                                  )}
                                                </TableCell>
                                                <TableCell align="center">
                                                  {row["approval_date"]
                                                    ? Moment(
                                                        row["approval_date"]
                                                      ).format("YYYY-MM-DD")
                                                    : getFromObj(this.props.lang, 'dict.pages.treatmentapproval.notapproved')}
                                                </TableCell>
                                              </TableRow>
                                            )}
                                            {Object.keys(row).map(
                                              (key1, index1) =>
                                                (key1 === "pdf0_name" ||
                                                  key1 === "pdf1_name" ||
                                                  key1 === "video0_name" ||
                                                  key1 === "video1_name") && (
                                                  <TableRow>
                                                    {key1 !== "url" ? (
                                                      <TableCell align="center">
                                                        <a
                                                          href={row?.[
                                                            key1.replace(
                                                              "_name",
                                                              ""
                                                            )
                                                          ] ? join(
                                                            ApiServerUrl,
                                                            row[
                                                              key1.replace(
                                                                "_name",
                                                                ""
                                                              )
                                                            ]
                                                          ) : ''}
                                                          target="_blank"
                                                          style={{
                                                            textDecoration:
                                                              "none",
                                                            color: "#4d4dbf",
                                                          }}
                                                        >
                                                          {key1 ==
                                                            "pdf0_name" &&
                                                            getFromObj(this.props.lang, 'dict.pages.treatmentapproval.analysis')}
                                                          {key1 ==
                                                            "pdf1_name" &&
                                                            getFromObj(this.props.lang, 'dict.pages.treatmentapproval.treatment_plan')}
                                                          {key1 ==
                                                            "video0_name" &&
                                                            getFromObj(this.props.lang, 'dict.pages.treatmentapproval.buccal_view_video')}
                                                          {key1 ==
                                                            "video1_name" &&
                                                            getFromObj(this.props.lang, 'dict.pages.treatmentapproval.occlusal_view_video')}
                                                        </a>
                                                      </TableCell>
                                                    ) : (
                                                      ""
                                                    )}

                                                    <TableCell align="center">
                                                      {Moment(
                                                        row["created_date"]
                                                      ).format("DD MMM YYYY")}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                      {row["approved_flag"] ? (
                                                        <DoneIcon />
                                                      ) : (
                                                        <ErrorOutlineIcon />
                                                      )}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                      {row["approval_date"]
                                                        ? Moment(
                                                            row["approval_date"]
                                                          ).format("YYYY-MM-DD")
                                                        : getFromObj(this.props.lang, 'dict.pages.treatmentapproval.notapproved')}
                                                    </TableCell>
                                                  </TableRow>
                                                )
                                            )}
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                    </ExpansionPanelDetails>
                                  </ExpansionPanel>
                                </Box>
                              </ExpansionPanelDetails>
                            ))}
                      </ExpansionPanel>
                      // <Grid container>
                      // </Grid>
                      // </Grid>
                    ))}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>

        <Modal
          className="modal"
          modal='recommended_days'
          title={this.state.RecommendedDaysModalTitle}
          open={this.state.RecommendedDaysModal}
          modalSize="sm"
          submitButton={this.state.RecommendedDaysModalSubmitButton}
          submitButtonLabel={this.state.RecommendedDaysModalSubmitLabel}
          closeButtonLabel={this.state.RecommendedDaysModalCloseLabel}
          handleDataSave={this.saveRecommendedDays}
          handleClose={() => this.handleClose('recommended_days')}
          modalError={this.state.modalError}
        >
          <RecommendedDays
            selectedPlan={this.state.selectedPlan}
            setAligners={this.setAligners}
            updateRecommendedDays={this.updateRecommendedDays}
            aligners={this.state.aligners}
          />
          {this.state.approve_question}
        </Modal>
      </Animated>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    config: store.config.config,
    lang: store.lang,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showSnackbar: (message, variant, queue = false) =>
      dispatch(easySnackbar(message, variant, queue)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withLang(TreatmentApproval, "PatientInfo")));
