import '../../util/array'
import React, { useEffect, useState, useCallback, useImperativeHandle, forwardRef } from 'react'
import { usePrevious } from '../../util/react'
import Axios from '../../axios-default'
import { ScaleLoader } from 'halogenium';
import * as Three from 'three';
import {
  MeshLambertMaterial, Mesh, AmbientLight,
  Scene, PerspectiveCamera, WebGLRenderer,
  MeshPhongMaterial, VertexColors, DirectionalLight,
  Color, Group
} from 'three'
import { useRef } from 'react';
const OrbitControls = require('three-orbit-controls')(Three)
const StlLoader = require('three-stl-loader')(Three)

const defaultProps = {
  stls: [],
  width: 400,
  height: 400
}

function StlViewer(props, ref) {
  const { className, stls } = props

  const DEGREE = Math.PI / 180,
    COLOR = 0xa1abd0

  const [width, setWidth] = useState(defaultProps.width),
    [height, setHeight] = useState(defaultProps.wheightidth)

  const sceneRef = useRef(new Scene()),
    groupRef = useRef(new Group()),
    cameraRef = useRef(new PerspectiveCamera(
      35, (width || defaultProps.width) / (height || defaultProps.height), 1, 4000
    )),
    rendererRef = useRef(new WebGLRenderer({ antialias: true, alpha: true })),
    loaderRef = useRef(new StlLoader()),
    controlRef = useRef(null),
    containerRef = useRef(),
    renderLoopFlagRef = useRef(false),
    updatingSizeRef = useRef(false),
    processingRef = useRef(false),
    meshesRef = useRef({})

  // resize
  const updateResize = () => {
    if (!updatingSizeRef.current) {
      updatingSizeRef.current = true
      if (containerRef.current) {
        const [width, height] = [containerRef.current.clientWidth, containerRef.current.clientHeight]
        if (cameraRef.current) {
          cameraRef.current.aspect = width / height
          cameraRef.current.updateProjectionMatrix()
        }
        if (rendererRef.current) {
          rendererRef.current.setSize(width, height)
        }
        setWidth(width)
        setHeight(height)
      }
      updatingSizeRef.current = false
    }
  }

  // lights
  function addShadowedLight(x, y, z, color, intensity) {
    var directionalLight = new DirectionalLight(color, intensity);
    directionalLight.position.set(x, y, z);
    directionalLight.castShadow = true;
    let d = 1;
    directionalLight.shadow.camera.left = -d;
    directionalLight.shadow.camera.right = d;
    directionalLight.shadow.camera.top = d;
    directionalLight.shadow.camera.bottom = -d;
    directionalLight.shadow.camera.near = 1;
    directionalLight.shadow.camera.far = 4;
    directionalLight.shadow.mapSize.width = 1024;
    directionalLight.shadow.mapSize.height = 1024;
    directionalLight.shadow.bias = -0.005;
    sceneRef.current.add(directionalLight);
  }

  const setCameraPosition = (x, y, z) => {
    cameraRef.current.position.set(x, y, z)
    cameraRef.current.lookAt(new Three.Vector3(0, 0, 0))
  }

  // const loop = () => {
  //   if (renderLoopFlagRef.current && !updatingSizeRef.current) {
  //     rendererRef.current.render(sceneRef.current, cameraRef.current)
  //     requestAnimationFrame(loop)
  //   }
  // }

  const loop = () => {
    if (renderLoopFlagRef.current &&
      containerRef.current &&
      sceneRef.current &&
      cameraRef.current
    ) {
      rendererRef.current.render(sceneRef.current, cameraRef.current)
      requestAnimationFrame(loop)
    }
  }

  const prev = usePrevious({ stls, width, height })
  useEffect(() => {
    sceneRef.current.add(groupRef.current)
    sceneRef.current.background = new Color(0xEBEBEB)
    rendererRef.current.setClearColor(0x000000, 0)
    rendererRef.current.setPixelRatio(window.devicePixelRatio || 1)
    rendererRef.current.setSize(width || defaultProps.width, height || defaultProps.height)
    rendererRef.current.gammaInput = true
    rendererRef.current.gammaOutput = true

    setCameraPosition(0, 0, 100)

    sceneRef.current.add(new AmbientLight(0x808AB1));
    addShadowedLight(10, 10, 10, 0xCFCFCF, 1, sceneRef.current);
    addShadowedLight(-10, -10, -10, 0xCFCFCF, 1, sceneRef.current);

    if (containerRef.current) {
      updateResize()
    }
    ; (async () => {
      if (stls && !processingRef.current) {
        processingRef.current = true
        const stlSet = new Set(Array.ensureArray(stls))
        await [...stlSet].asyncForEach(async stl => {
          let { data } = await Axios.get(stl, {
            onDownloadProgress: (event) => {},
            withCredentials: true, responseType: 'arraybuffer'
          })
          let geometry = loaderRef.current.parse(data)
          geometry.computeFaceNormals()
          geometry.computeVertexNormals()
          let material = new MeshPhongMaterial({
            color: 0x687090,
            specular: 0x0A0A0A,
            shininess: 20,
            side: Three.DoubleSide
          })
          const mesh = new Mesh(geometry, material)
          mesh.castShadow = true
          mesh.receiveShadow = true
          mesh.material = material
          mesh.position.set(0, 0, 0)
          meshesRef.current[stl] = mesh
          groupRef.current.add(mesh)
        })
        if (!renderLoopFlagRef.current) {
          renderLoopFlagRef.current = true
          requestAnimationFrame(loop)
        }
        processingRef.current = false
      }
    })()

    return () => {
      renderLoopFlagRef.current = false
      const meshes = meshesRef.current
      Object.values(meshes).forEach(mesh => {
        mesh.geometry.dispose()
        mesh.material.dispose()
      })

      const renderer = rendererRef.current
      renderer.dispose()
      renderer.forceContextLoss()

      window.removeEventListener('resize', updateResize)
    }
  }, [])
  useEffect(() => {
    if (prev && stls) {
      const prevStlSet = new Set(prev && Array.ensureArray(prev.stls)),
        stlSet = new Set(Array.ensureArray(stls))
      let addedStls = [...stlSet].filter(v => !prevStlSet.has(v)),
        removedStls = [...prevStlSet].filter(v => !stlSet.has(v))
      removedStls.forEach(stl => {
        const mesh = meshesRef.current[stl]
        groupRef.current.remove(mesh)
        mesh.geometry.dispose()
        mesh.material.dispose()
        delete meshesRef.current[stl]
      })
      if (!processingRef.current) {
        processingRef.current = true
          ; (async () => {
            await [...addedStls].asyncForEach(async stl => {
              let { data } = await Axios.get(stl, { withCredentials: true, responseType: 'arraybuffer' })
              let geometry = loaderRef.current.parse(data)
              geometry.computeFaceNormals()
              geometry.computeVertexNormals()
              let material = new MeshPhongMaterial({
                color: 0x687090,
                specular: 0x0A0A0A,
                shininess: 20,
                side: Three.DoubleSide
              })
              const mesh = new Mesh(geometry, material)
              mesh.castShadow = true
              mesh.receiveShadow = true
              mesh.material = material
              mesh.position.set(0, 0, 0)
              groupRef.current.add(mesh)
              meshesRef.current[stl] = mesh
            })
            if (!renderLoopFlagRef.current) {
              renderLoopFlagRef.current = true
              requestAnimationFrame(loop)
            }
            processingRef.current = false
          })()
      }
    }
  }, [stls])

  const containerRefCallback = useCallback(node => {
    if (node) {
      containerRef.current = node
      controlRef.current = new OrbitControls(cameraRef.current, node)
      controlRef.current.enablePan = false
      node.replaceChild(rendererRef.current.domElement, node.firstChild)
      window.addEventListener('resize', updateResize)
      if (containerRef.current) {
        updateResize()
      }
    }
  }, [])

  useImperativeHandle(ref, () => ({
    allshow() {
      Object.values(meshesRef.current).map(mesh => mesh.traverse(obj => obj.visible = true))
    },
    upperOnly() {
      Object.values(meshesRef.current)[0].traverse(obj => obj.visible = true)
      Object.values(meshesRef.current)[1].traverse(obj => obj.visible = false)
    },
    lowerOnly() {
      Object.values(meshesRef.current)[1].traverse(obj => obj.visible = true)
      Object.values(meshesRef.current)[0].traverse(obj => obj.visible = false)
    },
    anter() {
      this.allshow()
      setCameraPosition(0, 0, 135)
    },
    maxil() {
      this.upperOnly()
      setCameraPosition(0, -150, 0)
    },
    mand() {
      this.lowerOnly()
      setCameraPosition(0, 150, 0)
    },
    right() {
      this.allshow()
      setCameraPosition(-105, 5, 90)
    },
    left() {
      this.allshow()
      setCameraPosition(105, -5, 90)
    }
  }))

  return (
    <>
      <div
        className={className}
        ref={containerRefCallback}
        style={{
          overflow: 'hidden',
          width: '100%',
          height: '700px'
        }}
      >
        <div
          style={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <ScaleLoader color='#00ccaa' size="16px" />
        </div>
      </div>
    </>
  )
}

export default forwardRef(StlViewer)