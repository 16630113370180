import React from 'react';
import styles from './index.module.css';
import frontend_config from '../../frontend_config';
import { getWebAbsPath } from '../../util/path';

const hostname = window.location.hostname

export default ({
  height,
  hideLogo = false,
  loadingMessage = null
}) => (
  <div className={styles.loader} style={{ height }}>
    <img
      alt="BeMe Main Logo"
      src={getWebAbsPath(frontend_config?.[hostname]?.logo_path)}
      style={{ width: 200, marginBottom: 200 }}
      hidden={hideLogo}
    />
    <div />
    <div />
    <div />
    <div />
    {loadingMessage == null ? "" : (<section style={{marginBottom:-100}}>{loadingMessage}</section>)}
  </div>
)
