import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { withRouter } from 'react-router-dom'
import { Fab, Button, TextField, Select, MenuItem, CircularProgress, FormControlLabel, Checkbox, Tooltip, IconButton } from '@material-ui/core/';
import Modal from '../../../components/Modal/modal'
import Grid from '@material-ui/core/Grid';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import MUIDataTable from "mui-datatables";
import EditIcon from '@material-ui/icons/Edit'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import axios from '../../../axios-default'
import { connect } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'
import AddIcon from "@material-ui/icons/Add";
import { easySnackbar } from '../../../store/Noti/noti.actions';

const CustomToolbar = ({ onClick }) => (
  <React.Fragment>
    <Tooltip title={"custom icon"}>
      <IconButton onClick={onClick}>
        <AddIcon />
      </IconButton>
    </Tooltip>
  </React.Fragment>
)

class AddressList extends Component {

  columns = [
    {
      name: "firstName",
      label: "First Name",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "lastName",
      label: "Last Name",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "country",
      label: "Country",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "stateProvince",
      label: "State/Province",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "city",
      label: "City",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "postcode",
      label: "Post Code",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "phoneNo",
      label: "Phone No",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "faxNo",
      label: "Fax No",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "address_1",
      label: "Address 1",
      options: {
        filter: true,
        sort: true,
      }
    },
    // {
    //   name: "address_2",
    //   label: "Address 2",
    //   options: {
    //     filter: true,
    //     sort: true,
    //   }
    // },
    {
      name: 'defaultFlag',
      label: 'Default',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ display: "flex", justifyContent: 'center', margin: '0px 4px', width: 40, height: 40 }}>
              {value ? <CheckIcon /> : <CloseIcon />}
            </div>
          );
        }
      }
    },
    {
      name: "id",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ display: "flex" }}>
              <Fab size="small" aria-label="add"
                style={{
                  backgroundColor: "#75a0de",
                  color: "white",
                  margin: "0px 4px"
                }}
                onClick={() => {
                  this.setState({
                    updatedAddress: Object.assign({}, value),
                    editModalOpen: true
                  })
                }}>
                <EditIcon />
              </Fab>
              <Fab size="small" aria-label="add"
                style={{
                  backgroundColor: "#da5851",
                  color: "white",
                  margin: "0px 4px"
                }}
                onClick={async () => {
                  if (window.confirm('Are you going to delete this address?')) {
                    try {
                      const { data } = await axios.delete(`case/address/${value.delivery_address_id}`)
                      if (data.success) {
                        this.setState({
                          loading: true
                        }, () => this.componentDidMount())
                      } else {
                        console.error(data.message)
                      }
                    } catch (err) {
                      console.error(err)
                      if (err.response && err.response.statusText === 'Unauthorized') {
                        localStorage.removeItem("auth-token");
                        localStorage.removeItem("expirationDate");
                        localStorage.removeItem("role");
                        this.props.history.push("/login")
                      }
                    }
                  }
                }}>
                <DeleteOutlineIcon />
              </Fab>
            </div>
          );
        }
      }
    },
  ]

  constructor(props) {
    super(props)
    this.state = {
      userId: this.props.match.params.id,
      editModalOpen: false,
      modalError: "",
      updatedAddress: {},
      loading: true
    }
  }

  async componentDidMount() {
    try {
      const { data } = await axios.get(`case/address?user_id=${this.state.userId}`)
      this.setState({
        address: data.address.sort((a, b) => a.delivery_address_id - b.delivery_address_id).map(address => ({
          firstName: address.first_name,
          lastName: address.last_name,
          country: this.props.res.countries[address.country_id].country_name,
          stateProvince: this.props.res.stateProvinces[address.state_province_id] && this.props.res.stateProvinces[address.state_province_id].name,
          city: address.city,
          postcode: address.postcode,
          phoneNo: address.phone_no,
          faxNo: address.fax_no,
          address_1: address.address_1,
          address_2: address.address_2,
          defaultFlag: address.default_flag,
          id: address
        })),
        loading: false
      })
    } catch (err) {
      console.error(err)
      if (err.response && err.response.statusText === 'Unauthorized') {
        localStorage.removeItem("auth-token");
        localStorage.removeItem("expirationDate");
        localStorage.removeItem("role");
        this.props.history.push("/login")
      }
    }
  }

  handleAddressUpdate = async () => {
    try {
      const { data } = await axios.post(`case/address`, this.state.updatedAddress)
      if (data.success) {
        await this.props.showSnackbar('Address inserted', 'success')
        this.setState({
          updatedAddress: {},
          editModalOpen: false
        })
        this.componentDidMount()
      }
    } catch (err) {
      console.error(err)
      if (err.response?.data?.invalid) {
        this.setState({
          modalError: `Please enter ${err.response.data.invalid.join(',')}`
        })
      } else if (err.response && err.response.statusText === 'Unauthorized') {
        localStorage.removeItem("auth-token");
        localStorage.removeItem("expirationDate");
        localStorage.removeItem("role");
        this.props.history.push("/login")
      } else {
        this.setState({
          modalError: err.response.data.message
        })
      }
    }
  }

  handleUpdate = (field, convertFunc) => ({ target }) => {
    this.setState(({ updatedAddress }) => ({
      updatedAddress: {
        ...updatedAddress,
        [field]: convertFunc? convertFunc(target) : target.value
      }
    }))
  }

  returnCountry = () => {
    return (
      Object.entries(this.props.res.countries).sort((a, b) => a[1].country_name.localeCompare(b[1].country_name)).map(([id, c]) => (
        <MenuItem value={id} key={id}>{c.country_name}</MenuItem>
      ))
    )
  }

  returnStateProvince = () => {
    return (
      Object.entries(this.props.res.stateProvinces).filter(e =>
        e[1].country_id == this.state.updatedAddress.country_id
      ).map(([id, s]) => (
        <MenuItem value={id} key={id}>{s.name}</MenuItem>
      ))
    )
  }

  addAddress = () => {
    this.setState({
      updatedAddress: {
        user_id: parseInt(this.props.match.params.id)
      },
      editModalOpen: true
    })
  }

  render() {
    const options = {
      download: false,
      filterType: "dropdown",
      responsive: "scroll",
      selectableRows: false,
      rowsPerPage: 30,
      rowsPerPageOptions: [15, 30, 50],
      customToolbar: () => (<CustomToolbar onClick={this.addAddress}/>)
    };
    return (
      <div>
        {this.state.loading ?
          <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress style={{ color: "rgb(6,26,46)" }} />
          </Grid> :
          <>
            <MUIDataTable
              title={"Address List"}
              data={this.state.address}
              columns={this.columns}
              options={options}
            />
            <Modal
              className="modal"
              modal='Address'
              title={this.state.updatedAddress.delivery_address_id? 'Modify Address' : 'Insert Address'}
              open={this.state.editModalOpen}
              handleClose={() => this.setState({ editModalOpen: false })}
              handleDataSave={() => this.handleAddressUpdate()}
              modalError={this.state.modalError}
              submitButton={true}
            >
              <Grid item xs={12} style={{ padding: 4 }}>
                <TextField
                  id="first_name"
                  label="First Name"
                  margin="normal"
                  style={{ width: "100%" }}
                  value={this.state.updatedAddress.first_name}
                  onChange={this.handleUpdate('first_name')}
                />
              </Grid>
              <Grid item xs={12} style={{ padding: 4 }}>
                <TextField
                  id="last_name"
                  label="Last Name"
                  style={{ width: "100%" }}
                  margin="normal"
                  value={this.state.updatedAddress.last_name}
                  onChange={this.handleUpdate('last_name')}
                />
              </Grid>


              <Grid item xs={12} style={{ padding: 4 }}>
                <p>Select country</p>
                <Select
                  onChange={this.handleUpdate('country_id')}
                  style={{ width: "100%" }}
                  value={this.state.updatedAddress.country_id}
                >
                  {this.returnCountry()}
                </Select>
              </Grid>

              {Object.values(this.props.res.stateProvinces).some(s => s.country_id == this.state.updatedAddress.country_id) ?
                <Grid item xs={12} style={{ padding: 4 }}>
                  <p>Select State</p>
                  <Select
                    onChange={this.handleUpdate('state_province_id')}
                    style={{ width: "100%" }}
                    value={this.state.updatedAddress.state_province_id}
                  >
                    {this.returnStateProvince()}
                  </Select>
                </Grid> :
                null}

              <Grid item xs={12} style={{ padding: 4 }}>
                <TextField
                  id="city"
                  label="City"
                  margin="normal"
                  style={{ width: "100%" }}
                  value={this.state.updatedAddress.city}
                  onChange={this.handleUpdate('city')}
                />
              </Grid>


              <Grid item xs={12} style={{ padding: 4 }}>
                <TextField
                  id="address_1"
                  label="Address"
                  margin="normal"
                  style={{ width: "100%" }}
                  onChange={this.handleUpdate('address_1')}
                  value={this.state.updatedAddress.address_1}
                />
              </Grid>

              <Grid item xs={12} style={{ padding: 4 }}>
                <TextField
                  id="postcode"
                  label="Zip/Postal Code"
                  margin="normal"
                  style={{ width: "100%" }}
                  value={this.state.updatedAddress.postcode}
                  onChange={this.handleUpdate('postcode')}
                />
              </Grid>


              <Grid item xs={12} style={{ padding: 4 }}>
                <TextField
                  id="phone_no"
                  label="Phone Number"
                  margin="normal"
                  style={{ width: "100%" }}
                  value={this.state.updatedAddress.phone_no}
                  onChange={this.handleUpdate('phone_no')}
                />
              </Grid>

              <Grid item xs={12} style={{ padding: 4 }}>
                <TextField
                  id="fax_no"
                  label="Fax Number"
                  margin="normal"
                  style={{ width: "100%" }}
                  value={this.state.updatedAddress.fax_no}
                  onChange={this.handleUpdate('fax_no')}
                />
              </Grid>

              <Grid item xs={12} style={{ padding: 4 }}>
                <FormControlLabel
                  control={<Checkbox checked={this.state.updatedAddress.default_flag} />}
                  label="Default"
                  labelPlacement="end"
                  onChange={this.handleUpdate('default_flag', target => target.checked)} />
              </Grid>
            </Modal>
          </>}
      </div>
    );
  }
}

AddressList.propTypes = {
}


const mapStateToProps = state => ({
  res: state.res
});

const mapDispatchToProps = dispatch => ({
  showSnackbar: (msg, variant, queue=false) => dispatch(easySnackbar(msg, variant, queue))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AddressList))