import * as actionTypes from './actionType';

const initialState = {
  user: {
    gender: "M",
    first_name: "",
    last_name: "",
    email:"",
    password: "",
    confirm_password: "",
    phone_no: "",
    fax_no: "",
    license_no: "",
    client_code: "",
    clinic_phone_no: "",
    clinic_website: "",
    country_id: 78,
    state_province_id: null,
    user_id: undefined,
    role: "",
    active_flag: true,
    log: []
  },
  role: {
    role: null,
    groups: [],
    admin_groups: [],
    distr_groups: [],
    clinics: [],
    distributors: [],
    nurses: [],
    doctors: [],
    user_sep: [],
    user_sub: [],
    clinic_sep: [],
    clinic_sub: [],
    group_sep: [],
    group_sub: [],
    admin_group_sep: [],
    distr_group_sep: [],
    distr_group_sub: []
  },
  countries: [],
  stateProvinces: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATING_ACCOUNT_INIT: {
      return {
        ...initialState,
        countries: action.payload.countries || state.countries,
        stateProvinces: action.payload.stateProvinces || state.stateProvinces,
        user: {
          ...initialState.user,
          ...action.payload.user
        },
        role: {
          ...initialState.role,
          ...action.payload.role
        }
      }
    }
    case actionTypes.UPDATING_ACCOUNT_RELOAD: {
      return {
        ...state,
        user: {
          ...initialState.user,
          ...action.payload.user
        },
        role: {
          ...initialState.role,
          ...action.payload.role
        }
      }
    }
    case actionTypes.UPDATING_ACCOUNT_USER: {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload
        }
      }
    }
    case actionTypes.UPDATING_ACCOUNT_ROLE: {
      if (!action.payload.role || action.payload.role == state.role.role) {
        return {
          ...state,
          role: {
            ...state.role,
            ...action.payload
          }
        }
      } else {
        return {
          ...state,
          role: {
            ...initialState.role,
            ...action.payload
          }
        }
      }
    }
  }
  return state;
};

export default reducer;