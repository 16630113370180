import { Button, CircularProgress, Divider, Grid, MenuItem, Paper, Select, Box, Tabs, Tab, AppBar, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import SendIcon from '@material-ui/icons/Send';
import MuiAlert from '@material-ui/lab/Alert';
import classNames from 'classnames';
import MaterialTable from 'material-table';
import React, { Component, forwardRef } from 'react';
import { Animated } from "react-animated-css";
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import axios, { safeGet } from '../axios-default';
import Url from 'url'
import join from 'url-join'
import { ApiServerUrl } from '../config.json'
import CaseStatChart from '../components/Admin/CaseStatChart';
import NewAccountChart from '../components/Admin/NewAccountChart';
import { withLang } from '../util/lang';
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader

import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
// import Carousel, { Dots } from '@brainhubeu/react-carousel'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import { easySnackbar } from '../store/Noti/noti.actions';
import { getWebAbsPath } from '../util/path';

// material ui and css stuff
// import { Grid } from '@material-ui/core'

const TableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
}

const TabPanel = ({ children, value, index, ...other }) => (
  <Typography
    component="div"
    role="tabpanel"
    hidden={value !== index}
    id={`full-width-tabpanel-${index}`}
    aria-labelledby={`full-width-tab-${index}`}
    {...other}
  >
    {value === index && <Box p={3}>{children}</Box>}
  </Typography>
)

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function addMonths(date, months) {
  date.setMonth(date.getMonth() + months);
  return date;
}

function addDays(date, days) {
  date.setDate(date.getDate() + days)
  return date
}

const styles = theme => ({
  "@global": {
    body: {
      background: "#fdfdfd",
    },
  },
  root: {
    flexGrow: 1,
  },
  main: {
    width: 'auto',
    display: 'block',
    marginLeft: "0px 24px",
    boxShadow: "-2px 6px 24px -9px rgba(166,130,166,1)",
    [theme.breakpoints.up(500 + 48)]: {
      width: 500,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    [theme.breakpoints.up(600)]: {
      marginTop: 12,
      marginBottom: "30px"
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  form: {
    width: '100%',
    marginTop: 8,
    textAlign: "left",
  },
  submit: {
    marginTop: "24px",
  },
  required: {
    '&:after': {
      content: `'*'`,
      color: "red",
      marginLeft: "3px"
    }
  },
  headerWrapper: {
    '& header': {
      boxShadow: 'none'
    }
  },
  textField: {
    // marginLeft: "8px",
    // marginRight: "8px",
    '& label.Mui-focused': {
      color: 'black',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'black',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey',
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
  },
  dense: {
    marginTop: "16px",
    marginLeft: "0px",
  },
  welcomeTitle: {
    margin: "16px 0px"
  },
  progress: {
    marginTop: "48px"
  },
  login: {
    backgroundColor: `#473a69 !important`,
    // color: Colors.backgroundSecond,
    marginTop: "32px"
  },
  loginLeft: {
    height: "100vh",
    backgroundPosition: "top center",
    backgroundSize: "cover"
  },
  devicesContainer: {
    padding: 16,
    background: "#f2f2f2",
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  }
})

class Account extends Component {
  constructor(props) {
    super(props)
    this.state = {
      startDateCase: addDays(new Date(), -7),
      endDateCase: new Date(),
      startDateAccount: new Date(),
      endDateAccount: new Date(),
      distributors: [],
      groups: [],
      clinics: [],
      current_distributor: "",
      current_group: "",
      current_clinic: "",

      gender: "M",
      title: "Dr.",
      first_name: "",
      last_name: "",
      initialEmail: '',
      email:"",
      phone_no: "",
      fax_no: "",
      license_no: "",
      clinic_phone_no: null,
      clinic_website: null,
      company_name: "",
      country_id: 1,
      state_province_id: 1,
      approved_flag: false,
      active_flag: true,
      countryList: [],
      stateProvinceList: [],
      errors: {},
      success: "",
      updateError: "",
      userId: JSON.parse(localStorage.getItem('auth-token')),
      role: JSON.parse(localStorage.getItem('role')),
      isUpdating: false,
      disabled: false,
      countriesId2Name: {},
      tabIndex: parseInt(this.props.match?.params?.tabIndex ?? 0),
      stateProvinceId2Name: {}
    }
    this.stopping = false
  }

  componentWillUnmount() {
    this.stopping = true
  }

  async componentDidMount() {
    try {
      let distributors = {}
      if (['ADMIN', 'SADMIN'].includes(this.state.role)) {
        distributors = (await axios.get(`user?role=DISTR`)).data.user;
      }

      if (!this.stopping) {
        this.setState({
          distributors,
          countriesId2Name: Object.fromEntries(Object.entries(this.props.countries).map(([ id, { country_name } ]) => [id, country_name])),
          stateProvinceId2Name: Object.fromEntries(Object.entries(this.props.stateProvinces).map(([ id, { name } ]) => [id, name]))
        })
      }

      const { address: deliveryAddresses } = await safeGet(`case/address`)

      let { data } = await axios.get(`user/${this.state.userId}`)
      // let clinic_phone_no = data.user.role.clinic.length > 0 ? data.user.role.clinic[0].phone_no : null

      let { data: { state_province: stateProvince } } = await axios.get(`res/state_province/${data.user.country_id}`)
      this.setState({ stateProvinceList: stateProvince, state_province_id: stateProvince.length? this.state.state_province_id : undefined })

      if (!this.stopping) {
        this.setState({
          addresses: deliveryAddresses,
          "gender": data.user.gender,
          "title": data.user.title,
          initialEmail: data.user.email,
          "email": data.user.email,
          "first_name": data.user.first_name,
          "last_name": data.user.last_name,
          "phone_no": data.user.phone_no,
          "fax_no": data.user.fax_no,
          "company_name": data.user.company_name,
          "country_id": data.user.country_id,
          "state_province_id": data.user.state_province_id ? data.user.state_province_id : 1,
          "license_no": data.user.license_no,
          "clinic_phone_no": data.user.clinic_phone_no,
          "clinic_website": data.user.clinic_website,
          "approved_flag": data.user.approved_flag,
          "active_flag": data.user.active_flag
        });
      }

    } catch (err) {
      console.error(err)
      if (err.response && err.response.statusText === 'Unauthorized') {
        localStorage.removeItem("auth-token");
        localStorage.removeItem("expirationDate");
        localStorage.removeItem("role");
        this.props.history.push("/login")
      }
    }

  }

  async submitHandler() {
    let errors = {}

    if (this.state.first_name.length == 0) {
      errors['first_name'] = "First Name is required"
    }
    if (this.state.last_name.length == 0) {
      errors['last_name'] = "Last Name is required"
    }
    if (this.state.license_no.length == 0) {
      errors['license_no'] = "License Number is required"
    }
    if (this.state.phone_no.length == 0) {
      errors['phone_no'] = "Phone Number is required"
    }
    if (this.state.stateProvinceList && this.state.stateProvinceList.length > 0 && !this.state.state_province_id) {
      errors['state_province_id'] = "State/Province is required"
    }
    if (Object.keys(errors).length > 0 && errors.constructor === Object) {
      this.setState({ errors: errors })
    }
    else {
      this.setState({ errors: "", isUpdating: true, disabled: true })
      try {
        let modifiedState = { ...this.state }

        delete modifiedState.countryList
        delete modifiedState.stateProvinceList
        delete modifiedState.errors
        delete modifiedState.approved_flag
        delete modifiedState.active_flag

        const {
          email, title, gender, first_name, last_name, phone_no, fax_no,
          license_no, country_id, state_province_id, active_flag,
          clinic_phone_no, clinic_website, company_name
        } = this.state

        await axios.post(`/user/${this.state.userId}`, {
          email, title, gender, first_name, last_name, phone_no, fax_no,
          license_no, country_id, state_province_id, active_flag,
          clinic_phone_no, clinic_website, company_name, profile: true
        })

        if (email === this.state.initialEmail) {
          this.setState({ success: "User Profile Was Updated Successfully", updateError: '' })
        } else {
          await this.props.showSnackbar('Please login again as email has been changed', 'success')
          setTimeout(() => this.props.history.replace('/login'), 3000)
          return
        }
      }
      catch (e) {
        console.error(e.response)
        this.setState({ success: '', updateError: e.response.data.message ?? "Something went wrong try again later!" })
      }

      this.setState({ isUpdating: false, disabled: false })
    }
  }

  async handleChangeData(stateName, value) {

    switch (stateName) {
      case 'gender':
        this.setState({ "gender": value });
        break;
      case 'title':
        this.setState({ "title": value });
        break;
      case 'first_name':
        this.setState({ "first_name": value });
        break;
      case 'last_name':
        this.setState({ "last_name": value });
        break;
      case 'email':
        this.setState({ "email": value });
        break;
      case 'phone_no':
        this.setState({ "phone_no": value });
        break;
      case 'fax_no':
        this.setState({ "fax_no": value });
        break;
      case 'license_no':
        this.setState({ "license_no": value });
        break;
      case 'company_name':
        this.setState({ "company_name": value });
        break;
      case 'clinic_phone_no':
        this.setState({ "clinic_phone_no": value });
        break;
      case 'clinic_website':
        this.setState({ "clinic_website": value });
        break;
      case 'country':
        this.setState({ "country_id": value });
        let { data: { state_province: stateProvince } } = await axios.get(`res/state_province/${value}`)
        this.setState({ stateProvinceList: stateProvince, state_province_id: stateProvince.length? this.state.state_province_id : undefined })
        break;
      case 'currentUserRole':
        this.setState({ "role": value });
        break;
      case 'changeUserStatus':
        this.setState({ "approved_flag": value });
        break;
      case 'state':
        this.setState({ "state_province_id": value });
        break;
    }
  }

  handleChangeStartCase = date => {
    this.setState({
      startDateCase: date
    });
  };

  handleChangeEndCase = date => {
    this.setState({
      endDateCase: date
    });
  };

  handleChangeStartAccount = date => {
    this.setState({
      startDateAccount: date
    });
  };

  handleChangeEndAccount = date => {
    this.setState({
      endDateAccount: date
    });
  };

  handleChangeSelectData(type, value) {
    switch (type) {
      case "distributor_id":
        this.setState({ current_distributor: value })
        break;
      case "group_id":
        this.setState({ current_group: value })
        break;
      case "clinic_id":
        this.setState({ current_clinic: value })
        break;
    }
  }

  reloadPage() {
    this.props.history.push('/blank')
    this.props.history.replace(`/account/${this.state.tabIndex}`)
  }

  async upsertAddress(data) {
    try {
      if (String.isBlank(data.first_name)) {
        throw new Error('Please enter first name')
      }
      if (String.isBlank(data.last_name)) {
        throw new Error('Please enter last name')
      }
      if (!data.country_id) {
        throw new Error('Please select country')
      }
      if (
        data.state_province_id &&
        this.props.stateProvinces[data.state_province_id].country_id === data.country_id
      ) {
        throw new Error('Please enter first name')
      }
      if (String.isBlank(data.city)) {
        throw new Error('Please enter city')
      }
      if (String.isBlank(data.address_1)) {
        throw new Error('Please enter address')
      }
      if (String.isBlank(data.postcode)) {
        throw new Error('Please enter post code')
      }
      if (String.isBlank(data.phone_no)) {
        throw new Error('Please enter phone number')
      }
      await axios.post(`/case/address`, data)
      if (data.delivery_address_id) {
        await this.props.showSnackbar('Address updated', 'success')
      } else {
        await this.props.showSnackbar('Address added', 'success')
      }
      this.reloadPage()
    } catch (e) {
      if (Array.isArray(e.response?.data?.invalid)) {
        this.props.showSnackbar(e.response.data.invalid.map(field => `Invalid field "${field}".`).join('\n'), 'error')
      } else {
        this.props.showSnackbar(e.response?.data?.message ?? e.message ?? e, 'error')
      }
      throw e
      console.error(e.response)
    }
  }

  getBanner = () => {
    if (this.props.config.banner && this.props.config.banner.length) {
      return this.props.config.banner.map(b => {
        const banner = {}
        if (typeof b === 'string') {
          banner.img = b
          banner.url = ''
        } else {
          banner.img = b.img
          banner.url = b.url
        }
        if (banner.img.startsWith('/file')) {
          banner.img = join(ApiServerUrl, banner.img)
        }
        return banner
      })
    } else {
      return [{ img: getWebAbsPath('/img/bannerSmall.jpg'), url: '' }]
    }
  }

  render() {
    const { classes } = this.props
    return (
      <Animated animationIn="fadeIn" isVisible={true}>
        <div className={classes.headerWrapper}>
          <AppBar position="static" style={{ marginTop: 32 }}>

            <Tabs
              variant="fullWidth"
              value={this.state.tabIndex}
              onChange={(_, v) => this.setState({ tabIndex: v })}
              aria-label="nav tabs"
              className="inkBar"
              // indicatorColor="primary"
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#593687"
                }
              }}
            >
              <Tab label={this.get('profileTabTitle')} />
              <Tab label={this.get('chartTabTitle')} />
              {!['ADMIN', 'SADMIN', 'DISTR'].includes(this.state.role) && <Tab label={this.get('addressTabTitle')} />}
            </Tabs>

          </AppBar>
        </div>

        <TabPanel value={this.state.tabIndex} index={0}>
          <Grid container>
            <Grid item xs={12} md={12} style={{ padding: "24px 16px 24px 8px" }}>
              <Paper>
                <Grid container style={{ padding: "16px 24px 24px" }}>
                  <Grid item xs={12}>
                    <Typography component="h1" variant="h4" className={classes.welcomeTitle}>
                      {this.get('detailsTitle')}
                      </Typography>
                    <Divider className="loginHr" />
                    <form className={classes.form}>
                      <p style={{ fontSize: 18, marginBlockEnd: 0 }}>{this.get(':fields.title')}</p>
                      <Select
                        disabled={this.state.disabled}
                        value={this.state.title}
                        onChange={(event) => this.handleChangeData("title", event.target.value)}
                        style={{
                          width: "100%"
                        }}
                      >
                        <MenuItem value="Prof.">{this.get(':fields.Prof')}</MenuItem>
                        <MenuItem value="Dr.">{this.get(':fields.Dr')}</MenuItem>
                        <MenuItem value="Mr.">{this.get(':fields.Mr')}</MenuItem>
                        <MenuItem value="Mrs.">{this.get(':fields.Mrs')}</MenuItem>
                          <MenuItem value="Ms.">{this.get(':fields.Ms')}</MenuItem>
                        <MenuItem value="Miss.">{this.get(':fields.Miss')}</MenuItem>
                      </Select>

                      <Grid container>
                        <Grid item xs={6} style={{ paddingRight: 4 }}>
                          <p style={{ fontSize: 18, marginBlockEnd: 0 }} className={classNames(classes.required)}>{this.get(':fields.firstName')}</p>
                          <TextField
                            className={classNames(classes.textField, classes.dense)}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            autoFocus
                            value={this.state.first_name}
                            onChange={(event) => this.handleChangeData("first_name", event.target.value)}
                          />
                        </Grid>
                        <Grid item xs={6} style={{ paddingLeft: 4 }}>
                          <p style={{ fontSize: 18, marginBlockEnd: 0 }} className={classNames(classes.required)}>{this.get(':fields.lastName')}</p>
                          <TextField
                            className={classNames(classes.textField, classes.dense)}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            autoFocus
                            value={this.state.last_name}
                            onChange={(event) => this.handleChangeData("last_name", event.target.value)}
                          />
                        </Grid>
                      </Grid>

                      <p style={{ fontSize: 18, marginBlockEnd: 0 }} className={classNames(classes.required)}>{this.get(':fields.email')}</p>
                      <TextField
                        className={classNames(classes.textField, classes.dense)}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        autoFocus
                        value={this.state.email}
                        onChange={(event) => this.handleChangeData("email", event.target.value)}
                      />
                      <p style={{ fontSize: 18, marginBlockEnd: 0 }} className={classNames(classes.required)}>{this.get(':fields.licenseNo')}</p>
                      <TextField
                        className={classNames(classes.textField, classes.dense)}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        value={this.state.license_no}
                        onChange={(event) => this.handleChangeData("license_no", event.target.value)}
                      />

                      <Grid container>
                        <Grid item xs={6} style={{ paddingRight: 4 }}>
                          <p style={{ fontSize: 18, marginBlockEnd: 0 }} className={classNames(classes.required)}>{this.get(':fields.phoneNo')}</p>
                          <TextField
                            className={classNames(classes.textField, classes.dense)}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={this.state.phone_no}
                            onChange={(event) => this.handleChangeData("phone_no", event.target.value)}
                          />
                        </Grid>
                        <Grid item xs={6} style={{ paddingLeft: 4 }}>
                          <p style={{ fontSize: 18, marginBlockEnd: 0 }}>{this.get(':fields.faxNo')}</p>
                          <TextField
                            className={classNames(classes.textField, classes.dense)}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            value={this.state.fax_no}
                            onChange={(event) => this.handleChangeData("fax_no", event.target.value)}
                          />
                        </Grid>
                      </Grid>

                      <p style={{ fontSize: 18, marginBlockEnd: 0 }}>{this.get(':fields.companyName')}</p>
                      <TextField
                        className={classNames(classes.textField, classes.dense)}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        value={this.state.company_name}
                        onChange={(event) => this.handleChangeData("company_name", event.target.value)}
                      />

                      <p style={{ fontSize: 18, marginBlockEnd: 0 }}>{this.get(':fields.clinicPhoneNo')}</p>
                      <TextField
                        className={classNames(classes.textField, classes.dense)}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        value={this.state.clinic_phone_no}
                        onChange={(event) => this.handleChangeData("clinic_phone_no", event.target.value)}
                      />

                      <p style={{ fontSize: 18, marginBlockEnd: 0 }}>{this.get(':fields.clinicWebsite')}</p>
                      <TextField

                        className={classNames(classes.textField, classes.dense)}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        value={this.state.clinic_website}
                        onChange={(event) => this.handleChangeData("clinic_website", event.target.value)}
                      />

                      <p style={{ fontSize: 18, marginBlockEnd: 0 }} className={classNames(classes.required)}>{this.get(':fields.country')}</p>
                      <Select disabled={this.state.disabled}
                        value={this.state.country_id}
                        onChange={(event) => this.handleChangeData("country", event.target.value)}
                        style={{
                          width: "100%"
                        }}
                      >
                        {
                          Object.values(this.props.countries).map(v => (
                            <MenuItem value={v.country_id} key={v.country_id}>{v.country_name}</MenuItem>
                          ))
                        }
                      </Select>

                      {/* <p className={classNames(classes.required)} style={{ fontSize: 18, marginBlockEnd: 0 }} >{this.get(':fields.stateProvince')}</p>
                      <Select
                        disabled={this.state.disabled}
                        onChange={(event) => this.handleChangeData("state", event.target.value)}
                        style={{ width: "100%" }}
                        value={this.state.state_province_id}
                      >
                        {this.returnState()}
                      </Select> */}

                      {!!this.state.stateProvinceList?.length &&
                        <>
                        <p className={classes.required} style={{ fontSize: 18, marginBlockEnd: 0 }}>{this.get(':fields.stateProvince')}</p>
                        <Select
                          disabled={this.state.disabled}
                          value={this.state.state_province_id}
                          onChange={(event) => this.handleChangeData("state", event.target.value)}
                          style={{
                            width: "100%"
                          }}
                        >
                          {
                            this.state.stateProvinceList.map(v => (
                              <MenuItem value={v.state_province_id} key={v.state_province_id}>{v.name}</MenuItem>
                            ))
                          }
                        </Select>
                        </>}

                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.login}
                        onClick={this.submitHandler.bind(this)}
                        style={{ marginBottom: 16, maxWidth: 300 }}
                      >
                        {
                          this.state.isUpdating ? <CircularProgress style={{ color: "white" }} /> :
                            <div style={{ color: "white" }}>{this.get(':const.update')}<SendIcon style={{ margin: "0px 8px", verticalAlign: "middle", color: "white" }} /></div>
                        }
                      </Button>

                      {this.state.updateError && <Alert severity="error" style={{ marginBottom: "30px" }}>{this.state.updateError}</Alert>}

                      {this.state.success && <Alert severity="success" style={{ marginBottom: "30px" }}>{this.state.success}</Alert>}

                      {<ul className={classes.errors}>
                        {
                          Object.keys(this.state.errors).map((key) => (
                            <li className={classes.errorlist}>{this.state.errors[key]}</li>
                          ))
                        }
                      </ul>}
                    </form>
                  </Grid>
                </Grid>
              </Paper>

              {/* </main> */}
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={this.state.tabIndex} index={1}>
          <Grid container>
            <Grid item xs={12} md={12} style={{ padding: 8 }}>
              {
                ['ADMIN', 'SADMIN', 'DISTR'].includes(this.state.role) &&
                  <NewAccountChart
                    groups={Object.values(this.props.groups)}
                    distributors={this.state.distributors}
                    clinics={Object.values(this.props.clinics)}
                    distributor_id={this.state.current_distributor}
                    group_id={this.state.current_group}
                    clinic_id={this.state.current_clinic}
                    startDate={this.state.startDateAccount}
                    endDate={this.state.endDateAccount}
                    handleChangeSelect={(type, value) => this.handleChangeSelectData(type, value)}
                    handleChangeStart={(date) => this.handleChangeStartAccount(date)}
                    handleChangeEnd={(date) => this.handleChangeEndAccount(date)} />
              }

              {
                !['ADMIN', 'SADMIN', 'DISTR'].includes(this.state.role) &&
                  <CaseStatChart
                    user_id={JSON.parse(localStorage.getItem('auth-token'))}
                    country_id={this.state.country_id}
                    startDate={this.state.startDateCase}
                    endDate={this.state.endDateCase}
                    handleChangeStart={(date) => this.handleChangeStartCase(date)}
                    handleChangeEnd={(date) => this.handleChangeEndCase(date)}
                  />
              }

              {/*<TotalSalesChart
              country_id={this.state.country_id}
              startDate={this.state.startDateSales}
              endDate={this.state.endDateSales}
              handleChangeStart={(date)=>this.handleChangeStartSales(date)}
              handleChangeEnd={(date)=>this.handleChangeEndSales(date)}
              />*/}
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={this.state.tabIndex} index={2}>
          <Grid container>
            <MaterialTable
              title={this.get('addressTitle')}
              style={{width: '100%'}}
              columns={[
                {
                  field: 'first_name',
                  title: this.get(':fields.firstName')
                },
                {
                  field: 'last_name',
                  title: this.get(':fields.lastName')
                },
                {
                  field: 'country_id',
                  title: this.get(':fields.country'),
                  lookup: this.state.countriesId2Name
                },
                {
                  field: 'state_province_id',
                  title: this.get(':fields.stateProvince'),
                  lookup: this.state.stateProvinceId2Name,
                  lookupWhenEdit: data => Object.fromEntries(
                    Object.entries(this.props.stateProvinces)
                          .filter(([_, v]) => v.country_id == data.country_id)
                          .map(([k, v]) => [k, v.name])
                  )
                },
                {
                  field: 'city',
                  title: this.get(':fields.city')
                },
                {
                  field: 'address_1',
                  title: this.get(':fields.address'),
                  cellStyle: {
                    width: 800
                  }
                },
                {
                  field: 'postcode',
                  title: this.get(':fields.postCode')
                },
                {
                  field: 'phone_no',
                  title: this.get(':fields.phoneNo')
                },
                {
                  field: 'fax_no',
                  title: this.get(':fields.faxNo')
                },
              ]}
              editable={{
                onRowAdd: newData => this.upsertAddress(newData),
                onRowUpdate: newData => this.upsertAddress(newData),
                onRowDelete: async oldData => {
                  try {
                    await axios.delete(`case/address/${oldData.delivery_address_id}`)
                    this.props.showSnackbar('Address deleted', 'success', true)
                    this.reloadPage()
                  } catch (err) {
                    this.props.showSnackbar('Unable to delete this address', 'error')
                  }
                }
              }}
              data={this.state.addresses}
              isLoading={!this.state.addresses}
              icons={TableIcons}
              options={{
                initialPage: 0,
                emptyRowsWhenPaging: false,
                exportButton: false,
                exportAllData: true,
                debounceInterval: 700,
                columnsButton: true,
                pageSize: 10,
                pageSizeOptions: [10, 15, 30],
                minBodyHeight: 700,
                // maxBodyHeight: 1000,
              }}
            />
          </Grid>
        </TabPanel>
      </Animated>
    )
  }
}

const mapStateToProps = store => {
  return {
    countries: store.res.countries,
    stateProvinces: store.res.stateProvinces,
    clinics: store.res.clinics,
    groups: store.res.groups,
    lang: store.lang,
    config: store.config.config
  }
};

const mapDispatchToProps = dispatch => ({
  showSnackbar: (message, variant, queue=false) => dispatch(easySnackbar(message, variant, queue)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(withLang(Account, 'Account'))))
