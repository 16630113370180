import { useSnackbar } from 'notistack';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeSnackbar } from '../store/Noti/noti.actions';


const Notifier = () => {
	const dispatch = useDispatch()
	const { notifications, queuedNotifications } = useSelector(
	  store => ({
      notifications: store.noti.notifications || [],
      queuedNotifications: store.noti.queuedNotifications || []
    })
	)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const displayed = useRef([])

	const storeDisplayed = (id) => {
    if (displayed.current) displayed.current = [...displayed.current, id]
	}

	const removeDisplayed = (id) => {
		if (displayed.current) displayed.current = displayed.current.filter(key => id !== key)
  }

	React.useEffect(() => {
		notifications.filter(n => !displayed.current.includes(n.key)).forEach(({ key, message, options = {}, dismissed = false }) => {
			if (dismissed) {
				// dismiss snackbar using notistack
				closeSnackbar(key)
				return
      }

			// do nothing if snackbar is already displayed
			if (displayed.current.includes(key)) return

			// display snackbar using notistack
			enqueueSnackbar(message, {
				key,
				...options,
				onClose: (event, reason, myKey) => {
					if (options.onClose) {
						options.onClose(event, reason, myKey)
					}
				},
				onExited: (event, myKey) => {
					// removen this snackbar from redux store
					dispatch(removeSnackbar(myKey))
					removeDisplayed(myKey)
				},
			})

			// keep track of snackbars that we've displayed
			storeDisplayed(key)
		})
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch])

  React.useEffect(() => {
		queuedNotifications.filter(n => !displayed.current.includes(n.key)).forEach(({ key, message, options = {}, dismissed = false }) => {
			if (dismissed) {
				// dismiss snackbar using notistack
				closeSnackbar(key)
				return
      }

      // display snackbar using notistack
      enqueueSnackbar(message, {
        key,
        ...options,
        onClose: (event, reason, myKey, ...args) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey)
          }
        },
        onExited: (event, myKey, ...args) => {
          // removen this snackbar from redux store
          dispatch(removeSnackbar(myKey))
          removeDisplayed(myKey)
        },
      })

			// keep track of snackbars that we've displayed
			storeDisplayed(key)
    })
  }, [window.location.pathname, closeSnackbar, enqueueSnackbar, dispatch])

	return null
}

export default Notifier
