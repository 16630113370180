import { Button, ClickAwayListener, Grid, Grow, Hidden, IconButton, Menu, MenuItem, MenuList, Paper, Popper, SwipeableDrawer } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import LanguageIcon from '@material-ui/icons/Language';
import MenuIcon from '@material-ui/icons/Menu';
import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link, withRouter } from 'react-router-dom';
import { sendMessage2ServiceWorker } from '../util/service_worker'
import axios from '../axios-default';
import * as authAction from '../store/Auth/Auth.action';
import { loadLang } from '../store/lang/lang.action';
import { withLang } from '../util/lang';
import externalCss from './nav.module.css';
import frontend_config from '../frontend_config';
import { getWebAbsPath } from '../util/path';

const hostname = window.location.hostname
const styleMenuItem={
              padding: "8px 16px",
              background: frontend_config?.[hostname]?.main_background_color,
      }
const styleMenuItemEmpty={ }
const styles = theme => ({
  appBar: {
    // backgroundColor: "#30353b",
    // [theme.breakpoints.down('sm')]:{
    height: "88px"
    // },
  },
  toolbar: {
    backgroundColor: "white",
    padding: "16px 8px",
  },
  logo: {
    width: 148,
    [theme.breakpoints.down('sm')]: {
      width: 148,
      height: "auto",
      padding: "10px 0px"
    },
  },
  logoLink: {
    textDecoration: "unset",
    marginRight: "16px",
    marginTop: "2px",
    display: "inline-block",
    textAlign: "center"
  },
  sideLogo: {
    width: "80%",
    padding: "12px 0px",
  }
})

class Nav extends Component {
  constructor(props) {
    super(props)
    this.state = {
      left: false,
      toggleMenu: false,
      loggedEmail: "",
      userInfo: {
        name: ""
      },
      anchorEl: null,
      role: JSON.parse(localStorage.getItem('role')),
      userId: JSON.parse(localStorage.getItem('auth-token')),
      langAnchorEl: null
    }
  }

  toggleDrawer() {
    this.setState({ toggleMenu: !this.state.toggleMenu });
  };
  swipeableDrawerOpen() {
    this.setState({ toggleMenu: !this.state.toggleMenu });
  }

  async componentDidMount() {
    let { data } = await axios.get(`user/${this.state.userId}`)
    let newUserInfo = { ...this.state.userInfo }
    const honorific = data.user.user_id === 1? '' : this.get(`:fields.${data.user.title.replace('.', '')}`)
    newUserInfo['name'] = this.get(`:role.${this.state.role}`) + ", " + honorific + ' ' + data.user.last_name

    this.setState({ userInfo: newUserInfo })
  }

  // returnPages() {
  //   return pages.map(v=> {
  //     return(
  //       <Link to={v.linkTo} key={v.name} style={{padding: "10px 0px"}}>
  //         <Button>
  //           {v.name}
  //         </Button>
  //       </Link>
  //     )
  //   })
  // }

  // toggleDrawer(side, open){
  //   return (
  //     () => {
  //       this.setState({
  //         [side]: open,

  //       })
  //     }
  //   )
  //    // console.log(open)
  // }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleLogout = async () => {
    // console.log("before logout",this.props.isAuthenticated)
    this.setState({ anchorEl: null });
    await this.props.logOut();
    sendMessage2ServiceWorker({
      type: 'logout'
    })
    // logoutResponse.then(v=>{
    //   console.log(v)
    // })
    //console.log("after logout",this.props.isAuthenticated,logoutResponse)
    //window.location.reload()
  }

  handleLangChange = langName => async () => {
    const userId = JSON.parse(localStorage.getItem('auth-token'))
    if (userId) {
      await axios.post('config', { user_id: userId, config: JSON.stringify({ lang: langName }) })
    }
    await this.props.loadLang(langName)
    this.setState({ langAnchorEl: null })
  }

  getButtonLinks = isMobile => {
    const { classes } = this.props

    return (
      <>
        <Link to="/" className={classes.logoLink}>
          <Button
            className={this.props.history.location.pathname === "/" ||
              this.props.history.location.pathname.includes("/clinicalSimulation") ||
              this.props.history.location.pathname.includes("/patients") ?
              externalCss.navlinkBtnActive : externalCss.navlinkBtn}
          >
            {this.get('caseManagement')}
          </Button>
        </Link>

        <Link to="/account" className={classes.logoLink}>
          <Button className={this.props.history.location.pathname.includes("/account")
            ? externalCss.navlinkBtnActive
            : externalCss.navlinkBtn}>
            {this.get('accountSummary')}
          </Button>
        </Link>

        <Link to="/support" className={classes.logoLink}>
          <Button className={this.props.history.location.pathname.includes("/support") ? externalCss.navlinkBtnActive : externalCss.navlinkBtn}>
            {this.get('supportAndResources')}
          </Button>
        </Link>

        {isMobile &&
          <>
            <Link to="/changePassword" className={classes.logoLink}>
              <Button className={this.props.history.location.pathname.includes("/changePassword")
                ? externalCss.navlinkBtnActive
                : externalCss.navlinkBtn}>
                {this.get('changePassword')}
              </Button>
            </Link>

            {["ADMIN", "SADMIN", "DISTR"].includes(this.state.role) &&
              <Link to="/admin/dashboard" className={classes.logoLink}>
                <Button className={this.props.history.location.pathname.includes("/admin/dashboard")
                  ? externalCss.navlinkBtnActive
                  : externalCss.navlinkBtn}>
                  {this.get('dashboard')}
                </Button>
              </Link>
            }
          </>
        }
      </>
    )
  }

  render() {
    const { classes } = this.props
    const windowLoc = window.location.href;
    console.log(windowLoc.indexOf('clinicalSimulation'))
    console.log('aa')
    return (
      <div id="navigationBar">

        <Hidden xsDown>
          <Grid container style={{ paddingTop: 16 }}>

            <Grid item xs={6} style={{ paddingLeft: 16 }}>
              <Link to="/" className={classes.logoLink}>
                <img
                  alt="BeMe Main Logo"
                  src={ getWebAbsPath(frontend_config?.[hostname]?.logo_path)}
                  className={classes.logo}
                />
              </Link>
            </Grid>

            <Grid item xs={6} style={{ textAlign: "right", paddingTop: 6 }}>
              <p
                style={{
                  display: "contents"
                }}
              >
                {this.get('welcome') + ' ' + this.state.userInfo.name}
              </p>

              <IconButton
                color="inherit"
                aria-owns={this.state.anchorEl ? 'simple-menu' : undefined}
                aria-haspopup="true"
                onClick={this.handleClick}
              >
                <AccountBoxIcon style={{ color: frontend_config?.[hostname]?.main_color_1 }} />
              </IconButton>

              <IconButton
                color="inherit"
                aria-owns={this.state.anchorEl ? 'simple-menu' : undefined}
                aria-haspopup="true"
                onClick={({ currentTarget }) => this.setState({ langAnchorEl: currentTarget })}
              >
                <LanguageIcon style={{ color: `${frontend_config?.[hostname]?.main_color_1}` }} />
              </IconButton>

               <Menu
                id="simple-menu"
                anchorEl={this.state.anchorEl}
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleClose}
              >
                <MenuItem style={{ fontWeight: 300 }} onClick={this.handleClose}>

                  <Link to="/account" style={{ textDecoration: "none", color: "black", width: "100%" }}>{this.get('myAccount')}</Link>
                </MenuItem>

                <MenuItem style={{ fontWeight: 300 }} onClick={this.handleClose}>
                  <Link to="/changePassword" style={{ textDecoration: "none", color: "black", width: "100%" }}>{this.get('changePassword')}</Link>
                </MenuItem>

                {["ADMIN", "SADMIN", "DISTR"].includes(this.state.role) &&
                  <MenuItem style={{ fontWeight: 300 }} onClick={this.handleClose}>
                    <Link to="/admin/dashboard" style={{ textDecoration: "none", color: "black", width: "100%" }} target="_blank">{this.get('dashboard')}</Link>
                  </MenuItem>
                }

                <MenuItem style={{ fontWeight: 300 }} onClick={this.handleLogout}>
                  <div>{this.get('logout')}</div>
                </MenuItem>
              </Menu>
            </Grid>

          </Grid>
        </Hidden>

        <Popper
          open={Boolean(this.state.langAnchorEl)}
          anchorEl={this.state.langAnchorEl}
          role={undefined}
          style={{zIndex: 999}}
          placement="bottom"
          transition
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center bottem' : 'center top' }}
            >
              <Paper style={{overflow: 'auto', maxHeight: '30vh'}}>
                <ClickAwayListener onClickAway={() => this.setState({ langAnchorEl: null })}>
                  <MenuList autoFocusItem={Boolean(this.state.langAnchorEl)} id="lang-menu">
                    {this.props.lang.avail.map(langName => (
                      <MenuItem onClick={this.handleLangChange(langName)}>{langName}</MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>

        <Grid container>
        <Grid
          item xs={12}
            style={{
              padding: "8px 16px",
              background: frontend_config?.[hostname]?.main_background_color,
            }}
        >
          {(windowLoc.indexOf('clinicalSimulation')) >=0?
          <></>:
            <div className={externalCss.linkWrapper}>{this.getButtonLinks()}</div>
          }


            <Hidden smUp>
              <Link to="/">
                <img
                  alt="BeME White Logo"
                  src={getWebAbsPath(frontend_config?.[hostname]?.white_logo_path)}
                  style={{
                    width: 108,
                    marginTop: 8
                  }}
                />
              </Link>
            </Hidden>

            <div className={externalCss.linkWrapperMobile}>
              <Button onClick={() => this.toggleDrawer()}><MenuIcon style={{ fill: "white" }} /></Button>
            </div>
            <div className={externalCss.linkWrapperMobile}>
              <Button
                onClick={({ currentTarget }) => this.setState({ langAnchorEl: currentTarget })}
              >
                <LanguageIcon style={{ color: "white" }} />
              </Button>
            </div>

            <SwipeableDrawer
              anchor='left'
              open={this.state.toggleMenu}
              onClose={() => this.toggleDrawer()}
              disableSwipeToOpen={false}
              onOpen={() => this.swipeableDrawerOpen()}>
              <Link to="/" style={{ textAlign: "center", borderBottom: "solid 2px #efefef" }}>
                <img
                  alt="BeMe Main Logo"
                  src={ getWebAbsPath(frontend_config?.[hostname]?.logo_path)}
                  style={{
                    width: 188
                  }}
                />
              </Link>

              {this.getButtonLinks(true)}

              <Button onClick={this.handleLogout}>{this.get('logout')}</Button>

            </SwipeableDrawer>
          </Grid>
        </Grid>
      </div>

    )
  }
}


const mapStateToProps = store => {
  return {
    isAuthenticated: store.auth.isAuthenticated,
    lang: store.lang
  }
};

const mapDispatchToProps = dispatch => ({
    logOut: () => dispatch(authAction.logOut()),
    loadLang: langName => dispatch(loadLang(langName))
});


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(withLang(Nav, 'Nav'))))
