import axios from '../../axios-default';
import { uiStartLoading, uiStopLoading } from "../loading/actions";
import {
  NEW_PATIENT_DATA_SAVE_FAILURE, NEW_PATIENT_DATA_SAVE_SUCCESS,

  NEW_PATIENT_DATA_UPDATE_FAILURE, NEW_PATIENT_DATA_UPDATE_SUCCESS,

  NEW_PATIENT_TEMP_SAVE_SUCCESS
} from "./actionType";


const saveSuccess = payload => ({
  type: NEW_PATIENT_DATA_SAVE_SUCCESS,
  payload
});

const TempSaveSuccess = payload => ({
  type: NEW_PATIENT_TEMP_SAVE_SUCCESS,
  payload
});

const saveFailure = payload => ({
  type: NEW_PATIENT_DATA_SAVE_FAILURE,
  payload
});

const updateSuccess = payload => ({
  type: NEW_PATIENT_DATA_UPDATE_SUCCESS,
  payload
});

const updateFailure = payload => ({
  type: NEW_PATIENT_DATA_UPDATE_FAILURE,
  payload
});

export const save = (newPatientData) => async dispatch => {
  // console.log("before saving ", newPatientData)
  dispatch(uiStartLoading());
  try {
    let { data } = await axios.post('case', newPatientData)

    // if(newPatientData['cbct_files'].length>0)
    // {
    //     let cbct_files = newPatientData['cbct_files']
    //     cbct_files.map(v=>{
    //       v['case_id'] = data.result.case_id
    //     })
    //     console.log(cbct_files)
    //     let { data:cbct } = await axios.post(`case/cbct/${data.result.case_id}`, {case_cbct:cbct_files})
    //     console.log(cbct)
    // }
    dispatch(saveSuccess(data));
    dispatch(uiStopLoading());
  } catch (err) {
    console.error(err.response)
    dispatch(saveFailure(err));
    dispatch(uiStopLoading());
  }
};

export const tempSave = (newPatientData) => dispatch => {
  dispatch(uiStartLoading());
  try {
    dispatch(TempSaveSuccess(newPatientData));
    dispatch(uiStopLoading());
  } catch (err) {
    console.error(err.response)
    dispatch(uiStopLoading());
  }
};

