import React, { useEffect, forwardRef, useState, useCallback } from 'react'
import { CircularProgress, Grid, TextField, Select, InputLabel, MenuItem } from '@material-ui/core'
import MaterialTable from 'material-table'
import { useHistory } from 'react-router'
import { safeGet } from '../../axios-default'
import QueryString from 'querystring'
import Modal from '../../components/Modal/modal';
import DragAndDrop from '../../components/NewPatient/Photos/DragAndDrop';
import style from './PageBanner.module.css'
import axios from '../../axios-default';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { ApiServerUrl } from '../../config.json';
import { connect, useDispatch } from 'react-redux';
import { easySnackbar } from '../../store/Noti/noti.actions';
import {
  Close as CloseIcon, Check as CheckIcon, AddBox, ArrowDownward,
  Check, ChevronLeft, ChevronRight, Clear, DeleteOutline,
  Edit, FilterList, FirstPage, LastPage, Remove, SaveAlt,
  Search, ViewColumn, StarRate
} from '@material-ui/icons'
import { Edit as EditIcon, Add as AddIcon } from "@material-ui/icons";
const TableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
}


export const ProductMatrixTable = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [data, setData] = useState([]),
        [loading, setLoading] = useState(true),
        [search, setSearch] = useState(''),
        [modalOpen, setModalOpen] = useState(false),
        [modalError, setModalError] = useState(''),
        [updatedProductMatrix, setUpdatedProductMatrix] = useState({pmData: Object.assign({})})

   const fileToBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader()
    if (file) {
      reader.readAsDataURL(file)
    }
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.onerror = error => reject(error)
  })

  const handleNewPhoto = useCallback( async file => {
      let data = undefined
      if (file) {
        data = await fileToBase64(file)
      }

      setUpdatedProductMatrix({
        ...updatedProductMatrix,
        pmData:  {
          ...updatedProductMatrix.pmData,
          product_image_new: data,
        }
      })

  }, [updatedProductMatrix])
  const reloadPage = () => {
    const curPage = window.location.pathname
    history.push('/blank')
    history.replace(curPage)
  }
  const handleSave = useCallback(async () => {
    try {
      if (loading) return
      setLoading(true)

      await axios.post(`productmatrix/update`, updatedProductMatrix.pmData)

      window.scroll({top: 0, left: 0, behavior: 'smooth' })
      dispatch(easySnackbar('Success', 'success'))

      //dispatch(updateRes('admin_groups'))
      reloadPage()
      setLoading(true)
      setModalOpen(false)

    } catch (err) {
      /*
      console.error(err)
      if (err.response && err.response.statusText === 'Unauthorized') {
        localStorage.removeItem("auth-token");
      localStorage.removeItem("expirationDate");
        localStorage.removeItem("role");
        history.push("/login")
      }*/
    } finally {
      setLoading(false)
    }
  }, [updatedProductMatrix])
  const dataGetter = useCallback(async query => {
    const { pageSize, page: pageNo, search, orderBy, orderDirection } = query
    try {
      const { pm: data, total } = await safeGet(`productmatrix?${QueryString.encode({
        // all: 1,
        pageNo,
        pageSize,
        search,
        sortBy: orderBy?.field,
        sortDir: orderDirection
      })}`)
      if (loading) setLoading(false)
      return {
        data: data.filter(({ deleted_flag }) => !deleted_flag),
        page: pageNo ?? 0,
        totalCount: total ?? 0
      }
    } catch (err) {
      console.error(err)
    }
  }, [search])

  return (<>
    <MaterialTable
      title="Products List"
      data={dataGetter}
      actions={[
        {
          icon: () => <EditIcon />,
          tooltip: 'Edit Row',
          onClick: (_, data) => {
            setUpdatedProductMatrix({pmData: Object.assign({}, data)})
            setModalOpen(true)
          }
        }
      ]}
      columns={[
        {
          field: 'id',
          title: 'Order',
          sorting: true,
          filtering: true,
        },
        {
          field: 'product_name',
          title: 'Product name',
          sorting: true,
          filtering: true,
          defaultSort: 'asc',
        },
        {
          field: 'product_desc',
          title: 'Product Description',
          sorting: true,
          filtering: true,

        },
        {
          field: 'image',
          title: 'Product Image',
          render:  rowData => (  <img style={{ width: "400px"}} src={ ApiServerUrl+ "file/config/" +rowData.product_image}/>) ,
          sorting: false,
          filtering: false,
        },

        {
          field: 'enable',
          title: 'Enable',
          sorting: true,
          filtering: true,
          render: rowData => (
            <div style={{ display: "flex", justifyContent: 'center', margin: '0px 4px', width: 40, height: 40 }}>
              {rowData.enable_flag ? <CheckIcon /> : <CloseIcon />}
            </div>
          )
        },

      ]}

      options={{
        initialPage: 0,
        emptyRowsWhenPaging: false,
        exportAllData: true,
        debounceInterval: 700,
        columnsButton: true,
        pageSize: 10,
        search: false,
        pageSizeOptions: [10, 15, 30, 50, 100],
        minBodyHeight: 700,
        exportButton: true
      }}
      icons={TableIcons}
      isLoading={loading}
    />
    <Modal
      className="modal"
      modal='Distributor Group'
      title='Product'
      open={modalOpen}
      handleClose={() =>{
          setModalOpen(false)
        }
      }
      handleDataSave={() => handleSave()}
      //modalError={this.modalError}
      submitButton={true}
    >
      <>

      <TextField
        fullWidth
        label="Product Name"
        variant="filled"
         value={updatedProductMatrix.pmData.product_name}
        onChange={({ target }) =>{
            setUpdatedProductMatrix({...updatedProductMatrix,
              pmData:  {
                ...updatedProductMatrix.pmData,
                product_name: target.value,
                }
              })
            }
        }
      />
      <TextField
        fullWidth
        label="Product Description"
        variant="filled"
         value={updatedProductMatrix.pmData.product_desc}
        onChange={({ target }) =>{
            setUpdatedProductMatrix({...updatedProductMatrix,
              pmData:  {
                ...updatedProductMatrix.pmData,
                product_desc: target.value,
                }
              })
            }
        }
      />



      <Grid item xs={12}>
          <p>Current Image</p>
        </Grid>
        <Grid item xs={12}>
          <img style={{ width: "400px"}} src={ ApiServerUrl+ "file/config/" +updatedProductMatrix.pmData.product_image} alt="Current Image Photo" />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>

          <DragAndDrop
            handleDrop={file => handleNewPhoto(file)}
          >
            {
              //!this.state.photo.newPhoto ?
                <div className={style.imageUploadArea}>
                <button className={style.remove} >
                  <DeleteOutlineOutlinedIcon />
                </button>

                  <span>Upload New Photo</span>
                  <div className={style.uploadBtnWrapper}>
                    <button className={style.uploadBtn}>+</button>
                    <input
                      type="file"
                      onChange={({ target }) => {
                        handleNewPhoto(target.files[0])
                      }}
                      accept="image/*"
                      name="patientImage"
                      id="patientImage"
                    />
                  </div>
                </div>
                /*:
                <div className={style.imageUploadArea}>
                  <button className={style.remove} onClick={() => this.handleNewPhoto(undefined)}>
                    <DeleteOutlineOutlinedIcon />
                  </button>
                  <img
                    style={{ width: "100%", maxHeight: "400px" }}
                    src={this.state.photo.newPhoto}
                  />
                </div>*/
            }
          </DragAndDrop>
        </Grid>
        <InputLabel shrink id="demo-simple-select-placeholder-label-label">
          Enable
        </InputLabel>
        <Select
          value={updatedProductMatrix.pmData.enable_flag}
          label="Enable"
          onChange={({ target }) =>{
              setUpdatedProductMatrix({...updatedProductMatrix,
                pmData:  {
                  ...updatedProductMatrix.pmData,
                  enable_flag: target.value,
                  }
                })
              }
          }>
          <MenuItem value={false}>Off</MenuItem>
          <MenuItem value={true}>On</MenuItem>
        </Select>
      </>
    </Modal></>
  )

}

export default ProductMatrixTable
