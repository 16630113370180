import { Button, CircularProgress } from '@material-ui/core/';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import AccountsTable from '../../components/Admin/AccountsTable';
//Components
import SideNav from '../../components/Admin/SideNav';
import { getWebAbsPath } from '../../util/path';

const styles = theme => ({
  "@global": {
    body: {
      background: "#fdfdfd",
    },
  },
  root: {
    flexGrow: 1,
  },
  rootContainer: {
    padding: 16,
    margin: "8px 0px 0px 238px",
    [theme.breakpoints.between("xs", "sm")]: {
      margin: "12px 0px 0px 0px",
      width: "100%",
    },
    width: "calc(100% - 238px)",
  },
  scanBox: {
    padding: 16,
    margin: "8px 0px"
  },
  textField: {
    // marginLeft: "8px",
    // marginRight: "8px",
    '& label.Mui-focused': {
      color: 'black',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'black',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey',
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
  },
  dateField: {
    width: "100%"
  },
  formControl: {
    width: "100%"
  },
  dense: {
    marginTop: 16,
    marginLeft: 0
  },
  submitBtn: {
    backgroundColor: "#061a2e !important",
    color: "white",
    padding: "8px 24px",
    margin: "12px 0px"
  },
  linkBtn: {
    background: "rgba(89,54,135,1)",
    color: "white",
    padding: "4px 24px",
    fontWeight: 300,
    margin: "16px 0px",
    width: "100%",
    [theme.breakpoints.up('sm')]: {
      padding: "4px 72px",
      width: "unset",
    },
    "&:hover": {
      background: "rgb(77, 74, 142)",
      color: "white"
    }
  },
});

class Accounts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedin: true,
      name: "",
      channel: "",
      codeUrl: "",
      sources: {},
      addMsg: "",
      userInfo: {},
      redeemSurprisingGift: false,
      scanResult: "",
      reviewCustomer: true,
    }
  }


  handleError = data => {
    console.error(data)
  }

  componentDidMount() {
    // console.log("mounted")
  }


  render() {
    const { classes } = this.props
    return (
      <div>
        <SideNav />
        <Grid container className={classes.rootContainer}>

        </Grid>
        <Grid container className={classes.rootContainer} style={{ marginTop: 0 }}>
          {this.state.isLoggedin ?
            <div style={{ display: "contents" }}>
              <Grid container>

                <Grid item xs={12}
                  style={{
                    padding: 8
                  }}>
                  <h1
                  style={{
                      marginBlockEnd: 0
                    }}
                  >
                    Accounts
                  </h1>

                  <hr />
                </Grid>

                <Grid item xs={12}
                  style={{
                    padding: 8,
                    textAlign: "center"
                  }}>
                  <img style={{ width: "100%", maxWidth: 800 }} src={getWebAbsPath("/img/account1.png")}/>
                </Grid>

                <Grid item xs={12} style={{ padding: 8 }}>
                  <Button className={classes.linkBtn} onClick={() => this.props.history.push('/admin/accounts/new')}>
                    Add Account
                  </Button>
                  <AccountsTable />
                </Grid>

              </Grid>

            </div> :
            <Grid item xs={12}>
              <CircularProgress style={{ color: "rgb(6,26,46)" }} />
            </Grid>}
        </Grid>
      </div>
    )
  }
}

Accounts.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(withWidth()(Accounts)))