"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectionMaxWidth = exports.rowActions = exports.reducePercentsInCalc = exports.elementSize = exports.baseIconSize = exports.actionsColumnWidth = void 0;
const elementSize = props => props.options.padding === "default" ? "medium" : "small";
exports.elementSize = elementSize;
const baseIconSize = props => elementSize(props) === "medium" ? 48 : 32;
exports.baseIconSize = baseIconSize;
const rowActions = props => props.actions.filter(a => a.position === "row" || typeof a === "function");
exports.rowActions = rowActions;
const actionsColumnWidth = props => rowActions(props).length * baseIconSize(props);
exports.actionsColumnWidth = actionsColumnWidth;
const selectionMaxWidth = (props, maxTreeLevel) => baseIconSize(props) + 9 * maxTreeLevel;
exports.selectionMaxWidth = selectionMaxWidth;
const reducePercentsInCalc = (calc, fullValue) => {
  let index = calc.indexOf("%");
  while (index !== -1) {
    let leftIndex = index - 1;
    while (leftIndex >= 0 && "0123456789.".indexOf(calc[leftIndex]) !== -1) {
      leftIndex--;
    }
    leftIndex++;
    const value = Number.parseFloat(calc.substring(leftIndex, index));
    calc = calc.substring(0, leftIndex) + value * fullValue / 100 + "px" + calc.substring(index + 1);
    index = calc.indexOf("%");
  }
  return calc;
};
exports.reducePercentsInCalc = reducePercentsInCalc;