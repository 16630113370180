import { CircularProgress } from '@material-ui/core/';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
//Components
import SideNav from '../../components/Admin/SideNav';
import SettingTabs from './SettingTabs';


const styles = theme => ({
  "@global": {
    body: {
      background: "#fdfdfd",
    },
  },
  root: {
    flexGrow: 1,
  },
  rootContainer: {
    padding: 16,
    margin: "8px 0px 0px 238px",
    [theme.breakpoints.between("xs", "sm")]: {
      margin: "12px 0px 0px 0px",
      width: "100%",
    },
    width: "calc(100% - 238px)",
  },
  scanBox: {
    padding: 16,
    margin: "8px 0px"
  },
  textField: {
    // marginLeft: "8px",
    // marginRight: "8px",
    '& label.Mui-focused': {
      color: 'black',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'black',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey',
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
  },
  dateField: {
    width: "100%"
  },
  formControl: {
    width: "100%"
  }
});

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedin: true,
      name: "",
      channel: "",
      codeUrl: "",
      sources: {},
      addMsg: "",
      userInfo: {},
      language: null,
      redeemSurprisingGift: false,
      scanResult: "",
      reviewCustomer: true,
      currenTab: 0
    }
  }


  handleChange(event, newValue) {
    this.setState({ currenTab: newValue })
  };

  handleError = data => {
    console.error(data)
  }

  // async componentDidMount() {

  //   try {
  //     let query = {
  //       langCode: 'en'
  //     }
  //     let { data } = await axios.get(`lang?${QueryString.stringify(query)}`)
  //     console.log("config", data)
  //   }
  //   catch (err) {
  //     console.log(err.response)
  //   }
  // }


  render() {
    const { classes } = this.props
    return (
      <div>
        <SideNav />
        <Grid container className={classes.rootContainer}>

        </Grid>
        <Grid container className={classes.rootContainer} style={{ marginTop: 0 }}>
          {this.state.isLoggedin ?
            <div style={{ display: "contents" }}>
              <Grid container>

                <Grid item xs={12}
                  style={{
                    padding: 8
                  }}>
                  <h1
                    style={{
                      marginBlockEnd: 0
                    }}
                  >
                    Settings
                  </h1>

                  <hr />
                </Grid>

                {/*
              <Grid item xs={12}
                style={{
                  padding: 8,
                  textAlign: "center"
                }}>
                  <img style={{width: "100%", maxWidth: 800}} src={getWebAbsPath("/img/settings1.png")}/>
              </Grid>
              */}

                <Grid item xs={12}>
                  <SettingTabs />
                </Grid>
              </Grid>

            </div> :
            <Grid item xs={12}>
              <CircularProgress style={{ color: "rgb(6,26,46)" }} />
            </Grid>}
        </Grid>
      </div>
    )
  }
}

Settings.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(withWidth()(Settings)))