import '../../../util/string'
import '../../../util/array'
import React, { Component, PropTypes, forwardRef } from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Zoom from 'react-medium-image-zoom'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  TextField
} from '@material-ui/core';
import MaterialTable from 'material-table'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import ReplayOutlineOutlinedIcon from '@material-ui/icons/ReplayOutlined';
import Modal from '../../../components/Modal/modal';
import DragAndDrop from '../../../components/NewPatient/Photos/DragAndDrop'
import style from './PageBanner.module.css'
import axios from '../../../axios-default'
import { getFromObj } from '../../../util/lang'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Url from 'url'
import join from 'url-join'
import { ApiServerUrl } from '../../../config.json'

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import ArrowRight from '@material-ui/icons/ArrowRight';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { easySnackbar } from '../../../store/Noti/noti.actions';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { grey } from '@material-ui/core/colors';
import { loadConfig } from '../../../store/config/config.action';
import { safeGet } from '../../../axios-default';
import { getWebAbsPath } from '../../../util/path';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
}

const DEFAULT_BANNER = getWebAbsPath("/img/bannerSmall.png")

const styles = theme => ({
  modalContainer: {
    height: '80vh'
  }
})

class PageBanner extends Component {

  actions = [
    {
      icon: () => <EditOutlinedIcon/>,
      tooltip: 'Edit Banner',
      onClick: (_, data) => {
        this.setState({
          bannerModalOpen: true,
          bannerConfig: Object.assign({}, data)
        })
      },
    },
    row => ({
      icon: () => <DeleteOutlineOutlinedIcon />,
      tooltip: 'Delete Banner',
      hidden: !row.country_id && !row.state_province_id && !row.user_id,
      onClick: async (_, data) => {
        if (window.confirm('Do you want to delete this banner?')) {
          try {
            await axios.post('config', {
              country_id: data.country_id,
              state_province_id: data.state_province_id,
              user_id: null,
              config: JSON.stringify({
                banner: null
              })
            })
            await this.props.relaodConfig()
            this.setState({
              loading: true,
              bannerConfig: {}
            }, () => {
              this.componentDidMount()
            })
          } catch (err) {
            console.error(err)
            if (err.response && err.response.statusText === 'Unauthorized') {
              localStorage.removeItem("auth-token");
              localStorage.removeItem("expirationDate");
              localStorage.removeItem("role");
              this.props.history.push("/login")
            }
          }
        }
      }
    })
  ]

  constructor(props) {
    super(props);
    this.state = {
      valuePath: 'banner',
      data: [],
      newImage: null,
      photoUploadLoadingStatus: false,
      bannerModalOpen: false,
      bannerConfig: {},
      hideDragAndDrop: false,
      role: JSON.parse(localStorage.getItem('role'))
    }
  }

  getValueFromConfig = config => config[this.state.valuePath]

  handleAddBanner = async data => {
    try {
      if (!data.country_id) {
        this.props.enqueSnackbar('Please select country', 'error')
        throw new Error()
      }
      if (!data.banners) {
        data.banners = Array.ensureArray(data.banners)
        if (data.banners.some(banner => String.isBlank(banner))) {
          this.props.enqueSnackbar('Please select banner', 'error')
          throw new Error()
        }
      }
      if (data.state_province_id && parseInt(data.state_province_id)) {
        const stateProvince = this.props.res.stateProvinces[parseInt(data.state_province_id)]
        if (!stateProvince || stateProvince.country_id != data.country_id) {
          this.props.enqueSnackbar('Selected state/province is not in the selected country', 'error')
          throw new Error()
        }
      } else {
        data.state_province_id = null
      }

      await axios.post('config', {
        country_id: data.country_id,
        state_province_id: data.state_province_id,
        user_id: data.user_id,
        config: JSON.stringify({
          banner: data.banners.filter(({ url }) => String.isNotBlank(url))
        })
      })
      await this.props.relaodConfig()
      this.setState({
        loading: true
      }, () => {
        this.componentDidMount()
      })
    } catch (err) {
      if (err.response && err.response.statusText === 'Unauthorized') {
        localStorage.removeItem("auth-token");
        localStorage.removeItem("expirationDate");
        localStorage.removeItem("role");
        this.props.history.push("/login")
      } else {
        console.error(err.message)
        if (err.message) this.props.enqueSnackbar('Error occurred', 'error')
        throw err
      }
    }
  }

  handleSaveBanner = async () => {
    try {
      await axios.post('config', {
        country_id: this.state.bannerConfig.country_id,
        state_province_id: this.state.bannerConfig.state_province_id,
        user_id: this.state.bannerConfig.user_id,
        config: JSON.stringify({
          banner: this.state.bannerConfig.banners
        })
      })
      await this.props.relaodConfig()
      this.props.enqueSnackbar('Save successfully', 'success')
      this.setState({
        loading: true,
        bannerModalOpen: false,
        bannerConfig: {}
      }, () => {
        this.componentDidMount()
      })
    } catch (err) {
      console.error(err)
      if (err.response && err.response.statusText === 'Unauthorized') {
        localStorage.removeItem("auth-token");
        localStorage.removeItem("expirationDate");
        localStorage.removeItem("role");
        this.props.history.push("/login")
      }
    }
  }

  fileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    if (file !== undefined) {
      reader.readAsDataURL(file);
    }
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  handleNewBanner = async file => {
    let data = undefined
    if (file) {
      data = await this.fileToBase64(file)
    }
    this.setState(({ bannerConfig }) => ({
      bannerConfig: {
        ...bannerConfig,
        banners: [...(bannerConfig.banners ?? []), {
          img: data,
          url: ''
        }]
      }
    }))
  }

  removeBanner = index => {
    if (this.state.bannerConfig) {
      if (Array.isArray(this.state.bannerConfig.banners) && this.state.bannerConfig.banners.length > index) {
        this.setState(({ bannerConfig }) => {
          bannerConfig.banners.splice(index, 1)
          return { bannerConfig }
        })
      }
    }
  }

  reorder = ({ source, destination }) => {
    if (source && destination) {
      const { index: destIndex } = destination,
            { index: srcIndex } = source
      this.setState(({ bannerConfig }) => {
        const [removed] = bannerConfig.banners.splice(srcIndex, 1)
        bannerConfig.banners.splice(destIndex, 0, removed)
        return { bannerConfig }
      })
    }
    this.setState({ hideDragAndDrop: false })
  }

  async componentDidMount() {
    try {
      const country_id = JSON.parse(localStorage.getItem('country_id'))
      const { config } = await safeGet('config/list')
      const configList = config.filter(v =>
        this.getValueFromConfig(v.json) &&
        (['ADMIN', 'SADMIN'].includes(this.state.role) && !v.user_id)
        || (
          (
            (!v.state_province_id && !v.user_id)
            || (!v.country_id && !v.state_province_id && !v.user_id)
          )
          && (!v.country_id || country_id == v.country_id)
        )
      ).map(v => {
        let banners = this.getValueFromConfig(v.json)
        if (banners) {
          banners = Array.ensureArray(banners).map(v => {
            const banner = {}
            if (typeof v === 'string') {
              banner.img = v
              banner.url = ''
            } else {
              banner.img = v.img
              banner.url = v.url
            }
            if (banner.img.startsWith('/file')) {
              banner.img = join(ApiServerUrl, banner.img)
            }
            return banner
          })
        } else {
          banners = [{ img: DEFAULT_BANNER, url: '' }]
        }
        return {
          ...v,
          country_id: (this.props.res.countries[v.country_id]||{country_name: 'Default'}).country_id,
          state_province_id: ((this.props.res.stateProvinces[v.state_province_id]||{name: 'All'}).state_province_id),
          banners: banners,
          json: undefined
        }
      })
      this.setState({ data: configList, loading: false })
    } catch (err) {
      console.error(err)
      if (err.response && err.response.statusText === 'Unauthorized') {
        localStorage.removeItem("auth-token");
        localStorage.removeItem("expirationDate");
        localStorage.removeItem("role");
        this.props.history.push("/login")
      }
    }
  }

  getColumns = () => [
    {
      title: 'Country Name',
      field: 'country_id',
      lookup: Object.fromEntries(
        Object.entries(this.props.res.countries).map(([key, value]) =>
          ([key, value.country_name])
        )
      ),
      defaultSort: 'asc',
      customSort: (a, b) => {
        if (!a.country_id) {
          return -1
        } else if (!b.country_id) {
          return 1
        }
        return a.country_id - b.country_id
      },
      emptyValue: () => <div>Default</div>
    },
    {
      title: 'State/Province Name',
      field: 'state_province_id',
      lookup: Object.fromEntries(
        [[0, 'All']].concat(Object.entries(this.props.res.stateProvinces).map(([key, value]) =>
          ([key, value.name])
        ))
      ),
      initialEditValue: 0,
      emptyValue: () => <div>All</div>
    },
    {
      title: 'Banners',
      field: 'banners',
      render: data => (
        // <Zoom zoomMargin={48}>
          /* <img
            style={{ maxHeight: "200px" }}
            src={data.banners[0]}
            alt="Image Banner"
          /> */
          <Grid container
            style={{ maxHeight: "200px", width: "400px" }}
          >
            {!this.state.isLoading &&
              // <Carousel
              //   clickToChange
              //   // {...data.banners.length > 1? { autoPlay: 2000 } : null}
              //   autoPlay={data.banners.length > 1? 2000 : null}
              //   infinite
              //   // arrowLeft={<ArrowLeft/>}
              //   // arrowLeftDisabled={<ArrowLeft style={{ color: grey[100] }}/>}
              //   // arrowRight={<ArrowRight/>}
              //   // arrowRightDisabled={<ArrowRight style={{ color: grey[100] }}/>}
              // >
              //   {data.banners.map(banner => (
              //     <img src={banner} />
              //   ))}
              // </Carousel>

              <Carousel
                showArrows={false}
                autoPlay={data.banners.length > 1}
                infiniteLoop
                showThumbs={false}
                swipeable={true}
                emulateTouch
                transitionTime={500}
                interval={2000}
                renderIndicator={() => <div />}
              >
                {data.banners.map((banner, i) => {
                  let content = (
                    <img
                      src={banner.img}
                      style={{display: 'block'}}
                      onError={({ target }) => {
                        let u = Url.parse(target.src)
                        target.src = (
                          u.pathname === '/' || u.pathname.startsWith('/img')
                            ? ''
                            : getWebAbsPath('/img/bannerSmall.jpg')
                        )
                      }}
                    />
                  )
                  if (String.isNotBlank(banner.url)) {
                    content = (
                      <a key={i} href={banner.url} target='_blank' style={{display: 'block'}}>
                        {content}
                      </a>
                    )
                  }
                  return (
                    <div>
                      {content}
                    </div>
                  )
                })}
              </Carousel>
            }
          </Grid>
        // </Zoom>
      ),
      editComponent: props => (
        <>
          <DragDropContext
            onDragEnd={({ source, destination }) => {
              if (source && destination) {
                const { index: destIndex } = destination,
                      { index: srcIndex } = source
                const [removed] = props.value.splice(srcIndex, 1)
                props.value.splice(destIndex, 0, removed)
                props.onChange(props.value)
              }
              this.setState({ hideDragAndDrop: false })
            }}
            onBeforeDragStart={() => this.setState({ hideDragAndDrop: true }) }
          >
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {(Array.ensureArray(props.value)||[]).map((banner, i) => (
                    <Draggable key={i} draggableId={`photo_${i}`} index={i}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div className={style.imageUploadArea}>
                            <button className={style.remove} onClick={() => {
                              props.value.splice(i, 1)
                              props.onChange(props.value)
                            }}>
                              <DeleteOutlineOutlinedIcon />
                            </button>
                            <img
                              style={{ width: "100%", maxHeight: "400px" }}
                              src={banner}
                            />
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <DragAndDrop
            handleDrop={async file => {
              const data = await this.fileToBase64(file)
              props.onChange([...(props.value||[]), data])
            }}
          >
            <div className={style.imageUploadArea}>
              <span>Upload New Banner</span>
              <div className={style.uploadBtnWrapper}>
                <button className={style.uploadBtn}>+</button>
                <input
                  type="file"
                  onChange={async ({ target }) => {
                    const data = await this.fileToBase64(target.files[0])
                    let value = props.value? props.value : []
                    value.push(data)
                    props.onChange(value)
                  }}
                  accept="image/*"
                  name="patientImage"
                  id="patientImage"
                />
              </div>
            </div>
          </DragAndDrop>
        </>
      )
    }
  ]

  render() {
    return (
      <>
        <Modal
          modal='Banner'
          title='Banner'
          open={this.state.bannerModalOpen}
          handleClose={() => this.setState({ bannerModalOpen: false, banners: {} })}
          handleDataSave={() => this.handleSaveBanner()}
          photoUploadLoadingStatus={this.state.photoUploadLoadingStatus}
          submitButton={true}
        >
          <Grid container className={this.props.classes.modalContainer}>
            {/* <Grid item xs={12}>
              <img style={{ width: "100%" }} src={this.state.banner.bannerBase64} alt="Current Image Banner" />
            </Grid> */}
            <Grid item xs={12}>
              <p>Current Images</p>
              <DragDropContext onDragEnd={this.reorder} onBeforeDragStart={() => this.setState({ hideDragAndDrop: true }) }>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {(this.state.bannerConfig.banners||[]).map((banner, i) => (
                        <Draggable key={i} draggableId={`photo_${i}`} index={i}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <div className={style.imageUploadArea}>
                                <button className={style.remove} onClick={() => this.removeBanner(i)}>
                                  <DeleteOutlineOutlinedIcon />
                                </button>
                                <img
                                  style={{ width: "100%", maxHeight: "400px" }}
                                  src={banner.img}
                                />
                                <TextField
                                  fullWidth
                                  label="URL"
                                  variant="filled"
                                  value={banner.url}
                                  onChange={({ target }) =>
                                    this.setState(({ bannerConfig }) => {
                                      if (bannerConfig.banners[i]) {
                                        bannerConfig.banners[i].url = target.value
                                      }
                                      return { bannerConfig }
                                    })
                                  }
                                />
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <DragAndDrop
                hidden={this.state.hideDragAndDrop}
                handleDrop={file => this.handleNewBanner(file)}
              >
                <div className={style.imageUploadArea}>
                  <span>Upload New Banner</span>
                  <div className={style.uploadBtnWrapper}>
                    <button className={style.uploadBtn}>+</button>
                    <input
                      type="file"
                      onChange={({ target }) => this.handleNewBanner(target.files[0])}
                      accept="image/*"
                      name="patientImage"
                      id="patientImage"
                    />
                  </div>
                </div>
              </DragAndDrop>
            </Grid>
          </Grid>
        </Modal>

        <MaterialTable
          isLoading={this.state.loading}
          title='Banner'
          icons={tableIcons}
          columns={this.getColumns()}
          editable={{
            onRowAdd: this.handleAddBanner
          }}
          actions={this.actions}
          data={this.state.data}
          options={{
            actionsColumnIndex: -1
          }}
        />
      </>
    );
  }
}


const mapStateToProps = state => ({
  res: state.res
});

const mapDispatchToProps = dispatch => ({
  enqueSnackbar: (msg, variant) => dispatch(easySnackbar(msg, variant)),
  relaodConfig: () => dispatch(loadConfig()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(PageBanner)))
