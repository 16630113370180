import { Button, CircularProgress, makeStyles, Typography } from '@material-ui/core/';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { isWidthUp } from '@material-ui/core/withWidth';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import SendIcon from '@material-ui/icons/Send';
import classNames from 'classnames';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Url from 'url';
import Loader from "../components/Loader";
import TopBar from '../components/TopBar';
import { ApiServerUrl } from '../config.json';
import * as authAction from '../store/Auth/Auth.action';
import { useLang } from '../util/lang';
import { useWidth } from '../util/react';
import '../util/string';
import { uiStartLoading } from '../store/loading/actions';
import frontend_config from '../frontend_config';

const hostname = window.location.hostname

const loginStyles = makeStyles(theme => ({
  "@global": {
    body: {
      background: "#fdfdfd",
    },
  },
  root: {
    flexGrow: 1,
  },
  main: {
    width: 'auto',
    display: 'block',
    marginLeft: "0px 24px",
    [theme.breakpoints.up(500 + 48)]: {
      width: 500,
      marginLeft: '10%',
      marginRight: 'auto',
    },
  },
  paper: {
    [theme.breakpoints.up(600)]: {
      marginTop: "15vh",
      marginBottom: "30px"
    },
    marginTop: "15vh",
    background: "#ffffffcc",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  form: {
    width: '100%',
    marginTop: 8,
    textAlign: "left",
  },
  submit: {
    marginTop: "24px",
  },
  textField: {
    // marginLeft: "8px",
    // marginRight: "8px",
    '& label.Mui-focused': {
      color: 'black',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'black',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey',
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
  },
  dense: {
    marginTop: "16px",
    marginLeft: "0px",
  },
  welcomeTitle: {
    margin: "16px 0px"
  },
  progress: {
    marginTop: "48px"
  },
  login: {
    backgroundColor: `${frontend_config?.[hostname]?.main_color_1} !important`,
    // color: Colors.backgroundSecond,
    marginTop: "16px",
    marginBottom: "12px"
  },
  loginBackground: {
    minHeight: "80vh",
    backgroundPosition: "top center",
    backgroundSize: "cover"
  },
  loginContainer: {
    padding: "16px 24px 24px",
    background: "#ffffff30",
    // [theme.breakpoints.down('xs')]:{
    //   height: "80vh"
    // },
  }
}))

const backgroundImage = '/img/log_in.jpg'


export default function ForgotPasssword(props) {
  const get = useLang('ForgotPswd'),
        history = useHistory(),
        width = useWidth(),
        classes = loginStyles(),
        { isLoading } = props,
        dispatch = useDispatch(),
        [ isAuthenticated, isAuthenticatedText, config, isUiLoading ] = useSelector(store => ([
          store.auth.isAuthenticated,
          store.auth.isAuthenticatedText,
          store.config.config,
          store.loading.isLoading
        ]))

  const [authError, setAuthError] = useState("")
  const userFieldRef = useRef(null),
        passFieldRef = useRef(null)

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/')
    }
    localStorage.removeItem('Patients.pageNo')
    localStorage.removeItem('Patients.searchStr')
    localStorage.removeItem('Patients.mode')
  }, [])

  // useEffect(() => {
  //   console.log(isAuthenticatedText)
  // }, [isAuthenticatedText])

  const login = async () => {
    if (userFieldRef.current && passFieldRef.current) {
      const { value: user } = userFieldRef.current,
        { value: pwd } = passFieldRef.current
      if (user.isBlank() || pwd.isBlank()) {
        setAuthError("All the fields are required")
      } else {
        setAuthError("")
        await dispatch(uiStartLoading())
        await dispatch(authAction.login(user, pwd))
      }
    } else {
      console.error('UserField | PassField not found')
    }
  }
  const handleEnter = ({ key }) => key == "Enter" && login()

  return (
    <Fragment>
      {isLoading ? (
        <Loader />
      ) : (
          <Grid
            container
            style={{
              height: '100%',
              backgroundImage: `url("${config && config.loginPhoto && String.isNotBlank(config.loginPhoto) ?
                Url.resolve(ApiServerUrl, config.loginPhoto) :
                backgroundImage}")`
            }}
            className={classes.loginBackground}
          >
            <TopBar />
            <Grid item xs={12} style={{ minHeight: "54vh" }}>
              <main className={classes.main}>
                <Paper className={classes.paper} elevation={isWidthUp('sm', width) ? 2 : 0}>
                  <Grid container className={classes.loginContainer}>
                    <Grid item xs={12}>
                      <Typography component="h1" variant="h4" className={classes.welcomeTitle}>
                        {get('resetpswd')}
                      </Typography>
                      <Typography component="h3" variant="subtitle1" align="left" className={classes.welcomeTitle}>
                        {/*get('pleaseLogin')*/}
                      </Typography>
                      <Divider className="loginHr" />
                      <form className={classes.form}>
                        <Grid container>
                          <Grid item xs={3} sm={3}>
                            <p>{get(':fields.newpswd')}:</p>
                          </Grid>

                          <Grid item xs={9} sm={9}>
                            <TextField
                              id="account"
                              type="password"
                              className={classNames(classes.textField)}
                              margin="dense"
                              variant="outlined"
                              inputRef={userFieldRef}
                              fullWidth
                              autoFocus
                              onKeyDown={handleEnter}
                            />
                          </Grid>
                        </Grid>

                        <Grid container style={{ marginBottom: 16 }}>
                          <Grid item xs={3} sm={3}>
                            <p>{get(':fields.confirmpswd')}:</p>
                          </Grid>

                          <Grid item xs={9} sm={9}>
                            <TextField
                              id="password"
                              inputRef={passFieldRef}
                              type="password"
                              className={classNames(classes.textField)}
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              // onChange={e => this.setState({ [e.target.id]: e.target.value })}
                              onKeyDown={handleEnter}
                              style={{ marginBottom: 8 }}
                            />
                          </Grid>
                        </Grid>

                        <Link
                          to="/forgot"
                          style={{
                            textDecoration: "underline",
                            color: "black",
                          }}
                        >
                          {get('forgotPassword')}
                        </Link>

                        <p style={{ color: "red", marginBottom: "0" }}>{isAuthenticatedText}</p>
                        <p style={{ color: "red", marginBottom: "0" }}>{authError}</p>

                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          className={classes.login}
                          onClick={login}
                          style={{ marginBottom: 8 }}
                        >
                          {
                            isUiLoading ? <CircularProgress size={30} style={{ color: "white" }} /> :
                              <div style={{ color: "white" }}>{get(':const.login')}<SendIcon style={{ margin: "0px 8px", verticalAlign: "middle", color: "white" }} /></div>
                          }
                        </Button>

                        <Link
                          to="/register"
                          style={{
                            textDecoration: "none",
                            color: "black",
                          }}
                        >
                          <Button variant="outlined" style={{ textAlign: "left", border: "black solid 1px", width: "100%", marginTop: 16 }}>
                            {get('register')}
                          </Button>
                        </Link>

                      </form>
                    </Grid>
                  </Grid>
                </Paper>
              </main>
            </Grid>
          </Grid>
        )}
    </Fragment>
  )
}
