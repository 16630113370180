import { Button, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { withLang } from '../util/lang';
import frontend_config from '../frontend_config';

const hostname = window.location.hostname

const styles = theme => ({
  footer: {
    // background: "#39a8bd",
    background: frontend_config?.[hostname]?.main_background_color,
    color: "white",
    textAlign: "center",
    padding: 16,
  },
  footerTitle: {
    margin: "8px 0px",
    fontFamily: "'Quicksand', sans-serif"
  },
  roundBtn: {
    color: "white",
    padding: "4px 16px",
  },
  logo: {
    width: 148,
    [theme.breakpoints.down('sm')]: {
      width: 148,
      height: "auto",
      padding: "10px 0px"
    },
  },
  footerP: {
    color: 'white'
  }
})

class Footer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pages: [
        {
          name: "Home",
          icon: "",
          linkTo: "/"
        },
        {
          name: "About Us",
          icon: "",
          linkTo: "/"
        },
        {
          name: "News",
          icon: "",
          linkTo: "/"
        },
        {
          name: "Contact Us",
          icon: "",
          linkTo: "/"
        }
      ]
    }
  }

  returnPages() {
    return this.state.pages.map(v => {
      return (
        <Link to={v.linkTo} key={v.name} style={{ padding: "10px 0px" }}>
          <Button>
            {v.name}
          </Button>
        </Link>
      )
    })
  }
  
  render() {
    const { classes } = this.props
    return (
      <div className={classes.footer}>
        <Grid container>
          <Grid item xs={12} style={{ padding: "16px", textAlign: "center" }}>
            <Typography variant="h5" className={classes.footerTitle}>
              Customer Service
            </Typography>
            <Typography variant="body1" className={classes.footerP} style={{ fontFamily: "'Quicksand', sans-serif" }}>
              If you need assistance, please contact our customer service team on <a href="mailto: cs@bemealigners.com" style={{color: "inherit", textDecoration: "inherit"}}>cs@bemealigners.com</a>
            </Typography>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} align="center">
            <p className={classes.footerP} style={{ fontSize: "12px" }}>
              © {(new Date()).getFullYear()} BeMe ALL RIGHTS RESERVED
            </p>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} align="center">
            <p className={classes.footerP} style={{ fontSize: "12px" }}>
              v 2.0.0.1
            </p>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = store => {
  return {
    lang: store.lang
  }
};

export default connect(mapStateToProps)(withStyles(styles)(withRouter(withLang(Footer, 'Footer'))))