import { SHOW_SNACKBAR, CLOSE_SNACKBAR, REMOVE_SNACKBAR, ENQUEUE_SNACKBAR } from './noti.actions';

const defaultState = {
  notifications: [],
  queuedNotifications: [],
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SHOW_SNACKBAR:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            // @ts-ignore
            key: action.key,
            ...action.notification,
          }
        ]
      }

    case ENQUEUE_SNACKBAR:
      return {
        ...state,
        queuedNotifications: [
          ...state.queuedNotifications,
          {
            // @ts-ignore
            key: action.key,
            ...action.notification,
          }
        ]
      }

    case CLOSE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.map(notification =>
          (action.dismissAll || notification.key === action.key)
            ? { ...notification, dismissed: true }
            : { ...notification }
        )
      }

    case REMOVE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => notification.key !== action.key
        ),
        queuedNotifications: state.queuedNotifications.filter(
          queuedNotification => queuedNotification.key !== action.key
        )
      }

    default:
      return state;
  }
}
