import React from 'react';
import styles from './index.module.css';
import frontend_config from '../../frontend_config';
import { getWebAbsPath } from '../../util/path';

const hostname = window.location.hostname

export default () => {
  return (
    <div className={styles.loader}>
      <img
        alt="BeMe Main Logo"
        src={ getWebAbsPath(frontend_config?.[hostname]?.logo_path)}
        style={{ width: 200, marginBottom: 200 }}
      />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};
