String.prototype.isEmpty = function () {
  return !this || this === '' || this.length === 0
}
String.prototype.isBlank = function () {
  return !this || this === '' || this.trim().length === 0
}
String.prototype.isNotEmpty = function () {
  return !this.isEmpty()
}
String.prototype.isNotBlank = function () {
  return !this.isBlank()
}
String.prototype.escapeRegExp = function () {
  return this.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

String.prototype.toCapitalLetter = function () {
  return this.length ? (this[0].toUpperCase() + this.slice(1)) : this
}

String.isEmpty = function (s) {
  return !s || typeof s !== 'string' || s === '' || s.length === 0
}
String.isBlank = function (s) {
  return !s || typeof s !== 'string' || s === '' || s.trim().length === 0
}
String.isNotEmpty = function (s) {
  return !String.isEmpty(s)
}
String.isNotBlank = function (s) {
  return !String.isBlank(s)
}
String.escapeRegExp = function (s) {
  return s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

String.toCapitalLetter = function (s) {
  return s.length ? (s[0].toUpperCase() + s.slice(1)) : s
}