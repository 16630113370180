import {
  LANG_LOAD, LANG_CONFIG
} from './actionType'

export const initialState = {
  isInit: false,
  avail: ['English'],
  default: 'English',
  current: 'English',
  // dict_: {
  // },
  dict: {
    global: {
      caseStatus: {
        DRAFT: 'Draft',
        SUBMITTED: 'Submitted',
        AWAITING: 'Awaiting',
        RECEIVED: 'Received',
        PLAN_UPLOADED: 'Plan Uploaded',
        CHANGE_REQUEST: 'Change Request',
        PLAN_APPROVED: 'Plan Approved',
        PRODUCTION: 'Production',
        DELIVERED: 'Delivered',
        NEXT_SET_REQUEST: 'Next Set Request',
        ALL_DELIVERED: 'All Delivered',
        ENDED: 'Ended',
        REFINEMENT: 'Refinement',
      },
      role: {
        SADMIN: 'Super Admin',
        ADMIN: 'Admin',
        DISTR: "Distributor",
        GADMIN: "Group Admin",
        CADMIN: "Clinic Admin",
        DR: "Doctor",
        NR: "Nurse",
      },
      caseType: {
        TOUCH_UP: 'Touch Up',
        SIMPLE: 'Simple',
        COMPLETE: 'Complete',
        RETAINER: 'Retainer',
        CBCT: 'Cbct',
      },
      uploadType: {
        optional: 'Optional',
        mandatory: 'Mandatory',
        stronglyRecommended: 'Strongly Recommended',
        uploadPhotoLater: 'I will upload this photos later',
        uploadXrayLater: 'I will upload this x-ray later',
        willNotUploadPhoto: 'Photo will not be uploaded',
        willNotUploadXray: "I will NOT upload this X-ray",
      },
      dragAndDrop: {
        dragAndDrop: 'Drag And Drop',
        dropHere: 'Drop Here'
      },
      honorific: {
        mr: 'Mr.',
        ms: 'Ms.',
      },
      fields: {
        name: 'Name',
        firstName: 'First Name',
        lastName: 'Last Name',
        caseId: 'Case ID',
        patient: 'Patient',
        gender: 'Gender',
        male: 'Male',
        female: 'Female',
        other: 'Other',
        title: 'Title',
        Prof: 'Prof.',
        Dr: 'Dr.',
        Mr: 'Mr.',
        Mrs: 'Mrs.',
        Ms: 'Ms.',
        Miss: 'Miss.',
        dateOfBirth: 'Date of Birth',
        dateOfBirthAbbr: 'DOB',
        day: 'Day',
        month: 'Month',
        year: 'Year',
        refCode: 'Case Reference Code',
        remarks: 'Remarks',
        country: 'Country',
        stateProvince: 'State/Province',
        city: 'City',
        address: 'Address',
        doctor: 'Doctor',
        deliveryAddress: 'Delivery Address',
        postCode: 'Zip/Postal Code',
        phoneNo: 'Phone Number',
        tel: 'Telephone',
        faxNo: 'Fax Number',
        promotionCode: 'Promotion Code',
        distributor: 'Distributor',
        email: 'Email',
        newpswd: "New password",
        confirmpswd: "Confirm password",
        licenseNo: 'License No.',
        clinic: 'Clinic',
        clinicPhoneNo: 'Clinic Phone Number',
        clinicWebsite: 'Clinic Website',
        group: 'Group',
        radiographs: 'Radiographs',
        photos: 'Photos',
        impression: 'Impression',
        questionnaires: 'Questionnaires',
        treatmentPlan: 'Treatment Plan',
        date: 'Date',
        startDate: 'Start Date',
        endDate: 'End Date',
        password: 'Password',
        confirmPassword: 'Confirm Password',
        companyName: 'Company Name',
        doctorName: 'Doctor Name',
        devlieryName: 'Delivery Name'
      },
      const: {
        none: 'None',
        warning: 'Warning',
        close: 'Close',
        all: 'All',
        remove: 'Remove',
        uploading: 'Uploading',
        submit: 'Submit',
        update: 'Update',
        save: 'Save',
        saving: 'Saving',
        login: 'Log In',
        loginInstead: 'Log In Instead',
        register: 'Register',
        replace: 'Replace',
        lost: 'Lost',
        type: 'Type',
        back: 'Back',
        yes: 'Yes',
        no: 'No',
      }
    },
    Nav: {
      welcome: 'Welcome',
      caseManagement: 'Case Management',
      accountSummary: 'Account Summary',
      supportAndResources: 'Support and Resources',
      logout: 'Logout',
      changePassword: 'Change Password',
      dashboard: 'Dashboard',
      myAccount: 'My Account',
    },
    TopBar: {
      trioclearWebsite: 'TrioClear Website',
      contactUs: 'Contact Us',
      lang: 'Language',
    },
    Footer: {
      followUs: 'Follow Us',
      facebook: 'facebook',
      instagram: 'instagram',
      customerService: 'CUSTOMER SERVICE',
      contact: 'We are always ready to serve you. If you are interested in TrioClear, you are welcome to contact our team at cs@trioclear.com.hk.',
      companyName: 'TRIOCLEAR',
      allRightsReserved: 'ALL RIGHTS RESERVED'
    },
    Account: {
      accountChartTitle: 'Creation of New Accounts',
      accountChartSubtitle: 'By Week, Month, Year and Distributor, Group, Clinic, Doctor',
      caseChartTitle: 'Case Overview',
      caseChartSubtitle: 'By Month, Year and by All, Submitted, Draft',
      detailsTitle: 'Profile Details',
      addressTitle: 'Delivery Address',
      profileTabTitle: 'Profile',
      chartTabTitle: 'Chart',
      addressTabTitle: 'Delivery Address'
    },
    Support: {
      contactInfo: 'Contact Information',
      formDownload: 'Form Download',
      discountForm: 'Discount Form',
      consentForm: 'Conscent Form',
    },
    ChangePassword: {
      title: 'Update Password',
      oldPwd: 'Old Password',
      newPwd: 'New Password',
      confirmNewPwd: 'Confirm New Password',
    },
    Forgot: {
      title: 'Reset your password',
      provideEmail: 'Simply enter your email address below and we will send you an email with a link to reset your password',
      recover: 'Recover',
    },
    Register: {
      welcome: 'Welcome To BeMe',
      pleaseRegister: 'Please Register',
      southernCrossDentalCustomer: 'If you are a Southern Cross Dental Customer? Enter your account number here in order to link your account',
      errorMsg: {
        firstName: 'First Name is required',
        lastName: 'Last Name is required',
        email: 'Email is required',
        password: 'Password is required',
        companyName: 'Company Name is required',
        licenseNo: 'License Number is required',
        phoneNo: 'Phone Number is required',
        clinicPhoneNo: 'Clinic Phone Number is required',
      }
    },
    ForgotPswd: {
      resetpswd: "Reset Password",
      pleaseLogin: "",
      forgotPassword: "Forgot password?",
      register: "Become our member"
    },
    VerifyEmail: {
      email_verify_success: "Email verify success!",
    },
    Login: {
      welcome: 'Welcome Back',
      pleaseLogin: 'Please log in to your account',
      forgotPassword: 'Forgot password?',
      register: 'Become Our Provider',
    },
    Patients: {
      new_case: 'New Case',
      categories: {
        pending: 'Pending Action',
        inProgress: 'Treatment in Progress',
        completed: 'Completed',
        archived: 'Archived',
        unanswered: "Unanswered",
      },
      case_list: {
        title: 'Patients',
        searchPlaceHolder: 'Search or Filter',
        pendingAction: "Pending Action",
        treatmentInProgress: "Treatment In Progress",
        completed: "Completed",
        columns: {
          actions: 'Actions',
          archived: "Archived",
          notification: 'Notification',
          caseStatus: 'Case Status',
          profile: 'Profile',
          gender: 'Gender',
          patientID: 'Patient ID',
          clientCode: 'Client Code',
          refCode: 'Case Reference Code',
          treatmentOption: 'Treatment Option',
          approvalDate: 'Approval Date',
          treatmentEndDate: 'Treatment End Date',
          age: 'Age',
          submissionDate: 'Submission Date',
          remarks: 'Remarks',
          distributor: 'Distributor',
          group: 'Group',
          clinic: 'Clinic',
          drName: 'Dr. Name'
        }
      }
    },
    NewPatient: {
      warning: {
        firstName: "Please Provide Valid First Name",
        lastName: "Please Provide Valid Last Name",
        city: "Please Provide Valid City",
        postCode: "Please Provide Valid Zip Code",
        phoneNo: "Please Provide Valid Phone Number",
        allMandatory: "Please upload all the necessary files or it would cause inaccuracy for treatment plans",
        mandatoryPhoto: "Please upload ALL the mandatory photographs.",
        mandatoryRadiograph: "Please upload ALL the mandatory radiographs.",
        allStl: "Please upload ALL the STL files or check the 'I will upload this photo later' boxes.",
        courier: "Please provide Customer Pick-Up Code/ Doctor Code for impression.",
        scanner: "Please provide Scanner Brand and Username/Login Name/Dr. Name for impression.",
        leaveWithoutSave: 'Are you sure you want to leave?',
        saveDraft: 'Please SAVE DRAFT if you want to edit later',
      },
      tabs: {
        info: 'Patient Info',
        questionnaire: 'Questionnaire',
        delivery: 'Delivery',
        photos: 'Photos',
        impression: 'Impression',
        radiography: 'Radiographs',
        summary: 'Summary',
      },
      navBtns: {
        next: 'Next',
        submit: 'Submit',
        previous: 'Previous',
        saveDraft: 'Save Draft'
      },
      breadcrumbs: {
        caseManagement: 'Case Management',
        newCase: 'New Case',
      },
      info: {
        dragAndDrop: 'Upload patient profile picture (optional)',
      },
      questionnaire: {
        dentition_none: 'None',
        dentition_upper: 'Upper',
        dentition_lower: 'Lower',
        dentition_anterior: 'Anterior',
        dentition_posterior: 'Posterior',

        clinicalCondition: 'Clinical Condition',
        selectDentition: 'Please select the dentition that you wish NOT to move',
        gainSpaceMethod: 'Method of Gaining Space',
        overjet: 'Overjet',
        overbite: 'Overbite',
        midline: 'Midline',
        crossbite: 'Crossbite',
        comment: 'Comment',

        crowding: 'Crowding',
        deepBite: 'Deep Bite',
        spacing: 'Spacing',
        classII: 'Class II Division I',
        ClassIIDivI: 'Class II Division II',
        classIII: 'Class III',
        unevenSmile: 'Uneven Smile',
        openBite: 'Open Bite',
        overbite: 'Overbite',
        other: 'Other',

        upper: 'Upper',
        lower: 'Lower',
        anterior: 'Anterior',
        posterior: 'Posterior',

        primary: 'Primary',
        secondary: 'Secondary',

        referToClinicalSimulation: 'Refer to Clinical Simulation',

        maintain: 'Maintain',
        improve1mm: 'Improve 1mm',
        improve2mm: 'Improve 2mm',
        improve3mm: 'Improve 3mm',
        improve4mm: 'Improve 4mm',
        improve5mm: 'Improve 5mm',

        correctUpper: 'Correct upper by 1-5 mm',
        correctLower: 'Correct lower by 1-5 mm',

        improve: 'Improve',

        extraction: 'Extraction',
        extractionWhich: 'Extraction (Which tooth to extract?)',
        expansion: 'Expansion',
        interproximalReduction: 'Interproximal Reduction',
        distalization: 'Distalization',
        proclination: 'Proclination',
      },
      photographs: {
        uploadThisPhotoLater: 'Upload this photo later',
        photographs: 'Photographs',
        flip: 'Flip'
      },
      radiographs: {
        orthopantomogram: 'Orthopantomogram',
        lateralCephalogram: 'Lateral Cephalogram',
        uploadRadiograph: 'Upload the Radiographs',
        uploadCbct: 'Upload CBCT file',
        notUploadWarning: 'Not uploading strongly recommended file may cause error in final reports',
        cbct_dicom: 'CBCT DICOM'
      },
      impressions: {
        uploadImpression: 'Upload the impressions',
        uploadStlFilesLater: 'I will upload the STL files later',
        uploadStl: 'Upload STL file impression now',
        uploadByCourier: "Digital impressions by courier",
        uploadByScanner: "Digital impressions by intraoral scanner",
        notUploadWarning: "Treatment plan will not be started after submission unless all the STL files are received",
        byCourierLabel: "Customer Pick-Up Code/ Doctor Code",
        byScannerBrandLabel: "Scanner Brand",
        byScannerUserNameLabel: 'Username / Login Name / Dr. Name',
        doctorId: 'Doctor ID',
      },
      delivery: {
        promotionCodeLabel: "Enter a Promotion Code If Any",
        selectAddressLabel: 'Select a billing address or enter new address',
      },
      summary: {
        orderInfo: 'Order information',
        noCondition: 'No condition is selected',
        dentitionNotMove: 'Dentition that you wish NOT to move',
        allPhotos: 'All Photos',
        allRadiography: 'All Radiographs',
        allImpressions: 'All Impressions',
      },
    },
    PatientInfo: {
      msg: {
        caseHasBeenEnded: 'This case has been ended by doctor, no files should be uploaded afterwards.',
        termsAndCondChecked: 'By clicking Submit means you have checked',
        termsAndCondCheckedLink: 'terms and conditions',
        endCaseDialogTitle: 'End Case?',
        confirmEndCase: 'Are you sure you want to End Case?',
      },
      log: {
        DRAFT: 'Draft created',
        SUBMITTED: 'Prescription Submitted by Doctor',
        AWAITING: 'Awaiting More Record',
        RECEIVED: 'All materials have been confirmed and the treatment plan is expected to be delivered on {{status_param1}} {{status_param2}}',
        RECEIVED_STATUS_UPDATE: 'Delivery date of treatment plan has been updated to {{status_param1}} {{status_param2}} due to typo from technicians',
        TREATMENNT_PLAN_UPLOADED: 'Treatment Plan {{plan_no}} Uploaded',
        TREATMENNT_PLAN_CHANGED_REQUEST_RECEIVED: 'Treatment Plan {{plan_no}} Change Request Received',
        TREATMENNT_PLAN_APPROVED: 'Treatment Plan {{plan_no}} Approved',
        TREATMENNT_PLAN_UPDATED: 'Treatment Plan {{plan_no}} Updated',
        PRODUCTION: 'Production in Progress',
        ALIGNER_SET_DELIVERED: 'Aligner Set {{status_param1}}-{{status_param2}} Delivered',
        NEXT_SET_REQUEST: 'Request for Next Aligner Set',
        ALL_DELIVERED: 'All Aligner Sets Delivered',
        ENDED: 'Doctor ended the case, awaiting if retainer or refinement is needed',
        REFINEMENTS: 'Refinement Prescription Received',
        RETAINER_REQUESTED: 'Doctor Requested Retainer',
        REFINEMENT: 'Uploaded Refinement',
        RETAINER: 'Retainer',
        ALIGNERS_REQUESTED: 'Doctor Requested Aligners',
        UPLOADED_RADIOGRAPHS: 'Uploaded Radiographs',
        UPLOADED_PHOTOGRAPHS: 'Uploaded Photos',
        UPLOADED_IMPRESSION: 'Uploaded Impression',
        UPLOADED_TREATMENT_PLAN: 'Uploaded Treatment Plan',
        UPLOADED_INDIVIDUAL_STL_FILES: 'Uploaded Individual STL Files',
        ALIGNERS_STEP_DELIVERED: 'Aligners {{step}} Delivered',
        RETAINER_DELIVERED: 'Retainer {{retainer_no}} Delivered',
        STEP: 'Step',
        UPLOADED_MISSING_PHOTOS_RADIOS: 'Upload Missing Photos / Radiographs',
        UPLOADED_MISSING_IMPRESSIONS: 'Upload Missing Impressions',
        UNKNOWN: 'Unknown',
        VIEW_RADIOGRAPHS: 'View Radiographs',
        VIEW_PHOTOS: 'View Photos',
        VIEW_IMPRESSION: 'View Impression',
        VIEW_REFINEMENT: 'View Refinement',
        VIEW_RETAINER_REQUEST: 'View Retainer Request',
        MARK_DELIVERED: 'Mark Delivered',
        VIEW_TREATMENT_PLAN: 'View Plan',
        VIEW_QUESTIONNAIRE: 'View Prescription Notes',
        CLINICAL_SIMULATION: 'Clinical Simulation',
        VIEW_STL_FILES: 'View STL Files',
        LAST_APPROVAL_UNDONE: 'Treatment Plan {{plan_no}} has been undone'
      },
      breadcrumbs: {
        caseManagement: 'Case Management',
        patient: 'Patient',
        clinicalSimulation: 'Clinical Simulation',
      },
      case_info: {
        caseStatus: 'Case Status',
        currentStatus: "Current Status",
        editInfo: "Edit Info",
        chnageStatusTo: "Change Status To",
        dob: "Date of Birth",
        gender: "Gender",
        clientCode: "Client Code",
        internalRemarks: "Internal Remarks",
        refCode: "Case Reference Code",
        remarks: "Remarks",
        treatmentOption: 'Treatment Option',
        approvalDate: 'Approval Date',
        treatmentEndDate: 'Treatment End Date',
        caseLog: "Case Log",
        actions: "Actions",
        documents: "Documents",
        uploadFiles: "Upload Files",
        actionsList: {
          updateStatus: "Update Status",
          lostReplace: "Lost / Replace Aligners",
          endCase: "End Case",
          refinement: "Refinements",
          requestRetainer: "Request Ecodont™️ Retainers",
          processNext: "Process Next Set of Aligners"
        },
        documentsList: {
          viewTreatmentPlan: "View Treatment Plans",
          clinicalSimulation: "Clinical Simulations and Live Support",
          viewPhotos: "View Photos",
          printOrder: "Print Order Information",
          viewRadiograph: "View Radiographs",
          viewImpression: "View Impressions",
          viewQuestionnaire: "View Prescription Notes"
        },
        filesList: {
          uploadTreatmentPlan: "Upload Treatment Plan",
          uploadPhotos: "Upload Photos",
          uploadRadiograph: "Upload Radiographs",
          uploadImpression: "Upload Impressions",
          uploadQuestionnaire: "Upload Questionnaire"
        }
      },
      aligner: {
        addOneMore: 'Add One More',
        deleteThisRow: 'Delete This Row',
        replacement: 'Replacement',
        alreadyExists: 'Selected pair already exists,please choose other',
        duplicatePair: 'Duplicate Pair',
        stepNumber: 'Step Number',
        thickness: 'Thickness',
      },
      caseStatusLabel: {
        SUBMITTED: "Prescription Submitted",
        AWAITING: "Awaiting More Records{Remark: ?}",
        RECEIVED: "Confirmed All Materials Received",
        CHANGE_REQUEST: "Treatment Plan Change Request Received",
        PLAN_UPLOADED: "Treatment Plan {No.?} Uploaded",
        PLAN_APPROVED: "Treatment Plan {No.?} Approved",
        PRODUCTION: "Production In Progress",
        DELIVERED: "Aligners Set {No.1 ?}-{No.2 ?} Delivered",
        NEXT_SET_REQUEST: "Request for Next Aligner Set",
        ALL_DELIVERED: "All Aligners Sets Are Delivered",
        ALIGNER_REQUEST: "Aligner Request",
        RETAINER_REQUEST: "Retainer Request",
        REFINEMENT: "Refinements Prescription Received",
        ENDED: "Case Ended",
      },
      modals: {
        uploadedAt: 'Uploaded at',
        download: 'Download',
        infoModalTitle: 'Patient Info',
        photoModalTitle: 'Photos (Various Angle)',
        radioModalTitle: 'Radiographs',
        impressionModalTitle: 'Impression',
        questionnaireModalTitle: 'Questionnaire',
        reatinerModalTitle: 'Request Retainer',
        lostReplaceAlignersTitle: 'Lost / Replace Aligners'
      },
      treatmentPlan: {
        noTreatmentPlan: 'No treatment plan is uploaded',
        undoPlanApproval: 'Undo Plan Approval',
        approvePlan: 'Approve Plan',
        fileName: 'File Name(Click to Download)',
        submissionDate: 'Submission Date',
        approved: 'Approved',
        approvedDate: 'Approved Date',
        view_3DSimulation: 'View 3D Simulation',

        dragAndDrop: 'Drag And Drop',
        pdfFile: 'PDF file',
        analysis: 'Analysis',
        treatmentPlan: 'Treatment Plan',
        videoFile: 'Video file',
        buccalViewVideo: 'Buccal View Video',
        occlusalVideo: 'Occlusal Video',
        myoFile: '.myo file',
      },
      clinicalSimulation: {
        msg: {
          nextSet: 'Are you sure you want to Process Next Set?',
          noTreatmentPlan: 'No Treatment Plan',
          noStlFiles: 'No STL Files Uploaded Yet',
        },
        tabs: {
          simulation: '3D Simulation',
          viewStl: 'View STL',
          uploadStlFiles: 'Upload STL Files'
        },
        buttons: {
          approveTreatmentPlan: 'Approve Treatment Plan',
          processToNextSet: 'Process To Next Set',
          upper: 'UPPER',
          lower: 'LOWER',
          all: 'ALL',
          anter: 'ANTER',
          maxil: 'MAXIL',
          mand: 'MAND',
          right: 'RIGHT',
          left: 'LEFT',
        },
        row: 'Row',
        addRow: 'Add Row',
        chat: {
          caseStatusChanged: 'Case status changed',
          newStatus: 'New status',
          sendPlaceHolder: `Press 'Shift + Enter' for new line, 'Enter' to send`,
        }
      },
    }
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LANG_LOAD:
      let type = typeof action.payload
      if (type === 'string') {
        action.payload = JSON.parse(action.payload)
      } else if (type !== 'object') {
        throw new Error('Cannot identify what the lang object is')
      }
      return {
        ...state,
        isInit: true,
        current: action.payload.langCode,
        dict: {
          ...action.payload.dict,
        },
        avail: action.payload.avail
      }
    case LANG_CONFIG:
      if (Object.keys(action.payload).every(k => ['avail', 'default'].includes(k))) {
        return {
          ...state,
          isInit: true,
          ...action.payload
        }
      }
    default:
      return state
  }
}