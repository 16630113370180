import * as actionTypes from './actionType';

const initialState = {
  firstName: "",
  lastName: "",
  countriesName: [],
  statesName: [],
  addresses: [],
  address: "",
  selectedCountry: "",
  country_id: 0,
  selectedState: "",
  state_id: 0,
  city: "",
  zipCode: "",
  phoneNumber: "",
  faxNumber: "",
  delivery_id: null,
  delivery_address_id: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.NEW_PATIENT_DELIVERY_FIRST_NAME:
      return {
        ...state,
        firstName: action.value
      }
    case actionTypes.NEW_PATIENT_DELIVERY_LAST_NAME:
      return {
        ...state,
        lastName: action.value
      }
    case actionTypes.NEW_PATIENT_DELIVERY_ADDRESS_ID:
      return {
        ...state,
        delivery_id: action.value,
        addresses: [
          ...state.addresses,
          {
            delivery_address_id: action.value,
            first_name: state.firstName,
            last_name: state.lastName,
            address_1: state.address,
            city: state.city,
            country_id: state.country_id,
            state_province_id: state.state_id,
            postcode: state.zipCode,
            phone_no: state.phoneNumber,
            fax_no: state.faxNumber,
          }
        ]
      }
    case actionTypes.NEW_PATIENT_DELIVERY_ADDRESS:
      return {
        ...state,
        address: Array.isArray(action.address) && action.address.length > 0 ? action.address[0].address_1 : "",

        city: Array.isArray(action.address) && action.address.length > 0 ? action.address[0].city : "",
        zipCode: Array.isArray(action.address) && action.address.length > 0 ? action.address[0].postcode : "",
        phoneNumber: Array.isArray(action.address) && action.address.length > 0 ? action.address[0].phone_no : "",

        delivery_id: Array.isArray(action.address) && action.address.length > 0 ? action.address[0].delivery_address_id : action.address
      }
    case actionTypes.NEW_PATIENT_DELIVERY_ADDRESS_INIT:
      return {
        ...state,
        countriesName: action.country,
        selectedCountry: action.country[0].country_id,
        country_id: action.country[0].country_id,
        statesName: action.state,
        selectedState: action.state[0].state_province_id,
        state_id: action.state[0].state_province_id,
        addresses: action.address,

        city: Array.isArray(action.address) && action.address.length > 0 ? action.address[0].city : "",
        zipCode: Array.isArray(action.address) && action.address.length > 0 ? action.address[0].postcode : "",
        phoneNumber: Array.isArray(action.address) && action.address.length > 0 ? action.address[0].phone_no : "",
        faxNumber: Array.isArray(action.address) && action.address.length > 0? action.address[0].fax_no : "",

        address: Array.isArray(action.address) && action.address.length > 0 ? action.address[0].address_1 : "",
        delivery_id: Array.isArray(action.address) && action.address.length > 0 ? (action.delivery_address_id ? action.delivery_address_id : action.address[0].delivery_address_id) : "New Address"
      }
    case actionTypes.NEW_PATIENT_DELIVERY_ADDRESS_LINE:
      return { ...state, address: action.value }

    case actionTypes.NEW_PATIENT_DELIVERY_ADDRESS_COUNTRY:
      const firstState = state.statesName.find(s => s.country_id == action.value)
      return {
        ...state,
        selectedCountry: action.value,
        country_id: action.value,
        selectedState: firstState ? firstState.state_province_id : null,
        state_id: firstState ? firstState.state_province_id : null
      }

    case actionTypes.NEW_PATIENT_DELIVERY_ADDRESS_STATE:
      return {
        ...state,
        selectedState: action.value,
        state_id: action.value
      }
    case actionTypes.NEW_PATIENT_DELIVERY_ADDRESS_CITY:
      return {
        ...state,
        city: action.value,
      }
    case actionTypes.NEW_PATIENT_DELIVERY_ADDRESS_ZIPCODE:
      return {
        ...state,
        zipCode: action.value,
      }
    case actionTypes.NEW_PATIENT_DELIVERY_ADDRESS_PHONE:
      return {
        ...state,
        phoneNumber: action.value,
      }
    case actionTypes.NEW_PATIENT_DELIVERY_ADDRESS_FAX:
      return {
        ...state,
        faxNumber: action.value,
      }
  }
  return state;
};

export default reducer;