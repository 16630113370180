import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import axios from '../axios-default'
import { easySnackbar } from '../store/Noti/noti.actions'

export default function EmailLog(props) {
  const { logId } = useParams(),
        dispatch = useDispatch()
  const [content, setContent] = useState('')

  useEffect(() => {
    (async () => {
      try {
        const { data: { subject, content} } = await axios.get(`/email/log/${logId}`)
        document.title = subject
        setContent(content)
      } catch (err) {
        dispatch(easySnackbar(err.message || err, 'error'))
      }
    })()
  }, [])

  return (
    <div dangerouslySetInnerHTML={{__html: content}} />
  )
}