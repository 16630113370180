
Array.prototype.asyncForEach = function (callbackfn, thisArg) {
  return this.reduce(async (p, _, index) => {
    await p
    return Promise.resolve(callbackfn.bind(thisArg || this)(this[index], index, this))
  }, Promise.resolve())
}

Array.ensureArray = function (sth) {
  if (Array.isArray(sth) || typeof sth === 'undefined' || sth === null) {
    return sth
  } else {
    return [sth]
  }
}