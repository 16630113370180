import Path from 'path'
import {
  AmbientLight, BufferGeometry, CatmullRomCurve3, Color, CylinderBufferGeometry, DoubleSide,
  FrontSide, GridHelper, Line, LineBasicMaterial, Mesh, MeshBasicMaterial, MeshPhongMaterial,
  Object3D, OrthographicCamera, Raycaster, Scene, SphereGeometry, Spherical, SpotLight,
  TubeBufferGeometry, Vector2, Vector3, WebGLRenderer, Uint32BufferAttribute, Float32BufferAttribute
} from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { GUI } from 'three/examples/jsm/libs/dat.gui.module'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'
import { CSS2DObject, CSS2DRenderer } from 'three/examples/jsm/renderers/CSS2DRenderer'
import { BufferGeometryUtils } from 'three/examples/jsm/utils/BufferGeometryUtils'

import { Tween, update } from '@tweenjs/tween.js'

import { ViewAnimation } from './utils/ViewAnimation'
import { safeGet } from '../../../axios-default'

import { ApiServerUrl } from '../../../config.json'

global.self = window.self

const DEBOUNCE_DELAY_MS = 100

Array.prototype.asyncForEach = function (callbackfn, thisArg) {
  return this.reduce(async (p, _, index) => {
    await p
    return Promise.resolve(callbackfn.bind(thisArg || this)(this[index], index, this))
  }, [])
}

const CustomViewer = function ({
  containerElem, iprBtnElem, gridBtnElem, superBtnElem
}) {
  this.superBtn = superBtnElem
  this.gridBtn = gridBtnElem
  this.iprBtn = iprBtnElem
  this.element = containerElem
  this.canvasWidth = this.element.clientWidth
  this.canvasHeight = this.element.clientHeight
  // this.camera = new PerspectiveCamera(50, this.canvasWidth / this.canvasHeight, 1, 10000)
  this.camera = new OrthographicCamera(this.canvasWidth / - 2, this.canvasWidth / 2, this.canvasHeight / 2, this.canvasHeight / - 2, .1, 500000)
  this.scene = new Scene()
  this.iprObj = new IprObject()
  this.iprMode = false
  this.iprVisible = false
  this.renderer = new WebGLRenderer({
    antialias: true,
    // logarithmicDepthBuffer: true
  })
  this.animationRequestId = null
  // ,logarithmicDepthBuffer: true
  this.labelRenderer = new CSS2DRenderer()
  this.controls = null
  this.composer = null
  this.renderPass = null
  this.outlinePass = null
  this.addedObjects = []
  // this.render = null
  // this.animate = null
  this.mouse = new Vector2()
  this.gltfArray = []
  this.animateObject = []
  this.archOpen = false
  this.gridHelper = null
  this.gridHelper2 = null
  this.tempObj = null
  this.time = null
  this.scaleLine = null
  this.viewAnimation = null
  this.animation = {
    noOfAnimatedObjects: null,
    playbackSpeed: null,
    playMode: false,
    frame: false,
    speedFactor: 10
  }
  this.imposeMode = false
  // this.animation.playbackSpeed = this.animation.speedFactor / $("#speed").val()
  this.animation.playbackSpeed = this.animation.speedFactor / 1.0
  this.onStepChanged = null

  let scope = this

  this.drawLine = function (p1, p2, parent, lineSize, color) {
    let g = new CylinderBufferGeometry(lineSize, lineSize, 1, 8)
    g.translate(0, 0.5, 0)
    g.rotateX(Math.PI * 0.5)
    let m = new MeshBasicMaterial({ color: color, side: DoubleSide })
    let l = new Mesh(g, m)
    l.scale.z = p1.distanceTo(p2)
    l.position.copy(p1)
    l.lookAt(p2)
    parent.add(l)
    return l
  }
  this.adjustZoom = function () {
    let widthToHeightRatio = this.canvasWidth / this.canvasHeight
    if (this.canvasWidth >= this.canvasHeight) {
      return this.canvasWidth * (10 / 1920) * (2.33 / widthToHeightRatio)
    } else {
      if (this.canvasWidth / this.canvasHeight > 0.8) {
        return this.canvasWidth * (10 / 1920) * (2.33)
        // return this.canvasWidth * (10 / 1920) * (2.33 / widthToHeightRatio) * widthToHeightRatio
      } else {
        return 2.33 / widthToHeightRatio
      }
    }
  }
  this.initViewer = () => {
    this.scene.background = new Color("rgb(112, 114, 115)")
    this.scene.add(this.camera)
    this.scene.add(this.iprObj.mainObj)
    this.camera.position.set(0, 0, 200)
    this.camera.zoom = this.adjustZoom()
    this.renderer.setSize(this.canvasWidth, this.canvasHeight)
    this.element.appendChild(this.renderer.domElement)
    this.renderer.domElement.id = "mainCanvas"
    this.controls = new OrbitControls(this.camera, this.renderer.domElement)
    this.controls.autoRotate = false
    this.controls.autoRotateSpeed = 4.0

    this.labelRenderer.domElement.style.position = 'absolute'
    this.labelRenderer.domElement.style.top = '0px'
    this.labelRenderer.domElement.style['pointer-events'] = 'none'
    this.element.appendChild(this.labelRenderer.domElement)

    this.labelRenderer.setSize(this.element.clientWidth, this.element.clientHeight)
    // post processing
    this.composer = new EffectComposer(this.renderer)

    this.renderPass = new RenderPass(this.scene, this.camera)
    this.composer.addPass(this.renderPass)

    this.viewAnimation = new ViewAnimation(this)

    this.outlinePass = new OutlinePass(new Vector2(this.canvasWidth, this.canvasHeight), this.scene, this.camera)
    this.composer.addPass(this.outlinePass)
    this.outlinePass.edgeStrength = Number(.8)
    this.outlinePass.edgeGlow = Number(1)
    this.outlinePass.edgeThickness = Number(3)
    this.outlinePass.pulsePeriod = Number(0)
    this.outlinePass.visibleEdgeColor.set(0xffffff)

    this.gridHelper = new GridHelper(4000, 400, new Color("rgb(82,83,85)"), new Color("rgb(82,83,85)"))
    this.gridHelper.name = "grid1"
    this.camera.add(this.gridHelper)
    this.gridHelper.rotation.x = Math.PI / 2
    this.gridHelper.position.z = -1
    // this.gridHelper.lookAt(this.camera.position)
    this.gridHelper.material.transparent = true
    this.gridHelper.renderOrder = 0
    //======
    this.gridHelper2 = new GridHelper(4000, 4000, new Color("rgb(85,87,88)"), new Color("rgb(85,87,88)"))
    this.gridHelper2.name = "grid2"
    this.camera.add(this.gridHelper2)
    this.gridHelper2.rotation.x = Math.PI / 2
    this.gridHelper2.position.z = -1
    // this.gridHelper2.lookAt(this.camera.position)
    this.gridHelper2.material.transparent = true
    this.gridHelper2.renderOrder = 0
    //======
    this.gridHelper.visible = true
    this.gridHelper2.visible = true
    this.gridBtn.style.backgroundColor = 'grey'

    this.onClickDebounceTimer = null
    this.prevent = false

    const scaleLineMaterial = new LineBasicMaterial({
      color: 0x0000ff
    })
    const points = []
    points.push(new Vector3(0, 0, 0))
    points.push(new Vector3(0, 1, 0))

    const scaleLineGeometry = new BufferGeometry().setFromPoints(points)

    const line = new Line(scaleLineGeometry, scaleLineMaterial)
    line.position.z = -1
    // this.gridHelper.add(line)
    this.scaleLine = this.drawLine(points[0], points[1], this.camera, .08, 0x000000)
    this.scaleLine.position.x = 40
    this.scaleLine.position.y = -20
    this.scaleLine.position.z = -1
    this.scaleLine.rotation.x = Math.PI / 2
    this.scaleLine.scale.set((this.canvasWidth * 10 / 1920) / this.camera.zoom, (this.canvasWidth * 10 / 1920) / this.camera.zoom, (this.canvasWidth * 10 / 1920) / this.camera.zoom)

    const scaleLabel = document.createElement('div')
    scaleLabel.className = 'scaleLabel'
    scaleLabel.textContent = '1 mm'
    // earthDiv.style.marginTop = '-1em'
    const earthLabel = new CSS2DObject(scaleLabel)
    earthLabel.position.set(0.5, 0, 0.5)
    this.scaleLine.add(earthLabel)
    this.scaleLabel = scaleLabel
    // $('#occlusionBar').hide()


    this.controls.addEventListener('change', () => {
      if (this.camera.zoom > 60) {
        this.scaleLine.position.set(1, -0, -1)
      } else if (this.camera.zoom > 40) {
        this.scaleLine.scale.set(0.4, 1, 1)
        this.scaleLine.position.set(8, -3, -1)
      } else if (this.camera.zoom > 30) {
        this.scaleLine.scale.set(0.4, 1, 1)
        this.scaleLine.position.set(15, -5, -1)
      } else if (this.camera.zoom > 20) {
        this.scaleLine.scale.set(0.6, 1, 1)
        this.scaleLine.position.set(22, -8, -1)
      } else if (this.camera.zoom > 15) {
        this.scaleLine.scale.set(0.8, 1, 1)
        this.scaleLine.position.set(28, -12, -1)
      } else if (this.camera.zoom > 10) {
        this.scaleLine.scale.set(1, 1, 1)
        this.scaleLine.position.set(40, -15, -1)
        // $('.scaleLabel').css("margin-left", '18px')
        this.scaleLabel.style.marginLeft = '18px'
      } else {
        this.scaleLine.position.set(50, -25, -1)
        // $('.scaleLabel').css("margin-left", '23px')
        this.scaleLabel.style.marginLeft = '23px'
      }
      if (this.camera.zoom >= 60) {
        this.gridHelper.scale.set(0.1, 0.1, 0.1)
        this.gridHelper2.scale.set(0.1, 0.1, 0.1)
        // this.scaleLine.scale.set(2, 10, 10)
      } else {
        this.gridHelper.scale.set(1, 1, 1)
        this.gridHelper2.scale.set(1, 1, 1)
      }
    })
  }

  this.getObjectByName = (name) => {
    const ret = this.scene.getObjectByName(name)
    if (ret) return ret
    else return {}
  }

  this.objectAnimation = () => {
    if (this.animation.frame < ((this.animation.noOfAnimatedObjects / 2) * this.animation.playbackSpeed) - 1) {
      this.animation.frame++
    } else {
      this.animation.frame = 0
    }
    let groupNumber = Math.floor(this.animation.frame / this.animation.playbackSpeed) + 1
    let groupNumberP = groupNumber - 1

    this.showIpr()

    // TODO: shouldn't assume animated objects are pairs
    this.iprObj.updateAnimationIPR(groupNumber, this.animation.noOfAnimatedObjects)
    if (this.animation.noOfAnimatedObjects > 0) {
      this.getObjectByName('l' + groupNumber.toString()).visible = true
      this.getObjectByName('u' + groupNumber.toString()).visible = true
      if (groupNumber == 1) {
        this.getObjectByName('l' + ((this.animation.noOfAnimatedObjects / 2)).toString()).visible = false
        this.getObjectByName('u' + ((this.animation.noOfAnimatedObjects / 2)).toString()).visible = false
      } else {
        this.getObjectByName('l' + groupNumberP.toString()).visible = false
        this.getObjectByName('u' + groupNumberP.toString()).visible = false
      }
    }
    if (this.onStepChanged) {
      this.onStepChanged(groupNumberP)
    }
    // this.onStepChanged?.(groupNumberP)
    this.stepNo = groupNumberP
  }

  this.render = () => {
    if (this.animation.playMode) {
      this.objectAnimation()
    }
    this.viewAnimation.update()
    this.controls.update()
    update()
    this.composer.render()
  }

  this.animate = () => {
    this.animationRequestId = requestAnimationFrame(this.animate.bind(this))
    this.render()
    this.labelRenderer.render(this.scene, this.camera)
  }

  this.guiInit = () => {
    let gui = new GUI({ width: 300 })
    gui.open()

    let param = {
      teethColor: new Color('rgb(247, 247, 230)').getHex(),
      gumColor: new Color('rgb(188, 100, 98)').getHex(),
      opacity: this.VertexMat.opacity,
      specular: this.VertexMat.specular.getHex(),
      shininess: this.VertexMat.shininess,
      lightColor: this.spotLightCamera.color.getHex(),
      intensity: this.spotLightCamera.intensity,
      ambLight_color: this.ambientLight.color.getHex(),
      ambIntensity: this.ambientLight.intensity,
    }

    let materialFolder = gui.addFolder('Material')
    materialFolder.add(param, 'opacity', 0, 1).step(0.1).onChange(val => {
      this.VertexMat.opacity = val
    })
    materialFolder.add(param, 'shininess', 0, 2000).step(10).onChange(val => {
      this.VertexMat.shininess = val
    })
    materialFolder.addColor(param, 'specular').onChange(val => {
      this.VertexMat.specular.setHex(val)
    })
    materialFolder.addColor(param, 'teethColor').onChange(val => {
      let col = new Color().setHex(val)
      this.updateColor('upper', col, 'teeth')
      this.updateColor('lower', col, 'teeth')
    })
    materialFolder.addColor(param, 'gumColor').onChange(val => {
      let col = new Color().setHex(val)
      this.updateColor('upper', col, 'gum')
      this.updateColor('lower', col, 'gum')
    })
    materialFolder.open()

    let lightFolder = gui.addFolder('light')
    lightFolder.addColor(param, 'lightColor').onChange(val => {
      this.spotLightCamera.color.setHex(val)
    })
    lightFolder.add(param, 'intensity', 0, 1).step(0.1).onChange(val => {
      this.spotLightCamera.intensity = val
    })

    let AmbLightFolder = gui.addFolder('Ambient Light')
    AmbLightFolder.addColor(param, 'ambLight_color').onChange(val => {
      this.ambientLight.color.setHex(val)
    })
    AmbLightFolder.add(param, 'ambIntensity', 0, 1).step(0.1).onChange(val => {
      this.ambientLight.intensity = val
    })

    for (let item of document.getElementsByClassName('dg')) {
      item.style.zIndex = 5
    }
  }

  this.updateOrthoCamara = () => {
    this.camera.left = this.canvasWidth / - 2
    this.camera.right = this.canvasWidth / 2
    this.camera.right = this.canvasWidth / 2
    this.camera.top = this.canvasHeight / 2
    this.camera.bottom = this.canvasHeight / -2
  }

  this.onWindowResize = () => {
    this.canvasWidth = this.element.clientWidth
    this.canvasHeight = this.element.clientHeight
    this.camera.aspect = this.canvasWidth / this.canvasHeight
    this.updateOrthoCamara()
    this.camera.zoom = this.adjustZoom()
    this.camera.updateProjectionMatrix()

    this.controls.update()
    this.renderer.setSize(this.canvasWidth, this.canvasHeight)
    this.labelRenderer.setSize(this.canvasWidth, this.canvasHeight)
    this.composer.setSize(this.canvasWidth, this.canvasHeight)
  }

  this.checkIntersectionClick = () => {
    let raycaster = new Raycaster()
    raycaster.setFromCamera(this.mouse, this.camera)
    let tempArr = this.addedObjects.filter((element) => {
      return element.parent.parent.visible && element.visible
    })
    let intersectionclick = raycaster.intersectObjects(tempArr, true)
    if (intersectionclick.length > 0) {
      if (this.iprMode) {
        const geometry = new SphereGeometry(1.5, 32, 32)
        const material = new MeshBasicMaterial({ color: new Color('rgb(0, 133, 138)') })
        const sphere = new Mesh(geometry, material)
        let size = prompt("Please enter value")
        if (!size) return
        let modelNo = intersectionclick[0].object.name.match(/\d+/g).map(Number)[0];
        if (intersectionclick[0].object.name.charAt(0) == 'l') {
          this.iprObj.findorCreateGroup(modelNo, this.iprObj.lowerIPR).add(sphere)
        } else {
          this.iprObj.findorCreateGroup(modelNo, this.iprObj.upperIPR).add(sphere)
        }
        sphere.position.copy(intersectionclick[0].point)

        sphere.lookAt(0, intersectionclick[0].point.y, 0)
        let positions = [new Vector3(0, 0, 0), new Vector3(0, 0, -10), new Vector3(0, 0, -20), new Vector3(0, 20, -30)]
        // if (intersectionclick[0].point.x < 0) {
        //   positions = [new Vector3(0, 0, 0), new Vector3(0, 0, 0), new Vector3(0, 0, 20), new Vector3(0, 20, 30)]
        // }
        if (intersectionclick[0].object.name.charAt(0) == 'l') {
          positions[3].y = -positions[3].y
          // positions = [new Vector3(0, 0, 0), new Vector3(-10, 0, 0), new Vector3(-20, 0, 0), new Vector3(-30, 20, 0)]
        }
        const curve = new CatmullRomCurve3(positions, false, 'chordal')

        let tubeGeometry = new TubeBufferGeometry(curve, 100, .05, 10, false)
        let material1 = new MeshBasicMaterial({ color: 0xff0000 })
        let mesh1 = new Mesh(tubeGeometry, material1)

        sphere.add(mesh1)

        let label = document.createElement('div')
        label.className = 'labelIPR'
        label.textContent = size
        label.style.marginTop = '-1em'
        let labelObj = new CSS2DObject(label)
        // holeNoDivLabel.position.x = -0.3
        labelObj.position.copy(positions[3])
        sphere.add(labelObj)
        this.iprMode = false
        // $("#iprAddModeBtn").css("background-color",'')
        this.iprBtn.style.backgroundColor = ''
        document.body.style.cursor = "auto"
      }
    }
  }

  this.onClick = event => {
    this.onClickDebounceTimer = setTimeout(() => {
      if (!this.prevent) {
        this.mouse.x = ((event.clientX - (this.element.getBoundingClientRect().left)) / this.element.clientWidth) * 2 - 1
        this.mouse.y = - ((event.clientY - (this.element.getBoundingClientRect().top)) / this.element.clientHeight) * 2 + 1
        // mouse.x = (event.clientX / viewer.renderArea.clientWidth) * 2 - 1
        // mouse.y = - (event.clientY / viewer.renderArea.clientHeight) * 2 + 1
        this.checkIntersectionClick(event)
        this.prevent = false
      }
    }, DEBOUNCE_DELAY_MS)
  }

  this.checkIntersectionMove = () => {
    let raycaster = new Raycaster()
    raycaster.setFromCamera(this.mouse, this.camera)
    let tempArr = this.addedObjects.filter((element) => {
      return element.parent.parent.visible && element.visible
    })
    let intersectsOnMove = raycaster.intersectObjects(tempArr, true)
    if (intersectsOnMove.length > 0) {
      document.body.style.cursor = "pointer"
    } else {
      document.body.style.cursor = "auto"
    }
  }

  this.onMouseMove = event => {
    this.prevent = true
    setTimeout(() => {
      this.prevent = false
    }, DEBOUNCE_DELAY_MS)
    if (this.iprMode) {
      this.mouse.x = ((event.clientX - (this.element.getBoundingClientRect().left)) / this.element.clientWidth) * 2 - 1
      this.mouse.y = - ((event.clientY - (this.element.getBoundingClientRect().top)) / this.element.clientHeight) * 2 + 1
      this.checkIntersectionMove()
    }
  }

  this.initScene = () => {
    //lighting
    let ambientLight = new AmbientLight(0xFFFFFF, .5) // soft white light
    this.scene.add(ambientLight)
    this.ambientLight = ambientLight

    // light attached to camera
    let spotLightCamera = new SpotLight(0xf2dbdb, .5)
    spotLightCamera.castShadow = true
    spotLightCamera.target.position.set(0, 0, - 1)
    spotLightCamera.add(spotLightCamera.target)
    spotLightCamera.position.set(-2, -2, 0)
    this.camera.add(spotLightCamera)
    this.spotLightCamera = spotLightCamera

    //set grid opacity
    this.gridHelper.material.opacity = .2
    this.gridHelper2.material.opacity = .1

    //create object for upper & lower meshes
    let upperAsm = new Object3D()
    upperAsm.name = "upper"
    upperAsm.renderOrder = 0
    this.scene.add(upperAsm)
    let lowerAsm = new Object3D()
    lowerAsm.name = "lower"
    this.scene.add(lowerAsm)

    //material for meshes
    this.VertexMat = new MeshPhongMaterial({
      vertexColors: true,
      reflectivity: .5,
      transparent: false,
      opacity: 1,
      side: FrontSide,
      specular: "rgb(79, 13, 13)",
      shininess: 1500,
    })

    this.animate()
    // this.guiInit()

    window.addEventListener('resize', this.onWindowResize.bind(this))
    this.element.addEventListener('pointerdown', this.onClick.bind(this))
    this.element.addEventListener('pointermove', this.onMouseMove.bind(this))
  }

  this.zoomInToDefalut = () => {
    if (this.camera.zoom.toFixed(0) == this.adjustZoom().toFixed(0)) {
    } else {

      let factor = (this.adjustZoom() - this.camera.zoom) / 30
      let zoomCount = 0

      let id = setInterval(() => {
        if (zoomCount == 30) {
          this.controls.target.set(0, 0, 0)
          this.controls.enabled = false
          clearInterval(id)
        } else {
          this.camera.zoom += factor
          this.camera.updateProjectionMatrix()
          this.controls.update()
          zoomCount++
        }
      }, 15)
    }
  }

  this.moveCamera = (pos, rot, xAxis) => {
    let currentPos = this.camera.position.clone()
    let currentSphere = new Spherical().setFromCartesianCoords(this.camera.position.y, this.camera.position.z, this.camera.position.x)
    let desiredSphere = new Spherical().setFromCartesianCoords(pos.y, pos.z, pos.x)
    let currentTheta = currentSphere.theta
    let currentPhi = currentSphere.phi

    let theta = desiredSphere.theta
    let phi = desiredSphere.phi
    let deltaTheta = (theta - currentTheta) / 60
    let deltaPhi = (phi - currentPhi) / 60
    let count = 0
    let startRot, endRot, finalVec, dVec
    if (xAxis) {
      startRot = new Vector3(this.camera.rotation.x, this.camera.rotation.y, this.camera.rotation.z)
      endRot = rot
      let subVec = new Vector3().subVectors(endRot, startRot)
      let normal = subVec.clone().normalize()

      dVec = normal.setLength(subVec.length() / 60)
      finalVec = startRot.clone()
      this.controls.enabled = false
    }

    let id1 = setInterval(() => {
      if (count == 60) {
        clearInterval(id1)
        this.camera.rotation.x = -Math.PI / 2
        this.camera.rotation.y = 0
        this.camera.rotation.z = 0
        this.camera.position.copy(pos)
        this.controls.enabled = true

      } else {
        count++
        let v = new Vector3().setFromSphericalCoords(50, currentPhi += deltaPhi, currentTheta += deltaTheta)

        this.camera.position.set(v.z, v.x, v.y)
      }
    }, 10)
  }

  this.cameraTween = (newPosition, newRotation) => {
    this.controls.target.set(0, 0, 0)
    this.controls.enabled = false
    let toggleGrid = this.gridHelper.visible
    this.gridHelper.visible = false
    this.gridHelper2.visible = false
    let tween1 = new Tween(this.camera.position)
      .to({
        x: newPosition.x,
        y: newPosition.y,
        z: newPosition.z
      }, 500)
      .start()
      .onComplete(() => {
        // controls.enabled = true
      })

    let tween2 = new Tween(this.camera.rotation)
      .to({
        x: newRotation.x,
        y: newRotation.y,
        z: newRotation.z
      }, 500)
      .start()
      .onUpdate(() => {
        // console.log(this.camera.rotation)
      })
      .onComplete(() => {
        if (toggleGrid) {
          this.gridHelper.visible = true
          this.gridHelper2.visible = true
        }
        this.controls.enabled = true
      })
  }

  this.ObjectTween = (obj, rotation) => {
    this.controls.enabled = false
    let tween1 = new Tween(obj.rotation)
      .to({
        x: rotation.x,
        y: rotation.y,
        z: rotation.z
      }, 500)
      .start()
      .onComplete(() => {
        this.controls.enabled = true
      })
  }

  this.setView = async (newPosition, newRotation, xaxis) => {
    this.zoomInToDefalut()
    let countPos = 0
    let startPos = this.camera.position.clone().round()
    let endPos = newPosition.clone().round()
    if (startPos.x.toFixed(2) == endPos.x.toFixed(2)) {
      countPos++
    } if (startPos.y.toFixed(2) == endPos.y.toFixed(2)) {
      countPos++
    } if (startPos.z.toFixed(2) == endPos.z.toFixed(2)) {
      countPos++
    }

    //if start and end position is same, no animation
    if (countPos == 3) {
      return
    }

    if (this.camera.position.x.toFixed(0) == newPosition.x.toFixed(0)) {
      this.viewAnimation.setView(newPosition, newRotation, true)
    } else {
      if (!xaxis) {
        if (this.camera.position.y.toFixed(2) != 0 && endPos.y == 0) {

          this.cameraTween(newPosition, newRotation)

        } else {
          if (countPos > 0) {
            this.viewAnimation.setView(newPosition, newRotation, xaxis)
          } else {
            this.moveCamera(newPosition, newRotation, xaxis)
          }
        }
      } else {
        this.cameraTween(newPosition, newRotation)
      }
    }
  }

  this.importObj = async (file, toleranceY, parent, jaw) => {
    const loader = new OBJLoader()
    // cookie missing?
    const objBuf = await safeGet(file, {
      baseURL: ApiServerUrl,
      responseType: 'text'
    })
    const obj = loader.parse(objBuf)
    obj.traverse(e => {
      if (e.isMesh) {
        let newbuffer = BufferGeometryUtils.mergeVertices(e.geometry)
        newbuffer.computeVertexNormals()
        let material = new MeshBasicMaterial({ vertexColors: true })
        let newMesh = new Mesh(newbuffer, material)
        newMesh.position.y = toleranceY
        newMesh.visible = false
        parent.add(newMesh)
        if (toleranceY == 0) {
          newMesh.visible = true
          material = new MeshBasicMaterial({
            vertexColors: false,
            transparent: true,
            opacity: 0.45,
            side: FrontSide,
            color: new Color('rgb(0, 0, 110)'),
            polygonOffset: true,
            polygonOffsetFactor: -10,
          })
          newMesh.material = material
          newMesh.visible = false
          newMesh.name = jaw + '_white'
        }
      }
    })
  }

  this.importGltfFromWeb = async (gltfList, occList, onProgress) => {
    this.animation.noOfAnimatedObjects = gltfList.length

    const rangeTemp = document.getElementById('rangeTemp')
    // for (let i = 0; i < 6; i++) {
    //   const loadingDiv = document.createElement('div')
    //   loadingDiv.classList.add('tempDiv')
    //   rangeTemp.appendChild(loadingDiv)
    // }

    let barSet = 94 / gltfList.length
    let initSet = 6
    // bar1.set(initSet)
    let totalModels = gltfList.length
    let loadedModels = 0

    let finished = 0

    this.maxStep = gltfList.reduce((acc, gltf) => Math.max(gltf.model_no, acc), 0)
    this.stepNo = 0

    const loader1 = new GLTFLoader()
    // Optional: Provide a DRACOLoader instance to decode compressed mesh data
    const dracoLoader1 = new DRACOLoader()
    dracoLoader1.setDecoderPath('/draco/')
    // dracoLoader.setDecoderConfig({ type: 'js' })
    loader1.setDRACOLoader(dracoLoader1)

    await Promise.all(gltfList.map(async ({ file, model_no, jaw }) => {
      // cookie missing?
      const gltfBuf = await safeGet(file, {
        baseURL: ApiServerUrl,
        responseType: 'arraybuffer'
      })
      return new Promise((resolve, reject) => {
        loader1.parse(gltfBuf, '', gltf => {
          const promises = []
          gltf.scene.traverse(e => {
            if (e.isMesh) {
              this.animateObject.push(e) //animattion Array
              e.geometry.computeVertexNormals()
              e.material = this.VertexMat
              e.visible = false
              this.outlinePass.selectedObjects.push(e)

              // let filename = Path.basename(rPath)
              let path = Path.dirname(file)
              let filename = Path.basename(file).slice(0, -Path.extname(file).length ?? 0)
              // let filename = elemPath.split('/')[elemPath.split('/').length - 1].split('.')[0]

              // let jaw = filename.includes('Lower') ? 'l' : 'u'
              // let extactedNumbers = filename.match(/\d+/g).map(Number)
              // let model_no = extactedNumbers[extactedNumbers.length - 1]
              let meshName = jaw + model_no

              e.name = meshName
              e.visible = (model_no == 1) ? true : false

              let toleranceY = 0.
              if (jaw === 'l') {
                toleranceY = 0.05
              } else {
                toleranceY = -0.05
              }
              const occ = occList.find(({ file: _file, model_no: _model_no, jaw: _jaw, type }) =>
                model_no === _model_no && jaw === _jaw && type === 'OCC'
              )
              if (occ) {
                promises.push(this.importObj(occ.file, toleranceY, e))
              }
              this.addedObjects.push(e)
              if (jaw == 'u') {
                this.scene.getObjectByName('upper').add(e.parent)
              } else {
                this.scene.getObjectByName('lower').add(e.parent)
              }
              let intArr = new Int32Array(e.geometry.attributes.position.array.length)
              e.geometry.attributes.position.array.forEach((e, i) => {
                intArr[i] = parseInt(e * 10000)
              })
              let floatArr = new Float32Array(intArr.length)
              intArr.forEach((e, i) => {
                floatArr[i] = parseFloat(e / 10000)
              })
              let floatBuffer = new Float32BufferAttribute(floatArr, 3)
              e.geometry.setAttribute('position', floatBuffer);
              if (model_no == 1) {
                // live impose
                let newGeo = e.geometry.clone()
                let countFaces = 0
                for (var x = 0; x < newGeo.index.array.length; x += 3) {

                  if (newGeo.attributes.color.array[newGeo.index.array[x] * 3] * 255 >= 235) {
                    // console.log("white face");
                    countFaces++
                  }
                }
                let indexArr = new Uint32Array(countFaces * 3)
                let co = 0
                let deleteArr = []
                for (var x = 0; x < newGeo.index.array.length; x += 3) {

                  if (newGeo.attributes.color.array[newGeo.index.array[x] * 3] * 255 >= 235) {
                    indexArr[co++] = newGeo.index.array[x]
                    indexArr[co++] = newGeo.index.array[x + 1]
                    indexArr[co++] = newGeo.index.array[x + 2]
                  }
                }

                let newGeo2 = e.geometry.clone()
                newGeo2.setIndex(new Uint32BufferAttribute(indexArr, 1));
                newGeo2.computeVertexNormals()
                let newMesh1 = new Mesh(newGeo2, new MeshBasicMaterial({
                  vertexColors: false,
                  transparent: true,
                  opacity: 0.45,
                  side: FrontSide,
                  color: new Color('rgb(0, 0, 110)'),
                  polygonOffset: true,
                  // polygonOffsetFactor: -0.2,
                  polygonOffsetUnits: -2,
                  depthWrite: false,
                  // flatShading : false

                }));
                newMesh1.visible = false
                newMesh1.name = jaw + '_white'
                this.scene.add(newMesh1)
                e.geometry.computeBoundingBox()
                // this.importObj(`${path}/${filename}_white.obj`, 0, this.scene, jaw)
              }
            }
          })
          finished += 1
          onProgress(finished, gltfList.length)
          Promise.all(promises).then(() => resolve())
        }, err => {
          console.error(`Cannot load ${file}`)
          reject(err)
        })
      })
    }))
  }

  this.importIpr = iprObj => {
    if (typeof iprObj.noOfIprData !== 'number' || !Array.isArray(iprObj.data)) {
      return
    }
    if (iprObj.noOfIprData !== iprObj.data.length) {
      return
    }
    iprObj.data.forEach(({ jaw, model_no, values, position: positions }) => {
      if (values.length !== positions.length) {
        return
      }
      for (let i = 0; i < values.length; i++) {
        const value = values[i],
              position = positions[i]
        const geometry = new SphereGeometry(1.5, 32, 32)
        const material = new MeshBasicMaterial({ color: new Color('rgb(0, 133, 138)') })
        
        const sphere = new Mesh(geometry, material)
        sphere.position.copy(position)
        this.iprObj.add(sphere)
        
        let _positions = [new Vector3(0, 0, 0), new Vector3(10, 0, 0), new Vector3(20, 0, 0), new Vector3(30, 20, 0)]
        if (position.x < 0) {
          _positions = [new Vector3(0, 0, 0), new Vector3(-10, 0, 0), new Vector3(-20, 0, 0), new Vector3(-30, 20, 0)]
        }
        if (jaw[0].toLowerCase() == 'l') {
          _positions[3].y = -_positions[3].y
        }
        
        const curve = new CatmullRomCurve3(_positions, false, 'chordal')
        let tubeGeometry = new TubeBufferGeometry(curve, 100, .05, 10, false)
        let material1 = new MeshBasicMaterial({ color: 0xff0000 })
        let mesh1 = new Mesh(tubeGeometry, material1)
        sphere.add(mesh1)
        sphere.name = `ipr_${jaw[0].toLowerCase()}${model_no}_${i}`

        const size = parseFloat(value).toFixed(2)
        let label = document.createElement('div')
        label.className = 'labelIPR'
        label.textContent = size
        label.style.marginTop = '-1em'
        let labelObj = new CSS2DObject(label)
        // holeNoDivLabel.position.x = -0.3
        labelObj.position.copy(_positions[3])
        sphere.add(labelObj)
      }
    })

    this.setStep(this.stepNo)
  }

  this.setStep = stepNo => {
    this.stepNo = stepNo
    this.showIpr()
    for (let i = 0; i < this.maxStep ?? 0; i++) {
      this.getObjectByName('l'+(i+1).toString()).visible = i === stepNo
      this.getObjectByName('u'+(i+1).toString()).visible = i === stepNo
      this.iprObj.setStepIPR(this.stepNo, i)
    }
    this.animation.frame = stepNo * this.animation.playbackSpeed
  }

  this.previous = () => {
    this.animation.playMode = false
    this.setStep(Math.max(0, this.stepNo - 1))
    if (this.onStepChanged) {
      this.onStepChanged(this.stepNo)
    }
    // this.onStepChanged?.(this.stepNo)
  }

  this.next = () => {
    this.animation.playMode = false
    this.setStep(Math.min(this.maxStep - 1, this.stepNo + 1))
    if (this.onStepChanged) {
      this.onStepChanged(this.stepNo)
    }
    // this.onStepChanged?.(this.stepNo)
  }

  this.setSpeed = speed => {
    let isPlaying = this.animation.playMode,
        oldSpeed = this.animation.playbackSpeed
    this.animation.playMode = false
    this.animation.playbackSpeed = this.animation.speedFactor / speed
    this.animation.frame = Math.floor(this.animation.frame * this.animation.playbackSpeed / oldSpeed)
    if (isPlaying) this.animation.playMode = true
  }

  this.showOcclusion = () => {
    if (this.archOpen) {
      setTimeout(() => {
        this.iprObj.traverse(e => {
          e.visible = true
        })
        this.getObjectByName("l_white").visible = this.imposeMode
        this.getObjectByName("u_white").visible = this.imposeMode
      }, 500)
      this.getObjectByName('upper').traverse((e) => {
        if (e.parent.name == 'upper') {
          this.ObjectTween(e, new Vector3(0, 0, 0))
          //hide occlusion
          if (e.children[0].children[0]) e.children[0].children[0].visible = false
        }
      })
      this.getObjectByName('lower').traverse((e) => {
        if (e.parent.name == 'lower') {
          this.ObjectTween(e, new Vector3(0, 0, 0))
          //hide occlusion
          if (e.children[0].children[0]) e.children[0].children[0].visible = false
        }
      })
      this.archOpen = false
    } else {
      this.iprObj.traverse(e => { e.visible = false })
      this.getObjectByName('l_white').visible = false
      this.getObjectByName('u_white').visible = false

      this.getObjectByName('upper').traverse(e => {
        if (e.parent.name === 'upper') {
          this.ObjectTween(e, new Vector3(-Math.PI / 2, 0, 0))
          if (e.children[0].children[0]) {
            e.children[0].children[0].visible = true
          }
        }
      })
      this.getObjectByName('lower').traverse(e => {
        if (e.parent.name === 'lower') {
          this.ObjectTween(e, new Vector3(Math.PI / 2, 0, 0))
          //show occlusion
          if (e.children[0].children[0]) {
            e.children[0].children[0].visible = true
          }
        }
      })
      this.archOpen = true
    }
  }

  this.showIpr = symbol => {
    const iprPairName = [`ipr_l${this.stepNo + 1}_`, `ipr_u${this.stepNo + 1}_`]
    console.log(this.iprObj)
    if (this.iprObj) {
      this.iprObj.traverse(x => {
        if (x.name?.startsWith('ipr_') || x.parent?.name?.startsWith('ipr_')) {
          x.visible = this.iprVisible
            && (symbol !== 'u' || x.name?.startsWith('ipr_u') || x.parent?.name?.startsWith('ipr_u'))
            && (symbol !== 'l' || x.name?.startsWith('ipr_l') || x.parent?.name?.startsWith('ipr_l'))
            && iprPairName.some(n => 
              x.name?.startsWith(n) || x.parent?.name?.startsWith(n)
            )
        }
      })
    }
  }

  this.control = cmd => {
    switch (cmd) {
      case 'bothArch': {
        this.getObjectByName("u_white").visible = this.imposeMode && !this.archOpen
        this.getObjectByName("l_white").visible = this.imposeMode && !this.archOpen
        this.getObjectByName("upper").visible = true
        this.getObjectByName("lower").visible = true
        if (this.iprObj.lowerIPR) {
          this.iprObj.lowerIPR.visible = true
          this.iprObj.lowerIPR.traverse(e => {
            if (e.element) {
              e.visible = e.parent.parent.visible
            }
          })
        }
        if (this.iprObj.upperIPR) {
          this.iprObj.upperIPR.visible = true
          this.iprObj.upperIPR.traverse((e) => {
            if (e.element) {
              e.visible = e.parent.parent.visible
            }
          })
        }
        if (this.getObjectByName('upper')) {
          this.getObjectByName("upper").traverse((e) => {
            if (e.isMesh) {
              e.layers.enable(1)
            }
          })
        }
        if (this.getObjectByName('lower')) {
          this.getObjectByName("lower").traverse((e) => {
            if (e.isMesh) {
              e.layers.enable(1)
            }
          })
        }
        this.setStep(this.stepNo)
        break
      }
      case 'upperArch': {
        this.getObjectByName("u_white").visible = this.imposeMode && true && !this.archOpen
        this.getObjectByName("upper").visible = true
        this.getObjectByName("l_white").visible = false
        this.getObjectByName("lower").visible = false
        if (this.iprObj.lowerIPR) {
          this.iprObj.lowerIPR.visible = true
          this.iprObj.lowerIPR.traverse((e) => {
            if (e.element) {
              e.visible = true && e.parent.parent.visible
            }
          })
        }
        if (this.iprObj.upperIPR) {
          this.iprObj.upperIPR.visible = true
          this.iprObj.upperIPR.traverse((e) => {
            if (e.element) {
              e.visible = true && e.parent.parent.visible
            }
          })
        }
        this.getObjectByName("upper").traverse((e) => {
          if (e.isMesh) {
            e.layers.enable(1)
          }
        })
        if (this.getObjectByName('lower')) {
          this.getObjectByName("lower").traverse((e) => {
            if (e.isMesh) {
              e.layers.disable(1)
            }
          })
        }
        this.showIpr('u')
        break
      }
      case 'lowerArch': {
        this.getObjectByName("u_white").visible = false
        this.getObjectByName("upper").visible = false
        if (this.iprObj.lowerIPR) {
          this.iprObj.lowerIPR.visible = true
          this.iprObj.lowerIPR.traverse((e) => {
            if (e.element) {
              e.visible = true && e.parent.parent.visible
            }
          })
        }
        if (this.iprObj.upperIPR) {
          this.iprObj.upperIPR.visible = true
          this.iprObj.upperIPR.traverse((e) => {
            if (e.element) {
              e.visible = true && e.parent.parent.visible
            }
          })
        }
        this.getObjectByName("upper").traverse((e) => {
          if (e.isMesh) {
            e.layers.disable(1)
          }
        })
        this.getObjectByName("l_white").visible = this.imposeMode && true && !this.archOpen
        this.getObjectByName("lower").visible = true
        this.getObjectByName("lower").traverse((e) => {
          if (e.isMesh) {
            e.layers.enable(1)
          }
        })
        this.showIpr('l')
        break
      }
      case 'BottomView': {
        this.setView(new Vector3(0, 200, 0), new Vector3(-Math.PI / 2, 0, 0), true)
        this.control('lowerArch')
        break
      }
      case 'TopView': {
        this.setView(new Vector3(0, -200, 0), new Vector3(Math.PI / 2, 0, 0), true)
        this.control('upperArch')
        break
      }
      case 'FrontView': {
        this.setView(new Vector3(0, 0, 200), new Vector3(0, 0, 0), false)
        this.control('bothArch')
        break
      }
      case 'RightView': {
        this.setView(new Vector3(-200, 0, 0), new Vector3(0, -Math.PI / 2, 0), false)
        this.control('bothArch')
        break
      }
      case 'LeftView': {
        this.setView(new Vector3(200, 0, 0), new Vector3(0, Math.PI / 2, 0), false)
        this.control('bothArch')
        break
      }
      case 'gridToggle': {
        this.scaleLine.children[0].visible = !this.getObjectByName("grid1").visible
        this.scaleLine.visible = !this.getObjectByName("grid1").visible
        if (this.getObjectByName("grid1").visible) {
          this.gridBtn.style.backgroundColor = ''
        } else {
          this.gridBtn.style.backgroundColor = 'grey'
        }
        this.getObjectByName("grid1").visible = !this.getObjectByName("grid1").visible
        this.getObjectByName("grid2").visible = !this.getObjectByName("grid2").visible
        break
      }
      case 'iprAddModeBtn': {
        if (this.archOpen) {
          return
        }
        if (this.iprVisible) {
          // $("#iprAddModeBtn").css("background-color","")
          this.iprBtn.style.backgroundColor = ''
          // this.iprMode = false
          this.iprVisible = false
          this.showIpr()
        } else {
          // $("#iprAddModeBtn").css("background-color",'grey')
          this.iprBtn.style.backgroundColor = 'grey'
          // this.iprMode = true
          this.iprVisible = true
          this.setStep(this.stepNo)
        }
        break
      }
      case 'superImposeBtn': {
        if (this.archOpen) return
        this.imposeMode = !this.imposeMode
        if (this.imposeMode) {
          this.superBtn.style.backgroundColor = 'grey'
        } else {
          this.superBtn.style.backgroundColor = ''
        }
        // let bool = this.getObjectByName('l_white').visible
        this.getObjectByName('l_white').visible = this.imposeMode && this.getObjectByName("lower").visible
        this.getObjectByName('u_white').visible = this.imposeMode && this.getObjectByName("upper").visible
        break
      }
    }
  }

  this.play = () => {
    this.animation.playMode = true
  }

  this.pause = () => {
    this.animation.playMode = false
    if (this.onStepChanged) {
      this.onStepChanged(this.stepNo)
    }
    // this.onStepChanged?.(this.stepNo)
  }

  this.destroy = () => {
    window.removeEventListener('resize', this.onWindowResize.bind(this))
    if (this.animationRequestId) cancelAnimationFrame(this.animationRequestId)
    this.controls.dispose()
  }

  this.initViewer()
  this.initScene()
  this.onWindowResize()
}

const IprObject = function () {
  this.mainObj = new Object3D()
  this.mainObj.name = "IPR"
  this.upperIPR = new Object3D()
  this.upperIPR.name = "uIpr"
  this.lowerIPR = new Object3D()
  this.lowerIPR.name = "lIpr"
  this.mainObj.add(this.upperIPR)
  this.mainObj.add(this.lowerIPR)

  this.findorCreateGroup = function (number, teeth) {
    let group
    if (!teeth.getObjectByName(`${number}_iprGroup`)) {
      let iprGroup = new Object3D()
      iprGroup.name = `${number}_iprGroup`
      teeth.add(iprGroup)
      group = iprGroup
    } else {
      group = teeth.getObjectByName(`${number}_iprGroup`)
    }
    return group
  }
  this.updateAnimationIPR = function (number, noOfAnimatedObjects) {
    if (this.lowerIPR.getObjectByName(number.toString() + '_iprGroup')) {
      let group = this.lowerIPR.getObjectByName(number.toString() + '_iprGroup')
      group.visible = true

      group.traverse((e) => {
        if (e.element) {
          e.visible = true && e.parent.parent.parent.visible
        }
      })
    }
    if (this.upperIPR.getObjectByName(number.toString() + '_iprGroup')) {
      let group = this.upperIPR.getObjectByName(number.toString() + '_iprGroup')
      group.visible = true

      group.traverse((e) => {
        if (e.element) {
          e.visible = true && e.parent.parent.parent.visible
        }
      })
    }
    if (number == 1) {
      let groupL = this.lowerIPR.getObjectByName((noOfAnimatedObjects / 2) + '_iprGroup')
      let groupU = this.upperIPR.getObjectByName((noOfAnimatedObjects / 2) + '_iprGroup')

      if (groupL) {
        groupL.visible = false
        groupL.traverse((e) => {
          if (e.element) {
            e.visible = false
          }
        })
      }
      if (groupU) {
        groupU.visible = false
        groupU.traverse((e) => {
          if (e.element) {
            e.visible = false
          }
        })
      }
    } else {
      let groupL = this.lowerIPR.getObjectByName((number - 1).toString() + '_iprGroup')
      let groupU = this.upperIPR.getObjectByName((number - 1).toString() + '_iprGroup')
      if (groupL) {
        groupL.traverse((e) => {
          groupL.visible = false
          if (e.element) {
            e.visible = false
          }
        })
      }
      if (groupU) {
        groupU.visible = false
        groupU.traverse((e) => {
          if (e.element) {
            e.visible = false
          }
        })
      }
    }

  }
  this.setStepIPR = function (number, index) {
    if (this.lowerIPR.getObjectByName((index + 1).toString() + '_iprGroup')) {
      // debugger
      let group = this.lowerIPR.getObjectByName((index + 1).toString() + '_iprGroup')
      group.visible = (index === number)
      group.traverse((e) => {
        if (e.element) {
          e.visible = (index === number) && e.parent.parent.parent.visible
        }
      })
    }
    if (this.upperIPR.getObjectByName((index + 1).toString() + '_iprGroup')) {
      let group = this.upperIPR.getObjectByName((index + 1).toString() + '_iprGroup')
      group.visible = (index === number)
      group.traverse((e) => {
        if (e.element) {
          e.visible = (index === number) && e.parent.parent.parent.visible
        }
      })
    }
  }
}

export default CustomViewer