import {Breadcrumbs, Button, Chip, Grid,MenuItem, Select,TextField,Typography, Radio } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import classnames from "classnames"
import Moment from 'moment/moment'
import React, { Component } from 'react'
import { Animated } from "react-animated-css"
import Zoom from 'react-medium-image-zoom'
import { connect } from 'react-redux'
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import { Link, withRouter } from 'react-router-dom'
import Url from 'url'
import join from 'url-join'
import axios, { safeGet } from '../axios-default'
import ConfirmDialog from '../components/Dialog/ConfirmDialog'
import Loader from "../components/Loader"
import Modal from '../components/Modal/modal'
import Refinement from '../components/NewPatient/Photos/NewPatientPhotos'
import NewPatientPhotosStyle from '../components/NewPatient/Photos/NewPatientPhotos.module.css'
import Treatment from '../components/NewPatient/Photos/TreatmentPlan'
import Questionnaire from '../components/NewPatient/Questionnaire/NewPatientQuestionnaire_beme'
import Aligner from '../components/PatientInfo/Aligner'
import { ApiServerUrl, MaxRetainerPairs } from '../config.json'
import * as actionTypes from '../store/NewPatient/actionType'
import { easySnackbar } from '../store/Noti/noti.actions'
import { withLang, getFromObj } from '../util/lang'
import Log from '../util/log_beme'
import * as saveAction from '../store/NewPatient/NewPatient.action';
import '../util/object'
import { initialState as NewPatientInitialState } from '../store/NewPatient/NewPatient.reducer'
// import './patientInfo.module.css';
import externalCss from './css/patientInfo.module.css'
import ImpressionViewer from '../components/PatientInfo/ImpressionViewer'
import NewPatientInfo from '../components/NewPatient/Info/NewPatientInfo'
import ColorPattern from '../components/NewPatient/ColorPattern/ColorPattern';
import LoadingProgressBar from '../components/LoadingProgressBar'
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';
import {GetDates} from "../util/date_beme";
import Badge from '@material-ui/core/Badge';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import frontend_config from '../frontend_config';
import Draggable from 'react-draggable';
import Chat from '../components/chat/chat';
import './css/ClinicalSimulation.css';
import { getWebAbsPath } from '../util/path'
import { beme_questionnaire } from '../beme_questionnaire'
import { PrintOrder } from '../components/PatientInfo/PrintOrder'
import { updateRes } from '../store/res/res.action';
import {styles}  from './PaitentInfo/styles';
import ReplaceMyp from "../components/NewPatient/Photos/ReplaceMyp";
import { retainer_colors } from '../color_pattern'

const progress_photo_angles = ["left", "front", "right"]

// material ui and css stuff
// import { Grid } from '@material-ui/core'

const stlFileMapper = {
  stl_file0: "Upper STL",
  stl_file1: "Lower STL",
  stl_file2: "Bite 1 STL",
  stl_file3: "Bite 2 STL"
}
/*Form henry
After asking the workflow with internal team, I make something wrong about the workflow.
First, the underline before "Production" for user to input data. It is an open text for user.
Second, about circle 2&3 the underline before "sent on" which is typing number of set of retainer. And the underline
after "sent on" which is for the date.
//Retainer Set {No.1 ?}Delivered Send On {No.2 ?}
Third, about the"All delivered" which allow user to input the text. Such as All Passive aligner delivered.
*/
const retainer_statusList = {
  SUBMITTED: [
    {
      value: "RECEIVED",
      label: "All materials have been confirmed and the retainers are expected to be delivered on {No.1 ?} {No.2 ?}"
    }
  ],
  RECEIVED: [
    {
      value: "PRODUCTION",
      label: "{Remark: ?} Production will be delivered on {No.1 ?}"
    }
  ],
  PRODUCTION: [
    {
      value: "DELIVERED",
      label: "Retainer Set {No.1 ?} - {No.2 ?} Send On {Remark: ?}"
    },
  ],
  DELIVERED: [
    {
      value: "ALL_DELIVERED",
      label: "All {Remark: ?} Delivered"
    }
  ],
  ALL_DELIVERED: [
    {
      value: "none",
      label: "Treatment End"
    }
  ],
  ENDED: [
    {
      value: "none",
      label: "This case has ended by the doctor"
    }
  ]
}
const statusList = {
  SUBMITTED: [
    {
      value: "AWAITING",
      label: "Awaiting More Records{Remark: ?}"
    },
    {
      value: "RECEIVED",
      label: "Confirmed All Materials Received"
    }
  ],
  AWAITING: [
    {
      value: "RECEIVED",
      label: "Confirmed All Materials Received"
    }
  ],
  RECEIVED: [
    {
      value: "none",
      label: "Wait For Uploading Treatment Plan"
    }
  ],
  PLAN_UPLOADED: [
    {
      value: "CHANGE_REQUEST",
      label: "Treatment Plan Change Request Received"
    }
  ],
  CHANGE_REQUEST: [
    {
      value: "none",
      label: "Wait For Uploading Treatment Plan"
    }
  ],
  PLAN_APPROVED: [
    {
      value: "PRODUCTION",
      label: "Production In Progress"
    }
  ],
  PRODUCTION: [
    {
      value: "DELIVERED",
      label: "Aligners Set {No.1 ?}-{No.2 ?} Delivered"
    },
  ],
  DELIVERED: [
    {
      value: "none",
      label: "Awaiting Doctors for Requesting Next Set"
    },
    {
      value: "ALL_DELIVERED",
      label: "All Aligners Sets Are Delivered"
    }
  ],
  NEXT_SET_REQUEST: [
    {
      value: "PRODUCTION",
      label: "Production In Progress"
    }
  ],
  ALL_DELIVERED: [
    {
      value: "none",
      label: "Await For Refinement / Retainer If Needed"
    }
  ],
  ENDED: [
    {
      value: "none",
      label: "This case has ended by the doctor"
    }
  ],
  REFINEMENT: [
    {
      value: "AWAITING",
      label: "Awaiting More Records{Remark: ?}"
    },
    {
      value: "RECEIVED",
      label: "Confirmed All Materials Received"
    }
  ]
}

const aligner_package_types = {
  "type_1": "Evolve Max 8 Steps + 8 Steps",
  "type_2": "Progress Max 40 Steps + 10 Steps"
}

class PatientInfo extends Component {

  caseStatusOptions = {
    SUBMITTED: this.get('caseStatusLabel.SUBMITTED'),
    AWAITING: this.get('caseStatusLabel.AWAITING'),
    RECEIVED: this.get('caseStatusLabel.RECEIVED'),
    CHANGE_REQUEST: this.get('caseStatusLabel.CHANGE_REQUEST'),
    PLAN_UPLOADED: this.get('caseStatusLabel.PLAN_UPLOADED'),
    PLAN_APPROVED: this.get('caseStatusLabel.PLAN_APPROVED'),
    PRODUCTION: this.get('caseStatusLabel.PRODUCTION'),
    DELIVERED: this.get('caseStatusLabel.DELIVERED'),
    NEXT_SET_REQUEST: this.get('caseStatusLabel.NEXT_SET_REQUEST'),
    ALL_DELIVERED: this.get('caseStatusLabel.ALL_DELIVERED'),
    ALIGNER_REQUEST: this.get('caseStatusLabel.ALIGNER_REQUEST'),
    RETAINER_REQUEST: this.get('caseStatusLabel.RETAINER_REQUEST'),
    REFINEMENT: this.get('caseStatusLabel.REFINEMENT'),
    ENDED: this.get('caseStatusLabel.ENDED'),
  }

  retainerCaseStatusOptions = {
    DRAFT: this.get('log.DRAFT'),
    SUBMITTED: this.get('log.SUBMITTED'),
    AWAITING: "",
    RECEIVED: "The retainers will be ready for shipment within 5 business days",
    PLAN_UPLOADED: "",
    CHANGE_REQUEST: "",
    PLAN_APPROVED: "",
    PRODUCTION: "{Remark: ?} Production will be deliver on {No.1 ?}",
    DELIVERED: "Retainer Set {No.1 ?} - {No.2 ?} Send On {Remark: ?}" ,
    NEXT_SET_REQUEST: "",
    ALL_DELIVERED: "All {Remark: ?} delivered",
    ENDED: this.get('log.ENDED'),
    REFINEMENT: ""
  }

  constructor(props) {
    super(props)

    this.ReplaceMypDialogRef= React.createRef();
    this.pageVisible = 0
    this.state = {
      patientInfo: null,
      loading: false,
      stls: [],
      logs: [],
      treatmentPlanApproval: [],
      currentStatus: "",
      newStatus: "",
      param1: 1,
      param2: 2,
      selectedExpectedTxDate: GetDates(new Date(), 10)[0],
      selectedAmPmTxDate: "AM",
      modalError: "",
      doctorName: '',
      awaitingRemarks: "",
      caseId: this.props.match.params.id,
      AlignerModal: false,
      StatusModal: false,
      deliveryAddressModal: false,
      selectedDeliveryAddress: "",
      getDeliveryAddress: [],
      RefinementModal: false,
      PhotoModal: false,
      RadiographsModal: false,
      ImpressionsModal: false,
      QuestionnaireModal: false,
      TreatmentModal: false,
      ReplaceMypModal: false,
      InfoModal: false,
      RequestRetainerModal: false,
      RetainerModal: false,
      refinementHasDetail: false,
      refinementHasFile: false,
      refinementHasImpression: false,
      refinementHasComment: false,
      requestRetainerQuantity: 1,
      ModalState: "",
      tcCheckbox: false,
      targetId: null,
      PhotoUploadLoadingStatus: false,
      replaceMypLoadingStatus: false,
      isDownloading: false,
      treatmentPlan: {
        pdf1: null,
        pdf1_label: "",
        pdf2: null,
        pdf2_label: "",
        video1: null,
        video1_label: "",
        video2: null,
        video2_label: "",
        smile_3d: "",
        max_aligner_number: 0,
        treatment_approval: true,
      },
      replaceTreatmentPlan:{
        smile_3d:"",
        myo_oid: null,
        myo_pid: null,
        myo_id: null,
        myo_token: null,
        planid: 0
      },

      aligner:
        [
          { "step_no": null, "thickness": null, "remove": false },
          { "step_no": null, "thickness": null, "remove": true },
        ],
      alignertype: null,
      role: JSON.parse(localStorage.getItem('role')),
      confirmOpenNextAligner: false,
      confirmOpenEndCase: false,
      opendMarkReadConfirm: false,
      unreadStatusCount: 0,
      unreadChatCount: 0,
      clientCode: "",
      refCode: null,
      internalRemarks: "",
      caseRetainer: null,
      draggable: false,
      hideTabs: false,
      exitFullScreen: false,
      dragPosition: false,
      hideSimulation: false,
      hideChat: true,
      progress_photos: [],
      retainerOrder: null,
      case_detail: null
    }
    this.progressBarRef = React.createRef(null)
    this.handleOpen = this.handleOpen.bind(this)
    this.setConfirmOpenNextAligner = this.setConfirmOpenNextAligner.bind(this)
    this.setConfirmOpenEndCase = this.setConfirmOpenEndCase.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleMypDataSave = this.handleMypDataSave.bind(this)
    this._case = null
    this.markAsRead = this.markAsRead.bind(this)
    this.handleDeliveryAddress = this.handleDeliveryAddress.bind(this)
    this.deliveryAddressChangeModalOpen = this.deliveryAddressChangeModalOpen.bind(this)
    this.handleNewChatRoom = this.handleNewChatRoom.bind(this)
    this.handleFullScreen = this.handleFullScreen.bind(this)
    this.handleVerticalSplit = this.handleVerticalSplit.bind(this)
    this.dragableChatRoom = this.dragableChatRoom.bind(this)
  }

  async handleMyoFileUploaded(res){

    let newReplaceTreatmentPlan = { ...this.state.replaceTreatmentPlan}
    //for testing
    //newReplaceTreatmentPlan.smile_3d = '123';

    newReplaceTreatmentPlan.smile_3d = res.data.url;
    newReplaceTreatmentPlan.myo_oid = res.data.oid;
    newReplaceTreatmentPlan.myo_pid = res.data.pid;
    newReplaceTreatmentPlan.myo_id = res.data.id;
    newReplaceTreatmentPlan.myo_token = res.data.token;

    this.setState({ replaceTreatmentPlan: newReplaceTreatmentPlan })
}

  handleChangeTreatmentPlanId(value){
    let newReplaceTreatmentPlan = { ...this.state.replaceTreatmentPlan}
    newReplaceTreatmentPlan.planid = value
    this.setState({ replaceTreatmentPlan: newReplaceTreatmentPlan })
  }

  handleChangeSmile3d(){
    let newReplaceTreatmentPlan = { ...this.state.replaceTreatmentPlan}
    newReplaceTreatmentPlan.smile_3d = ''
    this.setState({ replaceTreatmentPlan: newReplaceTreatmentPlan })
  }
  async handleMypDataSave(){
      try {
        if (this.state.replaceTreatmentPlan.planid===0 || this.state.replaceTreatmentPlan.smile_3d===""){
          alert('Please select treatment Plan and upload myp file!')
          return
        }
        this.setState({replaceMypLoadingStatus:true, loading: true })
        await axios.post(`case/treatment_plan_replacemyp`, {
          plan_id: this.state.replaceTreatmentPlan.planid,
          url: this.state.replaceTreatmentPlan.smile_3d,
          myo_oid: this.state.replaceTreatmentPlan.myo_oid,
          myo_pid: this.state.replaceTreatmentPlan.myo_pid,
          myo_id:  this.state.replaceTreatmentPlan.myo_id,
          myo_token:  this.state.replaceTreatmentPlan.myo_token
        }, {
          onUploadProgress: this.progressBarRef.current?.onProgressChange
        })
        await this.props.showSnackbar('Treatment Plan updated', 'success', true)
        this.reloadPage()
      }
      catch (err) {
        this.setState({ modalError: "Could Not update the Treatment Plan" })
        console.error(err.response)
      } finally {
        this.setState({ replaceMypLoadingStatus:false, loading: false })
      }

  //const ReplaceMypDialogRef1 = this.ReplaceMypDialogRef.current;
  //console.log(ReplaceMypDialogRef1.save());
  }
  removeAligner(index, e) {
    let newAligner = [...this.state.aligner]
    let modifiedAligner = newAligner.splice(index, 1)
    this.setState({ aligner: newAligner })
  }

  handleChnageAlignerType(value) {
    this.setState({ alignertype: value })
  }
  setConfirmOpenNextAligner(val) {
    this.setState({ confirmOpenNextAligner: val })
  }
  setConfirmOpenEndCase(val) {
    this.setState({ confirmOpenEndCase: val })
  }
  addAligner() {
    let newAligner = [...this.state.aligner]
    let modifiedAligner = newAligner.push({ "step_no": null, "thickness": "", "remove": true })
    this.setState({ aligner: newAligner })
  }

  handleChnageSizeAligner(step, index) {
    let newAligner = [...this.state.aligner]
    newAligner[index]['step_no'] = step
    this.setState({ aligner: newAligner })
  }

  handleChnageThicknessAligner(index, thickness) {
    let newAligner = [...this.state.aligner]
    newAligner[index]['thickness'] = thickness
    this.setState({ aligner: newAligner })
  }

  handleChangeStatus(value) {
    this.setState({ newStatus: value })
  }

  async handleStatusUpdate() {
    try {
      this.setState({ PhotoUploadLoadingStatus: true, loading: true })
      await axios.post(`case/status/${this.state.caseId}/${this.state.newStatus}`, {
        caseId: this.state.caseId,
        newStatus: this.state.newStatus,
        param1: this.state.newStatus === "RECEIVED" || (this.state.newStatus === "PRODUCTION" && this.state.patientInfo.case_type==="RETAINER") ? this.state.selectedExpectedTxDate : this.state.param1,
        param2: this.state.newStatus === "RECEIVED" || (this.state.newStatus === "PRODUCTION" && this.state.patientInfo.case_type==="RETAINER") ? this.state.selectedAmPmTxDate : this.state.param2,
        remarks: (this.state.newStatus === "DELIVERED" && this.state.patientInfo.case_type==="RETAINER") ? this.state.selectedExpectedTxDate : this.state.awaitingRemarks
      }, {
        onUploadProgress: this.progressBarRef.current?.onProgressChange
      })
      await this.props.showSnackbar('Case status updated', 'success', true)
      this.reloadPage()
    }
    catch (err) {
      this.setState({ modalError: "Could Not update the status" })
      console.error(err.response)
    } finally {
      this.setState({ PhotoUploadLoadingStatus: false, loading: false })
    }
  }

  handleOpen(modalType, ModalTypeState = 'view', args) {

    this.props.initData()
    switch (modalType) {
      case 'refinement':
        if (args.refinement_no) {
          const case_detail = this._case.case_detail[args.refinement_no],
            case_file = this._case.case_file[args.refinement_no],
            case_impression = this._case.case_impression[args.refinement_no]
          // if (!case_file || !case_impression) return
          // try {
          //   case_detail[0].attr8 = case_detail[0].attr8 ?
          //     JSON.parse(case_detail[0].attr8) :
          //     [];
          // } catch (err) { }
          const newPatientInitialStateCopy = JSON.parse(JSON.stringify(NewPatientInitialState))
          // let modifiedTeethData = [...newPatientInitialStateCopy.teeth]
          // const teethData = modifiedTeethData.filter((v, index) => {
          //   if (case_detail?.[0]?.excluded_dentition?.split(',')?.map(v => parseInt(v))?.includes(index)) {
          //     return v['checked'] = true
          //   }
          //   else {
          //     return v;
          //   }
          // })
          let modifiedFilesData = [...newPatientInitialStateCopy.files]
          let radioKey = 0, stlKey = 0, key = "";
          modifiedFilesData.forEach((v, index) => {

            if (v.category === 'photo' || v.category === 'radio') {
              key = v.category === "radio" ? v.category + radioKey : v.category + index
              radioKey = v.category === "radio" ? radioKey + 1 : 0
              v['data'] = (case_file?.[0]?.[key]) ? join(ApiServerUrl, case_file[0][key]) : ""
              v['uploaded'] = (case_file?.[0]?.[key]) ? true : false
              v['upload_later'] = case_file?.[0]?.[`${key}_later`] === true
              v['will_not_upload'] = case_file?.[0]?.[`${key}_later`] === null
            }
            else {
              key = v.category + stlKey
              stlKey = stlKey + 1
              v['data'] = (case_impression?.[0]?.[key]) ? join(ApiServerUrl, case_impression[0][key]) : ""
              v['uploaded'] = (case_impression?.[0]?.[key]) ? true : false
              v['upload_later'] = case_impression?.[0]?.stl_files_later === true
              v['will_not_upload'] = case_impression?.[0]?.stl_files_later === null
            }

          })
          const initObj = {
            files: modifiedFilesData,
            impressionType: (case_impression?.[0]?.type) ?? 'FILE',
            scannerBrand: (case_impression?.[0]?.['scanner_brand']) ? case_impression[0]['scanner_brand'] : "",
            doctorId: (case_impression?.[0]?.['doctor_id']) ? case_impression[0]['doctor_id'] : "",
            modernCode: (case_impression?.[0]?.['modern_code']) ? case_impression[0]['modern_code'] : "",
            waybillNumber: (case_impression?.[0]?.['waybill_number']) ? case_impression[0]['waybill_number'] : "",
            courierCompany: (case_impression?.[0]?.['courier_company']) ? case_impression[0]['courier_company'] : "",
            ...case_detail[0]
          }
          // console.log(initObj)
          this.props.setTempData(initObj)
          this.setState({
            RefinementModal: true,
            ModalState: ModalTypeState,
            refinementHasDetail: !!case_detail,
            refinementHasFile: !!case_file,
            refinementHasImpression: !!case_impression,
            refinementHasComment: String.isNotBlank(args?.comment),
            case_detail: case_detail[0]
          });
        }
        break
      case 'replaceMyp':
          this.setState({ ReplaceMypModal: true, ModalState: ModalTypeState, targetId: args?.id });
          break
      case 'photo':
        // this.setState({ PhotoModal: true, ModalState: ModalTypeState, targetId: args?.id });
        this.getPhotos(ModalTypeState)
        break
      case 'radiograph':
        this.setState({ RadiographsModal: true, ModalState: ModalTypeState, targetId: args?.id });
        break
      case 'impression':
        this.setState({ ImpressionsModal: true, ModalState: ModalTypeState, targetId: args?.id });
        break
      case 'aligner':
        this.setState({ AlignerModal: true, ModalState: ModalTypeState });
        break
      case 'status':
        this.setState({ StatusModal: true, ModalState: ModalTypeState });
        break
      case 'questionnaire':
        this.setState({ QuestionnaireModal: true, ModalState: ModalTypeState });
        break
      case 'treatment':
        this.setState({ TreatmentModal: true, ModalState: ModalTypeState });
        break
      case 'request_retainer':
        this.setState({ RequestRetainerModal: true, ModalState: ModalTypeState });
        break
      case 'info':
        const [, day, month, year] = this.state.patientInfo.dateOfBirth.match(/([0-9]{1,2})-([0-9]{1,2})-([0-9]{4})/)
        const oldPatientInfo = {
          gender: this.state.patientInfo.gender === 'Male' ? 'M' : (this.state.patientInfo.gender === 'Female' ? 'F' : 'O'),
          lastName: this.state.patientInfo.lastName,
          firstName: this.state.patientInfo.firstName,
          day: parseInt(day),
          month: parseInt(month),
          year: parseInt(year),
          refCode: this.state.patientInfo.refCode,
          remarks: this.state.patientInfo.remarks,
          internalRemarks: this.state.patientInfo.internalRemarks,
          patientPhoto: { id: "profile_pic", uploaded: false, data: this.state.patientInfo.image, rotate: 0 },
          country_code: this.state.patientInfo.country_code,
          email: this.state.patientInfo.email,
          contact_number: this.state.patientInfo.contact_number,
          attr0: this.state.patientInfo.attr0,
          case_type: this.state.patientInfo.case_type
        }
        this.props.setTempData(oldPatientInfo)
        this.setState({ InfoModal: true, ModalState: ModalTypeState })
        break
      case 'retainer':
        this.getRetainerData(ModalTypeState, args?.id)
        break
    }
  }

  handleClose(modalType) {
    let defaultState = { modalError: "", targetId: null }
    switch (modalType) {
      case 'refinement':
        defaultState = { ...defaultState, RefinementModal: false }
        break
      case 'replaceMyp':
        defaultState = { ...defaultState, ReplaceMypModal: false }
        break
      case 'photo':
        defaultState = { ...defaultState, PhotoModal: false }
        break
      case 'radiograph':
        defaultState = { ...defaultState, RadiographsModal: false }
        break
      case 'impression':
        defaultState = { ...defaultState, ImpressionsModal: false, stls: [] };
        break
      case 'aligner':
        defaultState = { ...defaultState, AlignerModal: false }
        break
      case 'status':
        defaultState = { ...defaultState, StatusModal: false }
        break
      case 'questionnaire':
        defaultState = { ...defaultState, QuestionnaireModal: false }
        break
      case 'treatment':
        defaultState = { ...defaultState, TreatmentModal: false }
        break
      case 'request_retainer':
        defaultState = { ...defaultState, RequestRetainerModal: false }
        break
      case 'info':
        defaultState = { ...defaultState, InfoModal: false }
        break
      case 'deliveryAddress':
        defaultState = { ...defaultState, deliveryAddressModal: false }
        break
      case 'retainer':
        defaultState = { ...defaultState, RetainerModal: false, stls: [] };
        break
    }
    this.setState(defaultState)
  }

  handleChangeInputData(stateType, value) {
    switch (stateType) {
      case 'awaitingRemarks':
        this.setState({ awaitingRemarks: value })
        break;
      case 'requestRetainerQuantity':
        this.setState({ requestRetainerQuantity: value })

        break
    }
  }

  checkIfImageExists(image_id) {
    let image_data = this.props.files.filter(imageObj => { return imageObj.id == image_id })
    return image_data?.[0].uploaded;
  }

  getImage(image_id) {
    let image_data = this.props.files.filter(imageObj => { return imageObj.id == image_id })
    return image_data?.[0].data;
  }

  componentDidUpdate() {
    if (this.pageVisible === 0) {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' })
      this.pageVisible += 1
    }
  }

  async componentDidMount() {
    this.mount_init();
  }

  async mount_init(){
    let id = parseInt(this.state.caseId)
    const caseData = await safeGet(`case/${id}`)
    try {
      if (!caseData) throw new Error('Cannot retrieve case data')
      // redirect
      if (caseData.case_status === 'DRAFT') {
        this.props.history.replace(`/patients/${id}/edit`)
        return
      }

      this.setState({ unreadStatusCount: caseData.case[0]?.unread_status_count ?? 0 });
      this.setState({ unreadChatCount: (caseData.case[0]?.unread_chat_count ?? 0) + (caseData.case[0]?.unread_private_chat_count ?? 0) });

      const [_case] = caseData.case

      const [
        deliveryAddressData,
        treatmentPlanData,
        logData,
        { user: { first_name: doc_first_name, last_name: doc_last_name, client_code: user_client_code } },
        { retainer_order: retainerOrder }
      ] = await Promise.all([
        safeGet(`case/address/${_case.delivery_address_id}?view=1`),
        safeGet(`case/treatment_plan/${id}`),
        safeGet(`case/log/${id}`),
        safeGet(`user/${_case.created_by}`),
        safeGet(`case/retainer_order/${id}`)
      ])

      this.setState({
        doctorName: [doc_first_name, doc_last_name].join(' '),
        clientCode: user_client_code
      })

      let patientInfoData = {
        // distr: distributor_info,
        gender: _case.gender === 'M' ? 'Male' : (_case.gender === 'F' ? 'Female' : 'Other'),
        id: _case.case_id,
        firstName: _case.first_name,
        lastName: _case.last_name,
        country_code: _case.country_code,
        contact_number: _case.contact_number,
        email: _case.email,
        attr0: _case.case_detail[0][0].attr0,
        case_type: _case.case_type,
        product_name: _case.product_name===''?_case.case_type:_case.product_name,
        caseStatus: _case.case_status,
        delivery_address: ((deliveryAddressData && deliveryAddressData.address) || [])[0],
        caseStatusLabel: this.handleRemarkParam1Param2(_case),
        drName: "Business Analyst",
        drID: _case.case_impression.doctor_id,
        dateOfBirth: _case.date_of_birth,
        promotionCode: "promo2020",
        refCode: _case.ref_code,
        remarks: _case.remarks ? _case.remarks : this.get(':const.non'),
        internalRemarks: _case.internal_remarks,
        case_file: _case.case_file,
        created_by : _case.created_by,
        case_detail: _case.case_detail,
        distrName :_case.distr_name,
        country_name: _case.country_name,
        approvalDate: Moment(_case.approval_date).format('DD-MMM-YYYY'),
        treatmentEndDate: Moment(_case.expiration_date).format('DD-MMM-YYYY'),

        case_impression: _case.case_impression,
        treatment_plan: treatmentPlanData && treatmentPlanData.treatment_plan,
        image: _case.profile_pic ? (/^https?:\/\//.test(_case.profile_pic) ? _case.profile_pic : join(ApiServerUrl, _case.profile_pic)) : (_case.gender === 'M' ? getWebAbsPath("/img/front_smile.png") : (_case.gender === 'F' ? getWebAbsPath("/img/front_smile_female.png") : getWebAbsPath("/img/front_smile_other.png"))),
      }
      
      let retainerOrderData = retainerOrder ? {
        patientName: [retainerOrder.p_first_name, retainerOrder.p_last_name].join(" "),
        dentistName: [retainerOrder.d_first_name, retainerOrder.d_last_name].join(" "),
        recipientName: [retainerOrder.r_first_name, retainerOrder.r_last_name].join(" "),
        phoneNumber: retainerOrder.phone_no,
        deliveryAddress: retainerOrder.state_province ? [retainerOrder.address, retainerOrder.city, retainerOrder.state_province, retainerOrder.country, retainerOrder.postcode].join("\n") : [retainerOrder.address, retainerOrder.city, retainerOrder.country, retainerOrder.postcode].join("\n")
      } : null
      
      if (logData) {
        const { log: logs } = logData
        let translatedLogs = []
        try {
          translatedLogs = Log.translateLogs.bind(this)(logs,_case.case_type)
        } catch (err) {
          console.error(err)
        } finally {
          this.setState({ logs: translatedLogs })
        }
      }
      this.setState({
        patientInfo: patientInfoData,
        currentStatus: patientInfoData && patientInfoData.caseStatus,
        retainerOrder: retainerOrderData
      })
      this._case = _case

      if (['SADMIN', 'ADMIN', 'SADMIN', 'DISTR'].includes(this.state.role)) {
        // non blocking
        safeGet(`user/distr/${_case.created_by}`).then(({ distr, success }) => {
          if (success) {
            this.setState(({ patientInfo }) => ({
              patientInfo: {
                ...patientInfo,
                distr: distr?.map(({ email, phone }) => ({ email, phone })) ?? []
              }
            }))
          }
        })
      }
    } catch (err) {
      console.error(err)
      this.props.showSnackbar('Access denied / Cannot retrieve this case')
    }
  }

  async handleInstantUpdate(url, msg) {
    if (window.confirm(msg)) {
      let { data } = await axios.post(url)
      if (data.success) {
        this.reloadPage()
      } else {
        window.alert(data.message)
      }
    }
  }

  handleUpload(image_id, file) {
    switch (image_id) {
      case 'face_side_with_smile':
        this.props.handleDataFileUpload("face_side_with_smile", file);
        break;
      case 'face_front_with_smile':
        this.props.handleDataFileUpload("face_front_with_smile", file);
        break;
      case 'face_side_without_smile':
        this.props.handleDataFileUpload("face_side_without_smile", file);
        break;
      case 'teeth_side_with_smile':
        this.props.handleDataFileUpload("teeth_side_with_smile", file);
        break;
      case 'teeth_front_with_smile':
        this.props.handleDataFileUpload("teeth_front_with_smile", file);
        break;
      case 'teeth_side_without_smile':
        this.props.handleDataFileUpload("teeth_side_without_smile", file);
        break;
      case 'jaw_side_with_smile':
        this.props.handleDataFileUpload("jaw_side_with_smile", file);
        break;
      case 'jaw_front_with_smile':
        this.props.handleDataFileUpload("jaw_front_with_smile", file);
        break;
      case 'jaw_side_without_smile':
        this.props.handleDataFileUpload("jaw_side_without_smile", file);
        break;
      case 'front_with_smile':
        this.props.handleDataFileUpload("front_with_smile", file);
        break;
      case 'side_with_smile':
        this.props.handleDataFileUpload("side_with_smile", file);
        break;
      case 'impression_upper':
        this.props.handleDataFileUpload('impression_upper', file)
        break
      case 'impression_lower':
        this.props.handleDataFileUpload("impression_lower", file)
        break
      case 'impression_scan_one':
        this.props.handleDataFileUpload("impression_scan_one", file)
        break
      case 'impression_scan_two':
        this.props.handleDataFileUpload("impression_scan_two", file)
        break
    }
  }

  async handleRadioDataSave() {
    const form = new FormData()
    let newRadioUpload = {}

    this.props.files.filter(v => {
      return v.category === 'radio'
    }).forEach((radio, index) => {
      const fileName = `$$FILE:radio${index}`
      newRadioUpload['radio' + index] = radio.data ? fileName : null
      newRadioUpload['radio' + index + '_later'] = radio.upload_later
      if (radio.data) {
        form.append(fileName, radio.data)
      }
    })

    newRadioUpload['case_id'] = this.state.caseId

    form.append('body', JSON.stringify(newRadioUpload))

    try {
      this.setState({ PhotoUploadLoadingStatus: true, loading: true })
      if (Object.keys(newRadioUpload).length) {
        await axios.post(`case/file/${this.state.caseId}`, form, {
          onUploadProgress: this.progressBarRef.current?.onProgressChange
        })
        await this.props.showSnackbar('Radiographs uploaded', 'success', true)
        this.reloadPage()
      }
    }
    catch (err) {
      this.setState({ modalError: "All Images are required" })
      console.error(err.response)
    } finally {
      this.setState({ PhotoUploadLoadingStatus: false, loading: false })
    }
  }

  async getImpressionData() {
    const form = new FormData()
    let newImpressionUpload = {}

    this.props.files.filter(v => {
      return v.category === 'stl_file'
    }).forEach(async (v, index) => {
      const fileName = '$$FILE:stl_file' + index
      newImpressionUpload['stl_file' + index] = v.data ? fileName : null
      if (v.data) {
        form.append(fileName, v.data)
      }
      if (v?.upload_later) {
        newImpressionUpload['stl_files_later'] = true
      }
    })
    // newImpressionUpload['stl_files_later'] = caseImpressionRadio?.[0]?.['upload_later']

    newImpressionUpload['case_id'] = this.state.caseId
    newImpressionUpload['modern_code'] = this.props.newPatientData.modernCode
    newImpressionUpload['waybill_number'] = this.props.newPatientData.waybillNumber
    newImpressionUpload['courier_company'] = this.props.newPatientData.courierCompany
    newImpressionUpload['scanner_brand'] = this.props.newPatientData.scannerBrand
    newImpressionUpload['doctor_id'] = this.props.newPatientData.doctorId
    newImpressionUpload['type'] = this.props.newPatientData.impressionType

    return [form, newImpressionUpload]
  }

  async handleImpressionDataSave() {
    let [form, newImpressionUpload] = await this.getImpressionData()

    form.append('body', JSON.stringify(newImpressionUpload))

    //console.log(newImpressionUpload)
    try {
      this.setState({ PhotoUploadLoadingStatus: true, loading: true })
      await axios.post(`case/impression/${this.state.caseId}`, form, {
        onUploadProgress: this.progressBarRef.current?.onProgressChange
      })
      await this.props.showSnackbar('Impression uploaded', 'success', true)
      this.reloadPage()
    }
    catch (err) {
      this.setState({ modalError: "Something went wrong , Please connect with admin" })
      console.error(err.response)
    } finally {
      this.setState({ PhotoUploadLoadingStatus: false, loading: false })
    }
  }

  async handleChangeStatusUpdate(value) {
    try {
      await axios.post(`case/status/${this.state.caseId}/${value}`, {
        caseId: this.state.caseId,
        param1: this.state.param1,
        param2: this.state.param2,
        newStatus: value, remarks: null
      })
      await this.props.showSnackbar('Case status updated', 'success', true)
      this.reloadPage()
    }
    catch (err) {
      this.setState({ modalError: "Could Not update the status" })
      console.error(err.response)
    }
  }

  async handleAlignerSave() {
    this.setState({ loading: true })
    if (this.state.aligner.some(a => !a.step_no || !a.thickness)) {
      await this.props.showSnackbar('Please fill in missing data', 'error')
      this.setState({ loading: false })
      return
    }
    let alignerObj = {
      case_id: this.state.caseId,
      type: 'REQUEST',
      detail: this.state.aligner
    }
    try {
      this.setState({ PhotoUploadLoadingStatus: true })
      await axios.post(`case/aligner_request/${this.state.caseId}`, alignerObj)
      await this.props.showSnackbar('Aligner Request sent', 'success', true)
      this.props.history.push('/blank')
      this.reloadPage()
    }
    catch (err) {
      this.setState({ modalError: "Could Not Update , Please try again later" })
      console.error(err.response)
    } finally {
      this.setState({ PhotoUploadLoadingStatus: false, loading: false })
    }
  }

  async handleTreatmentSave() {
    const form = new FormData()
    let treatmentData = {
      case_id: this.state.caseId,
      pdf0: this.state.treatmentPlan.pdf1 ? '$$FILE:pdf0' : null,
      pdf0_name: this.state.treatmentPlan.pdf1_label,
      pdf1: this.state.treatmentPlan.pdf2 ? '$$FILE:pdf1' : null,
      pdf1_name: this.state.treatmentPlan.pdf2_label,
      video0: this.state.treatmentPlan.video1 ? '$$FILE:video0' : null,
      video0_name: this.state.treatmentPlan.video1_label,
      video1: this.state.treatmentPlan.video2 ? '$$FILE:video1' : null,
      video1_name: this.state.treatmentPlan.video2_label,
      max_aligner_number: this.state.treatmentPlan.max_aligner_number,
      url: this.state.treatmentPlan.smile_3d,
      myo_oid: this.state.treatmentPlan.myo_oid,
      myo_pid: this.state.treatmentPlan.myo_pid,
      myo_id: this.state.treatmentPlan.myo_id,
      myo_token: this.state.treatmentPlan.myo_token,
      created_by : this.state.patientInfo.created_by,
      finished_flag: true
    }
    let isFileUploaded = false;
    if (this.state.treatmentPlan.pdf1) {
      form.append('$$FILE:pdf0', this.state.treatmentPlan.pdf1)
      isFileUploaded = true;
    }
    if (this.state.treatmentPlan.pdf2) {
      form.append('$$FILE:pdf1', this.state.treatmentPlan.pdf2)
      isFileUploaded = true;
    }

    if (this.state.treatmentPlan.video1) {
      form.append('$$FILE:video0', this.state.treatmentPlan.video1)
      isFileUploaded = true;
    }
    if (this.state.treatmentPlan.video2) {
      form.append('$$FILE:video1', this.state.treatmentPlan.video2)
      isFileUploaded = true;
    }
    if (!this.state.treatmentPlan.max_aligner_number || this.state.treatmentPlan.max_aligner_number <= 0) {
      await this.props.showSnackbar('Please enter Number of Sets of Aligner', 'error')
      return
    }
    if (this.state.treatmentPlan.smile_3d && this.state.treatmentPlan.smile_3d != "~pending upload~"){
      //if smile 3d have value
      isFileUploaded = true;
    }

    if (!isFileUploaded){
      alert("No any file uploaded, please check and save again.");
      return;
    }
    //console.log(isFileUploaded)
    //return
    form.append('body', JSON.stringify(treatmentData))
    //console.log(treatmentData);
    try {
      this.setState({ PhotoUploadLoadingStatus: true, loading: true })
      await axios.post(`case/treatment_plan/${this.state.caseId}`, form, {
        onUploadProgress: this.progressBarRef.current?.onProgressChange
      })
      await this.props.showSnackbar('Treatment plan uploaded', 'success', true)
      this.reloadPage()
    }
    catch (err) {
      this.setState({ modalError: `Something went wrong. Please make sure you raised a treatment plan change request. If the problem happen again, please connect with admin`})
      console.error(err.response)
    } finally {
      this.setState({ PhotoUploadLoadingStatus: false, loading: false })
    }
  }
  async handleInfoSave() {
    try {
      this.setState({ loading: true })
      const form = new FormData()
      let profilePic = this.props.newPatientData.patientPhoto.data
      if (this.props.newPatientData.patientPhoto.data) {
        if (typeof this.props.newPatientData.patientPhoto.data === 'object') {
          profilePic = '$$FILE:profile_pic'
        } else if (this.props.newPatientData.patientPhoto.data.startsWith('/')) {
          profilePic = null
        }
      } else {
        profilePic = null
      }
      const json = {
        gender: this.props.newPatientData.gender,
        first_name: this.props.newPatientData.firstName,
        last_name: this.props.newPatientData.lastName,
        date_of_birth: `${this.props.newPatientData.day}-${this.props.newPatientData.month}-${this.props.newPatientData.year}`,
        profile_pic: {
          data: profilePic,
          rotate: this.props.newPatientData.patientPhoto.rotate
        },
        ref_code: this.props.newPatientData.refCode,
        remarks: this.props.newPatientData.remarks,
        internal_remarks: this.props.newPatientData.internalRemarks,
        country_code: this.props.newPatientData.country_code,
        email: this.props.newPatientData.email,
        contact_number:this.props.newPatientData.contact_number
      }
      if (this.props.newPatientData.patientPhoto.data && typeof this.props.newPatientData.patientPhoto.data === 'object') {
        form.append('$$FILE:profile_pic', this.props.newPatientData.patientPhoto.data)
      }
      form.append('body', JSON.stringify(json))
      await axios.post(`case/info/${this.state.caseId}`, form, {
        onUploadProgress: this.progressBarRef.current?.onProgressChange
      })
      await this.props.showSnackbar('Patient info updated', 'success', true)
      this.reloadPage()
    } catch (err) {
      this.setState({ modalError: "Something Went Wrong Please Connect With Admin" })
      console.error(err.response ?? err)
    } finally {
      this.setState({ loading: false })
    }
  }

  async handleTreatmentData(data = null, stateType, fileName) {
    let newtreatmentPlan = { ...this.state.treatmentPlan }
    switch (stateType) {
      case 'pdf1':
        newtreatmentPlan.pdf1 = data;
        newtreatmentPlan.pdf1_label = fileName;
        this.setState({ treatmentPlan: newtreatmentPlan })
        break
      case 'pdf2':
        newtreatmentPlan.pdf2 = data;
        newtreatmentPlan.pdf2_label = fileName;
        this.setState({ treatmentPlan: newtreatmentPlan })
        break
      case 'video1':
        newtreatmentPlan.video1 = data;
        newtreatmentPlan.video1_label = fileName;
        this.setState({ treatmentPlan: newtreatmentPlan })
        break
      case 'video2':
        newtreatmentPlan.video2 = data;
        newtreatmentPlan.video2_label = fileName;
        this.setState({ treatmentPlan: newtreatmentPlan })
        break
      case 'smile_3d':
        newtreatmentPlan.smile_3d = data;
        this.setState({ treatmentPlan: newtreatmentPlan })
        break;
      case 'treatment_approval':
        newtreatmentPlan.treatment_approval = data;
        this.setState({ treatmentPlan: newtreatmentPlan })
        break;
      case 'max_aligner_number':
        newtreatmentPlan.max_aligner_number = parseInt(data) || 0
        this.setState({ treatmentPlan: newtreatmentPlan })
        break
    }
  }

  async handleRequestRetainerSave() {
    let [form, impressionData] = await this.getImpressionData()

    form.append('body', JSON.stringify({
      case_impression: impressionData,
      quantity: this.state.requestRetainerQuantity
    }))

    try {
      this.setState({ PhotoUploadLoadingStatus: true, loading: true })
    
      let case_retainer_form = {
        case_retainer_id: null,
        retainer_pairs: this.props.newPatientData.retainer_pairs
      }

      for (let i = 0; i < MaxRetainerPairs; i++) {
        case_retainer_form = {
          ...case_retainer_form,
          [`retainer_type${i}`]: this.props.newPatientData[`retainer_type${i}`],
          [`retainer_color${i}_upper`]: this.props.newPatientData[`retainer_color${i}_upper`],
          [`retainer_color${i}_lower`]: this.props.newPatientData[`retainer_color${i}_lower`],
          [`retainer_position${i}`]: this.props.newPatientData[`retainer_position${i}`]
        }
      }

      form.append('body', JSON.stringify({
        case_impression: impressionData,
        quantity: this.state.requestRetainerQuantity,
        case_retainer: case_retainer_form
      }))

      await axios.post(`case/case_retainer/${this.state.caseId}`, form, {
        onUploadProgress: this.progressBarRef.current?.onProgressChange
      })

      await this.props.showSnackbar('Retainer request sent', 'success', true)
      this.reloadPage()
    }
    catch (err) {
      this.setState({ modalError: "Something went wrong , Please connect with admin" })
      console.error(err.response)
    } finally {
      this.setState({ PhotoUploadLoadingStatus: false, loading: false })
    }
  }

  async handleQuestionnaireSave() {
    let newPatientData = { ...this.props.newPatientData }
    let newQuestionnaireObj = {}
    newQuestionnaireObj['case_id'] = this.state.caseId
    beme_questionnaire.forEach(q => {
      newQuestionnaireObj[q.name] = newPatientData[q.name]
    })
    newQuestionnaireObj["attr12"] = newPatientData["attr12"]

    this.setState({ PhotoUploadLoadingStatus: true })
    try {
      await axios.post(`case/detail/${this.state.caseId}`, newQuestionnaireObj)
      await this.props.showSnackbar('Questionnaire updated', 'success', true)
      this.reloadPage()
    }
    catch (err) {
      this.setState({ modalError: "Something went wrong , Please connect with admin" })
      console.error(err.response)
    }
    this.setState({ PhotoUploadLoadingStatus: false })
  }

  handleChangetcBox() {
    this.setState({ tcCheckbox: !this.state.tcCheckbox })
  }

  downloadImages(caseFile, type) {
    let photosList = [], date = null
    Object.entries(caseFile).map(([key, value]) => {
      if (key.includes(type) && !key.endsWith("later") && value) {
        photosList.push(/^https?:\/\//.test(value) ? value : join(ApiServerUrl, value))
      }
    })
    date = Moment(caseFile.created_date).format('YYYY-MM-DD')
    for (let i = 0; i < photosList.length; i++) {
      this.downloadFile(photosList[i], date)
    }

  }

  downloadFile(file, date, type = "image/*") {
    axios.get(file, { responseType: 'blob' }).then(({ data }) => {
      let url = window.URL.createObjectURL(data)

      var newImageName = file.split("/")[file.split("\/").length-1];//using the server image name

      Object.assign(document.createElement("a"), { download: `${date}-${newImageName}`, href: url }).click()
      window.URL.revokeObjectURL(url)
    })
  }

  async handlePhotoDataSave() {
    const form = new FormData()
    let newPhotoUpload = {}

    this.props.files.filter(v => {
      return v.category === 'photo'
    }).forEach((v, index) => {
      if (v.data) {
        const fileName = '$$FILE:photo' + index
        newPhotoUpload['photo' + index] = v.data ? fileName : null
        newPhotoUpload['photo' + index + '_later'] = v.upload_later
        if (v.data) {
          form.append(fileName, v.data)
        }
      }
    })

    form.append('body', JSON.stringify(newPhotoUpload))

    try {
      this.setState({ PhotoUploadLoadingStatus: true, loading: true })
      if (Object.keys(newPhotoUpload).length) {
        await axios.post(`case/file/${this.state.caseId}`, form, {
          onUploadProgress: this.progressBarRef.current?.onProgressChange
        })
        await this.props.showSnackbar('Photos uploaded', 'success', true)
        this.reloadPage()
      } else {
        await this.props.showSnackbar('No photo to upload', 'error')
      }
    }
    catch (err) {
      this.setState({ modalError: "All Images are required" })
      console.error(err.response)
    } finally {
      this.setState({ PhotoUploadLoadingStatus: false, loading: false })
    }
  }

  returnLogs() {
    let { classes } = this.props,
      { logs } = this.state
    return logs.map(({ msg, btn, date }, i) => (
      <Grid container key={i} style={{ borderTop: "#f2f2f2 1px solid" }}>
        <Grid
          item xs={12} sm={2}
          className={msg && !msg.external ? classes.logDateContainer : classes.logDateContainer2}
        >
          {/* <Tooltip placement="left" title={date.formal}> */}
          <div>
            <p
              className={classes.logDate}
            >
              {
                ["sec", "min", "hour", "day"].find(s => date.friendly.includes(s))
                  ? date.friendly : date.formal
              }
            </p>
            <p style={{ fontSize: 12, textAlign: "center" }}>{date.formal}</p>
          </div>

          {/* </Tooltip> */}
        </Grid>
        <Grid
          item xs={12} sm={6}
          className={msg && !msg.external ? classes.logMsg : classes.logMsg2}
        >
          <p
            style={{
              color: msg && !msg.external ? "white" : "#444444"
            }}
          >
            {msg.msg || msg}
          </p>
        </Grid>
        <Grid
          item xs={12} sm={4}
          className={msg && !msg.external ? classes.logBtns : classes.logBtns2}
        >
          {
            btn.map(({ url, onClick, value, id, newtab }, j) => {
              if (url) {
                let aAttr = {
                  href: url,
                  ...(newtab ? { target: "_blank" } : null)
                }
                return (
                  <a
                    id={`${j}_${id}`}
                    {...aAttr} style={{ textDecoration: "none" }}
                  >
                    <Button className={!msg.external ? classes.caseBtn : classes.caseBtn2}>
                      {value}
                    </Button>
                  </a>
                )
              } else {
                return (
                  <Button
                    id={`${j}_${id}`}
                    className={!msg.external ? classes.caseBtn : classes.caseBtn2}
                    onClick={onClick}
                  >
                    {value}
                  </Button>
                )
              }
            })
          }
        </Grid>
      </Grid>
    ))
  }
  handleRemarkParam1Param2 = (_case) =>{
    return getFromObj(_case.case_type!="RETAINER" ? this.caseStatusOptions : this.retainerCaseStatusOptions, _case.case_status).replace(/\{No[^\}]*\?\}/, _case.status_param1)
          .replace(/\{No[^\}]*\?\}/, _case.status_param2)
          .replace(/\{Remark:[^\}]*\}/, _case.status_remarks ? `(${_case.status_remarks})` : '');
    }

  returnSteps() {
    const countArray = [];
    for (let i = 1; i <= 150; i++) {
      countArray.push(<MenuItem value={i} key={i}>{i}</MenuItem>);
    }
    return countArray
  }

  reloadPage() {
    this.props.history.push('/blank')
    this.props.history.replace(`/patients/${this.state.caseId}`)
  }
  handleClickOpen = () => {
    this.setState({ opendMarkReadConfirm: true });
  };
  markAsReadClose = () => {
    this.setState({ opendMarkReadConfirm: false });
  };
  async markAsRead() {
    let readAPI = await axios.post(`/case/${parseInt(this.state.caseId)}/read`);
    if(readAPI.data.success) {
      this.setState({ opendMarkReadConfirm: false });

      await this.mount_init();
    }
  }


  clinicalSimulaitonLiveSupportButton(linkedButtonClass) {
    return (
      <a href={getWebAbsPath(`/clinicalSimulation/${this.state.caseId}`)} target="_blank" style={{ textDecoration: "none", width: "100%" }}>
        <Button className={linkedButtonClass} >
          {this.get('case_info.documentsList.clinicalSimulation')}
        </Button>
      </a>
    );
  }
  async deliveryAddressChangeModalOpen() {
    this.setState({ deliveryAddressModal: true});
    try{
      const addressList = (await axios.get(`case/address?case_id=${this.state.caseId}`,{
      onUploadProgress: this.progressBarRef.current?.onProgressChange
      })).data.address
      this.setState({ getDeliveryAddress: addressList })
    }catch(err){
      console.log(err);
    }

  }
  handleDeliveryAddress(event){
    this.setState({selectedDeliveryAddress: event.target.value});
  }
  
  async DeliveryAddressSave() {
    try{
      const selectedAddress = await axios.post(`case/address/${this.state.caseId}/${this.state.selectedDeliveryAddress}`,{
      onUploadProgress: this.progressBarRef.current?.onProgressChange
      })

      if(selectedAddress.data.success){
        await this.props.showSnackbar('Delivery Address Changed', 'success', true)
        this.reloadPage()
      }
    } catch(err){
      this.setState({ modalError: "Something went wrong, Please contact to Admin"})
      console.log(err)
    }
  }

  async getRetainerData(ModalTypeState, impressionId) {
    try {
      const result = await axios.get(`/case/case_retainer/${this.state.caseId}`, { params: { impressionId: impressionId } })
      if (result.data.case_retainer) {
        this.setState({ caseRetainer: result.data.case_retainer })
      } else {
        this.setState({ caseRetainer: null })
      }
    } catch(err){
      this.setState({ caseRetainer: null })
    }
    this.setState({ RetainerModal: true, ModalState: ModalTypeState, targetId: impressionId })
  }

  handleNewChatRoom() {
    // hide tab and add second chatroom, set ChatRoom width to md=4
    this.setState({hideTabs: true})
  }

  handleFullScreen() {
    this.setState({exitFullScreen: !this.state.exitFullScreen})
  }

  handleVerticalSplit() {
    this.setState({hideSimulation: !this.state.hideSimulation})
  }

  dragableChatRoom() {
    this.setState({draggable: !this.state.draggable, dragPosition: !this.state.dragPosition})
  }

  handleChat() {
    this.setState({hideChat: !this.state.hideChat}, () => {
      const element = document.getElementById("livechat")
      if (element) {
        element.scrollIntoView({behavior: 'smooth'})
      }
    })
  }

  progressUpdate(props){
    if(this.state.patientInfo.case_type!="RETAINER"){
        return (
            this.state.currentStatus && statusList[this.state.currentStatus] &&
                         statusList[this.state.currentStatus].map((v, i) =>
                         <MenuItem disabled={v.value === "none"} value={v.value} key={v.value} selected>{v.label}</MenuItem>
               )
             )
     }else{
      return (
        this.state.currentStatus &&  retainer_statusList[this.state.currentStatus] &&
          retainer_statusList[this.state.currentStatus].map((v, i) =>
            <MenuItem disabled={v.value === "none"} value={v.value} key={v.value} selected>{v.label}</MenuItem>
        )
      )
     }
  }

  async getPhotos(ModalTypeState) {
    let result = await axios.get(`case/progress_photos/${this.state.caseId}`)
    this.setState({ PhotoModal: true, ModalState: ModalTypeState, progress_photos: result.data.progress_photos })
  }

  render() {
    const { classes } = this.props
    const { role, getDeliveryAddress, selectedDeliveryAddress } = this.state
    if (this.state.patientInfo == null) {
      return <Loader />
    }
    else {
      const retainerOrderDisplay = []
      if (this.state.caseRetainer) {
        for (let i = 0; i < this.state.caseRetainer.retainer_pairs; i++) {
          let img_upper = '', img_lower = ''
          if (this.state.caseRetainer[`retainer_color${i}_upper`]) {
            img_upper = `/img/color-pattern/${this.state.caseRetainer[`retainer_color${i}_upper`]}.${retainer_colors.find(c => c.value == this.state.caseRetainer[`retainer_color${i}_upper`]).extension}`
          }
          if (this.state.caseRetainer[`retainer_color${i}_lower`]) {
            img_lower = `/img/color-pattern/${this.state.caseRetainer[`retainer_color${i}_lower`]}.${retainer_colors.find(c => c.value == this.state.caseRetainer[`retainer_color${i}_lower`]).extension}`
          }
          retainerOrderDisplay.push(
            <Grid container style={{ padding: "10px" }}>
              <Grid item xs={12} md={2}>
                Retainer {i + 1}
              </Grid>
              <Grid item xs={12} md={10} style={{ fontWeight: "normal" }}>
                <Grid container>
                  <Grid item xs={12}>
                    Type: {this.state.caseRetainer[`retainer_type${i}`] ? String.toCapitalLetter(this.state.caseRetainer[`retainer_type${i}`]) : <span style={{color: "red"}}>Not chosen</span>}
                  </Grid>
                  {this.state.caseRetainer[`retainer_type${i}`] == "fashion" && <>
                    <Grid item sm={12} md={6} lg={4}>
                      <Zoom zoomMargin={48}>
                        <img style={{ height: "200px", width: "200px", objectFit: "contain" }} src={img_upper} />
                      </Zoom>
                      <br/>
                      Upper Jaw Color
                    </Grid>
                    <Grid item sm={12} md={6} lg={4}>
                      <Zoom zoomMargin={48}>
                        <img style={{ height: "200px", width: "200px", objectFit: "contain" }} src={img_lower} />
                      </Zoom>
                      <br/>
                      Lower Jaw Color
                    </Grid>
                    <Grid item sm={12} md={6} lg={4}>
                      <Zoom zoomMargin={48}>
                        <img style={{ height: "200px", width: "200px", objectFit: "contain" }} src={`/img/color-pattern/${this.state.caseRetainer[`retainer_position${i}`]}.png`} />
                      </Zoom>
                      <br/>
                      Position
                    </Grid>
                  </>}
                </Grid>
              </Grid>
            </Grid>
          )
        }
      }

      return (
        <Animated animationIn="fadeIn" isVisible={true}>
          <LoadingProgressBar ref={this.progressBarRef} open={this.state.loading} />
          <Grid container style={{
            padding: 16,
            background: "#f2f2f2"
          }}>
            <Grid
              item xs={12}
            >
              <Modal
                className="modal"
                modal='Status'
                title='Case Status'
                open={this.state.StatusModal}
                handleClose={() => this.handleClose('status')}
                handleDataSave={() => this.handleStatusUpdate()}
                modalError={this.state.modalError}
                PhotoUploadLoadingStatus={this.state.PhotoUploadLoadingStatus}
                submitButton={true}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <label><strong>{this.get('case_info.currentStatus')}</strong></label>
                  </Grid>

                  <Grid item xs={12} sm={8}>
                    {this.state.patientInfo.caseStatusLabel}
                  </Grid>
                </Grid>

                {this.state.patientInfo.caseStatus !== "ENDED" &&
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                      <label><strong>{this.get('case_info.chnageStatusTo')}</strong></label>:
                      </Grid>

                    <Grid item xs={12} sm={8}>
                      <Select
                        value={this.state.newStatus}
                        displayEmpty
                        onChange={(event) => this.handleChangeStatus(event.target.value)}
                        style={{
                          width: "100%"
                        }}
                      >
                        <MenuItem value="" disabled>
                          Please select new status
                          </MenuItem>
                        {
                          this.progressUpdate(this.props)
                        }
                      </Select>
                    </Grid>
                  </Grid>
                }

                { this.state.newStatus === "RECEIVED" ?
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={4}>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Grid container spacing={3}>
                        <Grid item md={6}>
                          <InputLabel>
                            {this.state.patientInfo.case_type === "RETAINER" ? "Expected Retainer Ship Out Date" : "Expected TX Plan delivery Date"}
                          </InputLabel>
                          <Box mt={1}>
                            <Select
                              value={this.state.selectedExpectedTxDate}
                              displayEmpty
                              onChange={(event) => this.setState({selectedExpectedTxDate: event.target.value})}
                            >
                              {
                                GetDates(new Date(), 16).map((nextDate, i) => {
                                  return (
                                    <MenuItem value={nextDate}>{nextDate}</MenuItem>
                                  );
                                })
                              }
                            </Select>
                          </Box>
                        </Grid>
                        <Grid item md={6}>
                          <InputLabel>
                            AM/PM
                          </InputLabel>
                          <Box mt={1}>
                            <Select
                              value={this.state.selectedAmPmTxDate}
                              displayEmpty
                              onChange={(event) => this.setState({selectedAmPmTxDate: event.target.value})}
                            >
                              <MenuItem value={"AM"}>AM</MenuItem>
                              <MenuItem value={"PM"}>PM</MenuItem>
                            </Select>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  : ""
                }

                {
                  this.state.newStatus === "ALL_DELIVERED" && this.state.patientInfo.case_type==="RETAINER" ?
                    <Grid container spacing={3}>
                    <Grid item xs={12} md={4} >

                    </Grid>
                    <Grid item xs={12} sm={8}>
                    <TextField

                      className={classnames(classes.textField, classes.dense)}
                      margin="dense"
                      variant="standard"

                      value="ALL"
                    />
                    <TextField

                      className={classnames(classes.textField, classes.dense)}
                      margin="dense"
                      variant="outlined"
                      required
                      value={this.state.awaitingRemarks}
                      onChange={(event) => this.handleChangeInputData("awaitingRemarks", event.target.value)}
                    />
                    <TextField

                      className={classnames(classes.textField, classes.dense)}
                      margin="dense"
                      variant="standard"

                      value="DELIVERED"
                    />
                    </Grid>
                    </Grid>
                    : ""
                }
                {
                  this.state.newStatus === "DELIVERED" ?
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={4} >
                        <span style={{ fontWeight: 600 }}>Delivered Sets:</span>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <span>{"From Set Number: "}</span>
                        <Select style={{ width: "50%" }} value={this.state.param1} onChange={e => this.setState({ param1: e.target.value })}>
                          {
                            this.returnSteps()
                          }
                        </Select>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <span>{"To Set Number: "}</span>
                        <Select style={{ width: "50%" }} value={this.state.param2} onChange={e => this.setState({ param2: e.target.value })}>
                          {
                            this.returnSteps()
                          }
                        </Select>
                      </Grid>
                      <Grid item xs={12} md={4}><span style={{ fontWeight: 600 }}>Sent On: </span></Grid>
                      <Grid item xs={12} md={4}>

                      <Select
                        value={this.state.selectedExpectedTxDate}
                        displayEmpty
                        onChange={(event) => this.setState({selectedExpectedTxDate: event.target.value})}
                      >
                        {
                          GetDates(new Date(), 16).map((nextDate, i) => {
                            return (
                              <MenuItem value={nextDate}>{nextDate}</MenuItem>
                            );
                          })
                        }
                      </Select>
                        </Grid>
                    </Grid>
                    : ""
                }

                {
                  this.state.newStatus === "AWAITING" ?
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={4}>
                        <label><strong>Remarks For Missing Materials:</strong></label>:
                      </Grid>

                      <Grid item xs={12} sm={8}>
                        <TextField
                          disabled={this.state.disabled}
                          className={classnames(classes.textField, classes.dense)}
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          multiline
                          value={this.state.awaitingRemarks}
                          onChange={(event) => this.handleChangeInputData("awaitingRemarks", event.target.value)}
                        />
                      </Grid>
                    </Grid>
                    : ""
                }
                {
                  this.state.newStatus === "PRODUCTION" && this.state.patientInfo.case_type === "RETAINER"?
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={4}>
                        <label><strong>RETAINER:</strong></label>
                      </Grid>

                      <Grid item xs={12} sm={8}>
                        <TextField

                          className={classnames(classes.textField, classes.dense)}
                          margin="dense"
                          variant="outlined"
                          required
                          value={this.state.awaitingRemarks}
                          onChange={(event) => this.handleChangeInputData("awaitingRemarks", event.target.value)}
                        />
                        <TextField

                          className={classnames(classes.textField, classes.dense)}
                          margin="dense"
                          variant="standard"

                          value="PRODUCTION"
                        />


                          <Select
                            value={this.state.selectedExpectedTxDate}
                            displayEmpty
                            onChange={(event) => this.setState({selectedExpectedTxDate: event.target.value})}
                          >
                            {
                              GetDates(new Date(), 16).map((nextDate, i) => {
                                return (
                                  <MenuItem value={nextDate}>{nextDate}</MenuItem>
                                );
                              })
                            }
                          </Select>

                      </Grid>



                    </Grid>
                    : ""
                }

              </Modal>

              <Modal
                className="modal"
                modal='Info'
                title={this.get('modals.infoModalTitle')}
                open={this.state.InfoModal && !this.state.loading}
                modalSize="lg"
                submitButton={true}
                submitButtonLabel={this.get(':const.submit')}
                checkCondition={this.state.tcCheckbox}
                handleDataSave={() => this.handleInfoSave()}
                PhotoUploadLoadingStatus={this.state.PhotoUploadLoadingStatus}
                handleClose={() => this.handleClose('info')}
                modalError={this.state.modalError}
              >
                <Grid container>
                  <NewPatientInfo role={role} edit_info={true} />
                </Grid>
              </Modal>

              <Modal
                className="modal"
                modal='Aligner'
                title={this.get('modals.lostReplaceAlignersTitle')}
                open={this.state.AlignerModal}
                modalSize="md"
                submitButton={true}
                submitButtonLabel={this.get(':const.submit')}
                checkCondition={this.state.tcCheckbox}
                handleDataSave={() => this.handleAlignerSave()}
                PhotoUploadLoadingStatus={this.state.PhotoUploadLoadingStatus}
                handleClose={() => this.handleClose('aligner')}
                modalError={this.state.modalError}
              >
                <Aligner
                  handleChnageSizeAligner={(value, index) => this.handleChnageSizeAligner(value, index)}
                  handleChnageThicknessAligner={(index, value) => this.handleChnageThicknessAligner(index, value)}
                  removeAligner={(index, value) => this.removeAligner(index, value)}
                  addAligner={() => this.addAligner()}
                  checked={this.state.tcCheckbox}
                  handleChangeCheckBox={() => this.handleChangetcBox()}
                  aligner={this.state.aligner}
                  alignertype={this.state.alignertype}
                  handleChnageAlignerType={(value) => this.handleChnageAlignerType(value)}
                />
              </Modal>

              <Modal
                className="modal"
                modal='refinement'
                title='Refinement'
                open={this.state.RefinementModal && !this.state.loading}
                handleClose={() => this.handleClose('refinement')}
              >
                {this.state.refinementHasDetail && <Questionnaire mode="view" {...this.state.case_detail} />}
                {this.state.refinementHasFile && <Refinement tabMode="photos" mode="view" />}
                {this.state.refinementHasFile && <Refinement tabMode="radiography" mode="view" />}
                {this.state.refinementHasImpression && <Refinement tabMode="impression" mode="view" />}
                {
                  this.state.refinementHasComment &&
                  <ul className={NewPatientPhotosStyle.patientImages}>
                    <TextField
                      id="outlined-basic"
                      label={getFromObj(this.props.lang, 'dict.NewPatient.questionnaire.comment')}
                      value={this.props.newPatientData.comment}
                      variant="outlined"
                      multiline
                      rows={3}
                      style={{ width: "100%" }}
                    />
                  </ul>
                }
              </Modal>

              <Modal
                className="modal"
                modal='treatmentPlan'
                title='Treatment Plan'
                open={this.state.TreatmentModal && !this.state.loading}
                handleClose={() => this.handleClose('treatment')}
                handleDataSave={() => this.handleTreatmentSave()}
                submitButton={true}
                PhotoUploadLoadingStatus={this.state.PhotoUploadLoadingStatus}
                modalError={this.state.modalError}
              >
                {
                  this.state.ModalState == 'update' ?
                    <Treatment
                      patientInfo={this.state.patientInfo}
                      treatmentPlan={this.state.treatmentPlan}
                      caseType={this.state.patientInfo.case_type}
                      handleTreatmentData={(data, stateType, fileName) => this.handleTreatmentData(data, stateType, fileName)} />
                    : ""
                }
              </Modal>

              <Modal
                className="modal"
                modal='photo'
                title={this.get('modals.photoModalTitle')}
                open={this.state.PhotoModal && !this.state.loading}
                handleClose={() => this.handleClose('photo')}
                handleDataSave={() => this.handlePhotoDataSave()}
                submitButton={this.state.ModalState == 'update' ? true : false}
                PhotoUploadLoadingStatus={this.state.PhotoUploadLoadingStatus}
                modalError={this.state.modalError}
              >
                {this.state.ModalState == 'update' ? "" : (
                  this.state.progress_photos.length ? this.state.progress_photos.map(v => {
                    let photo_exist = v.photo_left || v.photo_front || v.photo_right
                    return (
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography variant="body1">Aligner Set #{v.aligner_number}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <div>
                            <div style={{ display: "block", width: "100%" }}>
                              <p style={{ display: "inline", marginRight: "15px" }}>
                                <strong>Start date</strong> : {v.aligner_start_date ? Moment(v.aligner_start_date).format('DD MMM YYYY') : "Not started"}
                              </p>
                            </div>
                            <Grid container>
                              {photo_exist ?
                                progress_photo_angles.map(angle => {
                                  return (
                                    <Grid item xs={4} style={{ padding: 8, textAlign: "center" }}>
                                      <Typography>{String.toCapitalLetter(angle)} side</Typography>
                                      {v[`photo_${angle}`] && v[`photo_${angle}`].length ? (
                                        <>
                                          <Typography>{this.get('modals.uploadedAt')} : {v[`photo_${angle}_date`] ? Moment(v[`photo_${angle}_date`]).format('DD MMM YYYY HH:mm') : "N/A"}</Typography>
                                          <Zoom zoomMargin={48}>
                                            <img
                                              src={v[`photo_${angle}`]}
                                              style={{
                                                maxHeight: 300,
                                                width: "auto",
                                                marginLeft: "auto",
                                                marginRight: "auto",
                                                maxWidth: "100%"
                                              }}
                                            />
                                          </Zoom>
                                        </>
                                      ) : (
                                        <div>Not uploaded yet</div>
                                      )}
                                    </Grid>
                                  )
                                }) : "No available progress photo"
                              }
                            </Grid>
                            <hr />
                          </div>
                        </Grid>
                      </Grid>
                    )
                  }) : "No available progress photo"
                )}
                {
                  this.state.ModalState == 'update' ? <Refinement tabMode="photos" uploadLater='patientInfo' /> :
                    this.state.patientInfo.case_file?.filter(v => !!v).map((refinement, index, arr) => {
                      if (this.state.targetId) {
                        refinement = refinement.filter(v => v.case_file_id === this.state.targetId)
                      }
                      const content = refinement.sort((a, b) => (new Date(b.created_date)) - (new Date(a.created_date))).map((file, index) => {
                        const photos = Object.entries(file).filter(([key]) => key.startsWith('photo') && !key.endsWith('later'))
                          .filter(([key]) => file[`${key}_later`] === false)
                        if (photos.length) {
                          return (
                            <div key={index}>
                              <div style={{ display: "block", width: "100%" }}>
                                <p style={{ display: "inline", marginRight: "15px" }}>
                                  <strong>{this.get('modals.uploadedAt')}</strong> :{Moment(file.created_date).format('YYYY-MM-DD HH:mm')}
                                </p>
                                <Button onClick={() => this.downloadImages(file, 'photo')} variant="outlined">{this.get('modals.download')}</Button>
                                <hr />
                              </div>
                              <Grid container>
                                {
                                  photos.map(([key, value]) => (
                                    value &&
                                    <Grid item xs={6} sm={6} md={4} key={key} style={{ padding: 8, textAlign: "center" }}>
                                      <Zoom zoomMargin={48}>
                                        <img
                                          src={/^https?:\/\//.test(value) ? value : join(ApiServerUrl, value)}
                                          style={{
                                            maxHeight: 300,
                                            width: "auto",
                                            marginLeft: "auto",
                                            marginRight: "auto",
                                            maxWidth: "100%"
                                          }}
                                        />
                                      </Zoom>
                                    </Grid>
                                  ))
                                }
                              </Grid>
                            </div>
                          )
                        } else {
                          return null
                        }
                      })
                      return !!content.length && (
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography variant="body1" style={{ display: arr.length === 1 || index === 0 ? 'none' : undefined }}>Refinement #{index}</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            {content}
                          </Grid>
                        </Grid>
                      )
                    })
                }
              </Modal>

              <Modal
                  className="modal"
                  modal='replaceMyp'
                  title={'Upload Converted MYP File Screen'}
                  open={this.state.ReplaceMypModal && !this.state.loading}
                  handleClose={() => this.handleClose('replaceMyp')}
                  handleDataSave={() => this.handleMypDataSave()}
                  submitButton={true}
                  replaceMypLoadingStatus={this.state.replaceMypLoadingStatus}
                  modalError={this.state.modalError}
                >
                {<ReplaceMyp 
                ref={this.ReplaceMypDialogRef} 
                patientInfo={this.state.patientInfo} 
                TreatmentPlanData={this.state.patientInfo.treatment_plan} 
                handleMyoFileUploaded={(res)=>this.handleMyoFileUploaded(res)} 
                handleChangeTreatmentPlanId={(value)=>this.handleChangeTreatmentPlanId(value)} 
                handleChangeSmile3d={()=>this.handleChangeSmile3d()}/>}

              </Modal>

              <Modal
                className="modal"
                modal='radiograph'
                title={this.get('modals.radioModalTitle')}
                open={this.state.RadiographsModal && !this.state.loading}
                handleClose={() => this.handleClose('radiograph')}
                handleDataSave={() => this.handleRadioDataSave()}
                submitButton={this.state.ModalState == 'update' ? true : false}
                PhotoUploadLoadingStatus={this.state.PhotoUploadLoadingStatus}
                modalError={this.state.modalError}
              >
                {
                  this.state.ModalState == 'update' ? <Refinement tabMode="radiography" uploadLater='patientInfo' /> :
                    this.state.patientInfo.case_file?.filter(v => !!v).map((refinement, index, arr) => {
                      if (this.state.targetId) {
                        refinement = refinement.filter(v => v.case_file_id === this.state.targetId)
                      }
                      const content = refinement.sort((a, b) => (new Date(b.created_date)) - (new Date(a.created_date))).map((file, index) => {
                        const radios = Object.entries(file).filter(([key]) => key.startsWith('radio') && !key.endsWith('later'))
                          .filter(([key]) => file[`${key}_later`] === false)
                        if (radios.length) {
                          return (
                            <div key={index}>
                              <div style={{ display: "block", width: "100%" }}>
                                <p style={{ display: "inline", marginRight: "15px" }}>
                                  <strong>{this.get('modals.uploadedAt')}</strong> :{Moment(file.created_date).format('YYYY-MM-DD HH:mm')}
                                </p>
                                <Button onClick={() => this.downloadImages(file, 'radio')} variant="outlined">{this.get('modals.download')}</Button>
                                <hr />
                              </div>
                              <Grid container>
                                {
                                  radios.map(([key, value]) => (
                                    value &&
                                    <Grid item xs={6} sm={6} md={4} key={key} style={{ padding: 8, textAlign: "center" }}>
                                      <Zoom zoomMargin={48}>
                                        <img
                                          src={/^https?:\/\//.test(value) ? value : join(ApiServerUrl, value)}
                                          style={{
                                            maxHeight: 300,
                                            width: "auto",
                                            marginLeft: "auto",
                                            marginRight: "auto",
                                            maxWidth: "100%"
                                          }}
                                        />
                                      </Zoom>
                                    </Grid>
                                  ))
                                }
                              </Grid>
                            </div>
                          )
                        } else {
                          return null
                        }
                      }).filter(v => v)
                      return !!content.length && (
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography variant="body1" style={{ display: arr.length === 1 || index === 0 ? 'none' : undefined }}>Refinement #{index}</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            {content}
                          </Grid>
                        </Grid>
                      )
                    })
                }
              </Modal>

              <Modal
                className="modal"
                modal='Impression'
                title={this.get('modals.impressionModalTitle')}
                open={this.state.ImpressionsModal && !this.state.loading}
                handleClose={() => this.handleClose('impression')}
                handleDataSave={() => this.handleImpressionDataSave()}
                submitButton={this.state.ModalState == 'update' ? true : false}
                modalError={this.state.modalError}
                PhotoUploadLoadingStatus={this.state.PhotoUploadLoadingStatus}
              >

                {
                  this.state.ModalState == 'update' ? <Refinement tabMode="impression" uploadLater='patientInfo' /> :
                    this.state.patientInfo.case_impression?.filter(v => !!v).map((refinement, index, arr) => {
                      if (this.state.targetId) {
                        refinement = refinement.filter(v => v.case_impression_id === this.state.targetId)
                      }
                      return !!refinement.length && (
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography variant="body1" style={{ display: arr.length === 1 || index === 0 ? 'none' : undefined }}>Refinement #{index}</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            {
                              refinement
                                .sort((a, b) => (new Date(a.created_date)) - (new Date(b.created_date)))
                                .map(impression => (
                                  !!impression && <>
                                    <div style={{ display: "block", width: "100%" }}>
                                      <p style={{ display: "inline", marginRight: "15px" }}><strong>{this.get('modals.uploadedAt')}</strong> :{Moment(impression.created_date).format('YYYY-MM-DD HH:mm')}</p>
                                      <hr />
                                    </div>
                                    <Grid item xs={12} sm={12} md={12} style={{ padding: 8, textAlign: "left" }}>
                                      {/* <p>{stlFileMapper[key]}:<a href={join(ApiServerUrl, value)} download>Download</a></p> */}
                                      <ImpressionViewer caseImpression={impression} />
                                    </Grid>
                                  </>
                                  // impression.type === 'FILE' &&
                                  // // impression[index][index1].stl_file0 &&
                                  // Object.entries(impression).filter(([key, value]) => key.startsWith('stl') && !key.endsWith('later') && value).length > 0 &&
                                  // <>
                                  //   {
                                  //     <Grid item xs={12} sm={12} md={12} style={{ padding: 8, textAlign: "left" }}>
                                  //       {/* <p>{stlFileMapper[key]}:<a href={join(ApiServerUrl, value)} download>Download</a></p> */}
                                  //       <ImpressionViewer caseImpresison={impression} />
                                  //     </Grid>
                                  //     // Object.entries(impression).map(([key, value], index) => (
                                  //     //   key.includes("stl") && !key.endsWith("later") && value &&
                                  //     // )
                                  //     // )
                                  //   }
                                  // </>
                                ))
                            }
                          </Grid>
                        </Grid>
                      )
                    })
                }

              </Modal>

              <Modal
                className="modal"
                modal='retainer'
                title={<div>Order <PrintOrder retainerOrder={this.state.retainerOrder} caseRetainer={this.state.caseRetainer} /></div>}
                open={this.state.RetainerModal && !this.state.loading}
                handleClose={() => this.handleClose('retainer')}
                modalError={this.state.modalError}
              >
                {retainerOrderDisplay}
              </Modal>

              <Modal
                className="questionnaire"
                modal='Questionnaire'
                title={this.get('modals.questionnaireModalTitle')}
                open={this.state.QuestionnaireModal}
                handleClose={() => this.handleClose('questionnaire')}
                handleDataSave={() => this.handleQuestionnaireSave()}
                submitButton={this.state.ModalState == 'update' ? true : false}
                modalError={this.state.modalError}
                PhotoUploadLoadingStatus={this.state.PhotoUploadLoadingStatus}
              >
                {
                  this.state.ModalState == 'update' ? <Questionnaire /> :
                    this.state.patientInfo.case_detail?.filter(v => !!v).map((refinement, index, arr) => (
                      <Grid container key="">
                        <Grid item xs={12}>
                          <Typography variant="body1" style={{ display: arr.length === 1 || index === 0 ? 'none' : undefined }}>Refinement #{index}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          {
                            refinement
                              .sort((a, b) => (new Date(b.created_date)) - (new Date(a.created_date)))
                              .map(val => {
                                return val &&
                                  <div key={index}>
                                    <div style={{ display: "block", width: "100%" }}>
                                      <p><strong>{this.get('modals.uploadedAt')}</strong> :{Moment(val.created_date).format('YYYY-MM-DD HH:mm')}</p>
                                      <hr />
                                    </div>
                                    <div className="questionnaireAttr" style={{ width: "100%", fontSize: "16px", lineHeight: "2" }}>
                                      <ul className={externalCss.list}>
                                        {beme_questionnaire.map((q) =>
                                          <li>
                                            <Grid container>
                                              <Grid item xs={12} md={5}>
                                                {q.question}
                                              </Grid>
                                              <Grid item xs={12} md={7} className={externalCss.normalText}>
                                                {String.isBlank(val[q.field_name]) ? <span style={{color: "red"}}>Not selected</span> : q.options.find(({ value }) => value === val[q.field_name]).label}
                                              </Grid>
                                            </Grid>
                                          </li>
                                        )}
                                        <li>
                                          <Grid container>
                                            <Grid item xs={12} md={5}>
                                              Notes and Comments (Optional)
                                            </Grid>
                                            <Grid item xs={12} md={7} className={externalCss.normalText}>
                                              {String.isBlank(val["attr12"])? <span style={{color: "red"}}>{this.get(':const.none')}</span> : val["attr12"]}
                                            </Grid>
                                          </Grid>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                              })
                          }
                        </Grid>
                      </Grid>
                    ))
                }
              </Modal>

              <Modal
                className="modal"
                modal='request_retainer'
                title={this.get('modals.reatinerModalTitle')}
                open={this.state.RequestRetainerModal && !this.state.loading}
                handleClose={() => this.handleClose('request_retainer')}
                handleDataSave={() => this.handleRequestRetainerSave()}
                submitButton={this.state.ModalState == 'update' ? true : false}
                submitButtonLabel={this.get(':const.submit')}
                PhotoUploadLoadingStatus={this.state.PhotoUploadLoadingStatus}
                modalError={this.state.modalError}
              >
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <ColorPattern
                      popup={true}
                      max_retainer_pairs={MaxRetainerPairs}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Refinement tabMode="impression" />
                  </Grid>
                </Grid>
              </Modal>
            </Grid>

            <Grid item xs={12}>
              {/* <div
                style={{
                  margin: "8px 0px"
                }}
              > */}
              <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
                <Link
                  to="/"
                  style={{
                    color: "black"
                  }}
                >
                  {this.get('breadcrumbs.caseManagement')}
                </Link>
                <Typography>{`${this.get('breadcrumbs.patient')} #${this.props.match.params.id}`}</Typography>
              </Breadcrumbs>
            </Grid>

            <Grid item xs={12}>
              <Grid container>
                <Grid
                  item xs={12} md={8}
                  className={classes.leftCards}
                >
                  <Grid
                    container
                    className={classes.mainInfoContainer}
                  >
                    <Grid container item xs={12} style={{ padding: "24px 24px 0px 24px" }}>
                      <Grid item xs={11} style={{ padding: '6px 0px' }}>
                        {this.get('case_info.caseStatus')}:
                        <span style={{ fontWeight: 600, color: "#406097", fontSize: 20, marginLeft: "5px" }}>{this.state.patientInfo.caseStatusLabel} - {this.get(`:caseType.${this.state.patientInfo.case_type}`) == "Simple" ? "Aligner" : this.get(`:caseType.${this.state.patientInfo.case_type}`)}</span>
                      </Grid>
                      <Grid item xs={1}>
                        {['ADMIN', 'SADMIN', 'DISTR'].includes(role) && <Button style={{float: 'right'}} onClick={() => this.handleOpen('info')}>{this.get('case_info.editInfo')}</Button>}
                      </Grid>
                      <Grid item xs={12}>
                        <hr />
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={4} style={{ display: "grid", padding: 24 }}>
                      <img
                        src={this.state.patientInfo.image}
                        onError={e => {
                          let u = Url.parse(e.target.src)
                          e.target.src = (
                            (u.pathname === '/' || u.pathname.startsWith('/img')
                              ? ''
                              : (
                                this.state.patientInfo.gender === 'Male'
                                  ? getWebAbsPath("/img/front_smile.png")
                                  : (this.state.patientInfo.gender === 'Female'? getWebAbsPath("/img/front_smile_female.png") : getWebAbsPath("/img/front_smile_other.png"))
                              )
                            )
                          )
                        }}
                        style={{
                          width: "auto",
                          // height: "100%",
                          maxWidth: "100%",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }} />
                    </Grid>

                    <Grid item xs={12} sm={8} style={{ padding: 24 }}>
                      <h3
                        style={{
                          textAlign: "left",
                          fontWeight: 600,
                          color: "#2eafb6",
                          fontSize: 28,
                          marginBlockStart: 0,
                          marginBlockEnd: 0
                        }}
                      >
                        {`${this.state.patientInfo.firstName}, ${this.state.patientInfo.lastName} (#${this.props.match.params.id})`}
                      </h3>

                      <hr style={{ border: "solid 2px #2eafb6", width: 88, margin: "16px 0px" }} align="left" />
                      <Grid container item xs={12}>
                        <Grid item xs={6} sm={6}>
                          <h5 className={classes.mainTitle}>Email: </h5><p style={{wordWrap: "break-word"}}>{this.state.patientInfo.email}</p>
                          <h5 className={classes.mainTitle}>{this.get('case_info.dob')}: </h5><p>{this.state.patientInfo.dateOfBirth}</p>
                          <h5 className={classes.mainTitle}>{this.get('case_info.gender')}: </h5><p>{this.state.patientInfo.gender}</p>
                          {this.state.patientInfo.case_type != "RETAINER" && <><h5 className={classes.mainTitle}>Aligner Package Type: </h5><p style={{wordWrap: "break-word"}}>{aligner_package_types[this.state.patientInfo.case_detail?.[0]?.[0]?.attr0]}</p></>}

                          <h5 className={classes.mainTitle}>{this.get('case_info.remarks')}</h5>
                          <p className={classes.mainInfo} style={{wordWrap: "break-word"}}>{this.state.patientInfo.remarks}</p>
                          {['ADMIN', 'SADMIN', 'DISTR'].includes(role) && <>
                            <h5 className={classes.mainTitle}>Client Code:</h5>
                            <p className={classes.mainInfo}>{this.state.clientCode ? this.state.clientCode : "/"}</p>
                          </>}
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <h5 className={classes.mainTitle}>Phone No.: </h5>
                          <p style={{wordWrap: "break-word"}}>{this.state.patientInfo.country_code} {this.state.patientInfo.contact_number}</p>
                          <h5 className={classes.mainTitle}>{this.get('case_info.treatmentOption')}</h5>
                          {/* <p className={classes.mainInfo}>{this.state.patientInfo.remarks}</p> */}
                          {/* <p className={classes.mainInfo}>{this.state.patientInfo.product_name}</p> */}
                          <p className={classes.mainInfo}>{this.get(`:caseType.${this.state.patientInfo.case_type}`) == "Simple" ? `Aligner ${this.state.patientInfo.case_detail?.[0]?.[0]?.attr0 == 'type_1' ? 'Evolve' : 'Progress'}` : this.get(`:caseType.${this.state.patientInfo.case_type}`)}</p>

                          <h5 className={classes.mainTitle}>{this.get('case_info.approvalDate')}</h5>
                          {/* <p className={classes.mainInfo}>{this.state.patientInfo.remarks}</p> */}
                          <p className={classes.mainInfo}>{this.state.patientInfo.approvalDate}</p>

                          <h5 className={classes.mainTitle}>{this.get('case_info.treatmentEndDate')}</h5>
                          {/* <p className={classes.mainInfo}>{this.state.patientInfo.remarks}</p> */}
                          <p className={classes.mainInfo}>{this.state.patientInfo.treatmentEndDate}</p>
                          {/* TODO: ref_code */}
                          {['ADMIN', 'SADMIN', 'DISTR'].includes(role) &&
                            <>
                              <h5 className={classes.mainTitle}>Ref Code:</h5>
                              <p className={classes.mainInfo}>{this.state.patientInfo.refCode ? this.state.patientInfo.refCode : "/"}</p>
                              <h5 className={classes.mainTitle}>Delivery ID:</h5>
                              <p className={classes.mainInfo}>{this.state.patientInfo.logistics_order_id ? this.state.patientInfo.logistics_order_id : "/"}</p>
                              <h5 className={classes.mainTitle}>Delivery Company:</h5>
                              <p className={classes.mainInfo}>{this.state.patientInfo.logistics_company ? this.state.patientInfo.logistics_company : "/"}</p>
                            </>
                          }
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    style={{
                      textAlign: "left",
                      boxShadow: "-2px 6px 24px -9px rgba(166,130,166,1)",
                      marginTop: 16,
                      padding: 24
                    }}
                  >
                    <Grid item xs={12} md={6} style={{ display: "flex" }}>
                      <h5 className={classes.rightTitles} style={{marginTop: "4px"}}>{this.get('case_info.caseLog')}</h5>
                       {this.state.unreadStatusCount != 0 ?
                       <>
                        <Badge badgeContent={this.state.unreadStatusCount} className={classes.ClearNotificationBadge}>
                        <Chip className={classes.markReadClearNotification} label={this.get('markRead')}  onClick={() => this.handleClickOpen()} variant="outlined"/>
                       </Badge>
                         <Dialog
                          open={this.state.opendMarkReadConfirm}
                          onClose={this.markAsReadClose}
                        >
                          <DialogContent>
                            <DialogContentText>Confirm marking read for case status?</DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={this.markAsReadClose}>No</Button>
                            <Button onClick={this.markAsRead}>Yes</Button>
                          </DialogActions>
                        </Dialog></> : "" }
                    </Grid>
                    <Grid item xs={12} md={6} style={{ display: "flex" }}>
                     {['SADMIN', 'ADMIN', 'SADMIN', 'DISTR'].includes(role) &&
                        <> <h5 className={classes.mainTitle} style={{marginTop: "0px"}}>{this.get('case_info.internalRemarks')}:</h5>
                        <p className={classes.mainInfo} style={{marginTop: "2px"}}>{this.state.patientInfo.internalRemarks ? this.state.patientInfo.internalRemarks : "/"}</p></>
                      }
                    </Grid>
                    {this.returnLogs()}

                  </Grid>
                </Grid>

                <Grid
                  item xs={12} md={4}
                  className={classes.rightCard}
                >
                  <Grid
                    container
                    style={{
                      textAlign: "left",
                      boxShadow: "-2px 6px 24px -9px rgba(166,130,166,1)",
                      padding: 8
                    }}
                  >
                    <Grid item xs={12}>
                      <h5 className={classes.rightTitles}>{this.get('case_info.actions')}</h5>
                    </Grid>

                    {['ADMIN', 'DISTR', 'SADMIN'].includes(role) ?
                      <Grid item xs={12}>
                        <Button className={classes.linkBtn} onClick={() => this.handleOpen('status')}>
                          {this.get('case_info.actionsList.updateStatus')}
                        </Button>
                      </Grid> : ""}

                    {!['SADMIN', 'ADMIN', 'DISTR'].includes(role) && this.state.patientInfo.case_type!="RETAINER" && ['PRODUCTION', 'DELIVERED', 'NEXT_SET_REQUEST', 'ALL_DELIVERED', 'REFINEMENT'].includes(this.state.currentStatus) ?
                      <Grid item xs={12} >
                        <Button className={classes.linkBtn} onClick={() => this.handleOpen('aligner')}>
                          {this.get('case_info.actionsList.lostReplace')}
                        </Button>
                        {/* <p className={classes.setDescription}>This should appear only a set of aligners are delivered and doctors need to request aligners for lost / replace - Status: DELIVERED</p>
                    */}
                      </Grid>
                      : ""}

                    {!['SADMIN', 'ADMIN', 'DISTR'].includes(role) && this.state.currentStatus === "ALL_DELIVERED" ?
                      <Grid item xs={12}>
                        <Button className={classnames(classes.linkBtn, externalCss.buttonPulse)} onClick={() => this.setConfirmOpenEndCase(true)}>
                          {this.get('case_info.actionsList.endCase')}
                        </Button>

                        <ConfirmDialog
                          title={this.get('msg.endCaseDialogTitle')}
                          open={this.state.confirmOpenEndCase}
                          setOpen={this.setConfirmOpenEndCase}
                          onConfirm={() => this.handleChangeStatusUpdate('ENDED')}
                        >

                          {this.get('msg.confirmEndCase')}
                        </ConfirmDialog>

                        {/*
                    <p className={classes.setDescription}>This should appear only when all aligners are delivered and doctors dont need to request aligners for lost / replace - Status: ALL_DELIVERED</p>
                    */}
                      </Grid> : ""}


                    {!['SADMIN', 'ADMIN', 'DISTR'].includes(role) && this.state.patientInfo.case_type!="RETAINER" && (this.state.currentStatus === "DELIVERED" || this.state.currentStatus === "ALL_DELIVERED") ?
                      <Grid item xs={12}>
                        <Link
                          to={`/patients/${this.state.caseId}/refinement`}
                          style={{ textDecoration: "none" }}
                          target="_blank"
                        >
                          <Button className={classes.linkBtn}>
                            {this.get('case_info.actionsList.refinement')}
                          </Button>
                        </Link>
                        {/*
                    <p className={classes.setDescription}>This should appear only when doctors manually ends the case with "End Case" button - Status: ALL_DELIVERED</p>
                    */}
                      </Grid> : ""}

                    {!['ADMIN', 'SADMIN', 'DISTR'].includes(role) && this.state.patientInfo.case_type!="RETAINER" && ['PRODUCTION', 'DELIVERED', 'NEXT_SET_REQUEST', 'ALL_DELIVERED', 'REFINEMENT'].includes(this.state.currentStatus) ?
                      <Grid item xs={12}>
                        <Button className={classes.linkBtn} onClick={() => this.handleOpen('request_retainer', 'update')}>
                          {this.get('case_info.actionsList.requestRetainer')}
                        </Button>
                        {/*
                    <p className={classes.setDescription}>This should appear only when doctors manually ends the case with "End Case" button - Status: ENDED</p>
                    */}
                      </Grid> : ""}


                    <ConfirmDialog
                      title="Process Next?"
                      open={this.state.confirmOpenNextAligner}
                      setOpen={this.setConfirmOpenNextAligner}
                      onConfirm={() => this.handleChangeStatusUpdate('NEXT_SET_REQUEST')}
                    >

                      Are you sure you want to Process Next Aligner?
                    </ConfirmDialog>

                    {!['SADMIN', 'ADMIN'].includes(role) && this.state.patientInfo.case_type!="RETAINER" && this.state.currentStatus === "DELIVERED" ?
                      <Grid item xs={12}>
                        <Button className={classnames(classes.linkBtn, externalCss.buttonPulse)} onClick={() => this.setConfirmOpenNextAligner(true)}>
                          {this.get('case_info.actionsList.processNext')}
                        </Button>
                        {/*
                    <p className={classes.setDescription}>Currently: Delivered Aligners {1} - {6} Sets</p>
                    */}
                      </Grid> : ""}

                    <hr style={{ border: "solid 1px #386d9c", width: "100%", margin: "16px 0px" }} align="left" />

                    <Grid item xs={12}>
                      <h5 className={classes.rightTitles}>{this.get('case_info.documents')}</h5>
                    </Grid>

                    {this.state.patientInfo.case_type != "RETAINER" ? (<>
                      <Grid item xs={12}>
                        <Link
                          to={`/patients/${this.state.caseId}/treatment`}
                          style={{ textDecoration: "none", pointerEvents: (this.state.currentStatus === 'SUBMITTED')? 'none' : undefined }}

                        >
                          <Button className={classes.linkBtn} disabled={this.state.currentStatus === 'SUBMITTED'}>
                            {this.get('case_info.documentsList.viewTreatmentPlan')}
                          </Button>
                        </Link>
                      </Grid>


                      <Grid item xs={12}>
                        {/* TODO: jazib */}
                        {this.state.unreadChatCount === 0 ?
                          this.clinicalSimulaitonLiveSupportButton(classes.linkBtn)
                        :
                        <Badge badgeContent={this.state.unreadChatCount} className={classes.clinicalSimulationBadge} style={{width: "100%"}} >

                          {this.clinicalSimulaitonLiveSupportButton(classes.linkBtn)}

                          </Badge>
                        }

                      </Grid>

                      <Grid item xs={12}>
                        <Button className={classes.linkBtn} onClick={() => this.handleOpen('photo')}>
                          {this.get('case_info.documentsList.viewPhotos')}
                        </Button>
                      </Grid>

                      <Grid item xs={12}>
                        <Button className={classes.linkBtn} onClick={() => this.handleOpen('radiograph')}>
                          {this.get('case_info.documentsList.viewRadiograph')}
                        </Button>
                      </Grid>

                      <Grid item xs={12}>
                        <Button className={classes.linkBtn} onClick={() => this.handleOpen('impression')}>
                          {this.get('case_info.documentsList.viewImpression')}
                        </Button>
                      </Grid>

                      <Grid item xs={12}>
                        <Button className={classes.linkBtn} onClick={() => this.handleOpen('questionnaire')}>
                          {this.get('case_info.documentsList.viewQuestionnaire')}
                        </Button>
                      </Grid>
                    </>) : (<>
                      <Grid item xs={12}>
                        <Button className={classes.linkBtn} onClick={() => this.handleChat()}>
                          Live Support
                        </Button>
                      </Grid>

                      <Grid item xs={12}>
                        <Button className={classes.linkBtn} onClick={() => this.handleOpen('impression')}>
                          {this.get('case_info.documentsList.viewImpression')}
                        </Button>
                      </Grid>
                    </>)}

                    {this.state.retainerOrder ? (
                      <Grid item xs={12}>
                        <Button className={classes.linkBtn} onClick={() => this.handleOpen('retainer')}>
                          View Orders
                        </Button>
                      </Grid>
                    ) : ""}

                    {['ADMIN', 'SADMIN', 'DISTR'].includes(role) && this.state.patientInfo.case_type != "RETAINER" ?
                      <div style={{ display: "contents" }}>
                        <hr style={{ border: "solid 1px #386d9c", width: "100%", margin: "16px 0px" }} align="left" />

                        <Grid item xs={12}>
                          <h5 className={classes.rightTitles}>{this.get('case_info.uploadFiles')}</h5>
                        </Grid>

                        {this.state.currentStatus === "ENDED" ?

                          <p>{this.get('msg.caseHasBeenEnded')}</p> :

                          <div style={{ display: "contents" }}>

                            {!!this.state.currentStatus && ['CHANGE_REQUEST', 'PLAN_UPLOADED', 'RECEIVED', 'REFINEMENT'].includes(this.state.currentStatus) && <Grid item xs={12}>
                              <Button className={classes.uploadBtn} onClick={() => this.handleOpen('treatment', 'update')}>
                                {this.get('case_info.filesList.uploadTreatmentPlan')}
                              </Button>
                            </Grid>}

                            <Grid item xs={12}>
                              <Button className={classes.uploadBtn} onClick={() => this.handleOpen('photo', 'update')}>
                                {this.get('case_info.filesList.uploadPhotos')}
                              </Button>
                            </Grid>

                            <Grid item xs={12}>
                              <Button className={classes.uploadBtn} onClick={() => this.handleOpen('radiograph', 'update')}>
                                {this.get('case_info.filesList.uploadRadiograph')}
                              </Button>
                            </Grid>

                            <Grid item xs={12}>
                              <Button className={classes.uploadBtn} onClick={() => this.handleOpen('impression', 'update')}>
                                {this.get('case_info.filesList.uploadImpression')}
                              </Button>
                            </Grid>

                            <Grid item xs={12}>
                              <Button className={classes.uploadBtn} onClick={() => this.handleOpen('questionnaire', 'update')}>
                                {this.get('case_info.filesList.uploadQuestionnaire')}
                              </Button>
                            </Grid>

                            {
                              this.state.patientInfo.treatment_plan[0].length>=1 || this.state.patientInfo.treatment_plan?.length > 1 ?

                              <Grid item xs={12}>
                              <Button className={classes.uploadBtn} onClick={() => this.handleOpen('replaceMyp')}>
                                Upload Converted MYP File
                              </Button>
                              </Grid>
                              :
                              <></>
                            }
                          </div>}
                      </div> : ""}


                    <hr style={{ border: "solid 1px #386d9c", width: "100%", margin: "16px 0px" }} align="left" />
                    { this.state.getDeliveryAddress == [] ?
                      <Loader />
                      :
                        <>
                        <LoadingProgressBar ref={this.progressBarRef} open={this.state.loading} />
                        <Modal
                          className="modal"
                          modal='deliveryAddress'
                          title='Change Delivery Address'
                          open={this.state.deliveryAddressModal}
                          handleClose={() => this.handleClose('deliveryAddress')}
                          handleDataSave={() => this.DeliveryAddressSave()}
                          modalError={this.state.modalError}
                          submitButton={true}
                          modalSize="md"
                        >
                          <Grid container>
                            <div style={{ display: "contents" }}>
                            <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                              <FormControl component="fieldset">
                                <RadioGroup aria-label="deliveryAddress" name="deliveryAddress" value={selectedDeliveryAddress} onChange={this.handleDeliveryAddress}>
                                {getDeliveryAddress.map(function(result){
                                  return (
                                    <FormControlLabel
                                      checked={result.delivery_address_id == selectedDeliveryAddress}
                                      value={result.delivery_address_id}
                                      control={<Radio />}
                                      label={Object.getFromObj(result, 'address_1', '') + ' ' + Object.getFromObj(result, 'address_2', '')}
                                    />
                                  );
                                  })
                                }
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          </div>
                          </Grid>
                        </Modal>
                        </>
                    }
                    <Grid container>
                      <Grid item xs={6}>
                        <h5 className={classes.rightTitles} style={{ paddingTop: '6px'}}>{this.get(':fields.deliveryAddress')}</h5>
                      </Grid>
                      <Grid item xs={6}>
                        {['SADMIN', 'ADMIN', 'SADMIN', 'DISTR'].includes(role) &&
                          <Button style={{float: 'right'}} onClick={this.deliveryAddressChangeModalOpen}>
                          Select Address
                          </Button>
                        }
                      </Grid>
                    </Grid>
                    <p className={classes.accountInfo}>{this.get(':fields.doctorName')}: {this.state.doctorName ?? this.get(':const.none')}</p>
                    <p className={classes.accountInfo}>
                      {this.get(':fields.devlieryName') + ': '}
                      {String.isNotBlank(Object.getFromObj(this.state.patientInfo, 'delivery_address.first_name', '') + ' ' + Object.getFromObj(this.state.patientInfo, 'delivery_address.last_name', '')) ?
                        Object.getFromObj(this.state.patientInfo, 'delivery_address.first_name', '') + ' ' + Object.getFromObj(this.state.patientInfo, 'delivery_address.last_name', '') :
                        this.get(':const.none')
                      }
                    </p>
                    <p className={classes.accountInfo}>{Object.getFromObj(this.state.patientInfo, 'delivery_address.address_1', this.get(':const.none'))}</p>
                    <p className={classes.accountInfo}>{this.get(':fields.city')}: {Object.getFromObj(this.state.patientInfo, 'delivery_address.city', this.get(':const.none'))}</p>
                    {this.state.patientInfo.delivery_address &&    <p className={classes.accountInfo}>{this.get(':fields.country')}: {this.props.res.countries[this.state.patientInfo.delivery_address.country_id].country_name}</p>
                    }

                    <p className={classes.accountInfo}>{this.get(':fields.phoneNo')}:  {Object.getFromObj(this.state.patientInfo, 'delivery_address.phone_no', this.get(':const.none'))}</p>
                    <p className={classes.accountInfo}>{this.get(':fields.faxNo')}:  {Object.getFromObj(this.state.patientInfo, 'delivery_address.fax_no', this.get(':const.none'))}</p>

                    <hr style={{ border: "solid 1px #386d9c", width: "100%", margin: "16px 0px" }} align="left" />
                    {
                      ['ADMIN', 'SADMIN'].includes(role) &&
                      <>
                        <Grid item xs={6}>
                          <h5 className={classes.rightTitles}>{this.get(':fields.distributor')}</h5>
                        </Grid>
                        <Grid item xs={6}>
                            <span style={{float: 'right'}}>{(this.state.patientInfo.distrName)}, {(this.state.patientInfo.country_name)}</span>
                        </Grid>
                        {this.state.patientInfo?.distr?.map(({ email, phone }, i) => (
                          <Chip
                            key={i}
                            variant="outlined"
                            size="medium"
                            style={{
                              margin: 3,
                              padding: 5,
                              paddingTop: 8,
                              paddingBottom: 8,
                              // backgroundColor: 'rgb(106 186 246)',
                              color: 'black',
                              borderColor: 'black',
                              height: '48px',
                              fontSize: 16
                            }}
                            label={<p><strong>{email}</strong><br/>{this.get(':fields.phoneNo')}: {phone ?? this.get(':const.none')}</p>}
                            color="primary"
                          />))}
                        {/* <p className={classes.accountInfo}>{Object.getFromObj(this.state.patientInfo, 'distr.email')}</p> */}
                        {/* <p className={classes.accountInfo}>{this.get(':fields.doctor')}: {Object.getFromObj(this.state.patientInfo, 'distr.doctors', this.get(':const.none'))}</p> */}
                        {/* <p className={classes.accountInfo}>{this.get(':fields.phoneNo')}: {Object.getFromObj(this.state.patientInfo, 'distr.phone', this.get(':const.none'))}</p> */}
                      </>
                    }

                    {this.state.hideChat ||
                      <div id="livechat">
                        {this.state.draggable?
                          <Draggable handle=".handle">
                            <div className="handle">
                              <Chat
                                caseId={this.state.caseId}
                                handleNewChatRoom={this.handleNewChatRoom}
                                hideTabs={this.state.hideTabs}
                                chatRomWidth="12"
                                dragableChatRoom={this.dragableChatRoom}
                                exitFullScreen={this.state.exitFullScreen}
                                handleFullScreen={this.handleFullScreen}
                                dragPosition={this.state.dragPosition}
                                hideTreatmentPlan={true}
                              />
                            </div>
                          </Draggable>
                        :
                          <Chat
                            caseId={this.state.caseId}
                            handleNewChatRoom={this.handleNewChatRoom}
                            chatRomWidth="12"
                            hideTabs={this.state.hideTabs}
                            dragableChatRoom={this.dragableChatRoom}
                            exitFullScreen={this.state.exitFullScreen}
                            handleFullScreen={this.handleFullScreen}
                            hideTreatmentPlan={true}
                          />
                        }
                        {/* Second ChatRoom */}
                        { this.state.hideTabs?
                          <Chat
                            caseId={this.state.caseId}
                            handleNewChatRoom={this.handleNewChatRoom}
                            chatRomWidth="12"
                            hideTabs={this.state.hideTabs}
                            handleVerticalSplit={this.handleVerticalSplit}
                            hideSimulation={this.state.hideSimulation}
                            hideTreatmentPlan={true}
                          />
                          : <div></div>
                        }
                      </div>
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Animated>
      )
    }
  }
}

const mapStateToProps = store => {
  return {
    newPatientData: store.newPatientReducer,
    files: store.newPatientReducer.files,
    config: store.config.config,
    lang: store.lang,
    res: store.res
  }
};

const mapDispatchToProps = dispatch => {
  return {
    handleDataFileUpload: (id, value) => dispatch({ type: actionTypes.NEW_PATIENT_FILES, id: id, value: value }),
    showSnackbar: (message, variant, queue = false) => dispatch(easySnackbar(message, variant, queue)),
    setTempData: (newPatientData) => dispatch(saveAction.tempSave(newPatientData)),
    initData: () => dispatch({ type: 'NEW_PATIENT_INIT_TEMP' }),
    updateRes: (resName) => dispatch(updateRes(resName)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(withLang(PatientInfo, 'PatientInfo'))))
