import {
  LANG_LOAD, LANG_CONFIG
} from './actionType'

import QueryString from 'querystring'
import axios from '../../axios-default'

let isLoading = false

const langLoaded = payload => ({
  type: LANG_LOAD,
  payload
})
export const langConfig = payload => ({
  type: LANG_CONFIG,
  payload
})

export const loadLang = (langCode) => async dispatch => {
  if (!isLoading) {
    isLoading = true
    try {
      langCode = langCode || 'English'
      if (localStorage.getItem('lang') !== langCode) {
        localStorage.setItem('lang', langCode)
      }
      let { data } = await axios.get(`lang/${langCode}`)
      if (data && data.success) {
        dispatch(langLoaded({
          current: langCode,
          dict: data.lang,
          avail: data.avail
        }))
      }
    } catch (e) {
      console.error(e)
    }
    finally {
      isLoading = false
    }
  }
}

export const updateLang = (langChanges, langCode) => dispatch => {
  try {
    let body = {
      langCode,
      lang: langChanges
    }
    let { data } = axios.post('lang', body)
    if (data && data.success) {
      // TODO: should have some noti
      console.log('Updated successfully')
    } else {
      console.error(data)
    }
  } catch (e) {
    console.error(e)
  }
}