import '../../../util/array'
import '../../../util/string'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { safeGet, safePost } from '../../../axios-default'
import { usePrevious } from '../../../util/react'
import { Clear } from '@material-ui/icons'
import { reloadUpdatingAccount, updateUpdatingAccountRole } from '../../../store/UpdatingAccount/UpdatingAccount.action'
import { Button, Checkbox, CircularProgress, Grid, makeStyles, MenuItem, Select, FormGroup, FormControlLabel, IconButton, InputAdornment } from '@material-ui/core'
import { easySnackbar } from '../../../store/Noti/noti.actions'
// import Graph from 'react-graph-vis'
import SendIcon from '@material-ui/icons/Send'


const RoleTranslation = {
  SADMIN: 'Super Admin',
  ADMIN: 'Admin',
  DISTR: 'Distributor',
  CADMIN: 'Clinic Admin',
  GADMIN: 'Group Admin',
  NR: 'Nurse',
  DR: 'Doctor'
}

const UserRoles = [
  { value: "SADMIN", label: 'Super Admin', adminOnly: true, rolesOnly: ['SADMIN', 'ADMIN'] },
  { value: "ADMIN", label: "Admin", adminOnly: true, rolesOnly: ['SADMIN', 'ADMIN'] },
  { value: "DISTR", label: "Distributor", adminOnly: true },
  { value: "DR", label: "Doctor" },
  { value: "CADMIN", label: "Clinic Admin" },
  { value: "GADMIN", label: "Group Admin" },
  { value: "NR", label: "Nurse" }
]

const RolesStyles = makeStyles(theme => ({
  login: {
    backgroundColor: `#473a69 !important`,
    // color: Colors.backgroundSecond,
    marginTop: "32px"
  }
}))

const Roles = () => {
  const classes = RolesStyles()
  const dispatch = useDispatch()
  const { user, role, countries, stateProvinces } = useSelector(({ updatingAccount }) => updatingAccount)
  const { id: userId } = useParams()
  const history = useHistory()

  const [distr_groups, setDistrGroups] = useState([]),
        [admin_groups, setAdminGroups] = useState([]),
        [groups, setGroups] = useState([]),
        [clinics, setClinics] = useState([]),
        [distributors, setDistributors] = useState([]),
        [nurses, setNurses] = useState([]),
        [doctors, setDoctors] = useState([]),
        [loading, setLoading] = useState(true),
        [isUpdating, setIsUpdating] = useState(false),
        [graphData, setGraphData] = useState({
          nodes: [],
          edges: []
        }),
        [treeData, setTreeData] = useState({}),
        [modalError, setModalError] = useState('')

  const prevUser = usePrevious(user),
        prevRole = usePrevious(role)

  const graphConfig = useRef({
    nodes: {
      shape: 'dot',
      physics: false,
    },
    interaction: {
      dragNodes: true,
      dragView: true,
      zoomView: false,
      selectable: false
    },
    layout: {
      hierarchical: {
        enabled: true,
        direction: 'UD'
      }
    },
    edges: {
      color: "#3d3d3d",
      physics: false,
    },
    height: '600px',
    width: '100%'
  })
  const graph = useRef(null),
        resizeTimer = useRef(null)

//   const updateGraph = useCallback(async () => {
//     const users = distributors.concat(nurses).concat(doctors),
//           userDict = users.reduce((a, u) => Object.assign(a, { [u.user_id]: u }), {}),
//           clinicDict = clinics.reduce((a, c) => Object.assign(a, { [c.clinic_id]: c }), {}),
//           groupDict = groups.reduce((a, g) => Object.assign(a, { [g.group_id]: g }), {})
//     if (!users.length) return
//     const newGraphData = {
//       nodes: [],
//       edges: []
//     }

//     const curNode = {
//       id: `u${userId}`,
//       label: `User #${userId}`,
//       color: 'lightgrey'
//     }

//     // sep
//     if (role.user_sep && role.user_sep.length) {
//       role.user_sep.forEach(user_sep => {
//         const user = userDict[user_sep],
//               userName = [user.first_name, user.last_name].join(' ')
//         newGraphData.nodes.push({
//           id: `u${user.user_id}`,
//           label: `${RoleTranslation[user.role.role_name]}: ${userName}`,
//           color: 'RoyalBlue'
//         })
//         newGraphData.edges.push({
//           from: `u${user.user_id}`,
//           to: curNode.id
//         })
//       })
//     }
//     if (role.clinic_sep && role.clinic_sep.length) {
//       role.clinic_sep.forEach(clinic_sep => {
//         const clinic = clinicDict[clinic_sep]
//         newGraphData.nodes.push({
//           id: `c${clinic.clinic_id}`,
//           label: `Clinic: ${clinic.clinic_name}
// Country: ${countries[clinic.country_id].country_name}` +
//             (clinic.state_province_id ? `
// State/Province: ${stateProvinces[clinic.state_province_id]}` : '')
//         })
//         newGraphData.edges.push({
//           from: `c${clinic.clinic_id}`,
//           to: curNode.id,
//           color: 'lightorange'
//         })
//       })
//     }
//     if (role.group_sep && role.group_sep.length) {
//       role.group_sep.forEach(group_sep => {
//         const group = groupDict[group_sep]
//         newGraphData.nodes.push({
//           id: `c${group.group_id}`,
//           label: `Group: ${group.group_name}`,
//           color: 'lightgreen'
//         })
//         newGraphData.edges.push({
//           from: `c${group.group_id}`,
//           to: curNode.id
//         })
//       })
//     }
//     newGraphData.nodes.push(curNode)

//     // sub
//     if (role.user_sub && role.user_sub.length) {
//       role.user_sub.forEach(user_sub => {
//         const user = userDict[user_sub],
//               userName = [user.first_name, user.last_name].join(' ')
//         newGraphData.nodes.push({
//           id: `u${user.user_id}`,
//           label: `${RoleTranslation[user.role.role_name]}: ${userName}`,
//           color: 'lightskyblue'
//         })
//         newGraphData.edges.push({
//           to: `u${user.user_id}`,
//           from: curNode.id
//         })
//       })
//     }
//     if (role.clinic_sub && role.clinic_sub.length) {
//       role.clinic_sub.forEach(clinic_sub => {
//         const clinic = clinicDict[clinic_sub]
//         newGraphData.nodes.push({
//           id: `c${clinic.clinic_id}`,
//           label: `Clinic: ${clinic.clinic_name}
// Country: ${countries[clinic.country_id].country_name}` +
//             (clinic.state_province_id ? `
// State/Province: ${stateProvinces[clinic.state_province_id]}` : ''),
//           color: 'LightSalmon'
//         })
//         newGraphData.edges.push({
//           to: `c${clinic.clinic_id}`,
//           from: curNode.id
//         })
//       })
//     }
//     if (role.group_sub && role.group_sub.length) {
//       role.group_sub.forEach(group_sub => {
//         const group = groupDict[group_sub]
//         newGraphData.nodes.push({
//           id: `g${group.group_id}`,
//           label: `Group: ${group.group_name}`,
//           color: 'ForestGreen'
//         })
//         newGraphData.edges.push({
//           to: `g${group.group_id}`,
//           from: curNode.id
//         })
//       })
//     }

//     setGraphData(newGraphData)
//   }, [
//     user, role, countries, stateProvinces,
//     distributors, nurses,
//     doctors, groups, clinics
//   ])

  // const resizeGraph = useCallback(() => {
  //   resizeTimer.current = null
  //   if (typeof graph.current?.updateGraph === 'function') {
  //     graph.current.updateGraph()
  //   }
  // }, [resizeTimer, graph])

  const handleUpdate = useCallback(field => ({ target: { value } }) => {
    if (Array.isArray(value)) {
      value = value.filter(v => !!v)
    } else if (!value) {
      value = []
    }
    const updateRole = roleObj => dispatch(updateUpdatingAccountRole(roleObj))
    switch (role.role) {
      case 'DR': {
        if (field === 'clinics') updateRole({ clinic_sep: value })
        // if (['distributors', 'nurses'].includes(field)) updateRole({ user_sep: value })
        if (field === 'nurses') updateRole({ user_sep: value })
        if (field === 'distr_groups') updateRole({ distr_group_sep: value })
        break
      }
      case 'NR': {
        if (field === 'clinics') updateRole({ clinic_sep: value })
        // if (field === 'distributors') updateRole({ user_sep: value })
        if (field === 'distr_groups') updateRole({ distr_group_sep: value })
        if (field === 'doctors') updateRole({ user_sub: value })
        break
      }
      case 'CADMIN': {
        if (field === 'clinics') updateRole({ clinic_sep: value })
        break
      }
      case 'GADMIN': {
        if (field === 'groups') updateRole({ group_sep: value })
        break
      }
      case 'DISTR': {
        if (field === 'clinics') updateRole({ clinic_sub: value })
        if (field === 'groups') updateRole({ group_sub: value })
        if (field === 'doctors') updateRole({ user_sub: value })
        if (field === 'distr_groups') updateRole({ distr_group_sub: value })
        break
      }
    }
    updateRole({
      [field]: value
    })
  }, [role, dispatch])
  const handleIsAdmin = useCallback(({ target: { checked }}) => {
    switch (role.role) {
      case 'ADMIN': {
        dispatch(updateUpdatingAccountRole({ admin_group_admin: checked }))
        break
      }
      case 'DISTR': {
        dispatch(updateUpdatingAccountRole({ distr_group_admin: checked }))
        break
      }
    }
  }, [role, dispatch])

  const handleClear = useCallback(field => () => {
    handleUpdate(field)({
      target: {
        value: []
      }
    })
  }, [handleUpdate])

  const handleSave = useCallback(async () => {
    setIsUpdating(true)
    let { role: roleName, groups, clinics, nurses, doctors, admin_groups, distr_groups } = role
    if (!roleName || !UserRoles.map(r => r.value).includes(roleName)) {
      setModalError('Please assign a role to this user')
      setIsUpdating(false)
      return
    }

    groups = Array.ensureArray(groups)
    clinics = Array.ensureArray(clinics)
    // distributors = Array.ensureArray(distributors)
    nurses = Array.ensureArray(nurses)
    doctors = Array.ensureArray(doctors)
    admin_groups = Array.ensureArray(admin_groups)
    distr_groups = Array.ensureArray(distr_groups)

    const form = {
      role: role.role,
      clinic_id: [],
      group_id: [],
      user_id: [],
      admin_group_id: [],
      distr_group_id: [],
      isAdmin: role.admin_group_admin || role.distr_group_admin
    }
    switch (roleName) {
      case 'SADMIN': {
        break
      }
      case 'ADMIN': {
        form.admin_group_id = admin_groups
        break
      }
      case 'DR': {
        if (!distr_groups.length && !nurses.length && !clinics.length) {
          setModalError('Please assign distributor/nurse/clinic to this doctor')
          setIsUpdating(false)
          return
        }
        form.clinic_id = clinics
        // form.user_id = distributors.concat(nurses)
        form.user_id = nurses
        form.distr_group_id = distr_groups
        break
      }
      case 'NR': {
        if (!clinics.length && !distr_groups.length) {
          setModalError('Please assign distributor/clinic to this nurse')
          setIsUpdating(false)
          return
        }
        form.clinic_id = clinics
        form.user_id = doctors
        form.distr_group_id = distr_groups
        break
      }
      case 'CADMIN': {
        if (!clinics || !clinics.length) {
          setModalError('Please assign clinic to this clinic admin')
          setIsUpdating(false)
          return
        }
        form.clinic_id = clinics
        break
      }
      case 'GADMIN': {
        if (!groups.length) {
          setModalError('Please assign group to this group admin')
          setIsUpdating(false)
          return
        }
        form.group_id = groups
        break
      }
      case 'DISTR': {
        form.group_id = groups
        form.clinic_id = clinics
        form.user_id = doctors.concat(nurses)
        form.distr_group_id = distr_groups
        break
      }
      default: {
        setModalError('Unknown Role')
        setIsUpdating(false)
        return
      }
    }
    try {
      if (user && user.approved_flag) {
        // let success = false
        const { success } = await safePost(`user/role/${user.user_id}`, form)
        if (success) {
          await dispatch(reloadUpdatingAccount(user.user_id))
          window.scroll({top: 0, left: 0, behavior: 'smooth' })
          await dispatch(easySnackbar('Success', 'success'))
          setModalError('')
          setLoading(true)
          init()
        } else {
          setModalError('Cannot update user role')
          setIsUpdating(false)
        }
      } else {
        // let success = false
        const { success } = await safePost(`register/approve/${user.user_id}`, form)
        if (success) {
          await dispatch(reloadUpdatingAccount(user.user_id))
          window.scroll({top: 0, left: 0, behavior: 'smooth' })
          await dispatch(easySnackbar('Success', 'success'))
          setModalError('')
          setIsUpdating(false)
          setLoading(true)
          init()
        } else {
          setModalError('Cannot approve this user')
          setIsUpdating(false)
        }
      }
    } catch (err) {
      if (err.response && err.response.statusText === 'Unauthorized') {
        localStorage.removeItem("auth-token");
        localStorage.removeItem("expirationDate");
        localStorage.removeItem("role");
        history.replace('/login')
      }
      setModalError(err.response?.data?.message ?? err.message ?? err)
      setIsUpdating(false)
    }
  }, [role,  user, history])

  // useEffect(() => {
  //   if (prevUser !== user || prevRole !== role && user && role) {
  //     updateGraph()
  //   }
  // }, [user, role, updateGraph])

  const init = useCallback(async () => {
    try {
      if (admin_groups || !admin_groups.length) {
        setAdminGroups((await safeGet(`res/admingroup`))?.admin_group ?? [])
      }
      if (distr_groups || !distr_groups.length) {
        setDistrGroups((await safeGet(`res/distrgroup`))?.distr_group ?? [])
      }
      if (!groups || !groups.length) {
        setGroups((await safeGet(`res/group`))?.group ?? [])
      }
      if (!clinics || !clinics.length) {
        setClinics((await safeGet(`res/clinic`))?.clinic ?? [])
      }
      // if (!distributors || !distributors.length) {
      //   try {
      //     setDistributors((await safeGet(`user?role=DISTR`)).user)
      //   } catch {}
      // }
      if (!nurses || !nurses.length) {
        try {
          setNurses((await safeGet(`user?role=NR`))?.user ?? [])
        } catch {}
      }
      if (!doctors || !doctors.length) {
        try {
          setDoctors((await safeGet(`user?role=DR`))?.user ?? [])
        } catch {}
      }
      // if (user.approved_flag) {
      //   updateGraph()
      // }
    } catch (err) {
      console.error(err)
      if (err.response && err.response.statusText === 'Unauthorized') {
        localStorage.removeItem("auth-token");
        localStorage.removeItem("expirationDate");
        localStorage.removeItem("role");
        history.push("/login")
      }
    }
    // window.onresize = () => {
    //   if (resizeTimer.current) {
    //     clearTimeout(resizeTimer.current)
    //   }
    //   resizeTimer.current = setTimeout(resizeGraph, 500)
    // }
    setLoading(false)
    setIsUpdating(false)
  }, [
    setDistrGroups, setGroups, setClinics, setDistributors,
    setNurses, setDoctors, setLoading, setIsUpdating,
    distr_groups, groups, clinics, distributors, nurses, doctors
  ])

  // const init = async () => {
  //   try {
  //     if (distr_groups || !distr_groups.length) {
  //       setDistrGroups((await safeGet(`res/distrgroup`)).distr_group)
  //     }
  //     if (!groups || !groups.length) {
  //       setGroups((await safeGet(`res/group`)).group)
  //     }
  //     if (!clinics || !clinics.length) {
  //       setClinics((await safeGet(`res/clinic`)).clinic)
  //     }
  //     if (!distributors || !distributors.length) {
  //       try {
  //         setDistributors((await safeGet(`user?role=DISTR`)).user)
  //       } catch {}
  //     }
  //     if (!nurses || !nurses.length) {
  //       try {
  //         setNurses((await safeGet(`user?role=DISTR`)).user)
  //       } catch {}
  //     }
  //     if (!doctors || !doctors.length) {
  //       try {
  //         setDoctors((await safeGet(`user?role=DISTR`)).user)
  //       } catch {}
  //     }
  //     // if (user.approved_flag) {
  //     //   updateGraph()
  //     // }
  //   } catch (err) {
  //     console.error(err)
  //     if (err.response && err.response.statusText === 'Unauthorized') {
  //       localStorage.removeItem("auth-token");
  //       localStorage.removeItem("expirationDate");
  //       localStorage.removeItem("role");
  //       history.push("/login")
  //     }
  //   }
  //   window.onresize = () => {
  //     if (resizeTimer.current) {
  //       clearTimeout(resizeTimer.current)
  //     }
  //     resizeTimer.current = setTimeout(resizeGraph, 500)
  //   }
  //   setLoading(false)
  //   setIsUpdating(false)
  // }

  useEffect(() => {
    init()

    return () => {
      if (resizeTimer.current) {
        clearTimeout(resizeTimer.current)
      }
      window.onresize = null
    }
  }, [])

  return (
    <>
      {loading?
        <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress style={{ color: "rgb(6,26,46)" }} />
        </Grid> :
        <Grid direction='row' container style={{ height: 600 }}>
          <Grid item xs={12} style={{ padding: 4 }}>
            {/* <Graph
              id='relationship'
              ref={graph}
              graph={graphData}
              options={graphConfig}
            /> */}
          </Grid>
          <Grid item xs={12} style={{ padding: 4 }}>
            <p>Select Role</p>
            <Select
              disabled={isUpdating}
              value={role.role}
              onChange={handleUpdate('role')}
              style={{ width: '100%' }}
              margin="normal"
              label="Role"
              id="role"
            >
              {UserRoles.map(({ value, label, adminOnly, rolesOnly }) =>
                (
                  (parseInt(userId) === parseInt(JSON.parse(localStorage.getItem('auth-token'))) && (localStorage.getItem('role') && JSON.parse(localStorage.getItem('role')) === value)) ||
                  (
                    (!adminOnly || localStorage.getItem('role') && ['ADMIN', 'SADMIN'].includes(JSON.parse(localStorage.getItem('role')))) &&
                    (!Array.isArray(rolesOnly) || rolesOnly.includes(localStorage.getItem('role') && JSON.parse(localStorage.getItem('role'))))
                  )
                )
                  ? (<MenuItem value={value} key={value}>{label}</MenuItem>)
                  : null
              )}
            </Select>
          </Grid>
          {role.role === 'ADMIN'?
            <Grid item xs={12} style={{ padding: 4 }}>
              <p>Select Admin Group</p>
              <Select
                disabled={isUpdating}
                value={role.admin_groups}
                onChange={handleUpdate('admin_groups')}
                style={{ width: '100%' }}
                margin="normal"
                label="Admin Groups"
                id="admin_groups"
              >
                {admin_groups.sort((a, b) => a.admin_group_name.localeCompare(b.admin_group_name)).map(admin_group =>
                  (<MenuItem value={admin_group.admin_group_id} key={admin_group.admin_group_id}>{admin_group.admin_group_name}</MenuItem>)
                )}
              </Select>
            </Grid> : null
          }
          {['NR', 'DR', 'DISTR'].includes(role.role)?
            <Grid item xs={12} style={{ padding: 4 }}>
              <p>Select Distributor Group</p>
              <Select
                disabled={isUpdating}
                value={role.distr_groups}
                onChange={handleUpdate('distr_groups')}
                style={{ width: '100%' }}
                margin="normal"
                label="Distributor Groups"
                id="distr_groups"
              >
                {distr_groups.sort((a, b) => a.distr_group_name.localeCompare(b.distr_group_name)).map(distr_group =>
                  (<MenuItem value={distr_group.distr_group_id} key={distr_group.distr_group_id}>{distr_group.distr_group_name} ({distr_group.description})</MenuItem>)
                ).concat((
                  <MenuItem
                    value={undefined}
                    key="-1"
                  >{"None"}</MenuItem>
                ))}
              </Select>
            </Grid> : null
          }
          {'GADMIN' === role.role?
            <Grid item xs={12} style={{ padding: 4 }}>
              <p>Select Group(s)</p>
              <Select
                disabled={isUpdating}
                value={role.groups}
                onChange={handleUpdate('groups')}
                style={{ width: '100%' }}
                margin="normal"
                label="Groups"
                id="groups"
              >
                {groups.sort((a, b) => a.group_name.localeCompare(b.group_name)).map(group =>
                  (<MenuItem value={group.group_id} key={group.group_id}>{group.group_name}</MenuItem>)
                ).concat((
                  <MenuItem
                    value={undefined}
                    key="-1"
                  >{"None"}</MenuItem>
                ))}
              </Select>
            </Grid> : null
          }
          {!['ADMIN', 'SADMIN', 'GADMIN', 'DISTR'].includes(role.role) && role.role?
            <Grid item xs={12} style={{ padding: 4 }}>
              <p>Select Clinic(s)</p>
              <Select
                disabled={isUpdating}
                value={role.clinics}
                onChange={handleUpdate('clinics')}
                style={{ width: '100%' }}
                margin="normal"
                label="Clinics"
                id="clinics"
              >
                {clinics.sort((a, b) => a.clinic_name.localeCompare(b.clinic_name)).map(clinic =>
                  (<MenuItem value={clinic.clinic_id} key={clinic.clinic_id}>{clinic.clinic_name}</MenuItem>)
                ).concat((
                  <MenuItem
                    value={undefined}
                    key="-1"
                  >{"None"}</MenuItem>
                ))}
              </Select>
            </Grid> : null}
          {/* {['NR', 'DR'].includes(role.role) ?
            <Grid item xs={12} style={{ padding: 4 }}>
              <p>Select Distributor(s)</p>
              <Select
                disabled={isUpdating}
                value={role.distributors}
                onChange={handleUpdate('distributors')}
                style={{ width: '100%' }}
                margin="normal"
                label="Distributors"
                multiple
                id="distributors"
              >
                {distributors.sort((a, b) => `${a.first_name} ${a.last_name}`.localeCompare(`${b.first_name} ${b.last_name}`)).map(distributor =>
                  (
                    <MenuItem
                      value={distributor.user_id}
                      key={distributor.user_id}
                    >
                      {`${distributor.first_name} ${distributor.last_name}`}
                    </MenuItem>
                  )
                )}
              </Select>
            </Grid> : null} */}
          {role.role === 'DR' ?
            <Grid item xs={12} style={{ padding: 4 }}>
              <p>Select Nurse(s)</p>
              <Select
                disabled={isUpdating}
                value={role.nurses}
                onChange={handleUpdate('nurses')}
                style={{ width: '100%' }}
                margin="normal"
                label="Nurses"
                multiple
                id="nurses"
              >
                {nurses.sort((a, b) => `${a.first_name} ${a.last_name}`.localeCompare(`${b.first_name} ${b.last_name}`)).map(nurse =>
                  (
                    <MenuItem
                      value={nurse.user_id}
                      key={nurse.user_id}
                    >
                      {`${nurse.first_name}  ${nurse.last_name}`}
                    </MenuItem>
                  )
                )}
              </Select>
            </Grid> : null}
          {role.role === 'NR'?
            <Grid item xs={12} style={{ padding: 4 }}>
              <p>Select Doctor(s)</p>
              <Select
                disabled={isUpdating}
                value={role.doctors}
                onChange={handleUpdate('doctors')}
                style={{ width: '100%' }}
                margin="normal"
                label="Doctors"
                multiple
                id="doctors"
                displayEmpty
              >
                {doctors.sort((a, b) => `${a.first_name} ${a.last_name}`.localeCompare(`${b.first_name} ${b.last_name}`)).map(doctor =>
                  (
                    <MenuItem
                      value={doctor.user_id}
                      key={doctor.user_id}
                    >
                      {`${doctor.first_name}  ${doctor.last_name}`}
                    </MenuItem>
                  )
                )}
              </Select>
            </Grid> : null}
          {(((localStorage.getItem('role') && JSON.parse(localStorage.getItem('role'))) === 'SADMIN' && role.role === 'ADMIN') || (['SADMIN', 'ADMIN'].includes(localStorage.getItem('role') && JSON.parse(localStorage.getItem('role'))) && role.role === 'DISTR'))
            ? <FormGroup row>
                <FormControlLabel
                  control={<Checkbox checked={role.role === 'DISTR'? role.distr_group_admin : (role.role === 'ADMIN'? role.admin_group_admin : false)} onChange={handleIsAdmin} name="isAdmin" />}
                  label="Group Admin"
                />
              </FormGroup>
            : null
          }
          <p style={{ color: "red", marginBottom: "0" }}>{modalError}</p>
          <Button
            disabled={isUpdating}
            variant="contained"
            color="primary"
            className={classes.login}
            style={{
              marginBottom: 16,
              height: 48
            }}
            fullWidth
            // onClick={this.openModal}
            onClick={handleSave}
          >
            {isUpdating ? <CircularProgress style={{ color: "white" }} /> :
              <div style={{ color: "white" }}>
                {user && user.approved_flag ? 'Change Role' : 'Approve User'}<SendIcon style={{ margin: "0px 8px", verticalAlign: "middle", color: "white" }} />
              </div>}
          </Button>
        </Grid>
      }
    </>
  )
}
export default Roles
