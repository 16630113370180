import React, { useEffect } from 'react';
import { Select, MenuItem, AppBar, Tabs, Tab, Typography, Box, TextField, Grid, makeStyles } from '@material-ui/core'
import StlImpression from './StlImpression'
import style from './NewPatientPhotos.module.css'
import { useSelector, useDispatch } from 'react-redux';
import * as actionTypes from '../../../store/NewPatient/actionType';
import { useLang } from '../../../util/lang';



const TabPanel = ({ children, value, index, ...other }) => (
  <Typography
    component="div"
    role="tabpanel"
    hidden={value !== index}
    id={`full-width-tabpanel-${index}`}
    aria-labelledby={`full-width-tab-${index}`}
    {...other}
  >
    {value === index && <Box p={3}>{children}</Box>}
  </Typography>
)

const LinkTab = props => (
  <Tab
    component="a"
    onClick={event => {
      event.preventDefault();
    }}
    {...props}
  />
)

const ImpressionTabsStyle = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const ImpressionType  = [
  'FILE',
  'COURIER',
  'SCANNER',
]

export default function ImpressionTabs({
  uploadLater, handleChange, mode, checkInvalid
}) {
  const classes = ImpressionTabsStyle(),
        dispatch = useDispatch(),
        get = useLang('NewPatient'),
        { impressionType, scannerBrand, doctorId, modernCode, files, waybillNumber, courierCompany } = useSelector(store => store.newPatientReducer)

  const [value, setValue] = React.useState(0);

  useEffect(() => {
    setValue(ImpressionType.findIndex(v => v === impressionType))
  }, [])

  useEffect(() => {
    setValue(ImpressionType.findIndex(v => v === impressionType))
  }, [impressionType])

  const a11yProps = index => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  return (
    <div className={[classes.root, "tabContainer"].join(' ')}>
      <div className={style.headerWrapper}>
        <AppBar position="static" style={{ marginTop: 32 }}>

          <Tabs
            variant="fullWidth"
            value={value}
            onChange={mode !== 'view' && ((_, value) => dispatch({
              type: actionTypes.NEW_PATIENT_IMPRESSION_TYPE,
              value: ImpressionType[value]
            }))}
            aria-label="nav tabs"
            className="inkBar"
            // indicatorColor="primary"
            TabIndicatorProps={{
              style: {
                backgroundColor: "#593687"
              }
            }}
          >
            <LinkTab label={get('impressions.uploadStl')} href="/drafts" {...a11yProps(0)} />
            <LinkTab label={get('impressions.uploadByCourier')} href="/trash" {...a11yProps(1)} />
            <LinkTab label={get('impressions.uploadByScanner')} href="/spam" {...a11yProps(2)} />
          </Tabs>

        </AppBar>
      </div>
      <TabPanel value={value} index={0}>
        <ul className={style.patientImages}>
          <StlImpression
            mode={mode}
            imageUploaded={value => files.find(file => file.id === value)?.uploaded}
            imageData={(value) => files.find(file => file.id === value)}
            uploadLater={uploadLater == 'patientInfo' ? false : true}
            handleRoatate={(value, degree) => {
              dispatch({
                type: actionTypes.NEW_PATIENT_FILE_ROTATE,
                id: value,
                value: degree
              })
            }}
            handleChange={(value, file) => handleChange(value, file)} />
        </ul>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid container spacing={1}>
          <TextField
            id="outlined-basic"
            label={get('impressions.byCourierLabel')}
            variant="outlined"
            style={{ width: "100%" }}
            value={modernCode}
            onChange={mode !== 'view' && (async ({ target: { value } }) => {
              await dispatch({
                type: actionTypes.NEW_PATIENT_MODERN_CODE,
                value
              })
              checkInvalid()
            })}
          />
        </Grid>
        <Grid container spacing={1} style={{ marginTop: 16 }}>
          <TextField
            id="outlined-basic"
            label={get('impressions.waybillNumber') == "N/A" ? "Waybill Number" : get('impressions.wayBillNumber')}
            variant="outlined"
            style={{ 
              width: "49%",
              marginRight: "1%"
            }}
            value={waybillNumber}
            onChange={mode !== 'view' && (async ({ target: { value } }) => {
              await dispatch({
                type: actionTypes.NEW_PATIENT_WAYBILL_NUMBER,
                value
              })
              checkInvalid()
            })}
          />
          <Select
            defaultValue=""
            displayEmpty
            variant="outlined"
            value={courierCompany}
            onChange={mode !== 'view' && (async ({ target: { value } }) => {
              await dispatch({
                type: actionTypes.NEW_PATIENT_COURIER_COMPANY,
                value
              })
              checkInvalid()
            })}
            style={{
              width: "49%",
              marginLeft: "1%"
            }}
          >
            <MenuItem value="" disabled={true}>
              {get('impressions.courierCompany') != "N/A" ? get('impressions.courierCompany') : "Courier Company"}
            </MenuItem>
            {
              (get('$Courier') || []).map(v => (
                v ? <MenuItem value={v} key={v}>{v}</MenuItem> : ""
              ))
            }
          </Select>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} ld={4}>
            <TextField
              id="outlined-basic"
              label={get('impressions.byScannerBrandLabel')}
              variant="outlined"
              value={scannerBrand}
              style={{ width: "100%" }}
              onChange={mode !== 'view' && (async ({ target: { value } }) => {
                await dispatch({
                  type: actionTypes.NEW_PATIENT_SCANNER,
                  value
                })
                checkInvalid()
              })}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} style={{ marginTop: 16 }}>
          <Grid item xs={12} md={6} ld={4}>
            <TextField
              id="outlined-basic"
              label={get('impressions.byScannerUserNameLabel')}
              variant="outlined"
              value={doctorId}
              style={{ width: "100%" }}
              onChange={mode !== 'view' && (async ({ target: { value } }) => {
                await dispatch({
                  type: actionTypes.NEW_PATIENT_DOCTOR,
                  value
                })
                checkInvalid()
              })}
            />
          </Grid>
        </Grid>
      </TabPanel>
    </div>
  );
}