import React, { Component, PropTypes } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import * as actionTypes from '../../store/NewPatient/actionType';
import style from './NewPatientPrintData.module.css'
import Container from '@material-ui/core/Container';
import axios from '../../axios-default'
import Loader from "../../components/Loader";
import { withLang, getFromObj } from '../../util/lang';
import Url from 'url'
import join from 'url-join'
import { ApiServerUrl } from '../../config.json'
import Moment from 'moment';
import { getWebAbsPath } from '../../util/path';

export const teethGraph = [
  {
    margin: "352px 0px 0px 206px",
    width: 56
  },
  {
    margin: "305px 0px 0px 211px",
    width: 59
  },
  {
    margin: "253px 0px 0px 216px",
    width: 61
  },
  {
    margin: "214px 0px 0px 230px",
    width: 59
  },
  {
    margin: "178px 0px 0px 247px",
    width: 58
  },
  {
    margin: "146px 0px 0px 272px",
    width: 48
  },
  {
    margin: "118px 0px 0px 296px",
    width: 48
  },
  {
    margin: "108px 0px 0px 328px",
    width: 49
  },
  {
    margin: "108px 0px 0px 370px",
    deg: "scaleX(-1)",
    width: 49
  },
  {
    margin: "118px 0px 0px 402px",
    deg: "scaleX(-1)",
    width: 48
  },
  {
    margin: "146px 0px 0px 425px",
    deg: "scaleX(-1)",
    width: 48
  },
  {
    margin: "178px 0px 0px 444px",
    deg: "scaleX(-1)",
    width: 58
  },
  {
    margin: "214px 0px 0px 460px",
    deg: "scaleX(-1)",
    width: 59
  },
  {
    margin: "253px 0px 0px 474px",
    deg: "scaleX(-1)",
    width: 61
  },
  {
    margin: "305px 0px 0px 488px",
    deg: "scaleX(-1)",
    width: 59
  },
  {
    margin: "352px 0px 0px 497px",
    deg: "scaleX(-1)",
    width: 56
  },
  {
    margin: "431px 0px 0px 497px",
    deg: "scaleX(-1) scaleY(-1)",
    width: 56
  },
  {
    margin: "477px 0px 0px 488px",
    deg: "scaleX(-1) scaleY(-1)",
    width: 59
  },
  {
    margin: "523px 0px 0px 474px",
    deg: "scaleX(-1) scaleY(-1)",
    width: 61
  },
  {
    margin: "570px 0px 0px 460px",
    deg: "scaleX(-1) scaleY(-1)",
    width: 59
  },
  {
    margin: "604px 0px 0px 444px",
    deg: "scaleX(-1) scaleY(-1)",
    width: 58
  },
  {
    margin: "639px 0px 0px 425px",
    deg: "scaleX(-1) scaleY(-1)",
    width: 48
  },
  {
    margin: "663px 0px 0px 402px",
    deg: "scaleX(-1) scaleY(-1)",
    width: 48
  },
  {
    margin: "674px 0px 0px 370px",
    deg: "scaleX(-1) scaleY(-1)",
    width: 49
  },
  {
    margin: "674px 0px 0px 328px",
    deg: "scaleY(-1)",
    width: 49
  },
  {
    margin: "663px 0px 0px 296px",
    deg: "scaleY(-1)",
    width: 48
  },
  {
    margin: "639px 0px 0px 272px",
    deg: "scaleY(-1)",
    width: 48
  },
  {
    margin: "604px 0px 0px 247px",
    deg: "scaleY(-1)",
    width: 59
  },
  {
    margin: "570px 0px 0px 230px",
    deg: "scaleY(-1)",
    width: 61
  },
  {
    margin: "523px 0px 0px 216px",
    deg: "scaleY(-1)",
    width: 61
  },
  {
    margin: "477px 0px 0px 211px",
    deg: "scaleY(-1)",
    width: 59
  },
  {
    margin: "431px 0px 0px 206px",
    deg: "scaleY(-1)",
    width: 58
  },
]

const labelIntrerpret = {
  primary: "Primary",
  secondary: "Secondary",
  none: "None",
  clinicalSimulation: "Refer to Clinical Simulation",
  improve1mm: 'Improve 1mm',
  improve2mm: 'Improve 2mm',
  improve3mm: 'Improve 3mm',
  improve4mm: 'Improve 4mm',
  improve5mm: 'Improve 5mm',
  maintain: "Maintain",
  improve: "Improve",
  improveUpper: 'Improve Upper to match Facial Midline',
  lowerLower: 'Improve Lower midline to match Upper midline',
  upperLowerMidline: 'Correct upper + lower midline',
  correctUpper: 'Correct Upper by 1-5mm',
  correctLower: 'Correct Lower by 1-5 mm'
}

function getRealLabel(key) {
  return key in labelIntrerpret? labelIntrerpret[key] : key
}

class NewPatientPrintData extends Component {

  constructor(props) {
    super(props);
    this.state = {
      patientInfo: null
    }
    this.excluded_dentition = []
  }

  async componentDidMount() {
    let { id } = this.props.match.params
    try {
      var { data: { case: [ targetCase ] } } = await axios.get(`case/${id}`)
    }
    catch (err) {
      if (err.response && err.response.statusText === 'Unauthorized') {
        localStorage.removeItem("auth-token");
        localStorage.removeItem("expirationDate");
        localStorage.removeItem("role");
        this.props.history.replace('/login')
      }
    }
    this.excluded_dentition = targetCase.case_detail?.flat().slice(-1)?.[0]?.excluded_dentition.split(',').map(x => parseInt(x))
    if (this.state.patientInfo == null) {
      this.setState({ patientInfo: targetCase })
    }
    setTimeout(function () { window.print() }, 1000);
  }

  render() {
    if (this.state.patientInfo == null) {
      return <Loader />
    } else {
      const case_detail = this.state.patientInfo.case_detail?.flat().slice(-1)?.[0],
            case_file = this.state.patientInfo.case_file?.flat() ?? [],
            case_impression = this.state.patientInfo.case_impression?.flat() ?? [],
            case_cbct = this.state.patientInfo.case_cbct?.flat() ?? []
      try {
        case_detail.attr8 = JSON.parse(case_detail.attr8)
      } catch {}
      if (!Array.isArray(case_detail.attr8)) {
        case_detail.attr8 = [case_detail.attr8]
      }
      return (
        <Container maxWidth="lg">
          <div class="page-title" style={{ margin: "20px 0px 20px 0px", textAlign: "center" }}>
            <h1 style={{ margin: "0", paddingBottom: "13px" }}>
              {this.get('summary.orderInfo')}<strong>#{this.state.patientInfo.case_id}</strong>
            </h1>
          </div>
          <Grid container spacing={3} style={{ padding: 10 }}>

            <Grid item xs={12} sm={8}>
              <div className={style.LeftGrid}>
                <ul className={style.list}>

                  <li>{this.get('summary.dentitionNotMove')}</li>
                  <div className={style.toothImages}>
                    <div
                      style={{
                        width: 704,
                        height: 750,
                        position: "relative",
                        backgroundRepeat: "no-repeat",
                        marginLeft: '-1em'
                        // backgroundImage: "url('/img/newTeeth/permanentTeeth.png')"
                      }}
                    >
                      <img src={getWebAbsPath("/img/newTeeth/permanentTeeth.png")} style={{width: '100%', height: '100%', position: 'absolute'}}/>
                      {
                        teethGraph.map((v, i) =>
                          <div key={i}>
                            <img
                              src={getWebAbsPath(`/img/newTeeth/t${i + 1}.png`)}
                              style={{
                                position: "absolute",
                                margin: v.margin,
                                transform: `${v.deg}`,
                                transformOrigin: "50% 50%",
                                width: v.width
                              }}
                            />
                            {this.excluded_dentition.includes(i) &&
                              <img
                                src={getWebAbsPath("/img/tooth/check.png")}
                                style={{
                                  position: "absolute",
                                  margin: v.margin,
                                  width: v.width
                                }}
                              />}
                          </div>
                        )
                      }
                    </div>
                  </div>


                  <li>{this.get('questionnaire.gainSpaceMethod')}</li>
                  <div className={style.radioWrapper}>
                    <p>{this.get('questionnaire.extraction')} : {getRealLabel(case_detail.attr0)} {String.isNotBlank(case_detail.attr10)? `(${case_detail.attr10})` : ''}</p>
                    <p>{this.get('questionnaire.expansion')} : {getRealLabel(case_detail.attr1)} {String.isNotBlank(case_detail.attr11)? `(${getRealLabel(case_detail.attr11)})` : ''}</p>
                    <p>{this.get('questionnaire.interproximalReduction')} : {getRealLabel(case_detail.attr2)}</p>
                    <p>{this.get('questionnaire.distalization')} : {getRealLabel(case_detail.attr3)}</p>
                    <p>{this.get('questionnaire.proclination')} : {getRealLabel(case_detail.attr4)}</p>
                    <p>{this.get('questionnaire.referToClinicalSimulation')} : {getRealLabel(case_detail.attr5)}</p>
                  </div>

                  <li>{this.get('questionnaire.overjet')}</li>
                  <div className={style.radioWrapper}>
                    <p>{getRealLabel(case_detail.attr6)}</p>
                  </div>


                  <li>{this.get('questionnaire.overbite')}</li>
                  <div className={style.radioWrapper}>
                    <p>{getRealLabel(case_detail.attr7)}</p>
                  </div>


                  <li>{this.get('questionnaire.midline')}</li>
                  <div className={style.radioWrapper}>
                    {case_detail.attr8.length? case_detail.attr8.map(k => (
                      <p>{getRealLabel(k)}</p>
                    )) : getRealLabel('none')}
                  </div>

                  <li>{this.get('questionnaire.crossbite')}</li>

                  <div className={style.radioWrapper}>
                    <p>{getRealLabel(case_detail.attr9)}</p>
                  </div>

                  <li>{this.get('questionnaire.comment')}</li>

                  <div className={style.radioWrapper}>
                    <p>{getRealLabel(case_detail.attr12)}</p>
                  </div>

                  <li>{this.get('impressions.byCourierLabel')}</li>

                  <div className={style.radioWrapper}>
                    <p>{case_impression.slice(-1)?.[0]?.modern_code}</p>
                  </div>

                  <li>{this.get('impressions.byScannerBrandLabel')}</li>

                  <div className={style.radioWrapper}>
                    <p>{case_impression.slice(-1)?.[0]?.scanner_brand}</p>
                  </div>

                  <li>{this.get('impressions.doctorId')}</li>

                  <div className={style.radioWrapper}>
                    <p>{case_impression.slice(-1)?.[0]?.doctor_id}</p>
                  </div>


                </ul>
                <div className={style.UploadedImages}>
                  <p>{this.get('summary.allPhotos')}</p>
                  <GridList cellHeight={160} className={style.gridList} cols={3}>
                    {
                      case_file.map(file => (
                        <>
                          <div style={{ display: "block", width: "100%" }}>
                            <p style={{ display: "inline", marginRight: "15px", fontSize: 14 }}><strong>{getFromObj(this.props.lang, 'dict.PatientInfo.modals.uploadedAt')}</strong> :{Moment(file.created_date).format('YYYY-MM-DD')}</p>
                            <hr />
                          </div>
                          <GridList style={{ width: '100%' }} cols={3}>
                            {Object.entries(file)
                                  .filter(([key, value]) => key.startsWith('photo') && !key.endsWith('_later') && String.isNotBlank(value))
                                  .map(([key, value]) => (
                                    <GridListTile cols={1} key={key}>
                                      <img src={join(ApiServerUrl, value)} />
                                    </GridListTile>
                                  ))}
                          </GridList>
                        </>
                      ))
                    }
                  </GridList>
                </div>

                <div className={style.UploadedImages}>
                  <p>{this.get('summary.allRadiography')}</p>
                  <GridList cellHeight={160} className={style.gridList} cols={3}>
                    {
                      [...case_file, ...case_cbct].map(file => (
                        <>
                          <div style={{ display: "block", width: "100%" }}>
                            <p style={{ display: "inline", marginRight: "15px", fontSize: 14 }}><strong>{getFromObj(this.props.lang, 'dict.PatientInfo.modals.uploadedAt')}</strong> :{Moment(file.created_date).format('YYYY-MM-DD')}</p>
                            <hr />
                          </div>
                          {this.state.patientInfo.case_type?.toUpperCase() === 'CBCT'?
                            <Grid item xs={12} sm={3} key={file.name}>
                              <a style={{ padding: 10 }} href={join(ApiServerUrl, file.path)}>
                                {file.name}
                              </a>
                            </Grid> :
                            <GridList style={{ width: '100%' }} cols={3}>
                              {Object.entries(file)
                                .filter(([key, value]) => key.startsWith('radio') && !key.endsWith('_later') && String.isNotBlank(value))
                                .map(([key, value], i) => (
                                  <GridListTile cols={1} key={key}>
                                    {['jpg', 'jpeg', 'png'].includes(value.match(/\.(.+)$/)?.[1].toLowerCase())?
                                      <img style={{maxWidth: 100, padding: 10}} src={join(ApiServerUrl, value)} /> :
                                      <a style={{ padding: 10 }} href={join(ApiServerUrl, value)}>
                                        {[getFromObj(this.props.lang, 'dict.PatientInfo.NewPatient.radiographs.orthopantomogram'), getFromObj(this.props.lang, 'dict.PatientInfo.NewPatient.radiographs.lateralCephalogram')][parseInt(key.slice(-1))]}
                                      </a>
                                    }
                                  </GridListTile>
                                ))}
                            </GridList>
                          }
                        </>
                      ))
                    }
                  </GridList>
                </div>

                <div className={style.UploadedImages}>
                  <p>{this.get('summary.allImpressions')}</p>
                  <GridList cellHeight={160} className={style.gridList} cols={3}>
                    {
                      case_impression.map(impression => (
                        <>
                          <div style={{ display: "block", width: "100%" }}>
                            <p style={{ display: "inline", marginRight: "15px", fontSize: 14 }}><strong>{getFromObj(this.props.lang, 'dict.PatientInfo.modals.uploadedAt')}</strong> :{Moment(impression.created_date).format('YYYY-MM-DD')}</p>
                            <hr />
                          </div>
                          <GridList style={{ width: '100%' }} cols={4}>
                            {Object.entries(impression)
                              .filter(([key, value]) => key.startsWith('stl_file') && !key.endsWith('_later') && String.isNotBlank(value))
                              .map(([key, value]) => (
                                <GridListTile cols={1} key={key}>
                                  {['jpg', 'jpeg', 'png'].includes(value.match(/\.(.+)$/)?.[1].toLowerCase())?
                                    <img style={{maxWidth: 100, padding: 10}} src={join(ApiServerUrl, value)} /> :
                                    <a style={{ padding: 10 }} href={join(ApiServerUrl, value)}>{["Upper STL", "Lower STL", "Bite 1 STL", "Bite 2 STL"][parseInt(key.slice(-1))]}</a>}
                                </GridListTile>
                              ))}
                          </GridList>
                        </>
                      ))
                    }
                    {/* {
                      case_impression.map((value) => (
                        Object.keys(value).map((key1) => (
                          key1.includes("stl_file") && value[key1] !== null &&
                          <GridListTile item xs={12} sm={3} key={value.id}>
                            <img src={join(ApiServerUrl, value[key1])} />
                          </GridListTile>
                        ))

                      ))

                    } */}
                  </GridList>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={4}>

              <div className={style.UserInfo}>

                <div className={style.UserData}>
                  <ul>
                    <li><span className={style.UserDataLabel}>{this.get(':fields.caseId')}</span> : {this.state.patientInfo.case_id}</li>
                    <li><span className={style.UserDataLabel}>{this.get(':fields.patient')}</span> : {this.state.patientInfo.first_name} {this.state.patientInfo.last_name}</li>
                    <li><span className={style.UserDataLabel}>{this.get(':fields.gender')}</span> : {this.state.patientInfo.gender == 'M' ? this.get(':fields.male') : (this.state.patientInfo.gender == 'F' ? this.get(':fields.female') : this.get(':fields.other'))}</li>
                    <li><span className={style.UserDataLabel}>{this.get(':fields.dateOfBirthAbbr')}</span> : {this.state.patientInfo.date_of_birth}</li>
                    <li><span className={style.UserDataLabel}>{this.get(':fields.remarks')}</span> : {this.state.patientInfo.remarks}</li>
                  </ul>
                </div>
              </div>

              <p><strong>{this.get(':fields.deliveryAddress')}</strong>: {this.props.NewAddressData.address}</p>
              {this.props.NewAddressData.city && <p><strong>{this.get(':fields.city')}</strong>: {this.props.NewAddressData.city}</p>}
              {this.props.NewAddressData.zipCode && <p><strong>{this.get(':fields.postCode')}</strong>: {this.props.NewAddressData.zipCode}</p>}
              {this.props.NewAddressData.phoneNumber && <p><strong>{this.get(':fields.phoneNo')}</strong>: {this.props.NewAddressData.phoneNumber}</p>}

              <p>{this.get(':fields.promotionCode')} : {this.props.NewPaitientData.promotionCode}</p>

            </Grid>
          </Grid>
        </Container>
      );
    }
  }
}

const mapStateToProps = store => {
  return {
    NewPaitientData: store.newPatientReducer,
    NewAddressData: store.newAddressReducer,
    lang: store.lang,
  }
};

export default connect(mapStateToProps)(withRouter(withLang(NewPatientPrintData, 'NewPatient')));
