import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import * as actionTypes from '../../../store/NewPatient/actionType';
import { Grid, TextField, Button, Select, MenuItem, Radio, RadioGroup, FormControlLabel } from '@material-ui/core'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import DragAndDrop from '../Photos/DragAndDrop'
import style from './NewPatientInfo.module.css'
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import { withLang } from '../../../util/lang';
import countryCodeArr from '../../../util/country_code_latest.json'

// material ui and css stuff
// import { Grid } from '@material-ui/core'

const styles = theme => ({
  root: {
    margin: '48px auto',
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
    },
    [theme.breakpoints.up('lg')]: {
      width: "70%",
      minHeight: "400px",
    },
  },
  mainCard: {
    backgroundColor: "white",
    borderRight: "solid 6px #353535",
    color: "white",
    [theme.breakpoints.down('xs')]: {
      borderRight: "none",
    },
  },
  mainContainer: {
    textAlign: "left",
    boxShadow: "0 1px 2px rgba(0,0,0,0.15)",
    backgroundColor: "white",
    padding: 16,
    [theme.breakpoints.down('xs')]: {
      maxHeight: "unset"
    },
  },
  textField: {
    width: "100%",
    fontWeight: "300 !important"
  },
  textfieldContainer: {
    padding: 4
  },
  genderSelectMale: {
    borderLeft: "solid 1px #4a4f90",
    borderTop: "solid 1px #4a4f90",
    borderBottom: "solid 1px #4a4f90",
    borderRadius: 0,
    color: "#4a4f90",
    padding: "4px 24px",
    fontWeight: 300,
  },
  genderSelectFemale: {
    borderRight: "solid 1px #4a4f90",
    borderTop: "solid 1px #4a4f90",
    borderBottom: "solid 1px #4a4f90",
    borderRadius: 0,
    color: "#4a4f90",
    padding: "4px 24px",
    fontWeight: 300,
  },
  genderSelectOther: {
    borderRight: "solid 1px #4a4f90",
    borderTop: "solid 1px #4a4f90",
    borderBottom: "solid 1px #4a4f90",
    borderRadius: 0,
    color: "#4a4f90",
    padding: "4px 24px",
    fontWeight: 300,
  },
  genderSelectActive: {
    border: "solid 1px #4a4f90",
    borderRadius: 0,
    backgroundColor: "#4a4f90",
    color: "white",
    padding: "4px 24px",
    fontWeight: 300,
    "&:hover": {
      background: "#4a4f90",
      color: "white"
    }
  },
  dateLabel: {
    marginBlockStart: "8px",
    marginBlockEnd: "8px",
    fontSize: "14px"
  },
  countryCodeLabel: {
    marginBlockStart: "8px",
    marginBlockEnd: "5px",
    fontSize: "14px"
  }
})


class NewPatientInfo extends Component {
 
  constructor(props) {
    super(props)
    this.state = {
      rotateDegree: 0
    }
    this.removefile = this.removefile.bind(this)
    this.rotate = this.rotate.bind(this)
  }

  handleDrop = (uploadedFile) => {
    this.fileupload(uploadedFile)
  }

  rotate(e, direction) {
    if (direction == 'rotateRight') {
      this.setState(currentState => ({ rotateDegree: currentState.rotateDegree % 360 + 90 }), () => {
        this.props.handleDataPhotoRotate(this.state.rotateDegree)
      });
    }
    else {
      this.setState(currentState => ({ rotateDegree: currentState.rotateDegree % 360 - 90 }), () => {
        this.props.handleDataPhotoRotate(this.state.rotateDegree)
      });

    }
  }

  // fileToBase64 = file => new Promise((resolve, reject) => {
  //   const reader = new FileReader();
  //   if (file !== undefined) {
  //     reader.readAsDataURL(file);
  //   }
  //   reader.onload = () => resolve(reader.result);
  //   reader.onerror = error => reject(error);
  // });

  checkInvalid() {
    if (this.props.firstName.length == 0) {
      this.props.ErrorData({ id: "patient_info", msg: this.get('warning.firstName'), valid: false })
    } else if (this.props.lastName.length == 0) {
      this.props.ErrorData({ id: "patient_info", msg: this.get('warning.lastName'), valid: false })
    } else if (this.props.country_code.length == 0) {
      this.props.ErrorData({ id: "patient_info", msg: "missing country_code", valid: false })
    } else if (!this.props.contact_number || this.props.contact_number.length == 0){
      this.props.ErrorData({ id: "patient_info", msg: "missing contact_number", valid: false })
    } else if (!this.props.email || this.props.email.length == 0){
      this.props.ErrorData({ id: "patient_info", msg: "missing email", valid: false })
    }
    else {
      this.props.ErrorData({ id: "patient_info", msg: '', valid: true })
    }
  }

  fileupload(file) {
    let ClassThis = this
    ClassThis.props.handleDataPhoto(file)
    // let baseFile = this.fileToBase64(file).then((data) => {
    //   var i = new Image();
    //   i.src = data;
    //   i.onload = function () {
    //     ClassThis.props.handleDataPhoto(data)
    //   }

    // })
  }

  removefile() {
    this.props.handleDataPhoto(null)
    this.setState({ 'rotateDegree': 0 })
  }

  returnDays() {
    let ary = []
    let days = 0
    let bigMonth = [1, 3, 5, 7, 8, 10, 12]
    if (this.props.month !== 2 && bigMonth.includes(this.props.month)) {
      days = 31
    }
    if (this.props.month !== 2 && !bigMonth.includes(this.props.month)) {
      days = 30
    }
    else if (this.props.month === 2 && this.props.year % 4 === 0) {
      days = 29
    }
    else if (this.props.month === 2 && this.props.year % 4 !== 0) {
      days = 28
    }
    for (let i = 1; i < days + 1; i++) {
      ary.push(<MenuItem value={i} key={"day" + i}>{i}</MenuItem>)
      if (i === days) return ary
    }
  }

  returnMonths() {
    let ary = []
    for (let i = 1; i < 13; i++) {
      ary.push(<MenuItem value={i} key={"month" + i}>{i}</MenuItem>)
      if (i === 12) return ary
    }
  }

  returnYears() {
    let ary = []
    let thisYear = (new Date()).getYear() + 1800
    for (let i = thisYear; i < (thisYear + 101); i++) {
      ary.push(<MenuItem value={i} key={"year" + i}>{i}</MenuItem>)
      if (i === thisYear + 100) return ary
    }
  }
  returnCountryCode(){
    let arr = [];
    for (let i = 0; i < countryCodeArr.length; i++) {
      arr.push(<MenuItem value={countryCodeArr[i].code} key={"country" + i}>{countryCodeArr[i].name} ({countryCodeArr[i].code} )</MenuItem>)
    }
    return arr;
  }
  onFileChange = ({ target: { files: [ file ] } }) => {
    let compo = this
    compo.props.handleDataPhoto(file)
  }

  render() {
    const { classes } = this.props
    const profilePic = {
      data: this.props.patientPhoto?.dataURL ?? this.props.patientPhoto?.data,
      rotate: this.props.patientPhoto?.rotate ?? 0
    }
    const { role } = this.props
    return (
      <div style={{ display: "contents" }}>
        <Grid item xs={12} md={6} style={{ textAlign: "center" }}>
          <Grid container>
            <Grid item xs={12}>

            </Grid>
            <Grid item xs={12} style={{ padding: 12 }}>
              {
                !profilePic.data?
                <DragAndDrop handleDrop={this.handleDrop}>
                  <div className={style.imageUploadArea}>
                    <span>Upload patient profile picture (optional)</span>
                    <div className={style.uploadBtnWrapper}>
                      <button className={style.uploadBtn}>+</button>
                      <input
                        type="file"
                        onChange={this.onFileChange}
                        accept="image/*"
                        name="patientImage"
                        id="patientImage"
                      />
                    </div>
                  </div>
                </DragAndDrop> :
                <DragAndDrop handleDrop={this.handleDrop}>
                  <div className={style.imageUploadArea}>
                    <img style={{ width: "100%", maxWidth: 400, marginTop: "7%", transform: `rotateZ(${profilePic.rotate}deg)` }} src={profilePic.data} />
                    <button className={style.remove} onClick={this.removefile}><DeleteOutlinedIcon /></button>
                  </div>

                  <div className={style.uploadFooter}>
                    <Button style={{ marginLeft: 15 }} variant="outlined" className={style.customButton} value="rotateLeft" onClick={(e) => this.rotate(e, "rotateLeft")}><RotateLeftIcon /></Button>
                    <Button style={{ marginLeft: 15 }} variant="outlined" className={style.customButton} value="rotateRight" onClick={(e) => this.rotate(e, "rotateRight")}><RotateRightIcon /></Button>
                  </div>

                </DragAndDrop>
              }

            </Grid>
          </Grid>

        </Grid>

        <Grid item xs={12} md={6}>
          <Grid container>
            <Grid item xs={12} sm={6} className={classes.textfieldContainer}>
              <TextField
                id="firstName"
                label={"*"+this.get(':fields.firstName')}
                className={classes.textField}
                value={this.props.firstName}
                onChange={async (event) => {
                  await this.props.handleDataFirstName(event.target.value)
                  this.checkInvalid()
                }}
                margin="normal"
              />
              <p className={style.error}>{this.props.error && this.props.error["firstName"]}</p>
            </Grid>

            <Grid item xs={12} sm={6} className={classes.textfieldContainer}>

              <TextField
                id="lastName"
                label={"*"+this.get(':fields.lastName')}
                className={classes.textField}
                value={this.props.lastName}
                onChange={async (event) => {
                  await this.props.handleDataLastName(event.target.value)
                  this.checkInvalid()
                }}
                margin="normal"
              />
              <p className={style.error}>{this.props.error && this.props.error["lastName"]}</p>
            </Grid>
            
            <Grid item xs={12} className={classes.textfieldContainer}>
              <p>{this.get(':fields.gender')}</p>
              <Button
                className={this.props.gender === "M" ? classes.genderSelectActive : classes.genderSelectMale}
                onClick={() => this.props.handleDataGender("M")}
              >
                {this.get(':fields.male')}
              </Button>
              <Button
                className={this.props.gender === "F" ? classes.genderSelectActive : classes.genderSelectFemale}
                onClick={() => this.props.handleDataGender("F")}
              >
                {this.get(':fields.female')}
              </Button>
              <Button
                className={this.props.gender === "O" ? classes.genderSelectActive : classes.genderSelectOther}
                onClick={() => this.props.handleDataGender("O")}
              >
                {this.get(':fields.other')}
              </Button>
            </Grid>

            <Grid item xs={12} className={classes.textfieldContainer}>
              <p>{this.get(':fields.dateOfBirth')}</p>
              <Grid container>
                <Grid item xs={4} style={{ padding: 4 }}>
                  <p className={classes.dateLabel}>{this.get(':fields.day')}</p>
                  <Select
                    value={this.props.day}
                    onChange={(event) => this.props.handleDataDay(event.target.value)}
                    inputProps={{
                      name: 'day',
                      id: 'day',
                    }}
                    style={{
                      width: "100%"
                    }}
                  >
                    {this.returnDays()}
                  </Select>
                </Grid>

                <Grid item xs={4} style={{ padding: 4 }}>
                  <p className={classes.dateLabel}>{this.get(':fields.month')}</p>
                  <Select
                    value={this.props.month}
                    onChange={(event) => this.props.handleDataMonth(event.target.value)}
                    inputProps={{
                      name: 'month',
                      id: 'month',
                    }}
                    style={{
                      width: "100%"
                    }}
                  >
                    {this.returnMonths()}
                  </Select>
                </Grid>

                <Grid item xs={4} style={{ padding: 4 }}>
                  <p className={classes.dateLabel}>{this.get(':fields.year')}</p>
                  <Select
                    value={this.props.year}
                    onChange={(event) => this.props.handleDataYear(event.target.value)}
                    inputProps={{
                      name: 'year',
                      id: 'year',
                    }}
                    style={{
                      width: "100%"
                    }}
                  >
                    {this.returnYears()}
                  </Select>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={3} sm={3} className={classes.textfieldContainer}>
                    
                    <p className={classes.countryCodeLabel}>Country Code</p>
                    <Select
                      labelId="country_code_label"
                      id="country_code"
                      value={this.props.country_code}
                      label="Country Code"
                      onChange={async (event) => {
                        await this.props.handleDataCountryCode(event.target.value)
                        this.checkInvalid()
                      }}
                      inputProps={{
                        name: 'country_code',
                        id: 'country_code',
                      }}
                      style={{
                        width: "100%"
                      }}
                    >
                      {this.returnCountryCode()}
                    </Select>
                    
                  </Grid>
                  <Grid item xs={9} sm={9} className={classes.textfieldContainer}>
                    <TextField
                      id="phone"
                      label='Contact Number'
                      className={classes.textField}
                      value={this.props.contact_number}
                      onChange={async ({ target: { value } }) => {
                        await this.props.handleChangePhone('contact_number', value)
                        this.checkInvalid()
                      }}
                      margin="normal"
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} className={classes.textfieldContainer}>
                  <TextField
                    id="email"
                    label='Email'
                    className={classes.textField}
                    value={this.props.email}
                    onChange={async (event) => {
                      await this.props.handleDataEmail(event.target.value)
                      this.checkInvalid()
                    }}
                    margin="normal"
                  />
                  <p className={style.error}>{this.props.error && this.props.error["email"]}</p>
                </Grid>
                
                {this.props.case_type.toLowerCase() !== "retainer" &&
                  <Grid item xs={12} sm={12} className={classes.textfieldContainer}>
                    <p style={{ marginBlockEnd: 0 }}>Aligner Package Type</p>
                    <RadioGroup
                      onChange={(e) => {
                        !this.props.edit_info && this.props.handleChange("attr0", e.target.value)
                      }}
                      name="attr0" value={this.props.attr0}
                    >
                      <Grid container>
                        <Grid item xs={12} lg={6}>
                          <FormControlLabel key="1" value="type_1" control={<Radio />} disabled={this.props.edit_info} label="Evolve Max 8 Steps + 8 Steps" />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <FormControlLabel key="2" value="type_2" control={<Radio />} disabled={this.props.edit_info} label="Progress Max 40 Steps + 10 Steps" />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  </Grid>
                }
                {['ADMIN', 'SADMIN', 'DISTR'].includes(role) &&
                  <Grid item xs={12} className={classes.textfieldContainer}>
                    <p style={{ marginBlockEnd: 0 }}>{this.get(':fields.refCode')}</p>
                    <TextField
                      id="refCode"
                      className={classes.textField}
                      value={this.props.refCode == null ? "" : this.props.refCode}
                      onChange={(event) => { this.props.handleDataRefCode(event.target.value)}}
                      margin="normal"
                    />
                    <p className={style.error}>{this.props.error && this.props.error["refCode"]}</p>
                  </Grid>}

                {/* TODO: internal remark box */}
                {['ADMIN', 'SADMIN', 'DISTR'].includes(role) &&
                  <Grid item xs={12} className={classes.textfieldContainer}>
                    <p style={{ marginBlockEnd: 0 }}>Internal Remarks</p>
                    <TextField
                      id="refCode"
                      className={classes.textField}
                      value={this.props.internalRemarks == null ? "" : this.props.internalRemarks}
                      onChange={(event) => { this.props.handleDataInternalRemark(event.target.value)}}
                      margin="normal"
                    />
                    <p className={style.error}>{this.props.error && this.props.error["internalRemarks"]}</p>
                  </Grid>}
                <Grid item xs={12} className={classes.textfieldContainer}>
                  <p style={{ marginBlockEnd: 0 }}>{this.get(':fields.remarks')}</p>
                  <TextField
                    id="remarks"
                    // label="Remarks"
                    className={classes.textField}
                    value={this.props.remarks == null ? "" : this.props.remarks}
                    onChange={(event) => this.props.handleDataRemark(event.target.value)}
                    margin="normal"
                    variant="outlined"
                    multiline
                    rows={4}
                    placeholder={this.get(":fields.remarks")}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = store => {
  return {
    ...store.newPatientReducer,
    gender: store.newPatientReducer.gender,
    lastName: store.newPatientReducer.lastName,
    firstName: store.newPatientReducer.firstName,
    day: store.newPatientReducer.day,
    month: store.newPatientReducer.month,
    year: store.newPatientReducer.year,
    refCode: store.newPatientReducer.refCode,
    remarks: store.newPatientReducer.remarks,
    internalRemarks: store.newPatientReducer.internalRemarks,
    patientPhoto: store.newPatientReducer.patientPhoto,
    lang: store.lang
  }
};

const mapDispatchToProps = dispatch => {
  return {
    handleDataGender: (value) => dispatch({ type: actionTypes.NEW_PATIENT_INFO_GENDER, value: value }),
    handleDataLastName: (value) => dispatch({ type: actionTypes.NEW_PATIENT_INFO_LASTNAME, value: value }),
    handleDataFirstName: (value) => dispatch({ type: actionTypes.NEW_PATIENT_INFO_FIRSTNAME, value: value }),
    handleDataDay: (value) => dispatch({ type: actionTypes.NEW_PATIENT_INFO_DAY, value: value }),
    handleDataMonth: (value) => dispatch({ type: actionTypes.NEW_PATIENT_INFO_MONTH, value: value }),
    handleDataYear: (value) => dispatch({ type: actionTypes.NEW_PATIENT_INFO_YEAR, value: value }),
    handleDataCountryCode: (value) => dispatch({ type: actionTypes.NEW_PATIENT_INFO_COUNTRY_CODE, value: value }),
    handleDataEmail: (value) => dispatch({ type: actionTypes.NEW_PATIENT_INFO_EMAIL, value: value }),
    handleDataRefCode: (value) => dispatch({ type: actionTypes.NEW_PATIENT_INFO_REFERENCE_CODE, value: value }),
    handleDataRemark: (value) => dispatch({ type: actionTypes.NEW_PATIENT_INFO_REMARK, value: value }),
    handleDataInternalRemark: (value) => dispatch({ type: actionTypes.NEW_PATIENT_INTERNAL_REMARK, value: value }),
    handleDataPhoto: (value) => dispatch({ type: actionTypes.NEW_PATIENT_INFO_PHOTO, value: value }),
    handleDataPhotoRotate: (value) => dispatch({ type: actionTypes.NEW_PATIENT_INFO_PHOTO_ROTATE, value: value }),
    handleChange: (attrName, value) => dispatch({ type: actionTypes.NEW_PATIENT_QUES_SIMPLE_UPDATE, payload: { attrName, value } }),
    handleChangePhone: (attrName, value) => dispatch({ type: actionTypes.NEW_PATIENT_QUES_PHONE_UPDATE, payload: { attrName, value } }),
    ErrorData: (errorMsg) => dispatch({ type: 'NEW_PATIENT_DATA_ERROR', errorMsg: errorMsg })
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(withLang(NewPatientInfo, 'NewPatient'))))
