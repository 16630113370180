import React, { Component, PropTypes } from 'react';
import { Grid, TextField, Button, Select, MenuItem, FormLabel } from '@material-ui/core'
import style from './NewAddress.module.css'
import { connect } from 'react-redux';
import * as actionTypes from '../../../store/NewPatient/actionType';
import * as actionNewAddress from '../../../store/NewAddress/actionType';
import { withLang } from '../../../util/lang';

class NewAddress extends Component {


  returnCountry() {
    return (
      this.props.countriesName.map(v =>
        <MenuItem value={v.country_id} key={v.country_id}>{v.country_name}</MenuItem>
      )
    )
  }

  returnState() {
    return (
      this.props.statesName.map(v => v.country_id == this.props.selectedCountry &&
        <MenuItem value={v.state_province_id} key={v.state_province_id}>{v.name}</MenuItem>
      )
    )
  }

  render() {
    return (
      <div className={style.newAddress}>
        <Grid item xs={12} style={{ padding: 4 }}>
          <TextField
            id="firstName"
            label={this.get(':fields.firstName')}
            required
            margin="normal"
            style={{ width: "100%" }}
            value={this.props.firstName}
            onChange={(event) => this.props.handleDataFirstName(event.target.value)}
          />
        </Grid>
        <Grid item xs={12} style={{ padding: 4 }}>
          <TextField
            id="lastName"
            label={this.get(':fields.lastName')}
            required
            style={{ width: "100%" }}
            margin="normal"
            value={this.props.lastName}
            onChange={(event) => this.props.handleDataLastName(event.target.value)}
          />
        </Grid>


        <Grid item xs={12} style={{ padding: 4 }}>
          <FormLabel style={{fontSize:'0.8rem'}}>{this.get(':fields.country')}</FormLabel>
          <Select
            required
            onChange={(event) => this.props.handleDataSelectedCountry(event.target.value)}
            style={{ width: "100%" }}
            value={this.props.selectedCountry}
          >
            {this.returnCountry()}
          </Select>
        </Grid>

        {this.props.statesName.some(s => s.country_id === this.props.selectedCountry) ?
          <Grid item xs={12} style={{ padding: 4 }}>
            <FormLabel style={{fontSize:'0.8rem'}}>{this.get(':fields.stateProvince')}</FormLabel>
            <Select
              onChange={(event) => this.props.handleDataSelectedState(event.target.value)}
              style={{ width: "100%" }}
              value={this.props.selectedState}
            >
              {this.returnState()}
            </Select>
          </Grid> :
          null}

        <Grid item xs={12} style={{ padding: 4 }}>
          <TextField
            id="city"
            label={this.get(':fields.city')}
            required
            margin="normal"
            style={{ width: "100%" }}
            value={this.props.city}
            onChange={(event) => this.props.handleDataCity(event.target.value)}
          />
        </Grid>

        <Grid item xs={12} style={{ padding: 4 }}>
          <TextField
            id="zipCode"
            label={this.get(':fields.postCode')}
            required
            margin="normal"
            style={{ width: "100%" }}
            onChange={(event) => this.props.handleDataZipCode(event.target.value)}
          />
        </Grid>


        <Grid item xs={12} style={{ padding: 4 }}>
          <TextField
            id="phoneNumber"
            label={this.get(':fields.phoneNo')}
            required
            margin="normal"
            style={{ width: "100%" }}
            onChange={(event) => this.props.handleDataPhoneNumber(event.target.value)}
          />
        </Grid>


        <Grid item xs={12} style={{ padding: 4 }}>
          <TextField
            id="address1"
            label={this.get(':fields.address')}
            required
            margin="normal"
            style={{ width: "100%" }}
            onBlur={(event) => this.props.handleDataAddress(event.target.value)}
          />
        </Grid>

        <Grid item xs={12} style={{ padding: 4 }}>
          <TextField
            id="zipCode"
            label={this.get(':fields.faxNo')}
            margin="normal"
            style={{ width: "100%" }}
            onChange={(event) => this.props.handleDataFaxNumber(event.target.value)}
          />
        </Grid>
      </div>

    );
  }
}

const mapStateToProps = store => {
  return {
    firstName: store.newAddressReducer.firstName,
    lastName: store.newAddressReducer.lastName,
    countriesName: store.newAddressReducer.countriesName,
    statesName: store.newAddressReducer.statesName,
    selectedCountry: store.newAddressReducer.selectedCountry,
    selectedState: store.newAddressReducer.selectedState,
    city: store.newAddressReducer.city,
    address: store.newAddressReducer.address,
    zipCode: store.newAddressReducer.zipCode,
    phoneNumber: store.newAddressReducer.phoneNumber,
    faxNumber: store.newAddressReducer.faxNumber,
    lang: store.lang
  }
};

const mapDispatchToProps = dispatch => {
  return {
    handleDataLastName: (value) => dispatch({ type: actionNewAddress.NEW_PATIENT_DELIVERY_LAST_NAME, value }),
    handleDataFirstName: (value) => dispatch({ type: actionNewAddress.NEW_PATIENT_DELIVERY_FIRST_NAME, value }),

    handleDataSelectedCountry: (value) => dispatch({ type: actionNewAddress.NEW_PATIENT_DELIVERY_ADDRESS_COUNTRY, value: value }),
    handleDataSelectedState: (value) => dispatch({ type: actionNewAddress.NEW_PATIENT_DELIVERY_ADDRESS_STATE, value: value }),
    handleDataCity: (value) => dispatch({ type: actionNewAddress.NEW_PATIENT_DELIVERY_ADDRESS_CITY, value: value }),

    handleDataAddress: (value) => dispatch({ type: actionNewAddress.NEW_PATIENT_DELIVERY_ADDRESS_LINE, value: value }),
    handleDataZipCode: (value) => dispatch({ type: actionNewAddress.NEW_PATIENT_DELIVERY_ADDRESS_ZIPCODE, value: value }),
    handleDataPhoneNumber: (value) => dispatch({ type: actionNewAddress.NEW_PATIENT_DELIVERY_ADDRESS_PHONE, value: value }),
    handleDataFaxNumber: (value) => dispatch({ type: actionNewAddress.NEW_PATIENT_DELIVERY_ADDRESS_FAX, value: value }),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withLang(NewAddress, 'NewPatient'));
