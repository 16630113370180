import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types';
import { makeStyles, Toolbar, Typography, TextField, Grid, IconButton, InputAdornment } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'


const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  title: {
    flex: '1 1 100%',
  },
}))

const CountryToolbar = (props) => {
  const classes = useToolbarStyles()
  const { onRequestFilter } = props
  const [searchStr, setSearchStr] = useState('')
  const debounceTimer = useRef(null),
        lastSearchStr = useRef('')

  useEffect(() => {
    if (debounceTimer.current) clearTimeout(debounceTimer.current)
    setTimeout(() => {
      if (onRequestFilter && searchStr.trim() !== lastSearchStr.current) {
        lastSearchStr.current = searchStr.trim()
        onRequestFilter(searchStr.trim())
      }
    }, 500)
  }, [searchStr])

  return (
    <Toolbar
      className={classes.root}
    >
      <Grid container justify="space-between">
        <Grid item>
          <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
            {props.title}
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            value={searchStr}
            onChange={({ target: { value } }) => setSearchStr(value)}
            InputProps={{
              startAdornment: <InputAdornment><SearchIcon /></InputAdornment>,
              endAdornment: <InputAdornment><IconButton size="small" onClick={() => setSearchStr('')}><ClearIcon/></IconButton></InputAdornment>
            }}
          />
        </Grid>
      </Grid>
    </Toolbar>
  )
}

CountryToolbar.propTypes = {
  title: PropTypes.string.isRequired
}

export default CountryToolbar