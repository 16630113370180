import React, { Component, PropTypes } from 'react';
import { Grid, Button, Select, MenuItem, TextField, Checkbox } from '@material-ui/core'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withLang } from '../../util/lang';
import { connect } from 'react-redux';

class Aligner extends Component {

  constructor(props) {
    super(props);

    this.state = {
      open: false
    }
    this.handleClickOpen = this.handleClickOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  handleClickOpen() {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  returnSteps() {
    const countArray = [];
    for (let i = 1; i <= 36; i++) {
      countArray.push(<MenuItem value={i} key={i}>{i}</MenuItem>);
    }
    return countArray
  }
  handlethickness(index, thickness_value, step_no) {
    let counter_pair = 0
    let modified_aligner = this.props.aligner.filter(v => {
      return v.step_no === step_no
    })
    if (modified_aligner.length > 0) {
      modified_aligner.map(v => {
        if (v.thickness === thickness_value) {
          counter_pair += 1
        }
      })
    }

    if (counter_pair > 0) {
      this.setState({ open: true })
      this.props.handleChnageThicknessAligner(index, "")
      this.props.handleChnageSizeAligner("", index)
    }
    else {
      this.props.handleChnageThicknessAligner(index, thickness_value)
    }
  }

  render() {
    return (
      <>

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{this.get('aligner.duplicatePair')}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.get('aligner.alreadyExists')}
		          </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>
              {this.get(':const.close')}
		          </Button>
          </DialogActions>
        </Dialog>

        {/* <Grid item xs={12} md={12} style={{ margin: "8px 0px" }}>
          <span>{this.get(':const.type')}: </span>
          <Select
            style={{ width: "20%", margin: "2%" }}
            displayEmpty
            value={this.props.alignertype}
            onChange={(e) => { this.props.handleChnageAlignerType(e.target.value) }}
          >
            <MenuItem value="LOST" key="S">{this.get(':const.lost')}</MenuItem>
            <MenuItem value="REPLACE" key="M">{this.get(':const.replace')}</MenuItem>
          </Select>
        </Grid> */}
        {
          this.props.aligner.map((value, index) =>
            <Grid container>

              <Grid item xs={12} md={4} style={{ margin: "8px 0px" }}>
                {/* <p style={{height:"0px",marginTop:"0",marginRight:"8px", display: "inline"}}>Replacement {index+1}</p> */}
                <span style={{ fontWeight: 600 }}>{this.get('aligner.replacement')} {index + 1}</span>
              </Grid>

              <Grid item xs={12} md={4} style={{ margin: "8px 0px" }}>
                <span>{this.get('aligner.stepNumber')}: </span>
                <Select
                  style={{ width: "50%" }}
                  displayEmpty
                  value={value.step_no}
                  onChange={(e) => { this.props.handleChnageSizeAligner(e.target.value, index) }}
                >
                  {
                    this.returnSteps()
                  }
                </Select>
              </Grid>

              <Grid item xs={12} md={4} style={{ margin: "8px 0px" }}>
                <span>{this.get('aligner.thickness')}: </span>
                <Select
                  style={{ width: "50%" }}
                  value={value.thickness}
                  displayEmpty
                  onChange={(e) => this.handlethickness(index, e.target.value, value.step_no)}
                >
                  <MenuItem value="S" key="S">S</MenuItem>
                  <MenuItem value="M" key="M">M</MenuItem>
                  <MenuItem value="H" key="H">H</MenuItem>
                </Select>
              </Grid>

              <Grid item xs={12}>
                {value.remove && <Button variant="outlined" style={{ background: "#da5c5c", color: "white", border: "none" }} onClick={(e) => this.props.removeAligner(index, e)}>{this.get('aligner.deleteThisRow')}</Button>}
              </Grid>

              <Grid item xs={12} style={{ margin: "8px 0px" }}>
                <hr />
              </Grid>


            </Grid>
          )}
        <Grid item xs={12} md={12}>
          {/*<FormControlLabel
				        control={
				          <Checkbox
				            checked={this.props.checked}
				            onChange={this.props.handleChangeCheckBox}
				            name="checkedB"
				          />
				        }
				        label="Agree With Replacement Price"
				      />*/}
          <p>{this.get('msg.termsAndCondChecked')} <a href="/files/tc.pdf" target="_blank">{this.get('msg.termsAndCondCheckedLink')}</a></p>
        </Grid>
        <Button variant="outlined" onClick={() => this.props.addAligner()}>{this.get('aligner.addOneMore')}</Button>
      </>
    )
  }
}

const mapStateToProps = store => ({
  lang: store.lang
})

export default connect(mapStateToProps)(withLang(Aligner, 'PatientInfo'))
