import './object'
import { useCallback } from "react"
import { useSelector } from "react-redux"
import { initialState } from '../store/lang/lang.reducer'

export function withLang(context, name) {
  Object.assign(context.prototype, {
    get(key) {
      return _Text.bind(this)(name, key)
    }
  })
  return context
}

export function useLang(name) {
  const lang = useSelector(store => store.lang)
  const getLang = useCallback(
    (key) => {
      if (key.startsWith(':')) {
        return getFromObj(lang.dict.global, key.slice(1)) || getFromObj(initialState.dict.global, key.slice(1)) || 'N/A'
      }
      if (key.startsWith('$')) {
        return lang.dict[key.slice(1)]
      }
      return getFromObj(lang.dict[name], key) || getFromObj(initialState.dict[name], key) || 'N/A'
    },
    [lang]
  )
  return getLang
}

function _Text(name, key) {
  if (this.props && this.props.lang && this.props.lang.dict) {
    if (key.startsWith(':')) {
      return getFromObj(this.props.lang.dict.global, key.slice(1)) || getFromObj(initialState.dict.global, key.slice(1)) || 'N/A'
    }
    return getFromObj(this.props.lang.dict[name], key) || getFromObj(initialState.dict[name], key) || 'N/A'
  }
  return 'N/A'
}

export function getFromObj(obj, key) {
  return Object.getFromObj(obj, key)
}

export function objToDict(obj) {
  return Object.objToDict(obj)
}