export const retainer_colors = [
    { value: "CamoBlue", label: "Camo Blue", extension: "png", enabled: true },
    { value: "CamoGreen", label: "Camo Green", extension: "jpg", enabled: false },
    { value: "CamoPink", label: "Camo Pink", extension: "png", enabled: true },
    { value: "Hearts", label: "Hearts", extension: "png", enabled: true },
    { value: "InkBlots", label: "Ink Blots", extension: "png", enabled: true },
    { value: "InkBlots2", label: "Ink Blots 2", extension: "png", enabled: true },
    { value: "Pawprints", label: "Pawprints", extension: "png", enabled: true },
    { value: "PeacePinkAndPurple", label: "Peace Pink & Purple", extension: "png", enabled: true },
    { value: "Rainbow", label: "Rainbow", extension: "png", enabled: true },
    { value: "SkeletonFish", label: "Skeleton Fish", extension: "jpg", enabled: false },
    { value: "Stars", label: "Stars", extension: "png", enabled: true }
]

export default { retainer_colors }