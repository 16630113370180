import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useLocation, useHistory } from 'react-router-dom';
import { sendMessage2ServiceWorker } from './util/service_worker'
import './App.css';
import Footer from './components/Footer_beme';
// components
import Nav from './components/Nav';
import Notifier from './components/Notifier';
import Account from './pages/Account';
import Accounts from './pages/Admin/Accounts';
import AccountsInfo from './pages/Admin/AccountsInfo';
import Cases from './pages/Admin/Cases';
import Clinics from './pages/Admin/Clinics';
import Countries from './pages/Admin/Countries';
import SubDomains from './pages/Admin/SubDomains';
//admin pages
import Dashboard from './pages/Admin/Dashboard';
import Groups from './pages/Admin/Groups';
import DistrGroups from './pages/Admin/DistrGroups';
import AdminGroups from './pages/Admin/AdminGroups';
import Reports from './pages/Admin/Reports';
import Settings from './pages/Admin/Settings';
import ChangePassword from './pages/changePassword';
import ResetPassword from './pages/resetForgotPswd';
// import Simulation from './components/3dSimulation/Simulation'
import ClinicalSimulation from './pages/ClinicalSimulation';
import Viewer3D from './components/ClinicalSimulation/viewer/3DViewer'
import EmailLog from './pages/EmailLog';
import Forgot from './pages/Forgot';
import Login from './pages/Login';
import EmailVerified from './pages/EmailVerified';
import ForgotPassword from './pages/ForgotPassword';
import NewPatient from './pages/NewPatient';
import PatientInfo from './pages/PatientInfo';
// pages
import Patients from './pages/Patients';
import NewPatientPrintData from './pages/Print/NewPatientPrintData';
import Refinement from './pages/Refinement';
import Register from './pages/Register';
import Support from './pages/Support';
import TreatmentApproval from './pages/TreatmentApproval';
import { checkLoggedIn } from './store/Auth/Auth.action';
import * as configAction from './store/config/config.action';
import { usePrevious } from './util/react';
import Maintenance from './pages/maintenance';
import { loadRes } from './store/res/res.action';
import EmailManagement from './pages/Admin/EmailManagement';
import ProductMatrix from './pages/Admin/ProductMatrix';
import SupportResource from './pages/Admin/SupportResource';
// import DistrMsg from './pages/Admin/DistrMsg';
import CasesLog from './pages/Admin/CasesLog';
import CasesRollbackLog from './pages/Admin/CasesRollbackLog';
import CasesTransferLog from './pages/Admin/CasesTransferLog';
import * as axios from './axios-default';
const MaintenanceMode = false

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#fff',
    },
    secondary: {
      main: '#445994',
    }
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    // fontFamily: 'Museo',
    body1: {
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: 12,
    },
    button: {
      fontSize: '1.0rem',
      textTransform: 'none'
    }
  }
})

export default function App(props) {
  const dispatch = useDispatch(),
        history = useHistory(),
        location = useLocation(),
        { isAuthenticated, res, lang, config } = useSelector(store => ({
          isAuthenticated: store.auth.isAuthenticated,
          config: store.config,
          res: store.res,
          lang: store.lang
        }))

  const [loading, setLoading] = useState(true)
  const nav = useRef(null),
        footer = useRef(null),
        resLoadRetryCount = useRef(0),
        loadingRes = useRef(false)
  const prevIsAuth = usePrevious(isAuthenticated),
        prevRes = usePrevious(res)

  useEffect(() => {
    (async () => {
      try {
        updateNavAndFooter()
        await dispatch(configAction.check({ res, config, lang }))
        await dispatch(checkLoggedIn())
      } catch (err) {
        console.error(err)
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  useEffect(() => {
    if (prevIsAuth === false &&  prevIsAuth !== isAuthenticated) {
      dispatch(configAction.loadConfig())
    }
  }, [isAuthenticated])

  // useEffect(() => {
  //   dispatch(checkLoggedIn())
  // }, [location])

  useEffect(() => {
    if (!loadingRes.current && !res.isInit) {
      loadingRes.current = true
      ;(async () => {
        if (
          (
            !res.countries
            || !Object.keys(res.countries).length
            || !res.stateProvinces
            || !Object.keys(res.stateProvinces).length
          ) &&
          ++resLoadRetryCount.current < 100
        ) {
          // await dispatch(loadRes())
          await loadRes()(dispatch)
          resLoadRetryCount.current = 0
        }
        loadingRes.current = false
      })()
    }
  }, [res, dispatch, resLoadRetryCount, loadingRes])

  const routes = useMemo(() => {
    if (MaintenanceMode) {
      return (
        <Route component={Maintenance} />
      )
    } else {
      return (
        <Switch>
          {(isAuthenticated === null || isAuthenticated) && !loading?
            <>
              <Route exact path='/' component={Patients} />
              <Route exact path='/patients/newPatient/:type' component={NewPatient} />
              <Route exact path='/patients/:id' component={PatientInfo} />
              <Route exact path='/patients/:id/treatment' component={TreatmentApproval} />
              <Route exact path='/patients/:id/refinement' component={Refinement} />

              <Route exact path='/verifyemail/:caseId/:emailAddr/:caseId_iv/:emailAddr_iv' component={EmailVerified} />
              <Route exact path='/forgotpassword/:case_id/:email_addr/:case_id_iv/:email_addr_iv' component={ResetPassword} />

              <Route exact path='/changePassword/:oldPassword?' component={ChangePassword} />
              <Route exact path='/patients/:id/edit' component={NewPatient} />
              <Route path='/account/:tabIndex?' component={Account} />
              <Route path='/support' component={Support} />
              {/* <Route path='/sim/test' component={Simulation} /> */}
              <Route exact path='/clinicalSimulation/:id/plan/:plan_id?' component={ClinicalSimulation} />
              <Route exact path='/clinicalSimulation/:id' component={ClinicalSimulation} />
              <Route exact path='/clinicalSimulation2/:id' component={Viewer3D} />
              {/* <Route exact path='/clinicalSimulation/:id/2/:plan_id?' component={Viewer3D} /> */}
              <Route exact path='/printData/:id' component={NewPatientPrintData} />
              <Route path='/admin/dashboard' component={Dashboard} />
              <Route exact path='/admin/accounts' component={Accounts} />
              <Route path='/admin/accounts/:id/:tabIndex?' component={AccountsInfo} />
              <Route path='/admin/orders' component={Dashboard} />
              <Route path='/admin/cases' component={Cases} />
              <Route path='/admin/distrgroups' component={DistrGroups} />
              <Route path='/admin/admingroups' component={AdminGroups} />
              <Route path='/admin/clinics' component={Clinics} />
              <Route path='/admin/groups' component={Groups} />
              <Route path='/admin/countries' component={Countries} />
              <Route path='/admin/subdomains' component={SubDomains} />
              <Route path='/admin/reports' component={Reports} />
              <Route path='/admin/email-management' component={EmailManagement} />
              <Route path='/admin/productmatrix' component={ProductMatrix} />
              <Route path='/admin/settings' component={Settings} />
              <Route path='/admin/supportresource' component={SupportResource} />
              <Route path='/admin/casesLog' component={CasesLog} />
              <Route path='/admin/casesRollbackLog' component={CasesRollbackLog} />
              <Route path='/admin/CasesTransferLog' component={CasesTransferLog} />
              {/* <Route path='/admin/DistrMsg' component={DistrMsg} /> */}
              <Route path='/email/log/:logId' component={EmailLog} />
            </> : null}
          <Route exact path='/verifyemail/:caseId/:emailAddr/:caseId_iv/:emailAddr_iv' component={EmailVerified} />
          <Route exact path='/forgotpassword/:case_id/:email_addr/:case_id_iv/:email_addr_iv' component={ResetPassword} />

          <Route exact path='/changePassword' component={ChangePassword} />
          <Route exact path='/changePassword/:email/:oldPassword?' component={ChangePassword} />
          <Route exact path='/register' component={Register} />
          <Route exact path='/forgot' component={Forgot} />
          <Route exact path='/login' component={() => <Login isLoading={loading}/>} />
          {isAuthenticated === false?
            <Route>
              <Redirect to='/login' />
            </Route> : null}
        </Switch>
      )
    }
  }, [isAuthenticated, loading])

  useEffect(() => {
    sendMessage2ServiceWorker({
      type: isAuthenticated? 'login' : 'logout',
      user_id: parseInt(JSON.parse(localStorage.getItem('auth-token')))
    })
  }, [isAuthenticated, loading])

  useEffect(() => {
    if (isAuthenticated !== null) updateNavAndFooter()
    if (isAuthenticated && history.location.pathname.startsWith('/login')) {
      history.replace('/')
    }
    dispatch(configAction.check({ res, lang, config }))
  }, [isAuthenticated, history])

  const updateNavAndFooter = () => {
    const navExclusion = [
      '/admin', '/printData', '/clinicalSimulation2'
    ]
    const footerExclustion = [
      '/admin', '/clinicalSimulation2'
    ]
    if (
      navExclusion.some(ex => history.location.pathname.startsWith(ex))
      || !isAuthenticated
    ) {
      nav.current = null
    } else {
      nav.current = (
        isAuthenticated !== null && <Nav />
      )
    }
    if (footerExclustion.some(ex => history.location.pathname.startsWith(ex))) {
      footer.current = null
    } else {
      footer.current = (
        isAuthenticated !== null && <Footer />
      )
    }
  }

  return (
    <div>
      <Notifier/>
        <MuiThemeProvider theme={theme}>
          {nav.current}
          {routes}
          {footer.current}
        </MuiThemeProvider>
    </div>
  )
}
