import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import NewPatientPhoto from './NewPatientPhoto'
import ImpressionTabs from './ImpressionTabs'
import Radiograph from './Radiograph'
import Photos from './Photos'
import Grid from '@material-ui/core/Grid';
import style from './NewPatientPhotos.module.css'
import { connect } from 'react-redux';
import * as actionTypes from '../../../store/NewPatient/actionType';
import NewPatientHandleUpload from '../../../util/NewPatientHandleUpload'
import { withLang } from '../../../util/lang'

class NewPatientPhotos extends Component {

  constructor(props) {
    super(props)
  }

  invert(image_id, invert) {
    this.props.handleInvertImage(image_id, invert)
  }

  handleRoatate(image_id, degree) {
    this.props.handleRoatateImage(image_id, degree)
  }

  checkIfImageExists(image_id) {
    let image_data = this.props.files.filter(imageObj => { return imageObj.id == image_id })
    return image_data[0].uploaded;
  }

  getImage(image_id) {
    let image_data = this.props.files.filter(imageObj => { return imageObj.id == image_id })
    return image_data[0];
  }

  handleUpload(image_id, file) {
    NewPatientHandleUpload(image_id, file, this.props.handleDataFileUpload)
    this.checkInvalid()
    this.forceUpdate();
  }

  checkInvalid() {
    if (this.props.tabMode === 'photos' && this.props.match.params.type !== 'retainer') {
      let counter = 0
      this.props.NewPaitientData.files.forEach((v, index) => {
        if (index < 6 && !v.data && !v.upload_later && !v.will_not_upload) {
          counter += 1;
        }
      })
      if (counter) {
        this.props.ErrorData({ id: "photo", msg: this.get('warning.mandatoryPhoto'), valid: false })
      }
      else {
        this.props.ErrorData({ id: "photo", msg: '', valid: true })
      }
    }
    else if (this.props.tabMode === 'radiography') {
      let counter = 0
      this.props.NewPaitientData.files.forEach((v, index) => {
        if (v.category === 'radio' && v.id === 'front_with_smile' && !v.data && !v.upload_later && !v.will_not_upload) {
          counter += 1;
        }
      })
      if (counter) {
        this.props.ErrorData({ id: "radiograph", msg: this.get('warning.mandatoryRadiograph'), valid: false })
      }
      else {
        this.props.ErrorData({ id: "radiograph", msg: '', valid: true })
      }
    }
    else if (this.props.tabMode === 'impression') {
      switch (this.props.NewPaitientData.impressionType) {
        case 'FILE': {
          if (this.props.NewPaitientData.files.some(v =>
            v.category === 'stl_file' && !v.data && !v.upload_later && !v.will_not_upload
          )) {
            this.props.ErrorData({ id: "impression", msg: this.get('warning.allStl'), valid: false })
          } else {
            this.props.ErrorData({ id: "impression", msg: '', valid: true })
          }
          break
        }
        case 'COURIER': {
          if (String.isBlank(this.props.NewPaitientData.modernCode)) {
            this.props.ErrorData({ id: "impression", msg: this.get('warning.courier'), valid: false })
          } else {
            this.props.ErrorData({ id: "impression", msg: '', valid: true })
          }
          break
        }
        case 'SCANNER': {
          if (String.isBlank(this.props.NewPaitientData.scannerBrand) || String.isBlank(this.props.NewPaitientData.doctorId)) {
            this.props.ErrorData({ id: "impression", msg: this.get('warning.scanner'), valid: false })
          } else {
            this.props.ErrorData({ id: "impression", msg: '', valid: true })
          }
          break
        }
      }
    }
  }

  render() {

    return (
      <ul className={style.patientImages}>
        {this.props.tabMode == "photos" && <>
          <Photos
            mode={this.props.mode}
            imageUploaded={(value) => this.checkIfImageExists(value)}
            imageData={(value) => this.getImage(value)}
            parent={this.props.parent ? this.props.parent : 'main'}
            uploadLater={this.props.uploadLater == 'patientInfo' ? false : true}
            handleChange={(value, file) => { this.handleUpload(value, file) }}
            invert={(value, invert) => { this.invert(value, invert) }}
            handleRoatate={(value, degree) => { this.handleRoatate(value, degree) }}
            checkInvalid={this.checkInvalid.bind(this)}
          /> </>}

        {this.props.tabMode == "radiography" && <>
          <h3 className={style.heading} >{this.get('radiographs.uploadRadiograph')}</h3>
          <Radiograph
            mode={this.props.mode}
            parent={this.props.parent? this.props.parent : 'main'}
            imageUploaded={(value) => this.checkIfImageExists(value)}
            imageData={(value) => this.getImage(value)}
            case_type={this.props.case_type}
            uploadLater={this.props.uploadLater == 'patientInfo' ? false : true}
            handleChange={(value, file) => { this.handleUpload(value, file) }}
            handleRoatate={(value, degree) => { this.handleRoatate(value, degree) }}
            checkInvalid={this.checkInvalid.bind(this)}
          /></>}

        {this.props.tabMode == "impression" && <>
          <h3 className={style.heading}>{this.get('impressions.uploadImpression')}</h3>
          <div className={style.tabWrap}>
            <ImpressionTabs
              mode={this.props.mode}
              imageUploaded={(value) => this.checkIfImageExists(value)}
              uploadLater={this.props.uploadLater == 'patientInfo' ? 'patientInfo' : 'other'}
              imageData={(value) => this.getImage(value)}
              handleChange={(value, file) => { this.handleUpload(value, file) }}
              checkInvalid={this.checkInvalid.bind(this)}
            />
          </div> </>
        }

      </ul>
    )
  }
}

const mapStateToProps = store => {
  return {
    files: store.newPatientReducer.files,
    NewPaitientData: store.newPatientReducer,
    lang: store.lang
  }
};

const mapDispatchToProps = dispatch => {
  return {
    handleDataFileUpload: (id, value) => dispatch({ type: actionTypes.NEW_PATIENT_FILES, id: id, value: value }),
    handleInvertImage: (id, value) => dispatch({ type: actionTypes.NEW_PATIENT_FILE_INVERT, id: id, value: value }),
    handleRoatateImage: (id, value) => dispatch({ type: actionTypes.NEW_PATIENT_FILE_ROTATE, id: id, value: value }),
    ErrorData: (errorMsg) => dispatch({ type: 'NEW_PATIENT_DATA_ERROR', errorMsg: errorMsg })
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withLang(NewPatientPhotos, 'NewPatient')))