import '../util/string'
import { Button, Grid, MenuItem, Select } from '@material-ui/core';
import { CircularProgress, Hidden, Typography } from '@material-ui/core/';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import SendIcon from '@material-ui/icons/Send';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import axios from '../axios-default';
import Loader from "../components/Loader";
// import Colors from '../components/Colors'
import TopBar from '../components/TopBar';
import * as authAction from '../store/Auth/Auth.action';
import { easySnackbar } from '../store/Noti/noti.actions';
import { withLang } from '../util/lang';
import frontend_config from '../frontend_config';
import { getWebAbsPath } from '../util/path';

const hostname = window.location.hostname


const styles = theme => ({
  "@global": {
    body: {
      background: "#fdfdfd",
    },
  },
  root: {
    flexGrow: 1,
  },
  main: {
    width: 'auto',
    display: 'block',
    marginLeft: "0px 24px",
    [theme.breakpoints.up(500 + 48)]: {
      width: 500,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    [theme.breakpoints.up(600)]: {
      marginTop: "15vh",
      marginBottom: "30px"
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  form: {
    width: '100%',
    marginTop: 8,
    textAlign: "left",
  },
  submit: {
    marginTop: "24px",
  },
  textField: {
    // marginLeft: "8px",
    // marginRight: "8px",
    '& label.Mui-focused': {
      color: 'black',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'black',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey',
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
  },
  dense: {
    marginTop: "16px",
    marginLeft: "0px",
  },
  welcomeTitle: {
    margin: "16px 0px"
  },
  progress: {
    marginTop: "48px"
  },
  required: {
    '&:after': {
      content: `'*'`,
      color: "red",
      marginLeft: "3px"
    }
  },
  errors: {
    listStyleType: "none",
    color: "red",
    padding: "2px",
  },
  errorlist: {
    margin: "8px"
  },
  login: {
    backgroundColor: frontend_config?.[hostname]?.main_color_1,
    marginTop: "32px",
    '&:hover': {
      backgroundColor: frontend_config?.[hostname]?.main_color_2
    }
  },
  loginLeft: {
    backgroundPosition: "top center",
    backgroundSize: "cover"
  }
});

const backgroundImage = `url("${getWebAbsPath("/img/Beme3892.jpg")}")`

const initialState = {
  gender: "M",
  first_name: "",
  last_name: "",
  title: "",
  email: "",
  password: "",
  confirmPassword: "",
  phone_no: "",
  fax_no: "",
  license_no: "",
  clinic_phone_no: "",
  clinic_website: "",
  sc_customer: "",
  country_id: null,
  state_province_id: undefined,
  company_name: "",
  countryList: [],
  errors: {},
  submitting: false
};

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState
  }

  submitHandler() {
    if (!this.state.submitting) {
      this.setState({ submitting: true }, async () => {
        try {
          let errors = {}
          this.setState({ errors: errors })
          if (!this.state.title?.length) {
            errors['title'] = "Title is required"
          }
          if (!this.state.first_name?.length) {
            errors['first_name'] = "First Name is required"
          }
          if (!this.state.last_name?.length) {
            errors['last_name'] = "Last Name is required"
          }
          if (String.isBlank(this.state.phone_no)) {
            errors['phone_no'] = "Phone number is required"
          }
          if (!/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.state.email)) {
            errors['email'] = "Invalid email."
          }
          if (!this.state.password?.length) {
            errors['password'] = "Password is required"
          } else if (!this.state.password?.length) {
            errors['confirmPassword'] = 'Confirm Password is required'
          } else if (this.state.password !== this.state.confirmPassword) {
            errors['confirmPassword'] = 'Passwords do not match'
          }
          if (!this.state.company_name?.length) {
            errors['company_name'] = "Company Name is required"
          }
          if (!this.state.license_no?.length) {
            errors['license_no'] = "License Number is required"
          }
          if (!this.state.clinic_phone_no?.length) {
            errors['clinic_phone_no'] = "Clinic Phone Number is required"
          }
        
          if (!this.state.country_id) {
            errors['country_id'] = "Country is required"
          }
          if (!this.state.state_province_id && this.state.stateProvinceList?.length) {
            errors['state_province_id'] = "State Province is required"
          }

          if (Object.keys(errors).length > 0 && errors.constructor === Object) {
            this.setState({ errors: errors })
          } else {
            const { success } = await this.props.register(this.state);
            if (success) {
              this.props.queueSnackbar('Account created. Please wait for our approval.', 'success')
              this.props.history.push('/login')
            }
          }
        } catch (err) {
          console.error(err)
        } finally {
          this.setState({ submitting: false })
        }
      })
    }
  }

  async componentDidMount() {
    const { props } = this
    if (this.state.countryList.length == 0) {
      let { data } = await axios.get('res/country')
      let country = data.country.filter(v => {
        return v.country_name === 'Thailand'
      })
      this.setState({ 'countryList': data.country, 'country_id': country[0].country_id })
    }

  }

  async handleChangeData(stateName, value) {
    switch (stateName) {
      case 'gender':
        this.setState({ "gender": value });
        break;
      case 'title':
        this.setState({ "title": value });
        break;
      case 'first_name':
        this.setState({ "first_name": value });
        break;
      case 'last_name':
        this.setState({ "last_name": value });
        break;
      case 'email':
        this.setState({ "email": value });
        break;
      case 'password':
        this.setState({ "password": value });
        break;
      case 'confirmPassword':
        this.setState({ "confirmPassword": value })
        break
      case 'phone_no':
        this.setState({ "phone_no": value });
        break;
      case 'fax_no':
        this.setState({ "fax_no": value });
        break;
      case 'license_no':
        this.setState({ "license_no": value });
        break;
      case 'clinic_phone_no':
        this.setState({ "clinic_phone_no": value });
        break;
      case 'clinic_website':
        this.setState({ "clinic_website": value });
        break;
      case 'sc_customer':
        this.setState({ "sc_customer": value });
        break;
      case 'country':
        this.setState({ "country_id": value });
        let { data: { state_province: stateProvince } } = await axios.get(`res/state_province/${value}`)
        this.setState({ stateProvinceList: stateProvince, state_province_id: stateProvince.length? this.state.state_province_id : undefined })
        break;
      case 'state_province':
        this.setState({ "state_province_id": value })
        break;
      case 'company_name':
        this.setState({ "company_name": value });
        break;
    }

  }

  render() {
    const { classes, isLoading } = this.props
    const { errors } = this.state

    let registeredStatusText = "";
    if (!this.props.registeredStatus) {
      registeredStatusText = (
        <Typography component="h6" variant="h6" className={classes.welcomeTitle}>
          <p style={{ color: "red" }}>{this.props.registeredStatusText}</p>
        </Typography>
      )
    }

    if (this.props.registeredStatus) {
      const { props } = this
      setTimeout(function () {  }, 2000);
    }
    return (

      <Fragment>
        {isLoading ? (
          <Loader />
        ) : (
            <Grid container>
              <TopBar />
              <Hidden smDown>
                <Grid item xs={12} md={6} style={{ backgroundImage: backgroundImage }} className={classes.loginLeft}></Grid>
              </Hidden>
              <Grid item xs={12} md={6}>
                <main className={classes.main}>
                  <Paper className={classes.paper} elevation={isWidthUp('sm', this.props.width) ? 2 : 0}>
                    <div>
                      <Grid container style={{ background: frontend_config?.[hostname]?.main_background_color, padding: "16px" }}>
                        <Grid item xs={2}>
                        </Grid>
                        <Grid item xs={8} style={{ textAlign: "center" }}>
                          <img className="loginLogo" alt="BeMe Logo" src={ getWebAbsPath(frontend_config?.[hostname]?.white_logo_path)} style={{ width: "60%" }} />
                        </Grid>
                      </Grid>

                      <Grid container style={{ padding: "16px 24px 24px" }}>
                        <Grid item xs={12}>
                          {registeredStatusText}
                          <Typography component="h1" variant="h4" className={classes.welcomeTitle}>
                            Welcome to BeMe
                  </Typography>
                          <Typography component="h3" variant="subtitle1" align="left" className={classes.welcomeTitle}>
                            {this.get('pleaseRegister')}
                  </Typography>
                          <Divider className="loginHr" />
                          <form className={classes.form}>

                            {/* <p className={classes.required} style={{ fontSize: 18, marginBlockEnd: 0 }}>{this.get(':fields.gender')}</p>
                            <Select
                              value={this.state.gender}
                              onChange={(event) => this.handleChangeData("gender", event.target.value)}
                              style={{
                                width: "100%"
                              }}
                            >
                              <MenuItem value="M">{this.get(':fields.male')}</MenuItem>
                              <MenuItem value="F">{this.get(':fields.female')}</MenuItem>
                            </Select> */}
                            <p className={classes.required} style={{ fontSize: 18, marginBlockEnd: 0 }}>{this.get(':fields.title')}</p>
                            <Select
                              value={this.state.title}
                              onChange={(event) => this.handleChangeData("title", event.target.value)}
                              style={{
                                width: "100%"
                              }}
                            >
                              <MenuItem value="Prof.">{this.get(':fields.Prof')}</MenuItem>
                              <MenuItem value="Dr.">{this.get(':fields.Dr')}</MenuItem>
                              <MenuItem value="Mr.">{this.get(':fields.Mr')}</MenuItem>
                              <MenuItem value="Mrs.">{this.get(':fields.Mrs')}</MenuItem>
                              <MenuItem value="Ms.">{this.get(':fields.Ms')}</MenuItem>
                              <MenuItem value="Miss.">{this.get(':fields.Miss')}</MenuItem>
                            </Select>
                            <Typography style={{color: 'red'}}>{this.state.errors['title']}</Typography>

                            <Grid container>
                              <Grid item xs={6} style={{ paddingRight: 4 }}>
                                <p className={classes.required} style={{ fontSize: 18, marginBlockEnd: 0 }}>{this.get(':fields.firstName')}</p>
                                <TextField
                                  className={classNames(classes.textField, classes.dense)}
                                  margin="dense"
                                  variant="outlined"
                                  required
                                  fullWidth
                                  autoFocus
                                  value={this.state.first_name}
                                  onChange={(event) => this.handleChangeData("first_name", event.target.value)}
                                />
                                <Typography style={{color: 'red'}}>{this.state.errors['first_name']}</Typography>
                              </Grid>
                              <Grid item xs={6} style={{ paddingLeft: 4 }}>
                                <p className={classes.required} style={{ fontSize: 18, marginBlockEnd: 0 }}>{this.get(':fields.lastName')}</p>
                                <TextField
                                  className={classNames(classes.textField, classes.dense)}
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth
                                  autoFocus
                                  value={this.state.last_name}
                                  onChange={(event) => this.handleChangeData("last_name", event.target.value)}
                                />
                                <Typography style={{color: 'red'}}>{this.state.errors['last_name']}</Typography>
                              </Grid>
                            </Grid>

                            <Grid item xs={12} style={{ paddingRight: 4 }}>
                              <p className={classes.required} style={{ fontSize: 18, marginBlockEnd: 0 }}>{this.get(':fields.phoneNo')}</p>
                              <TextField
                                className={classNames(classes.textField, classes.dense)}
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                value={this.state.phone_no}
                                onChange={(event) => this.handleChangeData("phone_no", event.target.value)}
                              />
                              <Typography style={{color: 'red'}}>{this.state.errors['phone_no']}</Typography>
                            </Grid>

                            <Grid item xs={12} style={{ paddingRight: 4 }}>
                            <p className={classes.required} style={{ fontSize: 18, marginBlockEnd: 0 }}>{this.get(':fields.email')}</p>
                            <TextField
                              className={classNames(classes.textField, classes.dense)}
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              autoFocus
                              value={this.state.email}
                              onChange={(event) => this.handleChangeData("email", event.target.value)}
                            />
                              <Typography style={{color: 'red'}}>{this.state.errors['email']}</Typography>
                            </Grid>

                            <p className={classes.required} style={{ fontSize: 18, marginBlockEnd: 0 }}>{this.get(':fields.password')}</p>
                            <TextField
                              id="password"
                              type="password"
                              className={classNames(classes.textField, classes.dense)}
                              margin="dense"
                              autoComplete="new-password"
                              variant="outlined"
                              fullWidth
                              value={this.state.password}
                              onChange={(event) => this.handleChangeData("password", event.target.value)}
                            />
                            <Typography style={{color: 'red'}}>{this.state.errors['password']}</Typography>
                            <p className={classes.required} style={{ fontSize: 18, marginBlockEnd: 0 }}>{this.get(':fields.confirmPassword')}</p>
                            <TextField
                              id="confirmPassword"
                              type="password"
                              className={classNames(classes.textField, classes.dense)}
                              margin="dense"
                              autoComplete="new-password"
                              variant="outlined"
                              fullWidth
                              value={this.state.confirmPassword}
                              onChange={(event) => this.handleChangeData("confirmPassword", event.target.value)}
                            />
                            <Typography style={{color: 'red'}}>{this.state.errors['confirmPassword']}</Typography>

                            <p className={classes.required} style={{ fontSize: 18, marginBlockEnd: 0 }}>{this.get(':fields.companyName')}</p>
                            <TextField
                              className={classNames(classes.textField, classes.dense)}
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              value={this.state.company_name}
                              onChange={(event) => this.handleChangeData("company_name", event.target.value)}
                            />
                            <Typography style={{color: 'red'}}>{this.state.errors['company_name']}</Typography>

                            <p className={classes.required} style={{ fontSize: 18, marginBlockEnd: 0 }}>{this.get(':fields.licenseNo')}</p>
                            <TextField
                              className={classNames(classes.textField, classes.dense)}
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              value={this.state.license_no}
                              onChange={(event) => this.handleChangeData("license_no", event.target.value)}
                            />
                            <Typography style={{color: 'red'}}>{this.state.errors['license_no']}</Typography>

                            <p className={classes.required} style={{ fontSize: 18, marginBlockEnd: 0 }}>{this.get(':fields.clinicPhoneNo')}</p>
                            <TextField
                              className={classNames(classes.textField, classes.dense)}
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              value={this.state.clinic_phone_no}
                              onChange={(event) => this.handleChangeData("clinic_phone_no", event.target.value)}
                            />
                            <Typography style={{color: 'red'}}>{this.state.errors['clinic_phone_no']}</Typography>

                            <p style={{ fontSize: 18, marginBlockEnd: 0 }}>{this.get(':fields.clinicWebsite')}</p>
                            <TextField

                              className={classNames(classes.textField, classes.dense)}
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              value={this.state.clinic_website}
                              onChange={(event) => this.handleChangeData("clinic_website", event.target.value)}
                            />

                            <p className={classes.required} style={{ fontSize: 18, marginBlockEnd: 0 }}>{this.get(':fields.country')}</p>
                            <Select
                              value={this.state.country_id}
                              onChange={(event) => this.handleChangeData("country", event.target.value)}
                              style={{
                                width: "100%"
                              }}
                            >
                              {
                                this.state.countryList.map(v => (
                                  <MenuItem value={v.country_id} key={v.country_id}>{v.country_name}</MenuItem>
                                ))
                              }
                            </Select>
                            <Typography style={{color: 'red'}}>{this.state.errors['country_id']}</Typography>

                            {!!this.state.stateProvinceList?.length &&
                              <>
                              <p className={classes.required} style={{ fontSize: 18, marginBlockEnd: 0 }}>{this.get(':fields.stateProvince')}</p>
                              <Select
                                value={this.state.state_province_id}
                                onChange={(event) => this.handleChangeData("state_province", event.target.value)}
                                style={{
                                  width: "100%"
                                }}
                              >
                                {
                                  this.state.stateProvinceList.map(v => (
                                    <MenuItem value={v.state_province_id} key={v.state_province_id}>{v.name}</MenuItem>
                                  ))
                                }
                              </Select>
                            <Typography style={{color: 'red'}}>{this.state.errors['state_province_id']}</Typography>
                              </>}
                              
                            <Button
                              fullWidth
                              variant="contained"
                              color="primary"
                              disabled={this.state.submitting}
                              className={classes.login}
                              onClick={this.submitHandler.bind(this)}
                              style={{ marginBottom: 16 }}
                            >
                              {
                                this.state.isVerifying ? <CircularProgress style={{ color: "white" }} /> :
                                  <div style={{ color: "white" }}>{this.get(':const.register')}<SendIcon style={{ margin: "0px 8px", verticalAlign: "middle", color: "white" }} /></div>
                              }
                            </Button>

                            <Link
                              to="/login"
                              style={{
                                textDecoration: "none",
                                color: "black"
                              }}
                            >
                              <Button variant="outlined" style={{ textAlign: "left", border: "black solid 1px", width: "100%" }}>
                        {this.get(':const.loginInstead')}
                      </Button>
                            </Link>
                            <p style={{ color: "red" }}>{this.state.errorMsg}</p>
                          </form>
                        </Grid>
                      </Grid>
                    </div>
                  </Paper>

                </main>
              </Grid>
            </Grid>
          )}
      </Fragment>
    )
  }
}

Register.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = store => {
  return {
    registeredStatus: store.auth.registeredStatus,
    registeredStatusText: store.auth.registeredStatusText,
    isLoading: store.loading.isLoading,
    lang: store.lang,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    register: (state) => dispatch(authAction.register(state)),
    showSnackbar: (msg, variant) => dispatch(easySnackbar(msg, variant)),
    queueSnackbar: (msg, variant) => dispatch(easySnackbar(msg, variant, true)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(withWidth()(withLang(Register, 'Register')))))
