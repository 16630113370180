import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react'
import { withRouter } from 'react-router-dom';
import { Grid, Button, CircularProgress, Backdrop, MenuItem, Select, InputLabel, useMediaQuery, TextField } from '@material-ui/core';
import Modal from '../../components/Modal/modal'
import { withStyles } from '@material-ui/core/styles';
import EmailEditor from 'react-email-editor';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import QueryString from 'querystring'
import '../../util/string'
//Components
import SideNav from '../../components/Admin/SideNav';
import { safeGet, safePost } from '../../axios-default';
import { useDispatch, useSelector } from 'react-redux';
import { easySnackbar } from '../../store/Noti/noti.actions';
import Moment from 'moment/moment'

const styles = theme => ({
rootContainer: {
    padding: 16,
    margin: "8px 0px 0px 238px",
    [theme.breakpoints.between("xs", "sm")]: {
      margin: "12px 0px 0px 0px",
      width: "100%",
    },
    width: "calc(100% - 238px)",
  },
})

const email_variables = [
  {
    name: 'User Name (e.g. Mr. Chan)',
    variable: '[user_name]',
  },
  {
    name: 'User Last Name',
    variable: '[user_last_name]',
  },
  {
    name: 'Case ID',
    variable: '[case_id]',
  },
  {
    name: 'Patient Full Name',
    variable: '[patient_name]',
  },
  {
    name: 'Email Address',
    variable: '[email]',
  },
  {
    name: 'Role',
    variable: '[role_name]',
  },
  {
    name: 'Passphrase for Password Reset',
    variable: '[passphrase]',
  },
  {
    name: 'Status 1st Parameter',
    variable: '[status_param1]',
  },
  {
    name: 'Status 2nd Parameter',
    variable: '[status_param2]',
  },
  {
    name: 'Plan Number',
    variable: '[plan_no]',
  },
  {
    name: 'Shipped Item Type',
    variable: '[product]',
  },
  {
    name: 'Number of Days',
    variable: '[number_of_days]'
  },
  {
    name: 'Date',
    variable: '[date]'
  },
  {
    name: 'Link',
    variable: '[link]'
  }
]

const defaultValues = {
  '[user_name]': 'Mr. Wong',
  '[user_last_name]': 'Wong',
  '[case_id]': '900001',
  '[patient_name]': 'Testing',
  '[email]': 'cs@bemealigners.com',
  '[role_name]': 'Distributor',
  '[passphrase]': 'xxxxxxxxxxxxxxxxxxxxx',
  '[status_param1]': '3',
  '[status_param2]': '2',
  '[plan_no]': '18',
  '[product]': 'aligner',
  '[number_of_days]': '3',
  '[date]': Moment(new Date()).format('YYYY-MM-DD'),
  '[link]': 'https://www.bemealigners.com/'
}

const processEscape = text => {
  return text.replace(/\\\[/g, '[').replace(/\\\]/g, ']')
}

const escape = text => {
  email_variables.forEach(({ variable }, i) =>
    text = text.replace(new RegExp(variable.escapeRegExp(), 'g'), `{{{$${i}}}}`)
  )
  text = text.replace(/\[/g, '\\\[').replace(/\]/g, '\\\]')
  email_variables.forEach(({ variable }, i) =>
    text = text.replace(new RegExp(`{{{$${i}}}}`.escapeRegExp(), 'g'), variable)
  )
  return text
}

const convertName = name =>
  (typeof name === 'string'? name : '').replace(/(^[a-z]|_[a-z])/gi, v =>
    ` ${v}`.replace('_', '').toUpperCase()
  ).trim()

const EmailManagement = (props) => {
  const { classes } = props
  const dispatch = useDispatch()
  const { distr_groups } = useSelector(store => store.res)

  const [templates, setTemplates] = useState([]),
        [loading, setLoading] = useState(true),
        [emailData, setEmailData] = useState({
          distr_group_id: 0,
          template_id: 0,
          subject: "",
          html: "",
          json: null,
          enable_flag: '',
          templateDefaultContent:''
        }),
        [templateParams, setTemplateParams] = useState(JSON.parse(JSON.stringify(defaultValues))),
        [testModalOpen, setTestModalOpen] = useState(false)

  const emailEditorRef = useRef(null),
        emailEditor2Ref = useRef(null)

  const refreshTemplates = useCallback(async distr_group_id => {
    distr_group_id = distr_group_id && distr_group_id > 0? distr_group_id : undefined
    try {
      const { template } = await safeGet(`res/emailTemplate?${
        distr_group_id? QueryString.encode({
          distrgroupId: distr_group_id
        }) : ''
      }`)
      if (!template) {
        dispatch(easySnackbar('Cannot retrieve email templates'))
        return
      }
      const templates = template.reduce((a, t) => {
        if (a[t.name]) {
          if (t.distr_group_id) {
            a[t.name] = t
          }
        } else {
          a[t.name] = t
        }
        return a
      }, {})
      return Object.values(templates)
    } catch (err) {
      console.error(err)
      dispatch(easySnackbar(err.message, 'error'))
      return []
    }
  }, [])

  // useEffect(() => {
  //   if (!isInit.current && Object.values(distr_groups).length) {
  //     isInit.current = true
  //     setLoading(true)
  //     ;(async () => {
  //       try {
  //         const { template } = await safeGet(`res/emailTemplate?${
  //           QueryString.encode({
  //             distrgroupId: Object.values(distr_groups)[0].distr_group_id
  //           })
  //         }`)
  //         console.log(template)
  //         isInit.current = true
  //       } catch (err) {
  //         console.error(err)
  //         isInit.current = false
  //       }
  //       setLoading(false)
  //     })()
  //   }
  // }, [distr_groups, isInit])

  const distrgroupOptions = useMemo(() =>
    [
    // <MenuItem value={0}>Default</MenuItem>
    ].concat(
      Object.values(distr_groups).map(({ distr_group_id, distr_group_name }) => (
        <MenuItem value={distr_group_id}>{distr_group_name}</MenuItem>
      ))
    )
  , [distr_groups])

  useEffect(() => {
    ;(async () => {
      setTemplates(await refreshTemplates(emailData.distr_group_id))
    })()
  }, [emailData.distr_group_id])

  const templateOptions = useMemo(() => {
    setLoading(false)
    return templates.map(({ template_id, name }) => (
      <MenuItem value={template_id}>{convertName(name)}</MenuItem>
    ))
  }, [templates, setLoading])

  useEffect(() => {
    if (emailData.template_id) {
      setLoading(true)
      ;(async () => {
        try {
          const { template } = await safeGet(`res/emailTemplate/${emailData.template_id}`)
          if (template) {
            if (template.json === null) {
              dispatch(easySnackbar('Please refer to the default email template on the left', 'info'))
            }
            setEmailData(emailData => ({
              ...emailData,
              subject: processEscape(template.subject),
              html: template.json? processEscape(template.content) : processEscape(template.content.replace(/(<table[^>]+)width=\"[0-9]*(px)?\"([^>]*)>/g, '$1width="100%"$3')),
              json: template.json? JSON.parse(template.json) : null,
              enable_flag: template.enable_flag,
              templateDefaultContent: template.json? '' : processEscape(template.content.replace(/(<table[^>]+)width=\"[0-9]*(px)?\"([^>]*)>/g, '$1width="100%"$3'))
            }))
          }
        } catch (err) {
          console.error(err)
          dispatch(easySnackbar(err.message, 'error'))
        }
        setLoading(false)
      })()
    }
  }, [emailData.template_id, dispatch])

  useEffect(() => {
    if (emailData.subject && !emailData.json && emailData.html) {
      if (emailEditor2Ref?.current?.editor) {
        emailEditor2Ref.current.editor.loadDesign({
          html: emailData.html,
          classic: true
        })
      }
    }
    if (emailData.subject) {
      if (emailEditorRef?.current?.editor) {
        emailEditorRef.current.editor.loadDesign(emailData.json ?? { body: { rows: [] } })
      }
    }
  }, [emailData, emailEditorRef, emailEditorRef?.current?.editor])

  const exportHtml = useCallback(() => {
    emailEditorRef.current.editor.exportHtml(async data => {
      const { design, html } = data;


      try {
        var tmpTHML = ''
        var tmpJson = ''
          if (JSON.stringify(design).length==1163){
            tmpTHML = escape(emailData.templateDefaultContent)
            tmpJson = ''
          }else{
            tmpTHML = escape(html)
            tmpJson = design
            setEmailData({...emailData, json: data.design})
          }

          const { success, message } = await safePost(`res/emailTemplate/${emailData.template_id}`, {
            distr_group_id: emailData.distr_group_id,
            subject: escape(emailData.subject),
            content: tmpTHML,
            json: tmpJson,
            enable_flag: emailData.enable_flag
          })
        if (success) {
          dispatch(easySnackbar('Saved', 'success'))
        } else {
          dispatch(easySnackbar(message, 'error'))
        }
      } catch (err) {
        console.error(err)
        dispatch(easySnackbar(err.message ?? err, 'error'))
      }
    })
  }, [emailData, setEmailData, emailEditorRef, dispatch])

  const handleChangeFilters = useCallback(field => ({ target: { value } }) => {
    const updateObj = {}


    switch (field) {

      case 'distr_group_id': {
        updateObj.template_id = 0
        updateObj.enable_flag = ''
      }
      case 'template_id': {
        updateObj.subject = ''
        updateObj.html = ''
      }
      case 'enable_flag': {
        //updateObj.enable_flag = false

      }
    }
    setEmailData({
      ...emailData,
      ...updateObj,
      [field]: value
    })
  }, [emailData, setEmailData])

  return (
    <div>
      <SideNav />

      <Backdrop open={loading} style={{zIndex: 99999}}>
        <CircularProgress style={{ color: "white" }} />
      </Backdrop>

      <Modal
        className="modal"
        modal='Aligner'
        title='Test Email Template'
        open={testModalOpen}
        modalSize="md"
        handleDataSave={() => {
          emailEditorRef.current.editor.exportHtml(async data => {
            const { html } = data;
            setEmailData({...emailData, json: data.design})
            try {
              const { success, message } = await safePost(`res/emailTemplate/test`,{
                subject: escape(emailData.subject),
                content: escape(html),
                ...Object.fromEntries(Object.entries(templateParams).map(([k, v]) => [k.replace(/\[|\]/g, ''), v])),
              })
              if (success) {
                dispatch(easySnackbar('Test email sent', 'success'))
                setTestModalOpen(false)
              } else {
                dispatch(easySnackbar(message ?? 'Cannot send test email', 'error'))
              }
            } catch (err) {
              console.error(err)
              dispatch(easySnackbar(err.message ?? err, 'error'))
            }
          })
        }}
        submitButton={true}
        submitButtonLabel="Test"
        handleClose={() => setTestModalOpen(false)}
      >
        <Grid container>
          {email_variables.map(({ name, variable }, i) => (
            <Grid key={i} item xs={12}>
              {/* <p style={{ marginBlockEnd: 0 }}>{name}</p> */}
              <TextField
                id={variable}
                label={name}
                value={templateParams[variable]}
                onChange={({ target: { value } }) => setTemplateParams(params => ({
                  ...params,
                  [variable]: value
                }))}
                margin="normal"
                fullWidth
              />
            </Grid>
          ))}
        </Grid>
      </Modal>

      <Grid container className={classes.rootContainer}>
        <Grid item xs={12}
          style={{
            padding: 8
          }}>
          <h1
            style={{
              marginBlockEnd: 0
            }}
          >
            Email Template Builder
          </h1>

          <hr />
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={4} style={{padding: 4}}>
              <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                Distributor Group
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={emailData.distr_group_id}
                onChange={handleChangeFilters("distr_group_id")}
                style={{
                  width: "100%"
                }}
              >
                {distrgroupOptions}
              </Select>
            </Grid>
            <Grid item xs={8} style={{padding: 4}}>
              <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                Template Name
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                disabled={!emailData.distr_group_id}
                value={emailData.template_id}
                onChange={handleChangeFilters("template_id")}
                style={{
                  width: "100%"
                }}
              >
                {templateOptions}
              </Select>
            </Grid>
            <InputLabel shrink id="demo-simple-select-placeholder-label-label">
              Enable
            </InputLabel>
            <Grid item xs={8} style={{padding: 4}}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={emailData.enable_flag}
              label="Enable"
              onChange={handleChangeFilters("enable_flag")}
            >
              <MenuItem value={false}>Off</MenuItem>
              <MenuItem value={true}>On</MenuItem>
            </Select>
            </Grid>
            <Grid item xs={12}
              style={{
                padding: 4,
                display: emailData.html? undefined : 'none'
              }}
            >
              {
                email_variables.map(v =>
                  <CopyToClipboard text={v.variable}
                    key={v.variable}
                    onCopy={() => dispatch(easySnackbar(`Copied ${v.name}`, 'success'))}
                  >
                    <Button size="small" variant="outlined" style={{marginRight: 4}}>{v.name}</Button>
                  </CopyToClipboard>
                )
              }
              <TextField
                id="subject"
                label="Subject"
                onChange={handleChangeFilters("subject")}
                style={{
                  width: "100%",
                  marginBottom: 8
                }}
                value={emailData.subject}
              />
              {/* {
                email_variables.map(v =>
                  <Button
                    size="small"
                    variant="outlined"
                    style={{marginRight: 4}}
                    onClick={() => setEmailData({...emailData, subject: emailData.subject + ` ${v.variable}`})}
                  >
                      {v.name}
                  </Button>
                )
              } */}

            </Grid>
          </Grid>
        </Grid>

        {emailData.html && !emailData.json?
          <Grid item xs={3}><p>Default: </p><iframe style={{width: '100%', minHeight: 600}} src={"data:text/html,"+encodeURIComponent(emailData.html ?? '')}/></Grid>
          : null}
        <Grid item xs={emailData.html && !emailData.json? 6 : 12}  style={{position: "relative", display: emailData.html? undefined : 'none'}}>
          <EmailEditor
            ref={emailEditorRef}
            minHeight="80vh"
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={() => setTestModalOpen(true)}
            style={{
              marginTop: 8,
              display: emailData.html? undefined : 'none'
            }}
          >
            Test Template
          </Button>
          <Button
            variant="contained"
            onClick={exportHtml}
            style={{
              marginTop: 8,
              marginLeft: 20,
              color: '#fff',
              background: '#473a69',
              display: emailData.html? undefined : 'none'
            }}
          >
            Save Template
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

export default withStyles(styles)(withRouter(EmailManagement))
