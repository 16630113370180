import { Button, CircularProgress, Fab, TextField } from '@material-ui/core/';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import axios from '../../axios-default';
import CountryTable from '../../components/Admin/CountryPage/CollapsibleTable';
//Components
import SideNav from '../../components/Admin/SideNav';
import Modal from '../../components/Modal/modal';
import { easySnackbar } from '../../store/Noti/noti.actions';
import { updateRes } from '../../store/res/res.action';
import { getWebAbsPath } from '../../util/path';




const styles = theme => ({
  "@global": {
    body: {
      background: "#fdfdfd",
    },
  },
  root: {
    flexGrow: 1,
  },
  rootContainer: {
    padding: 16,
    margin: "8px 0px 0px 238px",
    [theme.breakpoints.between("xs", "sm")]: {
      margin: "12px 0px 0px 0px",
      width: "100%",
    },
    width: "calc(100% - 238px)",
  },
  scanBox: {
    padding: 16,
    margin: "8px 0px"
  },
  textField: {
    // marginLeft: "8px",
    // marginRight: "8px",
    '& label.Mui-focused': {
      color: 'black',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'black',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey',
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
  },
  dateField: {
    width: "100%"
  },
  formControl: {
    width: "100%"
  },
  dense: {
    marginTop: 16,
    marginLeft: 0
  },
  submitBtn: {
    backgroundColor: "#061a2e !important",
    color: "white",
    padding: "8px 24px",
    margin: "12px 0px"
  },
  linkBtn: {
    background: "rgba(89,54,135,1)",
    color: "white",
    padding: "4px 24px",
    fontWeight: 300,
    margin: "16px 0px",
    width: "100%",
    [theme.breakpoints.up('sm')]: {
      padding: "4px 72px",
      width: "unset",
    },
    "&:hover": {
      background: "rgb(77, 74, 142)",
      color: "white"
    }
  },
});

class Countries extends React.Component {

  columns = [
    {
      name: "name",
      label: "Name",
      index: true,
      sortable: true
    },
    {
      name: "isoCode2",
      label: 'ISO Code 2',
      sortable: true
    },
    {
      name: "isoCode3",
      label: "ISO Code 3",
      sortable: true
    },
    {
      name: "isoCodeNo",
      label: "ISO Code No",
      numeric: true,
      sortable: true
    },
    {
      name: "obj",
      label: "Action",
      numeric: true,
      render: value => (
        <Grid container justify='flex-end'>
          <Grid item>
            <Fab size="small" aria-label="add"
              style={{
                backgroundColor: "#75a0de",
                color: "white",
                margin: "0px 4px"
              }}
              onClick={() => {
                this.setState({
                  updatedCountry: Object.assign({}, value),
                  countryModalOpen: true
                })
              }}>
              <EditIcon />
            </Fab>
          </Grid>
          <Grid item>
            <Fab size="small" aria-label="add"
              style={{
                backgroundColor: "#da5851",
                color: "white",
                margin: "0px 4px"
              }}
              onClick={async () => {
                if (window.confirm('Are you going to delete this country?')) {
                  try {
                    await axios.delete(`res/country/${value.country_id}`)
                    this.setState({
                      loading: true
                    }, async () => {
                      await this.props.updateRes('country')
                      this.componentDidMount()
                    })
                  } catch (err) {
                    console.error(err)
                    if (err.response && err.response.statusText === 'Unauthorized') {
                      localStorage.removeItem("auth-token");
                      localStorage.removeItem("expirationDate");
                      localStorage.removeItem("role");
                      this.props.history.push("/login")
                    }
                    if (err.response && err.response.data) {
                      window.scroll({top: 0, left: 0, behavior: 'smooth' })
                      this.props.showSnackbar(err.response.data.message, 'error')
                    }
                  }
                }
              }}>
              <DeleteOutlineIcon />
            </Fab>
          </Grid>
        </Grid>
      )
    },
  ]

  subColumns = [
    {
      name: 'name',
      label: 'Name',
      index: true,
      sortable: true
    },
    {
      name: 'abbrv',
      label: 'Abbreviation',
      sortable: true
    },
    {
      name: "obj",
      label: "Action",
      numeric: true,
      render: value => (
        <Grid container justify='flex-end'>
          <Grid item>
            <Fab size="small" aria-label="add"
              style={{
                backgroundColor: "#75a0de",
                color: "white",
                margin: "0px 4px"
              }}
              onClick={() => {
                this.setState({
                  updatedState: Object.assign({}, value, {
                    country_name: this.props.res.countries[value.country_id].country_name
                  }),
                  stateModalOpen: true
                })
              }}>
              <EditIcon />
            </Fab>
          </Grid>
          <Grid item>
            <Fab size="small" aria-label="add"
              style={{
                backgroundColor: "#da5851",
                color: "white",
                margin: "0px 4px"
              }}
              onClick={async () => {
                if (window.confirm('Are you going to delete this state/province?')) {
                  try {
                    await axios.delete(`res/state_province/${value.state_province_id}`)
                    this.setState({
                      loading: true
                    }, async () => {
                      await this.props.updateRes('state_province')
                      this.componentDidMount()
                    })
                  } catch (err) {
                    console.error(err)
                    if (err.response && err.response.statusText === 'Unauthorized') {
                      localStorage.removeItem("auth-token");
                      localStorage.removeItem("expirationDate");
                      localStorage.removeItem("role");
                      this.props.history.push("/login")
                    }
                    if (err.response && err.response.data) {
                      window.scroll({top: 0, left: 0, behavior: 'smooth' })
                      this.props.showSnackbar(err.response.data.message, 'error')
                    }
                  }
                }
              }}>
              <DeleteOutlineIcon />
            </Fab>
          </Grid>
        </Grid>
      )
    }
  ]

  constructor(props) {
    super(props);
    this.state = {
      isLoggedin: true,

      countryModalOpen: false,
      updatedCountry: {},
      coutnryModalError: "",

      stateModalOpen: false,
      updatedState: {},
      stateModalError: "",

      countries: [],
      loading: true
    }
  }

  handleCountryUpdate = field => ({ target }) => {
    if (field === 'iso_code_3' && target.value.length > 3) return
    if (field === 'iso_code_2' && target.value.length > 2) return
    this.setState(({ updatedCountry }) => ({
      updatedCountry: {
        ...updatedCountry,
        [field]: target.value
      }
    }))
  }

  handleStateUpdate = field => ({ target }) => {
    this.setState(({ updatedState }) => ({
      updatedState: {
        ...updatedState,
        [field]: target.value
      }
    }))
  }

  handleCountrySave = async () => {
    try {
      await axios.post('/res/country', this.state.updatedCountry)
      window.scroll({top: 0, left: 0, behavior: 'smooth' })
      this.props.showSnackbar('Success', 'success')
      this.setState({
        loading: true,
        countryModalOpen: false,
        updatedCountry: {}
      }, async () => {
        await this.props.updateRes('country')
        this.componentDidMount()
      })
    } catch (err) {
      console.error(err)
      if (err.response && err.response.statusText === 'Unauthorized') {
        localStorage.removeItem("auth-token");
        localStorage.removeItem("expirationDate");
        localStorage.removeItem("role");
        this.props.history.push("/login")
      }
      if (err.response && err.response.data) {
        if (err.response.data.invalid) {
          this.setState({
            countryModalError: `Following field(s) invalid: ${err.response.data.invalid.join(',')}`
          })
        } else {
          this.setState({
            countryModalError: err.response.data.message
          })
        }
      }
    }
  }

  handleStateSave = async () => {
    try {
      await axios.post('/res/state_province', this.state.updatedState)
      this.setState({
        loading: true,
        stateModalOpen: false,
        updatedState: {}
      }, async () => {
        await this.props.updateRes('state_province')
        this.componentDidMount()
      })
    } catch (err) {
      console.error(err)
      if (err.response && err.response.statusText === 'Unauthorized') {
        localStorage.removeItem("auth-token");
        localStorage.removeItem("expirationDate");
        localStorage.removeItem("role");
        this.props.history.push("/login")
      }
      if (err.response && err.response.data) {
        if (err.response.data.invalid) {
          this.setState({
            stateModalError: `Following field(s) invalid: ${err.response.data.invalid.join(',')}`
          })
        } else {
          this.setState({
            stateModalError: err.response.data.message
          })
        }
      }
    }
  }

  async componentDidMount() {
    try {
      const countries = Object.values(this.props.res.countries).reduce((a, c) => Object.assign(a, {
        [c.country_id]: {
          name: c.country_name,
          isoCode2: c.iso_code_2,
          isoCode3: c.iso_code_3,
          isoCodeNo: c.iso_code_numeric,
          obj: c
        }
      }), {})
      Object.values(this.props.res.stateProvinces).forEach(s => {
        if (countries[s.country_id]) {
          countries[s.country_id].state_province = (countries[s.country_id].state_province||[]).concat({
            name: s.name,
            abbrv: s.abbreviation,
            obj: s
          })
        } else {
          console.log('Country not found for ', s)
        }
      })
      this.setState({
        countries: Object.values(countries).sort((a, b) => a.name.localeCompare(b.name)).concat(),
        loading: false
      })
    } catch (err) {
      console.error(err)
      if (err.response && err.response.statusText === 'Unauthorized') {
        localStorage.removeItem("auth-token");
        localStorage.removeItem("expirationDate");
        localStorage.removeItem("role");
        this.props.history.push("/login")
      }
    }
  }

  // handleCountriesave = async () => {
  //   let errors = {}
  //   if (String.isBlank(this.state.updatedCountry.country_name)) {
  //     return this.setState({
  //       modalError: "Country Name is required"
  //     })
  //   }
  //   if (String.isBlank(this.state.updatedCountry.phone_no)) {
  //     return this.setState({
  //       modalError: "Phone No is required"
  //     })
  //   }
  //   if (!this.state.updatedCountry.country_id) {
  //     return this.setState({
  //       modalError: "Country is required"
  //     })
  //   }
  //   this.setState({ errors: "", isUpdating: true, disabled: true })
  //   try {
  //     const countryRes = (await axios.post(`res/country`, this.state.updatedCountry)).data
  //     if (this.state.updatedCountry.group_id && this.state.updatedCountry.group_id != this.state.updatedCountry.ori_group_id) {
  //       await axios.post(`res/group/${this.state.updatedCountry.group_id}/add/${this.state.updatedCountry.country_id||countryRes.country_id}`)
  //     }
  //     this.setState({
  //       loading: true,
  //       modalOpen: false
  //     }, async () => {
  //       await this.props.updateRes('country')
  //       this.componentDidMount()
  //     })
  //   }
  //   catch (err) {
  //     console.log(err)
  //     if (err.response && err.response.statusText === 'Unauthorized') {
  //       localStorage.removeItem("auth-token");
  //       localStorage.removeItem("expirationDate");
  //       localStorage.removeItem("role");
  //       this.props.history.push("/login")
  //     }
  //   }

  //   this.setState({ isUpdating: false, disabled: false })
  // }

  render() {
    const { classes } = this.props
    return (
      <div>
        <SideNav />
        <Grid container className={classes.rootContainer}>

        </Grid>
        <Grid container className={classes.rootContainer} style={{ marginTop: 0 }}>
          {this.state.isLoggedin && !this.state.loading ?
            <div style={{ display: "contents" }}>
              <Grid container>

                <Grid item xs={12}
                  style={{
                    padding: 8
                  }}>
                  <h1
                    style={{
                      marginBlockEnd: 0
                    }}
                  >
                    Countries
                  </h1>

                  <hr />
                </Grid>

                <Grid item xs={12}
                  style={{
                    padding: 8,
                    textAlign: "center"
                  }}>
                  <img style={{ width: "100%", maxWidth: 800 }} src={getWebAbsPath("/img/account1.png")} />
                </Grid>

                <Grid item xs={12} style={{ padding: 8 }}>
                  <Button className={classes.linkBtn} onClick={() => this.setState({ countryModalOpen: true })}>
                    Add Country
                  </Button>
                  <CountryTable
                    title='Countries'
                    subTitle='States/Provinces'
                    onInsert={v => {
                      this.setState({
                        stateModalOpen: true,
                        updatedState: {
                          country_name: this.props.res.countries[v.obj.country_id].country_name,
                          country_id: v.obj.country_id
                        }
                      })
                    }}
                    filterColumns={['name']}
                    columns={this.columns}
                    subColumns={this.subColumns}
                    data={this.state.countries}
                    subDataName='state_province'
                  />

                  <Modal
                    className="modal"
                    modal='Country'
                    title='Modify Country'
                    open={this.state.countryModalOpen}
                    handleClose={() => this.setState({ countryModalOpen: false, updatedCountry: {} })}
                    handleDataSave={() => this.handleCountrySave()}
                    modalError={this.state.countryModalError}
                    submitButton={true}
                  >
                    <Grid item xs={12} style={{ padding: 4 }}>
                      <TextField
                        id="country_name"
                        label="Country Name"
                        margin="normal"
                        style={{ width: "100%" }}
                        value={this.state.updatedCountry.country_name}
                        onChange={this.handleCountryUpdate('country_name')}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ padding: 4 }}>
                      <TextField
                        id="iso_code_2"
                        maxLength={2}
                        label="ISO Code (2 letters)"
                        style={{ width: "100%" }}
                        margin="normal"
                        value={this.state.updatedCountry.iso_code_2}
                        onChange={this.handleCountryUpdate('iso_code_2')}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ padding: 4 }}>
                      <TextField
                        id="iso_code_3"
                        maxLength={3}
                        label="ISO Code (3 letters)"
                        style={{ width: "100%" }}
                        margin="normal"
                        value={this.state.updatedCountry.iso_code_3}
                        onChange={this.handleCountryUpdate('iso_code_3')}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ padding: 4 }}>
                      <TextField
                        id="iso_code_numeric"
                        type='number'
                        label="ISO Code (Number)"
                        style={{ width: "100%" }}
                        margin="normal"
                        value={this.state.updatedCountry.iso_code_numeric}
                        onChange={this.handleCountryUpdate('iso_code_numeric')}
                      />
                    </Grid>
                  </Modal>

                  <Modal
                    className="modal"
                    modal='stateProvince'
                    title='Modify Stat/Province'
                    open={this.state.stateModalOpen}
                    handleClose={() => this.setState({ stateModalOpen: false, updatedState: {} })}
                    handleDataSave={() => this.handleStateSave()}
                    modalError={this.state.stateModalError}
                    submitButton={true}
                  >
                    <Grid item xs={12} style={{ padding: 4 }}>
                      <TextField
                        id="country_name"
                        label="Country Name"
                        margin="normal"
                        style={{ width: "100%" }}
                        value={this.state.updatedState.country_name}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ padding: 4 }}>
                      <TextField
                        id="name"
                        label="Name"
                        style={{ width: "100%" }}
                        margin="normal"
                        value={this.state.updatedState.name}
                        onChange={this.handleStateUpdate('name')}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ padding: 4 }}>
                      <TextField
                        id="abbreviation"
                        label="Abbreviation"
                        style={{ width: "100%" }}
                        margin="normal"
                        value={this.state.updatedState.abbreviation}
                        onChange={this.handleStateUpdate('abbreviation')}
                      />
                    </Grid>
                  </Modal>

                </Grid>
              </Grid>

            </div> :
            <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress style={{ color: "rgb(6,26,46)" }} />
            </Grid>}
        </Grid>
      </div>
    )
  }
}

Countries.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  res: state.res
});

const mapDispatchToProps = dispatch => ({
  updateRes: (resName) => dispatch(updateRes(resName)),
  showSnackbar: (message, variant) => dispatch(easySnackbar(message, variant))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(withWidth()(Countries))))