import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import style from './NewPatientSummary_beme.module.css'
import Zoom from 'react-medium-image-zoom'
import { withLang } from '../../../util/lang';
import { getWebAbsPath } from '../../../util/path'
import { beme_questionnaire } from '../../../beme_questionnaire'
import { retainer_colors } from '../../../color_pattern'

const aligner_package_types = {
  "type_1": "Evolve Max 8 Steps + 8 Steps",
  "type_2": "Progress Max 40 Steps + 10 Steps"
}

class NewPatientSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: []
    }
  }

  render() {
    let { NewCaseType } = this.props
    const retainer_pattern = []
    if (NewCaseType === 'retainer') {
      for (let n = 0; n < this.props.NewPaitientData.retainer_pairs; n++) {
        let img_upper = '', img_lower = ''
        if (this.props.NewPaitientData[`retainer_color${n}_upper`]) {
          img_upper = `/img/color-pattern/${this.props.NewPaitientData[`retainer_color${n}_upper`]}.${retainer_colors.find(c => c.value == this.props.NewPaitientData[`retainer_color${n}_upper`]).extension}`
        }
        if (this.props.NewPaitientData[`retainer_color${n}_lower`]) {
          img_lower = `/img/color-pattern/${this.props.NewPaitientData[`retainer_color${n}_lower`]}.${retainer_colors.find(c => c.value == this.props.NewPaitientData[`retainer_color${n}_lower`]).extension}`
        }
        retainer_pattern.push(
          <Grid container className={style.listItem}>
            <Grid item xs={12} md={2}>
              Retainer {n + 1}
            </Grid>
            <Grid item xs={12} md={10} className={style.normalText}>
              <Grid container>
                <Grid item xs={12}>
                  Type: {this.props.NewPaitientData[`retainer_type${n}`] ? String.toCapitalLetter(this.props.NewPaitientData[`retainer_type${n}`]) : <span style={{color: "red"}}>Not chosen</span>}
                </Grid>
                {this.props.NewPaitientData[`retainer_type${n}`] == "fashion" && <>
                  <Grid item sm={12} md={6} lg={4}>
                    <Zoom zoomMargin={48}>
                      <img className={style.summaryImg} src={img_upper} />
                    </Zoom>
                    <br/>
                    Upper Jaw Color
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Zoom zoomMargin={48}>
                      <img className={style.summaryImg} src={img_lower} />
                    </Zoom>
                    <br/>
                    Lower Jaw Color
                  </Grid>
                  <Grid item sm={12} md={6} lg={4}>
                    <Zoom zoomMargin={48}>
                      <img className={style.summaryImgPos} src={`/img/color-pattern/${this.props.NewPaitientData[`retainer_position${n}`]}.png`} />
                    </Zoom>
                    <br/>
                    Position
                  </Grid>
                </>}
              </Grid>
            </Grid>
          </Grid>
        )
      }
    }
    
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} sm={8}>
          <div className={style.LeftGrid}>
            <ul className={style.list}>
              {NewCaseType !== 'retainer' && <>
                <li className={style.listItem}>
                  <Grid container>
                    <Grid item xs={12}>
                      Questionnaire
                    </Grid>
                  </Grid>
                </li>
                {beme_questionnaire.map((q, i) =>
                  <Grid container className={style.listItem}>
                    <Grid item xs={12} md={5}>
                      {i + 1}. {q.question}
                    </Grid>
                    <Grid item xs={12} md={7} className={style.normalText}>
                      {this.props.NewPaitientData[q.name] ? q.options.find(({ value }) => value === this.props.NewPaitientData[q.name]).label : <span style={{color: "red"}}>Not selected</span>}
                    </Grid>
                  </Grid>
                )}
                <Grid container className={style.listItem}>
                  <Grid item xs={12} md={5}>
                    {beme_questionnaire.length + 1}. Notes and Comments (Optional)
                  </Grid>
                  <Grid item xs={12} md={7} className={style.normalText}>
                    {this.props.NewPaitientData.attr12 || <span style={{color: "red"}}>/</span>}
                  </Grid>
                </Grid>
              </>}
              <li className={style.listItem}>
                <Grid container>
                  <Grid item xs={12} md={5}>
                    {this.get('impressions.byCourierLabel')}
                  </Grid>
                  <Grid item xs={12} md={7} className={style.normalText}>
                    {this.props.NewPaitientData.modernCode ? this.props.NewPaitientData.modernCode : "No Customer Pick Up Code Added"}
                  </Grid>
                </Grid>
              </li>
              <li className={style.listItem}>
                <Grid container>
                  <Grid item xs={12} md={5}>
                    {this.get('impressions.byScannerBrandLabel')}
                  </Grid>
                  <Grid item xs={12} md={7} className={style.normalText}>
                    {this.props.NewPaitientData.scannerBrand ? this.props.NewPaitientData.scannerBrand : "No Scanner Brand Added"}
                  </Grid>
                </Grid>
              </li>
              <li className={style.listItem}>
                <Grid container>
                  <Grid item xs={12} md={5}>
                    {this.get('impressions.byScannerUserNameLabel')}
                  </Grid>
                  <Grid item xs={12} md={7} className={style.normalText}>
                    {this.props.NewPaitientData.doctorId ? this.props.NewPaitientData.doctorId : "No Doctor Code Added"}
                  </Grid>
                </Grid>
              </li>
              { NewCaseType === 'retainer' ? <>
                <li className={style.listItem}>
                  <Grid container>
                    <Grid item xs={12} md={5}>
                      Order summary
                    </Grid>
                    <Grid item xs={12} md={7} className={style.normalText}>
                      {this.props.NewPaitientData.retainer_pairs} pair{this.props.NewPaitientData.retainer_pairs > 1 ? 's' : ''}
                    </Grid>
                  </Grid>
                </li>
                {retainer_pattern}
              </> : <>
                <li className={style.listItem}>
                  <Grid container>
                    <Grid item xs={12} md={2}>
                      {this.get('summary.allPhotos')}
                    </Grid>
                    <Grid item xs={12} md={10} className={style.normalText}>
                      <Grid container>
                        {this.props.NewPaitientData.files.filter(value => value.category == "photo").map((value, index) => (
                          <Grid item sm={12} md={6} lg={4} key={index}>
                            {value.uploaded ?
                              <Zoom zoomMargin={48}>
                                <img
                                  src={`${value.dataURL ?? value.data}`}
                                  className={style.summaryImg}
                                  style={{
                                    transform: value.flipped ?
                                      `scaleX(-1) rotateZ(${value.rotate}deg)` :
                                      `rotateZ(${value.rotate}deg)`
                                  }} />
                              </Zoom> :
                              <img
                                src={getWebAbsPath('/img/uploadLater.png')}
                                className={style.summaryImg}
                                style={{
                                  transform: value.flipped ?
                                    `scaleX(-1) rotateZ(${value.rotate}deg)` :
                                    `rotateZ(${value.rotate}deg)`
                                }}
                              />
                            }
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </li>
                <li className={style.listItem}>
                  <Grid container>
                    <Grid item xs={12} md={2}>
                      {this.get('summary.allRadiography')}
                    </Grid>
                    <Grid item xs={12} md={10} className={style.normalText}>
                      <Grid container>
                        {this.props.NewPaitientData.files.filter(value => value.category == "radio").map((value, index) => (
                          <Grid item sm={12} md={6} lg={4} key={index}>
                            {value.will_not_upload ?
                              <img
                                src={getWebAbsPath('/img/willNotUpload.png')}
                                className={style.summaryImg}
                              /> : (value.uploaded ?
                                <img
                                  src={`${value.dataURL ?? value.data}`}
                                  className={style.summaryImg}
                                  style={{
                                    transform: value.flipped ?
                                      `scaleX(-1) rotateZ(${value.rotate}deg)` :
                                      `rotateZ(${value.rotate}deg)`
                                  }}
                                /> :
                                <img
                                  src={getWebAbsPath('/img/uploadLater.png')}
                                  className={style.summaryImg}
                                  style={{
                                    transform: value.flipped ?
                                      `scaleX(-1) rotateZ(${value.rotate}deg)` :
                                      `rotateZ(${value.rotate}deg)`
                                  }}
                                />
                              )
                            }
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </li>
                <li className={style.listItem}>
                  <Grid container>
                    <Grid item xs={12} md={2}>
                      {this.get('summary.allImpressions')}
                    </Grid>
                    <Grid item xs={12} md={10} className={style.normalText}>
                      <Grid container>
                        {this.props.NewPaitientData.files.filter(value => value.category == "stl_file").map((value, index) => (
                          <Grid item sm={12} md={6} lg={4} key={index}>
                            {value.uploaded ? <>
                              <Zoom zoomMargin={48}>
                                <img
                                  src={`${value.data? getWebAbsPath('/img/uploaded.png') : getWebAbsPath('/img/imageUpload.png')}`}
                                  className={style.summaryImg}
                                />
                              </Zoom>
                              <br/>
                              {['Upper STL', 'Lower STL', 'Bite 1 STL', 'Bite 2 STL'][index]}
                            </> : <>
                              <img
                                src={getWebAbsPath('/img/uploadLater.png')}
                                className={style.summaryImg}
                                style={{
                                  transform: value.flipped ?
                                    `scaleX(-1) rotateZ(${value.rotate}deg)` :
                                    `rotateZ(${value.rotate}deg)`
                                }}
                              />
                              <br/>
                              {['Upper STL', 'Lower STL', 'Bite 1 STL', 'Bite 2 STL'][index]}
                            </>}
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </li>
              </>}
              {this.props.NewPaitientData.errors.filter(value => !value.valid).map((value, index) => <>
                {!index ?
                  <li className={style.listItem}>Warning</li> :
                ""}
                <Grid container className={style.listItem + " " + style.errorList}>
                  <Grid item xs={1}>
                    {index + 1}.
                  </Grid>
                  <Grid item xs={11}>
                    {value.msg}
                  </Grid>
                </Grid>
              </>)}
            </ul>
          </div>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Grid container>
            <Grid item xs={12} md={3} className={style.userImage}>
              <img
                src={
                  (typeof this.props.NewPaitientData.patientPhoto === 'object' && this.props.NewPaitientData.patientPhoto.data === null) ||
                  (typeof this.props.NewPaitientData.patientPhoto === 'string' && this.props.NewPaitientData.patientPhoto == '')
                  ? getWebAbsPath("/img/front_smile.png")
                  : this.props.NewPaitientData.patientPhoto.dataURL
                }
                style={this.props.NewPaitientData.patientPhoto?.rotate? {
                  transform: `rotateZ(${this.props.NewPaitientData.patientPhoto.rotate}deg)`
                } : null}
              />
            </Grid>
            <Grid item xs={12} md={9}>
              <ul>
                <li><strong>{this.get(':fields.name')}</strong>: {this.props.NewPaitientData.firstName} {this.props.NewPaitientData.lastName}</li>
                <li><strong>{this.get(':fields.gender')}</strong>: {this.props.NewPaitientData.gender}</li>
                <li><strong>{this.get(':fields.dateOfBirth')}</strong>: {this.props.NewPaitientData.day}-{this.props.NewPaitientData.month}-{this.props.NewPaitientData.year}</li>
                <li><strong>Contact</strong>: {this.props.NewPaitientData.country_code || ""} {this.props.NewPaitientData.contact_number || "/"}</li>
                {NewCaseType !== 'retainer' && <li><strong>Aligner Package Type</strong>: {this.props.NewPaitientData.attr0 ? aligner_package_types[this.props.NewPaitientData.attr0] : ""}</li>}
                <li><strong>Remarks</strong>: {this.props.NewPaitientData.remarks || "/"}</li>
              </ul>
            </Grid>
            <Grid item xs={12}>
              <ul className={style.noBulletList}>
                <li><strong>{this.get(':fields.deliveryAddress')}</strong>: {this.props.NewAddressData.address}</li>
                {this.props.NewAddressData.city && <li><strong>{this.get(':fields.city')}</strong>: {this.props.NewAddressData.city}</li>}
                {this.props.NewAddressData.zipCode && <li><strong>{this.get(':fields.postCode')}</strong>: {this.props.NewAddressData.zipCode}</li>}
                {this.props.NewAddressData.phoneNumber && <li><strong>{this.get(':fields.phoneNo')}</strong>: {this.props.NewAddressData.phoneNumber}</li>}
                <li><strong>{this.get(':fields.promotionCode')}</strong>: {this.props.NewPaitientData.promotionCode ? this.props.NewPaitientData.promotionCode : "None"}</li>
              </ul>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = store => {
  return {
    NewPaitientData: store.newPatientReducer,
    NewAddressData: store.newAddressReducer,
    lang: store.lang
  }
};

export default connect(mapStateToProps)(withLang(NewPatientSummary, 'NewPatient'));
