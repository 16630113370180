import '../../../util/string'
import React, { Component, forwardRef, useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { withRouter, useHistory, useParams } from 'react-router-dom'
import { Fab, Button, Typography, Tooltip, CircularProgress } from '@material-ui/core/';
import Grid from '@material-ui/core/Grid';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import MUIDataTable from "mui-datatables";
import QueryString from 'querystring'
import Url from 'url';
import join from 'url-join'
import CancelIcon from '@material-ui/icons/Cancel'
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'
import axios from '../../../axios-default'
import Moment from 'moment'
import MaterialTable from 'material-table';
import { ApiServerUrl } from '../../../config.json';

import Add from '@material-ui/icons/Add'
import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import DraftsIcon from '@material-ui/icons/Drafts'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import GridOnIcon from '@material-ui/icons/GridOn'
import LastPage from '@material-ui/icons/LastPage'
import MailOutlinedIcon from '@material-ui/icons/MailOutlined'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import ViewListIcon from '@material-ui/icons/ViewList'
import { useDispatch } from 'react-redux';
import { easySnackbar } from '../../../store/Noti/noti.actions';
import { getFromObj } from '../../../util/lang';
import SideNav from '../SideNav';
import { getWebAbsPath } from '../../../util/path';

const StatusList = {
  DRAFT: 'Draft',
  SUBMITTED: 'Prescription Submitted',
  AWAITING: 'Awaiting More Records{Remark: ?}',
  RECEIVED: 'Confirmed All Materials Received',
  CHANGE_REQUEST: 'Treatment Plan Change Request Received',
  PLAN_UPLOADED: 'Treatment Plan {No.?} Uploaded',
  PLAN_APPROVED: 'Treatment Plan {No.?} Approved',
  PRODUCTION: 'Production In Progress',
  DELIVERED: 'Aligners Set {No.1 ?}-{No.2 ?} Delivered',
  NEXT_SET_REQUEST: 'Request for Next Aligner Set',
  ALL_DELIVERED: 'All Aligners Sets Are Delivered',
  ALIGNER_REQUEST: 'Aligner Request',
  RETAINER_REQUEST: 'Retainer Request',
  REFINEMENT: 'Refinements Prescription Received',
  ENDED: 'Case Ended'
}

const TableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
}

const OrderStyles = makeStyles(theme => ({
  "@global": {
    body: {
      background: "#fdfdfd",
    },
  },
  root: {
    flexGrow: 1,
  },
  rootContainer: {
    padding: 16,
    margin: "8px 0px 0px 238px",
    [theme.breakpoints.between("xs", "sm")]: {
      margin: "12px 0px 0px 0px",
      width: "100%",
    },
    width: "calc(100% - 238px)",
  },
  scanBox: {
    padding: 16,
    margin: "8px 0px"
  },
  textField: {
    // marginLeft: "8px",
    // marginRight: "8px",
    '& label.Mui-focused': {
      color: 'black',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'black',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey',
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
  },
  dateField: {
    width: "100%"
  },
  formControl: {
    width: "100%"
  },
  dense: {
    marginTop: 16,
    marginLeft: 0
  },
  submitBtn: {
    backgroundColor: "#061a2e !important",
    color: "white",
    padding: "8px 24px",
    margin: "12px 0px"
  },
  linkBtn: {
    background: "rgba(89,54,135,1)",
    color: "white",
    padding: "4px 24px",
    fontWeight: 300,
    margin: "16px 0px",
    width: "100%",
    [theme.breakpoints.up('sm')]: {
      padding: "4px 72px",
      width: "unset",
    },
    "&:hover": {
      background: "rgb(77, 74, 142)",
      color: "white"
    }
  },
}))

const Columns = [
  {
    field: 'orderNum',
    title: 'Order #',
    searchable: true
  },
  {
    field: 'profilePic',
    title: 'Profile',
    searchable: false,
    render: ({ profilePic, gender }) => (
      <img
        alt={gender}
        style={{ width: 50 }}
        src={profilePic}
        onError={e => {
          let u = Url.parse(e.target.src)
          e.target.src = (
            (u.pathname === '/' || u.pathname.startsWith('/img')
              ? ''
              : (
                gender === 'M'
                  ? getWebAbsPath('/img/maleIcon.png')
                  : (gender === 'F'? getWebAbsPath('/img/femaleIcon.png') : getWebAbsPath('/img/otherIcon.png'))
              )
            )
          )
        }}
      />
    )
  },
  {
    field: 'status',
    title: 'Status',
    searchable: true
  },
  {
    field: 'firstName',
    title: 'First Name',
    searchable: true
  },
  {
    field: 'lastName',
    title: 'Last Name',
    searchable: true
  },
  {
    field: 'approvedFlag',
    title: 'Approved',
    render: ({ approvedFlag }) => (
      <div style={{ display: "flex", justifyContent: 'center', margin: '0px 4px', width: 40, height: 40 }}>
        {approvedFlag ? <CheckIcon /> : <CloseIcon />}
      </div>
    )
  },
  {
    field: "treatmentOption",
    title: "Treatment Option",
    searchable: true
  },
  {
    field: "createdDate",
    title: "Creation Date",
  }
]

export default function Orders(props) {
  const classes = OrderStyles(),
        dispatch = useDispatch(),
        history = useHistory(),
        { id } = useParams()

  const [loading, setLoading] = useState(false),
        lastPageSize = useRef(),
        lastPageNo = useRef(0),
        [total, setTotal] = useState(0)

  useEffect(() => {
    window.scroll({top: 0, left: 0, behavior: 'smooth' })
  }, [])

  const reloadPage = () => {
    const curPage = history.location.pathname
    history.push('/blank')
    history.replace(curPage)
  }
  const Actions = [
    // row => ({
    row => ({
      icon: () => (
        row.cancelFlag?
        <Typography variant="body1">Cancelled</Typography> :
        <Fab
          size="small"
          aria-label="add"
          style={{
            backgroundColor: "#ff0000",
            color: "white",
            margin: "0px 4px"
          }}
        >
          <CancelIcon />
        </Fab>
      ),
      tooltip: row.cancelFlag? 'Cancelled' : 'Cancel',
      hidden: false,
      onClick: row.cancelFlag? undefined : async (_, data) => {
        if (window.confirm('Are you going to cancel this case?')) {
          try {
            const { data: result } = await axios.post(`case/${row.orderNum}/cancelled`)
            if (result.success) {
              await dispatch(easySnackbar('Success', 'success', true))
              // setLoading(true)
              reloadPage()
            } else {
              console.error(result.message)
              dispatch(easySnackbar(result.message, 'error'))
            }
          } catch (err) {
            console.error(err)
            dispatch(easySnackbar(
              getFromObj(err, 'response.data.message') || err.message || err,
              'error'
            ))
          }
        }
      }
    })
  ]

  const dataGetter = async query => {
    const { pageSize, page: pageNo, search, orderBy, orderDirection } = query
    try {
      if (orderBy && orderBy.field.endsWith('_label')) {
        orderBy.field = orderBy.field.slice(0, -6)
      }
      const { data } = (await axios.get(`case?${QueryString.encode({
        searchStr: typeof search !== 'string' || String.isBlank(search)? undefined : search,
        sortBy: orderBy && orderBy.field,
        sortDir: orderDirection,
        statusFilter: 'ALL',
        pageSize,
        pageNo,
        userId: id,
        all: 1
      })}`))
      lastPageSize.current= pageSize
      lastPageNo.current = pageNo
      setLoading(false)
      return {
        data: data.case.filter(c => c.case_id >= 1).map(c => {
          return {
            orderNum: c.case_id,
            profilePic: c.profile_pic? join(ApiServerUrl, c.profile_pic) : '',
            gender: c.gender,
            firstName: c.first_name,
            lastName: c.last_name,
            approvedFlag: !!c.approval_date,
            status: StatusList[c.case_status],
            treatmentOption: c.case_type,
            createdDate: Moment(c.created_date).calendar({
              sameElse: 'DD MMM YYYY [at] hh:mm A'
            }),
            cancelFlag: c.cancel_flag
          }
        }),
        page: pageNo ?? 0,
        totalCount: data.total ?? 0
      }
    } catch (err) {
      console.error(err)
      if (err.response && err.response.statusText === 'Unauthorized') {
        localStorage.removeItem("auth-token");
        localStorage.removeItem("expirationDate");
        localStorage.removeItem("role");
        history.replace('/login')
      }
    }
  }

  return (
    <div>
      {!loading ?
        <Grid container>
          <Grid item xs={12} style={{ padding: 8 }}>
            <MaterialTable
              title=''
              columns={Columns}
              actions={Actions}
              data={dataGetter}
              icons={TableIcons}
              onRowClick={(_, row) => history.push(`/patients/${row.orderNum}${row.status === StatusList['DRAFT']? '/edit' : ''}`)}
              options={{
                initialPage: 0,
                emptyRowsWhenPaging: false,
                exportButton: true,
                exportAllData: true,
                debounceInterval: 700,
                columnsButton: true,
                actionsColumnIndex: -1,
                pageSize: 30,
                pageSizeOptions: [15, 30, 50],
                minBodyHeight: 700,
                maxBodyHeight: 1000,
              }}
            />
          </Grid>
        </Grid> :
        <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress style={{ color: "rgb(6,26,46)" }} />
        </Grid>}
    </div>
  )
}