import React, { Component, PropTypes } from 'react';
import { Grid, TextField, Button, Select, MenuItem, Checkbox, FormGroup, FormControlLabel } from '@material-ui/core'
import NewPatientPhoto from './NewPatientPhoto'
import style from './NewPatientPhotos.module.css'
import DragAndDrop from '../Photos/DragAndDrop'
import { Animated } from "react-animated-css";
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { connect } from 'react-redux';
import { withLang } from '../../../util/lang';
import axios from '../../../axios-default'
import ModalLoader from '../../ModalLoader'

class TreatmentPlan extends Component {

  constructor(props) {
    super(props);
    //set a default value for url of smile 3d
    this.props.treatmentPlan.smile_3d = "~pending upload~";
    this.handleInput("~pending upload~", "smile_3d");

    this.state = {
      loadingMyo: false
    };
  }

  // fileToBase64 = file => new Promise((resolve, reject) => {
  //   const reader = new FileReader();
  //   if (file !== undefined) {
  //     reader.readAsDataURL(file);
  //   }
  //   reader.onload = () => resolve(reader.result);
  //   reader.onerror = error => reject(error);
  // });

  fileupload(file, stateType) {
    let ClassThis = this
    let fileName = file.name
    ClassThis.props.handleTreatmentData(file, stateType, fileName)
    // let baseFile = this.fileToBase64(file).then((data) => {
    //   ClassThis.props.handleTreatmentData(data, stateType, fileName)
    // })
  }

  onFileChange = (event, stateType) => {
    let compo = this
    let fileName = event.target.files[0].name
    compo.props.handleTreatmentData(event.target.files[0], stateType, fileName)
    // let baseFile = compo.fileToBase64(event.target.files[0]).then((data) => {
    //   compo.props.handleTreatmentData(data, stateType, fileName)
    // })
  }

  onMyoFileChange = (event) => {
    this.handleMyo(event.target.files[0]);
  }

  handleMyo = async (uploadedFile) => {
    try {
      this.setState({ loadingMyo: true });
      if (!uploadedFile.name.includes(".myo") && !uploadedFile.name.includes(".myp")){
        alert("Please upload a .myo/.myp file!");
        this.setState({ loadingMyo: false });
        return;
      }
      // Upload the .myo file and get the respond.
      const form = new FormData();
      let treatmentData = {
        case_id: this.props.patientInfo.id,
        patient_name: `${this.props.patientInfo.firstName} ${this.props.patientInfo.lastName}`,
        created_by: this.props.patientInfo.created_by
      }
      let myoLink = `case/treatment_plan_myo/${this.props.patientInfo.id}`
      let mypLink = `case/treatment_plan_myp/${this.props.patientInfo.id}`
      var res
      if (uploadedFile.name.includes(".myo")){
        form.append('$$FILE:myo', uploadedFile);
        form.append('body', JSON.stringify(treatmentData))
        res = await axios.post(myoLink, form);
      }else{
        form.append('$$FILE:myp', uploadedFile);
        form.append('body', JSON.stringify(treatmentData))
        res = await axios.post(mypLink, form);
      }
      console.log(res);
      var smile3dUrl = res.data.url;
      this.props.treatmentPlan.smile_3d = smile3dUrl;
      this.props.treatmentPlan.myo_oid = res.data.oid;
      this.props.treatmentPlan.myo_pid = res.data.pid;
      this.props.treatmentPlan.myo_id = res.data.id;
      this.props.treatmentPlan.myo_token = res.data.token;
      this.handleInput(smile3dUrl, "smile_3d");
      this.setState({ loadingMyo: false });

      // old method, upload in front end.
      // // 1. Login
      // const loginForm = new FormData();
      // loginForm.append('token', '61dbae2f1fada427506e033161dbwr2f1fada4275065184161dbae2f1fada423');
      // await axios.post(`https://modern.inteware.com.tw/api/login/621742ed1e742f18f07ca3f0`, loginForm);
      // console.log("logined");
      // // 2. Create order
      // const creasteOrderForm = new FormData();
      // creasteOrderForm.append("customSerialnum", this.props.patientInfo.id);
      // creasteOrderForm.append("patient", `${this.props.patientInfo.firstName} ${this.props.patientInfo.lastName}`);
      // creasteOrderForm.append("draft", "true");
      // creasteOrderForm.append("modelType", "plasterModel");
      // creasteOrderForm.append("producedContent", "fullJaw");
      // const createOrderResponse = await axios.post(`https://modern.inteware.com.tw/api/order/Ortho`, creasteOrderForm);
      // console.log("created");
      // console.log(createOrderResponse);
      // // 3. Upload .myo
      // const uploadMyoForm = new FormData();
      // uploadMyoForm.append("oid", createOrderResponse.data.oid);
      // uploadMyoForm.append("action", "create");
      // uploadMyoForm.append("project", uploadedFile);
      // const uploadResponse = await axios.post(`https://modern.inteware.com.tw/api/project/ortho`, uploadMyoForm);
      // console.log("uploaded");
      // // 4. Create Link
      // const createLinkForm = new FormData();
      // createLinkForm.append("pid", uploadResponse.data.pid);
      // createLinkForm.append("type", "viewer_ortho");
      // const createLinkResponse = await axios.post(`https://modern.inteware.com.tw/api/token/viewer`, createLinkForm);
      // var smile3dUrl = "https://modern.inteware.com.tw" + createLinkResponse.data.path;
      // this.props.treatmentPlan.smile_3d = smile3dUrl;
      // this.props.treatmentPlan.myo_oid = createOrderResponse.data.oid;
      // this.props.treatmentPlan.myo_pid = uploadResponse.data.pid;
      // this.props.treatmentPlan.myo_id = createLinkResponse.data.id;
      // this.props.treatmentPlan.myo_token = createLinkResponse.data.token;
      // this.handleInput(smile3dUrl, "smile_3d");
    } catch (err){
      this.setState({ loadingMyo: false });
      console.log(err);
      alert("Upload failed, please check your file and try again.");
      // if (window.confirm("Upload failed, please check your file and try again")){
      //   this.handleMyo(uploadedFile);
      // }
    }
  }

  handleDrop = (uploadedFile, stateType) => {
    this.fileupload(uploadedFile, stateType)
  }

  handleRadioButtonChange = (type) => {
    let smile3DElement = document.getElementById("Smile_3D_Grid")
    let smile3DTextField = document.getElementById("3durl");
    let myoElement = document.getElementById("myo_grid")
    switch(type) {
      case "select_url":
        // smile3DElement.hidden = false
        smile3DTextField.disabled = false
        this.props.treatmentPlan.smile_3d = "";
        this.handleInput("", "smile_3d")
        myoElement.hidden = true
        smile3DElement.scrollIntoView()
        break
      case "select_myo":
        // smile3DElement.hidden = true
        smile3DTextField.disabled = true
        this.props.treatmentPlan.smile_3d = "~pending upload~";
        this.props.treatmentPlan.myo_oid = null;
        this.props.treatmentPlan.myo_pid = null;
        this.props.treatmentPlan.myo_id = null;
        this.props.treatmentPlan.myo_token = null;
        this.handleInput("~pending upload~", "smile_3d");
        myoElement.hidden = false
        myoElement.scrollIntoView()
        break
    }
  }

  handleInput(val, type) {
    this.props.handleTreatmentData(val, type, "")
  }

  removefile(stateType) {
    console.log(stateType);
    this.props.handleTreatmentData(null, stateType, "")
    //this.props.handleDataPhoto("")
  }

  render() {
    return (
      <Animated animationIn="fadeIn" isVisible={true}>
        <Grid container spacing={1} className={style.twoColumnImage}>
          <p style={{ display: "block", width: "100%", marginBottom: "25px" }}>{this.get('treatmentPlan.pdfFile')}:</p>
          <Grid item xs={12} sm={6}>
            {this.props.treatmentPlan.pdf1 === null ?
              <DragAndDrop handleDrop={(uploadedFile) => this.handleDrop(uploadedFile, 'pdf1')}>
                <div className={style.imageUploadArea}>
                  <span>{this.get('treatmentPlan.dragAndDrop')}</span>
                  <div className={style.uploadBtnWrapper}>
                    <button className={style.uploadBtn}>+</button>
                    <input
                      type="file"
                      onChange={(e) => this.onFileChange(e, "pdf1")}
                      name="patientImage"
                      id="patientImage"
                    />
                  </div>
                </div>
              </DragAndDrop> :
              <DragAndDrop handleDrop={(uploadedFile) => this.handleDrop(uploadedFile, 'pdf1')}>
                <div class={style.imageUploadArea}>
                  <button className={style.remove} onClick={() => this.removefile('pdf1')}><DeleteOutlinedIcon /></button>
                  {this.props.treatmentPlan.pdf1_label}
                </div>
              </DragAndDrop>}
            <p style={{ textAlign: "center", color: "rgba(0,0,0,0.3)" }}>({this.get('treatmentPlan.analysis')})</p>
          </Grid>
          <Grid item xs={12} sm={6}>
            {this.props.treatmentPlan.pdf2 === null ?
              <DragAndDrop handleDrop={(uploadedFile) => this.handleDrop(uploadedFile, 'pdf2')}>
                <div className={style.imageUploadArea}>
                  <span>{this.get('treatmentPlan.dragAndDrop')}</span>
                  <div className={style.uploadBtnWrapper}>
                    <button className={style.uploadBtn}>+</button>
                    <input
                      type="file"
                      onChange={(e) => this.onFileChange(e, "pdf2")}
                      name="patientImage"
                      id="patientImage"
                    />
                  </div>
                </div>
              </DragAndDrop> :
              <DragAndDrop handleDrop={(uploadedFile) => this.handleDrop(uploadedFile, 'pdf2')}>
                <div class={style.imageUploadArea}>
                  <button className={style.remove} onClick={() => this.removefile('pdf2')}><DeleteOutlinedIcon /></button>
                  {this.props.treatmentPlan.pdf2_label}
                </div>
              </DragAndDrop>}
            <p style={{ textAlign: "center", color: "rgba(0,0,0,0.3)" }}>({this.get('treatmentPlan.treatmentPlan')})</p>
          </Grid>
          <p style={{ display: "block", width: "100%", marginBottom: "25px" }}>{this.get('treatmentPlan.videoFile')}:</p>
          <Grid item xs={12} sm={6}>
            {this.props.treatmentPlan.video1 === null ?
              <DragAndDrop handleDrop={(uploadedFile) => this.handleDrop(uploadedFile, 'video1')}>
                <div className={style.imageUploadArea}>
                  <span>{this.get('treatmentPlan.dragAndDrop')}</span>
                  <div className={style.uploadBtnWrapper}>
                    <button className={style.uploadBtn}>+</button>
                    <input
                      type="file"
                      onChange={(e) => this.onFileChange(e, "video1")}
                      name="patientImage"
                      id="patientImage"
                    />
                  </div>
                </div>
              </DragAndDrop> :
              <DragAndDrop handleDrop={(uploadedFile) => this.handleDrop(uploadedFile, 'video1')}>
                <div class={style.imageUploadArea}>
                  <button className={style.remove} onClick={() => this.removefile('video1')}><DeleteOutlinedIcon /></button>
                  {this.props.treatmentPlan.video1_label}
                </div>
              </DragAndDrop>}
            <p style={{ textAlign: "center", color: "rgba(0,0,0,0.3)" }}>({this.get('treatmentPlan.buccalViewVideo')})</p>
          </Grid>

          <Grid item xs={12} sm={6}>
            {this.props.treatmentPlan.video2 === null ?
              <DragAndDrop handleDrop={(uploadedFile) => this.handleDrop(uploadedFile, 'video2')}>
                <div className={style.imageUploadArea}>
                  <span>{this.get('treatmentPlan.dragAndDrop')}</span>
                  <div className={style.uploadBtnWrapper}>
                    <button className={style.uploadBtn}>+</button>
                    <input
                      type="file"
                      onChange={(e) => this.onFileChange(e, "video2")}
                      name="patientImage"
                      id="patientImage"
                    />
                  </div>
                </div>
              </DragAndDrop> :
              <DragAndDrop handleDrop={(uploadedFile) => this.handleDrop(uploadedFile, 'video2')}>
                <div class={style.imageUploadArea}>
                  <button className={style.remove} onClick={() => this.removefile('video2')}><DeleteOutlinedIcon /></button>
                  {this.props.treatmentPlan.video2_label}
                </div>
              </DragAndDrop>}
            <p style={{ textAlign: "center", color: "rgba(0,0,0,0.3)" }}>({this.get('treatmentPlan.occlusalVideo')})</p>
          </Grid>
          
          { this.props.caseType == 'RETAINER' ? "" : (
            <Grid item xs={12} sm={12}>
              <TextField
                style={{ width: "80%" }}
                id="max_aligner_number"
                label="Number of Sets of Aligner"
                value={this.props.treatmentPlan.max_aligner_number}
                onKeyPress={(event) => !/[0-9]/.test(event.key) && event.preventDefault()}
                onChange={(event) => this.handleInput(event.target.value, "max_aligner_number")}
                margin="normal"
              />
            </Grid>
          ) }

          <Grid item xs={12} sm={12}>
            <p>Select smile 3D view file upload method</p>
            <div>
              <input type="radio" id="url" name="select_upload_method" value="select_url"
                onChange={(event) => this.handleRadioButtonChange(event.target.value)}
              />
              <label htmlFor="url">url</label>
            </div>
            <div>
              <input type="radio" id=".myo" name="select_upload_method" value="select_myo"
                defaultChecked
                onChange={(event) => this.handleRadioButtonChange(event.target.value)}
              />
              <label htmlFor=".myo">.myo/.myp</label>
            </div>
          </Grid>

          <Grid item xs={12} sm={6} id = "myo_grid" hidden={false} >
            {this.props.treatmentPlan.myo_pid == null?
              <DragAndDrop handleDrop={(uploadedFile) => this.handleMyo(uploadedFile)}>
                <div className={style.imageUploadArea}>
                  {this.state.loadingMyo ? <ModalLoader height={200} hideLogo={true} loadingMessage={"Processing, please wait..."}/> : <span>{this.get('treatmentPlan.dragAndDrop')}</span>}
                  <div className={style.uploadBtnWrapper}>
                    <button className={style.uploadBtn}>+</button>
                    <input
                      type="file"
                      onChange={(e) => this.onMyoFileChange(e)}
                      name="patientImage"
                      id="patientImage"
                    />
                  </div>
                </div>
              </DragAndDrop> :
              <DragAndDrop handleDrop={(uploadedFile) => this.handleMyo(uploadedFile)}>
                <div className={style.imageUploadArea}>
                  <button className={style.remove} onClick={() => {
                    this.props.treatmentPlan.myo_oid = null;
                    this.props.treatmentPlan.myo_pid = null;
                    this.props.treatmentPlan.myo_id = null;
                    this.props.treatmentPlan.myo_token = null;
                    this.props.treatmentPlan.smile_3d = "~pending upload~";
                    this.handleInput("~pending upload~", "smile_3d");
                  }}><DeleteOutlinedIcon /></button>
                </div>
              </DragAndDrop>}
            <p style={{ textAlign: "center", color: "rgba(0,0,0,0.3)" }}>({this.get('treatmentPlan.myoFile')})</p>
          </Grid>

          <Grid item xs={12} sm={12} id = "Smile_3D_Grid" >
            <TextField
              disabled={true}
              style={{ width: "80%" }}
              InputProps={
                !this.props.treatmentPlan.myo_token ? {style: {color: "black"}} : {style: {color: "black", fontWeight: "bold"}}
              }
              id="3durl"
              label="Smile 3D View(Url)"
              value={this.props.treatmentPlan.smile_3d}
              onChange={(event) => this.handleInput(event.target.value, "smile_3d")}
              onClick={() => {
                //if(this.props.treatmentPlan.smile_3d.includes(process.env.APP_3D_ENV_API_SERVER)){
                  window.open(this.props.treatmentPlan.smile_3d);
                //}
              }}
              margin="normal"
            />
          </Grid>
        </Grid>
      </Animated>
    );
  }
}

const mapStateToProps = store => {
  return {
    lang: store.lang
  }
};

export default connect(mapStateToProps)(withLang(TreatmentPlan, 'PatientInfo'));
