import React, { useState, PropTypes } from 'react';
import Grid from '@material-ui/core/Grid';
import NewPatientPhoto from './NewPatientPhoto'
import DragAndDrop from '../Photos/DragAndDrop'
import style from './NewPatientPhotos.module.css'
import * as actionTypes from '../../../store/NewPatient/actionType';
import { connect } from 'react-redux';
import HighlightOffSharpIcon from '@material-ui/icons/HighlightOffSharp';
import { useLang } from '../../../util/lang';

const Radiograph = (props) => {
  //let [cbctfiles,handleCbctFile] = useState([])

  const get = useLang('NewPatient')

  let { case_type } = props

  function fileupload(event) {
    let filename = []
    event.target.files.forEach(file => {
      props.handleCbctFile(file, file.name)
    })
    // for (let i = 0; i < event.target.files.length; i++) {
    //   // filename = [...filename,event.target.files[i].name]
    //   // handleCbctFile(filename)
    //   let currentFileName = event.target.files[i].name
    //   let baseFile = fileToBase64(event.target.files[i]).then((data) => {
    //     props.handleCbctFile(data, currentFileName)
    //   })
    // }
  }

  function fileuploadDrag(files) {
    let filename = []
    if (Array.isArray(files)) {
      files.forEach(file => {
        props.handleCbctFile(file, file.name)
      })
      // for (let i = 0; i < files.length; i++) {
      //   // filename = [...filename,files[i].name]
      //   // handleCbctFile(filename)
      //   let currentFileName = files[i].name

      //   let baseFile = fileToBase64(files[i]).then((data) => {
      //     props.handleCbctFile(data, currentFileName)
      //   })
      // }
    }
    else {
      // filename = [...filename,files.name]
      // handleCbctFile(filename)
      props.handleCbctFile(files, files.name)
      // let currentFileName = files.name
      // let baseFile = fileToBase64(files).then((data) => {
      //   props.handleCbctFile(data, currentFileName)
      // })
    }

  }

  // function fileToBase64(file) {
  //   let fileData = new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     if (file !== undefined) {
  //       reader.readAsDataURL(file);
  //     }
  //     reader.onload = () => resolve(reader.result);
  //     reader.onerror = error => reject(error);
  //   });

  //   return fileData
  // }


  function handleDrop(uploadfiles) {
    fileuploadDrag(uploadfiles)
  }

  function removeFile(indexVal) {
    // let newcbctFile = [...cbctfiles]
    // let modifiedFile = newcbctFile.splice(indexVal,1)
    // handleCbctFile(newcbctFile)
    props.handleRemoveCbctFile(indexVal)
  }

  return (
    <Grid container spacing={1} className={style.twoColumnImage}>
      <Grid item xs={12} sm={4}>
        <li>
          <NewPatientPhoto
            mode={props.mode}
            type="radio"
            imageId="front_with_smile"
            imageUploaded={props.imageUploaded("front_with_smile")}
            imageData={props.imageData("front_with_smile")}
            label={get(':uploadType.uploadPhotoLater')}
            labelSecondary="Will not submit"
            imageName="bg_xray1.png"
            mandatoryImage={true}
            will_not_upload={props.parent === 'refinement' ? true : false}
            will_not_upload_label={get(":uploadType.willNotUploadXray")}
            imageLabel={`${get('radiographs.orthopantomogram')} - ${get(':uploadType.mandatory')}`}
            labelUnderImage=" "
            handleRoatate={(degree) => { props.handleRoatate("front_with_smile", degree) }}
            handleChange={(file) => { props.handleChange("front_with_smile", file) }} />
        </li>
      </Grid>
      <Grid item xs={12} sm={4}>
        <li>
          <NewPatientPhoto
            mode={props.mode}
            type="radio"
            imageId="side_with_smile"
            imageUploaded={props.imageUploaded("side_with_smile")}
            imageData={props.imageData("side_with_smile")}
            label={get(':uploadType.uploadXrayLater')}
            imageName="bg_xray2.png"
            uploadLater={props.uploadLater}
            will_not_upload={true}
            will_not_upload_label={get(":uploadType.willNotUploadXray")}
            imageLabel={`${get('radiographs.lateralCephalogram')} - ${get(':uploadType.stronglyRecommended')}`}
            labelUnderImage=" "
            handleRoatate={(degree) => { props.handleRoatate("side_with_smile", degree) }}
            handleChange={(file) => { props.handleChange("side_with_smile", file) }} />
        </li>
      </Grid>
      <Grid item xs={12} sm={4}>
        <li>
          <NewPatientPhoto
            mode={props.mode}
            type="radio"
            imageId="cbct"
            imageUploaded={props.imageUploaded("cbct")}
            imageData={props.imageData("cbct")}
            label={get(':uploadType.uploadXrayLater')}
            imageName="bg_xray1.png"
            imageLabel={`${get('radiographs.cbct_dicom')} - ${get(':uploadType.optional')}`}
            labelUnderImage="FOV 16(W)X10(H)"
            handleRoatate={(degree) => { props.handleRoatate("cbct", degree) }}
            handleChange={(file) => { props.handleChange("cbct", file) }}
          />
        </li>
      </Grid>
      {
        case_type && case_type.toLowerCase() === 'cbct' &&
        <Grid item xs={12} sm={4}>
          {props.cbctFiles.length == 0 ?
            <DragAndDrop handleDrop={handleDrop} disabled={props.mode}>
              <div className={style.imageUploadArea}>
                <span>{get('radiographs.uploadCbct')} </span>
                <div className={style.uploadBtnWrapper}>
                  <button className={style.uploadBtn}>+</button>
                  <input
                    type="file"
                    onChange={fileupload}
                    name="patientcbctImage"
                    id="cbctImage"
                    multiple
                  />
                </div>
              </div>
            </DragAndDrop> :
            <DragAndDrop handleDrop={handleDrop} disabled={props.mode}>
              <div className={style.imageUploadArea}>
                {
                  props.cbctFiles.map((v, index) => (
                    <p key={index} className={style.cbctimageName}>{v.name} <span className={style.cbctimageRemove} onClick={() => removeFile(index)}><HighlightOffSharpIcon /></span></p>
                  ))
                }
              </div>
            </DragAndDrop>
          }
        </Grid>
      }
    </Grid>
  );
};

const mapStateToProps = store => {
  return {
    cbctFiles: store.newPatientReducer.cbctFiles,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    handleCbctFile: (value, fileName) => dispatch({ type: actionTypes.NEW_PATIENT_CBCT, value: value, name: fileName }),
    handleRemoveCbctFile: (value) => dispatch({ type: actionTypes.NEW_PATIENT_REMOVE_CBCT, value: value })
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Radiograph);
