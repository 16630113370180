import React, { useState, useEffect, forwardRef, useCallback } from 'react';
import { createMuiTheme, MuiThemeProvider, makeStyles, Paper, Breadcrumbs, Typography, Button, Grid } from '@material-ui/core'
import MaterialTable from 'material-table'
import axios from '../../../axios-default'
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { getFromObj, objToDict } from '../../../util/lang'

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { withRouter } from 'react-router-dom';
import { grey } from '@material-ui/core/colors';
import { useDispatch } from 'react-redux';
import { loadConfig } from '../../../store/config/config.action'

const TableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
}

const customTheme = createMuiTheme({
  overrides: {
    MuiPickersBasePicker: {
      pickerView: {
        backgroundColor: "white"
      }
    },
    MuiPickersDay: {
      day: {
        color: "light-gray",
        fontFamily: "\"Do Hyeon\", sans-serif",
        backgroundColor: "white",
        borderRadius: "0px",
      },
      container: {
        backgroundColor: "black"
      },
      daySelected: {
        backgroundColor: "",
        color: "light-gray"
      },
      dayDisabled: {
        color: "black",
      },
      current: {
        color: "",
      },
    },
  },
});

const useTranslationStyles = makeStyles(theme => ({
  breadcrumbs: {
    margin: 10,
    marginBottom: 5,
    padding: 10,
    paddingBottom: 0
  },
  paper: {
    width: '100%',
    boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.3)'
  },
  subDir: {
    textTransform: 'none'
  },
  subLabel: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}))

function Translation(props) {
  const classes = useTranslationStyles()

  const dispatch = useDispatch()
  const [dir, setDir] = useState({ isoCode: undefined, topic: undefined }),
        [langList, setLangList] = useState([]),
        [tableState, setTableState] = useState({
          columns: [],
          data: []
        }),
        [loading, setLoading] = useState(true)

  useEffect(() => {
    init()
  }, [])

  const init = async isReloading => {
    try {
      const list = (await axios.get('lang/list')).data.lang.map(v =>
        Object.assign(v, { name: v.lang_iso_code || 'default', isObject: true })
      )
      setLangList(list)
      if (isReloading) {
        updateDir(dir.isoCode, dir.topic, list)
      } else {
        updateDir(undefined, undefined, list)
      }
      setLoading(false)
    } catch (err) {
      if (err.response && err.response.statusText === 'Unauthorized') {
        localStorage.removeItem("auth-token");
        localStorage.removeItem("expirationDate");
        localStorage.removeItem("role");
        props.history.push("/login")
      }
    }
  }

  const getPath = () => {
    const ret = []
    if (dir.isoCode !== undefined) {
      if (dir.topic === undefined) {
        ret.push(<Typography key={-2} className={classes.subLabel}>{dir.isoCode === null? 'default': dir.isoCode}</Typography>)
      } else {
        ret.push(
          // <Link
          //   key={-1}
          //   component="button"
          //   variant="body2"
          //   onClick={() => updateDir(dir.isoCode, undefined)}
          // >
          //   {dir.isoCode === null? 'default': dir.isoCode}
          // </Link>
          (
            <Button
              key={-1}
              className={classes.subDir}
              onClick={() => updateDir(dir.isoCode, undefined)}
            >
              {dir.isoCode === null? 'default': dir.isoCode}
            </Button>
          ),
          (<Typography className={classes.subLabel} key={-2}>{dir.topic}</Typography>)
        )
        // dir.path.split('.').reduce((a, d, i, arr) => {
        //   const curDir = a.length? [a, d].join('.') : d
        //   if (i !== arr.length - 1) {
        //     ret.push(
        //       <Button
        //         key={i}
        //         className={classes.subDir}
        //         component="button"
        //         variant="body2"
        //         onClick={() => updateDir(dir.isoCode, curDir)}
        //       >
        //         {d}
        //       </Button>
        //     )
        //   } else {
        //     ret.push(<Typography key={-2} className={classes.subLabel}>{d}</Typography>)
        //   }
        //   return curDir
        // }, '')
      }
    }
    return ret
  }
  const getEditable = () => {
    if (dir.isoCode !== undefined) {
      return {
        isEditHidden: data => data.isObject,
        isDeleteHidden: _ => true,
        onRowUpdate: async newData => {
          const updatedObj = {},
                keys = [dir.topic, newData.key].join('.').split('.')
          let { prevObj: target } = keys.reduce((a, k) => {
            const { curObj } = a
            curObj[k] = {}
            return { curObj: curObj[k], prevObj: curObj }
          }, { curObj: updatedObj, prevObj: null })
          target[keys[keys.length-1]] = newData.value
          await axios.post('lang', { lang_iso_code: dir.isoCode, lang: JSON.stringify(updatedObj)})
          await dispatch(loadConfig())
          setLoading(true)
          init(true)
        },
        // onRowDelete: data => {
        //   console.log(data)
        // }
      }
    } else {
      return {
        isDeleteHidden: data => data.lang_iso_code === null,
        isEditHidden: data => data.isObject,
        onRowAdd: async data => {
          await axios.post('lang', { lang_iso_code: data.name.trim(), lang: null })
          await dispatch(loadConfig())
          setLoading(true)
          init(true)
        },
        onRowDelete: async data => {
          await axios.delete(`lang/${data.lang_iso_code}`)
          await dispatch(loadConfig())
          setLoading(true)
          init(true)
        }
      }
    }
  }
  const updateDir = (isoCode, topic, _langList) => {
    const list = _langList || langList || []
    setDir({ isoCode, topic })
    if (isoCode !== undefined) {
      const targetObj = getFromObj((list.find(i => i.lang_iso_code === isoCode)||{json:{}}).json, topic||'')
      if (topic === undefined) {
        setTableState({
          columns: [
            { title: 'Field', field: 'key', editable: 'never' },
            { title: 'Value', field: 'value' }
          ],
          data: targetObj? Object.entries(targetObj).map(([key, value]) => ({
            key,
            value: typeof value === 'object'? '' : value,
            isObject: typeof value === 'object'
          })) : []
        })
      } else {
        setTableState({
          columns: [
            { title: 'Field', field: 'key', editable: 'never' },
            { title: 'Value', field: 'value' }
          ],
          data: targetObj? Object.entries(objToDict(targetObj)).map(([key, value]) => ({
            key,
            value: typeof value === 'object'? '' : value
          })) : []
        })
      }
    } else {
      setTableState({
        columns: [
          { title: 'Lang ISO Name', field: 'name' }
        ],
        data: list
      })
    }
  }
  const handleFieldSelect = (_, data) => {
    if (dir.isoCode !== undefined) {
      if (data.isObject) {
        updateDir(dir.isoCode, dir.topic? [dir.path, data.key].join('.') : data.key)
      }
    } else {
      updateDir(data.lang_iso_code, undefined)
    }
  }

  return (
    <MuiThemeProvider theme={customTheme}>
      <Paper className={classes.paper}>
        <MaterialTable
          isLoading={loading}
          title={
            <Grid container>
              <Grid item xs={12}>
                <Breadcrumbs className={classes.breadcrumbs} separator={<NavigateNextIcon fontSize="small" />} aria-label="path">
                  {/* <Link
                    component="button"
                    variant="body2"
                    onClick={() => updateDir(undefined, undefined)}
                  >
                    Lang
                  </Link> */}
                  <Button
                    variant="outlined"
                    onClick={() => updateDir(undefined, undefined)}
                  >
                    Lang
                  </Button>
                  {getPath()}
                </Breadcrumbs>
              </Grid>
              <Grid item xs={12} style={{margin: 10}}>
                <Typography variant='body2' style={{color: grey[700]}}>Please do not remove the token (e.g. {'{{'}plan_no{'}}'})</Typography>
              </Grid>
            </Grid>
          }
          icons={TableIcons}
          columns={tableState.columns}
          data={tableState.data}
          editable={getEditable()}
          onRowClick={handleFieldSelect}
          options={{
            search: false,
            actionsColumnIndex: -1,
            pageSize: 10,
            pageSizeOptions: [10, 20, 50, 100]
          }}
        />
      </Paper>
    </MuiThemeProvider>
  )
}

export default withRouter(Translation)