import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import App from './App';
import './index.css';
import * as myServiceWorker from './myServiceWorker';
import authReducer from './store/Auth/Auth.reducer';
import configReducer from './store/config/config.reducer';
import langReducer from './store/lang/lang.reducer';
import loadingReducer from './store/loading/reducer';
import newAddressReducer from './store/NewAddress/NewAddress.reducer';
import newPatientReducer from './store/NewPatient/NewPatient.reducer';
import notiReducer from './store/Noti/noti.reducers';
import resReducer from './store/res/res.reducer';
import updatingAccountReducer from './store/UpdatingAccount/UpdatingAccount.reducer';
import { SubPath } from './config.json'


const reducers = combineReducers({
  newPatientReducer: newPatientReducer,
  newAddressReducer: newAddressReducer,
  auth: authReducer,
  lang: langReducer,
  config: configReducer,
  loading: loadingReducer,
  updatingAccount: updatingAccountReducer,
  res: resReducer,
  noti: notiReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'NEW_PATIENT_DATA_SAVE_SUCCESS') {
    state = undefined
  }

  return reducers(state, action)
}

const store = createStore(rootReducer, applyMiddleware(thunk));

ReactDOM.render(
  <SnackbarProvider
    maxSnack={5}
    disableWindowBlurListener
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
  >
    <Provider store={store}>
      <Router basename={SubPath}>
        <App />
      </Router>
    </Provider>
  </SnackbarProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
myServiceWorker.init();
