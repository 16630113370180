export const NEW_PATIENT_DELIVERY_FIRST_NAME = 'NEW_PATIENT_DELIVERY_FIRST_NAME';
export const NEW_PATIENT_DELIVERY_LAST_NAME = 'NEW_PATIENT_DELIVERY_LAST_NAME';
export const NEW_PATIENT_DELIVERY_ADDRESS = 'NEW_PATIENT_DELIVERY_ADDRESS';
export const NEW_PATIENT_DELIVERY_ADDRESS_ID = 'NEW_PATIENT_DELIVERY_ADDRESS_ID';
export const NEW_PATIENT_DELIVERY_ADDRESS_LINE = 'NEW_PATIENT_DELIVERY_ADDRESS_LINE';
export const NEW_PATIENT_DELIVERY_ADDRESS_COUNTRY = 'NEW_PATIENT_DELIVERY_ADDRESS_COUNTRY';
export const NEW_PATIENT_DELIVERY_ADDRESS_STATE = 'NEW_PATIENT_DELIVERY_ADDRESS_STATE';
export const NEW_PATIENT_DELIVERY_ADDRESS_CITY = 'NEW_PATIENT_DELIVERY_ADDRESS_CITY';
export const NEW_PATIENT_DELIVERY_ADDRESS_ZIPCODE = 'NEW_PATIENT_DELIVERY_ADDRESS_ZIPCODE';
export const NEW_PATIENT_DELIVERY_ADDRESS_PHONE = 'NEW_PATIENT_DELIVERY_ADDRESS_PHONE';
export const NEW_PATIENT_DELIVERY_ADDRESS_FAX = 'NEW_PATIENT_DELIVERY_ADDRESS_FAX';
export const NEW_PATIENT_DELIVERY_ADDRESS_INIT = 'NEW_PATIENT_DELIVERY_ADDRESS_INIT';
