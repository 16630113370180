import {
  CONFIG_RELOAD,
  CONFIG_UPDATE
} from './actionType'

const initialState = {
  isInit: false,
  config: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CONFIG_RELOAD:
      switch (typeof action.payload) {
        case 'string':
          action.payload = JSON.parse(action.payload)
          break

        case 'object':
          break

        default:
          throw new Error('Cannot identify what the config object is')
      }
      return {
        ...state,
        isInit: true,
        config: action.payload
      }
    case CONFIG_UPDATE:
      return {
        ...state,
        config: {
          ...state.config,
          ...action.payload
        }
      }
    default:
      return state
  }
}