import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILURE,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  FETCH_USER_FAILURE,
  FETCH_USER_SUCCESS,
  LOGOUT,
  FORGOT_SUCCESS,
  FORGOT_FAILURE,
} from './actionType';

const initialState = {
  status: '',
  user: null,
  error: false,
  isAuthenticated: null,
  isAuthenticatedText: "",
  registeredStatus: false,
  registeredStatusText: "",
  passwordUpdateSuccessText: "",
  passwordUpdateFailureText: "",
  loggedOut: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      if (state.user == action.payload.user.id && state.isAuthenticated) {
        return state
      }
      return {
        ...state,
        status: 'SUCCESS',
        user: action.payload.user.id,
        isAuthenticated: true
      };
    case LOGIN_FAILURE: return {
      ...state,
      status: 'FAILED',
      error: true,
      isAuthenticated: false,
      isAuthenticatedText: action.payload
    };
    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordUpdateSuccessText: 'Your Password Was Updated Successfully',
        passwordUpdateFailureText: ""
      };
    case UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        passwordUpdateFailureText: action.payload
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        status: 'SUCCESS',
        registeredStatus: true,
        registeredStatusText: "You have been registered successfully"
      };
    case REGISTER_FAILURE: return {
      ...state,
      status: 'FAILED',
      registeredStatus: false,
      // registeredStatusText: "Sorry,something went wrong you were not registered"
      registeredStatusText: action.payload
    };
    case FORGOT_FAILURE:
      return {
        ...state,
        status: 'FAILED',
        error: action.payload
      };
    case FORGOT_SUCCESS:
      return {
        ...state,
        user: action.payload,
        status: 'SUCCESS'
      };

    case FETCH_USER_FAILURE:
      return {
        ...state,
        status: 'FAILED',
        error: action.payload
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        isAuthenticated: true
      };

    case LOGOUT:
      return {
        ...state,
        user: null,
        isAuthenticated: false
      };

    default:
      return state;
  }
};

export default authReducer;
