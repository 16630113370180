import '../../../util/array';
import './3DViewer.css';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Draggable from 'react-draggable';

import {
    Backdrop, Box, Button, CircularProgress, createMuiTheme, Slider, FormControl, Grid, IconButton,
    makeStyles, MenuItem, Select, Tooltip, Typography, withStyles
} from '@material-ui/core';
import { Pause, PlayArrow, SkipNext, SkipPrevious } from '@material-ui/icons';
import DescriptionIcon from '@material-ui/icons/Description';

import { safeGet } from '../../../axios-default';
import Chat from '../../chat/chat_new';
import Viewer from './Viewer';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useParams } from 'react-router-dom';
import { ApiServerUrl } from '../../../config.json'
import { easySnackbar } from '../../../store/Noti/noti.actions';
import { useDispatch } from 'react-redux';
import frontend_config from '../../../frontend_config';
import { getWebAbsPath } from '../../../util/path';

const hostname = window.location.hostname

const Viewer3DStyles = makeStyles(theme => ({
  container: {
    backgroundColor: '#323639',
    // padding: 3,
    // height: '100vh',
    color: 'white',
    overflowY: 'hidden',
    minWidth: 800
  },
  topNav1: {
    width: "100%",
    display: "flex",
    padding: "4px 0px",
    height: 36
  },
  topNav1LogoContainer: {
    display: "flex",
    width: 82,
    alignItems: "center",
    marginRight: 12,
    paddingRight: 12,
    borderRight: "solid 2px rgba(120,92,191,1)"
  },
  topNav1TpSelect: {
    width: '100%',
    textAlign: 'center'
  },
  topNav1Control: {
    fontSize: 14,
    color: 'white'
  },
  topNav1Logo: {
    width: "100%",
    height: "auto"
  },
  topNav2: {
    padding: 5,
    paddingTop: 8
  },
  footer: {
    position: "fixed",
    width: "100%",
    bottom: 0,
    background: "#232323",
    height: 58,
    paddingTop: 6
  },
  controlContainer: {
    textAlign: 'center',
    borderRadius: 4,
    margin: "0px 2px",
    '&:hover': {
      backgroundColor: "#ffffff55"
    }
  },
  controlButtonLabel: {
    flexDirection: 'column',
    color: 'white'
  },
  controlButtonLabelTypography: {
    flexDirection: 'column'
  },
  controlImg: {
    width: 18,
    height: 18,
    marginBottom: 4
  },
  canvas: {
    // height: 'calc(100vh - 40px - 60px - 36px)',
    // height: 'calc(100vh - 54px - 4px - 78px)',
    // backgroundColor: '#fff',
    overflowY: window.innerWidth < 960 && "scroll",
    height: window.innerWidth < 960 && "calc(100vh - 136px)"
  },
  divider: {
    backgroundColor: 'gray'
  },
  videoControlContainer: {
    display: "flex",
    alignItems: "center"
  },
  videoControlBtn: {
    color: 'white',
    padding: 6
  },
  speedSelect: {
    color: 'white',
    minWidth: 60
  },
  rangeSliderContainer: {
    position: 'relative',
    flex: '1 1 auto',
    paddingLeft: 5,
    paddingRight: 5,
    height: '100%'
  },
  rangeButtonContainer: {
    display: "flex",
    overflowX: "scroll"
  },
  rangeButton: {
    // width: '100%',
    width: 20,
    height: '100%',
    padding: 8,
    borderRadius: 0
  },
  rangeButtonLabel: {
    height: '100%',
    padding: 2
  },
  rangeButtonIcon: {
    borderRadius: 50,
    border: "solid 1px white",
    width: "100%",
    fontSize: "smaller",
    color: "white"
  },
  rangeButtonSelected: {
    backgroundColor: '#848486'
  },
  fullHeight: {
    height: '100%'
  },
  controllerButton: {
    '&hover': {
      backgroundColor: "#ffffff55"
    }
  },
  hide: {
    display: 'none'
  },
  focus: {
    backgroundColor: 'grey'
  }
}))

const CustomCaseSlider = withStyles({
  mark: {
    backgroundColor: '#bfbfbf',
    height: 12,
    width: 2,
    marginTop: -3,
  },
  markLabel: {
    color: "white"
  },
  markLabelActive:  {
    color: "white"
  },
  track: {
    height: 4,
  },
  valueLabel: {
    // '& *': {
    //   background: 'transparent',
    //   color: 'red',
    // },
  },
})(Slider);


// TODO: for testing only
// const caseId = 4048

const Widths = [4, 6, 8, 12]

const Viewer3D = () => {
  const classes = Viewer3DStyles()
  const dispatch = useDispatch()
  const { id: caseId } = useParams()

  const [speed, setSpeed] = useState(1.0),
        [step, setStep] = useState(0),
        [occList, setOccList] = useState([]),
        [gltfList, setGltfList] = useState([]),
        [planNo, setPlanNo] = useState(null),
        [curPlanNo, setCurPlanNo] = useState(null),
        [planList, setPlanList] = useState([]),
        // [ipr, setIpr] = useState([]),
        [progress, setProgress] = useState(0),
        [loading, setLoading] = useState(true),
        [playing, setPlaying] = useState(false),
        [numOfSteps, setNumOfSteps] = useState(0),
        [isFailed, setIsFailed] = useState(false)
  // chatroom
  const [chatDraggable, setChatDraggable] = useState(false),
        [chatFullscreen, setChatFullscreen] = useState(false),
        [chatroomWidth, setChatroomWidth] = useState(4),
        [simulationWidth, setSimulationWidth] = useState(8),
        [hideChatTabs, setHideChatTabs] = useState(false),
        [hideSimulation, setHideSimulation] = useState(false),
        [chatDragPositioned, setChatDragPositioned] = useState(false)
  const container3D = useRef(null),
        viewer = useRef(null),
        iprBtn = useRef(null),
        gridBtn = useRef(null),
        superBtn = useRef(null),
        speedSelect = useRef(null),
        component3d = useRef({
          ambientLight: null,
          spotLightCamera: null,
          upperAsm: null,
          lowerAsm: null,
          VertexMat: null
        }),
        isLoaded = useRef(false)

  useEffect(() => {
    ;(async () => {
      const { simulation } = await safeGet(`case/simulation/${caseId}`)
      setPlanList(simulation ?? [])
      if (simulation?.length) {
        setPlanNo(0)
      }
    })()

    viewer.current = new Viewer({
      containerElem: container3D.current,
      iprBtnElem: iprBtn.current,
      gridBtnElem: gridBtn.current,
      superBtnElem: superBtn.current
    })

    return () => {
      if (viewer.current) {
        viewer.current.destroy()
      }
    }
  }, [])

  useEffect(() => {
    if (viewer.current && setStep) {
      viewer.current.onStepChanged = stepNo => setStep(stepNo)
    }
  }, [viewer, setStep])

  useEffect(() => {
    ;(async () => {
      if (typeof planNo === 'number' && planNo >= 0) {
        const tmpSimFiles = planList[planNo]
        if (tmpSimFiles && tmpSimFiles.length) {
          const _gltfList = tmpSimFiles.filter(({ type }) => type === 'JAW'),
                _occList = tmpSimFiles.filter(({ type }) => type === 'OCC'),
                ipr = tmpSimFiles.find(({ type }) => type === 'IPR')
          setGltfList(_gltfList)
          setOccList(_occList)
          const size = tmpSimFiles.reduce((acc, { model_no, type }) => {
            if (type === 'JAW' && acc < model_no) {
              return model_no
            }
            return acc
          }, 0)

          try {
            await viewer.current.importGltfFromWeb(_gltfList, _occList, (cur, max) => setProgress(Math.round(cur/max*100)))
          } catch (err) {
            console.trace(err)
            dispatch(easySnackbar('Cannot load 3d simulation files', 'error'))
            return
          }

          setNumOfSteps(parseInt(size))
          if (ipr) {
            try {
              const iprObj = await safeGet(ipr.file, {
                baseURL: ApiServerUrl
              })
              viewer.current.importIpr(iprObj)
            } catch (err) {
              console.trace(err)
            }
          }

          setLoading(false)
        }
      }
    })()
  }, [planNo, planList, viewer])

  // useEffect(() => {
  //   if (
  //     isLoaded.current || !container3D.current
  //     || !iprBtn.current || !gridBtn.current
  //     || !numOfSteps
  //   ) return
  //   isLoaded.current = true
  //   ;(async () => {
  //     viewer.current = new Viewer({
  //       containerElem: container3D.current,
  //       iprBtnElem: iprBtn.current,
  //       gridBtnElem: gridBtn.current,
  //       superBtnElem: superBtn.current
  //     })
  //     try {
  //       await viewer.current.importGltfFromWeb(gltfList, occList, (cur, max) => setProgress(Math.round(cur/max*1000)/10.))
  //     } catch (err) {
  //       console.trace(err)
  //       dispatch(easySnackbar('Cannot load 3d simulation files', 'error'))
  //       return
  //     }
  //     viewer.current.onStepChanged = stepNo => setStep(stepNo)
  //     setLoading(false)
  //   })()
  // }, [container3D, setStep, setProgress, planList, gltfList, occList, numOfSteps, setCurPlanNo, curPlanNo])

  // ---Old buttons for steps---
  // const sliderButtons = useMemo(() => {
  //   const maxStep = gltfList.reduce((acc, gltf) => Math.max(gltf.model_no, acc), 0)
  //   const btns = []
  //   for (let i = 0; i < maxStep; i++) {
  //     btns.push(
  //       <Button
  //         key={i}
  //         classes={{ root: classes.rangeButton }}
  //         onClick={() => {
  //           viewer.current.setStep(i)
  //           setStep(i)
  //         }}
  //         style={{
  //           backgroundColor: i === step ? "#848486" : ""
  //         }}
  //       >
  //         <div className={classes.rangeButtonIcon}>{i}</div>
  //         {/* step 0 should be before using aligner */}
  //       </Button>
  //     )
  //   }
  //   return btns
  // }, [gltfList, step])

  const sliderSteps = useMemo(() => {
    const maxStep = gltfList.reduce((acc, gltf) => Math.max(gltf.model_no, acc), 0)
    const marks = []
    for (let i = 0; i < maxStep; i++) {
      if(i % 6 === 0 || i === maxStep - 1) {
        marks.push(
          {
            value: i,
            label: i
          }
        )
      }
      else {
        marks.push(
          {
            value: i
          }
        )
      }
    }
    return(
      <CustomCaseSlider
        defaultValue={0}
        getAriaValueText={value => value}
        aria-labelledby="case-steps"
        valueLabelFormat={value => value}
        step={1}
        marks = {marks}
        min={0}
        value={step}
        valueLabelDisplay={step === 0 || step === maxStep - 1 ? "auto" : "on"}
        max={maxStep - 1}
        onChange={(e, value) => {
          viewer.current.setStep(value)
          setStep(value)
        }}
      />
    )
  }, [gltfList, step])

  const planItems = useMemo(() =>
    planList.map((_, i) => (
      <MenuItem value={i} key={i}>Treatment Simulation {i + 1}</MenuItem>
    ))
  , [planList, setPlanNo])

  const handleControlClicked = useCallback(id => () => viewer.current?.control?.(id), [viewer])
  const handleOcclusionClicked = useCallback(() => viewer.current?.showOcclusion(), [viewer])

  // chatroom
  const handleSecondChatroomVisibility = useCallback(show => {
    if (show) {
      setHideChatTabs(true)
      setSimulationWidth(Widths[0])
      setChatroomWidth(Widths[0])
      setImmediate(() => viewer.current.onWindowResize())
    } else {
      setHideChatTabs(false)
      setSimulationWidth(Widths[2])
      setChatroomWidth(Widths[0])
      setImmediate(() => viewer.current.onWindowResize())
    }
  }, [setHideChatTabs, setSimulationWidth, setChatroomWidth])
  const handleVerticalSplit = useCallback(() => {
    setHideSimulation(hideSimulation => !hideSimulation)
    if (chatroomWidth === Widths[0]) {
      setChatroomWidth(Widths[1])
    } else {
      setChatroomWidth(Widths[0])
    }
  }, [setHideSimulation, setChatroomWidth, chatroomWidth])
  const dragableChatroom = useCallback(() => {
    setChatDraggable(d => !d)
    setSimulationWidth(Widths[3])
    if (chatroomWidth === 0) {
      setSimulationWidth(Widths[2])
      setChatroomWidth(Widths[0])
    }
    if (chatroomWidth === Widths[0]) {
      setChatroomWidth(0)
    }
    setChatDragPositioned(dp => !dp)
  })
  const handleFullscreen = useCallback(() => {
    setChatFullscreen(fc => !fc)
  }, [setChatFullscreen])

  return (
    <Grid item container className={classes.container} alignItems="flex-start">
      <Backdrop open={loading} style={{zIndex: 99999}}>
        {/* <CircularProgress color="inherit" variant="determinate" value={progress} /> */}
        <Box position="relative" display="inline-flex">
          <CircularProgress variant="static" size={60} color="inherit" value={progress} />
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {isFailed? <Typography variant="body1">Failed to load 3d Simulation files</Typography> : <Typography variant="body1">{progress}%</Typography>}
            {/* <Typography variant="body1">{label}</Typography> */}
          </Box>
        </Box>
      </Backdrop>

      {/* Top Bar */}
      <div className={classes.topNav1}>
        <div className={classes.topNav1LogoContainer}>
          <img src={frontend_config?.[hostname]?.white_logo_path} className={classes.topNav1Logo}/>
        </div>
        <div>
          <Select
            label="Treatment Plan"
            value={planNo}
            IconComponent={() => (
              <ExpandMoreIcon style={{color: "white"}}/>
            )}
            variant="outlined"
            className={`${classes.topNav1Control} ${classes.topNav1TpSelect}`}
            // SelectDisplayProps={{
            //   padding: "8px 32px 8px 8px"
            // }}
            onChange={({ target: { value } }) => setPlanNo(value)}
            id="txPlanSelect"
          >
            {planItems}
            {/* <MenuItem value='test1'>Treatment Plan 1</MenuItem> */}
            {/* <MenuItem value={3}>Treatment Plan 3</MenuItem> */}
          </Select>
        </div>

        {
          window.innerWidth > 900 ?
          <Button
            variant="contained"
            style={{backgroundColor: "#323639", color: "white", marginLeft: 4}}
            size="small"
            className={classes.button}
            startIcon={<DescriptionIcon style={{color: "white"}}/>}
          >
            Treatment Plans
          </Button>
          :
          <IconButton color="secondary" aria-label="add an alarm">
            <DescriptionIcon style={{color: "white"}}/>
          </IconButton>
        }
      </div>

      {/* Gradient divider */}
      <Grid item container xs={12} justify='flex-start'
        style= {{
          height: 4,
          background: "linear-gradient(34deg, rgba(120,92,191,1) 39%, rgba(13,131,207,1) 100%)"
        }}
      >
      </Grid>

      {/* Controller buttons */}
      <div
        className="sliderBtnsTop"
        style={{
          justifyContent: window.innerWidth > 900 ? "center" : "flex-start"
        }}
      >
        {/* <Button onClick={handleControlClicked('bothArch')} classes={{ label: classes.controlButtonLabel }}>
          <img className={classes.controlImg} src={getWebAbsPath("/img/stl-viewer/bothArch.png")} value="Both Arch"/>
          <Typography className={classes.controlButtonLabelTypography} variant="caption">Both</Typography>
        </Button> */}
        <Tooltip title="Upper Arch">
          <Button onClick={handleControlClicked('upperArch')} classes={{ label: classes.controlButtonLabel }}>
            <img className={classes.controlImg} src={getWebAbsPath("/img/stl-viewer/upperArch.png")} value="Upper Arch" />
            <Typography className={classes.controlButtonLabelTypography} variant="caption">Upper</Typography>
          </Button>
        </Tooltip>
        <Tooltip title="Lower Arch">
          <Button onClick={handleControlClicked('lowerArch')} classes={{ label: classes.controlButtonLabel }}>
            <img className={classes.controlImg} src={getWebAbsPath("/img/stl-viewer/lowerArch.png")} value="Lower Arch" />
            <Typography className={classes.controlButtonLabelTypography} variant="caption">Lower</Typography>
          </Button>
        </Tooltip>
        <Tooltip title="Maxillary Occlusal">
          <Button onClick={handleControlClicked('TopView')} classes={{ label: classes.controlButtonLabel }}>
            <img className={classes.controlImg} src={getWebAbsPath("/img/stl-viewer/ViewTop.png")} value="Top View" />
            <Typography className={classes.controlButtonLabelTypography} variant="caption">Maxil</Typography>
          </Button>
        </Tooltip>
        <Tooltip title="Mandibular Occlusal">
          <Button onClick={handleControlClicked('BottomView')} classes={{ label: classes.controlButtonLabel }}>
            <img className={classes.controlImg} src={getWebAbsPath("/img/stl-viewer/ViewBottom.png")} value="Bottom View" />
            <Typography className={classes.controlButtonLabelTypography} variant="caption">Mand</Typography>
          </Button>
        </Tooltip>
        <Tooltip title="Anterior">
          <Button onClick={handleControlClicked('FrontView')} classes={{ label: classes.controlButtonLabel }}>
            <img className={classes.controlImg} src={getWebAbsPath("/img/stl-viewer/ViewFront.png")} value="Front View" />
            <Typography className={classes.controlButtonLabelTypography} variant="caption">Anter</Typography>
          </Button>
        </Tooltip>
        <Tooltip title="Right Buccal">
          <Button onClick={handleControlClicked('RightView')} classes={{ label: classes.controlButtonLabel }}>
            <img className={classes.controlImg} src={getWebAbsPath("/img/stl-viewer/ViewRight.png")} value="Right View" />
            <Typography className={classes.controlButtonLabelTypography} variant="caption">Right</Typography>
          </Button>
        </Tooltip>
        <Tooltip title="Left Buccal">
          <Button onClick={handleControlClicked('LeftView')} classes={{ label: classes.controlButtonLabel }}>
            <img className={classes.controlImg} src={getWebAbsPath("/img/stl-viewer/ViewLeft.png")} value="Left View" />
            <Typography className={classes.controlButtonLabelTypography} variant="caption">Left</Typography>
          </Button>
        </Tooltip>
        <Tooltip title="Grid on Ratio">
          <Button ref={gridBtn} onClick={handleControlClicked('gridToggle')} classes={{ label: classes.controlButtonLabel }}>
            <img className={classes.controlImg} src={getWebAbsPath("/img/stl-viewer/grid.png")} value="Grid" />
            <Typography className={classes.controlButtonLabelTypography} variant="caption">Grid</Typography>
          </Button>
        </Tooltip>
        <Button ref={iprBtn} onClick={handleControlClicked('iprAddModeBtn')} classes={{ label: classes.controlButtonLabel }}>
          <img className={classes.controlImg} src={getWebAbsPath("/img/stl-viewer/addIPR.png")} value="IPR" />
          <Typography className={classes.controlButtonLabelTypography} variant="caption">IPR</Typography>
        </Button>
        <Tooltip title="Superimposition">
          <Button ref={superBtn} onClick={handleControlClicked('superImposeBtn')} classes={{ label: classes.controlButtonLabel }}>
            <img className={classes.controlImg} src={getWebAbsPath("/img/stl-viewer/superImpose.png")} value="Super Imposition" />
            <Typography className={classes.controlButtonLabelTypography} variant="caption">Super</Typography>
          </Button>
        </Tooltip>
        <Tooltip title="Occlusion, showing and hiding contact points">
          <Button onClick={() => handleOcclusionClicked()} classes={{ label: classes.controlButtonLabel }}>
            <img className={classes.controlImg} src={getWebAbsPath("/img/stl-viewer/occlusion.png")} value="Occlusion" />
            <Typography className={classes.controlButtonLabelTypography} variant="caption">Occl.</Typography>
          </Button>
        </Tooltip>
      </div>


      {/* New layout including chatroom */}
      <Grid container className={classes.canvas}>
        <Grid item xs={12} md={simulationWidth} lg={simulationWidth} className={hideSimulation? classes.hide : ''}>
          <div
            ref={container3D}
            id="container3D"
            style={{
              maxHeight: window.innerWidth < 960 ? "90vh" : "",
              height: 'calc(100vh - 36px - 4px - 78px - 64px - 10px)',
            }}
          />
          <div
            className={classes.footer}
            alignItems="center"
            style={{
              // position: window.innerWidth > 960 ? "fixed" : "sticky",
              position: "fixed",
              left: 0,
              bottom: window.innerWidth > 960 ? 0 : "unset",
              zIndex: 9999
            }}
          >
            <div className={classes.videoControlContainer}>
              <IconButton aria-label="previous" className={classes.videoControlBtn}
                onClick={() => {
                  setPlaying(false)
                  viewer.current.previous()
                }}
              >
                <SkipPrevious />
              </IconButton>
              <IconButton aria-label="play/stop" className={classes.videoControlBtn}
                onClick={() => {
                  if (playing) {
                    setPlaying(false)
                    viewer.current.pause()
                  } else {
                    setPlaying(true)
                    viewer.current.play()
                  }
                }}
              >
                {playing? <Pause /> : <PlayArrow />}
              </IconButton>
              <IconButton aria-label="play/stop" className={classes.videoControlBtn}
                onClick={() => {
                  setPlaying(false)
                  viewer.current.next()
                }}
              >
                <SkipNext />
              </IconButton>
              {/* <Box m={1} /> */}
              <Typography variant="body2">Speed: </Typography>
              {/* <Box m={1} /> */}
              <Select
                ref={speedSelect}
                className={classes.speedSelect}
                value={speed}
                onChange={({ target: { value }}) => {
                  setSpeed(value)
                  viewer.current.setSpeed(value)
                }}
                style={{
                  textAlign: "center"
                }}
              >
                <MenuItem value={0.5}>0.5x</MenuItem>
                <MenuItem value={1.0}>1x</MenuItem>
                <MenuItem value={2.0}>2x</MenuItem>
              </Select>
              <div style={{padding: "0px 16px", width: "100%"}}>
                {sliderSteps}
              </div>
            </div>

          </div>
        </Grid>
        {/* <Grid item xs={12} md={5} lg={4} style={{background: "#4c4c4c", padding: 4, height: 'calc(100vh - 54px - 54px - 4px)',}}> */}
        {/* <Grid item container xs={12} md={chatroomWidth}> */}
          {/* <img src="/img/chatroom_sample.png" style={{width: "100%"}}/> */}
          {chatDraggable?
            <Draggable handle=".handle">
              <div className="handle">
                <Chat
                  caseId={caseId}
                  handleNewChatRoom={handleSecondChatroomVisibility}
                  hideTabs={hideChatTabs}
                  chatRomWidth={chatroomWidth}
                  dragableChatRoom={dragableChatroom}
                  exitFullScreen={chatFullscreen}
                  handleFullScreen={handleFullscreen}
                  dragPosition={chatDragPositioned}
                />
              </div>
            </Draggable> :
            <Chat
              caseId={caseId}
              handleNewChatRoom={handleSecondChatroomVisibility}
              chatRomWidth={chatroomWidth}
              hideTabs={hideChatTabs}
              dragableChatRoom={dragableChatroom}
              exitFullScreen={chatFullscreen}
              handleFullScreen={handleFullscreen}
            />
          }
          {/* Second ChatRoom */}
          {hideChatTabs?
            <Chat
              secondary
              caseId={caseId}
              handleNewChatRoom={handleSecondChatroomVisibility}
              chatRomWidth={chatroomWidth}
              hideTabs={hideChatTabs}
              handleVerticalSplit={handleVerticalSplit}
              hideSimulation={hideSimulation}
            />
            : <div></div>
          }
        {/* </Grid> */}
      </Grid>
    </Grid>
  )
}
export default Viewer3D