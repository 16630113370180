import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withLang } from '../../../util/lang'

class DragAndDrop extends Component {
  state = {
    drag: false,
    dragCounter: 0
  }
  dropRef = React.createRef()
  handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }
  handleDragIn = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.state.dragCounter++
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ drag: true })
    }
  }
  handleDragOut = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.state.dragCounter--
    if (this.state.dragCounter === 0) {
      this.setState({ drag: false })
    }
  }
  handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.setState({ drag: false })
    if (e.dataTransfer.items && e.dataTransfer.items.length == 1) {
      this.props.handleDrop(e.dataTransfer.files[0])
      //e.dataTransfer.clearData()
      this.state.dragCounter = 0
      // this.setState({drag: true})
    }

    if (e.dataTransfer.items && e.dataTransfer.items.length > 1) {
      this.props.handleDrop(e.dataTransfer.files)
      //e.dataTransfer.clearData()
      this.state.dragCounter = 0
      // this.setState({drag: true})
    }
  }
  componentDidMount() {
    if (!this.props.disabled) {
      let div = this.dropRef.current
      div.addEventListener('dragenter', this.handleDragIn)
      div.addEventListener('dragleave', this.handleDragOut)
      div.addEventListener('dragover', this.handleDrag)
      div.addEventListener('drop', this.handleDrop)
    }
  }
  // componentDidUpdate(prevProps) {
  //   if (this.props.
  // }
  componentWillUnmount() {
    let div = this.dropRef.current
    div.removeEventListener('dragenter', this.handleDragIn)
    div.removeEventListener('dragleave', this.handleDragOut)
    div.removeEventListener('dragover', this.handleDrag)
    div.removeEventListener('drop', this.handleDrop)
  }
  render() {
    return (
      <div
        style={{ display: this.props.hidden? 'none' : 'inline-block', position: 'relative', width: "100%" }}
        ref={this.dropRef}
      >
        {this.state.drag &&
          <div
            style={{
              border: 'dashed #39a8bd 4px',
              backgroundColor: 'rgba(255,255,255,.8)',
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              transform: "scale(0.7, 0.6)",
              zIndex: 9999
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: '50%',
                right: 0,
                left: 0,
                textAlign: 'center',
                color: 'grey',
                fontSize: 36
              }}
            >
              <div>{this.get(':dragAndDrop.dropHere')}</div>
            </div>
          </div>
        }
        {this.props.children}
      </div>
    )
  }
}

const mapStateToProps = store => {
  return {
    lang: store.lang
  }
};

export default connect(mapStateToProps)(withLang(DragAndDrop, 'PatientInfo'));