import Moment from 'moment/moment'

Moment.updateLocale(
  'en', {
  dow: 0,
  doy: 6 // First week of year must contain 1 January (7 + 0 - >1<)
}
)

Date.prototype.getWeek = function () {
  // var date = new Date(this.getTime());
  // date.setHours(0, 0, 0, 0);
  // // Thursday in current week decides the year.
  // date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
  // // January 4 is always in week 1.
  // var week1 = new Date(date.getFullYear(), 0, 4);
  // // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  // return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
  //                       - 3 + (week1.getDay() + 6) % 7) / 7);
  return Moment(this).week()
}

Date.shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

Date.getMonthName = function (month) {
  if (!isNaN(parseInt(month))) {
    return Date.shortMonths[month]
  } else {
    return undefined
  }
}

export function GetDates(startDate, daysToAdd) {
  let aryDates = [];
  let curDate = Moment(startDate).locale('en')

  for (var i = 1; i <= daysToAdd; i++) {
    curDate.add(1, 'day')
    aryDates.push(curDate.format('DD MM YYYY (ddd)'))
  }

  return aryDates;
}


function DayAsString(dayIndex) {
  var weekdays = new Array(7);
  weekdays[0] = "Sunday";
  weekdays[1] = "Monday";
  weekdays[2] = "Tuesday";
  weekdays[3] = "Wednesday";
  weekdays[4] = "Thursday";
  weekdays[5] = "Friday";
  weekdays[6] = "Saturday";

  return weekdays[dayIndex];
}
