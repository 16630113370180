import {
  RES_LOAD, RES_UPDATE
} from './actionType'

import axios, { safeGet } from '../../axios-default'

let isLoading = false

const resLoad = payload => ({
  type: RES_LOAD,
  payload
})
const resUpdate = payload => ({
  type: RES_UPDATE,
  payload
})

export const loadRes = () => async dispatch => {
  if (!isLoading) {
    isLoading = true
    try {
      const updatedObj = {}
      try {
        const [
          { data: { country } },
          { data: { state_province } }
        ] = await Promise.all([
          axios.get(`res/country`),
          axios.get(`res/state_province`)
        ])
        updatedObj.countries = country?.reduce((a, c) => Object.assign(a, { [c.country_id]: c }), {}) ?? {}
        updatedObj.stateProvinces = state_province?.reduce((a, s) => Object.assign(a, { [s.state_province_id]: s }), {}) ?? {}
      } catch (e) {
        console.error(e)
      }
      try {
        const [
          clinicRet,
          groupRet,
          agRet,
          dgRet
        ] = await Promise.allSettled([
          axios.get(`res/clinic`),
          axios.get(`res/group`),
          axios.get(`res/admingroup`),
          axios.get(`res/distrgroup`)
        ])
        if (clinicRet?.status === 'fulfilled') {
          updatedObj.clinics = clinicRet?.value?.data?.clinic?.reduce?.((a, c) => Object.assign(a, { [c.clinic_id]: c }), {}) ?? {}
        }
        if (groupRet?.status === 'fulfilled') {
          updatedObj.groups = groupRet?.value?.data?.group?.reduce?.((a, g) => Object.assign(a, { [g.group_id]: g }), {}) ?? {}
        }
        if (agRet?.status === 'fulfilled') {
          updatedObj.admin_groups = agRet?.value?.data?.admin_group?.reduce?.((a, ag) => Object.assign(a, { [ag.admin_group_id]: ag }), {}) ?? {}
        }
        if (dgRet?.status === 'fulfilled') {
          updatedObj.distr_groups = dgRet?.value?.data?.distr_group?.reduce?.((a, dg) => Object.assign(a, { [dg.distr_group_id]: dg }), {}) ?? {}
        }
      } catch (e) {
        console.error(e)
      }
      await dispatch(resLoad(updatedObj))
    } catch (e) {
      console.error(e)
    } finally {
      isLoading = false
    }
  }
}

export const updateRes = (resName) => async dispatch => {
  try {
    const updatedObj = {}
    switch (resName) {
      case 'country':
      case 'countries': {
        updatedObj.countries = (await axios.get(`res/country`)).data.country.reduce((a, c) => Object.assign(a, { [c.country_id]: c }), {})
        break
      }
      case 'state_province':
      case 'state_provinces':
      case 'stateProvince':
      case 'stateProvinces': {
        updatedObj.stateProvinces = (await axios.get(`res/state_province`)).data.state_province.reduce((a, s) => Object.assign(a, { [s.state_province_id]: s }), {})
        break
      }
      case 'group':
      case 'groups': {
        updatedObj.groups = (await axios.get(`res/group`)).data.group.reduce((a, g) => Object.assign(a, { [g.group_id]: g }), {})
        break
      }
      case 'admingroup':
      case 'admingroups':
      case 'admin_group':
      case 'admin_groups': {
        updatedObj.admin_groups = (await axios.get(`res/admingroup`)).data.admin_group.reduce((a, ag) => Object.assign(a, { [ag.admin_group_id]: ag }), {})
        break
      }
      case 'distrgroup':
      case 'distrgroups':
      case 'distr_group':
      case 'distr_groups': {
        updatedObj.distr_groups = (await axios.get(`res/distrgroup`)).data.distr_group.reduce((a, dg) => Object.assign(a, { [dg.distr_group_id]: dg }), {})
        break
      }
      case 'clinic':
      case 'clinics': {
        updatedObj.clinics = (await axios.get(`res/clinic`)).data.clinic.reduce((a, c) => Object.assign(a, { [c.clinic_id]: c }), {})
        break
      }
    }
    dispatch(resUpdate(updatedObj))
  } catch (e) {
    console.error(e)
  }
}