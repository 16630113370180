import * as THREE from "three"

class ViewAnimation {

    constructor(viewer) {
        // super()
        this.viewer = viewer
        this.camera = viewer.camera
        this.moveFrame = 0
        this.moveBool = false
        this.moveAngle = 0
        this.newPos = 0
        this.movePos = 0
        this.axis = null
        this.vector2 = null
        this.vectorAngle = null
        this.xAxisRot = null
    }
    setView(newPosition, newRotation, xAxisRot) {
        let scope = this
        this.newPos = newPosition
        let startAngel = new THREE.Vector2(this.camera.position.x, this.camera.position.z).angle()
        let endAngel = new THREE.Vector2(newPosition.x, newPosition.z).angle()
        this.vector2 = new THREE.Vector2(this.camera.position.x, this.camera.position.z)
        let axisMovement = this.camera.position.y
        this.xAxisRot = xAxisRot
        if (xAxisRot) {
            startAngel = new THREE.Vector2(this.camera.position.y, this.camera.position.z).angle()
            endAngel = new THREE.Vector2(newPosition.y, newPosition.z).angle()
            this.vector2.x = this.camera.position.y
            axisMovement = this.camera.position.x

        }
        this.vectorAngle = endAngel - startAngel

        let factor = (10 - this.camera.zoom) / 30
        let count = 0

        scope.movePos = axisMovement
        scope.moveBool = true

    }
    update() {
        if (this.moveBool) {
            if (this.moveFrame == 30) {
                this.camera.position.copy(this.newPos)

                this.moveBool = false
                this.viewer.controls.enabled = true
                this.moveFrame = 0
                this.moveFrame = 0
                this.moveAngle = 0
                this.newPos = 0
                this.movePos = 0
                this.axis = null
                this.vector2 = null
                this.vectorAngle = null


            } else {
                this.moveFrame += 1
                // this.x += this.moveAngle
                this.vector2.rotateAround(new THREE.Vector2(0, 0), this.vectorAngle / 30)

                if (this.xAxisRot) {
                    this.camera.position.x -= this.movePos / 30
                    this.camera.position.y = this.vector2.x
                    this.camera.position.z = this.vector2.y
                    // debugger
                } else {
                    this.camera.position.y -= this.movePos / 30
                    this.camera.position.x = this.vector2.x
                    this.camera.position.z = this.vector2.y
                }

            }

        }
    }

}
export { ViewAnimation }