import React, { PropTypes } from 'react';
import Grid from '@material-ui/core/Grid';
import NewPatientPhoto from './NewPatientPhoto'
import style from './NewPatientPhotos.module.css'
import { useLang } from '../../../util/lang';

const Photos = (props) => {
  const get = useLang('NewPatient')
  return (
    <>
      <Grid container spacing={3}>
        <h2 className={style.subHeading}>{`${get(':uploadType.mandatory')} ${get('photographs.photographs')}`}</h2>
        <Grid item xs={12} sm={4}>
          <li>
            <NewPatientPhoto
            mode={props.mode}
              // label={get(':uploadType.uploadPhotoLater')}
              imageId="jaw_side_with_smile"
              imageUploaded={props.imageUploaded("jaw_side_with_smile")}
              imageData={props.imageData("jaw_side_with_smile")}
              label={get('photographs.uploadThisPhotoLater')}
              descLabel={get('photographs.desc_jaw_side_with_smile') != "N/A" ? get('photographs.desc_jaw_side_with_smile') : ""}
              imageName="pbg11.png"
              handleRoatate={(degree) => { props.handleRoatate("jaw_side_with_smile", degree) }}
              flip="true"
              mandatoryImage={props.parent === 'refinement' ? false : true}
              fliplabel1="Q1"
              fliplabel2="Q2"
              uploadLater={props.parent === 'refinement' ? true : false}
              no_warning={props.parent === 'refinement' ? true : false}
              will_not_upload={props.parent === 'refinement' ? true : false}
              will_not_upload_label={get(':uploadType.willNotUploadPhoto')}
              handleInvert={(invert) => { props.invert('jaw_side_with_smile', invert) }}
              handleChange={(file) => { props.handleChange("jaw_side_with_smile", file) }} />
          </li>
        </Grid>
        <Grid item xs={12} sm={4}>
          <li>
            <NewPatientPhoto
            mode={props.mode}
              // label={get(':uploadType.uploadPhotoLater')}
              imageId="face_front_with_smile"
              imageUploaded={props.imageUploaded("face_front_with_smile")}
              imageData={props.imageData("face_front_with_smile")}
              label={get('photographs.uploadThisPhotoLater')}
              descLabel={get('photographs.face_front_with_smile') != "N/A" ? get('photographs.face_front_with_smile') : ""}
              imageName="front_smile.png"
              mandatoryImage={props.parent === 'refinement' ? false : true}
              uploadLater={props.parent === 'refinement' ? true : false}
              no_warning={props.parent === 'refinement' ? true : false}
              will_not_upload={props.parent === 'refinement' ? true : false}
              will_not_upload_label={get(':uploadType.willNotUploadPhoto')}
              handleRoatate={(degree) => { props.handleRoatate("face_front_with_smile", degree) }}
              handleChange={(file) => { props.handleChange("face_front_with_smile", file) }} />
          </li>
        </Grid>
        <Grid item xs={12} sm={4}>
          <li>
            <NewPatientPhoto
            mode={props.mode}
              // label={get(':uploadType.uploadPhotoLater')}
              imageId="teeth_side_without_smile"
              imageUploaded={props.imageUploaded("teeth_side_without_smile")}
              imageData={props.imageData("teeth_side_without_smile")}
              label={get('photographs.uploadThisPhotoLater')}
              descLabel={get('photographs.teeth_side_without_smile') != "N/A" ? get('photographs.teeth_side_without_smile') : ""}
              imageName="pbg13.png"
              mandatoryImage={props.parent === 'refinement' ? false : true}
              uploadLater={props.parent === 'refinement' ? true : false}
              no_warning={props.parent === 'refinement' ? true : false}
              will_not_upload={props.parent === 'refinement' ? true : false}
              will_not_upload_label={get(':uploadType.willNotUploadPhoto')}
              handleRoatate={(degree) => { props.handleRoatate("teeth_side_without_smile", degree) }}
              handleChange={(file) => { props.handleChange("teeth_side_without_smile", file) }} />
          </li>
        </Grid>

        <Grid item xs={12} sm={4}>
          <li>
            <NewPatientPhoto
            mode={props.mode}
              // label={get(':uploadType.uploadPhotoLater')}
              imageId="jaw_front_with_smile"
              imageUploaded={props.imageUploaded("jaw_front_with_smile")}
              imageData={props.imageData("jaw_front_with_smile")}
              label={get('photographs.uploadThisPhotoLater')}
              descLabel={get('photographs.jaw_front_with_smile') != "N/A" ? get('photographs.jaw_front_with_smile') : ""}
              imageName="pbg12.png"
              handleRoatate={(degree) => { props.handleRoatate("jaw_front_with_smile", degree) }}
              flip="true"
              fliplabel1="Q4"
              fliplabel2="Q3"
              mandatoryImage={props.parent === 'refinement' ? false : true}
              uploadLater={props.parent === 'refinement' ? true : false}
              no_warning={props.parent === 'refinement' ? true : false}
              will_not_upload={props.parent === 'refinement' ? true : false}
              will_not_upload_label={get(':uploadType.willNotUploadPhoto')}
              handleInvert={(invert) => { props.invert('jaw_front_with_smile', invert) }}
              handleChange={(file) => { props.handleChange("jaw_front_with_smile", file) }} />
          </li>
        </Grid>
        <Grid item xs={12} sm={4}>
          <li>
            <NewPatientPhoto
            mode={props.mode}
              // label={get(':uploadType.uploadPhotoLater')}
              imageId="teeth_side_with_smile"
              imageUploaded={props.imageUploaded("teeth_side_with_smile")}
              imageData={props.imageData("teeth_side_with_smile")}
              label={get('photographs.uploadThisPhotoLater')}
              descLabel={get('photographs.teeth_side_with_smile') != "N/A" ? get('photographs.teeth_side_with_smile') : ""}
              imageName="pbg10.png"
              mandatoryImage={props.parent === 'refinement' ? false : true}
              uploadLater={props.parent === 'refinement' ? true : false}
              no_warning={props.parent === 'refinement' ? true : false}
              will_not_upload={props.parent === 'refinement' ? true : false}
              will_not_upload_label={get(':uploadType.willNotUploadPhoto')}
              handleRoatate={(degree) => { props.handleRoatate("teeth_side_with_smile", degree) }}
              handleChange={(file) => { props.handleChange("teeth_side_with_smile", file) }} />
          </li>
        </Grid>
        <Grid item xs={12} sm={4}>
          <li>
            <NewPatientPhoto
            mode={props.mode}
              // label={get(':uploadType.uploadPhotoLater')}
              imageId="jaw_side_without_smile"
              imageUploaded={props.imageUploaded("jaw_side_without_smile")}
              imageData={props.imageData("jaw_side_without_smile")}
              label={get('photographs.uploadThisPhotoLater')}
              descLabel={get('photographs.jaw_side_without_smile') != "N/A" ? get('photographs.jaw_side_without_smile') : ""}
              imageName="pbg14.png"
              mandatoryImage={props.parent === 'refinement' ? false : true}
              uploadLater={props.parent === 'refinement' ? true : false}
              no_warning={props.parent === 'refinement' ? true : false}
              will_not_upload={props.parent === 'refinement' ? true : false}
              will_not_upload_label={get(':uploadType.willNotUploadPhoto')}
              handleRoatate={(degree) => { props.handleRoatate("jaw_side_without_smile", degree) }}
              handleChange={(file) => { props.handleChange("jaw_side_without_smile", file) }} />
          </li>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <h2 className={style.subHeading}>{get(':uploadType.stronglyRecommended')}</h2>
        <Grid item xs={12} sm={4}>
          <li>
            <NewPatientPhoto
            mode={props.mode}
              // label={get(':uploadType.uploadPhotoLater')}
              imageId="face_side_with_smile"
              imageData={props.imageData('face_side_with_smile')}
              imageUploaded={props.imageUploaded('face_side_with_smile')}
              label={get('photographs.uploadThisPhotoLater')}
              descLabel={get('photographs.face_side_with_smile') != "N/A" ? get('photographs.face_side_with_smile') : ""}
              imageName="side_smile.png"
              uploadLater={props.uploadLater}
              no_warning={props.parent === 'refinement' ? true : false}
              will_not_upload={props.parent === 'refinement' ? true : false}
              will_not_upload_label={get(':uploadType.willNotUploadPhoto')}
              handleRoatate={(degree) => { props.handleRoatate("face_side_with_smile", degree) }}
              handleChange={(file) => { props.handleChange('face_side_with_smile', file) }}
            />
          </li>
        </Grid>

        <Grid item xs={12} sm={4}>
          <li>
            <NewPatientPhoto
            mode={props.mode}
              // label={get(':uploadType.uploadPhotoLater')}
              imageId="teeth_front_with_smile"
              imageUploaded={props.imageUploaded("teeth_front_with_smile")}
              imageData={props.imageData("teeth_front_with_smile")}
              label={get('photographs.uploadThisPhotoLater')}
              descLabel={get('photographs.teeth_front_with_smile') != "N/A" ? get('photographs.teeth_front_with_smile') : ""}
              imageName="front_normal.png"
              uploadLater={props.uploadLater}
              no_warning={props.parent === 'refinement' ? true : false}
              will_not_upload={props.parent === 'refinement' ? true : false}
              will_not_upload_label={get(':uploadType.willNotUploadPhoto')}
              handleRoatate={(degree) => { props.handleRoatate("teeth_front_with_smile", degree) }}
              handleChange={(file) => { props.handleChange("teeth_front_with_smile", file) }} />
          </li>
        </Grid>

        <Grid item xs={12} sm={4}>
          <li>
            <NewPatientPhoto
            mode={props.mode}
              // label={get(':uploadType.uploadPhotoLater')}
              imageId="face_side_without_smile"
              imageUploaded={props.imageUploaded("face_side_without_smile")}
              imageData={props.imageData("face_side_without_smile")}
              label={get('photographs.uploadThisPhotoLater')}
              descLabel={get('photographs.face_side_without_smile') != "N/A" ? get('photographs.face_side_without_smile') : ""}
              imageName="side_without_smile.png"
              uploadLater={props.uploadLater}
              no_warning={props.parent === 'refinement' ? true : false}
              will_not_upload={props.parent === 'refinement' ? true : false}
              will_not_upload_label={get(':uploadType.willNotUploadPhoto')}
              handleRoatate={(degree) => { props.handleRoatate("face_side_without_smile", degree) }}
              handleChange={(file) => { props.handleChange("face_side_without_smile", file) }} />
          </li>
        </Grid>
      </Grid>
    </>
  );
};

export default Photos;
