import React, { useEffect, forwardRef, useState, useCallback } from 'react'
import { CircularProgress, Grid } from '@material-ui/core'
import MaterialTable from 'material-table'
import { useHistory } from 'react-router'
import { safeGet } from '../../axios-default'
import QueryString from 'querystring'

import {
  Close as CloseIcon, Check as CheckIcon, AddBox, ArrowDownward,
  Check, ChevronLeft, ChevronRight, Clear, DeleteOutline,
  Edit, FilterList, FirstPage, LastPage, Remove, SaveAlt,
  Search, ViewColumn, StarRate
} from '@material-ui/icons'

const TableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
}

export const AccountsTable = () => {
  const history = useHistory()

  const [data, setData] = useState([]),
        [loading, setLoading] = useState(true),
        [search, setSearch] = useState('')

  const dataGetter = useCallback(async query => {
    const { pageSize, page: pageNo, search, orderBy, orderDirection } = query
    try {
      const { user: data, total } = await safeGet(`user?${QueryString.encode({
        // all: 1,
        pageNo,
        pageSize,
        search,
        sortBy: orderBy?.field,
        sortDir: orderDirection
      })}`)
      if (loading) setLoading(false)
      return {
        data: data.filter(({ deleted_flag }) => !deleted_flag),
        page: pageNo ?? 0,
        totalCount: total ?? 0
      }
    } catch (err) {
      console.error(err)
    }
  }, [search])

  return (
    <MaterialTable
      title="Accounts List"
      data={dataGetter}
      columns={[
        {
          field: 'user_id',
          title: 'Account ID',
          sorting: true,
          filtering: true
        },
        {
          field: 'role',
          title: 'Role',
          sorting: true,
          filtering: true,
          render: rowData => (
            <>
              {rowData.admin_flag? <StarRate style={{verticalAlign: 'bottom'}}/> : null}{rowData.role}
            </>
          )
        },
        {
          field: 'first_name',
          title: 'First Name',
          sorting: true,
          filtering: true
        },
        {
          field: 'last_name',
          title: 'Last Name',
          sorting: true,
          filtering: true
        },
        {
          field: 'approved_flag',
          title: 'Approved',
          sorting: true,
          filtering: true,
          defaultSort: 'asc',
          render: rowData => (
            <div style={{ display: "flex", justifyContent: 'center', margin: '0px 4px', width: 40, height: 40 }}>
              {rowData.approved_flag ? <CheckIcon /> : <CloseIcon />}
            </div>
          )
        },
        {
          field: 'active_flag',
          title: 'Active',
          sorting: true,
          filtering: true,
          render: rowData => (
            <div style={{ display: "flex", justifyContent: 'center', margin: '0px 4px', width: 40, height: 40 }}>
              {rowData.active_flag ? <CheckIcon /> : <CloseIcon />}
            </div>
          )
        },
        {
          field: 'email_address',
          title: 'Email',
          sorting: true,
          filtering: true
        },
        {
          field: 'phone_no',
          title: 'Phone No',
          sorting: true,
          filtering: true
        },
        {
          field: 'distr_group_name',
          title: 'DistrGroup Name',
          sorting: true,
          filtering: true
        },
        {
          field: 'admin_group_name',
          title: 'AdminGroup Name',
          sorting: true,
          filtering: true
        },
        {
          field: 'country_name',
          title: 'Country',
          sorting: true,
          filtering: true
        }
      ]}
      onRowClick={(_, data) => {
        history.push(`/admin/accounts/${data.user_id}`)
      }}
      options={{
        initialPage: 0,
        emptyRowsWhenPaging: false,
        exportAllData: true,
        debounceInterval: 700,
        columnsButton: true,
        pageSize: 10,
        search: true,
        pageSizeOptions: [10, 15, 30, 50, 100],
        minBodyHeight: 700,
        exportButton: true
      }}
      icons={TableIcons}
      isLoading={loading}
    />
  )

}

export default AccountsTable
