import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';

import CollapsibleRow from './CollapsibleTableRow'
import CollapsibleToolbar from './CollapsibleTableToolbar'
import CollapsibleTableHead from './CollapsibleTableHead';
import { makeStyles, TablePagination, TableRow, TableCell } from '@material-ui/core';

const useTableStyles = makeStyles({
  tablePaper: {
    width: '100%',
    boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.3)'
  }
})

const CountryTable = (props) => {
  const sortingFunc = React.useRef(data => data),
        filteringFunc = React.useRef(data => data),
        classes = useTableStyles(),
        [curData, setCurData] = React.useState([]),
        [pageNo, setPageNo] = React.useState(0),
        [rowsPerPage, setRowsPerPage] = React.useState(20)
  const data = React.useRef([])
  // const onRequestSort = (name, dir) => {
  //   console.log(name, dir)
  //   sortingFunc.current = data => data.sort((a, b) => {
  //     let ret = 0
  //     if (!a[name]) {
  //       ret = -1
  //     } else if (!b[name]) {
  //       ret =  1
  //     } else {
  //       ret = (a[name] === b[name])? 0 : ((a[name] < b[name])? -1 : 1)
  //     }
  //     if (dir === 'desc') ret *= -1
  //     return ret
  //   })
  //   setData(sortingFunc.current(data))
  // }

  useEffect(() => {
    data.current = props.data
    updateCurData()
  }, [props.data])

  const updateCurData = () => {
    setCurData(sortingFunc.current(filteringFunc.current(data.current)).concat())
  }

  return (
    <Paper
      className={classes.tablePaper}
    >
      <CollapsibleToolbar
        title={props.title}
        onRequestFilter={searchStr => {
          if (String.isBlank(searchStr)) {
            filteringFunc.current = data => data
          } else {
            if (Array.isArray(props.filterColumns) && props.filterColumns.length > 0) {
              let testRegExp = new RegExp(`.*${searchStr}.*`, 'i')
              filteringFunc.current = data => data.filter(v => props.filterColumns.some(col => testRegExp.test(v[col])))
            }
          }
          updateCurData()
        }}
      />
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <CollapsibleTableHead
            shiftColumnNum={1}
            columns={props.columns}
            onRequestSort={(name, dir) => {
              sortingFunc.current = data => data.sort((a, b) => {
                let ret = 0
                if (!a[name]) {
                  ret = -1
                } else if (!b[name]) {
                  ret =  1
                } else {
                  ret = (a[name] === b[name])? 0 : ((a[name] < b[name])? -1 : 1)
                }
                if (dir === 'desc') ret *= -1
                return ret
              })
              updateCurData()
            }}
          />
          <TableBody>
            {curData.slice(pageNo*rowsPerPage, (pageNo+1)*rowsPerPage).map((row, i) => (
              <CollapsibleRow
                key={i}
                title={props.subTitle}
                columns={props.columns}
                subColumns={props.subColumns}
                onInsert={props.onInsert}
                data={row}
                subDataName={props.subDataName}
              />
            ))}
            {curData.length !== 0 && curData.length - (pageNo+1)*rowsPerPage-1 < 0 && (
              <TableRow style={{ height: 53 * (-(curData.length - (pageNo+1)*rowsPerPage)) }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 30, 50]}
        component="div"
        count={curData.length}
        rowsPerPage={rowsPerPage}
        page={pageNo}
        onChangePage={(_, newPageNo) => {
          setPageNo(newPageNo)
        }}
        onChangeRowsPerPage={({ target }) => {
          setRowsPerPage(parseInt(target.value, 10))
          setPageNo(0)
        }}
      />
    </Paper>
  )
}

CountryTable.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    render: PropTypes.func,
    index: PropTypes.bool,
    sortable: PropTypes.bool
  })),
  filterColumns: PropTypes.arrayOf(PropTypes.string),
  subColumns: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    render: PropTypes.func,
    index: PropTypes.bool,
    sortable: PropTypes.bool
  })),
  subDataName: PropTypes.string,
  onInsert: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.object)
}

export default CountryTable
