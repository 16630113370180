import '../../../util/string'
import React, { Component, forwardRef } from 'react';
import Zoom from 'react-medium-image-zoom'
import {
  Grid
} from '@material-ui/core';
import MaterialTable from 'material-table'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import Modal from '../../../components/Modal/modal';
import DragAndDrop from '../../../components/NewPatient/Photos/DragAndDrop'
import style from './PageBanner.module.css'
import axios from '../../../axios-default'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Url from 'url'
import join from 'url-join'
import { ApiServerUrl } from '../../../config.json'

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { easySnackbar } from '../../../store/Noti/noti.actions';
import { loadConfig } from '../../../store/config/config.action';
import { getWebAbsPath } from '../../../util/path';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
}

const DEFAULT_PHOTO = getWebAbsPath("/img/log_in.jpg")

class LoginPagePhoto extends Component {

  columns = [
    {
      title: 'Country Name',
      field: 'country_id',
      lookup: Object.fromEntries(
        Object.entries(this.props.res.countries).map(([key, value]) =>
          ([key, value.country_name])
        )
      ),
      emptyValue: () => <div>Default</div>
    },
    {
      title: 'State/Province Name',
      field: 'state_province_id',
      lookup: Object.fromEntries(
        [[0, 'All']].concat(Object.entries(this.props.res.stateProvinces).map(([key, value]) =>
          ([key, value.name])
        ))
      ),
      initialEditValue: 0,
      emptyValue: () => <div>All</div>
    },
    {
      title: 'Photo',
      field: 'photo',
      render: data => (
        <Zoom zoomMargin={48}>
          <img
            style={{ maxHeight: "200px" }}
            src={data.photo}
            alt="Login Page Photo"
          />
        </Zoom>
      ),
      editComponent: props => (
        <DragAndDrop
          handleDrop={async file => {
            const data = await this.fileToBase64(file)
            props.onChange(data)
          }}
        >
          {
            props.value === null || String.isBlank(props.value) ?
              <div className={style.imageUploadArea}>
                <span>Upload New Photo</span>
                <div className={style.uploadBtnWrapper}>
                  <button className={style.uploadBtn}>+</button>
                  <input
                    type="file"
                    onChange={async ({ target }) => {
                      const data = await this.fileToBase64(target.files[0])
                      props.onChange(data)
                    }}
                    accept="image/*"
                    name="patientImage"
                    id="patientImage"
                  />
                </div>
              </div> :
              <div className={style.imageUploadArea}>
                <button className={style.remove} onClick={() => props.onChange(null)}>
                  <DeleteOutlineOutlinedIcon />
                </button>
                <img
                  style={{ width: "100%", maxHeight: "400px" }}
                  src={props.value}
                />
              </div>
          }
        </DragAndDrop>
      )
    }
  ]
  actions = [
    {
      icon: () => <EditOutlinedIcon/>,
      tooltip: 'Edit Photo',
      onClick: (_, data) => {
        this.setState({
          photoModelOpen: true,
          photo: Object.assign({}, data)
        })
      }
    },
    row => ({
      icon: () => <DeleteOutlineOutlinedIcon />,
      tooltip: 'Delete Photo',
      hidden: !row.country_id && !row.state_province_id && !row.user_id,
      onClick: async (_, data) => {
        if (window.confirm('Do you want to delete this photo?')) {
          try {
            await axios.post('config', {
              country_id: data.country_id,
              state_province_id: data.state_province_id,
              user_id: null,
              config: JSON.stringify({
                loginPhoto: null
              })
            })
            await this.props.reloadConfig()
            this.setState({
              loading: true,
              photo: {}
            }, () => {
              this.componentDidMount()
            })
          } catch (err) {
            console.error(err)
            if (err.response && err.response.statusText === 'Unauthorized') {
              localStorage.removeItem("auth-token");
              localStorage.removeItem("expirationDate");
              localStorage.removeItem("role");
              this.props.history.push("/login")
            }
          }
        }
      }
    })
  ]

  constructor(props) {
    super(props);
    this.state = {
      role: JSON.parse(localStorage.getItem('role')),
      valuePath: 'loginPhoto',
      data: [],
      newImage: null,
      photoUploadLoadingStatus: false,
      photoModelOpen: false,
      photo: {}
    }
  }

  getValueFromConfig = config => config[this.state.valuePath]

  handleAddPhoto = async data => {
    try {
      if (!data.country_id) {
        this.props.enqueSnackbar('Please select country', 'error')
        throw new Error()
      }
      if (!data.photo || String.isBlank(data.photo)) {
        this.props.enqueSnackbar('Please select photo', 'error')
        throw new Error()
      }
      if (data.state_province_id && parseInt(data.state_province_id)) {
        const stateProvince = this.props.res.stateProvinces[parseInt(data.state_province_id)]
        if (!stateProvince || stateProvince.country_id != data.country_id) {
          this.props.enqueSnackbar('Selected state/province is not in the selected country', 'error')
          throw new Error()
        }
      } else {
        data.state_province_id = null
      }

      await axios.post('config', {
        ...data,
        config: JSON.stringify({
          loginPhoto: data.photo
        })
      })
      await this.props.reloadConfig()
      this.setState({
        loading: true
      }, () => {
        this.componentDidMount()
      })
    } catch (err) {
      if (err.response && err.response.statusText === 'Unauthorized') {
        localStorage.removeItem("auth-token");
        localStorage.removeItem("expirationDate");
        localStorage.removeItem("role");
        this.props.history.push("/login")
      } else {
        console.error(err.message)
        if (err.message) this.props.enqueSnackbar('Error occurred', 'error')
        throw err
      }
    }
  }

  handleSavePhoto = async () => {
    try {
      await axios.post('config', {
        ...this.state.photo,
        config: JSON.stringify({
          loginPhoto: this.state.photo.newPhoto
        })
      })
      await this.props.reloadConfig()
      this.setState({
        loading: true,
        photo: {}
      }, () => {
        this.componentDidMount()
      })
    } catch (err) {
      console.error(err)
      if (err.response && err.response.statusText === 'Unauthorized') {
        localStorage.removeItem("auth-token");
        localStorage.removeItem("expirationDate");
        localStorage.removeItem("role");
        this.props.history.push("/login")
      }
    }
  }

  fileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    if (file !== undefined) {
      reader.readAsDataURL(file);
    }
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  handleNewPhoto = async file => {
    let data = undefined
    if (file) {
      data = await this.fileToBase64(file)
    }
    this.setState(({ photo }) => ({
      photo: {
        ...photo,
        newPhoto: data
      }
    }))
  }

  async componentDidMount() {
    try {
      const country_id = JSON.parse(localStorage.getItem('country_id'))
      const configList = await Promise.all((await axios.get('config/list')).data.config.filter(v =>
        ((!v.state_province_id && !v.user_id && this.getValueFromConfig(v.json)) ||
        (!v.country_id && !v.state_province_id && !v.user_id)) &&
        (['ADMIN', 'SADMIN'].includes(this.state.role) || !v.country_id || country_id == v.country_id)
      ).map(async v => {
        let photo = this.getValueFromConfig(v.json)
        if (photo) {
          photo = join(ApiServerUrl, photo)
        } else {
          photo = DEFAULT_PHOTO
        }
        return {
          ...v,
          country_id: (this.props.res.countries[v.country_id]||{country_name: 'Default'}).country_id,
          state_province_id: ((this.props.res.stateProvinces[v.state_province_id]||{name: 'All'}).state_province_id),
          photo: photo,
          json: undefined
        }
      }))
      this.setState({ data: configList, loading: false })
    } catch (err) {
      console.error(err)
      if (err.response && err.response.statusText === 'Unauthorized') {
        localStorage.removeItem("auth-token");
        localStorage.removeItem("expirationDate");
        localStorage.removeItem("role");
        this.props.history.push("/login")
      }
    }
  }

  render() {
    return (
      <>
        <Modal
          className="modal"
          modal='LoginPagePhoto'
          title='Login Page Photo'
          open={this.state.photoModelOpen}
          handleClose={() => this.setState({ photoModelOpen: false, photo: {} })}
          handleDataSave={() => this.handleSavePhoto()}
          photoUploadLoadingStatus={this.state.photoUploadLoadingStatus}
          submitButton={true}
        >
          <Grid container>
            <Grid item xs={12}>
              <p>Current Image</p>
            </Grid>
            <Grid item xs={12}>
              <img style={{ width: "100%" }} src={this.state.photo.photo} alt="Current Image Photo" />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>

              <DragAndDrop
                handleDrop={file => this.handleNewPhoto(file)}
              >
                {
                  !this.state.photo.newPhoto ?
                    <div className={style.imageUploadArea}>
                      <span>Upload New Photo</span>
                      <div className={style.uploadBtnWrapper}>
                        <button className={style.uploadBtn}>+</button>
                        <input
                          type="file"
                          onChange={({ target }) => this.handleNewPhoto(target.files[0])}
                          accept="image/*"
                          name="patientImage"
                          id="patientImage"
                        />
                      </div>
                    </div> :
                    <div className={style.imageUploadArea}>
                      <button className={style.remove} onClick={() => this.handleNewPhoto(undefined)}>
                        <DeleteOutlineOutlinedIcon />
                      </button>
                      <img
                        style={{ width: "100%", maxHeight: "400px" }}
                        src={this.state.photo.newPhoto}
                      />
                    </div>
                }
              </DragAndDrop>
            </Grid>
          </Grid>
        </Modal>

        <MaterialTable
          isLoading={this.state.loading}
          title='Login Page Photo'
          icons={tableIcons}
          columns={this.columns}
          editable={{
            onRowAdd: this.handleAddPhoto
          }}
          actions={this.actions}
          data={this.state.data}
          options={{
            actionsColumnIndex: -1
          }}
        />
      </>
    );
  }
}


const mapStateToProps = state => ({
  res: state.res
});

const mapDispatchToProps = dispatch => ({
  enqueSnackbar: (msg, variant) => dispatch(easySnackbar(msg, variant)),
  reloadConfig: () => dispatch(loadConfig()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoginPagePhoto))
