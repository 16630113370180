import React, { Component } from 'react'
import { Grid, TextField } from '@material-ui/core'
import { withLang } from '../../util/lang'
import { connect } from 'react-redux'
import axios from '../../axios-default'
import Moment from "moment/moment"

class RecommendedDays extends Component {

  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    if (this.props.selectedPlan) {
      const caseId = this.props.selectedPlan.case_id
      const planId = this.props.selectedPlan.plan_id
      let aligners = (await axios.get(`case/case_aligner/${caseId}?planId=${planId}`)).data.case_aligner
      this.props.setAligners(aligners)
    }
  }

  render() {
    if (this.props.aligners && this.props.aligners.length) {
      return (
        <Grid container style={{marginBottom: "10px"}}>
          Recommended days to wear (start date)
          {this.props.aligners.map((v, i) =>
            <Grid item xs={12} style={{padding: 4}}>
              <TextField
                id={`days_for_aligner_${v.aligner_number}`}
                label={`Aligner #${v.aligner_number} (${v.aligner_start_date ? Moment(v.aligner_start_date).format('DD-MM-YYYY') : "Not started"})`}
                value={v.days_for_aligner}
                onKeyPress={(event) => !/[0-9]/.test(event.key) && event.preventDefault()}
                onChange={(event) => this.props.updateRecommendedDays(event.target.value, i)}
                style={{
                  width: "100%",
                  fontWeight: "300 !important"
                }}
              />
            </Grid>
          )}
        </Grid>
      )
    } else {
      return ""
    }
  }
}

const mapStateToProps = store => ({
  lang: store.lang
})

export default connect(mapStateToProps)(withLang(RecommendedDays, 'TreatmentApproval'))
