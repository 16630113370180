import './string'
import join from 'url-join'
import { SubPath } from '../config.json'
import Moment from 'moment/moment'

function interpolateStatus(msg, log) {
  const { status } = log
  const matches = msg.matchAll(/{{([a-z0-9_]+)}}/gi)
  for (const [token, varName] of matches) {
    msg = msg.replace(token, varName in status ? (status[varName] || '') : ``)
  }
  if (["CHANGE_REQUEST", "PLAN_APPROVED", "ENDED", "NEXT_SET_REQUEST"].includes(status.status)) {
    return { msg, external: true }
  }
  return msg
}

function mergeRequests(logs) {
  logs = (logs.reverse().reduce((acc, log) => {
    if (log) {
      if (log.case_retainer_request) {
        acc.filtered.case_impression_id = [...(acc.filtered.case_impression_id || []), log.case_impression.case_impression_id]
      } else if (log.case_refinement) {
        acc.filtered.case_detail_id = [...(acc.filtered.case_detail_id || []), log.case_refinement.case_detail.case_detail_id]
        acc.filtered.case_file_id = [...(acc.filtered.case_file_id || []), log.case_refinement.case_file.case_file_id]
        acc.filtered.case_impression_id = [...(acc.filtered.case_impression_id || []), log.case_refinement.case_impression.case_impression_id]
      } else if (log.case_impression && acc.filtered.case_impression_id && acc.filtered.case_impression_id.includes(log.case_impression.case_impression_id)) {
        return acc
      } else if (log.case_file && acc.filtered.case_file_id && acc.filtered.case_file_id.includes(log.case_file.case_file_id)) {
        return acc
      } else if (log.case_detail && acc.filtered.case_detail_id && acc.filtered.case_detail_id.includes(log.case_detail.case_detail_id)) {
        return acc
      }
      acc.results.push(log)
    }
    return acc
  }, { results: [], filtered: {} })).results
  return logs.reverse()
}

function translateLogs(logs, caseType, reverse=true, extraInfo=true, keepOri=false) {
  let StatusMsgDict={}

  if (caseType==='RETAINER'){
    StatusMsgDict = {
      DRAFT: this.get('log.DRAFT'),
      SUBMITTED: this.get('log.SUBMITTED'),
      AWAITING: "",
      RECEIVED: "All materials have been confirmed and the retainers are expected to be delivered on {{status_param1}} {{status_param2}}",
      PLAN_UPLOADED: "",
      CHANGE_REQUEST: "",
      PLAN_APPROVED: "",
      PRODUCTION: `{{status_remarks}} Production will be delivered on {{status_param1}}`,
      DELIVERED: `Retainer Set {{status_param1}} - {{status_param2}} Send On {{status_remarks}}` ,
      NEXT_SET_REQUEST: "",
      ALL_DELIVERED: `All {{status_remarks}} delivered`,
      ENDED: this.get('log.ENDED'),
      REFINEMENT: "",
      SWITCH_ALIGNER: ""
    }
  }else{
    StatusMsgDict = {
      DRAFT: this.get('log.DRAFT'),
      SUBMITTED: this.get('log.SUBMITTED'),
      AWAITING: `${this.get('log.AWAITING')} - {{status_remarks}}`,
      RECEIVED: this.get('log.RECEIVED'),
      PLAN_UPLOADED: this.get('log.TREATMENNT_PLAN_UPLOADED'),
      CHANGE_REQUEST: this.get('log.TREATMENNT_PLAN_CHANGED_REQUEST_RECEIVED'),
      PLAN_APPROVED: this.get('log.TREATMENNT_PLAN_APPROVED'),
      PRODUCTION: this.get('log.PRODUCTION'),
      DELIVERED: this.get('log.ALIGNER_SET_DELIVERED'),
      NEXT_SET_REQUEST: this.get('log.NEXT_SET_REQUEST'),
      ALL_DELIVERED: this.get('log.ALL_DELIVERED'),
      ENDED: this.get('log.ENDED'),
      REFINEMENT: this.get('log.REFINEMENTS'),
      SWITCH_ALIGNER: [
        "Patient starts aligner #{{status_param2}}",
        "Patient moves to aligner #{{status_param2}}",
        "Patient roll back to aligner #{{status_param2}} from #{{status_param1}}",
        "Patient jumps to aligner #{{status_param2}} from #{{status_param1}}"
      ]
    }
  }

  if (extraInfo) {
    logs = mergeRequests(logs)
    // count treatment plan & retainer
    logs = (logs.reduce((acc, log) => {
      if (log.treatment_plan?.plan_id) {
        if (log.treatment_plan.plan_no && log.treatment_plan.plan_id > 0) {
          try {
            if (log.status) {
              log.status.plan_no = log.treatment_plan.plan_no
            }
            acc.plan_ids[log.treatment_plan.plan_id] = log.treatment_plan.plan_no
            acc.plan_no = log.treatment_plan.plan_no
          } catch {}
        } else {
          try {
            if (log.treatment_plan.plan_id == -1 || !Object.keys(acc.plan_ids).includes(log.treatment_plan.plan_id.toString())) {
              log.status.plan_no = log.treatment_plan.plan_no = ++acc.plan_no
              acc.plan_ids[log.treatment_plan.plan_id] = log.status.plan_no
            } else {
              log.status.plan_no = log.treatment_plan.plan_no = acc.plan_ids[log.treatment_plan.plan_id]
            }
          } catch {}
        }
      }
      if (log.case_retainer_request?.retainer_id) {
        if (log.case_retainer_request.retainer_no) {
          try {
            if (log.status) {
              log.status.retainer_no = log.case_retainer_request.retainer_no
            }
            acc.retainer_ids[log.case_retainer_request.retainer_id] = log.case_retainer_request.retainer_no
            acc.retainer_no = log.case_retainer_request.retainer_no
          } catch {}
        } else {
          try {
            if (log.case_retainer_request.retainer_id == -1 || !Object.keys(acc.retainer_ids).includes(log.case_retainer_request.retainer_id)) {
              log.case_retainer_request.retainer_no = ++acc.retainer_no
              acc.retainer_ids[log.case_retainer_request.retainer_id] = log.case_retainer_request.retainer_no
            } else {
              log.case_retainer_request.retainer_no = acc.retainer_ids[log.case_retainer_request.retainer_id]
            }
          } catch {}
        }
      }
      if (log.case_refinement?.refinement_id) {
        if (log.case_refinement.refinement_no) {
          try {
            if (log.status) {
              log.status.refinement_no = log.case_refinement.refinement_no
            }
            acc.refinement_ids[log.case_refinement.refinement_id] = log.case_refinement.refinement_no
            if (log.case_refinement.refinement_no > 0 && log.case_refinement.refinement_no >= acc.refinement_no) {
              acc.refinement_no = log.case_refinement.refinement_no
            }
            if (log.case_refinement.refinement_no !== acc.refinement_no) {
              acc.plan_no = 0
              acc.retainer_no = 0
            }
          } catch {}
        } else {
          try {
            if (log.case_refinement.refinement_id == -1 || !Object.keys(acc.refinement_ids).includes(log.case_refinement.refinement_id)) {
              log.case_refinement.refinement_no = ++acc.refinement_no
              acc.plan_no = 0
              acc.retainer_no = 0
              acc.refinement_ids[log.case_refinement.refinement_id] = log.case_refinement.refinement_no
            } else {
              log.case_refinement.refinement_no = acc.refinement_ids[log.case_refinement.refinement_id]
            }
          } catch {}
        }
      }
      log.refinement_no = acc.refinement_no
      acc.logs.push(log)
      return acc
    }, {
      logs: [],
      plan_ids: {},
      plan_no: 0,
      retainer_ids: {},
      retainer_no: 0,
      refinement_ids: {},
      refinement_no: 0
    })).logs
  }
  logs = logs.filter(log =>
    (
      log.type !== 'UPLOAD' ||
      log.case_refinement?.refinement_id ||
      !(log.case_detail?.refinement_no || log.case_file?.refinement_no || log.case_impression?.refinement_no)
    ) &&
    (
      log.type !== 'UPDATE' ||
      !(log.case_detail || log.case_file || log.case_impression || log.case_retainer_request) ||
      (log.case_retainer_request?.delivered_flag)
    )
  ).map((log, i, logs) => ({
    refinementNo: log.refinement_no ?? 0,
    date: {
      friendly: Moment(log.created_date).fromNow(),
      formal: Moment(log.created_date).format('DD-MM-YYYY  HH:mm:ss')
    },
    logId: log.case_activity_log_id,
    msg: getMessage.bind(this)(log, StatusMsgDict, i, logs),
    ...(extraInfo? {
      btn: getButton.bind(this)(log, log.case_id, caseType, i, logs),
    } : null),
    ...(keepOri? {
      log
    } : null),
  }))
  if (reverse) logs.reverse()
  return logs
}

function getButton(log, caseId, caseType) {
  const btn = []
  if (typeof log.case_file === 'object' &&
    ['UPLOAD', 'UPDATE'].includes(log.type)
  ) {
    if (log.case_file.radio0 || log.case_file.radio1) {
      btn.push({
        onClick: _ => this.handleOpen('radiograph', 'view', { id: log.case_file.case_file_id }),
        value: this.get('log.VIEW_RADIOGRAPHS'),
        id: log.case_file.case_file_id
      })
    } else {
      btn.push({
        onClick: _ => this.handleOpen('photo', 'view', { id: log.case_file.case_file_id }),
        value: this.get('log.VIEW_PHOTOS'),
        id: log.case_file.case_file_id
      })
    }
  }
  if (typeof log.case_impression === 'object' &&
    ['UPLOAD', 'UPDATE'].includes(log.type)
  ) {
    btn.push({
      onClick: _ => this.handleOpen('impression', 'view', { id: log.case_impression.case_impression_id }),
      value: this.get('log.VIEW_IMPRESSION'),
      id: log.case_impression.case_impression_id
    })
  }
  // if (typeof log.case_detail === 'object') {
  //   btn.push({
  //     onClick: _ => this.handleOpen('questionnaire', 'view', { id: log.case_detail.case_detail_id }),
  //     value: this.get('log.VIEW_QUESTIONNAIRE'),
  //     id: log.case_detail.case_detail_id
  //   })
  // }
  if (typeof log.case_refinement === 'object') {
    if (log.case_refinement.refinement_id > 0) {
      btn.push({
        onClick: _ => this.handleOpen('refinement', 'view', {
          refinement_no: log.case_refinement.refinement_no,
          comment: log.case_refinement.comment
        }),
        value: this.get('log.VIEW_REFINEMENT'),
        id: log.case_refinement.refinement_id
      })
    }
  }
  if (typeof log.case_retainer_request === 'object') {
    // btn.push({
    //   onClick: _ => this.handleOpen('retainer', 'view', { id: log.case_retainer_request.retainer_id }),
    //   value: this.get('log.VIEW_RETAINER_REQUEST'),
    //   id: log.case_retainer_request.retainer_id
    // })
    if (!log.case_retainer_request.delivered_flag &&
      ["ADMIN", "SADMIN", "DISTR"].includes(this.state.role) &&
      caseType !== "RETAINER"
    ) {
      btn.push({
        onClick: _ =>
          this.handleInstantUpdate(
            `/case/${caseId}/retainer_request/${log.case_retainer_request.retainer_id}/delivered`,
            'Mark this Retainer Delivered?'
          ),
        value: this.get('log.MARK_DELIVERED'),
        id: log.case_retainer_request.retainer_id
      })
    }
  }
  if (typeof log.aligner_request === 'object') {
    // btn.push({
    //   onClick: _ => this.handleOpen('aligner'),
    //   value: 'View Aligner Request',
    //   id: log.aligner_request.request_id
    // })
    if (!log.aligner_request.delivered_flag &&
      ["ADMIN", "SADMIN", "DISTR"].includes(this.state.role)
    ) {
      btn.push({
        onClick: _ =>
          this.handleInstantUpdate(
            `/case/${caseId}/aligner_request/${log.aligner_request.request_id}/delivered`,
            'Mark this Aligner Set Delivered?'
          ),
        value: this.get('log.MARK_DELIVERED'),
        id: log.aligner_request.request_id
      })
    }
  }

  if ( log.status?.status === 'CHANGE_REQUEST') {

      if(log.status?.status_param2) {
        btn.push({
          newtab: true,
          url: join(SubPath, `clinicalSimulation/${this.props.match.params.id}/plan/${log.status?.status_param2}`),
          value: this.get('log.CLINICAL_SIMULATION')
        })
      }
  }
  if (typeof log.treatment_plan === 'object' && log.type !== 'UPDATE') {
    btn.push({
      newtab: true,
      url: join(SubPath, `clinicalSimulation/${this.props.match.params.id}/plan/${log.treatment_plan.plan_id}`),
      value: this.get('log.CLINICAL_SIMULATION')
    })
  }
  if (log.status?.status === 'PLAN_UPLOADED') {
    btn.push({
      newtab: true,
      url: join(SubPath, `patients/${this.props.match.params.id}/treatment`),
      value: this.get('log.VIEW_TREATMENT_PLAN')
    })
  }
  if (typeof log.stl_pair === 'object') {
    btn.push({
      onClick: _ => this.handleOpen('stl', 'view', { id: log.stl_pair.pair_id }),
      value: this.get('log.VIEW_STL_FILES'),
      id: log.stl_pair.pair_id
    })
  }
  return btn
}

function getMessage(log, StatusMsgDict={}) {
  let retMsg = this.get('log.UNKNOWN')
  switch (log.type) {
    case 'STATUS':
      let msgFromDict = ""
      if (log.status.status == "SWITCH_ALIGNER") {
        let msgs = StatusMsgDict[log.status.status]
        if (log.status.status_param1 == null) {
          // start using aligner
          msgFromDict = msgs[0]
        } else if (parseInt(log.status.status_param1) - parseInt(log.status.status_param2) === -1) {
          // next aligner
          msgFromDict = msgs[1]
        } else if (parseInt(log.status.status_param1) - parseInt(log.status.status_param2) >= 0) {
          // roll back
          msgFromDict = msgs[2]
        } else if (parseInt(log.status.status_param1) - parseInt(log.status.status_param2) < -1) {
          // jump forward
          msgFromDict = msgs[3]
        }
      } else {
        msgFromDict = StatusMsgDict[log.status.status]
      }
      retMsg = interpolateStatus(msgFromDict, log)
      break
    case 'UPLOAD':
      if (log.case_retainer_request && log.case_retainer_request.retainer_id) {
        retMsg = { msg: `${this.get('log.RETAINER_REQUESTED')} ${log.case_retainer_request.retainer_no}`, external: true }
        break
      } else if (log.case_refinement && log.case_refinement.refinement_id) {
        retMsg = `${this.get('log.REFINEMENT')} ${log.case_refinement.refinement_no}`
        break
      } else if (log.aligner_request && log.aligner_request.request_id) {
        let substr = ''
        if (log.aligner_request.type !== 'REQUEST') {
          substr = `[Type: ${log.aligner_request.type[0] + log.aligner_request.type.toLowerCase().slice(1)}]`
        }
        retMsg = {
          msg: `${this.get('log.ALIGNERS_REQUESTED')} ${substr} (${this.get('log.STEP')}: ${log.aligner_request.detail.map(v => v.step_no + ' ' + v.thickness).join(', ')})`,
          external: true
        }
        break
      } else if (log.case_file && log.case_file.case_file_id) {
        if (String.isNotBlank(log.case_file.radio0) &&
          String.isNotBlank(log.case_file.radio1)
        ) {
          retMsg = { msg: this.get('log.UPLOADED_RADIOGRAPHS'), external: false }
          break
        } else {
          retMsg = { msg: this.get('log.UPLOADED_PHOTOGRAPHS'), external: false }
          break
        }
      } else if (log.case_impression && log.case_impression.case_impression_id) {
        retMsg = this.get('log.UPLOADED_IMPRESSION')
        break
      } else if (log.case_detail && log.case_detail.case_detail_id) {
        retMsg = this.get(':fields.questionnaires')
        break
      } else if (log.treatment_plan && log.treatment_plan.plan_id) {
        retMsg = this.get('log.UPLOADED_TREATMENT_PLAN') + ' ' + log.treatment_plan.plan_no
        break
      } else if (log.stl_pair && log.stl_pair.pair_id) {
        retMsg = this.get('log.UPLOADED_INDIVIDUAL_STL_FILES')
        break
      }
      break
    case 'UPDATE':
      if (log.aligner_request && log.aligner_request.request_id &&
        log.aligner_request.delivered_flag
      ) {
        retMsg = this.get('log.ALIGNERS_STEP_DELIVERED').replace('{{step}}', `(${this.get('log.STEP')}: ${log.aligner_request.detail.map(v => v.step_no + ' ' + v.thickness).join(', ')})`)
        break
      } else if (log.case_retainer_request && log.case_retainer_request.retainer_id &&
        log.case_retainer_request.delivered_flag
      ) {
        retMsg = this.get('log.RETAINER_DELIVERED').replace('{{retainer_no}}', log.case_retainer_request.retainer_no)
        break
      } else if (log.treatment_plan && log.treatment_plan.plan_id) {
        retMsg = this.get('log.TREATMENNT_PLAN_UPDATED').replace('{{plan_no}}', log.treatment_plan.plan_no)// + ' ' + log.status.status_remarks
        break
      } else if (log.status?.status === 'RECEIVED') {
        retMsg = this.get('log.RECEIVED_STATUS_UPDATE').replace('{{status_param1}}', log.status.status_param1).replace('{{status_param2}}', log.status.status_param2)
        break
      }
      // else if (log.case_file && log.case_file.case_file_id) {
      //   return { msg: this.get('log.UPLOADED_MISSING_PHOTOS_RADIOS'), external: true }
      // } else if (log.case_impression && log.case_impression.case_impression_id) {
      //   return { msg: this.get('log.UPLOADED_MISSING_IMPRESSIONS'), external: true }
      // }
    case 'UNDO':
      if (log.treatment_plan && log.treatment_plan.plan_id) {
        retMsg = this.get('log.TREATMENNT_PLAN_UPDATED').replace('{{plan_no}}', log.treatment_plan.plan_no)// + ' ' + log.status.status_remarks
        break
      }
    default:
      return this.get('log.UNKNOWN')
  }
  const refinement_no = log.refinement_no ?? log.case_detail?.refinement_no ?? log.case_file?.refinement_no ?? log.case_impression?.refinement_no ?? log.case_refinement?.refinement_no
  if (refinement_no && refinement_no > 0) {
    if (typeof retMsg === 'object') {
      retMsg.msg = `( R${refinement_no} ) ${retMsg.msg}`
    } else if (typeof retMsg === 'string') {
      retMsg = `( R${refinement_no} ) ${retMsg}`
    }
  }
  return retMsg
}

export default {
  translateLogs
}
