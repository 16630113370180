import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { withRouter } from 'react-router-dom'
import { CircularProgress, Button } from '@material-ui/core/';
import Grid from '@material-ui/core/Grid';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import MUIDataTable from "mui-datatables";
import axios from '../../../axios-default'
import { connect } from 'react-redux';
import Moment from 'moment'
import { updateUpdatingAccountUser, updateUpdatingAccountRole, reloadUpdatingAccount } from '../../../store/UpdatingAccount/UpdatingAccount.action'

const columns = [
  {
    name: "logType",
    label: "Activity Log Type",
    options: {
      filter: true,
      sort: false,
    }
  },
  {
    name: "ip",
    label: "IP Address",
    options: {
      filter: true,
      sort: false,
    }
  },
  {
    name: "createdDate",
    label: "Date",
    options: {
      filter: true,
      sort: true,
    }
  },
]

const Type = {
  'LOGIN': 'Log in',
  'LOGOUT': 'Log out',
  'ADD': 'Add',
  'UPDATE': 'Update'
}

class ActivityLog extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  async componentDidMount() {
    try {
      if (!this.props.user.log || !this.props.user.log.length) {
        this.props.updateUser({ log: (await axios.get(`user/${this.props.user.user_id}/log`)).data.log })
      }
    } catch (err) {
      console.error(err)
      if (err.response && err.response.statusText === 'Unauthorized') {
        localStorage.removeItem("auth-token");
        localStorage.removeItem("expirationDate");
        localStorage.removeItem("role");
        this.props.history.push("/login")
      }
    }
  }

  getData = () => {
    return this.props.user.log.map(l => ({
      logType: Type[l.type],
      ip: l.ip.startsWith('::ffff:')? l.ip.slice(7) : l.ip,
      createdDate: Moment(l.created_date).calendar({
        sameElse: 'DD MMM YYYY [at] hh:mm A'
      })
    }))
  }

  render() {
    const options = {
      download: false,
      filterType: "dropdown",
      responsive: "scroll",
      selectableRows: false,
      rowsPerPage: 30,
      rowsPerPageOptions: [15, 30, 50],
      onRowClick: (rowData) => {}
    };
    return (
      <MUIDataTable
        title={"Activity Log"}
        data={this.getData()}
        columns={columns}
        options={options}
      />
    );
  }
}

ActivityLog.propTypes = {
}


const mapStateToProps = state => ({
  ...state.updatingAccount
});

const mapDispatchToProps = dispatch => {
  return {
    updateUser: (userObj) => dispatch(updateUpdatingAccountUser(userObj)),
    updateRole: (roleObj) => dispatch(updateUpdatingAccountRole(roleObj)),
    reload: (userId) => dispatch(reloadUpdatingAccount(userId))
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ActivityLog))