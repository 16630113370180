import { makeStyles } from '@material-ui/core'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SideNav from '../../components/Admin/SideNav';
import { Grid, MenuItem, TextField, Button, CircularProgress, Select, Fab } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import { useHistory } from 'react-router-dom'
import { updateRes } from '../../store/res/res.action';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import axios from '../../axios-default';
import { easySnackbar } from '../../store/Noti/noti.actions';
import Modal from '../../components/Modal/modal';
import { getWebAbsPath } from '../../util/path';

const ClinicsStyles = makeStyles(theme => ({
  "@global": {
    body: {
      background: "#fdfdfd",
    },
  },
  root: {
    flexGrow: 1,
  },
  rootContainer: {
    padding: 16,
    margin: "8px 0px 0px 238px",
    [theme.breakpoints.between("xs", "sm")]: {
      margin: "12px 0px 0px 0px",
      width: "100%",
    },
    width: "calc(100% - 238px)",
  },
  scanBox: {
    padding: 16,
    margin: "8px 0px"
  },
  textField: {
    // marginLeft: "8px",
    // marginRight: "8px",
    '& label.Mui-focused': {
      color: 'black',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'black',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey',
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
  },
  dateField: {
    width: "100%"
  },
  formControl: {
    width: "100%"
  },
  dense: {
    marginTop: 16,
    marginLeft: 0
  },
  submitBtn: {
    backgroundColor: "#061a2e !important",
    color: "white",
    padding: "8px 24px",
    margin: "12px 0px"
  },
  linkBtn: {
    background: "rgba(89,54,135,1)",
    color: "white",
    padding: "4px 24px",
    fontWeight: 300,
    margin: "16px 0px",
    width: "100%",
    [theme.breakpoints.up('sm')]: {
      padding: "4px 72px",
      width: "unset",
    },
    "&:hover": {
      background: "rgb(77, 74, 142)",
      color: "white"
    }
  },
}))

const Clinics = () => {
  const classes = ClinicsStyles()
  const res = useSelector(({ res }) => res)
  const dispatch = useDispatch()
  const history = useHistory()

  const [modalOpen, setModalOpen] = useState(false),
        [modalError, setModalError] = useState(''),
        [updatedClinic, setUpdatedClinic] = useState({}),
        [clinics, setClinics] = useState([]),
        [loading, setLoading] = useState(true)

  useEffect(() => {
    (async () => {
      try {
        setClinics(
          Object.values(res.clinics).sort((a, b) => a.clinic_id - b.clinic_id).map(c => ({
            clinicName: c.clinic_name,
            phoneNo: c.phone_no,
            website: c.website,
            groupName: c.group && c.group.group_name,
            distrGroupName: c.distr_group?.distr_group_name,
            country: res.countries[c.country_id] && res.countries[c.country_id].country_name,
            stateProvince: res.stateProvinces[c.state_province_id] && res.stateProvinces[c.state_province_id].name,
            id: c
          }))
        )
        setLoading(false)
        setUpdatedClinic({})
      } catch (err) {
        console.error(err)
        if (err.response && err.response.statusText === 'Unauthorized') {
          localStorage.removeItem("auth-token");
          localStorage.removeItem("expirationDate");
          localStorage.removeItem("role");
          history.push("/login")
        }
      }
    })()
  }, [res.clinics, res.countries, res.stateProvinces])

  const Columns = useMemo(() => [
    {
      name: "clinicName",
      label: "Clinic Name",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "phoneNo",
      label: 'Phone',
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "website",
      label: "Website",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "groupName",
      label: "Group Name",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "distrGroupName",
      label: "Distr Group Name",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "country",
      label: "Country",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "stateProvince",
      label: "State/Province",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "id",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ display: "flex" }}>
              <Fab size="small" aria-label="add"
                style={{
                  backgroundColor: "#75a0de",
                  color: "white",
                  margin: "0px 4px"
                }}
                onClick={() => {
                  setUpdatedClinic(
                    Object.assign({}, value, {
                      group_id: value.group?.group_id ?? null,
                      ori_group_id: value.group?.group_id ?? null,
                      distr_group_id: value.distr_group?.distr_group_id ?? null,
                      ori_distr_group_id: value.distr_group?.distr_group_id ?? null
                    })
                  )
                  setModalOpen(true)
                }}>
                <EditIcon />
              </Fab>
              <Fab size="small" aria-label="add"
                style={{
                  backgroundColor: "#da5851",
                  color: "white",
                  margin: "0px 4px"
                }}
                onClick={async () => {
                  if (window.confirm('Are you going to delete this clinic?')) {
                    try {
                      const { data } = await axios.delete(`res/clinic/${value.clinic_id}`)
                      if (data.success) {
                        setLoading(true)
                        await dispatch(updateRes('clinic'))
                        history.push('/blank')
                        history.replace(`/admin/clinics`)
                      } else {
                        console.error(data.message)
                      }
                    } catch (err) {
                      console.error(err)
                      if (err.response && err.response.statusText === 'Unauthorized') {
                        localStorage.removeItem("auth-token");
                        localStorage.removeItem("expirationDate");
                        localStorage.removeItem("role");
                        history.push("/login")
                      }
                    }
                  }
                }}>
                <DeleteOutlineIcon />
              </Fab>
            </div>
          );
        }
      }
    },
  ], [])

  const reloadPage = useCallback(() => {
    const curPage = history.location.pathname
    history.push('/blank')
    history.replace(curPage)
  }, [history])

  const handleUpdate = useCallback(field => ({ target: { value } }) => {
    if (
      ['group_id', 'distr_group_id'].includes(field)
      && value
    ) {
      setUpdatedClinic({
        ...updatedClinic,
        group_id: null,
        distr_group_id: null,
        [field]: value
      })
    } else {
      setUpdatedClinic({
        ...updatedClinic,
        [field]: value ?? null
      })
    }
  }, [updatedClinic, setUpdatedClinic])

  const handleSave = useCallback(async () => {
    try {
      if (loading) return
      setLoading(true)
      if (String.isBlank(updatedClinic.clinic_name)) {
        return setModalError("Clinic Name is required")
      }
      if (String.isBlank(updatedClinic.phone_no)) {
        return setModalError("Phone No is required")
      }
      if (!updatedClinic.country_id) {
        return setModalError("Country is required")
      }
      if (!updatedClinic.group_id && !updatedClinic.distr_group_id) {
        return setModalError("Please select group or distributor group")
      }
      await axios.post(`res/clinic`, updatedClinic)
      window.scroll({top: 0, left: 0, behavior: 'smooth' })
      dispatch(easySnackbar('Success', 'success'))
      await dispatch(updateRes('clinic'))
      reloadPage()
      setLoading(false)
      setModalOpen(false)
    }
    catch (err) {
      console.error(err)
      if (err.response && err.response.statusText === 'Unauthorized') {
        localStorage.removeItem("auth-token");
        localStorage.removeItem("expirationDate");
        localStorage.removeItem("role");
        history.push("/login")
      }
    } finally {
      setLoading(false)
    }
  }, [reloadPage, setLoading, setModalOpen, setModalError, dispatch, updatedClinic])

  const groupSelect = useMemo(() =>
    [(
      <MenuItem value={undefined} key={-1}>None</MenuItem>
    )].concat(Object.entries(res.groups).map(([id, g]) => (
      <MenuItem value={id} key={id}>{g.group_name}</MenuItem>
    )))
  , [res?.groups])

  const distrgroupSelect = useMemo(() =>
    [(
      <MenuItem value={undefined} key={-1}>None</MenuItem>
    )].concat(Object.entries(res.distr_groups).map(([id, dg]) => (
      <MenuItem value={id} key={id}>{dg.distr_group_name}</MenuItem>
    )))
  , [res?.distr_groups, loading])

  const countrySelect = useMemo(() =>
    Object.entries(res.countries).map(([id, c]) => (
      <MenuItem value={id} key={id}>{c.country_name}</MenuItem>
    ))
  , [res?.countries])

  const stateProvinceSelect = useMemo(() =>
    Object.entries(res.stateProvinces).filter(e => e[1].country_id == updatedClinic.country_id).map(([id, s]) => (
      <MenuItem value={id} key={id}>{s.name}</MenuItem>
    ))
  , [res?.stateProvinces])

  return (
    <div>
      <SideNav />
      <Grid container className={classes.rootContainer}>

      </Grid>
      <Grid container className={classes.rootContainer} style={{ marginTop: 0 }}>
        {!loading ?
          <div style={{ display: "contents" }}>
            <Grid container>

              <Grid item xs={12}
                style={{
                  padding: 8
                }}>
                <h1
                  style={{
                    marginBlockEnd: 0
                  }}
                >
                  Clinics
                </h1>

                <hr />
              </Grid>

              <Grid item xs={12}
                style={{
                  padding: 8,
                  textAlign: "center"
                }}>
                <img style={{ width: "100%", maxWidth: 800 }} src={getWebAbsPath("/img/account1.png")} />
              </Grid>

              <Grid item xs={12} style={{ padding: 8 }}>
                <Button className={classes.linkBtn} onClick={() => setModalOpen(true)}>
                  Add Clinic
                </Button>
                <MUIDataTable
                  title={"Clinic List"}
                  data={clinics}
                  columns={Columns}
                  options={{
                    download: false,
                    filterType: "dropdown",
                    responsive: "scroll",
                    selectableRows: false,
                    rowsPerPage: 30,
                    rowsPerPageOptions: [15, 30, 50]
                  }}
                />
                <Modal
                  className="modal"
                  modal='Clinic'
                  title='Modify Clinic'
                  open={modalOpen}
                  handleClose={() => setModalOpen(false)}
                  handleDataSave={() => handleSave()}
                  modalError={modalError}
                  submitButton={true}
                >
                  <>
                    <Grid item xs={12} style={{ padding: 4 }}>
                      <TextField
                        id="clinic_name"
                        label="Clinic Name"
                        margin="normal"
                        style={{ width: "100%" }}
                        value={updatedClinic.clinic_name}
                        onChange={handleUpdate('clinic_name')}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ padding: 4 }}>
                      <TextField
                        id="phone_no"
                        label="Phone"
                        style={{ width: "100%" }}
                        margin="normal"
                        value={updatedClinic.phone_no}
                        onChange={handleUpdate('phone_no')}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ padding: 4 }}>
                      <TextField
                        id="website"
                        label="Website"
                        style={{ width: "100%" }}
                        margin="normal"
                        value={updatedClinic.website}
                        onChange={handleUpdate('website')}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ padding: 4 }}>
                      <p>Select Group</p>
                      <Select
                        displayEmpty
                        onChange={handleUpdate('group_id')}
                        style={{ width: "100%" }}
                        value={updatedClinic.group_id}
                      >
                        {groupSelect}
                      </Select>
                    </Grid>
                    <Grid item xs={12} style={{ padding: 4 }}>
                      <p>Select Distr Group</p>
                      <Select
                        displayEmpty
                        onChange={handleUpdate('distr_group_id')}
                        style={{ width: "100%" }}
                        value={updatedClinic.distr_group_id}
                      >
                        {distrgroupSelect}
                      </Select>
                    </Grid>
                    <Grid item xs={12} style={{ padding: 4 }}>
                      <p>Select Country</p>
                      <Select
                        displayEmpty
                        onChange={handleUpdate('country_id')}
                        style={{ width: "100%" }}
                        value={updatedClinic.country_id}
                      >
                        {countrySelect}
                      </Select>
                    </Grid>

                    {Object.values(res.stateProvinces).some(s => s.country_id == updatedClinic.country_id) ?
                      <Grid item xs={12} style={{ padding: 4 }}>
                        <p>Select State</p>
                        <Select
                          displayEmpty
                          onChange={handleUpdate('state_province_id')}
                          style={{ width: "100%" }}
                          value={updatedClinic.state_province_id}
                        >
                          {stateProvinceSelect}
                        </Select>
                      </Grid> :
                      null}
                  </>
                </Modal>
              </Grid>

            </Grid>

          </div> :
          <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress style={{ color: "rgb(6,26,46)" }} />
          </Grid>}
      </Grid>
    </div>
  )
}

export default Clinics