import React from 'react'
import PropTypes from 'prop-types';
import { TableHead, TableRow, TableCell, TableSortLabel, makeStyles } from "@material-ui/core"


const userHeadStyles = makeStyles((theme) => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  emptyCell: {
    width: 20
  }
}))

const CollapsibleTableHead = (props) => {
  const { onRequestSort } = props
  const classes = userHeadStyles()
  const [orderBy, setOrderBy] = React.useState(),
        [orderDir, setOrderDir] = React.useState('asc')
  const onSort = (name) => _ => {
    let newDir = 'asc'
    if (orderBy === name) {
      newDir = orderDir === 'desc'? 'asc' : 'desc'
    }
    setOrderDir(newDir)
    setOrderBy(name)
    if (onRequestSort) onRequestSort(name, newDir)
  };

  const renderEmptyColumns = (n) => {
    if (n <= 0) return [null]
    return [(<TableCell key={n}/>), ...renderEmptyColumns(n-1)]
  }

  return (
    <TableHead>
      <TableRow>
        {props.shiftColumnNum? renderEmptyColumns(props.shiftColumnNum) : null}
        {props.columns.map(({ name, label, index, numeric, sortable }, i) => (
          sortable?
            <TableCell
              key={i}
              align={numeric ? 'right' : (index? 'left' : 'center')}
              padding='default'
              sortDirection={orderBy === name ? orderDir : false}
            >
              <TableSortLabel
                active={orderBy === name}
                direction={orderDir}
                onClick={onSort(name)}
              >
                {label}
                {orderBy === name ? (
                  <span className={classes.visuallyHidden}>
                    {orderDir === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell> :
            <TableCell
              key={i}
              padding='default'
              align={numeric ? 'right' : (index? 'left' : 'center')}
            >
              {label}
            </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}


CollapsibleTableHead.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    render: PropTypes.func,
    index: PropTypes.bool,
    sortable: PropTypes.bool
  })),
  onRequestSort: PropTypes.func.isRequired,
  shiftColumnNum: PropTypes.number
};

export default CollapsibleTableHead