import React, { Component, PropTypes } from 'react';
import { Grid, Button, Select, MenuItem, TextField } from '@material-ui/core'
import style from '../NewPatient/Photos/NewPatientPhotos.module.css'
import DragAndDrop from '../NewPatient/Photos/DragAndDrop'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import axios from '../../axios-default'
import STLViewer from 'stl-viewer'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { withLang } from '../../util/lang';
import { connect } from 'react-redux';
import { getFormToken } from '../../util/file';
import { withRouter } from 'react-router-dom';
import { easySnackbar } from '../../store/Noti/noti.actions';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


class UploadStl extends Component {

  constructor(props) {
    super(props);
    this.state = {
      stl: [
        { "lower": null, "upper": null, "remove": false }
      ],
      StlUploadLoadingStatus: false,
      stlError: ""
    }
  }

  addStlUpload() {
    let newStl = [...this.state.stl]
    let modifiedstl = newStl.push({ "lower": "", "upper": "", "remove": true })
    this.setState({ stl: newStl })
  }

  removeStl(index, e) {
    let newStl = [...this.state.stl]
    let modifiedstl = newStl.splice(index, 1)
    this.setState({ stl: newStl })
  }
  handleDrop = (uploadedFile, index, type) => {
    this.fileupload(uploadedFile, index, type)
  }

  // fileToBase64 = file => new Promise((resolve, reject) => {
  //   const reader = new FileReader();
  //   if (file !== undefined) {
  //     reader.readAsDataURL(file);
  //   }
  //   reader.onload = () => resolve(reader.result);
  //   reader.onerror = error => reject(error);
  // });

  fileupload(file, index, type) {
    let ClassThis = this
    if (type == 'lower') {
      let newStl = [...this.state.stl]
      newStl[index]['lower'] = file
      this.setState({ stl: newStl })
    }
    else {
      let newStl = [...this.state.stl]
      newStl[index]['upper'] = file
      this.setState({ stl: newStl })
    }
    // let baseFile = this.fileToBase64(file).then((data) => {
    //   var i = new Image();
    //   i.src = data;
    //   if (type == 'lower') {
    //     let newStl = [...this.state.stl]
    //     newStl[index]['lower'] = data
    //     this.setState({ stl: newStl })
    //   }
    //   else {
    //     let newStl = [...this.state.stl]
    //     newStl[index]['upper'] = data
    //     this.setState({ stl: newStl })
    //   }
    // })
  }

  removefile(index, type) {
    if (type == 'lower') {
      let newStl = [...this.state.stl]
      newStl[index]['lower'] = null
      this.setState({ stl: newStl })
    }
    else {
      let newStl = [...this.state.stl]
      newStl[index]['upper'] = null
      this.setState({ stl: newStl })
    }
  }

  async handleDataSave() {
    const form = new FormData()
    let stlFile = []

    this.state.stl.forEach((value, index) => {
      if (!value.lower || !value.upper) {
        this.props.showSnackbar('Please upload files for both upper and lower', 'error')
        return
      }
      stlFile.push({
        pair_no: index,
        stl0: getFormToken(form, value.lower, `$$FILE:stl0_${index}`),
        stl1: getFormToken(form, value.upper, `$$FILE:stl1_${index}`)
      })
    })

    let StlFileObj = {
      case_id: this.props.caseId,
      stl_pair: stlFile
    }
    form.append('body', JSON.stringify(StlFileObj))

    try {
      this.setState({ StlUploadLoadingStatus: true, loading: true })
      await axios.post(`case/stl_pair/${this.props.caseId}`, form, {
        onUploadProgress: this.progressBarRef.current?.onProgressChange
      })
      this.props.showSnackbar('Stl files uploaded', 'success', true)
      this.props.history.push('/blank')
      this.props.history.replace(this.props.history.location.pathname)
    }
    catch (err) {
      this.setState({ stlError: "All files are required" })
      console.error(err.response)

      if (err.response?.data?.invalid) {
        const invalidFields = err.response.data.invalid.join(',')
        this.props.showSnackbar(`Please enter ${invalidFields}`, 'error')
      } else {
        this.props.showSnackbar(err.response?.data?.message ?? err.message ?? err, 'error')
      }

      if (err && err.response && err.response && err.response.statusText === 'Unauthorized') {
        localStorage.removeItem("auth-token");
        localStorage.removeItem("expirationDate");
        localStorage.removeItem("role");
        this.props.history.replace('/login')
      }
    } finally {
      this.setState({ StlUploadLoadingStatus: false, loading: false })
    }
  }

  onFileChange = ({ target: { files: [ file ] } }, index, type) => {
    let compo = this
    if (type == 'lower') {
      let newStl = [...this.state.stl]
      newStl[index]['lower'] = file
      this.setState({ stl: newStl })
    }
    else {
      let newStl = [...this.state.stl]
      newStl[index]['upper'] = file
      this.setState({ stl: newStl })
    }
    // let baseFile = compo.fileToBase64(event.target.files[0]).then((data) => {
    //   var i = new Image();
    //   i.src = data;

    //   if (type == 'lower') {
    //     let newStl = [...this.state.stl]
    //     newStl[index]['lower'] = data
    //     this.setState({ stl: newStl })
    //   }
    //   else {
    //     let newStl = [...this.state.stl]
    //     newStl[index]['upper'] = data
    //     this.setState({ stl: newStl })
    //   }

    // })
  }

  render() {
    let { stl } = this.state
    return (
      <>
        {
          stl.map((value, index) =>
            <Grid container>
              {this.state.stlError && <Alert severity="error" style={{ marginBottom: "30px", width: "100%" }}>{this.state.stlError}</Alert>}
              <Grid item xs={12}>
                <p>{this.get('clinicalSimulation.row')} #{index + 1}</p>
              </Grid>
              <Grid item xs={12} sm={6} style={{ padding: "8px 0px" }}>
                {value.lower === "" ?
                  <DragAndDrop handleDrop={(uploadedFile) => this.handleDrop(uploadedFile, index, "lower")}>
                    <div className={style.imageUploadArea} style={{ height: "72px" }}>
                      <span>{this.get(':dragAndDrop.dragAndDrop')}</span>
                      <div className={style.uploadBtnWrapper}>
                        <button className={style.uploadBtn}>+</button>
                        <input
                          type="file"
                          onChange={(e) => this.onFileChange(e, index, "lower")}
                          name="patientImage"
                          id="patientImage"
                        />
                      </div>
                    </div>
                    <p style={{ textAlign: "center" }}>({this.get('clinicalSimulation.buttons.lower')})</p>
                  </DragAndDrop> :
                  <div className={style.imageUploadArea}>
                    <button className={style.remove} onClick={() => this.removefile(index, "lower")}><DeleteOutlinedIcon /></button>
                    <STLViewer style={{ width: "100%", maxWidth: 400, marginTop: "7%" }} model={value.lower} />
                  </div>
                }

              </Grid>

              <Grid item xs={12} sm={6} style={{ padding: "8px 0px" }}>
                {
                  value.upper === "" ?
                    <DragAndDrop handleDrop={(uploadedFile) => this.handleDrop(uploadedFile, index, "upper")}>
                      <div className={style.imageUploadArea} style={{ height: "72px" }}>
                        <span>{this.get(':dragAndDrop.dragAndDrop')}</span>
                        <div className={style.uploadBtnWrapper}>
                          <button className={style.uploadBtn}>+</button>
                          <input
                            type="file"
                            onChange={(e) => this.onFileChange(e, index, "upper")}
                            name="patientImage"
                            id="patientImage"
                          />
                        </div>
                      </div>
                      <p style={{ textAlign: "center" }}>({this.get('clinicalSimulation.buttons.upper')})</p>
                    </DragAndDrop> :
                    <div className={style.imageUploadArea}>
                      <button className={style.remove} onClick={() => this.removefile(index, "upper")}><DeleteOutlinedIcon /></button>
                      <STLViewer style={{ width: "100%", maxWidth: 400, marginTop: "7%" }} model={value.upper} />
                    </div>
                }

              </Grid>
              <Grid item xs={12}>
                {value.remove && <Button variant="outlined"
                  style={{ background: "#da5c5c", color: "white", border: "none" }}
                  onClick={(e) => this.removeStl(index, e)}>{this.get(':const.remove')}</Button>}
              </Grid>

            </Grid>
          )}

        <hr style={{ border: "solid 1px #ededed", width: "100%" }} />

        <Grid item xs={12}>
          <Button variant="outlined" style={{ marginRight: "12px" }} onClick={() => this.addStlUpload()}>{this.get('clinicalSimulation.addRow')}</Button>
          <Button onClick={() => this.handleDataSave()}>{this.state.StlUploadLoadingStatus ? `${this.get(':const.uploading')}...` : this.get(':const.submit')}</Button>
        </Grid>
      </>
    )
  }
}


const mapStateToProps = store => {
  return {
    lang: store.lang
  }
};

const mapDispatchToProps = dispatch => ({
  showSnackbar: (msg, variant, queue=false) => dispatch(easySnackbar(msg.toString(), variant, queue))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withLang(UploadStl, 'PatientInfo')))
