import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useLang } from '../../util/lang';
const ConfirmDialog = ({
  title, children, open, setOpen, onConfirm, dialogConfirmation
}) => {
  const get = useLang()
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        {
          dialogConfirmation ?
            <>
              <Button
                variant="contained"
                onClick={() => {
                  setOpen(false);
                  if (onConfirm) onConfirm();
                }}
                style={{ backgroundColor: "rgba(89,54,135,1)", color: "white" }}
              >
                {dialogConfirmation}
              </Button>
              <Button
                // variant="contained"
                onClick={() => setOpen(false)}
              >
                {get(':const.close')}
        </Button>
            </> :
            <>
              <Button
                // variant="contained"
                onClick={() => setOpen(false)}
              >
                {get(':const.no')}
        </Button>
              <Button
                variant="contained"
                onClick={() => {
                  setOpen(false);
                  if (onConfirm) onConfirm();
                }}
                style={{ backgroundColor: "rgba(89,54,135,1)", color: "white" }}
              >
                {get(':const.yes')}
        </Button>
            </>
        }

      </DialogActions>
    </Dialog>
  );
};
export default ConfirmDialog;